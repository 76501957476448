import "date-fns";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Alert } from "reactstrap";

import DateFnsUtils from "@date-io/date-fns";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  FormGroup,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useFlightData } from "../../data/FlightData";
import City from "../City";
import airportData from "../../data/airport";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { FaChair, FaClock, FaDollarSign, FaPlane } from "react-icons/fa";
import { GiSofa } from "react-icons/gi";
import SwapImage from "../../assets/images/icons/swap.png";

function EditBook(flightdata) {
  const flightDetails = flightdata.flightdata[0];
  const currentDate = new Date();
  const { UpdateFlightData } = useFlightData();

  console.log(flightDetails);

  const [from, setFrom] = useState(flightDetails.from_airport);
  const [to, setTo] = useState(flightDetails.to_airport);
  const [datevalue, setDateValue] = useState("");
  const [time, setTime] = useState("");
  const [planeType, setPlaneType] = useState(flightDetails.plane_type);
  const [formatteddatevalue, setFormattedDateValue] = useState("");
  const [total, setTotal] = useState("");
  const [available, setAvailable] = useState(flightDetails.available_seats);
  const [price, setPrice] = useState(flightDetails.total_price);
  const [user] = useLocalStorage("auth", {});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFlightPosted, setFlightPosted] = useState(false);
  const [isCharter, setIsCharter] = useState(false);

  const history = useHistory();

  useEffect(() => {
    // console.log(new Date(getFormatedDate(flightDetails.dep_date) + " " + flightDetails.dep_time));
    setDateValue(new Date(getFormatedDate(flightDetails.dep_date) + " " + flightDetails.dep_time));
    setFormattedDateValue(getFormatedDate(flightDetails.dep_date));
    setTime(
      GetFormatedTime(
        new Date(
          getFormatedDate(flightDetails.dep_date) +
          " " +
          flightDetails.dep_time
        )
      )
    );
  }, []);

  function SwapCity(e) {
    const From = from;
    setFrom(to);
    setTo(From);
  }

  function isCharterToggled(e) {
    isCharter == true ? setIsCharter(false) : setIsCharter(true)
    console.log(isCharter);
  }

  const DateChange = (date) => {
    setFormattedDateValue(getFormatedDate(date));
    setDateValue(date);
    setTime(GetFormatedTime(date));
  };

  function getFormatedDate(strDate) {
    let date = new Date(strDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return month + "/" + dt + "/" + year;
  }

  function GetFormatedTime(date) {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    var h = addZero(date.getHours());
    var m = addZero(date.getMinutes());
    return h + ":" + m;
  }

  async function EditFlightDetails() {
    let postData = {
      flightid: flightDetails.id,
      is_posted: flightDetails.is_posted,
      mobile: user.mobile,
      userid: user.userid,
      dep_date: formatteddatevalue,
      dep_time: time,
      from_city: getCityFromCode(from),
      from_airport: from,
      to_city: getCityFromCode(to),
      to_airport: to,
      planetype: planeType,
      totalseats: null,
      is_charter: isCharter,
      availableseats:
        available === "" || available === undefined || available === null
          ? 0
          : parseInt(available, 10),
      Totalprice:
        price === "" || price === undefined || price === null
          ? 0
          : parseInt(price, 10),
    };

    if (ValidateData()) {
      console.log(postData)

      const response = await UpdateFlightData(postData, user);

      if (response.status) {
        history.push("/myFlights");
      }
    }
  }

  function getCityFromCode(code) {
    return airportData.find((item) => item.IATA === code).city;
  }

  function ValidateData() {
    if (from === "" || from === undefined || from === null) {
      setErrorMessage("Please select from");
      setIsError(true);
      return false;
    } else if (to === "" || to === undefined || to === null) {
      setErrorMessage("Please select to");
      setIsError(true);
      return false;
    } else if (
      formatteddatevalue === "" ||
      formatteddatevalue === undefined ||
      formatteddatevalue === null
    ) {
      setErrorMessage("Please select valid date");
      setIsError(true);
      return false;
    } else if (time === "" || time === undefined || time === null) {
      setErrorMessage("Please select time");
      setIsError(true);
      return false;
    } else if (
      planeType === "" ||
      planeType === undefined ||
      planeType === null
    ) {
      setErrorMessage("Please enter plane type");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }


  return (
    <>
      <Row>
        <Col lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }} sm="12">
          <Row>
            <Col lg="12" md="12" sm="12">
              {isError && <Alert className="alertYellow">{errorMessage}</Alert>}
              {isFlightPosted && (
                <Alert className="alertYellow">
                  Your seats are listed for sale.
                </Alert>
              )}
            </Col>
          </Row>
          <Row className="pl-1 pr-1">
            <Col lg="12" md="12" sm="12">
              <City type="from" code={from} setCode={setFrom} Page="Book" />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <img src={SwapImage} className="btnSwap" onClick={SwapCity} />
            </Col>
          </Row>
          <Row className="pl-1 pr-1">
            <Col lg="12" md="12" sm="12">
              <City type="to" code={to} setCode={setTo} Page="Book" />
            </Col>
          </Row>
          <Row className="pl-1 pr-1">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
                <KeyboardDatePicker
                  className="p-0"
                  fullWidth
                  label="Date"
                  minDate={new Date().toString()}
                  format="MM/dd/yyyy"
                  value={datevalue}
                  onChange={DateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  keyboardIcon={
                    <img
                      alt="..."
                      style={{ height: "25px" }}
                      src={
                        require("../../assets/images/icons/calendar.png")
                          .default
                      }
                    />
                  }
                />
              </Col>
              <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
                <KeyboardTimePicker
                  className="p-0"
                  fullWidth
                  label="Time"
                  value={datevalue}
                  onChange={DateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  keyboardIcon={<FaClock className="text-dark" />}
                />
              </Col>
            </MuiPickersUtilsProvider>
          </Row>
          <Row className="pl-1 pr-1">
            <Col lg="12" md="12" sm="12" className="mb-3">
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="planeType">Plane Type</InputLabel>
                  <Input
                    id="planeType"
                    value={planeType}
                    onChange={(e) => setPlaneType(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          alt="..."
                          style={{ height: "25px" }}
                          src={
                            require("../../assets/images/icons/olane-2.png")
                              .default
                          }
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row className="pl-1 pr-1">
            <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="price">Price</InputLabel>
                  <Input
                    id="price"
                    onChange={(e) =>
                      e.target.value.length < 8 && setPrice(e.target.value)
                    }
                    value={price}
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">
                        <FaDollarSign
                          className="text-dark"
                          style={{ fontSize: "25px" }}
                        />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormGroup>
            </Col>
            <Col lg="3" md="3" sm="3" xs="3" className="mb-3">
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="availableSeats">
                    Seats
                  </InputLabel>
                  <Input
                    id="availableSeats"
                    onChange={(e) =>
                      e.target.value.length < 4 && setAvailable(e.target.value)
                    }
                    value={available}
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">
                        {/* <GiSofa
                          className="text-dark"
                          style={{ fontSize: "30px" }}
                        /> */}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormGroup>
            </Col>
            <Col lg="3" md="3" sm="3" xs="3" className="mb-3">
              <FormGroup>
                <FormControl fullWidth style={{ "align-items": 'center' }}>
                  <label for="isCharter" style={{ "font-size": '11px', "color": '#000000' }}>Charter?</label>
                  <Input
                    //style = {{paddingTop: '9px', marginLeft: '-20%'}}
                    style={{ marginTop: 'auto', alignSelf: 'stretch' }}
                    disableUnderline='true'
                    defaultChecked={isCharter}
                    id="isCharter"
                    //checked = {isCharter}               
                    value={isCharter}
                    onChange={isCharterToggled}
                    type="checkbox"
                    startAdornment={
                      <InputAdornment position="start">

                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <Row className="text-center px-0 py-2">
            <Col lg="12" md="12" sm="12">
              <Button
                style={{ borderRadius: "5px" }}
                className="btn btn-block btnYellow"
                onClick={(e) => EditFlightDetails()}
              >
                Update Details
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default EditBook;
