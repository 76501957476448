import React, { useEffect, useState, useCallback } from 'react';
import { Container } from 'reactstrap';
import BookingDetails from '../components/booking-details/bookingDetails';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocalStorage } from '../utils/useLocalStorage';
import { useFlightData } from '../data/FlightData';

const MyBookingDetails = () => {
    const [details, setDetails] = useState(null);
    const [user] = useLocalStorage("auth", {});
    const { id } = useParams();
    const { myBookingDetails } = useFlightData();

    const fetchBookingDetails = useCallback(async () => {
        try {
            const data = await myBookingDetails({ userid: user?.userid, booking_id: id }, user);
            if (data?.bookings.length > 0) {
                const parsedData = data?.bookings.map(flight => ({
                    ...flight,
                    flight_details: JSON.parse(flight?.flight_details)
                }));
                setDetails(parsedData[0]);
            }
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    }, [myBookingDetails, user?.userid, id]);

    useEffect(() => {
        fetchBookingDetails();
    }, [fetchBookingDetails]);


    return (
        <Container className="mt-5 py-4" style={{ height: "auto", minHeight: "500px", width: "100%" }}>

            {
                details !== null && details !== undefined && details && (<>

                    <BookingDetails detail={details} />

                </>)
            }
        </Container>
    );
};

export default MyBookingDetails;
