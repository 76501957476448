import React, { useEffect, useState } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import './Testimonials.css'
import { IoMdQuote } from "react-icons/io";
import { NextAndPrev } from '../utils/icon';
const testimonials = [
    {
        key: '1',
        label: 'Robust Inventory',
        content: `"Katana is the first place I look when booking flight reservation.
                They have the most comprehensive inventory of seats and empty
                leg charters." `,
        name: '- William L.'
    },
    {
        key: '2',
        label: 'Affordable Luxury Travel',
        content: `"Discovering Katana opened up the world of private flying to me.
                The platform offers affordable deals on seats and even some
                empty-leg flights. It is easy to use and has made luxury travel
                unexpectedly accessible."`,
        name: '- Stephanie Z.'
    },
    {
        key: '3',
        label: 'Sustainable and Smart Travel',
        content: `"Booking seats on flights that would otherwise fly less than full or
                even empty via Katana has allowed me to travel luxuriously while
                being smart and sustainable. It's a win-win, offering both
                environmental benefits and the unparalleled experience of private
                flying."`,
        name: ' - Wendy H.'
    },
    {
        key: '4',
        label: 'Networking in the Skies',
        content: `"Katana is more than a flight booking service; it's a community
                where I've met fascinating people, leading to new friendships and
                business opportunities. Buying and selling excess seats has
                introduced a unique networking angle to travel."`,
        name: ' - Jason P.'
    },
    {
        key: '5',
        label: 'Maximizing Value and Connections',
        content: `"Listing excess seats on Katana has been easy and beneficial,
                reducing my travel costs. I even met a couple who live in my
                resort community who are now dear friends."`,
        name: ' - Ronaldo S.'
    },
    {
        key: '6',
        label: 'Enhancing Resort Accessibility and Community',
        content: `"As a resort manager, Katana has revolutionized the way our
                residents travel to our property, making it seamless and sociable.
                Its service not only simplifies booking private flights but also
                fosters a vibrant community by connecting residents with shared
                destinations."`,
        name: '- Jamie P.'
    },
];


function Testimonials() {
    useEffect(() => {
        const prevControl = document.querySelector('.carousel-control-prev');
        const nextControl = document.querySelector('.carousel-control-next');
        if (prevControl) prevControl.classList.add(window.innerWidth >= 1200 ? "carousel-control-prev-pc" : "carousel-control-prev-mobile");
        if (nextControl) nextControl.classList.add(window.innerWidth >= 1200 ? "carousel-control-next-pc" : "carousel-control-next-mobile");
    }, [])
    return (
        <>
            <div className='testimonial-cointainer'>
                <div className='testimonial-Customer-Stories'>
                    <p><a href="/About" style={{ color: "yellow" }}>Read more</a></p>
                    <h1>Customer Stories.</h1>
                    <Carousel nextIcon={<NextAndPrev />}
                        prevIcon={<NextAndPrev style={{ transform: 'rotate(180deg)' }} />} indicators={false} className='testimonials-card' interval={5000000000}>
                        {testimonials.map((testimonial) => (
                            <Carousel.Item>

                                <div className='' key={testimonial.key}>
                                    <IoMdQuote color='#FFE000' size={50} style={{ transform: 'rotate(180deg)' }} />
                                    <p>{testimonial.label}</p>
                                    <p>
                                        {testimonial.content}
                                    </p>
                                    <p>{testimonial.name}</p>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div>
                </div>
            </div>
        </>
    );
}

export default Testimonials;