import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import HomePageNYtoSF from "./pages/routes/HomePageNYtoSF";
import MyFlights from "./pages/MyFlights";
import MyProfile from "./pages/MyProfile";
import MyGroups from "./pages/MyGroups";
import MyFriends from "./pages/MyFriends";
import GroupDetails from "./pages/GroupDetails";
import AuthorizeOutlook from "./pages/AuthorizeOutlook";
import AuthorizeGoogle from "./pages/AuthorizeGoogle";
import EditFlightDetails from "./pages/EditFlightDetails";

import { MainLayoutRoute } from "./layouts/MainLayout";

import "./App.css";
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import HowTo from "./pages/HowTo";
import EmptyLegs from "./pages/Empty-Legs"
import Press from "./pages/Press";
import FAQ from "./pages/FAQ";
import Legal from "./pages/Legal";
import ContactUs from "./pages/ContactUs";
import GroupInvitation from "./pages/GroupInvitation";
import ShortCode from "./pages/ShortCode";
import PricingPlans from "./components/PricingPlans";
import Chat from "./pages/chat";
// import Testimonials from "./pages/Testimonials";
import About from "./pages/About";
import MyBookings from "./pages/MyBookings";
import MyBookingsDeatails from "./pages/myBookingDetails";
// import Chat from "./pages/chat";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <MainLayoutRoute
          exact
          path="/"
          component={HomePage}
          title="Home Page"
        />
        <MainLayoutRoute
          exact
          path="/routes/:route"
          component={HomePageNYtoSF}
          title="Home Page"
        />

        <MainLayoutRoute
          exact
          path="/GroupInvitation/:groupid/:groupname"
          component={GroupInvitation}
          title="Group Invitation"
        />
        {/* <MainLayoutRoute
          exact
          path="/home"
          component={HomePage}
          title="Home Page"
          protected={false}
        /> */}
        <MainLayoutRoute
          path="/myFlights"
          component={MyFlights}
          title="My Flights"
          protected={true}
        />
        <MainLayoutRoute
          path="/myBookings"
          component={MyBookings}
          title="My Bookings"
          protected={true}
        />
        <MainLayoutRoute
          path="/myBooking/:id"
          component={MyBookingsDeatails}
          title="My Bookings details"
          protected={true}
        />
        <MainLayoutRoute path="/chat" component={Chat} title="Chat" />
        <MainLayoutRoute path="/chat/:id" component={Chat} title="Chat" />
        <MainLayoutRoute
          path="/myProfile"
          component={MyProfile}
          title="My Profile"
        />
        <MainLayoutRoute
          path="/myGroups"
          component={MyGroups}
          title="My Groups"
          protected={true}
        />
        <MainLayoutRoute
          path="/myFriends"
          component={MyFriends}
          title="My Friends"
          protected={true}
        />
        <MainLayoutRoute
          path="/GroupDetails/:groupid"
          component={GroupDetails}
          title="Group Details"
          protected={true}
        />
        <MainLayoutRoute
          path="/GroupDetails/:groupid"
          component={GroupDetails}
          title="Group Details"
          protected={true}
        />
        <MainLayoutRoute
          path="/AuthorizeOutlook"
          component={AuthorizeOutlook}
          title="Authorize Outlook"
          protected={true}
        />
        <MainLayoutRoute
          path="/Edit/:id"
          component={EditFlightDetails}
          title="Edit Flight"
          protected={true}
        />
        <MainLayoutRoute
          path="/AuthorizeGoogle"
          component={AuthorizeGoogle}
          title="Authorize Outlook"
          protected={true}
        />
        <MainLayoutRoute
          path="/How-to"
          component={HowTo}
          title="How To"
          protected={true}
        />
        <MainLayoutRoute
          path="/empty-leg-flights"
          component={EmptyLegs}
          title="Empty Leg Flights"
          protected={true}
        />
        <MainLayoutRoute
          path="/About"
          component={About}
          title="About"
          protected={true}
        />
        {/* <MainLayoutRoute
          path="/Testimonials"
          component={Testimonials}
          title="Testimonials"
          protected={true}
        /> */}
        <MainLayoutRoute
          path="/Press"
          component={Press}
          title="Press"
          protected={true}
        />
        <MainLayoutRoute
          path="/l/:code"
          component={ShortCode}
          title="Redirect"
          protected={false}
        />
        <MainLayoutRoute
          path="/Plans"
          component={PricingPlans}
          title="Plans"
          protected={true}
        />
        <MainLayoutRoute
          path="/faq"
          component={FAQ}
          title="Faq"
          protected={true}
        />
        <MainLayoutRoute
          path="/legal"
          component={Legal}
          title="Legal"
          protected={true}
        />
        <MainLayoutRoute
          path="/contactus"
          component={ContactUs}
          title="ContactUs"
          protected={true}
        />
        <MainLayoutRoute path="/:key" component={HomePage} title="Home Page" />
        {/* <Redirect from="/logout" to="/home" /> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
