import React,{ useState } from 'react'
import {Snackbar} from '@material-ui/core'
import useGroupData from '../../data/GroupsData'
import { Table, Button,Alert } from "reactstrap";
import {
  FaEnvelope,
  FaMinusCircle,
  FaPhone,
  FaSms,
  FaTrash,
} from "react-icons/fa";
import SweetAlert from 'react-bootstrap-sweetalert';

function MembersList({ membersData, RemoveMember, SetRemoveMemberData }) {
  const [confirmDelete, SetConfirmDelete] = useState(false);
  function ConfirmRemoveMember(memberdata) {
    SetRemoveMemberData(memberdata);
    SetConfirmDelete(true);
  }
  function YesConfirm(isconfirm) {
    if (isconfirm) {
      RemoveMember();
    }
    SetConfirmDelete(false);
  }
  return (
    <div>
      {confirmDelete && (
        <SweetAlert
          custom
          showCancel
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="btnYellow"
          cancelBtnBsStyle="btnYellow"
          onConfirm={(e) => YesConfirm(true)}
          onCancel={(e) => YesConfirm(false)}
          focusCancelBtn
        >
          Are you sure, You want to remove member?
        </SweetAlert>
      )}
      <Table className="align-items-center table-flush" responsive>
        <thead className="bg-dark text-yellow">
          <tr>
            <th
              scope="col"
              colSpan="2"
              style={{ width: "20%" }}
              className="p-1"
            >
              {/* Member Name */}
            </th>
            <th scope="col" style={{ width: "20%" }} className="p-1">
              {/* Mobile */}
            </th>
            <th scope="col" style={{ width: "40%" }} className="p-1" />
          </tr>
        </thead>
        <tbody>
          {membersData &&
            membersData.length > 0 &&
            membersData.map((friend, i) => {
              return (
                <tr key={friend.memberid + i}>
                  <th
                    scope="row"
                    className="pr-0 pl-1"
                    style={{ width: "60px" }}
                  >
                    {friend.profile_pic_path &&
                    friend.profile_pic_path !== "" ? (
                      <img
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        src={friend.profile_pic_path}
                      />
                    ) : (
                      <div
                        className="bg-white pr-0 text-center"
                        style={{
                          height: "45px",
                          width: "45px",
                          border: "3px solid black",
                          borderRadius: "50%",
                        }}
                      >
                        <h2 className="textInitial">
                          {friend.name.split(" ").length > 1
                            ? friend.name.split(" ")[0].substring(0, 1) +
                              friend.name.split(" ")[1].substring(0, 1)
                            : friend.name.substring(0, 2)}
                        </h2>
                      </div>
                    )}
                  </th>
                  <th
                    scope="row"
                    style={{ textTransform: "uppercase" }}
                    className=""
                  >
                    {friend.name}
                  </th>
                  <td scope="row" style={{ textTransform: "uppercase" }}>
                    {friend.mobile}
                  </td>
                  <td className="text-right">
                    {friend.mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "sms:" + friend.mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaSms fontSize="large" />
                      </Button>
                    )}
                    {friend.email && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "mailto:" + friend.email;
                          e.preventDefault();
                        }}
                      >
                        <FaEnvelope fontSize="large" />
                      </Button>
                    )}
                    {friend.mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "tel:" + friend.mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaPhone fontSize="large" />
                      </Button>
                    )}
                    <Button
                      className="btn btn-sm"
                      color="white"
                      onClick={(e) => {
                        ConfirmRemoveMember(friend);
                      }}
                    >
                      <FaTrash fontSize="large" />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default MembersList
