import React, { useState, useEffect } from "react";
import { CardHeader, Row, Col, Input, Button } from "reactstrap";
import { useGroupsData } from "../../data/GroupsData";

function EditGroupData({ user, SetGroupUpdated, editedGroupData, SetIsUpdate }) {
  const { ChangeGroupName } = useGroupsData();
  const [groupName, setGroupName] = useState(editedGroupData.group_name);

  useEffect(() => {
    setGroupName(editedGroupData.group_name);
  }, [editedGroupData]);

  async function UpdateGroup() {
    console.log(editedGroupData);
    const GroupData = {
      userid: parseInt(user.userid, 10),
      mobile: parseInt(user.mobile, 10),
      newgroupname: groupName,
      groupid: editedGroupData.group_id,
    };
    console.log(GroupData);
    const response = await ChangeGroupName(GroupData, user);
    SetGroupUpdated(true);
    setGroupName("");
    SetIsUpdate(false);
  }

  return (
    <>
      <CardHeader className="border-0">
        <Row>
          <Col lg="6" md="6" sm="12">
            <Input
              type="text"
              placeholder="Group Name"
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
            ></Input>
          </Col>
          <Col lg="4" md="6" sm="12">
            <Button color="dark" onClick={UpdateGroup}>
              Update Group
            </Button>
            <Button
              color="dark"
              onClick={(e) => {
                SetIsUpdate(false);
              }}
            >
              Cancle
            </Button>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
}

export default EditGroupData;
