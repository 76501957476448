import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import LoginAndSignUp from "./LoginAndSignUp";
import './PathArea.css'
import { useHistory } from "react-router-dom";
import PlansPopup from "../pages/PlansPopup";

const currentDate = new Date();

function PathArea({ pathType, setPathType, user, OpenLogin }) {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);
  let history = useHistory();
  useEffect(() => {
    setIsHomePage(window.location.pathname === '/');
  }, []);
  const showPlans = () => {
    history.push('/plans');
  }
  return (
    <>
      <PlansPopup
        show={showPopup}
        handleClose={handleClosePopup}
      />
      <Row>
        <Col lg="12" md="12" sm="12">
          <div className={isHomePage ? "PathTypeArea" : "PathTypeArea"}>
            <div
              className={
                pathType === "search"
                  ? isHomePage ? "PathTypeButton-no-border selected-buy" : "PathTypeButton-no-border selected-buy"
                  : isHomePage ? "PathTypeButton-no-border" : "PathTypeButton-no-border"
              }
              onClick={() => setPathType("search")}
            >
              {isHomePage ? "BUY SEATS" : "Buy"}
            </div>
            {typeof user.userid !== "undefined" ? (
              <div
                className={
                  pathType === "book"
                    ? isHomePage ? "PathTypeButton-no-border selected-sell" : "PathTypeButton-no-border selected-sell"
                    : isHomePage ? "PathTypeButton-no-border" : "PathTypeButton-no-border"
                }
                onClick={(e) => {
                  user.subscription_plan_id == 2 || user.subscription_plan_id == 3 ? setPathType("book") : handleShowPopup();
                }}
              >
                {isHomePage ? "SELL SEATS" : "Sell"}
              </div>
            ) : (
              <LoginAndSignUp ButtonMode="PathArea" />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PathArea
