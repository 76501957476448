import axios from "../libs/axios";
const CLIENT_ID = "ac69c4a6-53f3-4d09-b395-f20516f21e1f";
const REDIRECT_URL = process.env.REACT_APP_DOMAIN + "/AuthorizeOutlook";
const AuthUrl =
  "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
const SCOPES = "offline_access%20Contacts.read";

const Authorize = () => {
  var AUTHURL =
    AuthUrl +
    "?client_id=" +
    CLIENT_ID +
    "&redirect_uri=" +
    REDIRECT_URL +
    "&response_type=code&response_mode=query&scope=" +
    SCOPES +
    "&state=" +
    window.location.pathname;
  window.open(AUTHURL, "_self");
  return false;
};

const GetAccessTokenByAuthCode = async (userid, code, user) => {
  const { data } = await axios.request({
    url: "/GetOutlookAccessTokenByAuthCode",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { auth_code: code, userid: userid },
  });
  console.log(data);
  return data;
};

const GetContactListByUserId = async (userid, user) => {
  const { data } = await axios.request({
    url: "/GetOutlookContactsByUserId",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid },
  });
  return data;
};

const SignOutOutlook = async (userid) => {
  const { data } = await axios.request({
    url: "/SignOutOutlook",
    method: "post",
    data: { userid: userid },
  });
  return data;
};

export const useOutlookData = () => ({
  Authorize,
  GetAccessTokenByAuthCode,
  GetContactListByUserId,
  SignOutOutlook,
});   