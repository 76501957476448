import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import '../common.css'

function RouteDescription({fromCity, toCity}) {
  return (
    <>
    {console.log(toCity)}
      <Card style={{ margin: 0, padding: 0, overflowY: "scroll" }}>
        <CardBody>
          {/* <Row>
          <Col lg="12" md="12" sm="12" className="mb-2" style={{borderBottom: "2px solid"}}>
            <h1>Shared Private Jet Flights From {fromCity} to {toCity}</h1>
          </Col>
          </Row> */}
          <Row>          
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Flying Private</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Flying private planes offers numerous benefits over commercial flights, 
            including greater flexibility, convenience, and comfort. 
            With a private jet, you can set your own schedule, fly directly to 
            your destination, and avoid long security lines and crowded airports. 
            Additionally, private planes offer more luxurious amenities such as 
            comfortable seating, gourmet catering, and personalized service. 
            Flying private also allows you to avoid the hassle of lost baggage 
            and the risk of exposure to illness in crowded spaces. Whether for 
            business or leisure travel, flying private is a superior experience 
            that saves time and reduces stress, making it a worthwhile investment 
            for those who value their time and comfort.
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Benefits of Sharing a Private Jet</h2>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Sharing seats on private planes is a cost-effective and eco-friendly 
            way to travel. By flying together, members can split the cost of the 
            flight, reducing the overall expense. Additionally, sharing seats reduces 
            the carbon footprint of travel by utilizing fewer resources than multiple 
            individual flights. Moreover, sharing a private plane can save time by 
            enabling travelers to avoid the long wait times and security lines associated 
            with commercial air travel. Overall, sharing seats on private planes is an 
            excellent option for those who want to enjoy the convenience and luxury of 
            private air travel while minimizing their impact on the environment and 
            their wallet.
            </Col>
          </Row>

          { (toCity == "South Florida") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting South Florida</h2>
            </Col>
          </Row>
          }
          { (toCity == "South Florida") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            South Florida is an enticing destination that attracts visitors from all around the world. 
            With its beautiful beaches, warm weather, and diverse culture, it's no surprise that 
            South Florida is a popular tourist spot. Here are some reasons why you should consider 
            traveling to South Florida:
            <br/>
            <br/>
            <b>Beaches</b>: South Florida is home to some of the world's most beautiful 
            beaches, including Miami Beach, Fort Lauderdale Beach, and Key West 
            Beach. These beaches offer crystal-clear water, soft sand, and a 
            vibrant atmosphere that is perfect for relaxation and fun.
            <br/>
            <br/>
            <b>Weather</b>: With its tropical climate, South Florida is warm and sunny all 
            year round. This means you can enjoy outdoor activities and attractions 
            no matter when you visit.
            <br/>
            <br/>
            <b>Culture</b>: South Florida is a melting pot of cultures, with a vibrant 
            Latino community and a thriving arts scene. From street art to 
            world-class museums, there's always something new to discover in 
            this part of the country.
            <br/>
            <br/>
            <b>Food</b>: South Florida is known for its delicious cuisine, with a mix of 
            Caribbean, Latin, and American flavors. From fresh seafood to Cuban 
            sandwiches, there's something for everyone to enjoy.
            <br/>
            <br/>
            <b>Outdoor activities</b>: Whether you're interested in water sports, hiking, 
            or wildlife watching, South Florida has it all. From the Everglades 
            National Park to the Biscayne National Park, there are plenty of 
            outdoor activities to keep you entertained.
            <br/>
            <br/>
            Overall, South Florida is a destination that offers something for 
            everyone. With its beautiful beaches, warm weather, diverse culture, 
            delicious food, and outdoor activities, it's no wonder that people 
            continue to flock to this part of the country year after year.

            </Col>
          </Row>
          }
          { (toCity == "New York") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting New York</h2>
            </Col>
          </Row>
          }
          { (toCity == "New York") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            New York City is one of the most vibrant and exciting places in the world to visit. 
            Whether you are interested in art, culture, history, or just want to experience 
            the hustle and bustle of a big city, New York has something for everyone. Here 
            are some of the top reasons why you should consider traveling to New York:
            <br/>
            <br/>
            Firstly, New York City is home to some of the world's most famous landmarks, 
            including the Statue of Liberty, the Empire State Building, and Central Park. 
            These iconic sites are not only awe-inspiring but also offer incredible views 
            and photo opportunities.
            <br/>
            <br/>
            Secondly, New York City is a melting pot of cultures, with neighborhoods dedicated 
            to different ethnic groups, each with its own unique cuisine, traditions, and 
            celebrations. You can experience the world without ever leaving the city by 
            exploring Chinatown, Little Italy, or Harlem.
            <br/>
            <br/>
            Thirdly, New York is a hub for art and culture, with world-class museums like 
            the Metropolitan Museum of Art and the Museum of Modern Art, as well as Broadway
             shows and live music performances.
             <br/>
             <br/>
            Finally, New York is a shopper's paradise, with everything from high-end
             boutiques to vintage thrift stores. Plus, the city is constantly 
             evolving, with new restaurants, galleries, and attractions popping up
              all the time.
            <br/>
            <br/>
            In short, New York City is an exciting and diverse destination that should 
            be on everyone's travel bucket list.

            </Col>
          </Row>
          }
          { (toCity == "Palm Beach") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Palm Beach</h2>
            </Col>
          </Row>
          }
          { (toCity == "Palm Beach") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Palm Beach, Florida, is a must-visit destination for those seeking sun,
             sand, and relaxation. Located on a barrier island along the Atlantic 
             Coast, Palm Beach boasts 16 miles of pristine beaches, warm weather 
             year-round, and a plethora of outdoor activities to enjoy.
             <br/>
             <br/>
            One of the main draws of Palm Beach is its luxurious and upscale vibe. 
            The area is home to some of the most expensive real estate in the 
            country, with grand mansions, high-end boutiques, and fine dining 
            restaurants lining the streets. However, there are also plenty of 
            affordable options for those on a budget, making it accessible to all.
            <br/>
            <br/>
            In addition to its glamorous reputation, Palm Beach also offers a wide 
            range of outdoor activities, such as snorkeling, scuba diving, fishing, 
            and kayaking. Visitors can also take a stroll through the Palm Beach 
            Zoo, visit the Flagler Museum, or explore the local art galleries.
            <br/>
            <br/>
            For golf enthusiasts, Palm Beach is a dream destination with over 160 
            courses to choose from, including some designed by legendary golfers 
            such as Jack Nicklaus and Arnold Palmer. And for those who simply want 
            to relax and soak up the sun, there are numerous beaches to choose 
            from, each with its own unique charm.
            <br/>
            <br/>
            Overall, Palm Beach, Florida is a destination that should be on every 
            traveler's bucket list. With its beautiful beaches, luxurious 
            atmosphere, and endless activities, it's the perfect place to escape 
            and unwind.

            </Col>
          </Row>
          }
          { (toCity == "Teterboro") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Teterboro</h2>
            </Col>
          </Row>
          }
          { (toCity == "Teterboro") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Teterboro Airport is a popular choice for private and corporate jets, 
            and there are several benefits to flying into this airport versus 
            larger commercial airports. Firstly, Teterboro is conveniently located 
            just 12 miles from Manhattan, making it an excellent choice for those 
            traveling to New York City. This means that travelers can avoid the 
            traffic and congestion associated with larger airports, and arrive at 
            their destination more quickly.
            <br/>
            <br/>
            Another advantage of Teterboro Airport is its smaller size. This means that there 
            are typically fewer crowds and shorter lines, allowing travelers to move through 
            the airport more quickly and efficiently. Additionally, the smaller size of 
            Teterboro means that there are more flexible schedules and fewer delays, making 
            it a more convenient option for those who need to arrive or depart on a tight 
            schedule.
            <br/>
            <br/>
            For those who are looking for privacy and exclusivity, Teterboro Airport 
            is an excellent choice. The airport caters exclusively to private and 
            corporate jets, which means that travelers can avoid the hustle and bustle 
            of larger commercial airports and enjoy a more personalized experience.
            <br/>
            <br/>
            Overall, flying into Teterboro Airport offers several advantages over 
            larger commercial airports. Whether you are looking for convenience, 
            flexibility, or exclusivity, Teterboro is an excellent choice for your 
            next trip.

            </Col>
          </Row>
          }
          { (toCity == "Westchester") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Westchester</h2>
            </Col>
          </Row>
          }
          { (toCity == "Westchester") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Westchester County, New York, is a fantastic travel destination for 
            those looking to experience the best of both worlds: the bustle of 
            city life and the charm of nature. With its close proximity to 
            New York City, visitors can easily access all the attractions and 
            events of the city while also enjoying the tranquility of Westchester 
            County's lush forests and rolling hills.
            <br/>
            <br/>
            One of the top reasons to travel to Westchester County is its rich 
            history. The county played a significant role in the American 
            Revolution, and visitors can explore historic sites such as 
            Washington's Headquarters State Historic Site and the Old Dutch 
            Church of Sleepy Hollow.
            <br/>
            <br/>
            Westchester County is also a foodie's paradise, boasting a diverse 
            culinary scene that includes farm-to-table restaurants, authentic 
            ethnic cuisine, and award-winning vineyards. From fresh seafood to 
            artisanal cheese, the county's restaurants and markets offer an 
            array of delicious and locally sourced options.
            <br/>
            <br/>
            For outdoor enthusiasts, Westchester County is a paradise. 
            The county is home to numerous parks, trails, and nature preserves, 
            offering endless opportunities for hiking, biking, fishing, and 
            wildlife watching. Visitors can also enjoy water activities such 
            as kayaking and paddleboarding on the Hudson River.
            <br/>
            <br/>
            Whether you're looking for a relaxing weekend getaway or an 
            action-packed adventure, Westchester County, New York, has 
            something to offer everyone. Its proximity to New York City, 
            rich history, diverse culinary scene, and outdoor recreation 
            opportunities make it a must-visit destination for travelers.

            </Col>
          </Row>
          }
          { (toCity == "Aspen") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Aspen</h2>
            </Col>
          </Row>
          }
          { (toCity == "Aspen") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Aspen, Colorado is a picturesque mountain town nestled in the heart 
            of the Rocky Mountains. This world-renowned destination is a mecca 
            for outdoor enthusiasts, adventure seekers, and culture enthusiasts 
            alike. With its stunning scenery, outdoor activities, and cultural 
            offerings, Aspen is the perfect destination for travelers seeking a 
            unique and unforgettable experience.
            <br/>
            <br/>
            One of the main reasons to visit Aspen is for its exceptional skiing 
            and snowboarding. With four mountains to choose from, there are 
            endless opportunities for skiing and snowboarding, no matter your 
            skill level. In addition to skiing, Aspen also offers a variety of 
            other winter sports, including snowshoeing, ice skating, and sleigh 
            rides.
            <br/>
            <br/>
            Aspen is also known for its stunning natural beauty. From the 
            breathtaking views of the Maroon Bells to the serene tranquility 
            of the Aspen groves, there is no shortage of natural wonders to 
            explore in Aspen. Additionally, Aspen is home to a number of 
            world-class cultural offerings, including the Aspen Art Museum, 
            the Wheeler Opera House, and the Aspen Music Festival.
            <br/>
            <br/>
            Whether you're looking for outdoor adventure, cultural experiences, 
            or simply a relaxing escape, Aspen has something for everyone. With 
            its stunning scenery, endless outdoor activities, and world-class 
            cultural offerings, Aspen is a destination that should be on 
            everyone's bucket list. So pack your bags and come experience 
            the magic of Aspen for yourself!


            </Col>
          </Row>
          }
          { (toCity == "Los Angeles") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Los Angeles</h2>
            </Col>
          </Row>
          }
          { (toCity == "Los Angeles") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Los Angeles, California is a must-visit destination for anyone who 
            loves adventure, excitement, and unforgettable experiences. This 
            vibrant city is the second-largest in the United States and is 
            known for its sunny weather, beautiful beaches, and thriving 
            entertainment industry.
            <br/>
            <br/>
            One of the top reasons to visit Los Angeles is the opportunity to 
            see iconic landmarks like the Hollywood Sign, Beverly Hills, and 
            the Santa Monica Pier. These landmarks offer unique photo opportunities 
            that you won't find anywhere else in the world.
            <br/>
            <br/>
            Another reason to visit Los Angeles is the diverse range of cuisines 
            available. The city is a melting pot of cultures, with a thriving food 
            scene that includes everything from street tacos to Michelin-starred 
            restaurants.
            <br/>
            <br/>
            Los Angeles is also home to some of the best museums and art galleries 
            in the world, including the Getty Center, LACMA, and the Museum of 
            Contemporary Art. These venues offer visitors the chance to explore 
            world-class art collections and exhibitions.
            <br/>
            <br/>
            Finally, Los Angeles is a gateway to some of the most beautiful 
            natural scenery in the world, including the stunning coastline 
            and nearby national parks. Visitors can take a short drive to 
            explore the Santa Monica Mountains, hike through the Angeles 
            National Forest, or take a day trip to the breathtaking Joshua 
            Tree National Park.
            <br/>
            <br/>
            In conclusion, Los Angeles, California is a destination that offers 
            something for everyone. Whether you're a foodie, art lover, nature 
            enthusiast, or just looking for some fun in the sun, this vibrant 
            city is the perfect place to visit.


            </Col>
          </Row>
          }
          { (toCity == "Dallas") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Dallas</h2>
            </Col>
          </Row>
          }
          { (toCity == "Dallas") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Dallas, Texas is a vibrant and diverse city that offers a unique 
            blend of southern hospitality and urban sophistication. With its 
            world-class museums, bustling downtown area, and iconic landmarks, 
            Dallas is a must-visit destination for travelers from all over the 
            world.
            <br/>
            <br/>
            One of the top reasons to visit Dallas is its rich history and culture. 
            The city is home to numerous museums and cultural institutions, 
            including the Dallas Museum of Art, the Perot Museum of Nature and 
            Science, and the Sixth Floor Museum at Dealey Plaza. These 
            institutions showcase the diverse history and heritage of Dallas 
            and its people.
            <br/>
            <br/>
            In addition to its cultural offerings, Dallas is also a hub for 
            business and technology. The city boasts a thriving economy, 
            with numerous opportunities for networking and professional 
            development. Whether you're a seasoned entrepreneur or just 
            starting out in your career, Dallas is the perfect place to 
            make connections and gain valuable experience.
            <br/>
            <br/>
            Of course, no visit to Dallas would be complete without sampling 
            some of the city's famous cuisine. From barbecue and Tex-Mex to 
            upscale dining options, Dallas has something to offer every foodie. 
            And with its warm climate and abundant outdoor spaces, it's the 
            perfect place to enjoy a meal al fresco.
            <br/>
            <br/>
            Overall, whether you're interested in history and culture, business 
            and technology, or simply good food and fun, Dallas is the perfect 
            destination for your next travel adventure.


            </Col>
          </Row>
          }
          { (toCity == "Cabo San Lucas") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Cabo San Lucas</h2>
            </Col>
          </Row>
          }
          { (toCity == "Cabo San Lucas") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Cabo San Lucas is a breathtaking destination located on the 
            southern tip of the Baja California peninsula in Mexico. With 
            its stunning beaches, crystal-clear waters, and warm weather, 
            it's no wonder why this destination has become increasingly 
            popular among travelers in recent years. In this article, 
            we'll explore some of the top reasons why you should consider 
            traveling to Cabo San Lucas for your next vacation.
            <br/>
            <br/>
            Firstly, Cabo San Lucas boasts some of the most beautiful beaches 
            in the world, such as Medano Beach, Chileno Beach, and Lover's Beach. 
            Whether you're looking to swim, sunbathe, or surf, you'll find the 
            perfect beach for your needs.
            <br/>
            <br/>
            Secondly, Cabo San Lucas is a prime destination for water sports 
            enthusiasts. With its clear waters and abundant marine life, the 
            area is perfect for snorkeling, scuba diving, fishing, and more.
            <br/>
            <br/>
            Thirdly, the town itself is vibrant and full of culture. Visitors 
            can explore historic landmarks like the Cabo San Lucas Arch, shop 
            for souvenirs at local markets, and indulge in delicious cuisine 
            at the many restaurants and street food vendors.
            <br/>
            <br/>
            Finally, Cabo San Lucas is a popular destination for nightlife, 
            with a variety of bars, nightclubs, and beach parties to choose 
            from. Whether you're looking for a relaxing vacation or a wild 
            party scene, Cabo San Lucas has something for everyone.
            <br/>
            <br/>
            In conclusion, Cabo San Lucas is a must-visit destination for 
            anyone seeking sun, sand, and adventure. With its beautiful 
            beaches, water sports, cultural attractions, and nightlife, 
            it's easy to see why this destination has become a top choice 
            for travelers from around the world.

            </Col>
          </Row>
          }
          { (toCity == "San Francisco") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting San Francisco</h2>
            </Col>
          </Row>
          }
          { (toCity == "San Francisco") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            San Francisco, California is a travel destination that should be on 
            every traveler's bucket list. From its iconic landmarks to its 
            diverse culture and culinary scene, San Francisco offers a unique 
            experience that cannot be found anywhere else in the world.
            <br/>
            <br/>
            One of the main draws of San Francisco is its iconic landmarks 
            such as the Golden Gate Bridge, Alcatraz Island, and Fisherman's 
            Wharf. These landmarks provide an opportunity to take stunning 
            photographs and create lasting memories. In addition, 
            San Francisco's hills provide breathtaking views of the city 
            and its surroundings.
            <br/>
            <br/> 
            Another reason to visit San Francisco is its diverse culture. 
            The city is home to many ethnic neighborhoods such as Chinatown 
            and Little Italy, where visitors can immerse themselves in the 
            culture and experience unique traditions and cuisine. 
            San Francisco is also known for its LGBTQ+ friendly community 
            and its vibrant nightlife.
            <br/>
            <br/>
            San Francisco is also a foodie's paradise, with a diverse 
            culinary scene that ranges from traditional seafood to trendy 
            fusion dishes. Visitors can indulge in local favorites such as 
            sourdough bread, clam chowder, and Dungeness crab, or explore 
            the city's trendy restaurants and cafes.
            <br/>
            <br/>
            Overall, San Francisco is a must-visit destination for any traveler 
            looking for a unique and unforgettable experience. Its iconic 
            landmarks, diverse culture, and culinary scene make it a 
            one-of-a-kind destination that will leave a lasting impression.

            </Col>
          </Row>
          }
          { (toCity == "Fort Lauderdale") &&
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h2>Visiting Fort Lauderdale</h2>
            </Col>
          </Row>
          }
          { (toCity == "Fort Lauderdale") &&
          <Row className="mb-4">
            <Col lg="12" md="12" sm="12">
            Fort Lauderdale, Florida is a popular travel destination for tourists 
            from all around the world, and it's not hard to see why. With miles 
            of beautiful beaches, a thriving arts and culture scene, and a wide 
            range of outdoor activities, Fort Lauderdale has something for 
            everyone. In this article, we'll explore some of the top reasons 
            why you should consider traveling to Fort Lauderdale.
            <br/>
            <br/>
            First and foremost, Fort Lauderdale is known for its stunning beaches. 
            The city boasts over 23 miles of pristine coastline, including 
            popular spots like Fort Lauderdale Beach and Las Olas Beach. 
            These beaches offer crystal-clear waters, soft sand, and plenty 
            of opportunities for swimming, sunbathing, and water sports.
            <br/>
            <br/>
            In addition to its beaches, Fort Lauderdale is also home to a 
            thriving arts and culture scene. The city is home to numerous 
            museums, galleries, and performance venues, including the NSU 
            Art Museum Fort Lauderdale and the Broward Center for the 
            Performing Arts. Visitors can also explore the city's many 
            public art installations and attend a variety of festivals 
            and cultural events throughout the year.
            <br/>
            For outdoor enthusiasts, Fort Lauderdale offers plenty of 
            opportunities for hiking, biking, and water sports. The city 
            is home to several parks and nature preserves, including the 
            Hugh Taylor Birch State Park and the Anne Kolb Nature Center. 
            Visitors can also go fishing, kayaking, and paddleboarding in 
            the city's many waterways.
            <br/>
            <br/>
            Finally, Fort Lauderdale is known for its excellent dining and 
            nightlife scenes. The city boasts a wide range of restaurants 
            and bars, from casual beachfront cafes to upscale fine dining 
            establishments. Visitors can also enjoy live music and dancing 
            at many of the city's nightclubs and music venues.
            <br/>
            <br/>
            In conclusion, Fort Lauderdale, Florida is a fantastic travel 
            destination for anyone looking to enjoy beautiful beaches, 
            rich culture, outdoor activities, and excellent dining and 
            nightlife. So, pack your bags and head to Fort Lauderdale 
            for an unforgettable vacation experience!

            </Col>
          </Row>
          }

        </CardBody>
      </Card>
    </>
  );
}

export default RouteDescription;
