import React, { useEffect, useState } from 'react';
import { useFlightData } from '../data/FlightData';
import DuffelFlights from '../components/DuffelFlights';
import { Alert, Container } from 'reactstrap';
import { useLocalStorage } from "../utils/useLocalStorage";
import { CircularProgress, Snackbar } from '@material-ui/core';

const MyBookings = () => {
    const [flights, setFlights] = useState([]);
    const [loading, setLoading] = useState(true);  // New loading state
    const { myBookings } = useFlightData();
    const [user] = useLocalStorage("auth", {});

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const data = await myBookings(user?.userid || 1, user);
                setFlights(data?.bookings);
            } catch (error) {
                console.error('Error fetching bookings:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, [myBookings, user?.userid, user]);

    return (
        <Container className="mt-5 py-4" style={{ height: "auto", minHeight: "500px", width: "100%" }}>
            {
                loading ? (
                    // <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "500px" }}>
                    //     <h3 className="display-4" style={{ color: "gray" }}>Loading...</h3>
                    // </div>
                    <Snackbar
                        color="dark"
                        open={true}
                        anchorOrigin={{ horizontal: "center", vertical: "top" }}
                        keyboard={false}
                        style={{ height: "100%", width: "100%" }}
                    >
                        <Alert
                            className="text-center"
                            color="dark"
                            style={{ color: "rgb(255, 230, 0)" }}
                        >
                            <CircularProgress
                                style={{ color: "rgb(255, 230, 0)" }}
                            ></CircularProgress>
                            <br />
                            {"Loading...!!"}
                        </Alert>
                    </Snackbar>
                ) : (
                    flights.length > 0 ? (
                        <DuffelFlights flights={flights} />
                    ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "500px" }}>
                            <h3 className="display-4" style={{ color: "gray" }}>You have no bookings</h3>
                        </div>
                    )
                )
            }
        </Container>
    );
};

export default MyBookings;
