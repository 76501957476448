import axios from "../libs/axios";

const MyGroups = async (userid, user) => {
  const { data } = await axios.request({
    url: "/SeeCreatedGroups",
    method: "post",
    data: { userid: userid },
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
  });
  return data;
}

const JoinedGroup = async (userid, user) => {
  const { data } = await axios.request({
    url: "/SeeJoinedGroups",
    method: "post",
    data: { userid: userid },
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
  });
  return data;
};

const CreateGroup = async (groupData, user) => {
  const { data } = await axios.request({
    url: "/CreateGroup",
    method: "post",
    data: groupData,
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
  });
  return data;
};

const DeleteGroupApi = async (groupData, user) => {
  const { data } = await axios.request({
    url: "/DeleteGroup",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: groupData,
  });
  return data;
};

const ChangeGroupName = async (groupData, user) => {
  const { data } = await axios.request({
    url: "/ChangeGroupName",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: groupData,
  });
  return data;
};

const GroupMembers = async (groupid, user) => {
  const { data } = await axios.request({
    url: "/SeeGroupMembers",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { groupid: groupid },
  });
  return data;
}

const GroupDetailsApi = async (groupid) => {
  const { data } = await axios.request({
    url: "/SeeGroupDetails",
    method: "post",
    data: { groupid: groupid },
  });
  return data;
};

const InviteGroupMember = async (groupData, user) => {
  console.log(groupData);
  const { data } = await axios.request({
    url: "/InviteGroupMember",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: groupData,
  });
  return data;
};

const AddGroupMember = async (groupData, user) => {
  console.log(groupData);
  const { data } = await axios.request({
    url: "/CreateGroupConnection",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: groupData,
  });
  return data;
};

const GroupProfilePicture = async (groupData) => {
  const { data } = await axios.request({
    url: "/UpdateGroupProfilePic",
    method: "post",
    data: groupData,
  });
  return data;
};

const RemoveGroupMember = async (userid, memberid, user) => {
  const { data } = await axios.request({
    url: "/RemoveGroupMember",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid, member_id: memberid },
  });
  return data;
}

const LeaveGroup = async (memberid) => {
  const { data } = await axios.request({
    url: "/LeaveGroup",
    method: "post",
    data: { memberid: memberid },

  });
  return data;
}

export const useGroupsData = () => ({
  MyGroups,
  CreateGroup,
  DeleteGroupApi,
  ChangeGroupName,
  JoinedGroup,
  GroupMembers,
  GroupDetailsApi,
  InviteGroupMember,
  AddGroupMember,
  GroupProfilePicture,
  RemoveGroupMember,
  LeaveGroup,
});
