import React, { useState } from "react";
import { FaEdit, FaEye, FaTrash, FaUserPlus, FaSms, FaPhone } from 'react-icons/fa';
import { useGroupsData } from '../../data/GroupsData';
import { useHistory } from 'react-router-dom'
import {
  Table,
  Button,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { IoMdChatbubbles } from "react-icons/io";
import { logDOM } from "@testing-library/react";

function GroupList({
  groupData,
  DeleteGroup,
  EditGroup,
  isallowEditAndDelete,
  GetJoinedGroups,
}) {
  const history = useHistory();
  const { LeaveGroup } = useGroupsData();
  const [leaveGroupData, setLeaveGroupData] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  function AddMember(event, data) {
    history.push("/GroupDetails/" + data.group_id);
  }

  function ConfirmLeaveGroup(group) {
    setConfirmDelete(true);
    setLeaveGroupData(group);
  }

  function YesConfirm(isconfirm) {
    if (isconfirm) {
      Leavegroup();
    }
    setConfirmDelete(false);
  }

  async function Leavegroup() {
    // console.log(leaveGroupData);
    const data = await LeaveGroup(leaveGroupData.member_id);
    if (data.status) {
      GetJoinedGroups();
    }
  }
  const handelGroupChat = (data) => {
    console.log({ is_group: true, ...data }, "groupData");
    // localStorage.setItem("groupData", JSON.stringify({ is_group: true, ...data }));
    localStorage.setItem("ModalData", JSON.stringify({ is_group: true, ...data }))
    const url = `/chat`;
    window.open(url, "_self", "noopener,noreferrer");
  }
  return (
    <div>
      {confirmDelete && (
        <SweetAlert
          custom
          showCancel
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="btnYellow"
          cancelBtnBsStyle="btnYellow"
          onConfirm={(e) => YesConfirm(true)}
          onCancel={(e) => YesConfirm(false)}
          focusCancelBtn
        >
          Are you sure, You want to leave this group?
        </SweetAlert>
      )}
      <Table className="align-items-center table-flush" responsive>
        <thead className="bg-dark text-yellow">
          <tr>
            <th
              scope="col"
              className="p-1"
              colSpan="2"
              style={{ width: "20%" }}
            >
              {/* Group Name */}
            </th>
            <th scope="col" className="p-1" style={{ width: "20%" }}>
              {/* Members */}
            </th>
            <th
              scope="col"
              className="p-1"
              colSpan="2"
              style={{ width: "40%" }}
            >
              {/* Members */}
            </th>
            {isallowEditAndDelete && <th scope="col" className="p-1" />}
          </tr>
        </thead>
        <tbody>
          {groupData &&
            groupData.length > 0 &&
            groupData.map((group) => {
              return (
                <tr key={group.group_id}>
                  <th
                    scope="row"
                    className="pr-0 pl-1"
                    style={{ width: "60px" }}
                  >
                    {group.profile_pic_path && group.profile_pic_path !== "" ? (
                      <img
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        src={group.profile_pic_path}
                      />
                    ) : (
                      <div
                        className="bg-white pr-0 text-center"
                        style={{
                          height: "45px",
                          width: "45px",
                          border: "3px solid black",
                          borderRadius: "50%",
                        }}
                      >
                        <h2 className="textInitial">
                          {group.group_name.split(" ").length > 1
                            ? group.group_name.split(" ")[0].substring(0, 1) +
                            group.group_name.split(" ")[1].substring(0, 1)
                            : group.group_name.substring(0, 2)}
                        </h2>
                      </div>
                    )}
                  </th>
                  <th scope="row" style={{ textTransform: "uppercase" }}>
                    {group.group_name}
                  </th>
                  <th>{group.owner_mobile}</th>
                  <td className="text-left">{group.members_count}</td>
                  <td className="text-right">
                    {group.owner_mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={() => handelGroupChat(groupData[0])}
                      >
                        <IoMdChatbubbles fontSize="large" />
                      </Button>
                    )}
                    {group.owner_mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "sms:" + group.owner_mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaSms fontSize="large" />
                      </Button>
                    )}

                    {group.owner_mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "tel:" + group.owner_mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaPhone fontSize="large" />
                      </Button>
                    )}
                    <Button
                      className="btn btn-sm"
                      color="white"
                      onClick={(e) => {
                        ConfirmLeaveGroup(group);
                      }}
                    >
                      <FaTrash fontSize="large" />
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default GroupList
