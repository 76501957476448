import React, { useState } from "react";
import { FaAngleDoubleDown } from 'react-icons/fa';
// import "./CardComponent.css";
import image1 from "../assets/footerimage/marketwatch-ar21.png"
import image2 from "../assets/footerimage/logo-white-panews.webp"
import image3 from "../assets/footerimage/Timage.jpg"
import image4 from "../assets/footerimage/pngegg.png"
import Testimonials from "../pages/Testimonials";
const CardComponent = () => {
  const [expandedCard, setExpandedCard] = useState(-1); // Initial value -1 means no card expanded
  const data = [
    {
      title: "BROWSE",
      imageSrc: require("../assets/images/Browse.jpg").default,
      description:
        "Katana's comprehensive database empowers you to effortlessly locate available private plane seats for purchase, uncover enticing empty leg charter opportunities at competitive rates, and effortlessly list any surplus seats from your private charter for sale.",
    },
    {
      title: "BOOK",
      imageSrc: require("../assets/images/Book.jpg").default,
      description:
        "Katana easily connects you with the carrier, member, or operator of the flight you've chosen. From there, you can simply select the number of seats you require, arrange payment, and you're ready to go!",
    },
    {
      title: "RELAX",
      imageSrc: require("../assets/images/Relax.jpg").default,
      description:
        "Reach your destination more quickly, conveniently, and affordably by sharing with fellow Katana Members.",
    },
  ];
  const footerimage = [
    {
      src: image1,
      alt: "Image 1"
    },
    {
      src: image2,
      alt: "Image 2"
    },
    {
      src: image3,
      alt: "Image 3"
    }, {
      src: image4,
      alt: "image 4"
    }
  ];

  return (
    <div className="home-card-container">
      <>
        {data.map((item, index) => (
          <div
            key={index}
            className={`homecard ${expandedCard === index ? "expanded" : ""}`}
          >
            <div className="home-card-image-wrapper">
              <img src={item.imageSrc} alt={item.altText} />
              <div className="overlay-placeholder"></div>

              <h3 className="home-card-title">{item.title}</h3>
              <div
                className={`home-card-arrow-icon ${expandedCard === index ? "expanded" : ""
                  }`}
                onClick={() =>
                  setExpandedCard(expandedCard === index ? -1 : index)
                }
              >
                <FaAngleDoubleDown />
              </div>
            </div>
            <div className="home-card-content">
              <div className="home-card-description">{item.description}</div>
            </div>
          </div>
        ))}
          <div style={{padding:'5px'}}>
          <div>
            <h2><strong>Empty Legs</strong></h2>
            <p>An empty leg, also known as a deadhead or ferry flight, is a flight operated by a private jet without passengers on board. These flights occur when the private jet drops off passengers at their destination and needs to return to its original location empty. The empty-leg flight can then be available for charter at a reduced cost.</p>
            <p>Empty-leg flights present a unique opportunity for travelers to experience the luxury of private jet travel at a significantly reduced cost. This benefits the traveler (you) and allows the private jet company to optimize its resources. It's a win-win situation that we at Katana are proud to offer.</p>
            <p>At Katana, our goal is better resource utilization. Therefore, we aim to fill not only empty seats but also empty planes. Up to 40% of private planes depart without passengers due to required repositioning. Our goal is to eliminate this waste to benefit our Members and Partners.</p>
          </div>
          <div>
            <h2><strong>Saving Money with Empty Legs</strong></h2>
            <p>Booking an empty-leg flight with Katana is a smart financial move that not only saves you money but also provides unmatched flexibility and convenience. The search results are either price certain or 'Upon Request.' Price certain typically excludes Federal Excise Taxes (7.5%) and fuel surcharge (if any); when we confirm availability, we will typically get you a price all-in. If the cost is 'Upon Request,' Katana will reach out and negotiate the best possible price. The outcome will largely depend upon the supply and demand dynamics. Often times you can choose a nearby airport, but the operator will typically charge for the flight time and landing fees. </p>
            <p>In conclusion, booking an empty-leg flight is an excellent way to experience private jet travel at a fraction of the cost. So why wait? Contact us today to learn more about how we can help you take advantage of the benefits of an empty-leg flight and make your travel experience more enjoyable and cost-effective.</p>
          </div>
        </div>
        <div style={{ width: '100%' }}><Testimonials /></div>
        <div className="Footer-img-logo">
          {footerimage.map((image, index) => (
            <img
              key={index}
              src={image.src}
              alt={image.alt}
            />
          ))}
        </div>
      </>
    </div>
  );
};

export default CardComponent;