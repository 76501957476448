import { useEffect, useState } from "react";
import { Col, Row, Button, Alert } from "reactstrap";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  FormGroup,
  Popover,
  Typography,
  Checkbox
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useFlightData } from "../../data/FlightData";
import City from "../../components/City";
import airportData from "../../data/airport";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { FaChair, FaClock, FaDollarSign, FaPlane } from "react-icons/fa";
import { GiSofa } from "react-icons/gi";
import SwapImage from "../../assets/images/icons/swap.png";
import * as React from 'react';
import HelpIcon from '@mui/icons-material/Help';
import PlansPopup from "../../pages/PlansPopup";

function Book({ pageName }) {
  const currentDate = new Date();
  const { postFlight } = useFlightData();

  const [from, setFrom] = useState("HTO");
  const [to, setTo] = useState("TNT");
  const [datevalue, setDateValue] = useState("");
  const [time, setTime] = useState(0);
  const [planeType, setPlaneType] = useState("");
  const [formatteddatevalue, setFormattedDateValue] = useState("");
  const [total, setTotal] = useState("");
  const [available, setAvailable] = useState("");
  const [price, setPrice] = useState("");
  const [user] = useLocalStorage("auth", {});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFlightPosted, setFlightPosted] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isCharter, setIsCharter] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const OpenPopup = () => {
    document.getElementById("isCharter").checked = false;
    handleShowPopup();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setDateValue(currentDate.toISOString());
    setFormattedDateValue(getFormatedDate(currentDate.toISOString()));
    setTime(GetFormatedTime(currentDate));
    console.log(GetFormatedTime(currentDate));
  }, []);

  function GetFormatedTime(date) {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    var h = addZero(date.getHours());
    var m = addZero(date.getMinutes());
    return h + ":" + m;
  }

  function getFormatedDate(strDate) {
    let date = new Date(strDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return month + "/" + dt + "/" + year;
  }

  function SwapCity(e) {
    const From = from;
    setFrom(to);
    setTo(From);
  }

  function isCharterToggled(e) {

    isCharter == true ? setIsCharter(false) : setIsCharter(true)
    console.log(isCharter);

  }

  function getCityFromCode(code) {
    return airportData.find((item) => item.IATA === code).city;
  }

  const DateChange = (date) => {
    setFormattedDateValue(getFormatedDate(date));
    setDateValue(date);
    setTime(GetFormatedTime(date));
  };

  async function BookFlight(event) {
    let postData = {
      mobile: user.mobile,
      userid: user.userid,
      dep_date: formatteddatevalue,
      dep_time: time,
      from_city: getCityFromCode(from),
      from_airport: from,
      to_city: getCityFromCode(to),
      to_airport: to,
      planetype: planeType,
      totalseats: null,
      is_private: isPrivate,
      is_charter: isCharter,
      availableseats:
        available === "" || available === undefined || available === null
          ? 0
          : parseInt(available, 10),
      Totalprice:
        price === "" || price === undefined || price === null
          ? 0
          : parseInt(price, 10),
    };

    if (ValidateData()) {
      console.log(postData);
      const response = await postFlight(postData, user);
      if (response[0].fid > 0) {
        setFlightPosted(true);
        setErrorMessage("");
        setIsError(false);
        setTime("");
        setPlaneType("");
        setTotal("");
        setAvailable("");
        setPrice("");
      } else {
        setErrorMessage("There is something problem to book flight.");
        setIsError(true);
      }
    }
  }

  function ValidateData() {
    if (from === "" || from === undefined || from === null) {
      setErrorMessage("Please select from");
      setIsError(true);
      return false;
    } else if (to === "" || to === undefined || to === null) {
      setErrorMessage("Please select to");
      setIsError(true);
      return false;
    } else if (
      formatteddatevalue === "" ||
      formatteddatevalue === undefined ||
      formatteddatevalue === null
    ) {
      setErrorMessage("Please select valid date");
      setIsError(true);
      return false;
    } else if (time === "" || time === undefined || time === null) {
      setErrorMessage("Please select time");
      setIsError(true);
      return false;
    } else if (
      planeType === "" ||
      planeType === undefined ||
      planeType === null
    ) {
      setErrorMessage("Please enter plane type");
      setIsError(true);
      return false;
    } else if (
      available === "" ||
      available === undefined ||
      available === null ||
      available == "0" ||
      available <= 0
    ) {
      setErrorMessage("Please enter valid seats");
      setIsError(true);
      return false;
    } else if (
      price === "" ||
      price === undefined ||
      price === null ||
      price === "0" ||
      price <= 0
    ) {
      setErrorMessage("Please enter valid price");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }

  return (
    <>
      <PlansPopup show={showPopup} handleClose={handleClosePopup} />
      <Row>
        <Col lg="12" md="12" sm="12">
          {isError && <Alert className="alertBlue">{errorMessage}</Alert>}
          {isFlightPosted && (
            <Alert className="alertBlue">
              Your seats are listed for sale.
            </Alert>
          )}
        </Col>
      </Row>
      <Row className="pl-1 pr-1">
        <Col lg={pageName === "HomePage" ? "12" : "11"} md={pageName === "HomePage" ? "12" : "11"} sm={pageName === "HomePage" ? "12" : "11"} xs={pageName === "HomePage" ? "12" : "10"}>
          <City type="from" code={from} setCode={setFrom} Page="Book" />
        </Col>
        {pageName !== "HomePage" && (
          <Col
            lg="1"
            md="1"
            sm="1"
            xs="1"
            className="d-flex justify-content-center align-items-center mt-3 mt-lg-0"
          >
            <img src={SwapImage} className="btnSwap" onClick={SwapCity} />
          </Col>
        )}
      </Row>
      {pageName === "HomePage" && (
        <Row style={{ height: "30px" }}>
          <Col
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="d-flex justify-content-center align-items-center mt-3 mt-lg-0"
          >
            <img src={SwapImage} className="btnSwapNotRotate" onClick={SwapCity} />
          </Col>
        </Row>
      )}
      <Row className="pl-1 pr-1">
        <Col lg="12" md="12" sm="12">
          <City type="to" code={to} setCode={setTo} Page="Book" />
        </Col>
      </Row>
      <Row className="pl-1 pr-1">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
            <KeyboardDatePicker
              className="p-0"
              fullWidth
              label={<span style={{ color: pageName === "HomePage" ? '#3B5E75' : 'black' }}>Date</span>}
              minDate={new Date().toString()}
              format="MM/dd/yyyy"
              value={datevalue}
              onChange={DateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={
                <img
                  alt="..."
                  style={{ height: "25px" }}
                  src={
                    require("../../assets/images/icons/calendar.png").default
                  }
                />
              }
            />
          </Col>
          <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
            <KeyboardTimePicker
              className="p-0"
              fullWidth
              label={<span style={{ color: pageName === "HomePage" ? '#3B5E75' : 'black' }}>Time</span>}
              value={datevalue}
              onChange={DateChange}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              keyboardIcon={<FaClock className="text-dark" />}
            />
          </Col>
        </MuiPickersUtilsProvider>
      </Row>
      <Row className="pl-1 pr-1">
        <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
          <FormGroup>
            <FormControl fullWidth>
              <InputLabel htmlFor="planeType"><span style={{ color: pageName === "HomePage" ? '#3B5E75' : 'black' }}>Plane Type</span></InputLabel>
              <Input
                id="planeType"
                value={planeType}
                onChange={(e) => setPlaneType(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    {/* <img
                      alt="..."
                      style={{ height: "25px" }}
                      src={
                        require("../../assets/images/icons/olane-2.png").default
                      }
                    /> */}
                  </InputAdornment>
                }
              />
            </FormControl>
          </FormGroup>
        </Col>
        <Col lg="3" md="3" sm="3" xs="3" className="mb-3">
          <FormGroup>
            <FormControl fullWidth>
              <InputLabel htmlFor="availableSeats"><span style={{ color: pageName === "HomePage" ? '#3B5E75' : 'black' }}>Seats</span></InputLabel>
              <Input
                id="availableSeats"
                onChange={(e) =>
                  e.target.value.length < 4 && setAvailable(e.target.value)
                }
                value={available}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    {/* <GiSofa
                      className="text-dark"
                      style={{ fontSize: "30px" }}
                    /> */}
                    {/* <img
                      alt="..."
                      style={{ height: "25px" }}
                      src={
                        require("../../assets/images/icons/seat.png").default
                      }
                    /> */}
                  </InputAdornment>
                }
              />
            </FormControl>
          </FormGroup>
        </Col>
        <Col lg="3" md="3" sm="3" xs="3" className="mb-3">
          <FormGroup>
            <FormControl fullWidth style={{ "align-items": "center" }}>
              <label
                for="isCharter"
                style={{ "font-size": "11px", color: pageName === "HomePage" ? "#3B5E75" : "black" }}
              >
                Charter?
              </label>

              {user.subscription_plan_id == 3 && (
                <Input
                  //style = {{paddingTop: '9px', marginLeft: '-20%'}}
                  style={{ marginTop: "auto", alignSelf: "stretch" }}
                  disableUnderline="true"
                  id="isCharter"
                  checked={isCharter}
                  value={isCharter}
                  onChange={isCharterToggled}
                  type="checkbox"
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                />
              )}
              {user.subscription_plan_id == 2 && (
                <Input
                  //style = {{paddingTop: '9px', marginLeft: '-20%'}}
                  style={{ marginTop: "auto", alignSelf: "stretch" }}
                  disableUnderline="true"
                  id="isCharter"
                  checked={false}
                  value={false}
                  //onChange = {isCharterToggled}
                  onClick={OpenPopup}
                  type="checkbox"
                  startAdornment={
                    <InputAdornment position="start"></InputAdornment>
                  }
                />
              )}
              {/* <Checkbox 
              id="isCharter"
              marginTop= "10px"
              ></Checkbox> */}
              {/* <InputLabel htmlFor="isCharter">Charter?</InputLabel>
              <Input
                style = {{paddingTop: '9px', marginLeft: '-20%'}}
                disableUnderline = 'true'
                id="isCharter" 
                checked = {isCharter}               
                value={isCharter}
                onChange = {isCharterToggled}
                type="checkbox"              
                startAdornment={
                  <InputAdornment position="start">
                    
                  </InputAdornment>
                }
              /> */}
            </FormControl>
          </FormGroup>
        </Col>
      </Row>
      <Row className="pl-1 pr-1">
        <Col lg="6" md="6" sm="6" xs="6" className="mb-3">
          <FormGroup>
            <FormControl fullWidth>
              <InputLabel htmlFor="price"><span style={{ color: pageName === "HomePage" ? '#3B5E75' : 'black' }}>{isCharter ? "Price" : "Price/Seat"}</span></InputLabel>
              <Input
                id="price"
                onChange={(e) =>
                  e.target.value.length < 8 && setPrice(e.target.value)
                }
                value={price}
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    $
                  </InputAdornment>
                }
              />
            </FormControl>
          </FormGroup>
        </Col>
        <Col lg="6" md="6" sm="6" xs="6" className="mb-3 p-0">
          <FormGroup>
            <FormControl>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  justifyContent: "left",
                  color: pageName === "HomePage" ? "#3B5E75" : "black"
                }}
              >
                <span>&nbsp;&nbsp;&nbsp;Public:&nbsp;</span>
                <input
                  type="radio"
                  name="public"
                  value="Yes"
                  checked={!isPrivate}
                  onChange={(e) => setIsPrivate(false)}
                ></input>
                <span
                  onClick={(e) => setIsPrivate(false)}
                  style={{ cursor: "pointer", "font-size": "14px" }}
                >
                  Yes
                </span>
                &nbsp;&nbsp;
                <input
                  type="radio"
                  name="public"
                  value="No"
                  checked={isPrivate}
                  onChange={(e) => setIsPrivate(true)}
                ></input>
                <span
                  onClick={(e) => setIsPrivate(true)}
                  style={{ cursor: "pointer", "font-size": "14px" }}
                >
                  No
                </span>
                <div style={{ "padding-left": "2px" }}>
                  <HelpIcon
                    fontSize="12px"
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                    style={{ color: "black" }}
                  ></HelpIcon>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography style={{ background: "#FFE600" }} sx={{ p: 2 }}>
                      If Not Public (No) only Friends and Members of the same
                      Group will be notified and see on site.
                    </Typography>
                  </Popover>
                </div>
              </div>
            </FormControl>
          </FormGroup>
        </Col>
      </Row>
      <Row className="text-center px-4 py-0">
        <Col lg="10" md="12" sm="12" className="mx-auto">
          <Button
            style={{ borderRadius: "5px" }}
            className={`${pageName === "HomePage" ? "btnBorderdarkblue" : "btn btn-block btnYellow"} mb-3`}
            onClick={BookFlight}
          >
            Sell
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Book;
