import { useTranslation } from "react-i18next";
import "./PathArea.css";

const currentDate = new Date();

function FilterFriend({ friend, setFriend }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="PathTypeArea">
        <div
          className={
            friend === "No" ? "PathTypeButton selected" : "PathTypeButton"
          }
          onClick={() => setFriend("No")}
        >
          All
        </div>
        <div
          className={
            friend === "Yes" ? "PathTypeButton selected" : "PathTypeButton"
          }
          onClick={() => setFriend("Yes")}
        >
          Katana Members
        </div>
      </div>
    </>
  );
}

export default FilterFriend;
