import React, { useState, useEffect } from 'react'
import {
  Card, CardBody, Row, Col,
  Input,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { useValidateData } from '../data/Validation';
import { useGroupsData } from '../data/GroupsData';
import GroupImage from '../assets/images/icons/group.png'
import "./GroupInvitation.css"
function GroupInvitation() {
  const { groupid, groupname } = useParams();
  const [mobile, SetMobile] = useState("");
  const [mode, SetMode] = useState("");
  const [groupdata, SetGroupData] = useState([]);
  const { CheckUserByMobile } = useValidateData();
  const { GroupDetailsApi } = useGroupsData();

  const history = useHistory();

  useEffect(() => {
    if (groupid) {
      GetGroupDetails();
    }
  }, [])

  useEffect(() => {
    if (mobile.length === 10) {
      SetUserMode();
    } else {
      SetMode("");
    }
  }, [mobile]);

  async function GetGroupDetails() {
    const groupdetails = await GroupDetailsApi(groupid);
    SetGroupData(groupdetails[0]);
    console.log(groupdetails[0]);
  }

  function ClickHandler(events) {
    if (mode === "Member") {
      window.location.href =
        "http://35.183.144.210:8090/AcceptGroupConnectionNewFlow?mobile=1" + mobile + "&groupid=" + groupid;

    } else if (mode === "NotMember") {
      window.location.href =
        "http://35.183.144.210:8090/AcceptGroupConnectionNew?groupid=" +
        groupid +
        "&mobile=1" +
        mobile;
    }
  }

  async function SetUserMode() {
    debugger;
    const isUser = await CheckUserByMobile(mobile);
    if (isUser) {
      SetMode("Member");
    } else {
      SetMode("NotMember");
    }
  }

  return (
    <>
      <Card style={{ margin: 0, padding: 0, overflowY: "scroll" }}>
        <CardBody>
          <div className="container">
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12" className="text-center">
                <h1>GROUP INVITATION</h1>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col lg="12" md="12" sm="12" className="text-center">
                {groupdata && groupdata.profile_pic_path && (
                  <img
                    src={
                      groupdata.profile_pic_path
                        ? groupdata.profile_pic_path
                        : GroupImage
                    }
                    style={{
                      height: "150px",
                      width: "150px",
                      borderRadius: "50%",
                    }}
                  ></img>
                )}
                {groupdata &&
                  groupdata.group_name &&
                  !groupdata.profile_pic_path && (
                    <div
                      data-initials={
                        groupdata.group_name.split(" ").length > 1
                          ? groupdata.group_name
                            .split(" ")[0]
                            .substring(0, 1) +
                          groupdata.group_name.split(" ")[1].substring(0, 1)
                          : groupdata.group_name.substring(0, 2)
                      }
                    ></div>
                  )}
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-2">
                {groupdata.group_name && (
                  <h2>
                    You are invited to group "{groupdata.group_name}", To
                    accept the invitation, please enter your mobile no.
                  </h2>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="4" sm="12" className="mb-2">
                <InputGroup
                  className="input-group-alternative"
                  style={{ width: "100%" }}
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-phone" />
                      &nbsp;&nbsp;&nbsp;(+1)
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    placeholder="Enter Mobile"
                    value={mobile}
                    onChange={(e) => {
                      e.target.value.length < 11 && SetMobile(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              {mode && (
                <Col lg="2" md="3" sm="12" className="mb-2">
                  <Button color="dark" onClick={(e) => ClickHandler(e)}>
                    Submit
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default GroupInvitation
