import React, { useState, useEffect } from "react";
import { useGoogleData } from "../../utils/GooglePeopleApi";
import { Button } from "reactstrap";
import ContactListModal from "./ContactListModal";

function ImportContactFromGoogle({ user, SetUser, SetMobile, SetName, page }) {
  const [isAuthorizedGoogle, SetAuthorizedGoogle] = useState(false);
  const [contactListGoogle, SetContactListGoogle] = useState([]);
  const [filterListGoogle, SetFilterListGoogle] = useState([]);
  const [searchInput, SetSearch] = useState("");
  const [modal, setModal] = useState(false);

  const {
    Authorize,
    GetAccessTokenByAuthCode,
    GetContactListByUserId,
    SearchGoogleContactsByUserId,
    SignOutGoogle,
  } = useGoogleData();

  useEffect(() => {
    if (user.is_google_linked) {
      if (searchInput && searchInput !== "") {
        SearchData();
      } else {
        setTimeout(function () {
          SetFilterListGoogle(contactListGoogle);
        }, 1000);
      }
    }
  }, [searchInput]);

  useEffect(() => {
    if (user.is_google_linked) {
      SetAuthorizedGoogle(true);
    }
  }, []);

  async function GoogleSignOut(event) {
    const response = await SignOutGoogle(user.userid, user);
    if (response) {
      SetAuthorizedGoogle(false);
      let userDetails = user;
      userDetails.is_google_linked = false;
      SetUser({ ...userDetails, jwt: user.jwt });
    }
  }

  async function SearchData() {
    const response = await SearchGoogleContactsByUserId(
      user.userid,
      searchInput,
      user
    );
    if (response.status) {
      let ContactListDetails = [];

      if (response.results && response.results.length > 0) {
        const connections = response.results;

        for (var i = 0; i < connections.length; i++) {
          const person = connections[i].person;
          if (
            person.names &&
            person.names.length > 0 &&
            person.phoneNumbers &&
            person.phoneNumbers.length > 0 &&
            person.phoneNumbers[0].canonicalForm &&
            ValidatePhone(person.phoneNumbers[0].canonicalForm)
          ) {
            for (var j = 0; j < person.phoneNumbers.length; j++) {
              let contact = {};
              contact.id = i + "_" + j;
              contact.name = person.names[0].displayName;
              contact.is_user = person.phoneNumbers[j].is_user;
              if (person.phoneNumbers[j].canonicalForm) {
                if (person.phoneNumbers[j].canonicalForm.length === 10) {
                  contact.phone = person.phoneNumbers[j].canonicalForm;
                } else {
                  // contact.phone = person.phoneNumbers[
                  //   j
                  // ].canonicalForm.substring(
                  //   person.phoneNumbers[j].canonicalForm.length - 10
                  // );
                  //FOR International number
                  contact.phone = person.phoneNumbers[j].canonicalForm.replace(
                    "+",
                    ""
                  );
                }
                ContactListDetails.push(contact);
              }
            }
            //Old search logic, before the international number
            // for (var j = 0; j < person.phoneNumbers.length; j++) {
            //   let contact = {};
            //   contact.id = i + "_" + j;
            //   contact.name = person.names[0].displayName;
            //   contact.is_user = person.phoneNumbers[j].is_user;
            //   if (person.phoneNumbers[j].canonicalForm) {
            //     if (person.phoneNumbers[j].canonicalForm.length === 10) {
            //       contact.phone = person.phoneNumbers[j].canonicalForm;
            //     } else {
            //       contact.phone = person.phoneNumbers[
            //         j
            //       ].canonicalForm.substring(
            //         person.phoneNumbers[j].canonicalForm.length - 10
            //       );
            //     }
            //     ContactListDetails.push(contact);
            //   }
            // }
          }
        }
      }
      SetFilterListGoogle(ContactListDetails);
    }
  }

  async function GetGoogleContact(event) {
    GetContactsByUserId();
  }

  async function GetContactsByUserId() {
    const response = await GetContactListByUserId(user.userid, user);

    if (response.status) {
      let ContactListDetails = [];

      if (response.connections.length > 0) {
        const connections = response.connections;
        for (var i = 0; i < connections.length; i++) {
          const person = connections[i];
          if (
            person.names &&
            person.names.length > 0 &&
            person.phoneNumbers &&
            person.phoneNumbers.length > 0 &&
            person.phoneNumbers[0].canonicalForm &&
            ValidatePhone(person.phoneNumbers[0].canonicalForm)
          ) {
            for (var j = 0; j < person.phoneNumbers.length; j++) {
              let contact = {};
              contact.id = i + "_" + j;
              contact.name = person.names[0].displayName;
              contact.is_user = person.phoneNumbers[j].is_user;
              if (person.phoneNumbers[j].canonicalForm) {
                if (person.phoneNumbers[j].canonicalForm.length === 10) {
                  contact.phone = person.phoneNumbers[j].canonicalForm;
                } else {
                  // contact.phone = person.phoneNumbers[
                  //   j
                  // ].canonicalForm.substring(
                  //   person.phoneNumbers[j].canonicalForm.length - 10
                  // );
                  //FOR International number
                  contact.phone = person.phoneNumbers[j].canonicalForm.replace(
                    "+",
                    ""
                  );
                }
                ContactListDetails.push(contact);
              }
            }
          }
        }
      }
      SetContactListGoogle(ContactListDetails);
      SetFilterListGoogle(ContactListDetails);
      if (ContactListDetails.length > 0) {
        setModal(true);
      }
    } else {
      let userDetails = user;
      userDetails.is_google_linked = false;
      SetUser({ ...userDetails, jwt: user.jwt });
      SetAuthorizedGoogle(false);
    }
  }

  function ValidatePhone(phone) {
    // if (phone && phone.length === 10) {
    //   return true;
    // } else if (phone && phone.length > 10 && phone.includes("+1")) {
    //   return true;
    // }
    return true;
  }

  return (
    <>
      {!isAuthorizedGoogle && (
        <div className="text-center">
          <strong Style={{ display: "inline-block", verticalAlign: "1em" }}>
            Sign in with:&nbsp;&nbsp;
          </strong>
          <Button
            onClick={Authorize}
            style={{
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
              borderBottom: "3px solid lightgray",
              color: "lightgray !important",
            }}
            className="btn btn-secondary"
          >
            <img
              alt="..."
              style={{ height: "20px", width: "20px" }}
              src={require("../../assets/images/google.png").default}
            />
            &nbsp;&nbsp;&nbsp;
            <b style={{ color: "gray", fontWeight: "600" }}>Google</b>
          </Button>
        </div>
      )}
      {isAuthorizedGoogle && (
        <div style={{ display: "flex" }}>
          <Button
            onClick={GetGoogleContact}
            style={{
              width: "50%",
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
              borderBottom: "3px solid lightgray",
              color: "lightgray !important",
            }}
            className="btn btn-secondary"
          >
            {/* <img
              alt="..."
              style={{ height: "20px", width: "20px" }}
              src={require("../../assets/images/google.png").default}
            /> */}
            Google Contacts
          </Button>
          <Button
            onClick={GoogleSignOut}
            style={{
              width: "50%",
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
              borderRight: "1px solid lightgray",
              borderLeft: "1px solid lightgray",
              borderBottom: "3px solid lightgray",
              color: "lightgray !important",
            }}
            className="btn btn-secondary"
          >
            {/* <img
              alt="..."
              style={{ height: "20px", width: "20px" }}
              src={require("../../assets/images/google.png").default}
            />
            &nbsp;&nbsp;&nbsp;Sign Out */}
            De-link Google
          </Button>
        </div>
      )}
      <ContactListModal
        modal={modal}
        setModal={setModal}
        contactList={filterListGoogle}
        SetMobile={SetMobile}
        SetName={SetName}
        search={searchInput}
        SetSearch={SetSearch}
        page={page}
      />
    </>
  );
}

export default ImportContactFromGoogle;
