import './common.css'
import { useState, useEffect } from "react";
import {useParams, useHistory} from 'react-router-dom'
import * as React from 'react';
import {authAndUserData} from '../data/AuthAndUserData';
import { Card, CardBody, Row, Col } from "reactstrap";
//import {authAndUserData} from '../data/AuthAndUserData';

function ShortCode() {
  const { code } = useParams();
  const {ShortCode} = authAndUserData();

  var url = ShortCode(code).then((response)=>{
    console.log(response);
    if(response.long_url){
      window.location.assign(response.long_url);
      return <div>Redirecting</div>;
    }
    else{
      window.location.assign("/");
      return <div>Invalid link. Please try again.</div>;
    }
  })

 }
export default ShortCode;
