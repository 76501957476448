import axios from "../libs/axios";
import { message } from "antd";
import { useTranslation } from "react-i18next";

const PostAuth = async (mobile, password) => {
  try {
    const response = await axios.request({
      url: "/Login",
      method: "post",
      data: {
        mobile,
        password,
      },
    });

    const userData = response.data[0];

    userData.mobile = parseInt(userData.mobile, 10);

    return userData;
  } catch (error) {
    return false;
  }
};

const GetUserDetailsForHome = async (userid, user) => {
  try {
    const response = await axios.request({
      url: "/GetUserDetails",
      method: "post",
      headers: {
        Authorization: `Bearer ${user.jwt}`
      },
      data: {
        userid
      },
    });

    const userData = response.data[0];

    userData.mobile = parseInt(userData.mobile, 10);

    return userData;
  } catch (error) {
    return false;
  }
};

const CancelSubscription = async (subscription_id, user) => {
  try {
    const response = await axios.request({
      url: "/CancelSubscription",
      method: "post",
      headers: {
        Authorization: `Bearer ${user.jwt}`
      },
      data: {
        subscription_id
      },
    });

    const resp = response;

    //userData.mobile = parseInt(userData.mobile, 10);

    return resp;
  } catch (error) {
    return false;
  }
};

const ChangeSubscription = async (subscription_id, subscription_type, userid, user) => {
  try {
    const response = await axios.request({
      url: "/ChangeSubscription",
      method: "post",
      headers: {
        Authorization: `Bearer ${user.jwt}`
      },
      data: {
        userid,
        subscription_id,
        subscription_type
      },
    });

    const resp = response;

    //userData.mobile = parseInt(userData.mobile, 10);

    return resp;
  } catch (error) {
    return false;
  }
};

const PostCreateNewUser = async (email, name, mobile, home, away, password) => {
  try {
    const response = await axios.request({
      url: "/CreateNewUser",
      method: "post",
      data: {
        email,
        name,
        mobile,
        home,
        away,
        password,
      },
    });

    const userData = response.data[0];

    userData.mobile = parseInt(userData.mobile, 10);

    return userData;

    //return true;
  } catch (error) {
    return false;
  }
};

const UpdateProfile = async (email, name, home, away, userid, password, user) => {
  try {
    const response = await axios.request({
      url: "/UpdateProfile",
      method: "post",
      headers: {
        Authorization: `Bearer ${user.jwt}`
      },
      data: {
        email,
        name,
        userid,
        password,
        home: home === null ? "" : home,
        away: away === null ? "" : away,
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const ForgotPasswordApi = async (mobile) => {
  try {
    await axios.request({
      url: "/ForgetPassword",
      method: "post",
      data: {
        mobile,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const ForgotPasswordOtpApi = async (mobile, otp) => {
  try {
    await axios.request({
      url: "/ForgetPasswordOTP",
      method: "post",
      data: {
        mobile,
        otp,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const ResetPassword = async (mobile, password) => {
  try {
    await axios.request({
      url: "/ResetPassword",
      method: "post",
      data: {
        mobile,
        password,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};

const ProfilePicture = async (FormData) => {
  const { data } = await axios.request({
    url: "/UploadProfilePic",
    method: "post",
    data: FormData,
  });
  return data;
};

const GenerateS3PreSignedUrl = async (body) => {
  try {
    const { data } = await axios.request({
      url: "/GenerateS3PreSignedUrl",
      method: "post",
      data: body,
    });
    return data;
  }
  catch (error) {
    return false;
  }
};

const GetPress = async () => {
  const { data } = await axios.request({
    url: "/GetPress",
    method: "get",
  });
  return data;
};

const PlansShowed = async (userid) => {
  const { data } = await axios.request({
    url: "/PlansShowed",
    method: "post",
    data: {
      userid
    }
  });
  return data;
};

const ApplyCoupon = async (code, user) => {
  const { data } = await axios.request({
    url: "/ApplyCoupon",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: {
      code
    }
  });
  return data;
};

const ShortCode = async (code) => {
  const { data } = await axios.request({
    url: "/code/" + code,
    method: "get",
  });
  return data;
};

export const authAndUserData = () => ({
  PostAuth,
  GetUserDetailsForHome,
  PostCreateNewUser,
  UpdateProfile,
  ForgotPasswordApi,
  ForgotPasswordOtpApi,
  ResetPassword,
  ProfilePicture,
  GenerateS3PreSignedUrl,
  CancelSubscription,
  ChangeSubscription,
  GetPress,
  ShortCode,
  PlansShowed,
  ApplyCoupon
});