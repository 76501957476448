import { Route, useHistory } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import { useLocalStorage } from "../utils/useLocalStorage";
// import { Row, Col, Container } from "reactstrap";
// import ChatBot from "../components/ChatBot/ChatBot";

function MainLayout({ children }) {
  return (
    <>
      <Header />
      <>{children}</>
      {/* <ChatBot /> */}
      <Footer />
    </>
  );
}

export function MainLayoutRoute({
  component: Component,
  headerCreateType,
  title,
  ...rest
}) {
  const [user] = useLocalStorage("auth", {});
  const history = useHistory();
  const titles = [
    "My Flights",
    "My Profile",
    "My Groups",
    "My Friends",
    "Group Details",
  ];
  useEffect(() => {
    if (titles.includes(title) && typeof user.userid === "undefined") {
      history.push("/");
    }
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        // <Row>
        //   <Col lg={{ size: "10", offset: "1" }} md={{ size: "10", offset: "1" }} sm="12">
        // <Container>
        <MainLayout headerCreateType={headerCreateType}>
          <div>
            <Component {...props} title={title} />
          </div>
        </MainLayout>
        // </Container>
        //   </Col>
        // </Row>
      )}
    />
  );
}
