import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  CardHeader,
  Card,
  Col,
  Alert,
  Input,
  Button,
  CardBody,
} from "reactstrap";

import AddMember from "../components/GroupDetails/AddMember";
import MembersList from "../components/GroupDetails/MembersList";
import { useGroupsData } from "../data/GroupsData";
import { useLocalStorage } from "../utils/useLocalStorage";

function GroupDetails() {
  const [user] = useLocalStorage("auth", {});
  const { groupid } = useParams();
  const [groupdata, SetGroupDetails] = useState(undefined);
  const [membersData, SetMembersData] = useState(undefined);
  const { GroupDetailsApi, GroupMembers } = useGroupsData();

  useEffect(() => {
    GetGroupDetails();
    GetGroupMembers();
  }, []);

  async function GetGroupMembers() {
    const response = await GroupMembers(groupid, user);
    SetMembersData(response);
  }

  async function GetGroupDetails() {
    const response = await GroupDetailsApi(groupid);
    SetGroupDetails(response);
  }

  return (
    <div>
      <Container fluid>
        <Row>
          <div className="col p-0">
            <Card className="shadow" style={{ backgroundColor: "whitesmoke" }}>
              <Row className="p-3">
                <Col lg="4" md="4" sm="12">
                  <Card>
                    <CardHeader className="text-center">
                      <h2>Group Details</h2>
                    </CardHeader>
                    <CardBody>
                      <h2>
                        Group Name: {groupdata && groupdata[0].group_name}
                      </h2>
                      <h2>
                        Members: {groupdata && groupdata[0].members_count}
                      </h2>
                    </CardBody>
                  </Card>
                  <br />
                  <AddMember
                    membersData={membersData}
                    user={user}
                    groupDetails={groupdata}
                  />
                </Col>
                <Col lg="8" md="8" sm="12">
                  <Card>
                    <CardHeader className="text-center">
                      <h2>Group Members</h2>
                    </CardHeader>
                    <CardBody>
                      {membersData && membersData.length > 0 ? (
                        <MembersList membersData={membersData} />
                      ) : (
                        <Alert className="alertYellow">No Group members Available</Alert>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default GroupDetails;
