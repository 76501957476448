import React, { useEffect, useState } from "react";
import GroupImage from '../../assets/images/icons/group.png'
import { Button, Alert } from 'reactstrap'
import { Snackbar } from '@material-ui/core'
import reactS3 from "react-s3";
import { useGroupsData } from '../../data/GroupsData';
import { authAndUserData } from "../../data/AuthAndUserData";

function GroupProfilePic({ groupData, setErrorMessage, setIsError }) {

  const [groupImage, SetGropImage] = useState(GroupImage);
  const [fileData, SetFileData] = useState(null);
  const [isUploadtoS3, SetIsUploadedToS3] = useState(false);
  const config = {
    bucketName: "katana-images",
    dirName: "group_pictures",
    region: "us-east-1",
    accessKeyId: "AKIAYJTGGPZLK7IG6X5D",
    secretAccessKey: "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",
  };
  const { GroupProfilePicture } = useGroupsData();
  const { GenerateS3PreSignedUrl } = authAndUserData();

  useEffect(() => {
    if (
      groupData[0].profile_pic_path === null ||
      groupData[0].profile_pic_path === ""
    ) {
      SetGropImage(GroupImage);
    } else {
      SetGropImage(groupData[0].profile_pic_path);
    }
  }, []);

  function SelectProfilePicture(e) {
    document.getElementById("selectImage").click();
  }

  function UploadImage(e) {
    console.log(e.target.files[0]);
    var MyFile = e.target.files[0];
    var ext = MyFile.name.substr(MyFile.name.lastIndexOf(".") + 1);
    const myNewFile = new File([MyFile], groupData[0].group_id + "-group." + ext, {
      type: MyFile.type,
    });
    if (myNewFile.type.includes("image")) {
      SetFileData(myNewFile);
      SetGropImage(URL.createObjectURL(e.target.files[0]));
      setErrorMessage("");
      setIsError(false);
    } else {
      setErrorMessage("You can only upload image files");
      setIsError(true);
    }
  }

  async function OnUploadImageOld(e) {
    SetIsUploadedToS3(true);
    const data = await reactS3.uploadFile(fileData, config);
    if (data.result) {
      SetIsUploadedToS3(false);
      const profileData = {
        groupid: groupData[0].group_id,
        profile_pic_path: data.location,
      };
      const response = await GroupProfilePicture(profileData);
      console.log(response);
      SetFileData(null);
      window.location = "/myGroups";
    }
  }

  //Added on 21-02-2024 to generate Pre signed url from API and upload using that.
  async function OnUploadImage(e) {
    // Get presigned URL from your server
    try {
      SetIsUploadedToS3(true);
      const response = await GenerateS3PreSignedUrl({ fileName: fileData.name, fileType: fileData.type, uploadFolder: 'group_pictures' });
      const data = response;

      // Upload the file to S3 using the presigned URL
      const uploadResponse = await fetch(data.url, {
        method: 'PUT',
        body: fileData,
        headers: {
          'Content-Type': fileData.type
        }
      });

      if (uploadResponse.ok) {
        SetIsUploadedToS3(false);
        console.log('File successfully uploaded to S3');

        const profileData = {
          groupid: groupData[0].group_id,
          profile_pic_path: data.url.split('?')[0],
        };
        const response = await GroupProfilePicture(profileData);
        console.log(response);
        SetFileData(null);
        window.location = "/myGroups";
      } else {
        SetIsUploadedToS3(false);
        console.log('Failed to upload file');
      }
    } catch (error) {
      SetIsUploadedToS3(false);
      console.error('Error in file upload', error);
    }
  }


  return (
    <div className="text-center">
      {isUploadtoS3 && (
        <Snackbar
          open={isUploadtoS3}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
        >
          <Alert className="m-2 alertYellowInverse">
            Loading.!
          </Alert>
        </Snackbar>
      )}
      <img
        src={groupImage}
        style={{
          height: "150px",
          width: "150px",
          borderRadius: "50%",
        }}
        className="my-4"
        onClick={SelectProfilePicture}
      />
      <input
        type="file"
        onChange={UploadImage}
        multiple={false}
        accept="image/*"
        id="selectImage"
        hidden
      ></input>
      <br />
      {fileData && (
        <Button
          style={{ borderRadius: "20px" }}
          onClick={OnUploadImage}
          className="btn btnYellow mt-2"
        >
          Upload Image
        </Button>
      )}
    </div>
  );
}

export default GroupProfilePic
