import React, { useState } from 'react'
import {
  Alert,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import { authAndUserData } from "../../data/AuthAndUserData";
import PhoneInput from 'react-phone-input-2';
import { FormControl } from '@material-ui/core';

function ForgotPassword({ setMode, setPasswordChanged }) {
  const { ForgotPasswordApi, ForgotPasswordOtpApi, ResetPassword } =
    authAndUserData();
  const [step, setStep] = useState("1");
  const [mobile, setMobile] = useState("");
  const [otp, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function OnForgotPassword(e) {
    e.preventDefault();
    switch (step) {
      case "1":
        if (mobile !== "" && mobile !== undefined && mobile !== null) {
          if (await ForgotPasswordApi(parseInt(mobile, 10))) {
            setStep("2");
            setErrorMessage("");
            setIsError(false);
          } else {
            setErrorMessage("You entered wrong mobile");
            setIsError(true);
          }
        } else {
          setErrorMessage("Please enter mobile");
          setIsError(true);
        }
        break;
      case "2":
        if (ValidateOtp()) {
          if (await ForgotPasswordOtpApi(otp)) {
            setStep("3");
            setErrorMessage("");
            setIsError(false);
          } else {
            setErrorMessage("You entered wrong OTP, Please enter valid OTP");
            setIsError(true);
          }
        }
        break;
      case "3":
        if (ValidateNewPassword()) {
          if (await ResetPassword(parseInt(mobile, 10), newPassword)) {
            setStep("1");
            setMobile("");
            setOTP("");
            setNewPassword("");
            setPasswordChanged(true);
            setMode("Login");
          } else {
            setErrorMessage("Something went wrong.");
            setIsError(true);
          }
        }
        break;
    }
  };

  function ValidateOtp() {
    if (otp === "" || otp === undefined || otp === null) {
      setErrorMessage("Please enter OTP");
      setIsError(true);
      return false;
    } else if (otp.length !== 6) {
      setErrorMessage("Please enter valid OTP");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }

  function ValidateNewPassword() {
    if (
      newPassword === "" ||
      newPassword === undefined ||
      newPassword === null
    ) {
      setErrorMessage("Please enter new password");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }

  return (
    <div>
      {isError && <Alert color="danger">{errorMessage}</Alert>}
      {!isError && step === "1" && (
        <Alert className="alertYellow">
          Step1: Please input your mobile, we will send you a one-time password
        </Alert>
      )}
      {!isError && step === "2" && (
        <Alert className="alertYellow">
          Step2: Please input the one-time password you received.
        </Alert>
      )}
      {!isError && step === "3" && (
        <Alert className="alertYellow">Step3: Please set a new password</Alert>
      )}

      {step === "1" && (
        <FormGroup className="mb-3 form-inline">
          {/* Bhim code start */}
          <FormControl fullWidth className="mb-2">
            <p style={{ color: "black", marginBottom: "0" }}>Mobile</p>

            <PhoneInput
              country={"us"}
              value={mobile}
              onChange={(phone) => setMobile(phone)}
            />
          </FormControl>
          {/* Bhim code end */}

          {/* <InputGroup
            className="input-group-alternative"
            style={{ width: "100%" }}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-phone" />
                &nbsp;&nbsp;&nbsp;(+1)
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Enter 10 digit mobile"
              type="number"
              value={mobile}
              onChange={(e) => {
                if (e.target.value.length <= 10) setMobile(e.target.value);
              }}
            />
          </InputGroup> */}
        </FormGroup>
      )}
      {step === "2" && (
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-code" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Enter OTP"
              type="number"
              value={otp}
              maxLength={6}
              onChange={(e) => {
                setOTP(e.target.value);
              }}
            />
          </InputGroup>
        </FormGroup>
      )}
      {step === "3" && (
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-lock" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Enter New Password"
              type="password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </InputGroup>
        </FormGroup>
      )}
      <div className="text-center mb-3">
        <Button
          name="login"
          color="dark"
          style={{ borderRadius: "20px" }}
          onClick={OnForgotPassword}
          className="btn btn-block"
        >
          Submit
        </Button>
      </div>
      <div className="text-center mb-3">
        <Button
          style={{
            borderRadius: "20px",
            backgroundColor: "rgb(255, 230, 0)",
          }}
          onClick={(e) => setMode("Login")}
          className="btn btn-block"
        >
          Sign In
        </Button>
      </div>
    </div>
  );
}

export default ForgotPassword
