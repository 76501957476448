import React, { useState, useRef, useEffect } from "react";
import { FaCamera, FaRegTimesCircle } from "react-icons/fa";
import {
  Button,
  Modal,
  ModalBody,
  Card,
  CardBody,
  CardHeader,
  ModalFooter,
} from "reactstrap";
import Webcam from 'react-webcam'
import CameraOverlayImg from '../../assets/images/icons/cameraOverlay.png';
import LoginImage from "../../assets/images/icons/ninja3.png";

function Camera({ SetLoginImage, user, SetFileData , loginImage }) {
  const config = {
    bucketName: "katana-images",
    dirName: "profile_pictures",
    region: "us-east-1",
    accessKeyId: "AKIAYJTGGPZLK7IG6X5D",
    secretAccessKey: "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",
  };

  const [modal, setModal] = useState();

  useEffect(() => {
    if (user.profile_pic_path === null || user.profile_pic_path === "") {
      SetLoginImage(LoginImage);
    } else {
      SetLoginImage(user.profile_pic_path);
    }
  }, []);

  const toggle = () => setModal(!modal);
  const videoConstraints = {
    width: 300,
    height: 300,
  };
  const webcamRef = useRef(null);

  async function urltoFile(url, filename, mimeType) {
    return await fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  async function capture(e) {
    const imageSrc = webcamRef.current.getScreenshot();
    SetLoginImage(imageSrc);
    await urltoFile(imageSrc, user.userid + "-profile.png", "image/png").then(
      function (file) {
        SetFileData(file);
        setModal(false);
      }
    );
  }

  return (
    <>
      {/* <Button onClick={toggle} className="btnYellow">
        <FaCamera style={{ fontSize: "25px" }} />
      </Button>
      <br />
      <span>Click above to capture now.</span> */}
      <div className="text-center">
        <img
          src={loginImage}
          style={{
            height: "150px",
            width: "150px",
            borderRadius: "50%",
          }}
          onClick={toggle}
        />
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={"static"}
        keyboard={false}
        size="md"
      >
        <ModalBody className="p-0">
          <Card className="shadow border-0">
            <CardHeader className="p-0">
              <div className="text-right p-2">
                <FaRegTimesCircle onClick={toggle} cursor="pointer" />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-2">
              <>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/png"
                  videoConstraints={videoConstraints}
                  style={{
                    borderRadius: "50%",
                    left: "0",
                    top: "0",
                    height: "auto",
                    width: "100%",
                    transform: "scaleX(-1)",
                  }}
                />
                <img
                  src={CameraOverlayImg}
                  className="px-lg-5"
                  style={{
                    position: "absolute",
                    borderRadius: "50%",
                    left: "0",
                    height: "auto",
                    width: "100%",
                    opacity: "0.5",
                  }}
                />
              </>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter className="text-center">
          <div className="text-center">
            <br />
            <Button className="btnYellow" onClick={capture}>
              Capture Image
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Camera
