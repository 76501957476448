import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import { FaPlus, FaTrash, FaCheck, FaTimes, FaSuitcaseRolling, FaBriefcase, FaWifi, FaPlug, FaChevronRight } from 'react-icons/fa';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useFlightData } from '../data/FlightData';
import DuffelPortal from './DuffelPortal'; // Import the Portal
import { getNames, getCode } from 'country-list';
import Flag from 'react-world-flags';
import Select from 'react-select';
import PhoneInput from "react-phone-input-2";


function DuffelBooking({ isOpen, toggle, selectedFlight, user, setIsOpen }) {
    const [passengers, setPassengers] = useState([]);
    const [bookingError, setBookingError] = useState("");
    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [clientToken, setClientToken] = useState(null);
    const [validationOfData, setValidationOfData] = useState(false)
    const [showPayment, setShowPayment] = useState(false);


    const { createPaymentIntent, confirmPayment, bookDuffelFlight } = useFlightData();

    const [isDuffelLoaded, setIsDuffelLoaded] = useState(false);
    const duffelRef = useRef(null);
    const [successModal, setSuccessModal] = useState(false)
    const [showAncillaries, setShowAncillaries] = useState(false);
    const [clientKey, setClientKey] = useState(selectedFlight?.client_key || null); // Add state for client_key
    const [totalPrice, setTotalPrice] = useState(selectedFlight?.total_price_all_seats || 0);


    console.log("Selected Flight: " + JSON.stringify(selectedFlight));
    console.log("Client Key: " + clientKey);

    useEffect(() => {
        if (selectedFlight?.passengerDetails && selectedFlight.passengerDetails.length > 0) {
            setPassengers(selectedFlight.passengerDetails.map(passenger => ({
                id: passenger.id || '',
                given_name: "",
                family_name: "",
                email: user?.email || "",
                phone_number: "",
                born_on: "",
                title: "",
                gender: "",
                identity_documents: [
                    {
                        unique_identifier: "",
                        type: "passport",
                        issuing_country_code: "US",
                        expires_on: ""
                    }
                ],
            })));

            console.log("Passengers: " + passengers);
        } else {
            setPassengers([]);
        }
        setBookingError("");
        setPaymentIntentId(null);
        setPaymentStatus(null);
        setShowPayment(false);
        setClientToken(null);
        setValidationOfData(false);
        setShowAncillaries(false);
        setClientKey(selectedFlight?.client_key || null);
        setTotalPrice(selectedFlight?.total_price_all_seats || 0);
    }, [selectedFlight, isOpen]);

    useEffect(() => {
        const isValid = passengers.every(passenger =>
            passenger.given_name && passenger.family_name && passenger.born_on && passenger.phone_number && passenger.email && passenger.title && passenger.gender &&
            (selectedFlight?.is_identity_document ? (
                passenger.identity_documents &&
                passenger.identity_documents.length > 0 &&
                passenger.identity_documents.every(document => document.unique_identifier && document.type && document.issuing_country_code && document.expires_on)
            ) : true)
        );
        setValidationOfData(isValid);
    }, [passengers, selectedFlight?.is_identity_document]);

    useEffect(() => {
        // Listen for messages from the iframe
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return; // Ensure message comes from trusted source
            const { type, newTotalPrice, status, error } = event.data;

            if (status === "NEW_TOTAL_PRICE") {
                console.log("newTotalPrice", newTotalPrice)
                setTotalPrice((newTotalPrice * 1.03) * 1.05); // Update the additional price
            }

            if (status === "success") {
                setClientToken(null);
                setPaymentStatus("success");
                setPassengers(passengers);
                // setIsOpen(false);
            } else if (status === "failure") {
                setPaymentStatus("error");
                setPassengers(passengers);
                setClientToken(null);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);


    useEffect(() => {
        if (paymentStatus == 'success' && paymentIntentId) {

            handlePaymentSuccess()
        }
    }, [paymentStatus, paymentIntentId])

    const handleProceedToPayment = () => {
        handleDuffelBooking();
        setShowPayment(true);
    };

    // useEffect(() => {
    //     const checkDuffelLoaded = () => {
    //         if (window.Duffel) {
    //             console.log("Duffel script loaded");
    //             setIsDuffelLoaded(true);
    //         } else {
    //             console.log("Waiting for Duffel script to load...");
    //             setTimeout(checkDuffelLoaded, 100);
    //         }
    //     };

    //     checkDuffelLoaded();

    //     return () => {
    //         // Cleanup function
    //         setIsDuffelLoaded(false);
    //     };
    // }, []);

    useEffect(() => {
        const loadDuffelScript = () => {
            const script = document.createElement('script');
            script.src = 'https://assets.duffel.com/components/3.7.19/duffel-payments.js';
            script.async = true;
            script.onload = () => {
                setIsDuffelLoaded(true);
            };
            document.body.appendChild(script);
        };

        if (!window.Duffel) {
            loadDuffelScript();
        } else {
            setIsDuffelLoaded(true);
        }



        // return () => {
        //     // Cleanup function
        //     setIsDuffelLoaded(false);
        // };
    }, []);

    class ErrorBoundary extends React.Component {
        constructor(props) {
            super(props);
            this.state = { hasError: false };
        }

        static getDerivedStateFromError(error) {
            return { hasError: true };
        }

        render() {
            if (this.state.hasError) {
                return <h1>Something went wrong with the payment component.</h1>;
            }

            return this.props.children;
        }
    }



    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        setPassengers(prevPassengers => {
            const updatedPassengers = [...prevPassengers];
            if (name.startsWith('identity_documents.')) {
                const [, field] = name.split('.');
                updatedPassengers[index].identity_documents[0][field] = value;
            } else {
                updatedPassengers[index][name] = value;
            }
            return updatedPassengers;
        });
        setBookingError('');
        setPaymentIntentId(null);
    };

    const passdata = {
        passenger_id: selectedFlight?.passengerDetails?.[0]?.id || '',
        given_name: "", family_name: "", email: user?.email || "", phone_number: "", born_on: "", title: "", gender: "",
        identity_documents: [
            {
                unique_identifier: "",
                type: "passport",
                issuing_country_code: "US",
                expires_on: ""
            }
        ],
    }
    // selectedFlight?.passengers[0]?.id
    const ProceedToSelectSeats = async (event) => {
        event.preventDefault();
        const isValid = passengers.every(passenger =>
            passenger.given_name && passenger.family_name && passenger.born_on && passenger.phone_number && passenger.email && passenger.title && passenger.gender &&
            (selectedFlight?.is_identity_document ? (
                passenger.identity_documents &&
                passenger.identity_documents.length > 0 &&
                passenger.identity_documents.every(document => document.unique_identifier && document.type && document.issuing_country_code && document.expires_on)
            ) : true)
        );

        if (!isValid) {
            setBookingError("Please fill in all required fields for all passengers.");
            return;
        }

        setShowAncillaries(true);


    };

    const handleDuffelBooking = async () => {
        try {
            // Step 2: Create PaymentIntent
            // debugger
            const paymentIntentResponse = await createPaymentIntent({
                amount: totalPrice,
                currency: 'USD', // Adjust as needed
                // type: ''// Assuming the flight object has an id field
            }, user);
            // handlePaymentSuccess()
            if (Object.keys(paymentIntentResponse).length) {
                setPaymentIntentId(paymentIntentResponse);
                setClientToken(paymentIntentResponse.client_token); // Assuming the response includes a client_token
            }

            // Step 3: Collect Payment is handled by DuffelPayments component

            // Steps 4 and 5 will be handled after successful payment
        } catch (error) {
            console.error("Error creating payment intent:", error);
            setBookingError("An error occurred while initiating the booking. Please try again.");
        }
    }

    // useEffect(() => {

    //     if (clientToken && !paymentStatus && isDuffelLoaded) {
    //         try {
    //             window.Duffel.createPaymentsComponent({
    //                 clientToken: clientToken,
    //                 containerId: 'duffel-payments',
    //                 onSuccess: handlePaymentSuccess,
    //                 onError: (error) => {
    //                     setPaymentStatus('error');
    //                 }
    //             });
    //         } catch (error) {
    //         }
    //     }
    // }, [clientToken, paymentStatus]);

    // useEffect(() => {
    //     console.log(clientToken, isDuffelLoaded, duffelRef.current)
    //     if (clientToken && isDuffelLoaded && duffelRef.current) {
    //         // const duffelPaymentsElement = document.querySelector('duffel-payments');
    //         // console.log('Duffel Payments Element:', duffelPaymentsElement);
    //         // if (duffelPaymentsElement) {
    //         debugger
    //         duffelRef.current.render({
    //             paymentIntentClientToken: clientToken,
    //             debug: true, // Enable for debugging
    //             onSuccess: handlePaymentSuccess,
    //             onError: (error) => {
    //                 console.error("Payment error:", error);
    //                 // setPaymentStatus('error');
    //             }
    //         });
    //         // } else {
    //         //     console.error('Duffel Payments element not found in the DOM.');
    //         // }
    //     }
    // }, [clientToken, isDuffelLoaded]);



    const handlePaymentSuccess = async () => {
        try {
            // debugger
            // Step 4: Confirm PaymentIntent
            const confPayment = await confirmPayment(paymentIntentId?.id, user);

            // Step 5: Create Order
            if (Object.keys(confPayment).length) {

                const bookingPassengers = passengers.map(passenger => {
                    const passengerData = {
                        id: passenger.id,
                        given_name: passenger.given_name,
                        family_name: passenger.family_name,
                        email: passenger.email,
                        phone_number: passenger.phone_number,
                        born_on: passenger.born_on,
                        title: passenger.title,
                        gender: passenger.gender,
                    };

                    // Only include identity_documents if they are required
                    if (selectedFlight?.is_identity_document && passenger.identity_documents && passenger.identity_documents.length > 0) {
                        passengerData.identity_documents = passenger.identity_documents;
                    }

                    return passengerData;
                });

                const bookingResponse = await bookDuffelFlight({
                    offer_id: selectedFlight.offr_id,
                    userid: selectedFlight?.userid,
                    passengers: bookingPassengers,
                    payment_intent_id: paymentIntentId?.id,
                    user_id: user?.userid,
                    duffel_amount: selectedFlight?.duffel_price,
                    amount: totalPrice,
                    cancellable: selectedFlight?.cancellation_allowed,
                    cancellation_penalty: selectedFlight?.cancellation_penalty,
                    cancellation_penalty_currency: selectedFlight?.cancellation_penalty_currency,
                    changeable: selectedFlight?.change_allowed,
                    change_penalty: selectedFlight?.change_penalty,
                    change_penalty_currency: selectedFlight?.change_penalty_currency,
                    // amount: confPayment?.amount,
                    currency: confPayment?.fees_currency,
                    payment_intent_id: paymentIntentId?.id, // Include payment_intent_id here

                }, user);
                if (Object.keys(bookingResponse).length) {
                    setClientToken(null)
                    setPaymentIntentId(null)
                    setPaymentStatus('success');
                }
                else {
                    setPaymentStatus('error');
                    setClientToken(null)
                    setPaymentIntentId(null)
                }
            } else {
                setPaymentStatus('error');
                setClientToken(null)
                setPaymentIntentId(null)
            }
            // Handle successful booking (e.g., show confirmation, redirect)
        } catch (error) {
            console.error("Error finalizing booking:", error);
            setPaymentStatus('error');
            setBookingError("An error occurred while finalizing the booking. Please try again.");
            setClientToken(null)
            setPaymentIntentId(null)
        }
    };

    const countries = getNames(); // Get all country names
    const options = countries.map((country) => {
        const countryCode = getCode(country);
        return {
            value: countryCode,
            label: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag code={countryCode} style={{ width: '20px', marginRight: '10px' }} />
                    {country} (+{countryCode})
                </div>
            )
        };
    });
    const handleChange = (selectedOption, index) => {
        handleInputChange(index, {
            target: {
                name: 'issuing_country_code',
                value: selectedOption.value,
            },
        });
    };

    return (
        <>
            <Modal isOpen={paymentStatus} footer={null} width={'100%'} >
                <div className="d-flex justify-content-center flex-column" style={{ alignItems: 'center' }}>
                    <div className="my-5 ">
                        <img
                            alt="success picture"
                            className=""
                            height={150}
                            width={150}
                            src={
                                paymentStatus == "success"
                                    ? "/successUpdate.gif"
                                    : "/failure.gif"
                            }
                        />
                    </div>
                    {paymentStatus == "success" ? (
                        <div className=" font-semibold text-2xl text-center px-4">
                            Booking successful!
                            <br />
                            <h4 className="fs-4 font-medium">
                                Your flight has been confirmed.
                            </h4>
                        </div>
                    ) : (
                        <div className="font-semibold text-2xl text-center px-4">
                            Payment failed.
                            <br />
                            <span className="fs-4 font-medium">Please try Again</span> !
                        </div>
                    )}
                    <div>
                        <Button
                            color={paymentStatus == "success" ? "success" : "danger"}
                            onClick={() => {
                                setSuccessModal(false)
                                setIsOpen(false)
                                setPaymentStatus(null)
                            }}
                            className="text-white bg-theme min-w-24 m-4 mt-5"

                        >
                            Ok
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isOpen} toggle={toggle} size="lg" backdrop="static" keyboard={false}>
                <ModalHeader toggle={toggle}>Flight Booking</ModalHeader>
                <div className='p-3'>
                    {selectedFlight && (
                        <>
                            <Card className="mt-3">
                                <CardBody className="py-0">
                                    <Row>
                                        <Col lg="12" md="12" sm="12" xs="12">
                                            <Row>
                                                <Col lg="2" md="2" sm="2" xs="2" className="pl-0 py-2 text-left">
                                                    {selectedFlight.profile_pic_path ? (
                                                        <img
                                                            style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                                            src={selectedFlight.profile_pic_path}
                                                            alt="Profile"
                                                        />
                                                    ) : (
                                                        <div
                                                            className="bg-white pr-0 text-center"
                                                            style={{ height: "50px", width: "50px", border: "3px solid black", borderRadius: "50%" }}
                                                        >
                                                            <h2 className="textInitial">
                                                                {selectedFlight.name.split(" ").map(n => n[0]).join("")}
                                                            </h2>
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col lg="2" md="2" sm="2" xs="2" className="my-1 p-0">
                                                    <h6 className="m-0">Departs</h6>
                                                    <h4 className="m-0">{selectedFlight.from_airport}</h4>
                                                    <h6 className="m-0 textCity">{selectedFlight.from_city}</h6>
                                                    <h6 className="m-0">{selectedFlight.dep_date}</h6>
                                                    <h6 className="m-0">{selectedFlight.dep_time}</h6>
                                                </Col>
                                                <Col lg="1" md="1" sm="1" xs="1" className="mt-2 p-0 text-center">
                                                    <h6 className="my-0">
                                                        <FaChevronRight style={{ height: "40px", fontWeight: "bold" }} color="rgb(255, 230, 0)" />
                                                        <FaChevronRight style={{ height: "40px", fontWeight: "bold" }} color="rgb(255, 230, 0)" />
                                                        <FaChevronRight style={{ height: "40px", fontWeight: "bold" }} color="rgb(255, 230, 0)" />
                                                    </h6>
                                                </Col>
                                                <Col lg="2" md="2" sm="2" xs="2" className="my-1 p-0 text-right">
                                                    <h6 className="m-0">Arrives</h6>
                                                    <h4 className="m-0">{selectedFlight.to_airport}</h4>
                                                    <h6 className="m-0 textCity">{selectedFlight.to_city}</h6>
                                                    <h6 className="m-0">{selectedFlight.arrival_date}</h6>
                                                    <h6 className="m-0">{selectedFlight.arrival_time}</h6>
                                                </Col>
                                                <Col lg="2" md="2" sm="2" xs="2" className="my-1 pr-0 text-center">
                                                    <h6 className="m-0">Seats</h6>
                                                    <h4 className="m-0">{selectedFlight.available_seats}</h4>
                                                </Col>
                                                <Col lg="3" md="3" sm="3" xs="3" className="my-1 pr-0">
                                                    <h6 className="m-0">Price</h6>
                                                    <h4 className="m-0">
                                                        {totalPrice && totalPrice !== 0
                                                            ? "$" + totalPrice.toFixed(2)
                                                            : "Upon Request"}
                                                    </h4>
                                                    <h6 className="m-0">{selectedFlight.is_charter ? "Charter" : "Total Price"}</h6>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col lg="4" md="4" sm="6" xs="6">
                                                    <h6 className="m-0">Cancellation</h6>
                                                    <p className="m-0">
                                                        {selectedFlight.cancellation_allowed ? (
                                                            <><FaCheck color="green" /> Allowed</>
                                                        ) : (
                                                            <><FaTimes color="red" /> Not Allowed</>
                                                        )}
                                                    </p>
                                                    {selectedFlight.cancellation_penalty && (
                                                        <p className="m-0">Penalty: {selectedFlight.cancellation_penalty_currency} {selectedFlight.cancellation_penalty}</p>
                                                    )}
                                                </Col>
                                                <Col lg="4" md="4" sm="6" xs="6">
                                                    <h6 className="m-0">Changes</h6>
                                                    <p className="m-0">
                                                        {selectedFlight.change_allowed ? (
                                                            <><FaCheck color="green" /> Allowed</>
                                                        ) : (
                                                            <><FaTimes color="red" /> Not Allowed</>
                                                        )}
                                                    </p>
                                                    {selectedFlight.change_penalty && (
                                                        <p className="m-0">Penalty: {selectedFlight.change_penalty_currency} {selectedFlight.change_penalty}</p>
                                                    )}
                                                </Col>
                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <h6 className="m-0">Baggage</h6>
                                                    <p className="m-0">
                                                        <FaSuitcaseRolling /> Checked: {selectedFlight.checked_bag}
                                                    </p>
                                                    <p className="m-0">
                                                        <FaBriefcase /> Carry-on: {selectedFlight.carry_bag}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col lg="12" md="12" sm="12" xs="12">
                                                    <h6 className="m-0">Amenities</h6>
                                                    <p className="m-0">
                                                        {selectedFlight.wifi && <><FaWifi /> Wi-Fi</>}
                                                        {selectedFlight.power && <><FaPlug className="ml-2" /> Power</>}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <div hidden={clientToken || showAncillaries}>

                                <h2 className="mt-4">Passenger Details</h2>
                                {passengers.map((passenger, index) => (
                                    <div key={passenger.id} className="mb-4 p-3 border rounded">
                                        <h3>Passenger {index + 1}</h3>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for={`title-${index}`}>Title*</Label>
                                                        <Input
                                                            type="select"
                                                            name="title"
                                                            id={`title-${index}`}
                                                            value={passenger.title}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="mr">Mr</option>
                                                            <option value="mrs">Mrs</option>
                                                            <option value="ms">Ms</option>
                                                            <option value="miss">Miss</option>
                                                            <option value="dr">Dr</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for={`gender-${index}`}>Gender*</Label>
                                                        <Input
                                                            type="select"
                                                            name="gender"
                                                            id={`gender-${index}`}
                                                            value={passenger.gender}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            required
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="m">Male</option>
                                                            <option value="f">Female</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for={`given_name-${index}`}> Name*</Label>
                                                        <Input
                                                            type="text"
                                                            name="given_name"
                                                            id={`given_name-${index}`}
                                                            value={passenger.given_name}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            title="First name must contain only letters"
                                                            required
                                                        />

                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for={`family_name-${index}`}>Family Name*</Label>
                                                        <Input
                                                            type="text"
                                                            name="family_name"
                                                            id={`family_name-${index}`}
                                                            value={passenger.family_name}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            required
                                                            title="Last name must contain only letters"
                                                        />

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for={`phone_number-${index}`}>Phone Number*</Label>
                                                        <PhoneInput
                                                            country={"us"}
                                                            value={passenger.phone_number}
                                                            onChange={(phone) => {
                                                                const formattedValue = phone.startsWith('+') ? phone : `+${phone}`;
                                                                if (/^\+\d{10,15}$/.test(formattedValue)) { // Validate phone number
                                                                    handleInputChange(index, { target: { name: 'phone_number', value: formattedValue } });
                                                                } else {
                                                                    setBookingError('Invalid phone number format.');
                                                                }
                                                            }}
                                                            inputProps={{
                                                                name: "phone_number",
                                                                id: `phone_number-${index}`,
                                                                required: true,
                                                                title: "Enter valid Phone number"
                                                            }}
                                                        />

                                                        {/* <Input
                                                            type="tel"
                                                            name="phone_number"
                                                            id={`phone_number-${index}`}
                                                            value={passenger.phone_number.startsWith('+') ? passenger.phone_number : `+${passenger.phone_number}`}
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                const formattedValue = value.startsWith('+') ? value : `+${value}`;
                                                                handleInputChange(index, { target: { name: 'phone_number', value: formattedValue } });
                                                            }}
                                                            title="Enter valid Phone number "
                                                        /> */}

                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for={`born_on-${index}`}>Date of Birth*</Label>
                                                        <Input
                                                            type="date"
                                                            name="born_on"
                                                            id={`born_on-${index}`}
                                                            value={passenger.born_on}
                                                            onChange={(e) => {
                                                                const selectedDate = new Date(e.target.value);
                                                                const today = new Date();
                                                                const age = today.getFullYear() - selectedDate.getFullYear();
                                                                const monthDiff = today.getMonth() - selectedDate.getMonth();
                                                                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selectedDate.getDate())) {
                                                                    age--;
                                                                }
                                                                if (age >= 18) {
                                                                    handleInputChange(index, e);
                                                                } else {
                                                                    setBookingError('Passenger must be at least 18 years old.');
                                                                }
                                                            }}
                                                            required
                                                            max={new Date().toISOString().split('T')[0]}
                                                            min={new Date(new Date().setFullYear(new Date().getFullYear() - 120)).toISOString().split('T')[0]}
                                                        />
                                                        {passenger.born_on && (new Date(passenger.born_on) > new Date() || new Date(passenger.born_on) < new Date(new Date().setFullYear(new Date().getFullYear() - 120))) && (
                                                            <div className="text-danger">Please enter a valid date of birth.</div>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6} hidden={!selectedFlight?.is_identity_document}>
                                                    <FormGroup>
                                                        <Label for={`passport_number-${index}`}>Passport Number*</Label>
                                                        <Input
                                                            type="text"
                                                            name="identity_documents.unique_identifier"
                                                            id={`unique_identifier-${index}`}
                                                            value={passenger.identity_documents[0]?.unique_identifier || ''}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            required
                                                            pattern="^[A-Z0-9]{6,9}$"
                                                            title="Passport number must be 6-9 characters long and contain only uppercase letters and numbers"
                                                        />
                                                        {passenger.identity_documents[0]?.unique_identifier && !/^[A-Z0-9]{6,9}$/.test(passenger.identity_documents[0]?.unique_identifier) && (
                                                            <div className="text-danger">Please enter a valid passport number (6-9 characters, uppercase letters and numbers only).</div>
                                                        )}
                                                    </FormGroup>

                                                </Col>
                                                <Col md={6} hidden={!selectedFlight?.is_identity_document}>
                                                    <FormGroup>
                                                        <Label for={`expires_on-${index}`}>Expiration Date*</Label>
                                                        <Input
                                                            type="date"
                                                            name="identity_documents.expires_on"
                                                            id={`expires_on-${index}`}
                                                            value={passenger.identity_documents[0]?.expires_on || ''}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            required
                                                            min={new Date().toISOString().split('T')[0]}
                                                        />
                                                        {passenger.identity_documents[0]?.expires_on && new Date(passenger.identity_documents[0]?.expires_on) <= new Date() && (
                                                            <div className="text-danger">Expiration date must be in the future.</div>
                                                        )}
                                                    </FormGroup></Col>
                                            </Row>
                                            <FormGroup hidden={!selectedFlight?.is_identity_document}>
                                                <Label for={`issuing_country_code-${index}`}>Issuing Country Code*</Label>
                                                <Select
                                                    name={`issuing_country_code-${index}`}
                                                    value={options.find(option => option.value === passenger.identity_documents[0]?.issuing_country_code)}
                                                    onChange={(selectedOption) => {
                                                        handleInputChange(index, {
                                                            target: {
                                                                name: 'identity_documents.issuing_country_code',
                                                                value: selectedOption.value,
                                                            },
                                                        });
                                                    }}
                                                    options={options}
                                                    placeholder="Select a country"
                                                />
                                                {passenger.identity_documents[0]?.issuing_country_code && !/^[A-Z]{2}$/.test(passenger.identity_documents[0]?.issuing_country_code) && (
                                                    <div className="text-danger">Please enter a valid country code (2 uppercase letters).</div>
                                                )}
                                            </FormGroup>
                                        </Form>
                                    </div>
                                ))}
                            </div>
                            {bookingError && <Alert color="danger" className="mt-3">{bookingError}</Alert>}

                            {/* {clientToken && (
                        <ErrorBoundary>
                            <div className="mt-4">

                                <duffel-payments></duffel-payments>

                            </div>
                        </ErrorBoundary>
                    )} */}

                            {/* {clientToken && (
                        <DuffelPortal>
                            <div id="duffel-payments-container" style={{ width: '100%', minHeight: '300px' }}>
                                <duffel-payments ref={duffelRef}></duffel-payments>
                            </div>
                        </DuffelPortal>
                    )} */}

                            {showAncillaries && !showPayment && (
                                <iframe
                                    src={`/duffel-ancillaries.html?offer_id=${selectedFlight.offr_id}&passengers=${encodeURIComponent(JSON.stringify(passengers))}&client_key=${clientKey}`}
                                    title="Duffel Ancillaries"
                                    width="100%"
                                    height="550px"
                                    style={{ border: 'none' }}
                                ></iframe>
                            )}

                            {showPayment && clientToken && (<iframe
                                src={`/duffel-payment.html?clientToken=${encodeURIComponent(clientToken)}`}  // Append the clientToken here
                                title="Duffel Payment"
                                ref={duffelRef}
                                width="90%"
                                height="250px"
                                style={{ border: 'none' }}
                            ></iframe>)}

                            {paymentStatus === 'success' && (
                                <Alert color="success">Booking successful! Your flight has been confirmed.</Alert>
                            )}

                            {paymentStatus === 'error' && (
                                <Alert color="danger">Payment failed. Please try again.</Alert>
                            )}
                        </>
                    )}
                </div>
                <ModalFooter>
                    {!showAncillaries && !paymentIntentId && passengers.length === selectedFlight?.passengerDetails?.length && (
                        <Button disabled={!validationOfData} color="primary" onClick={ProceedToSelectSeats}>
                            Proceed to Select Seats
                        </Button>
                    )}
                    {showAncillaries && !showPayment && (
                        <Button color="primary" onClick={handleProceedToPayment}>
                            Proceed to Payment
                        </Button>
                    )}
                    <Button color="primary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default DuffelBooking;
