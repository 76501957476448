import "date-fns";
import { useEffect, useState } from "react";
import {
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  FormGroup,
  Select,
  MenuItem,
  Snackbar,
  CircularProgress,
  Popover,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import {
  Col,
  Row,
  Button,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useFlightData } from "../../data/FlightData";
import City from "../City";
import airportData from "../../data/airport";
import { useLocalStorage } from "../../utils/useLocalStorage";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import LoginAndSignUp from "../LoginAndSignUp";
import SwapImage from "../../assets/images/icons/swap.png";
import { FaTimes } from "react-icons/fa";
import * as React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { useHistory } from "react-router-dom";
import PlansPopup from "../../pages/PlansPopup";

function Search({
  unregistedFlightsData,
  setFlightData,
  fromAirport,
  toAirport,
  searchDate,
}) {
  const currentDate = new Date();
  const {
    AllUnregFlights,
    UnregSearchFlights,
    searchFlights,
    searchFlyEasyFlights,
    searchVolatoCharters,
    searchDuffelFlights,
    allRegFlights,
    addFlight,
    FlightPrice,
  } = useFlightData();

  const [from, setFrom] = useState(fromAirport);
  const [to, setTo] = useState(toAirport);
  const [datevalue, setDateValue] = useState(searchDate || null);
  const [formatteddatevalue, setFormattedDateValue] = useState(datevalue ? getFormatedDate(datevalue) : null);
  const [passenger, setPassenger] = useState("1");
  const [pettype, SetPetType] = useState("No");
  const [friend, setFriend] = useState("No");
  const [isSearchClick, setIsSearchClick] = useState(false);
  const [user] = useLocalStorage("auth", {});
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFlightAdded, setFlightAdded] = useState(false);
  const [isPricePosted, setPricePosted] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [modal, setModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isSearchedOnce, setSearchedOnce] = useState(false);
  const handleClosePopup = () => setShowPopup(false);
  const handleShowPopup = () => setShowPopup(true);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Toggle for Modal
  const toggle = () => setModal(!modal);

  const ResetSearch = () => {
    history.push("/");
    //Reset search fields
    //setFrom(user.home_code || 'NYALL');
    //setTo(null);
    // setDateValue(null);
    // setPassenger("0");
    // setIsPrivate(false);

    // //Fetch default flights
    // if (typeof user.userid === "undefined") {
    //   GetUnregisteredFlights().then((data) => {
    //     setFlightData(data);
    //   });
    // } else {
    //   GetRegFlights(user.userid).then((data) => {
    //     setFlightData(data);
    //   });
    // }
  };

  async function convertFlightData(jsonData) {
    //const data = jsonData[0]; // get the first element from the provided array
    try {
      for (var i in jsonData) {
        let data = jsonData[i];
        const id = Math.floor(Math.random() * 90000) + 10000;
        const flyeasy_id = data._id;
        const userid = 55;
        var dep_date = data.date; //new Date(data.date).toLocaleDateString(); // extract the departure date and format it
        var dep_time;
        //const dep_time = data.flightTimeStr;//new Date(data.date1).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }); // extract the departure time and format it
        const plane_type = data.ac.title; // get the plane type from the provided data
        const from_city = data.airportFrom.city.toUpperCase(); // get the origin city and convert it to uppercase
        const to_city = data.airportTo.city.toUpperCase(); // get the destination city and convert it to uppercase
        const from_airport = data.airportFrom.iata; // get the origin airport code
        const to_airport = data.airportTo.iata; // get the destination airport code
        const total_seats = null; // no total seats provided in the original data
        const available_seats = data.ac.pax; // get the number of available seats from the provided data
        var total_price = Math.round(data.price); // convert the price to cents and round it
        const isflexible = false;
        const passenger = null;
        const is_pet = null;
        const pet_type = null;
        const is_posted = true;
        const name = "Katana";
        const flyxo_bookingurl = null;
        const isfriend = true;
        const isgroup = false;
        const departure_date = data.date1; // get the departure date and time in ISO format
        const profile_pic_path =
          require("../../assets/images/CharterDefaultIMage.jpg").default;
        const group_profile_pic_path = "";
        const group_name = null;
        const is_private = false;
        const inquiries = 0;
        const is_charter = true;
        const subscription_plan = 1;

        if (dep_date.includes("-")) {
          const parts = dep_date.split("-");
          dep_date = [parts[1], parts[2], parts[0].substring(2, 4)].join("/");
        } else {
          const parts = dep_date.split("/");
          dep_date = [parts[0], parts[1], parts[2].substring(2, 4)].join("/");
        }

        //if(total_price == 0) total_price = "Upon Request";
        jsonData[i] = {
          id,
          flyeasy_id,
          userid,
          dep_date,
          dep_time,
          plane_type,
          from_city,
          to_city,
          from_airport,
          to_airport,
          total_seats,
          available_seats,
          total_price,
          isflexible,
          passenger,
          is_pet,
          pet_type,
          is_posted,
          name,
          flyxo_bookingurl,
          isfriend,
          isgroup,
          departure_date,
          profile_pic_path,
          group_profile_pic_path,
          group_name,
          is_private,
          inquiries,
          is_charter,
          subscription_plan,
        };
      }

      return jsonData;
    } catch (err) {
      console.log("Conversion Err: " + err);
    }
  }

  async function convertFlightDataForDate(arr) {
    try {
      let resultArr = [];
      let obj = arr;
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set current date to midnight to only compare date parts

      for (var i in obj) {
        const date1 = obj[i].date1.substring(0, 10);
        const date2 = obj[i].date2.substring(0, 10);

        if (date1 !== date2) {
          let startDate = new Date(date1);
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(date2);
          endDate.setHours(0, 0, 0, 0);
          while (startDate <= endDate) {
            if (startDate >= currentDate) { // Check if the date is greater than or equal to the current date
              var formattedDate = startDate.toLocaleDateString().slice(0, 10);
              resultArr.push({ date: formattedDate, ...obj[i] });
            }
            startDate.setDate(startDate.getDate() + 1);
          }
        } else {
          if (new Date(date1) >= currentDate) { // Check if the single date is greater than or equal to the current date
            resultArr.push({ date: date1, ...obj[i] });
          }
        }
      }

      return resultArr;

    } catch (err) {
      console.log("Conversion Err: " + err);
    }
  }


  async function convertVolatoFlightData(jsonData) {
    try {
      let results = [];

      for (let i in jsonData) {
        let data = jsonData[i];
        const baseId = Math.floor(Math.random() * 90000) + 10000;
        const baseTotalPrice = Math.round(data.price);
        const baseFromAirport = getIATAFromICAO(data.itinerary[0].from);
        const baseToAirport = getIATAFromICAO(data.itinerary[0].to);
        const baseDepDate = new Date(data.itinerary[0].departUtc).toISOString().split('T')[0];
        const baseDepTime = data.itinerary[0].departLocal.split(' ')[1];
        const planeType = data.aircrafttype.label;
        const fromCity = getCityFromICAOCode(baseFromAirport) || baseFromAirport;
        const toCity = getCityFromICAOCode(baseToAirport) || baseToAirport;
        const availableSeats = data.aircrafttype.maxPassengers;
        const flyxoBookingUrl = data.quoteurl + '?email=' + user.email;
        const departureDate = data.itinerary[0].departUtc;
        const profilePicPath = "https://flykatana.com/volato.svg";

        const baseData = {
          userid: 929,
          plane_type: planeType,
          from_city: fromCity,
          to_city: toCity,
          total_seats: null,
          available_seats: availableSeats,
          isflexible: false,
          passenger: null,
          is_pet: null,
          pet_type: null,
          is_posted: true,
          name: "Katana",
          flyxo_bookingurl: flyxoBookingUrl,
          isfriend: false,
          isgroup: false,
          departure_date: departureDate,
          profile_pic_path: profilePicPath,
          group_profile_pic_path: "",
          group_name: null,
          is_private: false,
          inquiries: 0,
          is_charter: true,
          subscription_plan: 1,
          flight_source: 'Volato',
        };

        const mainRoute = {
          ...baseData,
          id: baseId,
          flyeasy_id: 123,
          dep_date: baseDepDate,
          dep_time: baseDepTime,
          from_airport: baseFromAirport,
          to_airport: baseToAirport,
          total_price: baseTotalPrice
        };

        results.push(mainRoute);

        const warnings = data.itinerary[0].warnings || [];
        warnings.forEach(warning => {
          const alternatives = warning.nearestAirportAlternatives || [];
          alternatives.forEach(alt => {
            const altId = Math.floor(Math.random() * 90000) + 10000;
            const isDepartureAlternative = warning.icao === baseFromAirport;
            const altFromAirport = isDepartureAlternative ? alt.icao : baseFromAirport;
            const altToAirport = isDepartureAlternative ? baseToAirport : alt.icao;
            const altFromCity = getCityFromICAOCode(altFromAirport) || alt.label;
            const altToCity = getCityFromICAOCode(altToAirport) || alt.label;
            const altPrice = baseTotalPrice > 0 ? (baseTotalPrice - 1250) : baseTotalPrice; // Adjust this if needed based on specific logic for alternate routes

            const altRoute = {
              ...baseData,
              id: altId,
              flyeasy_id: 123,
              dep_date: baseDepDate,
              dep_time: baseDepTime,
              from_airport: altFromAirport,
              to_airport: altToAirport,
              from_city: altFromCity,
              to_city: altToCity,
              total_price: altPrice
            };

            results.push(altRoute);
          });
        });

        const errors = data.itinerary[0].errors || [];
        errors.forEach(error => {
          const alternatives = error.nearestAirportAlternatives || [];
          alternatives.forEach(alt => {
            const altId = Math.floor(Math.random() * 90000) + 10000;
            const isDepartureAlternative = error.icao === baseFromAirport;
            const altFromAirport = isDepartureAlternative ? alt.icao : baseFromAirport;
            const altToAirport = isDepartureAlternative ? baseToAirport : alt.icao;
            const altFromCity = getCityFromICAOCode(altFromAirport);
            const altToCity = getCityFromICAOCode(altToAirport);
            const altPrice = baseTotalPrice; // Adjust this if needed based on specific logic for alternate routes

            const altRoute = {
              ...baseData,
              id: altId,
              flyeasy_id: 123,
              dep_date: baseDepDate,
              dep_time: baseDepTime,
              from_airport: altFromAirport,
              to_airport: altToAirport,
              from_city: altFromCity,
              to_city: altToCity,
              total_price: altPrice
            };

            results.push(altRoute);
          });
        });
      }

      return results;
    } catch (err) {
      console.log("Conversion Err: " + err);
    }
  }

  async function convertDuffelFlightData(jsonData, max_usd) {
    try {
      let results = [];

      for (let i in jsonData.offers) {
        let data = jsonData.offers[i];
        const id = Math.floor(Math.random() * 90000) + 10000;
        const flyeasy_id = null;
        const userid = 1000;
        const dep_date = new Date(data.slices[0]?.segments[0]?.departing_at || new Date()).toLocaleDateString();
        const dep_time = new Date(data.slices[0]?.segments[0]?.departing_at || new Date()).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        const arrival_date = new Date(data.slices[0]?.segments[0]?.arriving_at || new Date()).toLocaleDateString();
        const arrival_time = new Date(data.slices[0]?.segments[0]?.arriving_at || new Date()).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
        const plane_type = data.slices[0]?.segments[0]?.aircraft?.name || "Unknown Aircraft";
        const from_city = data.slices[0]?.segments[0]?.origin?.city_name?.toUpperCase() || "";
        const to_city = data.slices[0]?.segments[0]?.destination?.city_name?.toUpperCase() || "";
        const from_airport = data.slices[0]?.segments[0]?.origin?.iata_code || "";
        const to_airport = data.slices[0]?.segments[0]?.destination?.iata_code || "";
        const total_seats = null;
        const available_seats = data.slices[0]?.segments[0]?.passengers?.length || 0;

        const total_price_all_seats = Math.round((data.total_amount * 1.03 || 0) * 1.05);

        // // Calculate the price with 3% markup and then 5% on top
        // const basePrice = data.total_amount || 0;
        // const priceWith3PercentFee = basePrice * 1.03;

        // const total_price_all_seats = Math.round(priceWith3PercentFee * 1.05);
        const duffel_price = data.total_amount;

        //Per Seat Price
        const total_price = available_seats > 0 ? total_price_all_seats / available_seats : 0;

        const isflexible = false;
        const is_duffel = true;

        const passenger = null;
        const is_pet = null;
        const pet_type = null;
        const is_posted = true;
        const airlines_name = data.owner?.name || "";
        const flyxo_bookingurl = null;
        const isfriend = false;
        const isgroup = false;
        const departure_date = data.slices[0]?.segments[0]?.departing_at || "";
        const arrival_Date = data.slices[0]?.segments[0]?.arriving_at || "";
        const profile_pic_path = data.owner?.logo_symbol_url || "";
        const group_profile_pic_path = "";
        const group_name = null;
        const is_private = false;
        const inquiries = 0;
        const is_charter = false;  // Since Duffel is not a charter
        const subscription_plan = 1;

        // New fields
        const cancellation_allowed = data.conditions?.change_before_departure?.allowed || false;
        const cancellation_penalty = data.conditions?.change_before_departure?.penalty_amount || 0;
        const cancellation_penalty_currency = data.conditions?.change_before_departure?.penalty_currency || "USD";

        const change_allowed = data.conditions?.change_before_departure?.allowed || false;
        const change_penalty = data.conditions?.change_before_departure?.penalty_amount || 0;
        const change_penalty_currency = data.conditions?.change_before_departure?.penalty_currency || "USD";
        const baggage = data.slices[0]?.segments[0]?.passengers[0]?.baggages || [];
        const checked_bag = baggage.find(bag => bag.type === 'checked')?.quantity || 0;
        const carry_bag = baggage.find(bag => bag.type === 'carry_on')?.quantity || 0;
        const amenities = data.slices[0]?.segments[0]?.passengers[0]?.cabin?.amenities || {};
        const wifi = amenities.wifi || false;
        const power = amenities.power || false;
        const passengerDetails = jsonData.passengers || [];
        const is_identity_document = data.passenger_identity_documents_required || false;
        const offr_id = data?.id
        const duffle_limit = Number(max_usd)
        const client_key = jsonData.client_key;

        const flightData = {
          id,
          flyeasy_id,
          is_duffel,
          userid,
          dep_date,
          dep_time,
          arrival_date,
          arrival_time,
          plane_type,
          from_city,
          to_city,
          from_airport,
          to_airport,
          total_seats,
          available_seats,
          total_price,
          total_price_all_seats,
          duffel_price,
          isflexible,
          passenger,
          is_pet,
          pet_type,
          is_posted,
          airlines_name,
          flyxo_bookingurl,
          isfriend,
          isgroup,
          departure_date,
          profile_pic_path,
          group_profile_pic_path,
          group_name,
          is_private,
          inquiries,
          is_charter,
          subscription_plan,
          cancellation_allowed,
          cancellation_penalty,
          cancellation_penalty_currency,
          change_allowed,
          change_penalty,
          change_penalty_currency,
          checked_bag,
          carry_bag,
          wifi,
          power,
          passengerDetails,
          is_identity_document,
          offr_id,
          duffle_limit,
          client_key
        };

        results.push(flightData);
      }

      return results;
    } catch (err) {
      console.log("Conversion Err: " + err);
      return [];
    }
  }

  const GetSearchFlightDetails = async () => {
    let postData = {
      dep_date: formatteddatevalue,
      from_city: getCityFromCode(from),
      from_airport: from,
      to_city: getCityFromCode(to),
      to_airport: to,
      passenger: parseInt(passenger === "" ? 1 : passenger),
      is_pet: pettype === "No" ? false : true,
      pet_type: pettype,
    };

    if (typeof user.userid !== "undefined") {
      postData.userid = parseInt(user.userid);
      postData.is_friend = friend === "No" ? false : true;
      postData.is_private = isPrivate;
    }
    let flights;
    if (typeof user.userid === "undefined") {
      flights = await UnregSearchFlights(postData);
    } else {
      flights = await searchFlights(postData, user);
    }
    let flyeasy_flights, volato_flights, duffel_flights;
    //ALI 1-2-2024 FLYEASY WORKS NOW WITH ONLY FROM CITY
    if (postData.from_city) {
      postData.from_icao = getICAOFromIATA(from);
      postData.to_icao = getICAOFromIATA(to);
      flyeasy_flights = await searchFlyEasyFlights(postData);
      let fl_flights = flyeasy_flights.flights;
      if (fl_flights.length > 0) {
        fl_flights = await convertFlightDataForDate(fl_flights);
        fl_flights = await convertFlightData(fl_flights);
        //console.log("FlyEasy flights: "+JSON.stringify(flyeasy_flights.flights));
        await flights.push(...fl_flights);

        //Sort based on date and time
        flights = await flights.sort((a, b) => {
          if (a.dep_date) {
            const aTimestamp = new Date(`${a.dep_date}`).getTime();
            const bTimestamp = new Date(`${b.dep_date}`).getTime();
            //console.log(aTimestamp);
            return aTimestamp - bTimestamp;
          }
          return 0;
        });
        //console.log("Sorted flights: "+JSON.stringify(flights));
      }
    }

    //if (postData.to_city) {
    if (postData.from_city && postData.to_city) {
      postData.from_icao = getICAOFromIATA(from);
      postData.to_icao = getICAOFromIATA(to);
      volato_flights = await searchVolatoCharters(postData);
      let vl_flights = volato_flights.offers;
      if (vl_flights.length > 0) {
        vl_flights = await convertVolatoFlightData(vl_flights);
        //console.log("FlyEasy flights: "+JSON.stringify(flyeasy_flights.flights));
        await flights.push(...vl_flights);

        //Sort based on date and time
        flights = await flights.sort((a, b) => {
          if (a.dep_date) {
            const aTimestamp = new Date(`${a.dep_date}`).getTime();
            const bTimestamp = new Date(`${b.dep_date}`).getTime();
            //console.log(aTimestamp);
            return aTimestamp - bTimestamp;
          }
          return 0;
        });
        //console.log("Sorted flights: "+JSON.stringify(flights));
      }
    }

    if (postData.from_city && postData.to_city && postData.dep_date) {
      // duffel_flights = await searchDuffelFlights(postData);
      // const duffel_flights_max_usd = duffel_flights.max_usd;
      // duffel_flights = duffel_flights.data;


      // duffel_flights = await convertDuffelFlightData(duffel_flights, duffel_flights_max_usd);
      // await flights.push(...duffel_flights);


    }

    return flights;
  };

  useEffect(() => {
    if (typeof user.userid === "undefined") {
      GetUnregisteredFlights().then((data) => {
        setFlightData(data);
      });
    } else {
      //console.log(user);
      //setFrom(user.home_code || 'NYALL');
      GetRegFlights(user.userid).then((data) => {
        setFlightData(data);
      });
    }
  }, []);

  useEffect(() => {
    if (true) {
      GetSearchFlightDetails().then((data) => {
        setFlightData(data);
      });
    }
  }, [pettype]);

  function getFormatedDate(strDate) {
    let date = new Date(strDate);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return month + "/" + dt + "/" + year;
  }

  function getCityFromCodeForNearby(code) {
    if (code && airportData.find((item) => item.IATA === code))
      return airportData.find((item) => item.IATA === code)?.city;
    else return null;
  }

  function getCityFromCode(code) {
    if (code) return airportData.find((item) => item.IATA === code)?.city;
    else return null;
  }

  function getCityFromICAOCode(code) {
    if (code) return airportData.find((item) => item.icao_code === code)?.city.toUpperCase();
    else return null;
  }

  function getICAOFromIATA(iata) {
    if (iata)
      return airportData.find((item) => item.IATA === iata).icao_code || iata;
    else return iata;
  }

  function getIATAFromICAO(icao) {
    if (icao) {
      const airport = airportData.find((item) => item.icao_code === icao);
      return airport ? airport.IATA : icao;
    }
    return icao;
  }


  const GetUnregisteredFlights = async () => {
    return await AllUnregFlights();
  };

  const GetRegFlights = async (userid) => {
    return await allRegFlights(userid, user);
  };

  const DateChange = (date) => {
    setFormattedDateValue(getFormatedDate(date));
    setDateValue(date);
  };

  function SearchUnregisterData(event) {
    if (from == "" || from == undefined || from == null) {
      setErrorMessage("Please select from");
      setIsError(true);
    } else {
      setIsSearchClick(true);
      setSearchedOnce(true);
      GetSearchFlightDetails().then((data) => {
        setFlightData(data);
      });
    }
  }

  function SwapCity(e) {
    if (to !== "" && to !== undefined && to !== null) {
      const From = from;
      setFrom(to);
      setTo(From);
    }
  }

  function ValidateData() {
    if (from === "" || from === undefined || from === null) {
      setErrorMessage("Please select from");
      setIsError(true);
      return false;
    } else if (to === "" || to === undefined || to === null) {
      setErrorMessage("Please select to");
      setIsError(true);
      return false;
    } else if (
      formatteddatevalue === "" ||
      formatteddatevalue === undefined ||
      formatteddatevalue === null
    ) {
      setErrorMessage("Please select valid date");
      setIsError(true);
      return false;
    } else if (
      passenger === "" ||
      passenger === undefined ||
      passenger === null ||
      passenger < 1
    ) {
      setErrorMessage("Please select passenger");
      setIsError(true);
      return false;
    } else {
      setErrorMessage("");
      setIsError(false);
      return true;
    }
  }

  async function AddFlightDetails(event) {
    if (user.subscription_plan_id == 2 || user.subscription_plan_id == 3) {
      if (ValidateData()) {
        const postData = {
          userid: user.userid,
          dep_date: formatteddatevalue,
          from_city: getCityFromCode(from),
          from_airport: from,
          to_city: getCityFromCode(to),
          to_airport: to,
          passenger: parseInt(passenger === "" ? "0" : passenger),
          is_pet: pettype === "No" ? false : true,
          pet_type: pettype,
          is_private: isPrivate,
        };
        const response = await addFlight(postData, user);
        if (response) {
          setFlightAdded(true);
          toggle(); //All Set popup
        }
      }
    } else {
      handleShowPopup();
      //history.push('/plans');
    }
  }

  async function BookFlightPrice(event) {
    if (ValidateData()) {
      const postData = {
        userid: user.userid,
        dep_date: formatteddatevalue,
        from_city: getCityFromCode(from),
        from_airport: from,
        to_city: getCityFromCode(to),
        to_airport: to,
        passenger: parseInt(passenger === "" ? "0" : passenger),
        is_pet: pettype === "No" ? false : true,
        pet_type: pettype,
        is_private: isPrivate,
      };
      const response = await FlightPrice(postData);
      if (response.status) {
        setPricePosted(true);
        toggle(); //All Set popup
      }
    }
  }

  return (
    <>
      {isError && (
        <Snackbar
          open={isError}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => setIsError(!isError)}
        >
          <Alert style={{ backgroundColor: '#ffe000', color: 'black' }}>{errorMessage}</Alert>
        </Snackbar>
      )}

      <PlansPopup show={showPopup} handleClose={handleClosePopup} />

      {isPricePosted && (
        <Snackbar
          open={isPricePosted}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => setPricePosted(!isPricePosted)}
        >
          <Alert className="alertYellowInverse">
            Katana will be in touch shortly
          </Alert>
        </Snackbar>
      )}

      {isFlightAdded && (
        <Snackbar
          open={isFlightAdded}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => setFlightAdded(!isFlightAdded)}
        >
          <Alert className="alertYellowInverse">All Set</Alert>
        </Snackbar>
      )}
      <Row className="pl-2 pr-1 mt-2 search-data-parent-div">
        {/* From City */}
        <Col lg="5" md="5" sm="12" xs="12">
          <City type="from" code={from} setCode={setFrom} />
        </Col>

        {/* Swap icon */}
        <Col
          lg="2"
          md="2"
          sm="12"
          xs="12"
          className="d-flex justify-content-center align-items-center mt-3 mt-lg-0"
        >
          <img src={SwapImage} className="btnSwap" onClick={SwapCity} />
        </Col>

        {/* To City */}
        <Col lg="5" md="5" sm="16" xs="16">
          <City type="to" code={to} setCode={setTo} />
        </Col>

        {/* Date Picker */}
        <Col lg="6" md="6" sm="12" xs="12" className="my-3">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ minWidth: "150px" }}
              className="p-0"
              fullWidth
              label={<span style={{ color: "#3B5E75", fontWeight: "900", fontSize: "20px" }}>Date</span>}
              minDate={new Date().toString()}
              format="MM/dd/yyyy"
              value={datevalue}
              onChange={DateChange}
              placeholder="Anytime"
              InputLabelProps={{
                shrink: true,
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={
                <img
                  alt="..."
                  style={{ height: "25px" }}
                  src={
                    require("../../assets/images/icons/calendar.png").default
                  }
                />
              }
            />
          </MuiPickersUtilsProvider>
          {datevalue && (
            <FaTimes
              style={{
                position: "absolute",
                bottom: "25px",
                right: "10px",
                cursor: "pointer",
                minWidth: "150px"
              }}
              onClick={(e) => {
                setDateValue(null);
                setFormattedDateValue(null);
              }}
            />
          )}
        </Col>
        {/* Passengers */}
        <Col lg="6" md="6" sm="12" xs="12" className="my-3">
          <FormGroup className="mb-3" style={{ minWidth: "50px" }} >
            <FormControl fullWidth>
              <InputLabel htmlFor="passengers"><span style={{ color: "#3B5E75", fontWeight: "900", fontSize: "20px" }}> Passengers</span></InputLabel>
              <Select
                id="passengers"
                value={passenger}
                onChange={(e) => setPassenger(e.target.value)}
                // startAdornment={
                //   <InputAdornment position="start">
                //     <img
                //       alt="..."
                //       style={{ height: "25px" }}
                //       src={
                //         require("../../assets/images/icons/passengers.png")
                //           .default
                //       }
                //     />
                //   </InputAdornment>
                // }
                renderValue={(selected) => `${selected}`}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Col>
        {/*
        <Col lg="6" md="6" sm="6" xs="6" className="p-0">
          {typeof user.userid !== "undefined" && (
            <FormGroup>
              <FormControl>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                    justifyContent: "left",
                  }}
                >
                  <span>&nbsp;&nbsp;&nbsp;Public:&nbsp;</span>
                  <input
                    type="radio"
                    name="public"
                    value="Yes"
                    checked={!isPrivate}
                    onChange={(e) => setIsPrivate(false)}
                  ></input>
                  <span
                    onClick={(e) => setIsPrivate(false)}
                    style={{ cursor: "pointer", 'font-size':'14px' }}
                  >
                    Yes
                  </span>
                  &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="public"
                    value="No"
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(true)}
                  ></input>
                  <span
                    onClick={(e) => setIsPrivate(true)}
                    style={{ cursor: "pointer", 'font-size':'14px' }}
                  >
                    No
                  </span>
                  <div style={{ 'padding-left': '2px'}}>
                <HelpIcon sx={{ fontSize: 16,  }}  aria-describedby={id} variant="contained" onClick={handleClick}>                
                </HelpIcon>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography style={{background:"#FFE600"}}  sx={{ p: 2 }}>If Not Public (No) only Friends and Members of the same Group will be notified and see on site.</Typography>
                </Popover>
              </div>
                </div>                
              </FormControl>
            </FormGroup>
          )} */}
        {/* <FilterPet pet={pet} setPet={setPet} /> */}
        {/* <FormGroup className="mb-3">
            <FormControl fullWidth>
              <InputLabel htmlFor="pet">Pet</InputLabel>
              <Select
                id="pet"
                value={pettype}
                onChange={(e) =>
                  SetPetType(e.target.value == "" ? "No" : e.target.value)
                }
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      alt="..."
                      style={{ height: "25px" }}
                      src={require("../../assets/images/icons/pet.png").default}
                    />
                  </InputAdornment>
                }
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Cat">Cat</MenuItem>
                <MenuItem value="Dog">Dog</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
          </FormGroup> */}
        {/* </Col> */}

        {typeof user.userid !== "undefined" ? (
          <>
            <Col lg="10" md="12" sm="12" xs="12" className="pr-4 pl-3 mb-2 mx-auto">
              <Button
                color="dark"
                className="btndarkblue border-0"
                onClick={SearchUnregisterData}
              >
                Search
              </Button>
            </Col>
            {isSearchedOnce && (
              <>
                <Col lg="5" md="6" sm="6" xs="6" className="pl-3 ml-auto">
                  <Button
                    color=""
                    className="btnBorderdarkblue"
                    onClick={AddFlightDetails}
                  >
                    Add Interest
                  </Button>
                </Col>
                <Col lg="5" md="6" sm="6" xs="6" className="pr-4 mr-auto">
                  <Button
                    color=""
                    className="btnBorderdarkblue"
                    onClick={BookFlightPrice}
                  >
                    Get Price
                  </Button>
                </Col>
              </>
            )}
            <Snackbar
              autoHideDuration={2000}
              toggle={toggle}
              color="dark"
              open={modal}
              onClose={toggle}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              keyboard={false}
              style={{ height: "100%", width: "100%" }}
            >
              <Alert
                className="text-center"
                color="dark"
                style={{ color: "rgb(255, 230, 0)" }}
              >
                <CheckCircleOutlineIcon />
                <br />
                {"All Set"}
              </Alert>
            </Snackbar>
          </>
        ) : (
          <>
            <Col lg="10" md="12" sm="12" xs="12" className="my-2 mx-auto" style={{ marginLeft: "-4px" }}>
              <Button
                color=""
                className="btndarkblue"
                onClick={SearchUnregisterData}
              >
                Search
              </Button>
            </Col>
            <Col lg="5" md="6" sm="6" xs="6" style={{ paddingLeft: "11px", marginLeft: "auto" }}>
              <LoginAndSignUp
                ButtonMode="AddFlight"
                isSearchedOnce={isSearchedOnce}
              />
            </Col>
            <Col lg="5" md="6" sm="6" xs="6" style={{ paddingRight: "20px", marginRight: "auto" }}>
              <LoginAndSignUp
                ButtonMode="GetPrice"
                isSearchedOnce={isSearchedOnce}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );

  // return (
  //   <>
  //     {isError && (
  //       <Snackbar
  //         open={isError}
  //         autoHideDuration={3000}
  //         anchorOrigin={{ horizontal: "center", vertical: "top" }}
  //         onClose={(e) => setIsError(!isError)}
  //       >
  //         <Alert color="danger">{errorMessage}</Alert>
  //       </Snackbar>
  //     )}

  //     <PlansPopup show={showPopup} handleClose={handleClosePopup} />

  //     {isPricePosted && (
  //       <Snackbar
  //         open={isPricePosted}
  //         autoHideDuration={6000}
  //         anchorOrigin={{ horizontal: "center", vertical: "top" }}
  //         onClose={(e) => setPricePosted(!isPricePosted)}
  //       >
  //         <Alert className="alertYellowInverse">
  //           Katana will be in touch shortly
  //         </Alert>
  //       </Snackbar>
  //     )}

  //     {isFlightAdded && (
  //       <Snackbar
  //         open={isFlightAdded}
  //         autoHideDuration={3000}
  //         anchorOrigin={{ horizontal: "center", vertical: "top" }}
  //         onClose={(e) => setFlightAdded(!isFlightAdded)}
  //       >
  //         <Alert className="alertYellowInverse">All Set</Alert>
  //       </Snackbar>
  //     )}
  //     <Row className="pl-2 pr-1 mt-2">
  //       {/* From City */}
  //       <Col lg="3" md="3" sm="12" xs="12">
  //         <City type="from" code={from} setCode={setFrom} />
  //       </Col>

  //       {/* Swap icon */}
  //       <Col
  //         lg="1"
  //         md="1"
  //         sm="12"
  //         xs="12"
  //         className="d-flex justify-content-center align-items-center mt-3 mt-lg-0"
  //       >
  //         <img src={SwapImage} className="btnSwap" onClick={SwapCity} />
  //       </Col>

  //       {/* To City */}
  //       <Col lg="3" md="4" sm="16" xs="16">
  //         <City type="to" code={to} setCode={setTo} />
  //       </Col>

  //       {/* Date Picker */}
  //       <Col lg="2" md="2" sm="12" xs="12" className="mb-3">
  //         <MuiPickersUtilsProvider utils={DateFnsUtils}>
  //           <KeyboardDatePicker
  //             className="p-0"
  //             fullWidth
  //             label="Date"
  //             minDate={new Date().toString()}
  //             format="MM/dd/yyyy"
  //             value={datevalue}
  //             onChange={DateChange}
  //             KeyboardButtonProps={{
  //               "aria-label": "change date",
  //             }}
  //             keyboardIcon={
  //               <img
  //                 alt="..."
  //                 style={{ height: "25px" }}
  //                 src={
  //                   require("../../assets/images/icons/calendar.png").default
  //                 }
  //               />
  //             }
  //           />
  //         </MuiPickersUtilsProvider>
  //         {datevalue && (
  //           <FaTimes
  //             style={{
  //               position: "absolute",
  //               bottom: "5px",
  //               right: "10px",
  //               cursor: "pointer",
  //             }}
  //             onClick={(e) => {
  //               setDateValue(null);
  //               setFormattedDateValue(null);
  //             }}
  //           />
  //         )}
  //       </Col>
  //       <Col lg="1" md="2" sm="12" xs="12">
  //         <FormGroup className="mb-3">
  //           <FormControl fullWidth>
  //             <InputLabel htmlFor="passengers">Passengers</InputLabel>
  //             <Select
  //               id="passengers"
  //               value={passenger}
  //               onChange={(e) => setPassenger(e.target.value)}
  //               startAdornment={
  //                 <InputAdornment position="start">
  //                   {/* <img
  //                     alt="..."
  //                     style={{ height: "25px" }}
  //                     src={
  //                       require("../../assets/images/icons/passengers.png")
  //                         .default
  //                     }
  //                   /> */}
  //                 </InputAdornment>
  //               }
  //             >
  //               <MenuItem value={1}>1</MenuItem>
  //               <MenuItem value={2}>2</MenuItem>
  //               <MenuItem value={3}>3</MenuItem>
  //               <MenuItem value={4}>4</MenuItem>
  //               <MenuItem value={5}>5</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </FormGroup>
  //       </Col>
  //       {/* <Col lg="6" md="6" sm="6" xs="6">
  //         <FormGroup className="mb-3">
  //           <FormControl fullWidth>
  //             <InputLabel htmlFor="passengers">Passengers</InputLabel>
  //             <Select
  //               id="passengers"
  //               value={passenger}
  //               onChange={(e) => setPassenger(e.target.value)}
  //               startAdornment={
  //                 <InputAdornment position="start">
  //                   <img
  //                     alt="..."
  //                     style={{ height: "25px" }}
  //                     src={
  //                       require("../../assets/images/icons/passengers.png")
  //                         .default
  //                     }
  //                   />
  //                 </InputAdornment>
  //               }
  //             >
  //               <MenuItem value={0}>Select</MenuItem>
  //               <MenuItem value={1}>1</MenuItem>
  //               <MenuItem value={2}>2</MenuItem>
  //               <MenuItem value={3}>3</MenuItem>
  //               <MenuItem value={4}>4</MenuItem>
  //               <MenuItem value={5}>5</MenuItem>
  //               <MenuItem value={6}>6</MenuItem>
  //               <MenuItem value={7}>7</MenuItem>
  //               <MenuItem value={8}>8</MenuItem>
  //               <MenuItem value={9}>9</MenuItem>
  //               <MenuItem value={10}>10</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </FormGroup>
  //       </Col>
  //       <Col lg="6" md="6" sm="6" xs="6" className="p-0">
  //         {typeof user.userid !== "undefined" && (
  //           <FormGroup>
  //             <FormControl>
  //               <div
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   marginTop: "20px",
  //                   justifyContent: "left",
  //                 }}
  //               >
  //                 <span>&nbsp;&nbsp;&nbsp;Public:&nbsp;</span>
  //                 <input
  //                   type="radio"
  //                   name="public"
  //                   value="Yes"
  //                   checked={!isPrivate}
  //                   onChange={(e) => setIsPrivate(false)}
  //                 ></input>
  //                 <span
  //                   onClick={(e) => setIsPrivate(false)}
  //                   style={{ cursor: "pointer", 'font-size':'14px' }}
  //                 >
  //                   Yes
  //                 </span>
  //                 &nbsp;&nbsp;
  //                 <input
  //                   type="radio"
  //                   name="public"
  //                   value="No"
  //                   checked={isPrivate}
  //                   onChange={(e) => setIsPrivate(true)}
  //                 ></input>
  //                 <span
  //                   onClick={(e) => setIsPrivate(true)}
  //                   style={{ cursor: "pointer", 'font-size':'14px' }}
  //                 >
  //                   No
  //                 </span>
  //                 <div style={{ 'padding-left': '2px'}}>
  //               <HelpIcon sx={{ fontSize: 16,  }}  aria-describedby={id} variant="contained" onClick={handleClick}>                
  //               </HelpIcon>
  //               <Popover
  //                 id={id}
  //                 open={open}
  //                 anchorEl={anchorEl}
  //                 onClose={handleClose}
  //                 anchorOrigin={{
  //                   vertical: 'bottom',
  //                   horizontal: 'left',
  //                 }}
  //               >
  //                 <Typography style={{background:"#FFE600"}}  sx={{ p: 2 }}>If Not Public (No) only Friends and Members of the same Group will be notified and see on site.</Typography>
  //               </Popover>
  //             </div>
  //               </div>                
  //             </FormControl>
  //           </FormGroup>
  //         )} */}
  //       {/* <FilterPet pet={pet} setPet={setPet} /> */}
  //       {/* <FormGroup className="mb-3">
  //           <FormControl fullWidth>
  //             <InputLabel htmlFor="pet">Pet</InputLabel>
  //             <Select
  //               id="pet"
  //               value={pettype}
  //               onChange={(e) =>
  //                 SetPetType(e.target.value == "" ? "No" : e.target.value)
  //               }
  //               startAdornment={
  //                 <InputAdornment position="start">
  //                   <img
  //                     alt="..."
  //                     style={{ height: "25px" }}
  //                     src={require("../../assets/images/icons/pet.png").default}
  //                   />
  //                 </InputAdornment>
  //               }
  //             >
  //               <MenuItem value="No">No</MenuItem>
  //               <MenuItem value="Cat">Cat</MenuItem>
  //               <MenuItem value="Dog">Dog</MenuItem>
  //               <MenuItem value="Other">Other</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </FormGroup> */}
  //       {/* </Col> */}

  //       {typeof user.userid !== "undefined" ? (
  //         <>
  //           <Col lg="2" md="2" sm="12" xs="12" className="p-1">
  //             <Button
  //               color="dark"
  //               className="btn btn-block btn btn-secondary btnYellow"
  //               onClick={SearchUnregisterData}
  //             >
  //               Search
  //             </Button>
  //           </Col>
  //           {/* <Col lg="4" md="4" sm="4" xs="4" className="p-1">
  //             <Button
  //               color="dark"
  //               className="btn btn-block btn btn-secondary btnYellow"
  //               onClick={ResetSearch}
  //             >
  //               Reset
  //             </Button>
  //           </Col> */}
  //           {isSearchedOnce && (
  //             <>
  //               <Col lg="6" md="6" sm="6" xs="6" className="p-1">
  //                 <Button
  //                   color="dark"
  //                   className="btn btn-block btn btn-secondary btnYellow"
  //                   onClick={AddFlightDetails}
  //                 >
  //                   Add Interest
  //                 </Button>
  //               </Col>
  //               <Col lg="6" md="6" sm="6" xs="6" className="p-1">
  //                 <Button
  //                   color="dark"
  //                   className="btn btn-block btn btn-secondary btnYellow"
  //                   onClick={BookFlightPrice}
  //                 >
  //                   Get Price
  //                 </Button>
  //               </Col>
  //             </>
  //           )}
  //           <Snackbar
  //             autoHideDuration={2000}
  //             toggle={toggle}
  //             color="dark"
  //             open={modal}
  //             onClose={toggle}
  //             anchorOrigin={{ horizontal: "center", vertical: "top" }}
  //             keyboard={false}
  //             style={{ height: "100%", width: "100%" }}
  //           >
  //             <Alert
  //               className="text-center"
  //               color="dark"
  //               style={{ color: "rgb(255, 230, 0)" }}
  //             >
  //               <CheckCircleOutlineIcon />
  //               <br />
  //               {"All Set"}
  //             </Alert>
  //           </Snackbar>
  //         </>
  //       ) : (
  //         <>
  //           <Col lg="2" md="2" sm="12" xs="12" className="p-1">
  //             <Button
  //               color="dark"
  //               className="btn btn-block btn btn-secondary btnYellow"
  //               onClick={SearchUnregisterData}
  //             >
  //               Search
  //             </Button>
  //           </Col>
  //           {/* <Col lg="4" md="4" sm="4" xs="4" className="p-1">
  //             <Button
  //               color="dark"
  //               className="btn btn-block btn btn-secondary btnYellow"
  //               onClick={ResetSearch}
  //             >
  //               Reset
  //             </Button>
  //           </Col> */}
  //           <Col lg="6" md="6" sm="6" xs="6" className="p-1">
  //             <LoginAndSignUp
  //               ButtonMode="AddFlight"
  //               isSearchedOnce={isSearchedOnce}
  //             />
  //           </Col>
  //           <Col lg="6" md="6" sm="6" xs="6" className="p-1">
  //             <LoginAndSignUp
  //               ButtonMode="GetPrice"
  //               isSearchedOnce={isSearchedOnce}
  //             />
  //           </Col>
  //         </>
  //       )}
  //     </Row>
  //   </>
  // );
}

export default Search;
