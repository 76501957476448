import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FaRegTimesCircle, } from 'react-icons/fa'
import { Modal, Alert, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, CardHeader } from "reactstrap";
import Login from '../components/AuthPages/Login'
import ForgotPassword from '../components/AuthPages/ForgotPassword'
import SignUp from "../components/AuthPages/SignUp";
import './LoginAndSignUp.css'
import "./Flights.css";

function LoginAndSignUp({ ButtonMode, flight, openmodal, openmode, isSearchedOnce, isNavsideBar = false, isMobile = false }) {
  const [modal, setModal] = useState(openmodal ? openmodal : false);
  const [mode, setMode] = useState(openmode ? openmode : "Login");
  const toggle = () => setModal(!modal);
  const [isPasswordChanged, setPasswordChanged] = useState(false);
  const [isSignUpSuccess, setSignUpSuccess] = useState(false);


  useEffect(() => {
    setTimeout(function () {
      if (mode) { setMode(mode) } else { setMode("Login") };
    }, 200);
  }, [modal]);
  const HandelInquire = () => {
    setModal(!modal);
    localStorage?.setItem("Inquire", true)
  }
  return (
    <>
      {ButtonMode === "Join" && (
        <Button
          className={
            flight.is_posted
              ? "Inquire-btndarkblue"
              : "btn-sm btn-block btnGray"
          }
          onClick={HandelInquire}
        >
          Inquire
        </Button>
      )}

      {ButtonMode === "PathArea" && (
        <div className={"PathTypeButton-no-border"} onClick={toggle}>
          SELL SEATS
        </div>
      )}

      {ButtonMode === "AddFlight" && isSearchedOnce && (
        <Button
          color=""
          className="btnBorderdarkblue"
          onClick={toggle}
        >
          Add Interest
        </Button>
      )}

      {ButtonMode === "GetPrice" && isSearchedOnce && (
        <Button
          color=""
          className="btnBorderdarkblue"
          onClick={toggle}
        >
          Get Price
        </Button>
      )}

      {!ButtonMode && !openmode && (
        <Button color="transparent" style={{ borderRadius: "20px", color: isNavsideBar == isMobile ? "#3B5E75" : "#ffe000", fontWeight: "normal", paddingTop: "0.75rem", fontSize: "17px" }} onClick={toggle}>
          Log In
        </Button>
      )}

      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={"static"}
        keyboard={false}
      >
        <ModalBody className="p-0">
          <Card className="shadow border-0">
            <CardHeader className="p-0">
              <div className="text-right p-2">
                <FaRegTimesCircle onClick={toggle} cursor="pointer" />
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-2">
              <div className="text-center mb-3">
                <img
                  alt="..."
                  src={require("../assets/images/icons/logo1.png").default}
                  style={{ height: "auto", width: "80%" }}
                />
              </div>
              {isPasswordChanged && (
                <Alert className="alertYellow">
                  Password Changed Successfully, Please Login With Your New
                  Password.
                </Alert>
              )}
              {isSignUpSuccess && (
                <Alert className="alertYellow">
                  <div className="signup-success"></div>
                  Signed up successfully. Please enter your credentials to logIn.
                </Alert>
              )}

              {mode === "ForgotPassword" && (
                <ForgotPassword
                  setMode={setMode}
                  setPasswordChanged={setPasswordChanged}
                />
              )}

              {mode === "Login" && (
                <>
                  <Login setMode={setMode} />
                </>
              )}

              {mode === "SignUp" && (
                <>
                  <SignUp
                    setMode={setMode}
                    setSignUpSuccess={setSignUpSuccess}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
}

export default LoginAndSignUp
