import { Input } from "antd";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Table,
  Button,
  CardBody,
} from "reactstrap";


function ContactListModal({
  modal,
  setModal,
  contactList,
  SetMobile,
  SetName,
  search,
  SetSearch,
}) {
    const toggle = () => setModal(!modal);

    function SelectContact(contact) {
      SetMobile(contact.phone);
      SetName(contact.name);
      toggle();
    }

    return (
      <>
        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop={"static"}
          keyboard={false}
          size="lg"
        >
          <ModalHeader
            toggle={toggle}
            className="p-1 border-0"
            style={{ backgroundColor: "#fff", boxShadow: "none !important" }}
          ></ModalHeader>
          <ModalBody className="p-0">
            <Card className="shadow border-0">
              <CardBody>
                <Input
                  type="text"
                  placeholder="Search contact"
                  className="form-control mb-3"
                  value={search}
                  onChange={(e) => SetSearch(e.target.value)}
                ></Input>
                <div style={{ maxHeight: "400px", overflow: "scroll" }}>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="bg-dark text-yellow">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {contactList &&
                        contactList.length > 0 &&
                        contactList.map((contact) => {
                          return (
                            <tr key={contact.id}>
                              <th scope="row">{contact.name}</th>
                              <td scope="row">{contact.phone}</td>
                              <td className="text-right">
                                <Button
                                  className="btn btn-sm btnYellow"
                                  onClick={(e) => SelectContact(contact)}
                                >
                                  Select
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </>
    );
}

export default ContactListModal;