export default [
  {
    "city": "108 Mile Ranch",
    "country": "Canada",
    "IATA": "ZMH",
    "icao_code": "CZML"
  },
  {
    "city": "ANDERSON",
    "country": "United States",
    "IATA": "AID",
    "icao_code": "KAID"
  },
  {
    "city": "Aachen",
    "country": "Germany",
    "IATA": "AAH",
    "icao_code": "EDKA"
  },
  {
    "city": "Aalborg",
    "country": "Denmark",
    "IATA": "AAL",
    "icao_code": "EKYT"
  },
  {
    "city": "Aarhus",
    "country": "Denmark",
    "IATA": "AAR",
    "icao_code": "EKAH"
  },
  {
    "city": "Aasiaat",
    "country": "Greenland",
    "IATA": "JEG",
    "icao_code": "BGEM"
  },
  {
    "city": "Abadan",
    "country": "Iran",
    "IATA": "ABD",
    "icao_code": "OIAA"
  },
  {
    "city": "Abaiang Atoll",
    "country": "Kiribati",
    "IATA": "ABF"
  },
  {
    "city": "Abakan",
    "country": "Russia",
    "IATA": "ABA",
    "icao_code": "UNAA"
  },
  {
    "city": "Abbeville",
    "country": "France",
    "IATA": "XAB"
  },
  {
    "city": "Abbotsford",
    "country": "Canada",
    "IATA": "YXX",
    "icao_code": "CYXX"
  },
  {
    "city": "Abeche",
    "country": "Chad",
    "IATA": "AEH",
    "icao_code": "FTTC"
  },
  {
    "city": "Abemama",
    "country": "Kiribati",
    "IATA": "AEA",
    "icao_code": "NGTB"
  },
  {
    "city": "Aberdeen",
    "country": "United States",
    "IATA": "ABR",
    "icao_code": "KABR"
  },
  {
    "city": "Aberdeen",
    "country": "United States",
    "IATA": "APG"
  },
  {
    "city": "Aberdeen",
    "country": "United Kingdom",
    "IATA": "ABZ",
    "icao_code": "EGPD"
  },
  {
    "city": "Abha",
    "country": "Saudi Arabia",
    "IATA": "AHB",
    "icao_code": "OEAB"
  },
  {
    "city": "Abidjan",
    "country": "Cote d'Ivoire",
    "IATA": "ABJ",
    "icao_code": "DIAP"
  },
  {
    "city": "Abilene",
    "country": "United States",
    "IATA": "ABI",
    "icao_code": "KABI"
  },
  {
    "city": "Abilene",
    "country": "United States",
    "IATA": "DYS"
  },
  {
    "city": "Ablow",
    "country": "Vanuatu",
    "IATA": "MTV",
    "icao_code": "NVSA"
  },
  {
    "city": "Abu Dhabi",
    "country": "United Arab Emirates",
    "IATA": "DHF"
  },
  {
    "city": "Abu Dhabi",
    "country": "United Arab Emirates",
    "IATA": "AZI",
    "icao_code": "OMAD"
  },
  {
    "city": "Abu Dhabi",
    "country": "United Arab Emirates",
    "IATA": "AUH",
    "icao_code": "OMAA"
  },
  {
    "city": "Abu Simbel",
    "country": "Egypt",
    "IATA": "ABS",
    "icao_code": "HEBL"
  },
  {
    "city": "Abuja",
    "country": "Nigeria",
    "IATA": "ABV",
    "icao_code": "DNAA"
  },
  {
    "city": "Abumusa I.",
    "country": "Iran",
    "IATA": "AEU",
    "icao_code": "OIBA"
  },
  {
    "city": "Acandi",
    "country": "Colombia",
    "IATA": "ACD",
    "icao_code": "SKAD"
  },
  {
    "city": "Acapulco",
    "country": "Mexico",
    "IATA": "ACA",
    "icao_code": "MMAA"
  },
  {
    "city": "Acarigua",
    "country": "Venezuela",
    "IATA": "AGV",
    "icao_code": "SVAC"
  },
  {
    "city": "Accra",
    "country": "Ghana",
    "IATA": "ACC",
    "icao_code": "DGAA"
  },
  {
    "city": "Achinsk",
    "country": "Russia",
    "IATA": "ACS",
    "icao_code": "UNKS"
  },
  {
    "city": "Ada",
    "country": "United States",
    "IATA": "ADT",
    "icao_code": "KADH"
  },
  {
    "city": "Adak Island",
    "country": "United States",
    "IATA": "ADK",
    "icao_code": "PADK"
  },
  {
    "city": "Adampur",
    "country": "India",
    "IATA": "AIP"
  },
  {
    "city": "Adana",
    "country": "Turkey",
    "IATA": "UAB"
  },
  {
    "city": "Adana",
    "country": "Turkey",
    "IATA": "ADA",
    "icao_code": "LTAF"
  },
  {
    "city": "Addis Ababa",
    "country": "Ethiopia",
    "IATA": "ADD",
    "icao_code": "HAAB"
  },
  {
    "city": "Addison",
    "country": "United States",
    "IATA": "ADS",
    "icao_code": "KADS"
  },
  {
    "city": "Adelaide",
    "country": "Australia",
    "IATA": "ADL",
    "icao_code": "YPAD"
  },
  {
    "city": "Aden",
    "country": "Yemen",
    "IATA": "ADE",
    "icao_code": "OYAA"
  },
  {
    "city": "Adiyaman",
    "country": "Turkey",
    "IATA": "ADF",
    "icao_code": "LTCP"
  },
  {
    "city": "Adrar",
    "country": "Algeria",
    "IATA": "AZR",
    "icao_code": "DAUA"
  },
  {
    "city": "Afutara",
    "country": "Solomon Islands",
    "IATA": "AFT",
    "icao_code": "AGAF"
  },
  {
    "city": "Afyon",
    "country": "Turkey",
    "IATA": "AFY",
    "icao_code": "LTAH"
  },
  {
    "city": "Agadez",
    "country": "Niger",
    "IATA": "AJY",
    "icao_code": "DRZA"
  },
  {
    "city": "Agadir",
    "country": "Morocco",
    "IATA": "AGA",
    "icao_code": "GMAD"
  },
  {
    "city": "Agana",
    "country": "Guam",
    "IATA": "GUM",
    "icao_code": "PGUM"
  },
  {
    "city": "Agartala",
    "country": "India",
    "IATA": "IXA",
    "icao_code": "VEAT"
  },
  {
    "city": "Agatti Island",
    "country": "India",
    "IATA": "AGX",
    "icao_code": "VOAT"
  },
  {
    "city": "Agen",
    "country": "France",
    "IATA": "AGF",
    "icao_code": "LFBA"
  },
  {
    "city": "Aggeneys",
    "country": "South Africa",
    "IATA": "AGZ",
    "icao_code": "FAAG"
  },
  {
    "city": "Aghajari",
    "country": "Iran",
    "IATA": "AKW"
  },
  {
    "city": "Agra",
    "country": "India",
    "IATA": "AGR",
    "icao_code": "VIAG"
  },
  {
    "city": "Agri",
    "country": "Turkey",
    "IATA": "AJI",
    "icao_code": "LTCO"
  },
  {
    "city": "Agrinion",
    "country": "Greece",
    "IATA": "AGQ"
  },
  {
    "city": "Aguadilla",
    "country": "Puerto Rico",
    "IATA": "BQN",
    "icao_code": "TJBQ"
  },
  {
    "city": "Aguascalientes",
    "country": "Mexico",
    "IATA": "AGU",
    "icao_code": "MMAS"
  },
  {
    "city": "Aguni",
    "country": "Japan",
    "IATA": "AGJ",
    "icao_code": "RORA"
  },
  {
    "city": "Ahe",
    "country": "French Polynesia",
    "IATA": "AHE",
    "icao_code": "NTHE"
  },
  {
    "city": "Ahmed Al Jaber AB",
    "country": "Kuwait",
    "IATA": "XIJ"
  },
  {
    "city": "Ahmedabad",
    "country": "India",
    "IATA": "AMD",
    "icao_code": "VAAH"
  },
  {
    "city": "Ahuas",
    "country": "Honduras",
    "IATA": "AHS",
    "icao_code": "MHAH"
  },
  {
    "city": "Ahwaz",
    "country": "Iran",
    "IATA": "AWZ",
    "icao_code": "OIAW"
  },
  {
    "city": "Aiken",
    "country": "United States",
    "IATA": "AIK",
    "icao_code": "KAIK"
  },
  {
    "city": "Aioun El Atrouss",
    "country": "Mauritania",
    "IATA": "AEO",
    "icao_code": "GQNA"
  },
  {
    "city": "Airlie Beach",
    "country": "Australia",
    "IATA": "WSY"
  },
  {
    "city": "Aitutaki",
    "country": "Cook Islands",
    "IATA": "AIT",
    "icao_code": "NCAI"
  },
  {
    "city": "Aizwal",
    "country": "India",
    "IATA": "AJL",
    "icao_code": "VEAZ"
  },
  {
    "city": "Ajaccio",
    "country": "France",
    "IATA": "MFX"
  },
  {
    "city": "Ajaccio",
    "country": "France",
    "IATA": "AJA",
    "icao_code": "LFKJ"
  },
  {
    "city": "Ajmer",
    "country": "India",
    "IATA": "KQH",
    "icao_code": "VIKG"
  },
  {
    "city": "Akhiok",
    "country": "United States",
    "IATA": "AKK",
    "icao_code": "PAKH"
  },
  {
    "city": "Akiak",
    "country": "United States",
    "IATA": "AKI",
    "icao_code": "PFAK"
  },
  {
    "city": "Akita",
    "country": "Japan",
    "IATA": "AXT",
    "icao_code": "RJSK"
  },
  {
    "city": "Aklavik",
    "country": "Canada",
    "IATA": "LAK",
    "icao_code": "CYKD"
  },
  {
    "city": "Akola",
    "country": "India",
    "IATA": "AKD",
    "icao_code": "VAAK"
  },
  {
    "city": "Akron",
    "country": "United States",
    "IATA": "AKO",
    "icao_code": "KAKO"
  },
  {
    "city": "Akron",
    "country": "United States",
    "IATA": "CAK",
    "icao_code": "KCAK"
  },
  {
    "city": "Akron",
    "country": "United States",
    "IATA": "AKC",
    "icao_code": "KAKR"
  },
  {
    "city": "Akrotiri",
    "country": "Cyprus",
    "IATA": "AKT",
    "icao_code": "LCRA"
  },
  {
    "city": "Aksu",
    "country": "China",
    "IATA": "AKU",
    "icao_code": "ZWAK"
  },
  {
    "city": "Aktau",
    "country": "Kazakhstan",
    "IATA": "SCO",
    "icao_code": "UATE"
  },
  {
    "city": "Aktyubinsk",
    "country": "Kazakhstan",
    "IATA": "AKX",
    "icao_code": "UATT"
  },
  {
    "city": "Akulivik",
    "country": "Canada",
    "IATA": "AKV",
    "icao_code": "CYKO"
  },
  {
    "city": "Akure",
    "country": "Nigeria",
    "IATA": "AKR",
    "icao_code": "DNAK"
  },
  {
    "city": "Akureyri",
    "country": "Iceland",
    "IATA": "AEY",
    "icao_code": "BIAR"
  },
  {
    "city": "Akutan",
    "country": "United States",
    "IATA": "KQA"
  },
  {
    "city": "Al Ain",
    "country": "United Arab Emirates",
    "IATA": "AAN",
    "icao_code": "OMAL"
  },
  {
    "city": "Al Asad",
    "country": "Iraq",
    "IATA": "IQA",
    "icao_code": "CYFB"
  },
  {
    "city": "Al Bakr",
    "country": "Iraq",
    "IATA": "XQC"
  },
  {
    "city": "Al Bayda'",
    "country": "Libya",
    "IATA": "LAQ",
    "icao_code": "HLLQ"
  },
  {
    "city": "Al Ghaidah Intl",
    "country": "Yemen",
    "IATA": "AAY",
    "icao_code": "OYGD"
  },
  {
    "city": "Al Hociema",
    "country": "Morocco",
    "IATA": "AHU",
    "icao_code": "GMTA"
  },
  {
    "city": "Al Kharj",
    "country": "Saudi Arabia",
    "IATA": "AKH",
    "icao_code": "OEPS"
  },
  {
    "city": "Al Masna'ah",
    "country": "Oman",
    "IATA": "MNH"
  },
  {
    "city": "Al Taqaddum",
    "country": "Iraq",
    "IATA": "TQD"
  },
  {
    "city": "Al-Jawf",
    "country": "Saudi Arabia",
    "IATA": "AJF",
    "icao_code": "OESK"
  },
  {
    "city": "Al-Ula",
    "country": "Saudi Arabia",
    "IATA": "ULH",
    "icao_code": "OEAO"
  },
  {
    "city": "Al-ahsa",
    "country": "Saudi Arabia",
    "IATA": "HOF",
    "icao_code": "OEAH"
  },
  {
    "city": "Alakanuk",
    "country": "United States",
    "IATA": "AUK",
    "icao_code": "PAUK"
  },
  {
    "city": "Alameda",
    "country": "United States",
    "IATA": "NGZ"
  },
  {
    "city": "Alamogordo",
    "country": "United States",
    "IATA": "ALM",
    "icao_code": "KALM"
  },
  {
    "city": "Alamogordo",
    "country": "United States",
    "IATA": "HMN"
  },
  {
    "city": "Alamosa",
    "country": "United States",
    "IATA": "ALS",
    "icao_code": "KALS"
  },
  {
    "city": "Alanya",
    "country": "Turkey",
    "IATA": "GZP",
    "icao_code": "LTFG"
  },
  {
    "city": "Albacete",
    "country": "Spain",
    "IATA": "ABC",
    "icao_code": "LEAB"
  },
  {
    "city": "Albany",
    "country": "Australia",
    "IATA": "ALH",
    "icao_code": "YABA"
  },
  {
    "city": "Albany",
    "country": "United States",
    "IATA": "ABY",
    "icao_code": "KABY"
  },
  {
    "city": "Albany",
    "country": "United States",
    "IATA": "ALB",
    "icao_code": "KALB"
  },
  {
    "city": "Albenga",
    "country": "Italy",
    "IATA": "ALL",
    "icao_code": "LIMG"
  },
  {
    "city": "Albert",
    "country": "France",
    "IATA": "BYF"
  },
  {
    "city": "Albi",
    "country": "France",
    "IATA": "LBI",
    "icao_code": "LFCI"
  },
  {
    "city": "Albina",
    "country": "Suriname",
    "IATA": "ABN"
  },
  {
    "city": "Albuquerque",
    "country": "United States",
    "IATA": "ABQ",
    "icao_code": "KABQ"
  },
  {
    "city": "Albury",
    "country": "Australia",
    "IATA": "ABX",
    "icao_code": "YMAY"
  },
  {
    "city": "Aldan",
    "country": "Russia",
    "IATA": "ADH",
    "icao_code": "UEEA"
  },
  {
    "city": "Alderney",
    "country": "Guernsey",
    "IATA": "ACI",
    "icao_code": "EGJA"
  },
  {
    "city": "Aleknagik",
    "country": "United States",
    "IATA": "WKK"
  },
  {
    "city": "Aleppo",
    "country": "Syria",
    "IATA": "ALP",
    "icao_code": "OSAP"
  },
  {
    "city": "Alert",
    "country": "Canada",
    "IATA": "YLT",
    "icao_code": "CYLT"
  },
  {
    "city": "Alert Bay",
    "country": "Canada",
    "IATA": "YAL",
    "icao_code": "CYAL"
  },
  {
    "city": "Alesund",
    "country": "Norway",
    "IATA": "AES",
    "icao_code": "ENAL"
  },
  {
    "city": "Alexander Bay",
    "country": "South Africa",
    "IATA": "ALJ",
    "icao_code": "FAAB"
  },
  {
    "city": "Alexander City",
    "country": "United States",
    "IATA": "ALX",
    "icao_code": "ALX_"
  },
  {
    "city": "Alexandra",
    "country": "New Zealand",
    "IATA": "ALR",
    "icao_code": "NZLX"
  },
  {
    "city": "Alexandria",
    "country": "United States",
    "IATA": "AXN",
    "icao_code": "KAXN"
  },
  {
    "city": "Alexandria",
    "country": "United States",
    "IATA": "AEX",
    "icao_code": "KAEX"
  },
  {
    "city": "Alexandria",
    "country": "United States",
    "IATA": "ESF",
    "icao_code": "KESF"
  },
  {
    "city": "Alexandria",
    "country": "Egypt",
    "IATA": "HBE",
    "icao_code": "HEBA"
  },
  {
    "city": "Alexandria",
    "country": "Egypt",
    "IATA": "ALY",
    "icao_code": "HEAX"
  },
  {
    "city": "Alexandroupolis",
    "country": "Greece",
    "IATA": "AXD",
    "icao_code": "LGAL"
  },
  {
    "city": "Algeciras",
    "country": "Spain",
    "IATA": "AEI"
  },
  {
    "city": "Alghero",
    "country": "Italy",
    "IATA": "AHO",
    "icao_code": "LIEA"
  },
  {
    "city": "Algier",
    "country": "Algeria",
    "IATA": "ALG",
    "icao_code": "DAAG"
  },
  {
    "city": "Alicante",
    "country": "Spain",
    "IATA": "ALC",
    "icao_code": "LEAL"
  },
  {
    "city": "Alice",
    "country": "United States",
    "IATA": "ALI",
    "icao_code": "KALI"
  },
  {
    "city": "Alice Springs",
    "country": "Australia",
    "IATA": "ASP",
    "icao_code": "YBAS"
  },
  {
    "city": "Alice Town",
    "country": "Bahamas",
    "IATA": "BIM",
    "icao_code": "MYBS"
  },
  {
    "city": "Allahabad",
    "country": "India",
    "IATA": "IXD",
    "icao_code": "VIAL"
  },
  {
    "city": "Allakaket",
    "country": "United States",
    "IATA": "AET",
    "icao_code": "PFAL"
  },
  {
    "city": "Alldays",
    "country": "South Africa",
    "IATA": "ADY",
    "icao_code": "FAAL"
  },
  {
    "city": "Allentown",
    "country": "United States",
    "IATA": "ABE",
    "icao_code": "KABE"
  },
  {
    "city": "Alliance",
    "country": "United States",
    "IATA": "AIA",
    "icao_code": "KAIA"
  },
  {
    "city": "Alluitsup Paa",
    "country": "Greenland",
    "IATA": "LLU",
    "icao_code": "BGAP"
  },
  {
    "city": "Alma",
    "country": "Canada",
    "IATA": "YTF",
    "icao_code": "CYTF"
  },
  {
    "city": "Alma-ata",
    "country": "Kazakhstan",
    "IATA": "ALA",
    "icao_code": "UAAA"
  },
  {
    "city": "Almeirim",
    "country": "Brazil",
    "IATA": "MEU",
    "icao_code": "SBMD"
  },
  {
    "city": "Almeria",
    "country": "Spain",
    "IATA": "LEI",
    "icao_code": "LEAM"
  },
  {
    "city": "Alofi",
    "country": "Niue",
    "IATA": "IUE",
    "icao_code": "NIUE"
  },
  {
    "city": "Along",
    "country": "India",
    "IATA": "IXV",
    "icao_code": "VEAN"
  },
  {
    "city": "Alor Island",
    "country": "Indonesia",
    "IATA": "ARD",
    "icao_code": "WATM"
  },
  {
    "city": "Alor Setar",
    "country": "Malaysia",
    "IATA": "AOR",
    "icao_code": "WMKA"
  },
  {
    "city": "Alpena",
    "country": "United States",
    "IATA": "APN",
    "icao_code": "KAPN"
  },
  {
    "city": "Alpha",
    "country": "Australia",
    "IATA": "ABH",
    "icao_code": "YAPH"
  },
  {
    "city": "Alpine",
    "country": "United States",
    "IATA": "ALE",
    "icao_code": "KE38"
  },
  {
    "city": "Alta",
    "country": "Norway",
    "IATA": "ALF",
    "icao_code": "ENAT"
  },
  {
    "city": "Alta Floresta",
    "country": "Brazil",
    "IATA": "AFL",
    "icao_code": "SBAT"
  },
  {
    "city": "Altai",
    "country": "Mongolia",
    "IATA": "LTI",
    "icao_code": "ZMAT"
  },
  {
    "city": "Altamira",
    "country": "Brazil",
    "IATA": "ATM",
    "icao_code": "SBHT"
  },
  {
    "city": "Altay",
    "country": "China",
    "IATA": "AAT",
    "icao_code": "ZWAT"
  },
  {
    "city": "Altenburg",
    "country": "Germany",
    "IATA": "AOC",
    "icao_code": "EDAC"
  },
  {
    "city": "Altenrhein",
    "country": "Switzerland",
    "IATA": "ACH",
    "icao_code": "LSZR"
  },
  {
    "city": "Alto Rio Senguer",
    "country": "Argentina",
    "IATA": "ARR",
    "icao_code": "SAVR"
  },
  {
    "city": "Alton/St Louis",
    "country": "United States",
    "IATA": "ALN",
    "icao_code": "KALN"
  },
  {
    "city": "Altoona",
    "country": "United States",
    "IATA": "AOO",
    "icao_code": "KAOO"
  },
  {
    "city": "Altus",
    "country": "United States",
    "IATA": "LTS"
  },
  {
    "city": "Alula",
    "country": "Somalia",
    "IATA": "ALU",
    "icao_code": "HCMA"
  },
  {
    "city": "Alverca",
    "country": "Portugal",
    "IATA": "AVR"
  },
  {
    "city": "Alxa Left Banner",
    "country": "China",
    "IATA": "AXF",
    "icao_code": "ZBAL"
  },
  {
    "city": "Alxa Right Banner",
    "country": "China",
    "IATA": "RHT"
  },
  {
    "city": "Am Timan",
    "country": "Chad",
    "IATA": "AMC",
    "icao_code": "FTTN"
  },
  {
    "city": "Amakusa",
    "country": "Japan",
    "IATA": "AXJ",
    "icao_code": "RJDA"
  },
  {
    "city": "Amami",
    "country": "Japan",
    "IATA": "ASJ",
    "icao_code": "RJKA"
  },
  {
    "city": "Amarillo",
    "country": "United States",
    "IATA": "AMA",
    "icao_code": "KAMA"
  },
  {
    "city": "Amata",
    "country": "Australia",
    "IATA": "AMT",
    "icao_code": "YAMT"
  },
  {
    "city": "Ambato",
    "country": "Ecuador",
    "IATA": "ATF",
    "icao_code": "SEAM"
  },
  {
    "city": "Ambatondrazaka",
    "country": "Madagascar",
    "IATA": "WAM",
    "icao_code": "FMMZ"
  },
  {
    "city": "Amberley",
    "country": "Australia",
    "IATA": "ABM",
    "icao_code": "YBAM"
  },
  {
    "city": "Ambilobe",
    "country": "Madagascar",
    "IATA": "AMB"
  },
  {
    "city": "Ambler",
    "country": "United States",
    "IATA": "ABL",
    "icao_code": "PAFM"
  },
  {
    "city": "Amboseli National Park",
    "country": "Kenya",
    "IATA": "ASV",
    "icao_code": "HKAM"
  },
  {
    "city": "Ambryn Island",
    "country": "Vanuatu",
    "IATA": "ULB",
    "icao_code": "NVSU"
  },
  {
    "city": "Amderma",
    "country": "Russia",
    "IATA": "AMV",
    "icao_code": "ULDD"
  },
  {
    "city": "Amilcar Cabral",
    "country": "Cape Verde",
    "IATA": "SID",
    "icao_code": "GVAC"
  },
  {
    "city": "Amman",
    "country": "Jordan",
    "IATA": "ADJ",
    "icao_code": "OJAM"
  },
  {
    "city": "Amman",
    "country": "Jordan",
    "IATA": "AMM",
    "icao_code": "OJAI"
  },
  {
    "city": "Amos",
    "country": "Canada",
    "IATA": "YEY",
    "icao_code": "CYEY"
  },
  {
    "city": "Ampampamena",
    "country": "Madagascar",
    "IATA": "IVA"
  },
  {
    "city": "Amritsar",
    "country": "India",
    "IATA": "ATQ",
    "icao_code": "VIAR"
  },
  {
    "city": "Amsterdam",
    "country": "Netherlands",
    "IATA": "AMS",
    "icao_code": "EHAM"
  },
  {
    "city": "Anaa",
    "country": "French Polynesia",
    "IATA": "AAA",
    "icao_code": "NTGA"
  },
  {
    "city": "Anaco",
    "country": "Venezuela",
    "IATA": "AAO"
  },
  {
    "city": "Anadyr",
    "country": "Russia",
    "IATA": "DYR",
    "icao_code": "UHMA"
  },
  {
    "city": "Anahim Lake",
    "country": "Canada",
    "IATA": "YAA",
    "icao_code": "CAJ4"
  },
  {
    "city": "Anaktuvuk Pass",
    "country": "United States",
    "IATA": "AKP",
    "icao_code": "PAKP"
  },
  {
    "city": "Analalava",
    "country": "Madagascar",
    "IATA": "HVA",
    "icao_code": "FMNL"
  },
  {
    "city": "Anambas Islands",
    "country": "Indonesia",
    "IATA": "MWK",
    "icao_code": "WIOM"
  },
  {
    "city": "Anapa",
    "country": "Russia",
    "IATA": "AAQ",
    "icao_code": "URKA"
  },
  {
    "city": "Anchorage",
    "country": "United States",
    "IATA": "ANC",
    "icao_code": "PANC"
  },
  {
    "city": "Anchorage",
    "country": "United States",
    "IATA": "EDF"
  },
  {
    "city": "Anchorage",
    "country": "United States",
    "IATA": "MRI"
  },
  {
    "city": "Ancona",
    "country": "Italy",
    "IATA": "AOI",
    "icao_code": "LIPY"
  },
  {
    "city": "Andahuaylas",
    "country": "Peru",
    "IATA": "ANS",
    "icao_code": "SPHY"
  },
  {
    "city": "Andapa",
    "country": "Madagascar",
    "IATA": "ZWA",
    "icao_code": "FMND"
  },
  {
    "city": "Andersen",
    "country": "United States",
    "IATA": "AND",
    "icao_code": "KAND"
  },
  {
    "city": "Andersen",
    "country": "Guam",
    "IATA": "UAM",
    "icao_code": "PGUA"
  },
  {
    "city": "Andizhan",
    "country": "Uzbekistan",
    "IATA": "AZN",
    "icao_code": "UTKA"
  },
  {
    "city": "Andoas",
    "country": "Peru",
    "IATA": "AOP"
  },
  {
    "city": "Andoya",
    "country": "Norway",
    "IATA": "ANX",
    "icao_code": "ENAN"
  },
  {
    "city": "Andravida",
    "country": "Greece",
    "IATA": "PYR",
    "icao_code": "LGAD"
  },
  {
    "city": "Andros",
    "country": "Bahamas",
    "IATA": "COX"
  },
  {
    "city": "Andros Town",
    "country": "Bahamas",
    "IATA": "ASD",
    "icao_code": "MYAF"
  },
  {
    "city": "Anegada",
    "country": "British Virgin Islands",
    "IATA": "NGD"
  },
  {
    "city": "Anelghowhat",
    "country": "Vanuatu",
    "IATA": "AUY",
    "icao_code": "NVVA"
  },
  {
    "city": "Angeles City",
    "country": "Philippines",
    "IATA": "CRK",
    "icao_code": "RPLC"
  },
  {
    "city": "Angelsey",
    "country": "United Kingdom",
    "IATA": "VLY",
    "icao_code": "EGOV"
  },
  {
    "city": "Angers/Marcé",
    "country": "France",
    "IATA": "ANE",
    "icao_code": "LFJR"
  },
  {
    "city": "Angleton",
    "country": "United States",
    "IATA": "LJN",
    "icao_code": "KLBX"
  },
  {
    "city": "Angling Lake",
    "country": "Canada",
    "IATA": "YAX",
    "icao_code": "CKB6"
  },
  {
    "city": "Angola",
    "country": "United States",
    "IATA": "ANQ",
    "icao_code": "KANQ"
  },
  {
    "city": "Angoon",
    "country": "United States",
    "IATA": "AGN",
    "icao_code": "PAGN"
  },
  {
    "city": "Angouleme",
    "country": "France",
    "IATA": "ANG",
    "icao_code": "LFBU"
  },
  {
    "city": "Aniak",
    "country": "United States",
    "IATA": "ANI",
    "icao_code": "PANI"
  },
  {
    "city": "Aniwa",
    "country": "Vanuatu",
    "IATA": "AWD",
    "icao_code": "NVVB"
  },
  {
    "city": "Anjouan",
    "country": "Comoros",
    "IATA": "AJN",
    "icao_code": "FMCV"
  },
  {
    "city": "Ankang",
    "country": "China",
    "IATA": "AKA",
    "icao_code": "ZLAK"
  },
  {
    "city": "Ankara",
    "country": "Turkey",
    "IATA": "ANK",
    "icao_code": "LTAD"
  },
  {
    "city": "Ankara",
    "country": "Turkey",
    "IATA": "ESB",
    "icao_code": "LTAC"
  },
  {
    "city": "Ankavandra",
    "country": "Madagascar",
    "IATA": "JVA"
  },
  {
    "city": "Ann",
    "country": "Burma",
    "IATA": "VBA"
  },
  {
    "city": "Ann Arbor",
    "country": "United States",
    "IATA": "ARB",
    "icao_code": "KARB"
  },
  {
    "city": "Annaba",
    "country": "Algeria",
    "IATA": "AAE",
    "icao_code": "DABB"
  },
  {
    "city": "Annai",
    "country": "Guyana",
    "IATA": "NAI",
    "icao_code": "SYAN"
  },
  {
    "city": "Annapolis",
    "country": "United States",
    "IATA": "ANP",
    "icao_code": "KANP"
  },
  {
    "city": "Annecy",
    "country": "France",
    "IATA": "NCY",
    "icao_code": "LFLP"
  },
  {
    "city": "Annemasse",
    "country": "France",
    "IATA": "QNJ",
    "icao_code": "LFLI"
  },
  {
    "city": "Annette Island",
    "country": "United States",
    "IATA": "ANN",
    "icao_code": "PANT"
  },
  {
    "city": "Anniston",
    "country": "United States",
    "IATA": "ANB",
    "icao_code": "KANB"
  },
  {
    "city": "Anqing",
    "country": "China",
    "IATA": "AQG",
    "icao_code": "ZSAQ"
  },
  {
    "city": "Anshan",
    "country": "China",
    "IATA": "AOG",
    "icao_code": "ZYAS"
  },
  {
    "city": "Anshun",
    "country": "China",
    "IATA": "AVA",
    "icao_code": "ZUAS"
  },
  {
    "city": "Anta",
    "country": "Peru",
    "IATA": "ATA",
    "icao_code": "SPHZ"
  },
  {
    "city": "Antalaha",
    "country": "Madagascar",
    "IATA": "ANM",
    "icao_code": "FMNH"
  },
  {
    "city": "Antalya",
    "country": "Turkey",
    "IATA": "AYT",
    "icao_code": "LTAI"
  },
  {
    "city": "Antananarivo",
    "country": "Madagascar",
    "IATA": "TNR",
    "icao_code": "FMMI"
  },
  {
    "city": "Antigua",
    "country": "Antigua and Barbuda",
    "IATA": "ANU",
    "icao_code": "TAPA"
  },
  {
    "city": "Antofagasta",
    "country": "Chile",
    "IATA": "ANF",
    "icao_code": "SCFA"
  },
  {
    "city": "Antsalova",
    "country": "Madagascar",
    "IATA": "WAQ"
  },
  {
    "city": "Antsirabe",
    "country": "Madagascar",
    "IATA": "ATJ",
    "icao_code": "FMME"
  },
  {
    "city": "Antsiranana",
    "country": "Madagascar",
    "IATA": "DIE",
    "icao_code": "FMNA"
  },
  {
    "city": "Antsohihy",
    "country": "Madagascar",
    "IATA": "WAI"
  },
  {
    "city": "Antwerp",
    "country": "Belgium",
    "IATA": "ANR",
    "icao_code": "EBAW"
  },
  {
    "city": "Anuradhapura",
    "country": "Sri Lanka",
    "IATA": "ACJ",
    "icao_code": "VCCA"
  },
  {
    "city": "Anvik",
    "country": "United States",
    "IATA": "ANV",
    "icao_code": "PANV"
  },
  {
    "city": "Aomori",
    "country": "Japan",
    "IATA": "AOJ",
    "icao_code": "RJSA"
  },
  {
    "city": "Aosta",
    "country": "Italy",
    "IATA": "AOT",
    "icao_code": "LIMW"
  },
  {
    "city": "Apalachicola",
    "country": "United States",
    "IATA": "AAF",
    "icao_code": "KAAF"
  },
  {
    "city": "Apataki",
    "country": "French Polynesia",
    "IATA": "APK",
    "icao_code": "NTGD"
  },
  {
    "city": "Apatity",
    "country": "Russia",
    "IATA": "KVK",
    "icao_code": "ULMK"
  },
  {
    "city": "Apia",
    "country": "Samoa",
    "IATA": "FGI",
    "icao_code": "NSAP"
  },
  {
    "city": "Apiay",
    "country": "Colombia",
    "IATA": "API"
  },
  {
    "city": "Appleton",
    "country": "United States",
    "IATA": "ATW",
    "icao_code": "KATW"
  },
  {
    "city": "Aqaba",
    "country": "Jordan",
    "IATA": "AQJ",
    "icao_code": "OJAQ"
  },
  {
    "city": "Aracaju",
    "country": "Brazil",
    "IATA": "AJU",
    "icao_code": "SBAR"
  },
  {
    "city": "Aracatuba",
    "country": "Brazil",
    "IATA": "ARU",
    "icao_code": "SBAU"
  },
  {
    "city": "Arad",
    "country": "Romania",
    "IATA": "ARW",
    "icao_code": "LRAR"
  },
  {
    "city": "Araguaina",
    "country": "Brazil",
    "IATA": "AUX",
    "icao_code": "SWGN"
  },
  {
    "city": "Arak",
    "country": "Iran",
    "IATA": "AJK",
    "icao_code": "OIHR"
  },
  {
    "city": "Arandis",
    "country": "Namibia",
    "IATA": "ADI",
    "icao_code": "FYAR"
  },
  {
    "city": "Arapiraca",
    "country": "Brazil",
    "IATA": "APQ",
    "icao_code": "SNAL"
  },
  {
    "city": "Arar",
    "country": "Saudi Arabia",
    "IATA": "RAE",
    "icao_code": "OERR"
  },
  {
    "city": "Araracuara",
    "country": "Colombia",
    "IATA": "ACR",
    "icao_code": "SKAC"
  },
  {
    "city": "Araracuara",
    "country": "Brazil",
    "IATA": "AQA",
    "icao_code": "SBAQ"
  },
  {
    "city": "Arathusa",
    "country": "South Africa",
    "IATA": "ASS"
  },
  {
    "city": "Arauca",
    "country": "Colombia",
    "IATA": "AUC",
    "icao_code": "SKUC"
  },
  {
    "city": "Araxa",
    "country": "Brazil",
    "IATA": "AAX",
    "icao_code": "SBAX"
  },
  {
    "city": "Arba Minch",
    "country": "Ethiopia",
    "IATA": "AMH",
    "icao_code": "HAAM"
  },
  {
    "city": "Arcata CA",
    "country": "United States",
    "IATA": "ACV",
    "icao_code": "KACV"
  },
  {
    "city": "Arctic Bay",
    "country": "Canada",
    "IATA": "YAB"
  },
  {
    "city": "Arctic Village",
    "country": "United States",
    "IATA": "ARC",
    "icao_code": "PARC"
  },
  {
    "city": "Ardabil",
    "country": "Iran",
    "IATA": "ADU",
    "icao_code": "OITL"
  },
  {
    "city": "Ardmore",
    "country": "United States",
    "IATA": "ADM",
    "icao_code": "KADM"
  },
  {
    "city": "Ardmore",
    "country": "New Zealand",
    "IATA": "AMZ",
    "icao_code": "NZAR"
  },
  {
    "city": "Arecibo",
    "country": "Puerto Rico",
    "IATA": "ARE",
    "icao_code": "TJAB"
  },
  {
    "city": "Arequipa",
    "country": "Peru",
    "IATA": "AQP",
    "icao_code": "SPQU"
  },
  {
    "city": "Argyle",
    "country": "Australia",
    "IATA": "PUG",
    "icao_code": "YPAG"
  },
  {
    "city": "Argyle",
    "country": "Australia",
    "IATA": "GYL",
    "icao_code": "YARG"
  },
  {
    "city": "Arica",
    "country": "Chile",
    "IATA": "ARI",
    "icao_code": "SCAR"
  },
  {
    "city": "Aripuana",
    "country": "Brazil",
    "IATA": "AIR",
    "icao_code": "SWRP"
  },
  {
    "city": "Arkalyk",
    "country": "Kazakhstan",
    "IATA": "AYK",
    "icao_code": "UAUR"
  },
  {
    "city": "Arkhangelsk",
    "country": "Russia",
    "IATA": "LDG",
    "icao_code": "ULAL"
  },
  {
    "city": "Arkhangelsk",
    "country": "Russia",
    "IATA": "ARH",
    "icao_code": "ULAA"
  },
  {
    "city": "Armenia",
    "country": "Colombia",
    "IATA": "AXM",
    "icao_code": "SKAR"
  },
  {
    "city": "Armidale",
    "country": "Australia",
    "IATA": "ARM",
    "icao_code": "YARM"
  },
  {
    "city": "Armstrong",
    "country": "Canada",
    "IATA": "YYW",
    "icao_code": "CYYW"
  },
  {
    "city": "Arorae",
    "country": "Kiribati",
    "IATA": "AIS",
    "icao_code": "NGTR"
  },
  {
    "city": "Arrecife",
    "country": "Spain",
    "IATA": "ACE",
    "icao_code": "GCRR"
  },
  {
    "city": "Arthur's Town",
    "country": "Bahamas",
    "IATA": "ATC",
    "icao_code": "MYCA"
  },
  {
    "city": "Artigas",
    "country": "Uruguay",
    "IATA": "ATI",
    "icao_code": "SUAG"
  },
  {
    "city": "Arua",
    "country": "Uganda",
    "IATA": "RUA",
    "icao_code": "HUAR"
  },
  {
    "city": "Arusha",
    "country": "Tanzania",
    "IATA": "ARK",
    "icao_code": "HTAR"
  },
  {
    "city": "Arutua",
    "country": "French Polynesia",
    "IATA": "AXR",
    "icao_code": "NTGU"
  },
  {
    "city": "Arvaikheer",
    "country": "Mongolia",
    "IATA": "AVK",
    "icao_code": "ZMAH"
  },
  {
    "city": "Arvidsjaur",
    "country": "Sweden",
    "IATA": "AJR",
    "icao_code": "ESNX"
  },
  {
    "city": "Arxan",
    "country": "China",
    "IATA": "YIE"
  },
  {
    "city": "Asaba",
    "country": "Nigeria",
    "IATA": "ABB",
    "icao_code": "DNAS"
  },
  {
    "city": "Asahikawa",
    "country": "Japan",
    "IATA": "AKJ",
    "icao_code": "RJEC"
  },
  {
    "city": "Asheville",
    "country": "United States",
    "IATA": "AVL",
    "icao_code": "KAVL"
  },
  {
    "city": "Ashkhabad",
    "country": "Turkmenistan",
    "IATA": "ASB",
    "icao_code": "UTAA"
  },
  {
    "city": "Asmara",
    "country": "Eritrea",
    "IATA": "ASM",
    "icao_code": "HHAS"
  },
  {
    "city": "Asosa",
    "country": "Ethiopia",
    "IATA": "ASO",
    "icao_code": "HASO"
  },
  {
    "city": "Aspen",
    "country": "United States",
    "IATA": "ASE",
    "icao_code": "KASE"
  },
  {
    "city": "Assab",
    "country": "Eritrea",
    "IATA": "ASA",
    "icao_code": "HHSB"
  },
  {
    "city": "Astoria",
    "country": "United States",
    "IATA": "AST",
    "icao_code": "KAST"
  },
  {
    "city": "Astrakhan",
    "country": "Russia",
    "IATA": "ASF",
    "icao_code": "URWA"
  },
  {
    "city": "Astypalaia",
    "country": "Greece",
    "IATA": "JTY",
    "icao_code": "LGPL"
  },
  {
    "city": "Asuncion",
    "country": "Paraguay",
    "IATA": "ASU",
    "icao_code": "SGAS"
  },
  {
    "city": "Aswan",
    "country": "Egypt",
    "IATA": "ASW",
    "icao_code": "HESN"
  },
  {
    "city": "Asyut",
    "country": "Egypt",
    "IATA": "ATZ",
    "icao_code": "HEAT"
  },
  {
    "city": "Ataq",
    "country": "Yemen",
    "IATA": "AXK",
    "icao_code": "ODAT"
  },
  {
    "city": "Atar",
    "country": "Mauritania",
    "IATA": "ATR",
    "icao_code": "GQPA"
  },
  {
    "city": "Atbara",
    "country": "Sudan",
    "IATA": "ATB",
    "icao_code": "HSAT"
  },
  {
    "city": "Athens",
    "country": "United States",
    "IATA": "MMI",
    "icao_code": "KMMI"
  },
  {
    "city": "Athens",
    "country": "United States",
    "IATA": "ATO"
  },
  {
    "city": "Athens",
    "country": "United States",
    "IATA": "AHN",
    "icao_code": "KAHN"
  },
  {
    "city": "Athens",
    "country": "Greece",
    "IATA": "HEW"
  },
  {
    "city": "Athens",
    "country": "Greece",
    "IATA": "ATH",
    "icao_code": "LGAV"
  },
  {
    "city": "Atikokan",
    "country": "Canada",
    "IATA": "YIB",
    "icao_code": "CYIB"
  },
  {
    "city": "Atiu Island",
    "country": "Cook Islands",
    "IATA": "AIU",
    "icao_code": "NCAT"
  },
  {
    "city": "Atka",
    "country": "United States",
    "IATA": "AKB",
    "icao_code": "PAAK"
  },
  {
    "city": "Atlanta",
    "country": "United States",
    "IATA": "PDK",
    "icao_code": "KPDK"
  },
  {
    "city": "Atlanta",
    "country": "United States",
    "IATA": "FTY",
    "icao_code": "KFTY"
  },
  {
    "city": "Atlanta",
    "country": "United States",
    "IATA": "ATL",
    "icao_code": "KATL"
  },
  {
    "city": "Atlantic City",
    "country": "United States",
    "IATA": "ACY",
    "icao_code": "KACY"
  },
  {
    "city": "Atoifi",
    "country": "Solomon Islands",
    "IATA": "ATD",
    "icao_code": "AGAT"
  },
  {
    "city": "Atqasuk",
    "country": "United States",
    "IATA": "ATK",
    "icao_code": "PATQ"
  },
  {
    "city": "Atsugi",
    "country": "Japan",
    "IATA": "NJA",
    "icao_code": "RJTA"
  },
  {
    "city": "Attawapiskat",
    "country": "Canada",
    "IATA": "YAT",
    "icao_code": "CYAT"
  },
  {
    "city": "Attopeu",
    "country": "Laos",
    "IATA": "AOU",
    "icao_code": "VLAP"
  },
  {
    "city": "Attu",
    "country": "Greenland",
    "IATA": "QGQ"
  },
  {
    "city": "Atyrau",
    "country": "Kazakhstan",
    "IATA": "GUW",
    "icao_code": "UATG"
  },
  {
    "city": "Aubenas-vals-lanas",
    "country": "France",
    "IATA": "OBS",
    "icao_code": "LFHO"
  },
  {
    "city": "Auburn",
    "country": "United States",
    "IATA": "AUO",
    "icao_code": "KAUO"
  },
  {
    "city": "Auckland",
    "country": "New Zealand",
    "IATA": "AKL",
    "icao_code": "NZAA"
  },
  {
    "city": "Augsburg",
    "country": "Germany",
    "IATA": "AGB",
    "icao_code": "EDMA"
  },
  {
    "city": "Augusta",
    "country": "United States",
    "IATA": "DNL",
    "icao_code": "KDNL"
  },
  {
    "city": "Augusta",
    "country": "United States",
    "IATA": "AUG",
    "icao_code": "KAUG"
  },
  {
    "city": "Auki",
    "country": "Solomon Islands",
    "IATA": "AKS",
    "icao_code": "AGGA"
  },
  {
    "city": "Aupaluk",
    "country": "Canada",
    "IATA": "YPJ",
    "icao_code": "CYLA"
  },
  {
    "city": "Aurangabad",
    "country": "India",
    "IATA": "IXU",
    "icao_code": "VAAU"
  },
  {
    "city": "Aurillac",
    "country": "France",
    "IATA": "AUR",
    "icao_code": "LFLW"
  },
  {
    "city": "Aurukun",
    "country": "Australia",
    "IATA": "AUU",
    "icao_code": "YAUR"
  },
  {
    "city": "Austin",
    "country": "United States",
    "IATA": "AUS",
    "icao_code": "KAUS"
  },
  {
    "city": "Auxerre",
    "country": "France",
    "IATA": "AUF",
    "icao_code": "LFLA"
  },
  {
    "city": "Avalon",
    "country": "Australia",
    "IATA": "AVV",
    "icao_code": "YMAV"
  },
  {
    "city": "Avarua",
    "country": "Cook Islands",
    "IATA": "RAR",
    "icao_code": "NCRG"
  },
  {
    "city": "Aviano",
    "country": "Italy",
    "IATA": "AVB",
    "icao_code": "LIPA"
  },
  {
    "city": "Avignon",
    "country": "France",
    "IATA": "AVN",
    "icao_code": "LFMV"
  },
  {
    "city": "Aviles",
    "country": "Spain",
    "IATA": "OVD",
    "icao_code": "LEAS"
  },
  {
    "city": "Avon Park",
    "country": "United States",
    "IATA": "AVO",
    "icao_code": "KAVO"
  },
  {
    "city": "Awasa",
    "country": "Ethiopia",
    "IATA": "AWA",
    "icao_code": "HALA"
  },
  {
    "city": "Axum",
    "country": "Ethiopia",
    "IATA": "AXU",
    "icao_code": "HAAX"
  },
  {
    "city": "Ayacucho",
    "country": "Peru",
    "IATA": "AYP",
    "icao_code": "SPHO"
  },
  {
    "city": "Ayawasi",
    "country": "Indonesia",
    "IATA": "AYW"
  },
  {
    "city": "Ayolas",
    "country": "Paraguay",
    "IATA": "AYO"
  },
  {
    "city": "BLUMENAU",
    "country": "Brazil",
    "IATA": "BNU",
    "icao_code": "SSBL"
  },
  {
    "city": "BRISTOL",
    "country": "United States",
    "IATA": "TRI",
    "icao_code": "KTRI"
  },
  {
    "city": "Babelthuap",
    "country": "Palau",
    "IATA": "ROR",
    "icao_code": "PTRO"
  },
  {
    "city": "Babo",
    "country": "Indonesia",
    "IATA": "BXB",
    "icao_code": "WASO"
  },
  {
    "city": "Bacau",
    "country": "Romania",
    "IATA": "BCM",
    "icao_code": "LRBC"
  },
  {
    "city": "Baco",
    "country": "Ethiopia",
    "IATA": "BCO",
    "icao_code": "HABC"
  },
  {
    "city": "Bacolod",
    "country": "Philippines",
    "IATA": "BCD",
    "icao_code": "RPVB"
  },
  {
    "city": "Badajoz",
    "country": "Spain",
    "IATA": "BJZ",
    "icao_code": "LEBZ"
  },
  {
    "city": "Badu Island",
    "country": "Australia",
    "IATA": "BDD",
    "icao_code": "YBAU"
  },
  {
    "city": "Bafoussam",
    "country": "Cameroon",
    "IATA": "BFX",
    "icao_code": "FKKU"
  },
  {
    "city": "Bagan",
    "country": "Burma",
    "IATA": "BPE"
  },
  {
    "city": "Bagan",
    "country": "Burma",
    "IATA": "NYU",
    "icao_code": "VBNU"
  },
  {
    "city": "Bage",
    "country": "Brazil",
    "IATA": "BGX",
    "icao_code": "SBBG"
  },
  {
    "city": "Baghdad",
    "country": "Iraq",
    "IATA": "BGW",
    "icao_code": "ORBI"
  },
  {
    "city": "Baghdogra",
    "country": "India",
    "IATA": "IXB",
    "icao_code": "VEBD"
  },
  {
    "city": "Baglung",
    "country": "Nepal",
    "IATA": "BGL",
    "icao_code": "VNBL"
  },
  {
    "city": "Bagotville",
    "country": "Canada",
    "IATA": "YBG",
    "icao_code": "CYBG"
  },
  {
    "city": "Baguio",
    "country": "Philippines",
    "IATA": "BAG",
    "icao_code": "RPUB"
  },
  {
    "city": "Bahar Dar",
    "country": "Ethiopia",
    "IATA": "BJR",
    "icao_code": "HABD"
  },
  {
    "city": "Bahawalpur",
    "country": "Pakistan",
    "IATA": "BHV",
    "icao_code": "OPBW"
  },
  {
    "city": "Bahia Blanca",
    "country": "Argentina",
    "IATA": "BHI",
    "icao_code": "SAZB"
  },
  {
    "city": "Bahia Solano",
    "country": "Colombia",
    "IATA": "BSC",
    "icao_code": "SKBS"
  },
  {
    "city": "Bahrain",
    "country": "Bahrain",
    "IATA": "BAH",
    "icao_code": "OBBI"
  },
  {
    "city": "Baia Mare",
    "country": "Romania",
    "IATA": "BAY",
    "icao_code": "LRBM"
  },
  {
    "city": "Baicheng",
    "country": "China",
    "IATA": "DBC",
    "icao_code": "ZYBA"
  },
  {
    "city": "Baidoa",
    "country": "Somalia",
    "IATA": "BIB",
    "icao_code": "HCMB"
  },
  {
    "city": "Baie Comeau",
    "country": "Canada",
    "IATA": "YBC",
    "icao_code": "CYBC"
  },
  {
    "city": "Baikonur",
    "country": "Kazakhstan",
    "IATA": "BXY"
  },
  {
    "city": "Baimuru",
    "country": "Papua New Guinea",
    "IATA": "VMU"
  },
  {
    "city": "Bainbridge",
    "country": "United States",
    "IATA": "BGE",
    "icao_code": "KBGE"
  },
  {
    "city": "Bairnsdale",
    "country": "Australia",
    "IATA": "BSJ",
    "icao_code": "YBNS"
  },
  {
    "city": "Baise",
    "country": "China",
    "IATA": "AEB"
  },
  {
    "city": "Baishan",
    "country": "China",
    "IATA": "NBS"
  },
  {
    "city": "Bajawa",
    "country": "Indonesia",
    "IATA": "BJW",
    "icao_code": "WATB"
  },
  {
    "city": "Bajhang",
    "country": "Nepal",
    "IATA": "BJH",
    "icao_code": "VNBG"
  },
  {
    "city": "Bajura",
    "country": "Nepal",
    "IATA": "BJU",
    "icao_code": "VNBR"
  },
  {
    "city": "Bakalalan",
    "country": "Malaysia",
    "IATA": "BKM",
    "icao_code": "WBGQ"
  },
  {
    "city": "Bakel",
    "country": "Senegal",
    "IATA": "BXE"
  },
  {
    "city": "Baker City",
    "country": "United States",
    "IATA": "BKE",
    "icao_code": "KBKE"
  },
  {
    "city": "Baker Lake",
    "country": "Canada",
    "IATA": "YBK",
    "icao_code": "CYBK"
  },
  {
    "city": "Bakersfield",
    "country": "United States",
    "IATA": "BFL",
    "icao_code": "KBFL"
  },
  {
    "city": "Bakhtaran",
    "country": "Iran",
    "IATA": "KSH",
    "icao_code": "OICC"
  },
  {
    "city": "Baku",
    "country": "Azerbaijan",
    "IATA": "ZXT"
  },
  {
    "city": "Baku",
    "country": "Azerbaijan",
    "IATA": "GYD",
    "icao_code": "UBBB"
  },
  {
    "city": "Balakovo",
    "country": "Russia",
    "IATA": "BWO",
    "icao_code": "UWSB"
  },
  {
    "city": "Baler",
    "country": "Philippines",
    "IATA": "BQA",
    "icao_code": "RPUR"
  },
  {
    "city": "Balikesir",
    "country": "Turkey",
    "IATA": "BZI",
    "icao_code": "LTBF"
  },
  {
    "city": "Balikesir Korfez",
    "country": "Turkey",
    "IATA": "EDO",
    "icao_code": "LTFD"
  },
  {
    "city": "Balikpapan",
    "country": "Indonesia",
    "IATA": "BPN",
    "icao_code": "WALL"
  },
  {
    "city": "Balimo",
    "country": "Papua New Guinea",
    "IATA": "OPU"
  },
  {
    "city": "Balkhash",
    "country": "Kazakhstan",
    "IATA": "BXH"
  },
  {
    "city": "Ballalae",
    "country": "Solomon Islands",
    "IATA": "BAS",
    "icao_code": "AGGE"
  },
  {
    "city": "Ballera",
    "country": "Australia",
    "IATA": "BBL",
    "icao_code": "YLLE"
  },
  {
    "city": "Ballina Byron Bay",
    "country": "Australia",
    "IATA": "BNK",
    "icao_code": "YBNA"
  },
  {
    "city": "Balmaceda",
    "country": "Chile",
    "IATA": "BBA",
    "icao_code": "SCBA"
  },
  {
    "city": "Baltimore",
    "country": "United States",
    "IATA": "MTN",
    "icao_code": "KMTN"
  },
  {
    "city": "Baltimore",
    "country": "United States",
    "IATA": "BWI",
    "icao_code": "KBWI"
  },
  {
    "city": "Baltrum",
    "country": "Germany",
    "IATA": "BMR"
  },
  {
    "city": "Bam",
    "country": "Iran",
    "IATA": "BXR",
    "icao_code": "OIKM"
  },
  {
    "city": "Bamako",
    "country": "Mali",
    "IATA": "BKO",
    "icao_code": "GABS"
  },
  {
    "city": "Bamenda",
    "country": "Cameroon",
    "IATA": "BPC",
    "icao_code": "FKKV"
  },
  {
    "city": "Bamyan",
    "country": "Afghanistan",
    "IATA": "BIN",
    "icao_code": "OABN"
  },
  {
    "city": "Banda Aceh",
    "country": "Indonesia",
    "IATA": "BTJ",
    "icao_code": "WITT"
  },
  {
    "city": "Bandar Abbas",
    "country": "Iran",
    "IATA": "HDR",
    "icao_code": "OIKP"
  },
  {
    "city": "Bandar Abbas",
    "country": "Iran",
    "IATA": "BND",
    "icao_code": "OIKB"
  },
  {
    "city": "Bandar Lampung-Sumatra Island",
    "country": "Indonesia",
    "IATA": "TKG",
    "icao_code": "WIIT"
  },
  {
    "city": "Bandar Lengeh",
    "country": "Iran",
    "IATA": "BDH",
    "icao_code": "OIBL"
  },
  {
    "city": "Bandar Mahshahr",
    "country": "Iran",
    "IATA": "MRX",
    "icao_code": "OIAM"
  },
  {
    "city": "Bandar Seri Begawan",
    "country": "Brunei",
    "IATA": "BWN",
    "icao_code": "WBSB"
  },
  {
    "city": "Bandirma",
    "country": "Turkey",
    "IATA": "BDM",
    "icao_code": "LTBG"
  },
  {
    "city": "Bandoundu",
    "country": "Congo (Kinshasa)",
    "IATA": "FDU",
    "icao_code": "FZBO"
  },
  {
    "city": "Bandung",
    "country": "Indonesia",
    "IATA": "BDO",
    "icao_code": "WIIB"
  },
  {
    "city": "Banff",
    "country": "Canada",
    "IATA": "YBA",
    "icao_code": "CYBA"
  },
  {
    "city": "Bangalore",
    "country": "India",
    "IATA": "BLR",
    "icao_code": "VOBL"
  },
  {
    "city": "Bangda",
    "country": "China",
    "IATA": "BPX",
    "icao_code": "ZUBD"
  },
  {
    "city": "Bangkok",
    "country": "Thailand",
    "IATA": "BKK",
    "icao_code": "VTBS"
  },
  {
    "city": "Bangkok",
    "country": "Thailand",
    "IATA": "DMK",
    "icao_code": "VTBD"
  },
  {
    "city": "Bangor",
    "country": "United States",
    "IATA": "BGR",
    "icao_code": "KBGR"
  },
  {
    "city": "Bangui",
    "country": "Central African Republic",
    "IATA": "BGF",
    "icao_code": "FEFF"
  },
  {
    "city": "Banja Luka",
    "country": "Bosnia and Herzegovina",
    "IATA": "BNX",
    "icao_code": "LQBK"
  },
  {
    "city": "Banjarmasin",
    "country": "Indonesia",
    "IATA": "BDJ",
    "icao_code": "WRBB"
  },
  {
    "city": "Banjul",
    "country": "Gambia",
    "IATA": "BJL",
    "icao_code": "GBYD"
  },
  {
    "city": "Banmaw",
    "country": "Burma",
    "IATA": "BMO",
    "icao_code": "VYBM"
  },
  {
    "city": "Banning",
    "country": "United States",
    "IATA": "BNG",
    "icao_code": "KBNG"
  },
  {
    "city": "Bannu",
    "country": "Pakistan",
    "IATA": "BNP"
  },
  {
    "city": "Bantry",
    "country": "Ireland",
    "IATA": "BYT",
    "icao_code": "EIBN"
  },
  {
    "city": "Banyuwangi",
    "country": "Indonesia",
    "IATA": "BWX",
    "icao_code": "WARB"
  },
  {
    "city": "Baoshan",
    "country": "China",
    "IATA": "BSD",
    "icao_code": "ZPBS"
  },
  {
    "city": "Baotou",
    "country": "China",
    "IATA": "BAV",
    "icao_code": "ZBOW"
  },
  {
    "city": "Bar Harbor",
    "country": "United States",
    "IATA": "BHB",
    "icao_code": "KBHB"
  },
  {
    "city": "Baracoa Playa",
    "country": "Cuba",
    "IATA": "UPB",
    "icao_code": "MUPB"
  },
  {
    "city": "Baracoa Playa",
    "country": "Cuba",
    "IATA": "BCA",
    "icao_code": "MUBA"
  },
  {
    "city": "Barahona",
    "country": "Dominican Republic",
    "IATA": "BRX",
    "icao_code": "MDBH"
  },
  {
    "city": "Barcaldine",
    "country": "Australia",
    "IATA": "BCI",
    "icao_code": "YBAR"
  },
  {
    "city": "Barcelona",
    "country": "Venezuela",
    "IATA": "BLA",
    "icao_code": "SVBC"
  },
  {
    "city": "Barcelona",
    "country": "Spain",
    "IATA": "BCN",
    "icao_code": "LEBL"
  },
  {
    "city": "Barcelos",
    "country": "Brazil",
    "IATA": "BAZ",
    "icao_code": "SWBC"
  },
  {
    "city": "Bardufoss",
    "country": "Norway",
    "IATA": "BDU",
    "icao_code": "ENDU"
  },
  {
    "city": "Bareilly",
    "country": "India",
    "IATA": "BEK"
  },
  {
    "city": "Bari",
    "country": "Italy",
    "IATA": "BRI",
    "icao_code": "LIBD"
  },
  {
    "city": "Barimunya",
    "country": "Australia",
    "IATA": "BYP",
    "icao_code": "YBRY"
  },
  {
    "city": "Barinas",
    "country": "Venezuela",
    "IATA": "BNS",
    "icao_code": "SVBI"
  },
  {
    "city": "Bario",
    "country": "Malaysia",
    "IATA": "BBN",
    "icao_code": "WBGZ"
  },
  {
    "city": "Barisal",
    "country": "Bangladesh",
    "IATA": "BZL",
    "icao_code": "VGBR"
  },
  {
    "city": "Barking Sands",
    "country": "United States",
    "IATA": "BKH",
    "icao_code": "PHBK"
  },
  {
    "city": "Barnaul",
    "country": "Russia",
    "IATA": "BAX",
    "icao_code": "UNBB"
  },
  {
    "city": "Barnstable",
    "country": "United States",
    "IATA": "HYA",
    "icao_code": "KHYA"
  },
  {
    "city": "Baroda",
    "country": "India",
    "IATA": "BDQ",
    "icao_code": "VABO"
  },
  {
    "city": "Barquisimeto",
    "country": "Venezuela",
    "IATA": "BRM",
    "icao_code": "SVBM"
  },
  {
    "city": "Barra",
    "country": "United Kingdom",
    "IATA": "BRR",
    "icao_code": "EGPR"
  },
  {
    "city": "Barra Do Garcas",
    "country": "Brazil",
    "IATA": "BPG",
    "icao_code": "SBBW"
  },
  {
    "city": "Barrancabermeja",
    "country": "Colombia",
    "IATA": "EJA",
    "icao_code": "SKEJ"
  },
  {
    "city": "Barranquilla",
    "country": "Colombia",
    "IATA": "BAQ",
    "icao_code": "SKBQ"
  },
  {
    "city": "Barreiras",
    "country": "Brazil",
    "IATA": "BRA",
    "icao_code": "SNBR"
  },
  {
    "city": "Barretos",
    "country": "Brazil",
    "IATA": "BAT",
    "icao_code": "SNBA"
  },
  {
    "city": "Barrie-Orillia",
    "country": "Canada",
    "IATA": "YLK",
    "icao_code": "CYLS"
  },
  {
    "city": "Barrow",
    "country": "United States",
    "IATA": "BRW",
    "icao_code": "PABR"
  },
  {
    "city": "Barrow Island",
    "country": "Australia",
    "IATA": "BWB",
    "icao_code": "YBWX"
  },
  {
    "city": "Barrow Island",
    "country": "United Kingdom",
    "IATA": "BWF",
    "icao_code": "EGNL"
  },
  {
    "city": "Barter Island",
    "country": "United States",
    "IATA": "BTI",
    "icao_code": "PABA"
  },
  {
    "city": "Barth",
    "country": "Germany",
    "IATA": "BBH",
    "icao_code": "EDBH"
  },
  {
    "city": "Bartica",
    "country": "Guyana",
    "IATA": "GFO",
    "icao_code": "SYBT"
  },
  {
    "city": "Bartow",
    "country": "United States",
    "IATA": "BOW",
    "icao_code": "KBOW"
  },
  {
    "city": "Barysiai",
    "country": "Lithuania",
    "IATA": "HLJ"
  },
  {
    "city": "Basankusu",
    "country": "Congo (Kinshasa)",
    "IATA": "BSU",
    "icao_code": "FZEN"
  },
  {
    "city": "Basco",
    "country": "Philippines",
    "IATA": "BSO",
    "icao_code": "RPUO"
  },
  {
    "city": "Basrah",
    "country": "Iraq",
    "IATA": "BSR",
    "icao_code": "ORMM"
  },
  {
    "city": "Basse Terre",
    "country": "Guadeloupe",
    "IATA": "BBR"
  },
  {
    "city": "Basse Terre",
    "country": "Saint Kitts and Nevis",
    "IATA": "SKB",
    "icao_code": "TKPK"
  },
  {
    "city": "Bastia",
    "country": "France",
    "IATA": "BIA",
    "icao_code": "LFKB"
  },
  {
    "city": "Bata",
    "country": "Equatorial Guinea",
    "IATA": "BSG",
    "icao_code": "FGBT"
  },
  {
    "city": "Batagay",
    "country": "Russia",
    "IATA": "BQJ",
    "icao_code": "UEBB"
  },
  {
    "city": "Batagay-Alyta",
    "country": "Russia",
    "IATA": "SUK"
  },
  {
    "city": "Batajnica",
    "country": "Serbia",
    "IATA": "BJY",
    "icao_code": "LYBT"
  },
  {
    "city": "Batam",
    "country": "Indonesia",
    "IATA": "BTH",
    "icao_code": "WIKB"
  },
  {
    "city": "Bathurst",
    "country": "Australia",
    "IATA": "BHS",
    "icao_code": "YBTH"
  },
  {
    "city": "Bathurst",
    "country": "Canada",
    "IATA": "ZBF",
    "icao_code": "CZBF"
  },
  {
    "city": "Bathurst Island",
    "country": "Australia",
    "IATA": "BRT",
    "icao_code": "YBTI"
  },
  {
    "city": "Batman",
    "country": "Turkey",
    "IATA": "BAL",
    "icao_code": "LTCJ"
  },
  {
    "city": "Batna",
    "country": "Algeria",
    "IATA": "BLJ",
    "icao_code": "DABT"
  },
  {
    "city": "Baton Rouge",
    "country": "United States",
    "IATA": "BTR",
    "icao_code": "KBTR"
  },
  {
    "city": "Batsfjord",
    "country": "Norway",
    "IATA": "BJF",
    "icao_code": "ENBS"
  },
  {
    "city": "Battambang",
    "country": "Cambodia",
    "IATA": "BBM",
    "icao_code": "VDBG"
  },
  {
    "city": "Batticaloa",
    "country": "Sri Lanka",
    "IATA": "BTC",
    "icao_code": "VCCB"
  },
  {
    "city": "Battle Creek",
    "country": "United States",
    "IATA": "BTL",
    "icao_code": "KBTL"
  },
  {
    "city": "Batu Licin",
    "country": "Indonesia",
    "IATA": "BTW",
    "icao_code": "WAOC"
  },
  {
    "city": "Batumi",
    "country": "Georgia",
    "IATA": "BUS",
    "icao_code": "UGSB"
  },
  {
    "city": "Batuna",
    "country": "Solomon Islands",
    "IATA": "BPF",
    "icao_code": "AGBT"
  },
  {
    "city": "Bau-Bau",
    "country": "Indonesia",
    "IATA": "BUW",
    "icao_code": "WAWB"
  },
  {
    "city": "Baucau",
    "country": "East Timor",
    "IATA": "BCH",
    "icao_code": "WPEC"
  },
  {
    "city": "Bauchi",
    "country": "Nigeria",
    "IATA": "BCU"
  },
  {
    "city": "Baudette",
    "country": "United States",
    "IATA": "BDE",
    "icao_code": "KBDE"
  },
  {
    "city": "Bauru",
    "country": "Brazil",
    "IATA": "JTC",
    "icao_code": "SJTC"
  },
  {
    "city": "Bauru",
    "country": "Brazil",
    "IATA": "BAU",
    "icao_code": "SBBU"
  },
  {
    "city": "Bay City",
    "country": "United States",
    "IATA": "BBC",
    "icao_code": "KBYY"
  },
  {
    "city": "Bayamo",
    "country": "Cuba",
    "IATA": "BYM",
    "icao_code": "MUBY"
  },
  {
    "city": "Bayankhongor",
    "country": "Mongolia",
    "IATA": "BYN",
    "icao_code": "ZMBH"
  },
  {
    "city": "Bayannur",
    "country": "China",
    "IATA": "RLK"
  },
  {
    "city": "Bayreuth",
    "country": "Germany",
    "IATA": "BYU",
    "icao_code": "EDQD"
  },
  {
    "city": "Bearskin Lake",
    "country": "Canada",
    "IATA": "XBE",
    "icao_code": "CNE3"
  },
  {
    "city": "Beaufort",
    "country": "United States",
    "IATA": "BFT",
    "icao_code": "KBFT"
  },
  {
    "city": "Beaumont",
    "country": "United States",
    "IATA": "BMT",
    "icao_code": "KBMT"
  },
  {
    "city": "Beaumont",
    "country": "United States",
    "IATA": "BPT",
    "icao_code": "KBPT"
  },
  {
    "city": "Beauvais",
    "country": "France",
    "IATA": "BVA",
    "icao_code": "LFOB"
  },
  {
    "city": "Beaver",
    "country": "United States",
    "IATA": "WBQ",
    "icao_code": "PAWB"
  },
  {
    "city": "Beaver Creek",
    "country": "Canada",
    "IATA": "YXQ",
    "icao_code": "CYXQ"
  },
  {
    "city": "Beaver Falls",
    "country": "United States",
    "IATA": "BFP",
    "icao_code": "KBVI"
  },
  {
    "city": "Beckley",
    "country": "United States",
    "IATA": "BKW",
    "icao_code": "KBKW"
  },
  {
    "city": "Bedford",
    "country": "United States",
    "IATA": "BED",
    "icao_code": "KBED"
  },
  {
    "city": "Bedourie",
    "country": "Australia",
    "IATA": "BEU",
    "icao_code": "YBIE"
  },
  {
    "city": "Bedwell Harbour",
    "country": "Canada",
    "IATA": "YBW",
    "icao_code": "CYBW"
  },
  {
    "city": "Beer-sheba",
    "country": "Israel",
    "IATA": "BEV",
    "icao_code": "LLBS"
  },
  {
    "city": "Beica",
    "country": "Ethiopia",
    "IATA": "BEI",
    "icao_code": "HABE"
  },
  {
    "city": "Beihai",
    "country": "China",
    "IATA": "BHY",
    "icao_code": "ZGBH"
  },
  {
    "city": "Beihan",
    "country": "Yemen",
    "IATA": "BHN",
    "icao_code": "OYBN"
  },
  {
    "city": "Beijing",
    "country": "China",
    "IATA": "PKX",
    "icao_code": "ZBAD"
  },
  {
    "city": "Beijing",
    "country": "China",
    "IATA": "NAY",
    "icao_code": "ZBBB"
  },
  {
    "city": "Beijing",
    "country": "China",
    "IATA": "PEK",
    "icao_code": "ZBAA"
  },
  {
    "city": "Beira",
    "country": "Mozambique",
    "IATA": "BEW",
    "icao_code": "FQBR"
  },
  {
    "city": "Beirut",
    "country": "Lebanon",
    "IATA": "BEY",
    "icao_code": "OLBA"
  },
  {
    "city": "Beja (madeira)",
    "country": "Portugal",
    "IATA": "BYJ",
    "icao_code": "LPBJ"
  },
  {
    "city": "Bejaja",
    "country": "Algeria",
    "IATA": "BJA",
    "icao_code": "DAAE"
  },
  {
    "city": "Bekily",
    "country": "Madagascar",
    "IATA": "OVA"
  },
  {
    "city": "Belaga",
    "country": "Malaysia",
    "IATA": "BLG",
    "icao_code": "WBGC"
  },
  {
    "city": "Belem",
    "country": "Brazil",
    "IATA": "BEL",
    "icao_code": "SBBE"
  },
  {
    "city": "Belfast",
    "country": "United Kingdom",
    "IATA": "BHD",
    "icao_code": "EGAC"
  },
  {
    "city": "Belfast",
    "country": "United Kingdom",
    "IATA": "BFS",
    "icao_code": "EGAA"
  },
  {
    "city": "Belfort",
    "country": "France",
    "IATA": "BOR"
  },
  {
    "city": "Belgaum",
    "country": "India",
    "IATA": "IXG",
    "icao_code": "VABM"
  },
  {
    "city": "Belgorod",
    "country": "Russia",
    "IATA": "EGO",
    "icao_code": "UUOB"
  },
  {
    "city": "Belgrade",
    "country": "Serbia",
    "IATA": "BEG",
    "icao_code": "LYBE"
  },
  {
    "city": "Belize City",
    "country": "Belize",
    "IATA": "BZE",
    "icao_code": "MZBZ"
  },
  {
    "city": "Bella Coola",
    "country": "Canada",
    "IATA": "QBC",
    "icao_code": "CYBD"
  },
  {
    "city": "Bellary",
    "country": "India",
    "IATA": "BEP",
    "icao_code": "VOBI"
  },
  {
    "city": "Belleville",
    "country": "United States",
    "IATA": "BLV",
    "icao_code": "KBLV"
  },
  {
    "city": "Bellingham",
    "country": "United States",
    "IATA": "BLI",
    "icao_code": "KBLI"
  },
  {
    "city": "Belo Horizonte",
    "country": "Brazil",
    "IATA": "CNF",
    "icao_code": "SBCF"
  },
  {
    "city": "Belo Horizonte",
    "country": "Brazil",
    "IATA": "PLU",
    "icao_code": "SBBH"
  },
  {
    "city": "Belo sur Tsiribihina",
    "country": "Madagascar",
    "IATA": "BMD",
    "icao_code": "FMML"
  },
  {
    "city": "Beloyarsky",
    "country": "Russia",
    "IATA": "EYK",
    "icao_code": "USHQ"
  },
  {
    "city": "Bembridge",
    "country": "United Kingdom",
    "IATA": "BBP",
    "icao_code": "EGHJ"
  },
  {
    "city": "Bemidji",
    "country": "United States",
    "IATA": "BJI",
    "icao_code": "KBJI"
  },
  {
    "city": "Ben Slimane",
    "country": "Morocco",
    "IATA": "GMD",
    "icao_code": "GMMB"
  },
  {
    "city": "Benbecula",
    "country": "United Kingdom",
    "IATA": "BEB",
    "icao_code": "EGPL"
  },
  {
    "city": "Bendigo",
    "country": "Australia",
    "IATA": "BXG",
    "icao_code": "YBDG"
  },
  {
    "city": "Bengbu",
    "country": "China",
    "IATA": "BFU",
    "icao_code": "ZSBB"
  },
  {
    "city": "Benghazi",
    "country": "Libya",
    "IATA": "BEN",
    "icao_code": "HLLB"
  },
  {
    "city": "Bengkulu",
    "country": "Indonesia",
    "IATA": "BKS",
    "icao_code": "WIPL"
  },
  {
    "city": "Benguela",
    "country": "Angola",
    "IATA": "BUG",
    "icao_code": "FNBG"
  },
  {
    "city": "Beni",
    "country": "Congo (Kinshasa)",
    "IATA": "BNC",
    "icao_code": "FZNP"
  },
  {
    "city": "Beni Mellal",
    "country": "Morocco",
    "IATA": "BEM"
  },
  {
    "city": "Benin",
    "country": "Nigeria",
    "IATA": "BNI",
    "icao_code": "DNBE"
  },
  {
    "city": "Benson",
    "country": "United Kingdom",
    "IATA": "BEX",
    "icao_code": "EGUB"
  },
  {
    "city": "Bentonville",
    "country": "United States",
    "IATA": "XNA",
    "icao_code": "KXNA"
  },
  {
    "city": "Bequia",
    "country": "Saint Vincent and the Grenadines",
    "IATA": "BQU",
    "icao_code": "TVSB"
  },
  {
    "city": "Berbera",
    "country": "Somalia",
    "IATA": "BBO",
    "icao_code": "HCMI"
  },
  {
    "city": "Berberati",
    "country": "Central African Republic",
    "IATA": "BBT",
    "icao_code": "FEFT"
  },
  {
    "city": "Berdyansk",
    "country": "Ukraine",
    "IATA": "ERD",
    "icao_code": "UKDB"
  },
  {
    "city": "Berens River",
    "country": "Canada",
    "IATA": "YBV",
    "icao_code": "CYBV"
  },
  {
    "city": "Berezovo",
    "country": "Russia",
    "IATA": "EZV",
    "icao_code": "USHB"
  },
  {
    "city": "Bergamo",
    "country": "Italy",
    "IATA": "BGY",
    "icao_code": "LIME"
  },
  {
    "city": "Bergen",
    "country": "Norway",
    "IATA": "BGO",
    "icao_code": "ENBR"
  },
  {
    "city": "Bergerac",
    "country": "France",
    "IATA": "EGC",
    "icao_code": "LFBE"
  },
  {
    "city": "Berlevag",
    "country": "Norway",
    "IATA": "BVG",
    "icao_code": "ENBV"
  },
  {
    "city": "Berlin",
    "country": "Germany",
    "IATA": "TXL",
    "icao_code": "EDDT"
  },
  {
    "city": "Berlin",
    "country": "Germany",
    "IATA": "THF"
  },
  {
    "city": "Berlin",
    "country": "Germany",
    "IATA": "SXF",
    "icao_code": "EDDB"
  },
  {
    "city": "Bermejo",
    "country": "Bolivia",
    "IATA": "BJO",
    "icao_code": "SLBJ"
  },
  {
    "city": "Bermuda",
    "country": "Bermuda",
    "IATA": "BDA",
    "icao_code": "TXKF"
  },
  {
    "city": "Bern",
    "country": "Switzerland",
    "IATA": "BRN",
    "icao_code": "LSZB"
  },
  {
    "city": "Beru Island",
    "country": "Kiribati",
    "IATA": "BEZ",
    "icao_code": "NGBR"
  },
  {
    "city": "Besalampy",
    "country": "Madagascar",
    "IATA": "BPY",
    "icao_code": "FMNQ"
  },
  {
    "city": "Beslan",
    "country": "Russia",
    "IATA": "OGZ",
    "icao_code": "URMO"
  },
  {
    "city": "Bethel",
    "country": "United States",
    "IATA": "BET",
    "icao_code": "PABE"
  },
  {
    "city": "Bettles",
    "country": "United States",
    "IATA": "BTT",
    "icao_code": "PABT"
  },
  {
    "city": "Beverly",
    "country": "United States",
    "IATA": "BVY",
    "icao_code": "KBVY"
  },
  {
    "city": "Beziers",
    "country": "France",
    "IATA": "BZR",
    "icao_code": "LFMU"
  },
  {
    "city": "Bhagtanwala",
    "country": "Pakistan",
    "IATA": "BHW"
  },
  {
    "city": "Bhairawa",
    "country": "Nepal",
    "IATA": "BWA",
    "icao_code": "VNBW"
  },
  {
    "city": "Bharatpur",
    "country": "Nepal",
    "IATA": "BHR",
    "icao_code": "VNBP"
  },
  {
    "city": "Bhatinda",
    "country": "India",
    "IATA": "BUP",
    "icao_code": "VIBT"
  },
  {
    "city": "Bhaunagar",
    "country": "India",
    "IATA": "BHU",
    "icao_code": "VABV"
  },
  {
    "city": "Bhojpur",
    "country": "Nepal",
    "IATA": "BHP",
    "icao_code": "VNBJ"
  },
  {
    "city": "Bhopal",
    "country": "India",
    "IATA": "BHO",
    "icao_code": "VABP"
  },
  {
    "city": "Bhubaneswar",
    "country": "India",
    "IATA": "BBI",
    "icao_code": "VEBS"
  },
  {
    "city": "Bhuj",
    "country": "India",
    "IATA": "BHJ",
    "icao_code": "VABJ"
  },
  {
    "city": "Biak",
    "country": "Indonesia",
    "IATA": "BIK",
    "icao_code": "WABB"
  },
  {
    "city": "Biarritz-bayonne",
    "country": "France",
    "IATA": "BIQ",
    "icao_code": "LFBZ"
  },
  {
    "city": "Biała Podlaska",
    "country": "Poland",
    "IATA": "BXP",
    "icao_code": "EPBP"
  },
  {
    "city": "Bielefeld",
    "country": "Germany",
    "IATA": "BFE",
    "icao_code": "EDLI"
  },
  {
    "city": "Big Mountain",
    "country": "United States",
    "IATA": "BMX"
  },
  {
    "city": "Big Piney",
    "country": "United States",
    "IATA": "BPI",
    "icao_code": "KBPI"
  },
  {
    "city": "Big Trout Lake",
    "country": "Canada",
    "IATA": "YTL",
    "icao_code": "CYTL"
  },
  {
    "city": "Biggin Hill",
    "country": "United Kingdom",
    "IATA": "BQH",
    "icao_code": "EGKB"
  },
  {
    "city": "Bijie",
    "country": "China",
    "IATA": "BFJ",
    "icao_code": "ZUBJ"
  },
  {
    "city": "Bikaner",
    "country": "India",
    "IATA": "BKB",
    "icao_code": "VIBK"
  },
  {
    "city": "Bilaspur",
    "country": "India",
    "IATA": "PAB",
    "icao_code": "VABI"
  },
  {
    "city": "Bilbao",
    "country": "Spain",
    "IATA": "BIO",
    "icao_code": "LEBB"
  },
  {
    "city": "Bildudalur",
    "country": "Iceland",
    "IATA": "BIU",
    "icao_code": "BIBD"
  },
  {
    "city": "Billings",
    "country": "United States",
    "IATA": "BIL",
    "icao_code": "KBIL"
  },
  {
    "city": "Billund",
    "country": "Denmark",
    "IATA": "BLL",
    "icao_code": "EKBI"
  },
  {
    "city": "Biloela",
    "country": "Australia",
    "IATA": "THG",
    "icao_code": "YTNG"
  },
  {
    "city": "Biloxi",
    "country": "United States",
    "IATA": "BIX"
  },
  {
    "city": "Bima",
    "country": "Indonesia",
    "IATA": "BMU",
    "icao_code": "WRRB"
  },
  {
    "city": "Binghamton",
    "country": "United States",
    "IATA": "BGM",
    "icao_code": "KBGM"
  },
  {
    "city": "Bingol",
    "country": "Turkey",
    "IATA": "BGG"
  },
  {
    "city": "Bintulu",
    "country": "Malaysia",
    "IATA": "BTU",
    "icao_code": "WBGB"
  },
  {
    "city": "Bintuni",
    "country": "Indonesia",
    "IATA": "NTI",
    "icao_code": "WASB"
  },
  {
    "city": "Biratnagar",
    "country": "Nepal",
    "IATA": "BIR",
    "icao_code": "VNVT"
  },
  {
    "city": "Birburg",
    "country": "Germany",
    "IATA": "BBJ",
    "icao_code": "EDAB"
  },
  {
    "city": "Bird Island",
    "country": "Seychelles",
    "IATA": "BDI",
    "icao_code": "FSSB"
  },
  {
    "city": "Birdsville",
    "country": "Australia",
    "IATA": "BVI",
    "icao_code": "YBDV"
  },
  {
    "city": "Birjand",
    "country": "Iran",
    "IATA": "XBJ",
    "icao_code": "OIMB"
  },
  {
    "city": "Birmingham",
    "country": "United States",
    "IATA": "BHM",
    "icao_code": "KBHM"
  },
  {
    "city": "Birmingham",
    "country": "United Kingdom",
    "IATA": "BHX",
    "icao_code": "EGBB"
  },
  {
    "city": "Bisha",
    "country": "Saudi Arabia",
    "IATA": "BHH",
    "icao_code": "OEBH"
  },
  {
    "city": "Bishkek",
    "country": "Kyrgyzstan",
    "IATA": "FRU",
    "icao_code": "UAFM"
  },
  {
    "city": "Bisho",
    "country": "South Africa",
    "IATA": "BIY",
    "icao_code": "FABE"
  },
  {
    "city": "Bishop",
    "country": "United States",
    "IATA": "BIH",
    "icao_code": "KBIH"
  },
  {
    "city": "Biskra",
    "country": "Algeria",
    "IATA": "BSK",
    "icao_code": "DAUB"
  },
  {
    "city": "Bismarck",
    "country": "United States",
    "IATA": "BIS",
    "icao_code": "KBIS"
  },
  {
    "city": "Bissau",
    "country": "Guinea-Bissau",
    "IATA": "OXB",
    "icao_code": "GGOV"
  },
  {
    "city": "Bitam",
    "country": "Gabon",
    "IATA": "BMM",
    "icao_code": "FOOB"
  },
  {
    "city": "Black Tickle",
    "country": "Canada",
    "IATA": "YBI"
  },
  {
    "city": "Blackall",
    "country": "Australia",
    "IATA": "BKQ",
    "icao_code": "YBCK"
  },
  {
    "city": "Blackbushe",
    "country": "United Kingdom",
    "IATA": "BBS",
    "icao_code": "EGLK"
  },
  {
    "city": "Blackpool",
    "country": "United Kingdom",
    "IATA": "BLK",
    "icao_code": "EGNH"
  },
  {
    "city": "Blackwater",
    "country": "Australia",
    "IATA": "BLT",
    "icao_code": "YBTR"
  },
  {
    "city": "Blagoveschensk",
    "country": "Russia",
    "IATA": "BQS",
    "icao_code": "UHBB"
  },
  {
    "city": "Blantyre",
    "country": "Malawi",
    "IATA": "BLZ",
    "icao_code": "FWCL"
  },
  {
    "city": "Blida",
    "country": "Algeria",
    "IATA": "QLD"
  },
  {
    "city": "Block Island",
    "country": "United States",
    "IATA": "BID",
    "icao_code": "KBID"
  },
  {
    "city": "Bloemfontein",
    "country": "South Africa",
    "IATA": "BFN",
    "icao_code": "FABL"
  },
  {
    "city": "Bloomington",
    "country": "United States",
    "IATA": "BMG",
    "icao_code": "KBMG"
  },
  {
    "city": "Bloomington",
    "country": "United States",
    "IATA": "BMI",
    "icao_code": "KBMI"
  },
  {
    "city": "Bluefield",
    "country": "United States",
    "IATA": "BLF"
  },
  {
    "city": "Bluefields",
    "country": "Nicaragua",
    "IATA": "BEF",
    "icao_code": "MNBL"
  },
  {
    "city": "Blythe",
    "country": "United States",
    "IATA": "BLH",
    "icao_code": "KBLH"
  },
  {
    "city": "Blytheville",
    "country": "United States",
    "IATA": "BYH",
    "icao_code": "KBYH"
  },
  {
    "city": "Bo",
    "country": "Sierra Leone",
    "IATA": "KBS",
    "icao_code": "GFBO"
  },
  {
    "city": "Boa Vista",
    "country": "Brazil",
    "IATA": "BVB",
    "icao_code": "SBBV"
  },
  {
    "city": "Boa Vista",
    "country": "Cape Verde",
    "IATA": "BVC",
    "icao_code": "GVBA"
  },
  {
    "city": "Bob Quinn Lake",
    "country": "Canada",
    "IATA": "YBO"
  },
  {
    "city": "Bobo-dioulasso",
    "country": "Burkina Faso",
    "IATA": "BOY",
    "icao_code": "DFOO"
  },
  {
    "city": "South Florida, All Airports",
    "country": "United States",
    "IATA": "SFALL"
  },
  {
    "city": "South Florida, Boca Raton",
    "country": "United States",
    "IATA": "BCT",
    "icao_code": "KBCT"
  },
  {
    "city": "Bocas Del Toro",
    "country": "Panama",
    "IATA": "BOC",
    "icao_code": "MPBO"
  },
  {
    "city": "Bodaibo",
    "country": "Russia",
    "IATA": "ODO"
  },
  {
    "city": "Bodinumu",
    "country": "Papua New Guinea",
    "IATA": "BNM"
  },
  {
    "city": "Bodo",
    "country": "Norway",
    "IATA": "BOO",
    "icao_code": "ENBO"
  },
  {
    "city": "Bodrum",
    "country": "Turkey",
    "IATA": "BJV",
    "icao_code": "LTFE"
  },
  {
    "city": "Bodrum",
    "country": "Turkey",
    "IATA": "BXN",
    "icao_code": "LTBV"
  },
  {
    "city": "Boende",
    "country": "Congo (Kinshasa)",
    "IATA": "BNB",
    "icao_code": "FZGN"
  },
  {
    "city": "Bogorodskoe",
    "country": "Russia",
    "IATA": "BQG"
  },
  {
    "city": "Bogota",
    "country": "Colombia",
    "IATA": "BOG",
    "icao_code": "SKBO"
  },
  {
    "city": "Boigu",
    "country": "Australia",
    "IATA": "GIC",
    "icao_code": "YBOI"
  },
  {
    "city": "Bointo",
    "country": "Brazil",
    "IATA": "BYO",
    "icao_code": "SJDB"
  },
  {
    "city": "Boise",
    "country": "United States",
    "IATA": "BOI",
    "icao_code": "KBOI"
  },
  {
    "city": "Bojnourd",
    "country": "Iran",
    "IATA": "BJB",
    "icao_code": "OIMN"
  },
  {
    "city": "Bokepyin",
    "country": "Burma",
    "IATA": "VBP"
  },
  {
    "city": "Bokondini-Papua Island",
    "country": "Indonesia",
    "IATA": "BUI",
    "icao_code": "WAJB"
  },
  {
    "city": "Bole",
    "country": "China",
    "IATA": "BPL",
    "icao_code": "ZWBL"
  },
  {
    "city": "Bologna",
    "country": "Italy",
    "IATA": "BLQ",
    "icao_code": "LIPE"
  },
  {
    "city": "Bolzano",
    "country": "Italy",
    "IATA": "BZO",
    "icao_code": "LIPB"
  },
  {
    "city": "Bom Jesus Da Lapa",
    "country": "Brazil",
    "IATA": "LAZ",
    "icao_code": "SBLP"
  },
  {
    "city": "Boma",
    "country": "Congo (Kinshasa)",
    "IATA": "BOA",
    "icao_code": "FZAJ"
  },
  {
    "city": "Bonanza",
    "country": "Nicaragua",
    "IATA": "BZA",
    "icao_code": "MNBZ"
  },
  {
    "city": "Bonaventure",
    "country": "Canada",
    "IATA": "YVB",
    "icao_code": "CYVB"
  },
  {
    "city": "Bonnyville",
    "country": "Canada",
    "IATA": "YBY",
    "icao_code": "CYBF"
  },
  {
    "city": "Bonthe",
    "country": "Sierra Leone",
    "IATA": "BTE",
    "icao_code": "GFBN"
  },
  {
    "city": "Bor",
    "country": "Russia",
    "IATA": "TGP",
    "icao_code": "UNIP"
  },
  {
    "city": "Bora Bora",
    "country": "French Polynesia",
    "IATA": "BOB",
    "icao_code": "NTTB"
  },
  {
    "city": "Borba",
    "country": "Brazil",
    "IATA": "RBB",
    "icao_code": "SWBR"
  },
  {
    "city": "Bordeaux",
    "country": "France",
    "IATA": "BOD",
    "icao_code": "LFBD"
  },
  {
    "city": "Bordj Badji Mokhtar",
    "country": "Algeria",
    "IATA": "BMW",
    "icao_code": "DATM"
  },
  {
    "city": "Borger",
    "country": "United States",
    "IATA": "BGD",
    "icao_code": "KBGD"
  },
  {
    "city": "Borkum",
    "country": "Germany",
    "IATA": "BMK"
  },
  {
    "city": "Borlange",
    "country": "Sweden",
    "IATA": "BLE",
    "icao_code": "ESSD"
  },
  {
    "city": "Borroloola",
    "country": "Australia",
    "IATA": "BOX",
    "icao_code": "BOCH"
  },
  {
    "city": "Bosaso",
    "country": "Somalia",
    "IATA": "BSA",
    "icao_code": "HCMF"
  },
  {
    "city": "Boston",
    "country": "United States",
    "IATA": "BOS",
    "icao_code": "KBOS"
  },
  {
    "city": "Botucatu",
    "country": "Brazil",
    "IATA": "QCJ"
  },
  {
    "city": "Bou Saada",
    "country": "Algeria",
    "IATA": "BUJ",
    "icao_code": "DAAD"
  },
  {
    "city": "Bouake",
    "country": "Cote d'Ivoire",
    "IATA": "BYK",
    "icao_code": "DIBK"
  },
  {
    "city": "Bouarfa",
    "country": "Morocco",
    "IATA": "UAR"
  },
  {
    "city": "Boulder",
    "country": "United States",
    "IATA": "WBU",
    "icao_code": "KBDU"
  },
  {
    "city": "Boulia",
    "country": "Australia",
    "IATA": "BQL",
    "icao_code": "YBOU"
  },
  {
    "city": "Boundary Bay",
    "country": "Canada",
    "IATA": "YDT",
    "icao_code": "CZBB"
  },
  {
    "city": "Bourgas",
    "country": "Bulgaria",
    "IATA": "BOJ",
    "icao_code": "LBBG"
  },
  {
    "city": "Bourges",
    "country": "France",
    "IATA": "BOU",
    "icao_code": "LFLD"
  },
  {
    "city": "Bourke",
    "country": "Australia",
    "IATA": "BRK",
    "icao_code": "YBKE"
  },
  {
    "city": "Bournemouth",
    "country": "United Kingdom",
    "IATA": "BOH",
    "icao_code": "EGHH"
  },
  {
    "city": "Boven Digoel",
    "country": "Indonesia",
    "IATA": "TMH",
    "icao_code": "WAKT"
  },
  {
    "city": "Bowen",
    "country": "Australia",
    "IATA": "ZBO",
    "icao_code": "YBWN"
  },
  {
    "city": "Bowling Green",
    "country": "United States",
    "IATA": "BWG",
    "icao_code": "KBWG"
  },
  {
    "city": "Bozeman",
    "country": "United States",
    "IATA": "BZN",
    "icao_code": "KBZN"
  },
  {
    "city": "Brac",
    "country": "Croatia",
    "IATA": "BWK",
    "icao_code": "LDSB"
  },
  {
    "city": "Bradford",
    "country": "United States",
    "IATA": "BFD",
    "icao_code": "KBFD"
  },
  {
    "city": "Bradshaw Field",
    "country": "United States",
    "IATA": "BSF"
  },
  {
    "city": "Brady",
    "country": "United States",
    "IATA": "BBD",
    "icao_code": "KBBD"
  },
  {
    "city": "Braga",
    "country": "Portugal",
    "IATA": "BGZ",
    "icao_code": "LPBR"
  },
  {
    "city": "Braganca",
    "country": "Portugal",
    "IATA": "BGC",
    "icao_code": "LPBG"
  },
  {
    "city": "Braganca Paulista",
    "country": "Brazil",
    "IATA": "BJP",
    "icao_code": "SBBP"
  },
  {
    "city": "Brainerd",
    "country": "United States",
    "IATA": "BRD",
    "icao_code": "KBRD"
  },
  {
    "city": "Brampton Island",
    "country": "Australia",
    "IATA": "BMP",
    "icao_code": "YBPI"
  },
  {
    "city": "Brandon",
    "country": "Canada",
    "IATA": "YBR",
    "icao_code": "CYBR"
  },
  {
    "city": "Branson",
    "country": "United States",
    "IATA": "BKG",
    "icao_code": "KBBG"
  },
  {
    "city": "Brasilia",
    "country": "Brazil",
    "IATA": "BSB",
    "icao_code": "SBBR"
  },
  {
    "city": "Bratislava",
    "country": "Slovakia",
    "IATA": "BTS",
    "icao_code": "LZIB"
  },
  {
    "city": "Bratsk",
    "country": "Russia",
    "IATA": "BTK",
    "icao_code": "UIBB"
  },
  {
    "city": "Braunschweig",
    "country": "Germany",
    "IATA": "BWE",
    "icao_code": "EDVE"
  },
  {
    "city": "Brazzaville",
    "country": "Congo (Brazzaville)",
    "IATA": "BZV",
    "icao_code": "FCBB"
  },
  {
    "city": "Breckenridge",
    "country": "United States",
    "IATA": "BKD"
  },
  {
    "city": "Bremen",
    "country": "Germany",
    "IATA": "BRE",
    "icao_code": "EDDW"
  },
  {
    "city": "Bremerhaven",
    "country": "Germany",
    "IATA": "BRV"
  },
  {
    "city": "Bremerton",
    "country": "United States",
    "IATA": "PWT",
    "icao_code": "KPWT"
  },
  {
    "city": "Brescia",
    "country": "Italy",
    "IATA": "VBS",
    "icao_code": "LIPO"
  },
  {
    "city": "Brest",
    "country": "Belarus",
    "IATA": "BQT",
    "icao_code": "UMBB"
  },
  {
    "city": "Brest",
    "country": "France",
    "IATA": "BES",
    "icao_code": "LFRB"
  },
  {
    "city": "Breves",
    "country": "Brazil",
    "IATA": "BVS",
    "icao_code": "SNVS"
  },
  {
    "city": "Brevig Mission",
    "country": "United States",
    "IATA": "KTS",
    "icao_code": "PFKT"
  },
  {
    "city": "Bridgetown",
    "country": "Barbados",
    "IATA": "BGI",
    "icao_code": "TBPB"
  },
  {
    "city": "Brigham City",
    "country": "United States",
    "IATA": "BMC",
    "icao_code": "KBMC"
  },
  {
    "city": "Brindisi",
    "country": "Italy",
    "IATA": "BDS",
    "icao_code": "LIBR"
  },
  {
    "city": "Brisbane",
    "country": "Australia",
    "IATA": "BNE",
    "icao_code": "YBBN"
  },
  {
    "city": "Brisbane",
    "country": "Australia",
    "IATA": "ACF"
  },
  {
    "city": "Bristol",
    "country": "United Kingdom",
    "IATA": "FZO"
  },
  {
    "city": "Bristol",
    "country": "United Kingdom",
    "IATA": "BRS",
    "icao_code": "EGGD"
  },
  {
    "city": "Brive",
    "country": "France",
    "IATA": "BVE",
    "icao_code": "LFBV"
  },
  {
    "city": "Brize Norton",
    "country": "United Kingdom",
    "IATA": "BZZ",
    "icao_code": "EGVN"
  },
  {
    "city": "Brno",
    "country": "Czech Republic",
    "IATA": "BRQ",
    "icao_code": "LKTB"
  },
  {
    "city": "Brochet",
    "country": "Canada",
    "IATA": "YBT",
    "icao_code": "CYBT"
  },
  {
    "city": "Brockman",
    "country": "Australia",
    "IATA": "OCM",
    "icao_code": "YBGD"
  },
  {
    "city": "Broken Hill",
    "country": "Australia",
    "IATA": "BHQ",
    "icao_code": "YBHI"
  },
  {
    "city": "Bromont",
    "country": "Canada",
    "IATA": "ZBM",
    "icao_code": "CZBM"
  },
  {
    "city": "Bronnoysund",
    "country": "Norway",
    "IATA": "BNN",
    "icao_code": "ENBN"
  },
  {
    "city": "Broome",
    "country": "Australia",
    "IATA": "BME",
    "icao_code": "YBRM"
  },
  {
    "city": "Broomfield-CO",
    "country": "United States",
    "IATA": "BJC",
    "icao_code": "KBJC"
  },
  {
    "city": "Broughton Island",
    "country": "Canada",
    "IATA": "YVM",
    "icao_code": "CYVM"
  },
  {
    "city": "Brownsville",
    "country": "United States",
    "IATA": "BRO",
    "icao_code": "KBRO"
  },
  {
    "city": "Brunswick",
    "country": "United States",
    "IATA": "NHZ",
    "icao_code": "KNHZ"
  },
  {
    "city": "Brunswick",
    "country": "United States",
    "IATA": "SSI",
    "icao_code": "KSSI"
  },
  {
    "city": "Brunswick",
    "country": "United States",
    "IATA": "BQK",
    "icao_code": "KBQK"
  },
  {
    "city": "Brus Laguna",
    "country": "Honduras",
    "IATA": "BHG",
    "icao_code": "MHBL"
  },
  {
    "city": "Brussels",
    "country": "Belgium",
    "IATA": "BRU",
    "icao_code": "EBBR"
  },
  {
    "city": "Brusselton",
    "country": "Australia",
    "IATA": "BQB"
  },
  {
    "city": "Bryan",
    "country": "United States",
    "IATA": "CFD"
  },
  {
    "city": "Bryansk",
    "country": "Russia",
    "IATA": "BZK",
    "icao_code": "UUBP"
  },
  {
    "city": "Bryce Canyon",
    "country": "United States",
    "IATA": "BCE",
    "icao_code": "KBCE"
  },
  {
    "city": "Buariki",
    "country": "Kiribati",
    "IATA": "AAK",
    "icao_code": "NGUK"
  },
  {
    "city": "Bubaque",
    "country": "Guinea-Bissau",
    "IATA": "BQE",
    "icao_code": "GGBU"
  },
  {
    "city": "Bucaramanga",
    "country": "Colombia",
    "IATA": "BGA",
    "icao_code": "SKBG"
  },
  {
    "city": "Bucharest",
    "country": "Romania",
    "IATA": "OTP",
    "icao_code": "LROP"
  },
  {
    "city": "Bucharest",
    "country": "Romania",
    "IATA": "BBU",
    "icao_code": "LRBS"
  },
  {
    "city": "Buckeye",
    "country": "United States",
    "IATA": "BXK",
    "icao_code": "KBXK"
  },
  {
    "city": "Buckland",
    "country": "United States",
    "IATA": "BKC",
    "icao_code": "PABL"
  },
  {
    "city": "Buckley",
    "country": "United States",
    "IATA": "BFK"
  },
  {
    "city": "Budapest",
    "country": "Hungary",
    "IATA": "BUD",
    "icao_code": "LHBP"
  },
  {
    "city": "Buenaventura",
    "country": "Colombia",
    "IATA": "BUN",
    "icao_code": "SKBU"
  },
  {
    "city": "Buenos Aires",
    "country": "Argentina",
    "IATA": "EZE",
    "icao_code": "SAEZ"
  },
  {
    "city": "Buenos Aires",
    "country": "Argentina",
    "IATA": "AEP",
    "icao_code": "SABE"
  },
  {
    "city": "Buenos Aires",
    "country": "Costa Rica",
    "IATA": "BAI",
    "icao_code": "MRBA"
  },
  {
    "city": "Buffalo",
    "country": "United States",
    "IATA": "BUF",
    "icao_code": "KBUF"
  },
  {
    "city": "Buffalo Narrows",
    "country": "Canada",
    "IATA": "YVT",
    "icao_code": "CYVT"
  },
  {
    "city": "Bugulma",
    "country": "Russia",
    "IATA": "UUA",
    "icao_code": "UWKB"
  },
  {
    "city": "Bujumbura",
    "country": "Burundi",
    "IATA": "BJM",
    "icao_code": "HBBA"
  },
  {
    "city": "Buka Island",
    "country": "Papua New Guinea",
    "IATA": "BUA",
    "icao_code": "AYBK"
  },
  {
    "city": "Bukavu/kavumu",
    "country": "Congo (Kinshasa)",
    "IATA": "BKY",
    "icao_code": "FZMA"
  },
  {
    "city": "Bukhara",
    "country": "Uzbekistan",
    "IATA": "BHK",
    "icao_code": "UTSB"
  },
  {
    "city": "Bukoba",
    "country": "Tanzania",
    "IATA": "BKZ",
    "icao_code": "HTBU"
  },
  {
    "city": "Bulawayo",
    "country": "Zimbabwe",
    "IATA": "BUQ",
    "icao_code": "FVBU"
  },
  {
    "city": "Bulgan",
    "country": "Mongolia",
    "IATA": "UGA",
    "icao_code": "ZMBN"
  },
  {
    "city": "Bullocks Harbour",
    "country": "Bahamas",
    "IATA": "GHC",
    "icao_code": "MYBG"
  },
  {
    "city": "Bulolo",
    "country": "Papua New Guinea",
    "IATA": "BUL",
    "icao_code": "AYBU"
  },
  {
    "city": "Bumba",
    "country": "Congo (Kinshasa)",
    "IATA": "BMB",
    "icao_code": "FZFU"
  },
  {
    "city": "Bumi Hills",
    "country": "Zimbabwe",
    "IATA": "BZH"
  },
  {
    "city": "Bunbury",
    "country": "Australia",
    "IATA": "BUY",
    "icao_code": "KBUY"
  },
  {
    "city": "Bundaberg",
    "country": "Australia",
    "IATA": "BDB",
    "icao_code": "YBUD"
  },
  {
    "city": "Bunia",
    "country": "Congo (Kinshasa)",
    "IATA": "BUX",
    "icao_code": "FZKA"
  },
  {
    "city": "Bunyu",
    "country": "Indonesia",
    "IATA": "BYQ",
    "icao_code": "WALV"
  },
  {
    "city": "Buochs",
    "country": "Switzerland",
    "IATA": "BXO",
    "icao_code": "LSZC"
  },
  {
    "city": "Buol",
    "country": "Indonesia",
    "IATA": "UOL",
    "icao_code": "WAMQ"
  },
  {
    "city": "Buonmethuot",
    "country": "Vietnam",
    "IATA": "BMV",
    "icao_code": "VVBM"
  },
  {
    "city": "Burao",
    "country": "Somalia",
    "IATA": "BUO",
    "icao_code": "HCMV"
  },
  {
    "city": "Los Angeles, All Airports",
    "country": "United States",
    "IATA": "LOSALL"
  },
  {
    "city": "Los Angeles, Burbank",
    "country": "United States",
    "IATA": "BUR",
    "icao_code": "KBUR"
  },
  {
    "city": "Burg Feuerstein",
    "country": "Germany",
    "IATA": "URD"
  },
  {
    "city": "Burgos",
    "country": "Spain",
    "IATA": "RGS",
    "icao_code": "LEBG"
  },
  {
    "city": "Buri Ram",
    "country": "Thailand",
    "IATA": "BFV",
    "icao_code": "VTUO"
  },
  {
    "city": "Burketown",
    "country": "Australia",
    "IATA": "BUC",
    "icao_code": "YBKT"
  },
  {
    "city": "Burley",
    "country": "United States",
    "IATA": "BYI",
    "icao_code": "KBYI"
  },
  {
    "city": "Burlington",
    "country": "United States",
    "IATA": "BRL",
    "icao_code": "KBRL"
  },
  {
    "city": "Burlington",
    "country": "United States",
    "IATA": "BTV",
    "icao_code": "KBTV"
  },
  {
    "city": "Burnie",
    "country": "Australia",
    "IATA": "BWT",
    "icao_code": "YBUI"
  },
  {
    "city": "Burns",
    "country": "United States",
    "IATA": "BNO",
    "icao_code": "KBNO"
  },
  {
    "city": "Burqin",
    "country": "China",
    "IATA": "KJI",
    "icao_code": "ZWKN"
  },
  {
    "city": "Bursa",
    "country": "Turkey",
    "IATA": "BTZ"
  },
  {
    "city": "Buru Island",
    "country": "Indonesia",
    "IATA": "NRE",
    "icao_code": "WAPG"
  },
  {
    "city": "Burwash",
    "country": "Canada",
    "IATA": "YDB",
    "icao_code": "CYDB"
  },
  {
    "city": "Busan",
    "country": "South Korea",
    "IATA": "PUS",
    "icao_code": "RKPK"
  },
  {
    "city": "Bush Field",
    "country": "United States",
    "IATA": "AGS",
    "icao_code": "KAGS"
  },
  {
    "city": "Bushehr",
    "country": "Iran",
    "IATA": "BUZ",
    "icao_code": "OIBB"
  },
  {
    "city": "Busuanga",
    "country": "Philippines",
    "IATA": "USU",
    "icao_code": "RPVV"
  },
  {
    "city": "Buta Zega",
    "country": "Congo (Kinshasa)",
    "IATA": "BZU",
    "icao_code": "FZKJ"
  },
  {
    "city": "Butaritari",
    "country": "Kiribati",
    "IATA": "BBG",
    "icao_code": "NGTU"
  },
  {
    "city": "Butte",
    "country": "United States",
    "IATA": "BTM",
    "icao_code": "KBTM"
  },
  {
    "city": "Butterworth",
    "country": "Malaysia",
    "IATA": "BWH",
    "icao_code": "WMKB"
  },
  {
    "city": "Butuan",
    "country": "Philippines",
    "IATA": "BXU",
    "icao_code": "RPWE"
  },
  {
    "city": "Buzios",
    "country": "Brazil",
    "IATA": "BZC",
    "icao_code": "SBBZ"
  },
  {
    "city": "Bydgoszcz",
    "country": "Poland",
    "IATA": "BZG",
    "icao_code": "EPBY"
  },
  {
    "city": "Béchar",
    "country": "Algeria",
    "IATA": "CBH",
    "icao_code": "DAOR"
  },
  {
    "city": "Büsum",
    "country": "Germany",
    "IATA": "HEI"
  },
  {
    "city": "COSTA MARQUES",
    "country": "Brazil",
    "IATA": "CQS",
    "icao_code": "SWCQ"
  },
  {
    "city": "Ca Mau",
    "country": "Vietnam",
    "IATA": "CAH",
    "icao_code": "VVCM"
  },
  {
    "city": "Cabinda",
    "country": "Angola",
    "IATA": "CAB",
    "icao_code": "FNCA"
  },
  {
    "city": "Cabo Frio",
    "country": "Brazil",
    "IATA": "CFB",
    "icao_code": "SBCB"
  },
  {
    "city": "Cabo Rojo",
    "country": "Dominican Republic",
    "IATA": "CBJ"
  },
  {
    "city": "Cacador",
    "country": "Brazil",
    "IATA": "CFC",
    "icao_code": "SBCD"
  },
  {
    "city": "Caceres",
    "country": "Brazil",
    "IATA": "CCX"
  },
  {
    "city": "Cacoal",
    "country": "Brazil",
    "IATA": "OAL",
    "icao_code": "SSKW"
  },
  {
    "city": "Caen",
    "country": "France",
    "IATA": "CFR",
    "icao_code": "LFRK"
  },
  {
    "city": "Cagayan de Oro City",
    "country": "Philippines",
    "IATA": "CGY",
    "icao_code": "RPML"
  },
  {
    "city": "Cagliari",
    "country": "Italy",
    "IATA": "CAG",
    "icao_code": "LIEE"
  },
  {
    "city": "Cahors",
    "country": "France",
    "IATA": "ZAO",
    "icao_code": "LFCC"
  },
  {
    "city": "Caicara De Orinoco",
    "country": "Venezuela",
    "IATA": "CXA",
    "icao_code": "SVCD"
  },
  {
    "city": "Cairns",
    "country": "Australia",
    "IATA": "CNS",
    "icao_code": "YBCS"
  },
  {
    "city": "Cairo",
    "country": "Egypt",
    "IATA": "CWE"
  },
  {
    "city": "Cairo",
    "country": "Egypt",
    "IATA": "CAI",
    "icao_code": "HECA"
  },
  {
    "city": "Cajamarca",
    "country": "Peru",
    "IATA": "CJA",
    "icao_code": "SPJB"
  },
  {
    "city": "Calabar",
    "country": "Nigeria",
    "IATA": "CBQ",
    "icao_code": "DNCA"
  },
  {
    "city": "Calabozo",
    "country": "Venezuela",
    "IATA": "CLZ",
    "icao_code": "SVCL"
  },
  {
    "city": "Calais",
    "country": "France",
    "IATA": "CQF",
    "icao_code": "LFAC"
  },
  {
    "city": "Calama",
    "country": "Chile",
    "IATA": "CJC",
    "icao_code": "SCCF"
  },
  {
    "city": "Calbayog City",
    "country": "Philippines",
    "IATA": "CYP",
    "icao_code": "RPVC"
  },
  {
    "city": "Caldas Novas",
    "country": "Brazil",
    "IATA": "CLV",
    "icao_code": "SBCN"
  },
  {
    "city": "Caldwell",
    "country": "United States",
    "IATA": "CDW",
    "icao_code": "KCDW"
  },
  {
    "city": "Calexico",
    "country": "United States",
    "IATA": "CXL",
    "icao_code": "KCXL"
  },
  {
    "city": "Calgary",
    "country": "Canada",
    "IATA": "YYC",
    "icao_code": "CYYC"
  },
  {
    "city": "Cali",
    "country": "Colombia",
    "IATA": "CLO",
    "icao_code": "SKCL"
  },
  {
    "city": "Calicut",
    "country": "India",
    "IATA": "CCJ",
    "icao_code": "VOCL"
  },
  {
    "city": "Caloundra",
    "country": "Australia",
    "IATA": "CUD",
    "icao_code": "YCDR"
  },
  {
    "city": "Calvi",
    "country": "France",
    "IATA": "CLY",
    "icao_code": "LFKC"
  },
  {
    "city": "Camaguey",
    "country": "Cuba",
    "IATA": "CMW",
    "icao_code": "MUCM"
  },
  {
    "city": "Cambridge",
    "country": "United Kingdom",
    "IATA": "CBG",
    "icao_code": "EGSC"
  },
  {
    "city": "Cambridge Bay",
    "country": "Canada",
    "IATA": "YCB",
    "icao_code": "CYCB"
  },
  {
    "city": "Camden",
    "country": "United States",
    "IATA": "CDN"
  },
  {
    "city": "Camden",
    "country": "Australia",
    "IATA": "CDU",
    "icao_code": "YSCN"
  },
  {
    "city": "Camiguin",
    "country": "Philippines",
    "IATA": "CGM"
  },
  {
    "city": "Camp Bastion",
    "country": "Afghanistan",
    "IATA": "OAZ",
    "icao_code": "OAZJ"
  },
  {
    "city": "Camp Douglas",
    "country": "United States",
    "IATA": "VOK",
    "icao_code": "KVOK"
  },
  {
    "city": "Camp Springs",
    "country": "United States",
    "IATA": "ADW"
  },
  {
    "city": "Campbell River",
    "country": "Canada",
    "IATA": "YBL",
    "icao_code": "CYBL"
  },
  {
    "city": "Campbeltown",
    "country": "United Kingdom",
    "IATA": "CAL",
    "icao_code": "EGEC"
  },
  {
    "city": "Campeche",
    "country": "Mexico",
    "IATA": "CPE",
    "icao_code": "MMCP"
  },
  {
    "city": "Campina Grande",
    "country": "Brazil",
    "IATA": "CPV",
    "icao_code": "SBKG"
  },
  {
    "city": "Campinas",
    "country": "Brazil",
    "IATA": "CPQ",
    "icao_code": "SDAM"
  },
  {
    "city": "Campinas",
    "country": "Brazil",
    "IATA": "VCP",
    "icao_code": "SBKP"
  },
  {
    "city": "Campo Grande",
    "country": "Brazil",
    "IATA": "CGR",
    "icao_code": "SBCG"
  },
  {
    "city": "Campos",
    "country": "Brazil",
    "IATA": "CAW",
    "icao_code": "SBCP"
  },
  {
    "city": "Can Tho",
    "country": "Vietnam",
    "IATA": "VCA",
    "icao_code": "VVCT"
  },
  {
    "city": "Canaima",
    "country": "Venezuela",
    "IATA": "CAJ",
    "icao_code": "SVCN"
  },
  {
    "city": "Canakkale",
    "country": "Turkey",
    "IATA": "CKZ",
    "icao_code": "LTBH"
  },
  {
    "city": "Canarana",
    "country": "Brazil",
    "IATA": "CQA",
    "icao_code": "SWEK"
  },
  {
    "city": "Canberra",
    "country": "Australia",
    "IATA": "CBR",
    "icao_code": "YSCB"
  },
  {
    "city": "Cancun",
    "country": "Mexico",
    "IATA": "CUN",
    "icao_code": "MMUN"
  },
  {
    "city": "Canefield",
    "country": "Dominica",
    "IATA": "DCF",
    "icao_code": "TDCF"
  },
  {
    "city": "Cangyuan",
    "country": "China",
    "IATA": "CWJ",
    "icao_code": "ZPCW"
  },
  {
    "city": "Cannes",
    "country": "France",
    "IATA": "CEQ",
    "icao_code": "LFMD"
  },
  {
    "city": "Canouan Island",
    "country": "Saint Vincent and the Grenadines",
    "IATA": "CIW",
    "icao_code": "TVSC"
  },
  {
    "city": "Canton Island",
    "country": "Kiribati",
    "IATA": "CIS",
    "icao_code": "PCIS"
  },
  {
    "city": "Cap Haitien",
    "country": "Haiti",
    "IATA": "CAP",
    "icao_code": "MTCH"
  },
  {
    "city": "Cap Skiring",
    "country": "Senegal",
    "IATA": "CSK",
    "icao_code": "GOGS"
  },
  {
    "city": "Cape Dorset",
    "country": "Canada",
    "IATA": "YTE",
    "icao_code": "CYTE"
  },
  {
    "city": "Cape Girardeau",
    "country": "United States",
    "IATA": "CGI",
    "icao_code": "KCGI"
  },
  {
    "city": "Cape Lisburne",
    "country": "United States",
    "IATA": "LUR",
    "icao_code": "PALU"
  },
  {
    "city": "Cape Newenham",
    "country": "United States",
    "IATA": "EHM",
    "icao_code": "PAEH"
  },
  {
    "city": "Cape Romanzof",
    "country": "United States",
    "IATA": "CZF",
    "icao_code": "PACZ"
  },
  {
    "city": "Cape Town",
    "country": "South Africa",
    "IATA": "CPT",
    "icao_code": "FACT"
  },
  {
    "city": "Capurgana",
    "country": "Colombia",
    "IATA": "CPB",
    "icao_code": "SKCA"
  },
  {
    "city": "Caracas",
    "country": "Venezuela",
    "IATA": "CCS",
    "icao_code": "SVMI"
  },
  {
    "city": "Caransebes",
    "country": "Romania",
    "IATA": "CSB",
    "icao_code": "LRCS"
  },
  {
    "city": "Carauari",
    "country": "Brazil",
    "IATA": "CAF",
    "icao_code": "SWCA"
  },
  {
    "city": "Caravelas",
    "country": "Brazil",
    "IATA": "CRQ",
    "icao_code": "SBCV"
  },
  {
    "city": "Carbondale/Murphysboro",
    "country": "United States",
    "IATA": "MDH",
    "icao_code": "KMDH"
  },
  {
    "city": "Carcassonne",
    "country": "France",
    "IATA": "CCF",
    "icao_code": "LFMK"
  },
  {
    "city": "Cardiff",
    "country": "United Kingdom",
    "IATA": "CWL",
    "icao_code": "EGFF"
  },
  {
    "city": "Carepa",
    "country": "Colombia",
    "IATA": "APO",
    "icao_code": "SKLC"
  },
  {
    "city": "Caribou",
    "country": "United States",
    "IATA": "CAR",
    "icao_code": "KCAR"
  },
  {
    "city": "Carlisle",
    "country": "United Kingdom",
    "IATA": "CAX",
    "icao_code": "EGNC"
  },
  {
    "city": "Carlsbad",
    "country": "United States",
    "IATA": "CLD",
    "icao_code": "KCRQ"
  },
  {
    "city": "Carlsbad",
    "country": "United States",
    "IATA": "CNM",
    "icao_code": "KCNM"
  },
  {
    "city": "Carnarvon",
    "country": "Australia",
    "IATA": "CVQ",
    "icao_code": "YCAR"
  },
  {
    "city": "Carnicobar",
    "country": "India",
    "IATA": "CBD"
  },
  {
    "city": "Carolina",
    "country": "Brazil",
    "IATA": "CLN",
    "icao_code": "SBCI"
  },
  {
    "city": "Carora",
    "country": "Venezuela",
    "IATA": "VCR",
    "icao_code": "SVCO"
  },
  {
    "city": "Carrillo",
    "country": "Costa Rica",
    "IATA": "PLD",
    "icao_code": "MRSR"
  },
  {
    "city": "Cartagena",
    "country": "Colombia",
    "IATA": "CTG",
    "icao_code": "SKCG"
  },
  {
    "city": "Cartago",
    "country": "Colombia",
    "IATA": "CRC",
    "icao_code": "SKGO"
  },
  {
    "city": "Cartwright",
    "country": "Canada",
    "IATA": "YRF",
    "icao_code": "CYCA"
  },
  {
    "city": "Caruaru",
    "country": "Brazil",
    "IATA": "CAU",
    "icao_code": "SNRU"
  },
  {
    "city": "Carupano",
    "country": "Venezuela",
    "IATA": "CUP",
    "icao_code": "SVCP"
  },
  {
    "city": "Casa Grande",
    "country": "United States",
    "IATA": "CGZ",
    "icao_code": "KCGZ"
  },
  {
    "city": "Casablanca",
    "country": "Morocco",
    "IATA": "CMN",
    "icao_code": "GMMN"
  },
  {
    "city": "Cascais",
    "country": "Portugal",
    "IATA": "CAT"
  },
  {
    "city": "Cascavel",
    "country": "Brazil",
    "IATA": "CAC",
    "icao_code": "SBCA"
  },
  {
    "city": "Casper",
    "country": "United States",
    "IATA": "CPR",
    "icao_code": "KCPR"
  },
  {
    "city": "Castellón de la Plana",
    "country": "Spain",
    "IATA": "CDT",
    "icao_code": "LECN"
  },
  {
    "city": "Castlegar",
    "country": "Canada",
    "IATA": "YCG",
    "icao_code": "CYCG"
  },
  {
    "city": "Castres",
    "country": "France",
    "IATA": "DCM",
    "icao_code": "LFCK"
  },
  {
    "city": "Castries",
    "country": "Saint Lucia",
    "IATA": "SLU",
    "icao_code": "TLPC"
  },
  {
    "city": "Castro",
    "country": "Chile",
    "IATA": "MHC",
    "icao_code": "SCPQ"
  },
  {
    "city": "Cat Island",
    "country": "Bahamas",
    "IATA": "TBI",
    "icao_code": "MYCB"
  },
  {
    "city": "Cat Lake",
    "country": "Canada",
    "IATA": "YAC",
    "icao_code": "CYAC"
  },
  {
    "city": "Catalina Island",
    "country": "United States",
    "IATA": "AVX",
    "icao_code": "KAVX"
  },
  {
    "city": "Catamarca",
    "country": "Argentina",
    "IATA": "CTC",
    "icao_code": "SANC"
  },
  {
    "city": "Catania",
    "country": "Italy",
    "IATA": "CTA",
    "icao_code": "LICC"
  },
  {
    "city": "Catarman",
    "country": "Philippines",
    "IATA": "CRM",
    "icao_code": "RPVF"
  },
  {
    "city": "Caticlan",
    "country": "Philippines",
    "IATA": "MPH",
    "icao_code": "RPWY"
  },
  {
    "city": "Catumbela",
    "country": "Angola",
    "IATA": "CBT",
    "icao_code": "FNCM"
  },
  {
    "city": "Cauayan",
    "country": "Philippines",
    "IATA": "CYZ",
    "icao_code": "RPUY"
  },
  {
    "city": "Caucasia",
    "country": "Colombia",
    "IATA": "CAQ",
    "icao_code": "SKCU"
  },
  {
    "city": "Caxias Do Sul",
    "country": "Brazil",
    "IATA": "CXJ",
    "icao_code": "SBCX"
  },
  {
    "city": "Cayenne",
    "country": "French Guiana",
    "IATA": "CAY",
    "icao_code": "SOCA"
  },
  {
    "city": "Cayes",
    "country": "Haiti",
    "IATA": "CYA"
  },
  {
    "city": "Cayman Brac",
    "country": "Cayman Islands",
    "IATA": "CYB",
    "icao_code": "MWCB"
  },
  {
    "city": "Cayo Largo del Sur",
    "country": "Cuba",
    "IATA": "CYO",
    "icao_code": "MUCL"
  },
  {
    "city": "Cayo Santa Maria",
    "country": "Cuba",
    "IATA": "BWW",
    "icao_code": "MUBR"
  },
  {
    "city": "Cebu",
    "country": "Philippines",
    "IATA": "CEB",
    "icao_code": "RPMC"
  },
  {
    "city": "Cedar City",
    "country": "United States",
    "IATA": "CDC",
    "icao_code": "KCDC"
  },
  {
    "city": "Cedar Rapids",
    "country": "United States",
    "IATA": "CID",
    "icao_code": "KCID"
  },
  {
    "city": "Ceduna",
    "country": "Australia",
    "IATA": "CED",
    "icao_code": "YCDU"
  },
  {
    "city": "Ceiba",
    "country": "Puerto Rico",
    "IATA": "NRR",
    "icao_code": "TJNR"
  },
  {
    "city": "Celaya",
    "country": "Mexico",
    "IATA": "CYW",
    "icao_code": "MMCY"
  },
  {
    "city": "Celle",
    "country": "Germany",
    "IATA": "ZCN"
  },
  {
    "city": "Central",
    "country": "United States",
    "IATA": "CEM",
    "icao_code": "PACE"
  },
  {
    "city": "Centralia",
    "country": "Canada",
    "IATA": "YCE",
    "icao_code": "CYCE"
  },
  {
    "city": "Cessnock",
    "country": "Australia",
    "IATA": "CES",
    "icao_code": "YCNK"
  },
  {
    "city": "Chacarita",
    "country": "Costa Rica",
    "IATA": "JAP"
  },
  {
    "city": "Chachapoyas",
    "country": "Peru",
    "IATA": "CHH",
    "icao_code": "SPPY"
  },
  {
    "city": "Chadron",
    "country": "United States",
    "IATA": "CDR",
    "icao_code": "KCDR"
  },
  {
    "city": "Chaghcharan",
    "country": "Afghanistan",
    "IATA": "CCN",
    "icao_code": "OACC"
  },
  {
    "city": "Chah Bahar",
    "country": "Iran",
    "IATA": "ZBR",
    "icao_code": "OIZC"
  },
  {
    "city": "Chaiten",
    "country": "Chile",
    "IATA": "WCH",
    "icao_code": "SCTN"
  },
  {
    "city": "Chalkyitsik",
    "country": "United States",
    "IATA": "CIK",
    "icao_code": "PACI"
  },
  {
    "city": "Chalons",
    "country": "France",
    "IATA": "XCR",
    "icao_code": "LFOK"
  },
  {
    "city": "Chambery",
    "country": "France",
    "IATA": "CMF",
    "icao_code": "LFLB"
  },
  {
    "city": "Champaign",
    "country": "United States",
    "IATA": "CMI",
    "icao_code": "KCMI"
  },
  {
    "city": "Chandigarh",
    "country": "India",
    "IATA": "IXC",
    "icao_code": "VICG"
  },
  {
    "city": "Chandragarhi",
    "country": "Nepal",
    "IATA": "BDP",
    "icao_code": "VNCG"
  },
  {
    "city": "Changcha",
    "country": "China",
    "IATA": "CSX",
    "icao_code": "ZGHA"
  },
  {
    "city": "Changchun",
    "country": "China",
    "IATA": "CGQ",
    "icao_code": "ZYCC"
  },
  {
    "city": "Changde",
    "country": "China",
    "IATA": "CGD",
    "icao_code": "ZGCD"
  },
  {
    "city": "Changhai",
    "country": "China",
    "IATA": "CNI",
    "icao_code": "KCNI"
  },
  {
    "city": "Changuinola",
    "country": "Panama",
    "IATA": "CHX",
    "icao_code": "MPCH"
  },
  {
    "city": "Changzhi",
    "country": "China",
    "IATA": "CIH",
    "icao_code": "ZBCZ"
  },
  {
    "city": "Changzhou",
    "country": "China",
    "IATA": "CZX",
    "icao_code": "ZSCG"
  },
  {
    "city": "Chania",
    "country": "Greece",
    "IATA": "CHQ",
    "icao_code": "LGSA"
  },
  {
    "city": "Chanute",
    "country": "United States",
    "IATA": "CNU",
    "icao_code": "KCNU"
  },
  {
    "city": "Chaoyang",
    "country": "China",
    "IATA": "CHG",
    "icao_code": "ZYCY"
  },
  {
    "city": "Chapacura",
    "country": "Bolivia",
    "IATA": "CCA"
  },
  {
    "city": "Chapeco",
    "country": "Brazil",
    "IATA": "XAP",
    "icao_code": "SBCH"
  },
  {
    "city": "Chapleau",
    "country": "Canada",
    "IATA": "YLD",
    "icao_code": "CYLD"
  },
  {
    "city": "Chardzhou",
    "country": "Turkmenistan",
    "IATA": "CRZ"
  },
  {
    "city": "Charleroi",
    "country": "Belgium",
    "IATA": "CRL",
    "icao_code": "EBCI"
  },
  {
    "city": "Charles City",
    "country": "United States",
    "IATA": "CCY",
    "icao_code": "KCCY"
  },
  {
    "city": "Charleston",
    "country": "United States",
    "IATA": "CRW",
    "icao_code": "KCRW"
  },
  {
    "city": "Charleston",
    "country": "United States",
    "IATA": "CHS",
    "icao_code": "KCHS"
  },
  {
    "city": "Charlestown",
    "country": "Saint Kitts and Nevis",
    "IATA": "NEV",
    "icao_code": "TKPN"
  },
  {
    "city": "Charleville",
    "country": "France",
    "IATA": "XCZ"
  },
  {
    "city": "Charlevoix",
    "country": "Canada",
    "IATA": "YML",
    "icao_code": "CYML"
  },
  {
    "city": "Charlieville",
    "country": "Australia",
    "IATA": "CTL",
    "icao_code": "YBCV"
  },
  {
    "city": "Charlo",
    "country": "Canada",
    "IATA": "YCL",
    "icao_code": "CYCL"
  },
  {
    "city": "Charlotte",
    "country": "United States",
    "IATA": "CLT",
    "icao_code": "KCLT"
  },
  {
    "city": "Charlotte Amalie",
    "country": "Virgin Islands",
    "IATA": "SPB"
  },
  {
    "city": "Charlottesville VA",
    "country": "United States",
    "IATA": "CHO",
    "icao_code": "KCHO"
  },
  {
    "city": "Charlottetown",
    "country": "Canada",
    "IATA": "YYG",
    "icao_code": "CYYG"
  },
  {
    "city": "Chateauroux",
    "country": "France",
    "IATA": "CHR",
    "icao_code": "LFLX"
  },
  {
    "city": "Chatham",
    "country": "Canada",
    "IATA": "YCH",
    "icao_code": "CYCH"
  },
  {
    "city": "Chatham Island",
    "country": "New Zealand",
    "IATA": "CHT",
    "icao_code": "NZCI"
  },
  {
    "city": "Chatsworth",
    "country": "Australia",
    "IATA": "CWT",
    "icao_code": "YCWR"
  },
  {
    "city": "Chattanooga",
    "country": "United States",
    "IATA": "CHA",
    "icao_code": "KCHA"
  },
  {
    "city": "Chañaral",
    "country": "Chile",
    "IATA": "CNR",
    "icao_code": "SCRA"
  },
  {
    "city": "Cheboksary",
    "country": "Russia",
    "IATA": "CSY",
    "icao_code": "UWKS"
  },
  {
    "city": "Chefornak",
    "country": "United States",
    "IATA": "CYF",
    "icao_code": "PACK"
  },
  {
    "city": "Chehalis",
    "country": "United States",
    "IATA": "CLS"
  },
  {
    "city": "Cheju",
    "country": "South Korea",
    "IATA": "CJU",
    "icao_code": "RKPC"
  },
  {
    "city": "Chelyabinsk",
    "country": "Russia",
    "IATA": "CEK",
    "icao_code": "USCC"
  },
  {
    "city": "Chenega",
    "country": "United States",
    "IATA": "NCN",
    "icao_code": "PFCB"
  },
  {
    "city": "Chengde",
    "country": "China",
    "IATA": "CDE"
  },
  {
    "city": "Chengdu",
    "country": "China",
    "IATA": "CTU",
    "icao_code": "ZUUU"
  },
  {
    "city": "Cheraw",
    "country": "United States",
    "IATA": "HCW",
    "icao_code": "KCQW"
  },
  {
    "city": "Cherbourg",
    "country": "France",
    "IATA": "CER",
    "icao_code": "LFRC"
  },
  {
    "city": "Cherepovets",
    "country": "Russia",
    "IATA": "CEE",
    "icao_code": "ULBC"
  },
  {
    "city": "Cherkassy",
    "country": "Ukraine",
    "IATA": "CKC",
    "icao_code": "UKKE"
  },
  {
    "city": "Chernigov",
    "country": "Ukraine",
    "IATA": "CEJ"
  },
  {
    "city": "Chernovtsk",
    "country": "Ukraine",
    "IATA": "CWC",
    "icao_code": "UKLN"
  },
  {
    "city": "Cherskiy",
    "country": "Russia",
    "IATA": "CYX",
    "icao_code": "UESS"
  },
  {
    "city": "Chesterfield Inlet",
    "country": "Canada",
    "IATA": "YCS",
    "icao_code": "CYCS"
  },
  {
    "city": "Chetumal",
    "country": "Mexico",
    "IATA": "CTM",
    "icao_code": "MMCM"
  },
  {
    "city": "Chetwynd",
    "country": "Canada",
    "IATA": "YCQ",
    "icao_code": "CYCQ"
  },
  {
    "city": "Chevak",
    "country": "United States",
    "IATA": "VAK",
    "icao_code": "PAVA"
  },
  {
    "city": "Chevery",
    "country": "Canada",
    "IATA": "YHR",
    "icao_code": "CYHR"
  },
  {
    "city": "Cheyenne",
    "country": "United States",
    "IATA": "CYS",
    "icao_code": "KCYS"
  },
  {
    "city": "Chiang Mai",
    "country": "Thailand",
    "IATA": "CNX",
    "icao_code": "VTCC"
  },
  {
    "city": "Chiang Rai",
    "country": "Thailand",
    "IATA": "CEI",
    "icao_code": "VTCT"
  },
  {
    "city": "Chiayi",
    "country": "Taiwan",
    "IATA": "CYI",
    "icao_code": "RCKU"
  },
  {
    "city": "Chibougamau",
    "country": "Canada",
    "IATA": "YMT",
    "icao_code": "CYMT"
  },
  {
    "city": "Chicago, All Airports",
    "country": "United States",
    "IATA": "CGXALL"
  },
  {
    "city": "Chicago",
    "country": "United States",
    "IATA": "CGX"
  },
  {
    "city": "Chicago",
    "country": "United States",
    "IATA": "ORD",
    "icao_code": "KORD"
  },
  {
    "city": "Chicago",
    "country": "United States",
    "IATA": "UGN",
    "icao_code": "KUGN"
  },
  {
    "city": "Chicago",
    "country": "United States",
    "IATA": "MDW",
    "icao_code": "KMDW"
  },
  {
    "city": "Chicago-Wheeling",
    "country": "United States",
    "IATA": "PWK",
    "icao_code": "KPWK"
  },
  {
    "city": "Chichen Itza",
    "country": "Mexico",
    "IATA": "CZA",
    "icao_code": "MMCT"
  },
  {
    "city": "Chiclayo",
    "country": "Peru",
    "IATA": "CIX",
    "icao_code": "SPHI"
  },
  {
    "city": "Chico",
    "country": "United States",
    "IATA": "CIC",
    "icao_code": "KCIC"
  },
  {
    "city": "Chicopee Falls",
    "country": "United States",
    "IATA": "CEF",
    "icao_code": "KCEF"
  },
  {
    "city": "Chifeng",
    "country": "China",
    "IATA": "CIF",
    "icao_code": "ZBCF"
  },
  {
    "city": "Chihuahua",
    "country": "Mexico",
    "IATA": "CUU",
    "icao_code": "MMCU"
  },
  {
    "city": "Childress",
    "country": "United States",
    "IATA": "CDS",
    "icao_code": "KCDS"
  },
  {
    "city": "Chile Chico",
    "country": "Chile",
    "IATA": "CCH",
    "icao_code": "SCCC"
  },
  {
    "city": "Chillan",
    "country": "Chile",
    "IATA": "YAI",
    "icao_code": "SCCH"
  },
  {
    "city": "Chilliwack",
    "country": "Canada",
    "IATA": "YCW",
    "icao_code": "CYCW"
  },
  {
    "city": "Chimbote",
    "country": "Peru",
    "IATA": "CHM",
    "icao_code": "SPEO"
  },
  {
    "city": "Chimkent",
    "country": "Kazakhstan",
    "IATA": "CIT",
    "icao_code": "UAII"
  },
  {
    "city": "Chimoio",
    "country": "Mozambique",
    "IATA": "VPY",
    "icao_code": "FQCH"
  },
  {
    "city": "Chinchilla",
    "country": "Australia",
    "IATA": "CCL",
    "icao_code": "YCCA"
  },
  {
    "city": "Chinhae",
    "country": "South Korea",
    "IATA": "CHF",
    "icao_code": "RKPE"
  },
  {
    "city": "Chino",
    "country": "United States",
    "IATA": "CNO",
    "icao_code": "KCNO"
  },
  {
    "city": "Chios",
    "country": "Greece",
    "IATA": "JKH",
    "icao_code": "LGHI"
  },
  {
    "city": "Chipata",
    "country": "Zambia",
    "IATA": "CIP",
    "icao_code": "FLCP"
  },
  {
    "city": "Chiredzi",
    "country": "Zimbabwe",
    "IATA": "BFO",
    "icao_code": "FVCZ"
  },
  {
    "city": "Chisasibi",
    "country": "Canada",
    "IATA": "YKU",
    "icao_code": "CSU2"
  },
  {
    "city": "Chisinau",
    "country": "Moldova",
    "IATA": "KIV",
    "icao_code": "LUKK"
  },
  {
    "city": "Chita",
    "country": "Russia",
    "IATA": "HTA",
    "icao_code": "UIAA"
  },
  {
    "city": "Chitral",
    "country": "Pakistan",
    "IATA": "CJL",
    "icao_code": "OPCH"
  },
  {
    "city": "Chitré",
    "country": "Panama",
    "IATA": "CTD"
  },
  {
    "city": "Chittagong",
    "country": "Bangladesh",
    "IATA": "CGP",
    "icao_code": "VGEG"
  },
  {
    "city": "Chizhou",
    "country": "China",
    "IATA": "JUH"
  },
  {
    "city": "Choibalsan",
    "country": "Mongolia",
    "IATA": "COQ",
    "icao_code": "ZMCD"
  },
  {
    "city": "Choiseul Bay",
    "country": "Solomon Islands",
    "IATA": "CHY",
    "icao_code": "AGGC"
  },
  {
    "city": "Chokurdah",
    "country": "Russia",
    "IATA": "CKH",
    "icao_code": "UESO"
  },
  {
    "city": "Cholet",
    "country": "France",
    "IATA": "CET"
  },
  {
    "city": "Chongjin",
    "country": "North Korea",
    "IATA": "RGO"
  },
  {
    "city": "Chongju",
    "country": "South Korea",
    "IATA": "CJJ",
    "icao_code": "RKTU"
  },
  {
    "city": "Chongqing",
    "country": "China",
    "IATA": "CKG",
    "icao_code": "ZUCK"
  },
  {
    "city": "Chosmadal",
    "country": "Argentina",
    "IATA": "HOS"
  },
  {
    "city": "Christchurch",
    "country": "New Zealand",
    "IATA": "CHC",
    "icao_code": "NZCH"
  },
  {
    "city": "Christmas Island",
    "country": "Christmas Island",
    "IATA": "XCH",
    "icao_code": "YPXM"
  },
  {
    "city": "Chu Lai",
    "country": "Vietnam",
    "IATA": "VCL"
  },
  {
    "city": "Chuathbaluk",
    "country": "United States",
    "IATA": "CHU",
    "icao_code": "PACH"
  },
  {
    "city": "Chub Cay",
    "country": "Bahamas",
    "IATA": "CCZ",
    "icao_code": "MYBC"
  },
  {
    "city": "Chumphon",
    "country": "Thailand",
    "IATA": "CJM",
    "icao_code": "VTSE"
  },
  {
    "city": "Churchill",
    "country": "Canada",
    "IATA": "YYQ",
    "icao_code": "CYYQ"
  },
  {
    "city": "Churchill Falls",
    "country": "Canada",
    "IATA": "ZUM",
    "icao_code": "CZUM"
  },
  {
    "city": "Chuuk",
    "country": "Micronesia",
    "IATA": "TKK",
    "icao_code": "PTKK"
  },
  {
    "city": "Cicia",
    "country": "Fiji",
    "IATA": "ICI",
    "icao_code": "NFCI"
  },
  {
    "city": "Ciego De Avila",
    "country": "Cuba",
    "IATA": "AVI",
    "icao_code": "MUCA"
  },
  {
    "city": "Cienfuegos",
    "country": "Cuba",
    "IATA": "CFG",
    "icao_code": "MUCF"
  },
  {
    "city": "Cilacap",
    "country": "Indonesia",
    "IATA": "CXP"
  },
  {
    "city": "Cimei",
    "country": "Taiwan",
    "IATA": "CMJ",
    "icao_code": "RCCM"
  },
  {
    "city": "Cincinnati",
    "country": "United States",
    "IATA": "LUK",
    "icao_code": "KLUK"
  },
  {
    "city": "Cincinnati",
    "country": "United States",
    "IATA": "CVG",
    "icao_code": "KCVG"
  },
  {
    "city": "Circle",
    "country": "United States",
    "IATA": "IRC",
    "icao_code": "SCIR"
  },
  {
    "city": "Cirebon",
    "country": "Indonesia",
    "IATA": "CBN",
    "icao_code": "WICD"
  },
  {
    "city": "Ciudad Acuna",
    "country": "Mexico",
    "IATA": "ACN",
    "icao_code": "MMCC"
  },
  {
    "city": "Ciudad Bolivar",
    "country": "Venezuela",
    "IATA": "CBL",
    "icao_code": "SVCB"
  },
  {
    "city": "Ciudad Constitución",
    "country": "Mexico",
    "IATA": "CUA",
    "icao_code": "MMDA"
  },
  {
    "city": "Ciudad Del Carmen",
    "country": "Mexico",
    "IATA": "CME",
    "icao_code": "MMCE"
  },
  {
    "city": "Ciudad Juarez",
    "country": "Mexico",
    "IATA": "CJS",
    "icao_code": "MMCS"
  },
  {
    "city": "Ciudad Obregon",
    "country": "Mexico",
    "IATA": "CEN",
    "icao_code": "MMCN"
  },
  {
    "city": "Ciudad Real",
    "country": "Spain",
    "IATA": "CQM",
    "icao_code": "LERL"
  },
  {
    "city": "Ciudad Victoria",
    "country": "Mexico",
    "IATA": "CVM",
    "icao_code": "MMCV"
  },
  {
    "city": "Ciudad del Este",
    "country": "Paraguay",
    "IATA": "AGT",
    "icao_code": "SGES"
  },
  {
    "city": "Cizre",
    "country": "Turkey",
    "IATA": "NKT",
    "icao_code": "LTCV"
  },
  {
    "city": "Clarence Bain",
    "country": "Bahamas",
    "IATA": "MAY"
  },
  {
    "city": "Claris",
    "country": "New Zealand",
    "IATA": "GBZ",
    "icao_code": "NZGB"
  },
  {
    "city": "Clarks Point",
    "country": "United States",
    "IATA": "CLP",
    "icao_code": "PFCL"
  },
  {
    "city": "Clarksburg",
    "country": "United States",
    "IATA": "CKB",
    "icao_code": "KCKB"
  },
  {
    "city": "Clarksville",
    "country": "United States",
    "IATA": "CKV",
    "icao_code": "KCKV"
  },
  {
    "city": "Clearwater",
    "country": "United States",
    "IATA": "CLW",
    "icao_code": "KCLW"
  },
  {
    "city": "Clemson",
    "country": "United States",
    "IATA": "CEU",
    "icao_code": "KCEU"
  },
  {
    "city": "Clermont",
    "country": "Australia",
    "IATA": "CMQ",
    "icao_code": "YCMT"
  },
  {
    "city": "Clermont-Ferrand",
    "country": "France",
    "IATA": "CFE",
    "icao_code": "LFLC"
  },
  {
    "city": "Cleveland",
    "country": "United States",
    "IATA": "HDI"
  },
  {
    "city": "Cleveland",
    "country": "United States",
    "IATA": "BKL",
    "icao_code": "KBKL"
  },
  {
    "city": "Cleveland",
    "country": "United States",
    "IATA": "CLE",
    "icao_code": "KCLE"
  },
  {
    "city": "Clinton",
    "country": "United States",
    "IATA": "CSM",
    "icao_code": "KCSM"
  },
  {
    "city": "Clinton",
    "country": "United States",
    "IATA": "CWI",
    "icao_code": "KCWI"
  },
  {
    "city": "Cloncurry",
    "country": "Australia",
    "IATA": "CNJ",
    "icao_code": "YCCY"
  },
  {
    "city": "Cloudbreak",
    "country": "Australia",
    "IATA": "KFE"
  },
  {
    "city": "Clovis",
    "country": "United States",
    "IATA": "CVN",
    "icao_code": "KCVN"
  },
  {
    "city": "Clovis",
    "country": "United States",
    "IATA": "CVS"
  },
  {
    "city": "Club Makokola",
    "country": "Malawi",
    "IATA": "CMK",
    "icao_code": "FWCM"
  },
  {
    "city": "Cluj-napoca",
    "country": "Romania",
    "IATA": "CLJ",
    "icao_code": "LRCL"
  },
  {
    "city": "Clyde River",
    "country": "Canada",
    "IATA": "YCY",
    "icao_code": "CYCY"
  },
  {
    "city": "Coari",
    "country": "Brazil",
    "IATA": "CIZ",
    "icao_code": "SWKO"
  },
  {
    "city": "Coatesville",
    "country": "United States",
    "IATA": "CTH",
    "icao_code": "KMQS"
  },
  {
    "city": "Coban",
    "country": "Guatemala",
    "IATA": "CBV",
    "icao_code": "MGCB"
  },
  {
    "city": "Cobar",
    "country": "Australia",
    "IATA": "CAZ",
    "icao_code": "YCBA"
  },
  {
    "city": "Cobija",
    "country": "Bolivia",
    "IATA": "CIJ",
    "icao_code": "SLCO"
  },
  {
    "city": "Coca",
    "country": "Ecuador",
    "IATA": "OCC",
    "icao_code": "SECO"
  },
  {
    "city": "Cochabamba",
    "country": "Bolivia",
    "IATA": "CBB",
    "icao_code": "SLCB"
  },
  {
    "city": "Cochrane",
    "country": "Canada",
    "IATA": "YCN",
    "icao_code": "CYCN"
  },
  {
    "city": "Cochstedt",
    "country": "Germany",
    "IATA": "CSO"
  },
  {
    "city": "Cockburn Town",
    "country": "Turks and Caicos Islands",
    "IATA": "GDT",
    "icao_code": "MBGT"
  },
  {
    "city": "Cockburn Town",
    "country": "Bahamas",
    "IATA": "ZSA",
    "icao_code": "MYSM"
  },
  {
    "city": "Coco Beach",
    "country": "United States",
    "IATA": "COF"
  },
  {
    "city": "Coconut Island",
    "country": "Australia",
    "IATA": "CNC",
    "icao_code": "YCCT"
  },
  {
    "city": "Cocos Keeling Island",
    "country": "Cocos (Keeling) Islands",
    "IATA": "CCK",
    "icao_code": "YPCC"
  },
  {
    "city": "Codrington",
    "country": "Antigua and Barbuda",
    "IATA": "BBQ"
  },
  {
    "city": "Cody",
    "country": "United States",
    "IATA": "COD",
    "icao_code": "KCOD"
  },
  {
    "city": "Coen",
    "country": "Australia",
    "IATA": "CUQ",
    "icao_code": "YCOE"
  },
  {
    "city": "Coeur d'Alene",
    "country": "United States",
    "IATA": "COE",
    "icao_code": "KCOE"
  },
  {
    "city": "Coff's Harbour",
    "country": "Australia",
    "IATA": "CFS",
    "icao_code": "YSCH"
  },
  {
    "city": "Coffeyville",
    "country": "United States",
    "IATA": "CFV",
    "icao_code": "KCFV"
  },
  {
    "city": "Cognac",
    "country": "France",
    "IATA": "CNG",
    "icao_code": "LFBG"
  },
  {
    "city": "Coimbatore",
    "country": "India",
    "IATA": "CJB",
    "icao_code": "VOCB"
  },
  {
    "city": "Cold Bay",
    "country": "United States",
    "IATA": "PML",
    "icao_code": "PAAL"
  },
  {
    "city": "Cold Bay",
    "country": "United States",
    "IATA": "CDB",
    "icao_code": "PACD"
  },
  {
    "city": "Cold Lake",
    "country": "Canada",
    "IATA": "YOD",
    "icao_code": "CEN5"
  },
  {
    "city": "Colima",
    "country": "Mexico",
    "IATA": "CLQ",
    "icao_code": "MMIA"
  },
  {
    "city": "College Station",
    "country": "United States",
    "IATA": "CLL",
    "icao_code": "KCLL"
  },
  {
    "city": "Colmar",
    "country": "France",
    "IATA": "CMR",
    "icao_code": "LFGA"
  },
  {
    "city": "Cologne",
    "country": "Germany",
    "IATA": "CGN",
    "icao_code": "EDDK"
  },
  {
    "city": "Colombo",
    "country": "Sri Lanka",
    "IATA": "RML",
    "icao_code": "VCCC"
  },
  {
    "city": "Colombo",
    "country": "Sri Lanka",
    "IATA": "CMB",
    "icao_code": "VCBI"
  },
  {
    "city": "Colombus",
    "country": "United States",
    "IATA": "CBM"
  },
  {
    "city": "Colonel Hill",
    "country": "Bahamas",
    "IATA": "CRI",
    "icao_code": "MYCI"
  },
  {
    "city": "Colonel Suarez",
    "country": "Argentina",
    "IATA": "CSZ"
  },
  {
    "city": "Colonia",
    "country": "Uruguay",
    "IATA": "CYR"
  },
  {
    "city": "Colonsay",
    "country": "United Kingdom",
    "IATA": "CSA",
    "icao_code": "EGEY"
  },
  {
    "city": "Colorado Springs",
    "country": "United States",
    "IATA": "COS",
    "icao_code": "KCOS"
  },
  {
    "city": "Columbia",
    "country": "United States",
    "IATA": "CUB",
    "icao_code": "KCUB"
  },
  {
    "city": "Columbia",
    "country": "United States",
    "IATA": "COU",
    "icao_code": "KCOU"
  },
  {
    "city": "Columbia",
    "country": "United States",
    "IATA": "CAE",
    "icao_code": "KCAE"
  },
  {
    "city": "Columbus",
    "country": "United States",
    "IATA": "CLU",
    "icao_code": "KBAK"
  },
  {
    "city": "Columbus",
    "country": "United States",
    "IATA": "TZR"
  },
  {
    "city": "Columbus",
    "country": "United States",
    "IATA": "OSU",
    "icao_code": "KOSU"
  },
  {
    "city": "Columbus",
    "country": "United States",
    "IATA": "CSG",
    "icao_code": "KCSG"
  },
  {
    "city": "Columbus",
    "country": "United States",
    "IATA": "CMH",
    "icao_code": "KCMH"
  },
  {
    "city": "Columbus",
    "country": "United States",
    "IATA": "LCK",
    "icao_code": "KLCK"
  },
  {
    "city": "Columbus Mississippi",
    "country": "United States",
    "IATA": "GTR",
    "icao_code": "KGTR"
  },
  {
    "city": "Colville Lake",
    "country": "Canada",
    "IATA": "YCK"
  },
  {
    "city": "Colón",
    "country": "Panama",
    "IATA": "ONX"
  },
  {
    "city": "Comayagua",
    "country": "Honduras",
    "IATA": "XPL"
  },
  {
    "city": "Comiso",
    "country": "Italy",
    "IATA": "CIY",
    "icao_code": "LICB"
  },
  {
    "city": "Comodoro Rivadavia",
    "country": "Argentina",
    "IATA": "CRD",
    "icao_code": "SAVC"
  },
  {
    "city": "Comox",
    "country": "Canada",
    "IATA": "YQQ",
    "icao_code": "CYQQ"
  },
  {
    "city": "Conakry",
    "country": "Guinea",
    "IATA": "CKY",
    "icao_code": "GUCY"
  },
  {
    "city": "Conceicao Do Araguaia",
    "country": "Brazil",
    "IATA": "CDJ",
    "icao_code": "SBAA"
  },
  {
    "city": "Concepcion",
    "country": "Chile",
    "IATA": "CCP",
    "icao_code": "SCIE"
  },
  {
    "city": "Conception",
    "country": "Paraguay",
    "IATA": "CIO",
    "icao_code": "SGCO"
  },
  {
    "city": "Concord",
    "country": "United States",
    "IATA": "CCR",
    "icao_code": "KCCR"
  },
  {
    "city": "Concord",
    "country": "United States",
    "IATA": "USA",
    "icao_code": "KJQF"
  },
  {
    "city": "Concord NH",
    "country": "United States",
    "IATA": "CON"
  },
  {
    "city": "Concordia",
    "country": "Brazil",
    "IATA": "CCI",
    "icao_code": "SSCK"
  },
  {
    "city": "Concordia",
    "country": "Argentina",
    "IATA": "COC",
    "icao_code": "SAAC"
  },
  {
    "city": "Condoto",
    "country": "Colombia",
    "IATA": "COG",
    "icao_code": "SKCD"
  },
  {
    "city": "Confresa",
    "country": "Brazil",
    "IATA": "CFO",
    "icao_code": "SJHG"
  },
  {
    "city": "Coningsby",
    "country": "United Kingdom",
    "IATA": "QCY"
  },
  {
    "city": "Connaught",
    "country": "Ireland",
    "IATA": "NOC",
    "icao_code": "EIKN"
  },
  {
    "city": "Conroe",
    "country": "United States",
    "IATA": "CXO",
    "icao_code": "KCXO"
  },
  {
    "city": "Conson",
    "country": "Vietnam",
    "IATA": "VCS",
    "icao_code": "VVCS"
  },
  {
    "city": "Constanta",
    "country": "Romania",
    "IATA": "CND",
    "icao_code": "LRCK"
  },
  {
    "city": "Constantine",
    "country": "Algeria",
    "IATA": "CZL",
    "icao_code": "DABC"
  },
  {
    "city": "Constanza",
    "country": "Dominican Republic",
    "IATA": "COZ"
  },
  {
    "city": "Coober Pedy",
    "country": "Australia",
    "IATA": "CPD",
    "icao_code": "YCBP"
  },
  {
    "city": "Cooch-behar",
    "country": "India",
    "IATA": "COH",
    "icao_code": "VECO"
  },
  {
    "city": "Cooinda",
    "country": "Australia",
    "IATA": "CDA",
    "icao_code": "YCOO"
  },
  {
    "city": "Cooktown",
    "country": "Australia",
    "IATA": "CTN",
    "icao_code": "YCKN"
  },
  {
    "city": "Coolangatta",
    "country": "Australia",
    "IATA": "OOL",
    "icao_code": "YBCG"
  },
  {
    "city": "Cooma",
    "country": "Australia",
    "IATA": "OOM",
    "icao_code": "YCOM"
  },
  {
    "city": "Coonabarabran",
    "country": "Australia",
    "IATA": "COJ",
    "icao_code": "YCBB"
  },
  {
    "city": "Coonamble",
    "country": "Australia",
    "IATA": "CNB",
    "icao_code": "YCNM"
  },
  {
    "city": "Coondewanna",
    "country": "Australia",
    "IATA": "CJF",
    "icao_code": "YCWA"
  },
  {
    "city": "Copenhagen",
    "country": "Denmark",
    "IATA": "RKE",
    "icao_code": "EKRK"
  },
  {
    "city": "Copenhagen",
    "country": "Denmark",
    "IATA": "CPH",
    "icao_code": "EKCH"
  },
  {
    "city": "Copiapo",
    "country": "Chile",
    "IATA": "CPO",
    "icao_code": "SCHA"
  },
  {
    "city": "Coppermine",
    "country": "Canada",
    "IATA": "YCO",
    "icao_code": "CYCO"
  },
  {
    "city": "Coral Harbour",
    "country": "Canada",
    "IATA": "YZS",
    "icao_code": "CYZS"
  },
  {
    "city": "Cordoba",
    "country": "Argentina",
    "IATA": "COR",
    "icao_code": "SACO"
  },
  {
    "city": "Cordoba",
    "country": "Spain",
    "IATA": "ODB",
    "icao_code": "LEBA"
  },
  {
    "city": "Cordova",
    "country": "United States",
    "IATA": "CDV",
    "icao_code": "PACV"
  },
  {
    "city": "Cork",
    "country": "Ireland",
    "IATA": "ORK",
    "icao_code": "EICK"
  },
  {
    "city": "Corn Island",
    "country": "Nicaragua",
    "IATA": "RNI",
    "icao_code": "MNCI"
  },
  {
    "city": "Cornwall",
    "country": "Canada",
    "IATA": "YCC",
    "icao_code": "CYCC"
  },
  {
    "city": "Coro",
    "country": "Venezuela",
    "IATA": "CZE",
    "icao_code": "SVCR"
  },
  {
    "city": "Coronation",
    "country": "Canada",
    "IATA": "YCT",
    "icao_code": "CYCT"
  },
  {
    "city": "Corozal",
    "country": "Colombia",
    "IATA": "CZU",
    "icao_code": "SKCZ"
  },
  {
    "city": "Corpus Christi",
    "country": "United States",
    "IATA": "CRP",
    "icao_code": "KCRP"
  },
  {
    "city": "Corrientes",
    "country": "Argentina",
    "IATA": "CNQ",
    "icao_code": "SARC"
  },
  {
    "city": "Cortez",
    "country": "United States",
    "IATA": "CEZ",
    "icao_code": "KCEZ"
  },
  {
    "city": "Corumba",
    "country": "Brazil",
    "IATA": "CMG",
    "icao_code": "SBCR"
  },
  {
    "city": "Corvallis",
    "country": "United States",
    "IATA": "CVO",
    "icao_code": "KCVO"
  },
  {
    "city": "Corvo",
    "country": "Portugal",
    "IATA": "CVU",
    "icao_code": "LPCR"
  },
  {
    "city": "Cotabato",
    "country": "Philippines",
    "IATA": "CBO",
    "icao_code": "RPWC"
  },
  {
    "city": "Coto 47",
    "country": "Costa Rica",
    "IATA": "OTR",
    "icao_code": "MRCC"
  },
  {
    "city": "Cotonou",
    "country": "Benin",
    "IATA": "COO",
    "icao_code": "DBBB"
  },
  {
    "city": "Cottbus",
    "country": "Germany",
    "IATA": "CBU"
  },
  {
    "city": "Cotulla",
    "country": "United States",
    "IATA": "COT",
    "icao_code": "KCOT"
  },
  {
    "city": "Council Bluffs",
    "country": "United States",
    "IATA": "CBF",
    "icao_code": "KCBF"
  },
  {
    "city": "Courcheval",
    "country": "France",
    "IATA": "CVF"
  },
  {
    "city": "Coventry",
    "country": "United Kingdom",
    "IATA": "CVT",
    "icao_code": "EGBE"
  },
  {
    "city": "Coveñas",
    "country": "Colombia",
    "IATA": "CVE"
  },
  {
    "city": "Cox's Bazar",
    "country": "Bangladesh",
    "IATA": "CXB",
    "icao_code": "VGCB"
  },
  {
    "city": "Coyhaique",
    "country": "Chile",
    "IATA": "GXQ",
    "icao_code": "SCCY"
  },
  {
    "city": "Cozumel",
    "country": "Mexico",
    "IATA": "CZM",
    "icao_code": "MMCZ"
  },
  {
    "city": "Craig Cove",
    "country": "Vanuatu",
    "IATA": "CCV",
    "icao_code": "NVSF"
  },
  {
    "city": "Craiova",
    "country": "Romania",
    "IATA": "CRA",
    "icao_code": "LRCV"
  },
  {
    "city": "Cranbrook",
    "country": "Canada",
    "IATA": "YXC",
    "icao_code": "CYXC"
  },
  {
    "city": "Creil",
    "country": "France",
    "IATA": "CSF"
  },
  {
    "city": "Crescent City",
    "country": "United States",
    "IATA": "CEC",
    "icao_code": "KCEC"
  },
  {
    "city": "Crestview",
    "country": "United States",
    "IATA": "EGI"
  },
  {
    "city": "Crestview",
    "country": "United States",
    "IATA": "CEW",
    "icao_code": "KCEW"
  },
  {
    "city": "Criciuma",
    "country": "Brazil",
    "IATA": "CCM",
    "icao_code": "SBCM"
  },
  {
    "city": "Croker Island",
    "country": "Australia",
    "IATA": "CKI",
    "icao_code": "YCKI"
  },
  {
    "city": "Cross City",
    "country": "United States",
    "IATA": "CTY",
    "icao_code": "KCTY"
  },
  {
    "city": "Cross Lake",
    "country": "Canada",
    "IATA": "YCR",
    "icao_code": "CYCR"
  },
  {
    "city": "Crossville",
    "country": "United States",
    "IATA": "CSV",
    "icao_code": "KCSV"
  },
  {
    "city": "Crotone",
    "country": "Italy",
    "IATA": "CRV",
    "icao_code": "LIBC"
  },
  {
    "city": "Cruzeiro do Sul",
    "country": "Brazil",
    "IATA": "CZS",
    "icao_code": "SBCZ"
  },
  {
    "city": "Cuamba",
    "country": "Mozambique",
    "IATA": "FXO",
    "icao_code": "FQCB"
  },
  {
    "city": "Cucuta",
    "country": "Colombia",
    "IATA": "CUC",
    "icao_code": "SKCC"
  },
  {
    "city": "Cuddapah",
    "country": "India",
    "IATA": "CDP",
    "icao_code": "VOCP"
  },
  {
    "city": "Cuenca",
    "country": "Ecuador",
    "IATA": "CUE",
    "icao_code": "SECU"
  },
  {
    "city": "Cuernavaca",
    "country": "Mexico",
    "IATA": "CVJ",
    "icao_code": "MMCB"
  },
  {
    "city": "Cuiaba",
    "country": "Brazil",
    "IATA": "CGB",
    "icao_code": "SBCY"
  },
  {
    "city": "Culebra Island",
    "country": "Puerto Rico",
    "IATA": "CPX",
    "icao_code": "TJCP"
  },
  {
    "city": "Culiacan",
    "country": "Mexico",
    "IATA": "CUL",
    "icao_code": "MMCL"
  },
  {
    "city": "Cumana",
    "country": "Venezuela",
    "IATA": "CUM",
    "icao_code": "SVCU"
  },
  {
    "city": "Cumberland",
    "country": "United States",
    "IATA": "CBE",
    "icao_code": "KCBE"
  },
  {
    "city": "Cunagua",
    "country": "Cuba",
    "IATA": "CCC",
    "icao_code": "MUCC"
  },
  {
    "city": "Cuneo",
    "country": "Italy",
    "IATA": "CUF",
    "icao_code": "LIMZ"
  },
  {
    "city": "Cunnamulla",
    "country": "Australia",
    "IATA": "CMA",
    "icao_code": "YCMU"
  },
  {
    "city": "Curitiba",
    "country": "Brazil",
    "IATA": "CWB",
    "icao_code": "SBCT"
  },
  {
    "city": "Curitiba",
    "country": "Brazil",
    "IATA": "BFH",
    "icao_code": "SBBI"
  },
  {
    "city": "Curuzu Cuatia",
    "country": "Argentina",
    "IATA": "UZU",
    "icao_code": "SATU"
  },
  {
    "city": "Cushing",
    "country": "United States",
    "IATA": "CUH",
    "icao_code": "KCUH"
  },
  {
    "city": "Cutbank",
    "country": "United States",
    "IATA": "CTB",
    "icao_code": "KCTB"
  },
  {
    "city": "Cutralco",
    "country": "Argentina",
    "IATA": "CUT",
    "icao_code": "SAZW"
  },
  {
    "city": "Cuyo",
    "country": "Philippines",
    "IATA": "CYU",
    "icao_code": "RPLO"
  },
  {
    "city": "Cuzco",
    "country": "Peru",
    "IATA": "CUZ",
    "icao_code": "SPZO"
  },
  {
    "city": "Cyclades Islands",
    "country": "Greece",
    "IATA": "JNX",
    "icao_code": "LGNX"
  },
  {
    "city": "DHARAVANDHOO",
    "country": "Maldives",
    "IATA": "DRV"
  },
  {
    "city": "Dabaa City",
    "country": "Egypt",
    "IATA": "DBB",
    "icao_code": "HEAL"
  },
  {
    "city": "Daet",
    "country": "Philippines",
    "IATA": "DTE",
    "icao_code": "RPUD"
  },
  {
    "city": "Daggett",
    "country": "United States",
    "IATA": "DAG",
    "icao_code": "KDAG"
  },
  {
    "city": "Dakar",
    "country": "Senegal",
    "IATA": "DKR",
    "icao_code": "GOOY"
  },
  {
    "city": "Dakhla",
    "country": "Western Sahara",
    "IATA": "VIL",
    "icao_code": "GMMH"
  },
  {
    "city": "Dalaman",
    "country": "Turkey",
    "IATA": "DLM",
    "icao_code": "LTBS"
  },
  {
    "city": "Dalanzadgad",
    "country": "Mongolia",
    "IATA": "DLZ",
    "icao_code": "ZMDZ"
  },
  {
    "city": "Dalat",
    "country": "Vietnam",
    "IATA": "DLI",
    "icao_code": "VVDL"
  },
  {
    "city": "Dalbandin",
    "country": "Pakistan",
    "IATA": "DBA",
    "icao_code": "OPDB"
  },
  {
    "city": "Dalhart",
    "country": "United States",
    "IATA": "DHT",
    "icao_code": "KDHT"
  },
  {
    "city": "Dali",
    "country": "China",
    "IATA": "DLU",
    "icao_code": "ZPDL"
  },
  {
    "city": "Dalian",
    "country": "China",
    "IATA": "DLC",
    "icao_code": "ZYTL"
  },
  {
    "city": "Dallas, All Airports",
    "country": "United States",
    "IATA": "DALALL"
  },
  {
    "city": "Dallas",
    "country": "United States",
    "IATA": "RBD",
    "icao_code": "KRBD"
  },
  {
    "city": "Dallas",
    "country": "United States",
    "IATA": "FWH"
  },
  {
    "city": "Dallas",
    "country": "United States",
    "IATA": "DAL",
    "icao_code": "KDAL"
  },
  {
    "city": "Dallas-Fort Worth",
    "country": "United States",
    "IATA": "DFW",
    "icao_code": "KDFW"
  },
  {
    "city": "Daloa",
    "country": "Cote d'Ivoire",
    "IATA": "DJO",
    "icao_code": "DIDL"
  },
  {
    "city": "Dalton",
    "country": "United States",
    "IATA": "DNN",
    "icao_code": "KDNN"
  },
  {
    "city": "Daman",
    "country": "India",
    "IATA": "NMB",
    "icao_code": "VADN"
  },
  {
    "city": "Damascus",
    "country": "Syria",
    "IATA": "DAM",
    "icao_code": "OSDI"
  },
  {
    "city": "Damazin",
    "country": "Sudan",
    "IATA": "RSS"
  },
  {
    "city": "Dammam",
    "country": "Saudi Arabia",
    "IATA": "DMM",
    "icao_code": "OEDF"
  },
  {
    "city": "Danang",
    "country": "Vietnam",
    "IATA": "DAD",
    "icao_code": "VVDN"
  },
  {
    "city": "Danbury",
    "country": "United States",
    "IATA": "DXR",
    "icao_code": "KDXR"
  },
  {
    "city": "Dandong",
    "country": "China",
    "IATA": "DDG",
    "icao_code": "ZYDD"
  },
  {
    "city": "Dang",
    "country": "Nepal",
    "IATA": "DNP",
    "icao_code": "VNDG"
  },
  {
    "city": "Danville",
    "country": "United States",
    "IATA": "DAN",
    "icao_code": "KDAN"
  },
  {
    "city": "Danville",
    "country": "United States",
    "IATA": "DNV",
    "icao_code": "KDNV"
  },
  {
    "city": "Daocheng",
    "country": "China",
    "IATA": "DCY"
  },
  {
    "city": "Daqing",
    "country": "China",
    "IATA": "DQA",
    "icao_code": "ZYDQ"
  },
  {
    "city": "Dar Es Salaam",
    "country": "Tanzania",
    "IATA": "DAR",
    "icao_code": "HTDA"
  },
  {
    "city": "Daran",
    "country": "Iran",
    "IATA": "IFH",
    "icao_code": "OIFE"
  },
  {
    "city": "Darnley Island",
    "country": "Australia",
    "IATA": "NLF",
    "icao_code": "YDNI"
  },
  {
    "city": "Daru",
    "country": "Papua New Guinea",
    "IATA": "DAU",
    "icao_code": "AYDU"
  },
  {
    "city": "Darwin",
    "country": "Australia",
    "IATA": "DRW",
    "icao_code": "YPDN"
  },
  {
    "city": "Dasht-e-naz",
    "country": "Iran",
    "IATA": "SRY",
    "icao_code": "OINS"
  },
  {
    "city": "Dasoguz",
    "country": "Turkmenistan",
    "IATA": "TAZ",
    "icao_code": "UTAT"
  },
  {
    "city": "Datadawai-Borneo Island",
    "country": "Indonesia",
    "IATA": "DTD",
    "icao_code": "WALJ"
  },
  {
    "city": "Datong",
    "country": "China",
    "IATA": "DAT",
    "icao_code": "ZBDT"
  },
  {
    "city": "Daugavpils",
    "country": "Latvia",
    "IATA": "DGP",
    "icao_code": "EVDA"
  },
  {
    "city": "Dauphin",
    "country": "Canada",
    "IATA": "YDN",
    "icao_code": "CYDN"
  },
  {
    "city": "Davao",
    "country": "Philippines",
    "IATA": "DVO",
    "icao_code": "RPMD"
  },
  {
    "city": "David",
    "country": "Panama",
    "IATA": "DAV",
    "icao_code": "MPDA"
  },
  {
    "city": "Dawei",
    "country": "Burma",
    "IATA": "TVY",
    "icao_code": "VBTV"
  },
  {
    "city": "Dawson",
    "country": "Canada",
    "IATA": "YDA",
    "icao_code": "CYDA"
  },
  {
    "city": "Dawson Creek",
    "country": "Canada",
    "IATA": "YDQ",
    "icao_code": "CYDQ"
  },
  {
    "city": "Dayong",
    "country": "China",
    "IATA": "DYG",
    "icao_code": "ZGDY"
  },
  {
    "city": "Dayton",
    "country": "United States",
    "IATA": "MGY",
    "icao_code": "KMGY"
  },
  {
    "city": "Dayton",
    "country": "United States",
    "IATA": "FFO"
  },
  {
    "city": "Dayton",
    "country": "United States",
    "IATA": "DAY",
    "icao_code": "KDAY"
  },
  {
    "city": "Daytona Beach",
    "country": "United States",
    "IATA": "DAB",
    "icao_code": "KDAB"
  },
  {
    "city": "Dazhou",
    "country": "China",
    "IATA": "DAX",
    "icao_code": "ZUDX"
  },
  {
    "city": "De Kooy",
    "country": "Netherlands",
    "IATA": "DHR",
    "icao_code": "EHKD"
  },
  {
    "city": "Dead Man's Cay",
    "country": "Bahamas",
    "IATA": "LGI",
    "icao_code": "MYLD"
  },
  {
    "city": "Deadhorse",
    "country": "United States",
    "IATA": "SCC",
    "icao_code": "PASC"
  },
  {
    "city": "Dease Lake",
    "country": "Canada",
    "IATA": "YDL",
    "icao_code": "CYDL"
  },
  {
    "city": "Deauville",
    "country": "France",
    "IATA": "DOL",
    "icao_code": "LFRG"
  },
  {
    "city": "Debre Marqos",
    "country": "Ethiopia",
    "IATA": "DBM",
    "icao_code": "HADM"
  },
  {
    "city": "Debre Tabor",
    "country": "Ethiopia",
    "IATA": "DBT",
    "icao_code": "HADT"
  },
  {
    "city": "Debre Zeyit",
    "country": "Ethiopia",
    "IATA": "QHR"
  },
  {
    "city": "Debrecen",
    "country": "Hungary",
    "IATA": "DEB",
    "icao_code": "LHDC"
  },
  {
    "city": "Decatur",
    "country": "United States",
    "IATA": "DCU",
    "icao_code": "KDCU"
  },
  {
    "city": "Decatur",
    "country": "United States",
    "IATA": "DEC",
    "icao_code": "KDEC"
  },
  {
    "city": "Decimomannu",
    "country": "Italy",
    "IATA": "DCI"
  },
  {
    "city": "Deer Lake",
    "country": "Canada",
    "IATA": "YVZ",
    "icao_code": "CYVZ"
  },
  {
    "city": "Deer Lake",
    "country": "Canada",
    "IATA": "YDF",
    "icao_code": "CYDF"
  },
  {
    "city": "Deering",
    "country": "United States",
    "IATA": "DRG",
    "icao_code": "PADE"
  },
  {
    "city": "Dehra Dun",
    "country": "India",
    "IATA": "DED",
    "icao_code": "VIDN"
  },
  {
    "city": "Deire Zor",
    "country": "Syria",
    "IATA": "DEZ",
    "icao_code": "OSDZ"
  },
  {
    "city": "Dekai",
    "country": "Indonesia",
    "IATA": "DEX",
    "icao_code": "WAVD"
  },
  {
    "city": "Del Bajio",
    "country": "Mexico",
    "IATA": "BJX",
    "icao_code": "MMLO"
  },
  {
    "city": "Del Rio",
    "country": "United States",
    "IATA": "DRT",
    "icao_code": "KDRT"
  },
  {
    "city": "Del Rio",
    "country": "United States",
    "IATA": "DLF"
  },
  {
    "city": "Delhi",
    "country": "India",
    "IATA": "DEL",
    "icao_code": "VIDP"
  },
  {
    "city": "Deline",
    "country": "Canada",
    "IATA": "YWJ",
    "icao_code": "CYWJ"
  },
  {
    "city": "Delta",
    "country": "United States",
    "IATA": "DTA",
    "icao_code": "KDTA"
  },
  {
    "city": "Delta Junction",
    "country": "United States",
    "IATA": "BIG",
    "icao_code": "PABI"
  },
  {
    "city": "Dembidollo",
    "country": "Ethiopia",
    "IATA": "DEM",
    "icao_code": "HADD"
  },
  {
    "city": "Deming",
    "country": "United States",
    "IATA": "DMN",
    "icao_code": "KDMN"
  },
  {
    "city": "Deniliquin",
    "country": "Australia",
    "IATA": "DNQ",
    "icao_code": "YDLQ"
  },
  {
    "city": "Denizli",
    "country": "Turkey",
    "IATA": "DNZ",
    "icao_code": "LTAY"
  },
  {
    "city": "Denpasar",
    "country": "Indonesia",
    "IATA": "DPS",
    "icao_code": "WADD"
  },
  {
    "city": "Denver",
    "country": "United States",
    "IATA": "APA",
    "icao_code": "KAPA"
  },
  {
    "city": "Denver",
    "country": "United States",
    "IATA": "DEN",
    "icao_code": "KDEN"
  },
  {
    "city": "Dera Ghazi Khan",
    "country": "Pakistan",
    "IATA": "DEA",
    "icao_code": "OPDG"
  },
  {
    "city": "Dera Ismael Khan",
    "country": "Pakistan",
    "IATA": "DSK",
    "icao_code": "OPDI"
  },
  {
    "city": "Derby",
    "country": "Australia",
    "IATA": "DCN",
    "icao_code": "YCIN"
  },
  {
    "city": "Derby",
    "country": "Australia",
    "IATA": "DRB",
    "icao_code": "YDBY"
  },
  {
    "city": "Deridder",
    "country": "United States",
    "IATA": "DRI",
    "icao_code": "KDRI"
  },
  {
    "city": "Des Moines",
    "country": "United States",
    "IATA": "DSM",
    "icao_code": "KDSM"
  },
  {
    "city": "Desroches",
    "country": "Seychelles",
    "IATA": "DES",
    "icao_code": "FSDR"
  },
  {
    "city": "Dessie",
    "country": "Ethiopia",
    "IATA": "DSE",
    "icao_code": "HADC"
  },
  {
    "city": "Destin",
    "country": "United States",
    "IATA": "DSI"
  },
  {
    "city": "Detroit",
    "country": "United States",
    "IATA": "YIP",
    "icao_code": "KYIP"
  },
  {
    "city": "Detroit",
    "country": "United States",
    "IATA": "DET",
    "icao_code": "KDET"
  },
  {
    "city": "Detroit",
    "country": "United States",
    "IATA": "DTW",
    "icao_code": "KDTW"
  },
  {
    "city": "Devils Lake",
    "country": "United States",
    "IATA": "DVL",
    "icao_code": "KDVL"
  },
  {
    "city": "Devonport",
    "country": "Australia",
    "IATA": "DPO",
    "icao_code": "YDPO"
  },
  {
    "city": "Dezful",
    "country": "Iran",
    "IATA": "DEF",
    "icao_code": "OIAD"
  },
  {
    "city": "Dhahran",
    "country": "Saudi Arabia",
    "IATA": "DHA",
    "icao_code": "OEDR"
  },
  {
    "city": "Dhaka",
    "country": "Bangladesh",
    "IATA": "DAC",
    "icao_code": "VGZR"
  },
  {
    "city": "Dhanbad",
    "country": "India",
    "IATA": "DBD",
    "icao_code": "VEDB"
  },
  {
    "city": "Dhangarhi",
    "country": "Nepal",
    "IATA": "DHI",
    "icao_code": "VNDH"
  },
  {
    "city": "Diamantina",
    "country": "Brazil",
    "IATA": "DTI",
    "icao_code": "SNDT"
  },
  {
    "city": "Diamantino",
    "country": "Brazil",
    "IATA": "DMT",
    "icao_code": "SWDM"
  },
  {
    "city": "Diass",
    "country": "Senegal",
    "IATA": "DSS",
    "icao_code": "GOBD"
  },
  {
    "city": "Dickinson",
    "country": "United States",
    "IATA": "DIK",
    "icao_code": "KDIK"
  },
  {
    "city": "Diego Garcia Island",
    "country": "British Indian Ocean Territory",
    "IATA": "NKW"
  },
  {
    "city": "Dienbienphu",
    "country": "Vietnam",
    "IATA": "DIN",
    "icao_code": "VVDB"
  },
  {
    "city": "Digby",
    "country": "Canada",
    "IATA": "YDG",
    "icao_code": "CYID"
  },
  {
    "city": "Dijon",
    "country": "France",
    "IATA": "DIJ",
    "icao_code": "LFSD"
  },
  {
    "city": "Dikson",
    "country": "Russia",
    "IATA": "DKS",
    "icao_code": "UODD"
  },
  {
    "city": "Dili",
    "country": "East Timor",
    "IATA": "DIL",
    "icao_code": "WPDL"
  },
  {
    "city": "Dillingham",
    "country": "United States",
    "IATA": "HDH"
  },
  {
    "city": "Dillingham",
    "country": "United States",
    "IATA": "DLG",
    "icao_code": "PADL"
  },
  {
    "city": "Dillon's Bay",
    "country": "Vanuatu",
    "IATA": "DLY",
    "icao_code": "NVVD"
  },
  {
    "city": "Dimapur",
    "country": "India",
    "IATA": "DMU",
    "icao_code": "VEMR"
  },
  {
    "city": "Dinard",
    "country": "France",
    "IATA": "DNR",
    "icao_code": "LFRD"
  },
  {
    "city": "Dipolog",
    "country": "Philippines",
    "IATA": "DPL",
    "icao_code": "RPMG"
  },
  {
    "city": "Dire Dawa",
    "country": "Ethiopia",
    "IATA": "DIR",
    "icao_code": "HADR"
  },
  {
    "city": "Diu",
    "country": "India",
    "IATA": "DIU",
    "icao_code": "VA1P"
  },
  {
    "city": "Divinopolis",
    "country": "Brazil",
    "IATA": "DIQ",
    "icao_code": "SNDV"
  },
  {
    "city": "Diyabakir",
    "country": "Turkey",
    "IATA": "DIY",
    "icao_code": "LTCC"
  },
  {
    "city": "Djanet",
    "country": "Algeria",
    "IATA": "DJG",
    "icao_code": "DAAJ"
  },
  {
    "city": "Djelfa",
    "country": "Algeria",
    "IATA": "QDJ"
  },
  {
    "city": "Djerba",
    "country": "Tunisia",
    "IATA": "DJE",
    "icao_code": "DTTJ"
  },
  {
    "city": "Djibouti",
    "country": "Djibouti",
    "IATA": "JIB",
    "icao_code": "HDAM"
  },
  {
    "city": "Dnepropetrovsk",
    "country": "Ukraine",
    "IATA": "DNK",
    "icao_code": "UKDD"
  },
  {
    "city": "Dobo",
    "country": "Indonesia",
    "IATA": "DOB",
    "icao_code": "WAPD"
  },
  {
    "city": "Docker River",
    "country": "Australia",
    "IATA": "DKV",
    "icao_code": "YDVR"
  },
  {
    "city": "Dodge City",
    "country": "United States",
    "IATA": "DDC",
    "icao_code": "KDDC"
  },
  {
    "city": "Dodoma",
    "country": "Tanzania",
    "IATA": "DOD",
    "icao_code": "HTDO"
  },
  {
    "city": "Doha",
    "country": "Qatar",
    "IATA": "DOH",
    "icao_code": "OTBD"
  },
  {
    "city": "Doha",
    "country": "Qatar",
    "IATA": "XJD",
    "icao_code": "OTBH"
  },
  {
    "city": "Doha",
    "country": "Qatar",
    "IATA": "DIA"
  },
  {
    "city": "Dolbeau-St-Félicien",
    "country": "Canada",
    "IATA": "YDO",
    "icao_code": "CYDO"
  },
  {
    "city": "Dole",
    "country": "France",
    "IATA": "DLE",
    "icao_code": "LFGJ"
  },
  {
    "city": "Dolpa",
    "country": "Nepal",
    "IATA": "DOP",
    "icao_code": "VNDP"
  },
  {
    "city": "Dominica",
    "country": "Dominica",
    "IATA": "DOM",
    "icao_code": "TDPD"
  },
  {
    "city": "Donaueschingen",
    "country": "Germany",
    "IATA": "ZQL"
  },
  {
    "city": "Donetsk",
    "country": "Ukraine",
    "IATA": "DOK",
    "icao_code": "UKCC"
  },
  {
    "city": "Dong Hoi",
    "country": "Vietnam",
    "IATA": "VDH"
  },
  {
    "city": "Dongloe",
    "country": "Ireland",
    "IATA": "CFN",
    "icao_code": "EIDL"
  },
  {
    "city": "Dongola",
    "country": "Sudan",
    "IATA": "DOG",
    "icao_code": "HSDN"
  },
  {
    "city": "Dongsheng",
    "country": "China",
    "IATA": "DSN",
    "icao_code": "ZBDS"
  },
  {
    "city": "Dongying",
    "country": "China",
    "IATA": "DOY",
    "icao_code": "ZSDY"
  },
  {
    "city": "Doomadgee",
    "country": "Australia",
    "IATA": "DMD",
    "icao_code": "YDMG"
  },
  {
    "city": "Doris Lake",
    "country": "Canada",
    "IATA": "JOJ"
  },
  {
    "city": "Dortmund",
    "country": "Germany",
    "IATA": "DTM",
    "icao_code": "EDLW"
  },
  {
    "city": "Dothan",
    "country": "United States",
    "IATA": "DHN",
    "icao_code": "KDHN"
  },
  {
    "city": "Douala",
    "country": "Cameroon",
    "IATA": "DLA",
    "icao_code": "FKKD"
  },
  {
    "city": "Douglas",
    "country": "United States",
    "IATA": "DGL",
    "icao_code": "KDGL"
  },
  {
    "city": "Douglas",
    "country": "United States",
    "IATA": "DUG",
    "icao_code": "KDUG"
  },
  {
    "city": "Dourados",
    "country": "Brazil",
    "IATA": "DOU",
    "icao_code": "SSDO"
  },
  {
    "city": "Dover",
    "country": "United States",
    "IATA": "DOV",
    "icao_code": "KDOV"
  },
  {
    "city": "Doylestown",
    "country": "United States",
    "IATA": "DYL",
    "icao_code": "KDYL"
  },
  {
    "city": "Dresden",
    "country": "Germany",
    "IATA": "DRS",
    "icao_code": "EDDC"
  },
  {
    "city": "Drietabbetje",
    "country": "Suriname",
    "IATA": "DRJ"
  },
  {
    "city": "Drummond Island",
    "country": "United States",
    "IATA": "DRE",
    "icao_code": "KDRM"
  },
  {
    "city": "Dryden",
    "country": "Canada",
    "IATA": "YHD",
    "icao_code": "CYHD"
  },
  {
    "city": "Du Bois",
    "country": "United States",
    "IATA": "DUJ",
    "icao_code": "KDUJ"
  },
  {
    "city": "Dubai",
    "country": "United Arab Emirates",
    "IATA": "DWC"
  },
  {
    "city": "Dubai",
    "country": "United Arab Emirates",
    "IATA": "DXB",
    "icao_code": "OMDB"
  },
  {
    "city": "Dubbo",
    "country": "Australia",
    "IATA": "DBO",
    "icao_code": "YSDU"
  },
  {
    "city": "Dublin",
    "country": "United States",
    "IATA": "DBN",
    "icao_code": "KDBN"
  },
  {
    "city": "Dublin",
    "country": "Ireland",
    "IATA": "DUB",
    "icao_code": "EIDW"
  },
  {
    "city": "Dubrovnik",
    "country": "Croatia",
    "IATA": "DBV",
    "icao_code": "LDDU"
  },
  {
    "city": "Dubuque IA",
    "country": "United States",
    "IATA": "DBQ",
    "icao_code": "KDBQ"
  },
  {
    "city": "Duesseldorf",
    "country": "Germany",
    "IATA": "DUS",
    "icao_code": "EDDL"
  },
  {
    "city": "Dulkaninna",
    "country": "Australia",
    "IATA": "DLK",
    "icao_code": "YDLK"
  },
  {
    "city": "Duluth",
    "country": "United States",
    "IATA": "DLH",
    "icao_code": "KDLH"
  },
  {
    "city": "Dumaguete",
    "country": "Philippines",
    "IATA": "DGT",
    "icao_code": "RPVD"
  },
  {
    "city": "Dumai",
    "country": "Indonesia",
    "IATA": "DUM",
    "icao_code": "WIBD"
  },
  {
    "city": "Duncan",
    "country": "United States",
    "IATA": "DUC",
    "icao_code": "KDUC"
  },
  {
    "city": "Duncan Town",
    "country": "Bahamas",
    "IATA": "DCT",
    "icao_code": "MYRD"
  },
  {
    "city": "Dundee",
    "country": "United Kingdom",
    "IATA": "DND",
    "icao_code": "EGPN"
  },
  {
    "city": "Dundo",
    "country": "Angola",
    "IATA": "DUE",
    "icao_code": "FNDU"
  },
  {
    "city": "Dunedin",
    "country": "New Zealand",
    "IATA": "DUD",
    "icao_code": "NZDN"
  },
  {
    "city": "Dunhuang",
    "country": "China",
    "IATA": "DNH",
    "icao_code": "ZLDH"
  },
  {
    "city": "Dunk Island",
    "country": "Australia",
    "IATA": "DKI",
    "icao_code": "YDKI"
  },
  {
    "city": "Dunkirk",
    "country": "United States",
    "IATA": "DKK",
    "icao_code": "KDKK"
  },
  {
    "city": "Dunnville",
    "country": "Canada",
    "IATA": "DU9"
  },
  {
    "city": "Duqm",
    "country": "Oman",
    "IATA": "DQM",
    "icao_code": "OODQ"
  },
  {
    "city": "Durango",
    "country": "United States",
    "IATA": "DRO",
    "icao_code": "KDRO"
  },
  {
    "city": "Durango",
    "country": "Mexico",
    "IATA": "DGO",
    "icao_code": "MMDO"
  },
  {
    "city": "Durazno",
    "country": "Uruguay",
    "IATA": "DZO",
    "icao_code": "SUDU"
  },
  {
    "city": "Durban",
    "country": "South Africa",
    "IATA": "VIR",
    "icao_code": "FAVG"
  },
  {
    "city": "Durban",
    "country": "South Africa",
    "IATA": "DUR",
    "icao_code": "FALE"
  },
  {
    "city": "Durgapur",
    "country": "India",
    "IATA": "RDP",
    "icao_code": "VEDG"
  },
  {
    "city": "Dushanbe",
    "country": "Tajikistan",
    "IATA": "DYU",
    "icao_code": "UTDD"
  },
  {
    "city": "Duxford",
    "country": "United Kingdom",
    "IATA": "QFO"
  },
  {
    "city": "Dwangwa",
    "country": "Malawi",
    "IATA": "DWA",
    "icao_code": "KDWA"
  },
  {
    "city": "Dzaoudzi",
    "country": "Mayotte",
    "IATA": "DZA",
    "icao_code": "FMCZ"
  },
  {
    "city": "Dzhambul",
    "country": "Kazakhstan",
    "IATA": "DMB",
    "icao_code": "UADD"
  },
  {
    "city": "ELLIOT LAKE",
    "country": "Canada",
    "IATA": "YEL",
    "icao_code": "CYEL"
  },
  {
    "city": "Eagle",
    "country": "United States",
    "IATA": "EAA",
    "icao_code": "PAEA"
  },
  {
    "city": "Eagle River",
    "country": "United States",
    "IATA": "EGV",
    "icao_code": "KEGV"
  },
  {
    "city": "Earlton",
    "country": "Canada",
    "IATA": "YXR",
    "icao_code": "CYXR"
  },
  {
    "city": "East London",
    "country": "South Africa",
    "IATA": "ELS",
    "icao_code": "FAEL"
  },
  {
    "city": "East Midlands",
    "country": "United Kingdom",
    "IATA": "EMA",
    "icao_code": "EGNX"
  },
  {
    "city": "East Tawas",
    "country": "United States",
    "IATA": "ECA"
  },
  {
    "city": "Easter Island",
    "country": "Chile",
    "IATA": "IPC",
    "icao_code": "SCIP"
  },
  {
    "city": "Eastmain River",
    "country": "Canada",
    "IATA": "ZEM",
    "icao_code": "CZEM"
  },
  {
    "city": "Easton",
    "country": "United States",
    "IATA": "ESN",
    "icao_code": "KESN"
  },
  {
    "city": "Eastover",
    "country": "United States",
    "IATA": "MMT"
  },
  {
    "city": "Eastsound",
    "country": "United States",
    "IATA": "ESD",
    "icao_code": "KORS"
  },
  {
    "city": "Eau Claire",
    "country": "United States",
    "IATA": "EAU",
    "icao_code": "KEAU"
  },
  {
    "city": "Ech-cheliff",
    "country": "Algeria",
    "IATA": "CFK",
    "icao_code": "DAOI"
  },
  {
    "city": "Eday",
    "country": "United Kingdom",
    "IATA": "EOI",
    "icao_code": "EGED"
  },
  {
    "city": "Eden Prairie",
    "country": "United States",
    "IATA": "FCM",
    "icao_code": "KFCM"
  },
  {
    "city": "Edinburgh",
    "country": "United Kingdom",
    "IATA": "EDI",
    "icao_code": "EGPH"
  },
  {
    "city": "Edmonton",
    "country": "Canada",
    "IATA": "YXD"
  },
  {
    "city": "Edmonton",
    "country": "Canada",
    "IATA": "YEG",
    "icao_code": "CYEG"
  },
  {
    "city": "Edson",
    "country": "Canada",
    "IATA": "YET",
    "icao_code": "CYET"
  },
  {
    "city": "Edwards Afb",
    "country": "United States",
    "IATA": "EDW"
  },
  {
    "city": "Eek",
    "country": "United States",
    "IATA": "EEK",
    "icao_code": "PAEE"
  },
  {
    "city": "Efogi",
    "country": "Papua New Guinea",
    "IATA": "EFG"
  },
  {
    "city": "Egegik",
    "country": "United States",
    "IATA": "EGX",
    "icao_code": "PAII"
  },
  {
    "city": "Egelsbach",
    "country": "Germany",
    "IATA": "QEF",
    "icao_code": "EDFE"
  },
  {
    "city": "Egilsstadir",
    "country": "Iceland",
    "IATA": "EGS",
    "icao_code": "BIEG"
  },
  {
    "city": "Eilat",
    "country": "Israel",
    "IATA": "ETM",
    "icao_code": "LLER"
  },
  {
    "city": "Eindhoven",
    "country": "Netherlands",
    "IATA": "EIN",
    "icao_code": "EHEH"
  },
  {
    "city": "Eirunepe",
    "country": "Brazil",
    "IATA": "ERN",
    "icao_code": "SWEI"
  },
  {
    "city": "Eisenach",
    "country": "Germany",
    "IATA": "EIB"
  },
  {
    "city": "Ejin Banner",
    "country": "China",
    "IATA": "EJN"
  },
  {
    "city": "Ekati",
    "country": "Canada",
    "IATA": "YOA"
  },
  {
    "city": "Ekibastuz",
    "country": "Kazakhstan",
    "IATA": "EKB",
    "icao_code": "UASB"
  },
  {
    "city": "El Aaiún",
    "country": "Western Sahara",
    "IATA": "EUN",
    "icao_code": "GMML"
  },
  {
    "city": "El Arish",
    "country": "Egypt",
    "IATA": "AAC",
    "icao_code": "HEAR"
  },
  {
    "city": "El Aroui",
    "country": "Morocco",
    "IATA": "NDR",
    "icao_code": "GMFN"
  },
  {
    "city": "El Bagre",
    "country": "Colombia",
    "IATA": "EBG"
  },
  {
    "city": "El Banco",
    "country": "Colombia",
    "IATA": "ELB",
    "icao_code": "SKBC"
  },
  {
    "city": "El Bayadh",
    "country": "Algeria",
    "IATA": "EBH",
    "icao_code": "DAOY"
  },
  {
    "city": "El Bolson",
    "country": "Argentina",
    "IATA": "EHL",
    "icao_code": "SAVB"
  },
  {
    "city": "El Borma",
    "country": "Tunisia",
    "IATA": "EBM",
    "icao_code": "DTTR"
  },
  {
    "city": "El Cajon",
    "country": "United States",
    "IATA": "SEE",
    "icao_code": "KSEE"
  },
  {
    "city": "El Calafate",
    "country": "Argentina",
    "IATA": "ING",
    "icao_code": "SAWA"
  },
  {
    "city": "El Calafate",
    "country": "Argentina",
    "IATA": "FTE",
    "icao_code": "SAWC"
  },
  {
    "city": "El Centro",
    "country": "United States",
    "IATA": "NJK",
    "icao_code": "KNJK"
  },
  {
    "city": "El Dorado",
    "country": "United States",
    "IATA": "ELD",
    "icao_code": "KELD"
  },
  {
    "city": "El Dorado",
    "country": "Venezuela",
    "IATA": "EOR",
    "icao_code": "SVED"
  },
  {
    "city": "El Dorado",
    "country": "Argentina",
    "IATA": "ELO",
    "icao_code": "SATD"
  },
  {
    "city": "El Fasher",
    "country": "Sudan",
    "IATA": "ELF",
    "icao_code": "HSFS"
  },
  {
    "city": "El Golea",
    "country": "Algeria",
    "IATA": "ELG",
    "icao_code": "DAUE"
  },
  {
    "city": "El Gorah",
    "country": "Egypt",
    "IATA": "EGH"
  },
  {
    "city": "El Monte",
    "country": "United States",
    "IATA": "EMT",
    "icao_code": "KEMT"
  },
  {
    "city": "El Obeid",
    "country": "Sudan",
    "IATA": "EBD",
    "icao_code": "HSOB"
  },
  {
    "city": "El Palomar",
    "country": "Argentina",
    "IATA": "EPA",
    "icao_code": "SADP"
  },
  {
    "city": "El Paso",
    "country": "United States",
    "IATA": "BIF",
    "icao_code": "KBIF"
  },
  {
    "city": "El Paso",
    "country": "United States",
    "IATA": "ELP",
    "icao_code": "KELP"
  },
  {
    "city": "El Salvador",
    "country": "Chile",
    "IATA": "ESR",
    "icao_code": "SCES"
  },
  {
    "city": "El Vigía",
    "country": "Venezuela",
    "IATA": "VIG",
    "icao_code": "SVVG"
  },
  {
    "city": "El-baha",
    "country": "Saudi Arabia",
    "IATA": "ABT",
    "icao_code": "OEBA"
  },
  {
    "city": "El-tor",
    "country": "Egypt",
    "IATA": "ELT",
    "icao_code": "HETR"
  },
  {
    "city": "Elat",
    "country": "Israel",
    "IATA": "ETH",
    "icao_code": "LLET"
  },
  {
    "city": "Elazig",
    "country": "Turkey",
    "IATA": "EZS",
    "icao_code": "LTCA"
  },
  {
    "city": "Elcho Island",
    "country": "Australia",
    "IATA": "ELC",
    "icao_code": "YELD"
  },
  {
    "city": "Eldoret",
    "country": "Kenya",
    "IATA": "EDL",
    "icao_code": "HKED"
  },
  {
    "city": "Elfin Cove",
    "country": "United States",
    "IATA": "ELV",
    "icao_code": "PAEL"
  },
  {
    "city": "Elim",
    "country": "United States",
    "IATA": "ELI",
    "icao_code": "PFEL"
  },
  {
    "city": "Elista",
    "country": "Russia",
    "IATA": "ESL",
    "icao_code": "URWI"
  },
  {
    "city": "Elizabeth City",
    "country": "United States",
    "IATA": "ECG",
    "icao_code": "KECG"
  },
  {
    "city": "Elkhart",
    "country": "United States",
    "IATA": "EKI"
  },
  {
    "city": "Elkins",
    "country": "United States",
    "IATA": "EKN",
    "icao_code": "KEKN"
  },
  {
    "city": "Elko",
    "country": "United States",
    "IATA": "EKO",
    "icao_code": "KEKO"
  },
  {
    "city": "Elmira",
    "country": "United States",
    "IATA": "ELM",
    "icao_code": "KELM"
  },
  {
    "city": "Elorza",
    "country": "Venezuela",
    "IATA": "EOZ"
  },
  {
    "city": "Ely",
    "country": "United States",
    "IATA": "ELY",
    "icao_code": "KELY"
  },
  {
    "city": "Ely",
    "country": "United States",
    "IATA": "LYU",
    "icao_code": "KELO"
  },
  {
    "city": "Emam Shahr",
    "country": "Iran",
    "IATA": "RUD",
    "icao_code": "OIMJ"
  },
  {
    "city": "Emden",
    "country": "Germany",
    "IATA": "EME"
  },
  {
    "city": "Emerald",
    "country": "Australia",
    "IATA": "EMD",
    "icao_code": "YEML"
  },
  {
    "city": "Emmen",
    "country": "Switzerland",
    "IATA": "EML"
  },
  {
    "city": "Emmonak",
    "country": "United States",
    "IATA": "EMK",
    "icao_code": "PAEM"
  },
  {
    "city": "Encarnacion",
    "country": "Paraguay",
    "IATA": "ENO",
    "icao_code": "SGEN"
  },
  {
    "city": "Ende",
    "country": "Indonesia",
    "IATA": "ENE",
    "icao_code": "WATE"
  },
  {
    "city": "Enfidha",
    "country": "Tunisia",
    "IATA": "NBE",
    "icao_code": "DTNZ"
  },
  {
    "city": "Enid",
    "country": "United States",
    "IATA": "END"
  },
  {
    "city": "Eniwetok Atoll",
    "country": "Marshall Islands",
    "IATA": "ENT"
  },
  {
    "city": "Enniskillen",
    "country": "United Kingdom",
    "IATA": "ENK",
    "icao_code": "EGAB"
  },
  {
    "city": "Enontekio",
    "country": "Finland",
    "IATA": "ENF",
    "icao_code": "EFET"
  },
  {
    "city": "Enschede",
    "country": "Netherlands",
    "IATA": "ENS",
    "icao_code": "EHTW"
  },
  {
    "city": "Ensenada",
    "country": "Mexico",
    "IATA": "ESE"
  },
  {
    "city": "Enshi",
    "country": "China",
    "IATA": "ENH",
    "icao_code": "ZHES"
  },
  {
    "city": "Entebbe",
    "country": "Uganda",
    "IATA": "EBB",
    "icao_code": "HUEN"
  },
  {
    "city": "Enterprise",
    "country": "United States",
    "IATA": "ETS",
    "icao_code": "KEDN"
  },
  {
    "city": "Enugu",
    "country": "Nigeria",
    "IATA": "ENU",
    "icao_code": "DNEN"
  },
  {
    "city": "Epinal",
    "country": "France",
    "IATA": "EPL",
    "icao_code": "LFSG"
  },
  {
    "city": "Eqalugaarsuit",
    "country": "Greenland",
    "IATA": "QFG"
  },
  {
    "city": "Er-rachidia",
    "country": "Morocco",
    "IATA": "ERH",
    "icao_code": "GMFK"
  },
  {
    "city": "Erbil",
    "country": "Iraq",
    "IATA": "EBL",
    "icao_code": "ORER"
  },
  {
    "city": "Erechim",
    "country": "Brazil",
    "IATA": "ERM",
    "icao_code": "SSER"
  },
  {
    "city": "Erenhot",
    "country": "China",
    "IATA": "ERL",
    "icao_code": "ZBER"
  },
  {
    "city": "Erfurt",
    "country": "Germany",
    "IATA": "ERF",
    "icao_code": "EDDE"
  },
  {
    "city": "Erie",
    "country": "United States",
    "IATA": "ERI",
    "icao_code": "KERI"
  },
  {
    "city": "Erldunda",
    "country": "Australia",
    "IATA": "EDD"
  },
  {
    "city": "Erzincan",
    "country": "Turkey",
    "IATA": "ERC",
    "icao_code": "LTCD"
  },
  {
    "city": "Erzurum",
    "country": "Turkey",
    "IATA": "ERZ",
    "icao_code": "LTCE"
  },
  {
    "city": "Esbjerg",
    "country": "Denmark",
    "IATA": "EBJ",
    "icao_code": "EKEB"
  },
  {
    "city": "Escanaba",
    "country": "United States",
    "IATA": "ESC",
    "icao_code": "KESC"
  },
  {
    "city": "Esfahan",
    "country": "Iran",
    "IATA": "IFN",
    "icao_code": "OIFM"
  },
  {
    "city": "Eskilstuna",
    "country": "Sweden",
    "IATA": "EKT",
    "icao_code": "ESSU"
  },
  {
    "city": "Eskimo Point",
    "country": "Canada",
    "IATA": "YEK",
    "icao_code": "CYEK"
  },
  {
    "city": "Eskisehir",
    "country": "Turkey",
    "IATA": "ESK",
    "icao_code": "LTBI"
  },
  {
    "city": "Eskissehir",
    "country": "Turkey",
    "IATA": "AOE",
    "icao_code": "LTBY"
  },
  {
    "city": "Esmeraldas",
    "country": "Ecuador",
    "IATA": "ESM",
    "icao_code": "SEES"
  },
  {
    "city": "Esperance",
    "country": "Australia",
    "IATA": "EPR",
    "icao_code": "YESP"
  },
  {
    "city": "Esquel",
    "country": "Argentina",
    "IATA": "EQS",
    "icao_code": "SAVE"
  },
  {
    "city": "Essadouira",
    "country": "Morocco",
    "IATA": "ESU",
    "icao_code": "GMMI"
  },
  {
    "city": "Essen",
    "country": "Germany",
    "IATA": "ESS",
    "icao_code": "EDLE"
  },
  {
    "city": "Estevan",
    "country": "Canada",
    "IATA": "YEN",
    "icao_code": "CYEN"
  },
  {
    "city": "Eua Island",
    "country": "Tonga",
    "IATA": "EUA",
    "icao_code": "NFTE"
  },
  {
    "city": "Eufala",
    "country": "United States",
    "IATA": "EUF"
  },
  {
    "city": "Eugene",
    "country": "United States",
    "IATA": "EUG",
    "icao_code": "KEUG"
  },
  {
    "city": "Eureka",
    "country": "United States",
    "IATA": "EKA",
    "icao_code": "KEKA"
  },
  {
    "city": "Eureka",
    "country": "Canada",
    "IATA": "YEU",
    "icao_code": "CYEU"
  },
  {
    "city": "Evanston",
    "country": "United States",
    "IATA": "EVW",
    "icao_code": "KEVW"
  },
  {
    "city": "Evansville",
    "country": "United States",
    "IATA": "EVV",
    "icao_code": "KEVV"
  },
  {
    "city": "Everett",
    "country": "United States",
    "IATA": "PAE",
    "icao_code": "KPAE"
  },
  {
    "city": "Evreux",
    "country": "France",
    "IATA": "EVX"
  },
  {
    "city": "Exeter",
    "country": "United Kingdom",
    "IATA": "EXT",
    "icao_code": "EGTE"
  },
  {
    "city": "Eyn-yahav",
    "country": "Israel",
    "IATA": "EIY",
    "icao_code": "LLEY"
  },
  {
    "city": "Eysk",
    "country": "Russia",
    "IATA": "EIK"
  },
  {
    "city": "Faaite",
    "country": "French Polynesia",
    "IATA": "FAC",
    "icao_code": "NTKF"
  },
  {
    "city": "Fagernes",
    "country": "Norway",
    "IATA": "VDB",
    "icao_code": "ENFG"
  },
  {
    "city": "Fair Isle",
    "country": "United Kingdom",
    "IATA": "FIE",
    "icao_code": "EGEF"
  },
  {
    "city": "Fairbanks",
    "country": "United States",
    "IATA": "FAI",
    "icao_code": "PAFA"
  },
  {
    "city": "Fairbanks",
    "country": "United States",
    "IATA": "EIL"
  },
  {
    "city": "Fairfield",
    "country": "United States",
    "IATA": "SUU",
    "icao_code": "KSUU"
  },
  {
    "city": "Fairford",
    "country": "United Kingdom",
    "IATA": "FFD"
  },
  {
    "city": "Faisalabad",
    "country": "Pakistan",
    "IATA": "LYP",
    "icao_code": "OPFA"
  },
  {
    "city": "Faizabad",
    "country": "Afghanistan",
    "IATA": "FBD",
    "icao_code": "OAFZ"
  },
  {
    "city": "Fajardo",
    "country": "Puerto Rico",
    "IATA": "FAJ",
    "icao_code": "TJFA"
  },
  {
    "city": "Fak Fak",
    "country": "Indonesia",
    "IATA": "FKQ",
    "icao_code": "WASF"
  },
  {
    "city": "Fakarava",
    "country": "French Polynesia",
    "IATA": "FAV",
    "icao_code": "NTGF"
  },
  {
    "city": "Faleolo",
    "country": "Samoa",
    "IATA": "APW",
    "icao_code": "NSFA"
  },
  {
    "city": "Fallon",
    "country": "United States",
    "IATA": "NFL",
    "icao_code": "KNFL"
  },
  {
    "city": "Falmouth",
    "country": "United States",
    "IATA": "FMH"
  },
  {
    "city": "False Pass",
    "country": "United States",
    "IATA": "KFP",
    "icao_code": "PAKF"
  },
  {
    "city": "Fangatau",
    "country": "French Polynesia",
    "IATA": "FGU",
    "icao_code": "NTGB"
  },
  {
    "city": "Farafangana",
    "country": "Madagascar",
    "IATA": "RVA",
    "icao_code": "FMSG"
  },
  {
    "city": "Farah",
    "country": "Afghanistan",
    "IATA": "FAH",
    "icao_code": "OAFR"
  },
  {
    "city": "Faranah",
    "country": "Guinea",
    "IATA": "FAA",
    "icao_code": "GUFH"
  },
  {
    "city": "Fargo",
    "country": "United States",
    "IATA": "FAR",
    "icao_code": "KFAR"
  },
  {
    "city": "Farmington",
    "country": "United States",
    "IATA": "FMN",
    "icao_code": "KFMN"
  },
  {
    "city": "Farnborough",
    "country": "United Kingdom",
    "IATA": "FAB",
    "icao_code": "EGLF"
  },
  {
    "city": "Faro",
    "country": "Portugal",
    "IATA": "FAO",
    "icao_code": "LPFR"
  },
  {
    "city": "Faro",
    "country": "Canada",
    "IATA": "ZFA",
    "icao_code": "CZFA"
  },
  {
    "city": "Farsund",
    "country": "Norway",
    "IATA": "FAN"
  },
  {
    "city": "Fasa",
    "country": "Iran",
    "IATA": "FAZ",
    "icao_code": "OISF"
  },
  {
    "city": "Faya-largeau",
    "country": "Chad",
    "IATA": "FYT",
    "icao_code": "FTTY"
  },
  {
    "city": "Fayetteville",
    "country": "United States",
    "IATA": "FAY",
    "icao_code": "KFAY"
  },
  {
    "city": "Fayetteville",
    "country": "United States",
    "IATA": "FYV",
    "icao_code": "KFYV"
  },
  {
    "city": "Fengnin",
    "country": "Taiwan",
    "IATA": "TTT",
    "icao_code": "RCQS"
  },
  {
    "city": "Fera Island",
    "country": "Solomon Islands",
    "IATA": "FRE",
    "icao_code": "AGGF"
  },
  {
    "city": "Fergana",
    "country": "Uzbekistan",
    "IATA": "FEG",
    "icao_code": "UTKF"
  },
  {
    "city": "Fernando Do Noronha",
    "country": "Brazil",
    "IATA": "FEN",
    "icao_code": "SBFN"
  },
  {
    "city": "Fes",
    "country": "Morocco",
    "IATA": "FEZ",
    "icao_code": "GMFF"
  },
  {
    "city": "Fianarantsoa",
    "country": "Madagascar",
    "IATA": "WFI",
    "icao_code": "FMSF"
  },
  {
    "city": "Ficksburg",
    "country": "South Africa",
    "IATA": "FCB"
  },
  {
    "city": "Figari",
    "country": "France",
    "IATA": "FSC",
    "icao_code": "LFKF"
  },
  {
    "city": "Findley",
    "country": "United States",
    "IATA": "FDY",
    "icao_code": "KFDY"
  },
  {
    "city": "Finke",
    "country": "Australia",
    "IATA": "FIK",
    "icao_code": "YFNE"
  },
  {
    "city": "Fira",
    "country": "Guinea",
    "IATA": "FIG",
    "icao_code": "GUFA"
  },
  {
    "city": "Fiti'uta",
    "country": "American Samoa",
    "IATA": "FTI",
    "icao_code": "NSFQ"
  },
  {
    "city": "Fitzroy Crossing",
    "country": "Australia",
    "IATA": "FIZ",
    "icao_code": "YFTZ"
  },
  {
    "city": "Flagstaff",
    "country": "United States",
    "IATA": "FLG",
    "icao_code": "KFLG"
  },
  {
    "city": "Flensburg",
    "country": "Germany",
    "IATA": "FLF",
    "icao_code": "EDXF"
  },
  {
    "city": "Flin Flon",
    "country": "Canada",
    "IATA": "YFO",
    "icao_code": "CYFO"
  },
  {
    "city": "Flinders Island",
    "country": "Australia",
    "IATA": "FLS",
    "icao_code": "YFLI"
  },
  {
    "city": "Flint",
    "country": "United States",
    "IATA": "FNT",
    "icao_code": "KFNT"
  },
  {
    "city": "Flippin",
    "country": "United States",
    "IATA": "FLP",
    "icao_code": "KFLP"
  },
  {
    "city": "Florence",
    "country": "United States",
    "IATA": "FLO",
    "icao_code": "KFLO"
  },
  {
    "city": "Florence",
    "country": "Italy",
    "IATA": "FLR",
    "icao_code": "LIRQ"
  },
  {
    "city": "Florencia",
    "country": "Colombia",
    "IATA": "FLA",
    "icao_code": "SKFL"
  },
  {
    "city": "Flores",
    "country": "Guatemala",
    "IATA": "FRS",
    "icao_code": "MGTK"
  },
  {
    "city": "Flores",
    "country": "Portugal",
    "IATA": "FLW",
    "icao_code": "LPFL"
  },
  {
    "city": "Floriano",
    "country": "Brazil",
    "IATA": "FLB",
    "icao_code": "SNQG"
  },
  {
    "city": "Florianopolis",
    "country": "Brazil",
    "IATA": "FLN",
    "icao_code": "SBFL"
  },
  {
    "city": "Floro",
    "country": "Norway",
    "IATA": "FRO",
    "icao_code": "ENFL"
  },
  {
    "city": "Foggia",
    "country": "Italy",
    "IATA": "FOG",
    "icao_code": "LIBF"
  },
  {
    "city": "Fond du Lac",
    "country": "United States",
    "IATA": "FLD",
    "icao_code": "KFLD"
  },
  {
    "city": "Fond-Du-Lac",
    "country": "Canada",
    "IATA": "ZFD",
    "icao_code": "CZFD"
  },
  {
    "city": "Fonte Boa",
    "country": "Brazil",
    "IATA": "FBA",
    "icao_code": "SWOB"
  },
  {
    "city": "Forbes",
    "country": "Australia",
    "IATA": "FRB",
    "icao_code": "YFBS"
  },
  {
    "city": "Forestville",
    "country": "Canada",
    "IATA": "YFE",
    "icao_code": "CYFE"
  },
  {
    "city": "Forli",
    "country": "Italy",
    "IATA": "FRL",
    "icao_code": "LIPK"
  },
  {
    "city": "Formosa",
    "country": "Argentina",
    "IATA": "FMA",
    "icao_code": "SARF"
  },
  {
    "city": "Forrest",
    "country": "Australia",
    "IATA": "FOS",
    "icao_code": "YPFT"
  },
  {
    "city": "Fort Albany",
    "country": "Canada",
    "IATA": "YFA",
    "icao_code": "CYFA"
  },
  {
    "city": "Fort Belvoir",
    "country": "United States",
    "IATA": "DAA"
  },
  {
    "city": "Fort Benning",
    "country": "United States",
    "IATA": "LSF",
    "icao_code": "KLSF"
  },
  {
    "city": "Fort Bragg",
    "country": "United States",
    "IATA": "POB"
  },
  {
    "city": "Fort Bridger",
    "country": "United States",
    "IATA": "FBR"
  },
  {
    "city": "Fort Carson",
    "country": "United States",
    "IATA": "FCS",
    "icao_code": "KFCS"
  },
  {
    "city": "Fort Chipewyan",
    "country": "Canada",
    "IATA": "YPY"
  },
  {
    "city": "Fort Collins",
    "country": "United States",
    "IATA": "FNL",
    "icao_code": "KFNL"
  },
  {
    "city": "Fort Dodge",
    "country": "United States",
    "IATA": "FOD",
    "icao_code": "KFOD"
  },
  {
    "city": "Fort Eustis",
    "country": "United States",
    "IATA": "FAF",
    "icao_code": "KFAF"
  },
  {
    "city": "Fort Frances",
    "country": "Canada",
    "IATA": "YAG",
    "icao_code": "CYAG"
  },
  {
    "city": "Fort Good Hope",
    "country": "Canada",
    "IATA": "YGH",
    "icao_code": "CYGH"
  },
  {
    "city": "Fort Hood",
    "country": "United States",
    "IATA": "HLR"
  },
  {
    "city": "Fort Hope",
    "country": "Canada",
    "IATA": "YFH",
    "icao_code": "CYFH"
  },
  {
    "city": "Fort Huachuca",
    "country": "United States",
    "IATA": "FHU",
    "icao_code": "KFHU"
  },
  {
    "city": "Fort Irwin",
    "country": "United States",
    "IATA": "BYS",
    "icao_code": "KBYS"
  },
  {
    "city": "Fort Knox",
    "country": "United States",
    "IATA": "FTK",
    "icao_code": "KFTK"
  },
  {
    "city": "South Florida, Fort Lauderdale",
    "country": "United States",
    "IATA": "FLL",
    "icao_code": "KFLL"
  },
  {
    "city": "Fort Lauderdale",
    "country": "United States",
    "IATA": "FXE",
    "icao_code": "KFXE"
  },
  {
    "city": "Fort Leavenworth",
    "country": "United States",
    "IATA": "FLV"
  },
  {
    "city": "Fort Leonardwood",
    "country": "United States",
    "IATA": "TBN",
    "icao_code": "KTBN"
  },
  {
    "city": "Fort Lewis",
    "country": "United States",
    "IATA": "GRF"
  },
  {
    "city": "Fort Liard",
    "country": "Canada",
    "IATA": "YJF",
    "icao_code": "CYJF"
  },
  {
    "city": "Fort Mcmurray",
    "country": "Canada",
    "IATA": "YMM",
    "icao_code": "CYMM"
  },
  {
    "city": "Fort Mcpherson",
    "country": "Canada",
    "IATA": "ZFM",
    "icao_code": "CZFM"
  },
  {
    "city": "Fort Meade",
    "country": "United States",
    "IATA": "FME",
    "icao_code": "KFME"
  },
  {
    "city": "Fort Myers",
    "country": "United States",
    "IATA": "RSW",
    "icao_code": "KRSW"
  },
  {
    "city": "Fort Myers",
    "country": "United States",
    "IATA": "FMY",
    "icao_code": "KFMY"
  },
  {
    "city": "Fort Nelson",
    "country": "Canada",
    "IATA": "YYE",
    "icao_code": "CYYE"
  },
  {
    "city": "Fort Ord",
    "country": "United States",
    "IATA": "OAR",
    "icao_code": "KOAR"
  },
  {
    "city": "Fort Pierce",
    "country": "United States",
    "IATA": "FPR",
    "icao_code": "KFPR"
  },
  {
    "city": "Fort Polk",
    "country": "United States",
    "IATA": "POE"
  },
  {
    "city": "Fort Resolution",
    "country": "Canada",
    "IATA": "YFR",
    "icao_code": "CYFR"
  },
  {
    "city": "Fort Richardson",
    "country": "United States",
    "IATA": "FRN"
  },
  {
    "city": "Fort Riley",
    "country": "United States",
    "IATA": "FRI"
  },
  {
    "city": "Fort Rucker/Ozark",
    "country": "United States",
    "IATA": "OZR"
  },
  {
    "city": "Fort Saint John",
    "country": "Canada",
    "IATA": "YXJ",
    "icao_code": "CYXJ"
  },
  {
    "city": "Fort Severn",
    "country": "Canada",
    "IATA": "YER",
    "icao_code": "CYER"
  },
  {
    "city": "Fort Sill",
    "country": "United States",
    "IATA": "FSI",
    "icao_code": "KFSI"
  },
  {
    "city": "Fort Simpson",
    "country": "Canada",
    "IATA": "YFS",
    "icao_code": "CYFS"
  },
  {
    "city": "Fort Smith",
    "country": "United States",
    "IATA": "FSM",
    "icao_code": "KFSM"
  },
  {
    "city": "Fort Smith",
    "country": "Canada",
    "IATA": "YSM",
    "icao_code": "CYSM"
  },
  {
    "city": "Fort Stockton",
    "country": "United States",
    "IATA": "FST",
    "icao_code": "KFST"
  },
  {
    "city": "Fort Wainwright",
    "country": "United States",
    "IATA": "FBK"
  },
  {
    "city": "Fort Wayne",
    "country": "United States",
    "IATA": "FWA",
    "icao_code": "KFWA"
  },
  {
    "city": "Fort Wayne IN",
    "country": "United States",
    "IATA": "SMD"
  },
  {
    "city": "Fort Worth",
    "country": "United States",
    "IATA": "AFW",
    "icao_code": "KAFW"
  },
  {
    "city": "Fort Worth",
    "country": "United States",
    "IATA": "FTW",
    "icao_code": "KFTW"
  },
  {
    "city": "Fort Yukon",
    "country": "United States",
    "IATA": "FYU",
    "icao_code": "PFYU"
  },
  {
    "city": "Fort-de-france",
    "country": "Martinique",
    "IATA": "FDF",
    "icao_code": "TFFF"
  },
  {
    "city": "Fortaleza",
    "country": "Brazil",
    "IATA": "FOR",
    "icao_code": "SBFZ"
  },
  {
    "city": "Foshan",
    "country": "China",
    "IATA": "FUO"
  },
  {
    "city": "Foumban",
    "country": "Cameroon",
    "IATA": "FOM",
    "icao_code": "FKKM"
  },
  {
    "city": "Foz Do Iguacu",
    "country": "Brazil",
    "IATA": "IGU",
    "icao_code": "SBFI"
  },
  {
    "city": "Franca",
    "country": "Brazil",
    "IATA": "FRC",
    "icao_code": "SIMK"
  },
  {
    "city": "Franceville",
    "country": "Gabon",
    "IATA": "MVB",
    "icao_code": "FOON"
  },
  {
    "city": "Francisco Beltrao",
    "country": "Brazil",
    "IATA": "FBE",
    "icao_code": "SSFB"
  },
  {
    "city": "Francistown",
    "country": "Botswana",
    "IATA": "FRW",
    "icao_code": "FBFT"
  },
  {
    "city": "Frankfort",
    "country": "United States",
    "IATA": "FFT",
    "icao_code": "KFFT"
  },
  {
    "city": "Frankfurt",
    "country": "Germany",
    "IATA": "FRA",
    "icao_code": "EDDF"
  },
  {
    "city": "Franklin",
    "country": "United States",
    "IATA": "FKL",
    "icao_code": "KFKL"
  },
  {
    "city": "Fraser Island",
    "country": "Australia",
    "IATA": "OCN",
    "icao_code": "KOKB"
  },
  {
    "city": "Fredericksburg",
    "country": "United States",
    "IATA": "FBG",
    "icao_code": "KFBG"
  },
  {
    "city": "Fredericton",
    "country": "Canada",
    "IATA": "YFC",
    "icao_code": "CYFC"
  },
  {
    "city": "Freeport",
    "country": "Bahamas",
    "IATA": "FPO",
    "icao_code": "MYGF"
  },
  {
    "city": "Freetown",
    "country": "Sierra Leone",
    "IATA": "HGS",
    "icao_code": "GFHA"
  },
  {
    "city": "Freetown",
    "country": "Sierra Leone",
    "IATA": "FNA",
    "icao_code": "GFLL"
  },
  {
    "city": "Frejus",
    "country": "France",
    "IATA": "FRJ"
  },
  {
    "city": "Fremont",
    "country": "United States",
    "IATA": "FET",
    "icao_code": "KFET"
  },
  {
    "city": "Frenchville",
    "country": "United States",
    "IATA": "WFK"
  },
  {
    "city": "Fresno",
    "country": "United States",
    "IATA": "FAT",
    "icao_code": "KFAT"
  },
  {
    "city": "Friday Harbor",
    "country": "United States",
    "IATA": "FRD",
    "icao_code": "KFHR"
  },
  {
    "city": "Friedrichshafen",
    "country": "Germany",
    "IATA": "FDH",
    "icao_code": "EDNY"
  },
  {
    "city": "Fritzlar",
    "country": "Germany",
    "IATA": "FRZ",
    "icao_code": "ETHF"
  },
  {
    "city": "Fryeburg",
    "country": "United States",
    "IATA": "FRY"
  },
  {
    "city": "Fuerstenfeldbruck",
    "country": "Germany",
    "IATA": "FEL",
    "icao_code": "ETSF"
  },
  {
    "city": "Fuerte Gral Roca",
    "country": "Argentina",
    "IATA": "GNR",
    "icao_code": "SAHR"
  },
  {
    "city": "Fuerteventura",
    "country": "Spain",
    "IATA": "FUE",
    "icao_code": "GCFV"
  },
  {
    "city": "Fujeirah",
    "country": "United Arab Emirates",
    "IATA": "FJR",
    "icao_code": "OMFJ"
  },
  {
    "city": "Fukue",
    "country": "Japan",
    "IATA": "FUJ",
    "icao_code": "RJFE"
  },
  {
    "city": "Fukui",
    "country": "Japan",
    "IATA": "FKJ",
    "icao_code": "RJNF"
  },
  {
    "city": "Fukuoka",
    "country": "Japan",
    "IATA": "FUK",
    "icao_code": "RJFF"
  },
  {
    "city": "Fukushima",
    "country": "Japan",
    "IATA": "FKS",
    "icao_code": "RJSF"
  },
  {
    "city": "Fullerton",
    "country": "United States",
    "IATA": "FUL",
    "icao_code": "KFUL"
  },
  {
    "city": "Funafuti",
    "country": "Tuvalu",
    "IATA": "FUN",
    "icao_code": "NGFU"
  },
  {
    "city": "Funchal",
    "country": "Portugal",
    "IATA": "FNC",
    "icao_code": "LPMA"
  },
  {
    "city": "Funter Bay",
    "country": "United States",
    "IATA": "FNR",
    "icao_code": "PANR"
  },
  {
    "city": "Futaleufu",
    "country": "Chile",
    "IATA": "FFU",
    "icao_code": "SCFT"
  },
  {
    "city": "Futuna Island",
    "country": "Vanuatu",
    "IATA": "FTA",
    "icao_code": "NVVF"
  },
  {
    "city": "Futuna Island",
    "country": "Wallis and Futuna",
    "IATA": "FUT",
    "icao_code": "NLWF"
  },
  {
    "city": "Fuyang",
    "country": "China",
    "IATA": "FUG",
    "icao_code": "ZSFY"
  },
  {
    "city": "Fuyuan",
    "country": "China",
    "IATA": "FYJ",
    "icao_code": "ZYFY"
  },
  {
    "city": "Fuyun",
    "country": "China",
    "IATA": "FYN",
    "icao_code": "ZWFY"
  },
  {
    "city": "Fuzhou",
    "country": "China",
    "IATA": "FOC",
    "icao_code": "ZSFZ"
  },
  {
    "city": "GAYLORD",
    "country": "United States",
    "IATA": "GLR",
    "icao_code": "KGLR"
  },
  {
    "city": "Gaberone",
    "country": "Botswana",
    "IATA": "GBE",
    "icao_code": "FBSK"
  },
  {
    "city": "Gabes",
    "country": "Tunisia",
    "IATA": "GAE"
  },
  {
    "city": "Gachsaran",
    "country": "Iran",
    "IATA": "GCH"
  },
  {
    "city": "Gadsden",
    "country": "United States",
    "IATA": "GAD",
    "icao_code": "KGAD"
  },
  {
    "city": "Gafsa",
    "country": "Tunisia",
    "IATA": "GAF",
    "icao_code": "DTTF"
  },
  {
    "city": "Gainesville",
    "country": "United States",
    "IATA": "GVL",
    "icao_code": "KGVL"
  },
  {
    "city": "Gainesville",
    "country": "United States",
    "IATA": "GNV",
    "icao_code": "KGNV"
  },
  {
    "city": "Gaithersburg",
    "country": "United States",
    "IATA": "GAI",
    "icao_code": "KGAI"
  },
  {
    "city": "Galapagos",
    "country": "Ecuador",
    "IATA": "GPS",
    "icao_code": "SEGS"
  },
  {
    "city": "Galcaio",
    "country": "Somalia",
    "IATA": "GLK",
    "icao_code": "HCMR"
  },
  {
    "city": "Galena",
    "country": "United States",
    "IATA": "GAL",
    "icao_code": "PAGA"
  },
  {
    "city": "Galesburg",
    "country": "United States",
    "IATA": "GBG",
    "icao_code": "KGBG"
  },
  {
    "city": "Galion",
    "country": "United States",
    "IATA": "GQQ",
    "icao_code": "KGQQ"
  },
  {
    "city": "Gallivare",
    "country": "Sweden",
    "IATA": "GEV",
    "icao_code": "ESNG"
  },
  {
    "city": "Gallup",
    "country": "United States",
    "IATA": "GUP",
    "icao_code": "KGUP"
  },
  {
    "city": "Galoya",
    "country": "Sri Lanka",
    "IATA": "ADP"
  },
  {
    "city": "Galveston",
    "country": "United States",
    "IATA": "GLS",
    "icao_code": "KGLS"
  },
  {
    "city": "Galway",
    "country": "Ireland",
    "IATA": "GWY",
    "icao_code": "EICM"
  },
  {
    "city": "Gambell",
    "country": "United States",
    "IATA": "GAM",
    "icao_code": "PAGM"
  },
  {
    "city": "Gambella",
    "country": "Ethiopia",
    "IATA": "GMB",
    "icao_code": "HAGM"
  },
  {
    "city": "Gamètì",
    "country": "Canada",
    "IATA": "YRA",
    "icao_code": "CEV4"
  },
  {
    "city": "Gan Island",
    "country": "Maldives",
    "IATA": "GAN",
    "icao_code": "VRMG"
  },
  {
    "city": "Gander",
    "country": "Canada",
    "IATA": "YQX",
    "icao_code": "CYQX"
  },
  {
    "city": "Ganges",
    "country": "Canada",
    "IATA": "YGG"
  },
  {
    "city": "Ganja",
    "country": "Azerbaijan",
    "IATA": "KVD",
    "icao_code": "UBBG"
  },
  {
    "city": "Ganzhou",
    "country": "China",
    "IATA": "KOW",
    "icao_code": "ZSGZ"
  },
  {
    "city": "Gao",
    "country": "Mali",
    "IATA": "GAQ",
    "icao_code": "GAGO"
  },
  {
    "city": "Garden City",
    "country": "United States",
    "IATA": "GCK",
    "icao_code": "KGCK"
  },
  {
    "city": "Garissa",
    "country": "Kenya",
    "IATA": "GAS",
    "icao_code": "HKGA"
  },
  {
    "city": "Garoua",
    "country": "Cameroon",
    "IATA": "GOU",
    "icao_code": "FKKR"
  },
  {
    "city": "Gary",
    "country": "United States",
    "IATA": "GYY",
    "icao_code": "KGYY"
  },
  {
    "city": "Gasan",
    "country": "Philippines",
    "IATA": "MRQ",
    "icao_code": "RPUW"
  },
  {
    "city": "Gaspe",
    "country": "Canada",
    "IATA": "YGP",
    "icao_code": "CYGP"
  },
  {
    "city": "Gassim",
    "country": "Saudi Arabia",
    "IATA": "ELQ",
    "icao_code": "OEGS"
  },
  {
    "city": "Gatineau",
    "country": "Canada",
    "IATA": "YND",
    "icao_code": "CYND"
  },
  {
    "city": "Gaua Island",
    "country": "Vanuatu",
    "IATA": "ZGU",
    "icao_code": "NVSQ"
  },
  {
    "city": "Gavle",
    "country": "Sweden",
    "IATA": "GVX",
    "icao_code": "ESSK"
  },
  {
    "city": "Gaya",
    "country": "India",
    "IATA": "GAY",
    "icao_code": "VEGY"
  },
  {
    "city": "Gayndah",
    "country": "Australia",
    "IATA": "GAH",
    "icao_code": "YGAY"
  },
  {
    "city": "Gaza",
    "country": "Palestine",
    "IATA": "GZA"
  },
  {
    "city": "Gaziantep",
    "country": "Turkey",
    "IATA": "GZT",
    "icao_code": "LTAJ"
  },
  {
    "city": "Gbadolite",
    "country": "Congo (Kinshasa)",
    "IATA": "BDT",
    "icao_code": "FZFD"
  },
  {
    "city": "Gbangbatok",
    "country": "Sierra Leone",
    "IATA": "GBK",
    "icao_code": "GFGK"
  },
  {
    "city": "Gdansk",
    "country": "Poland",
    "IATA": "GDN",
    "icao_code": "EPGD"
  },
  {
    "city": "Gdynia",
    "country": "Poland",
    "IATA": "QYD"
  },
  {
    "city": "Geelong",
    "country": "Australia",
    "IATA": "GEX",
    "icao_code": "YGLG"
  },
  {
    "city": "Geilenkirchen",
    "country": "Germany",
    "IATA": "GKE",
    "icao_code": "ETNG"
  },
  {
    "city": "Geilo",
    "country": "Norway",
    "IATA": "DLD"
  },
  {
    "city": "Geita",
    "country": "Tanzania",
    "IATA": "GIT"
  },
  {
    "city": "Gelendzhik",
    "country": "Russia",
    "IATA": "GDZ"
  },
  {
    "city": "Gelephu",
    "country": "Bhutan",
    "IATA": "GLU"
  },
  {
    "city": "Gemena",
    "country": "Congo (Kinshasa)",
    "IATA": "GMA",
    "icao_code": "FZFK"
  },
  {
    "city": "Geneina",
    "country": "Sudan",
    "IATA": "EGN",
    "icao_code": "HSGN"
  },
  {
    "city": "General Pico",
    "country": "Argentina",
    "IATA": "GPO",
    "icao_code": "SAZG"
  },
  {
    "city": "Geneva",
    "country": "Switzerland",
    "IATA": "GVA",
    "icao_code": "LSGG"
  },
  {
    "city": "Genoa",
    "country": "Italy",
    "IATA": "GOA",
    "icao_code": "LIMJ"
  },
  {
    "city": "George",
    "country": "South Africa",
    "IATA": "GRJ",
    "icao_code": "FAGG"
  },
  {
    "city": "Georgetown",
    "country": "United States",
    "IATA": "GGE",
    "icao_code": "KGGE"
  },
  {
    "city": "Georgetown",
    "country": "United States",
    "IATA": "GED",
    "icao_code": "KGED"
  },
  {
    "city": "Georgetown",
    "country": "Guyana",
    "IATA": "OGL"
  },
  {
    "city": "Georgetown",
    "country": "Guyana",
    "IATA": "GEO",
    "icao_code": "SYCJ"
  },
  {
    "city": "Georgetown",
    "country": "Cayman Islands",
    "IATA": "GCM",
    "icao_code": "MWCR"
  },
  {
    "city": "Geralds",
    "country": "Montserrat",
    "IATA": "MNI"
  },
  {
    "city": "Geraldton",
    "country": "Australia",
    "IATA": "GET",
    "icao_code": "YGEL"
  },
  {
    "city": "Geraldton",
    "country": "Canada",
    "IATA": "YGQ",
    "icao_code": "CYGQ"
  },
  {
    "city": "Gerona",
    "country": "Spain",
    "IATA": "GRO",
    "icao_code": "LEGE"
  },
  {
    "city": "Geçitkale",
    "country": "Cyprus",
    "IATA": "GEC"
  },
  {
    "city": "Ghadames",
    "country": "Libya",
    "IATA": "LTD",
    "icao_code": "HLTD"
  },
  {
    "city": "Ghanzi",
    "country": "Botswana",
    "IATA": "GNZ",
    "icao_code": "FBGZ"
  },
  {
    "city": "Ghardaia",
    "country": "Algeria",
    "IATA": "GHA",
    "icao_code": "DAUG"
  },
  {
    "city": "Ghat",
    "country": "Libya",
    "IATA": "GHT",
    "icao_code": "HLGT"
  },
  {
    "city": "Ghazni",
    "country": "Afghanistan",
    "IATA": "GZI"
  },
  {
    "city": "Ghazvin",
    "country": "Iran",
    "IATA": "GZW"
  },
  {
    "city": "Ghriss",
    "country": "Algeria",
    "IATA": "MUW",
    "icao_code": "DAOV"
  },
  {
    "city": "Gibraltar",
    "country": "Gibraltar",
    "IATA": "GIB",
    "icao_code": "LXGB"
  },
  {
    "city": "Giebelstadt",
    "country": "Germany",
    "IATA": "GHF",
    "icao_code": "ETEU"
  },
  {
    "city": "Gifu",
    "country": "Japan",
    "IATA": "QGU"
  },
  {
    "city": "Gilgit",
    "country": "Pakistan",
    "IATA": "GIL",
    "icao_code": "OPGT"
  },
  {
    "city": "Gillam",
    "country": "Canada",
    "IATA": "YGX",
    "icao_code": "CYGX"
  },
  {
    "city": "Gillette",
    "country": "United States",
    "IATA": "GCC",
    "icao_code": "KGCC"
  },
  {
    "city": "Gilze-rijen",
    "country": "Netherlands",
    "IATA": "GLZ",
    "icao_code": "EHGR"
  },
  {
    "city": "Gimli",
    "country": "Canada",
    "IATA": "YGM",
    "icao_code": "CYGM"
  },
  {
    "city": "Girardot",
    "country": "Colombia",
    "IATA": "GIR",
    "icao_code": "SKGI"
  },
  {
    "city": "Girua",
    "country": "Papua New Guinea",
    "IATA": "PNP",
    "icao_code": "AYGR"
  },
  {
    "city": "Gisborne",
    "country": "New Zealand",
    "IATA": "GIS",
    "icao_code": "NZGS"
  },
  {
    "city": "Gisenyi",
    "country": "Rwanda",
    "IATA": "GYI",
    "icao_code": "HRYG"
  },
  {
    "city": "Gitega",
    "country": "Burundi",
    "IATA": "GID"
  },
  {
    "city": "Gizan",
    "country": "Saudi Arabia",
    "IATA": "GIZ",
    "icao_code": "OEGN"
  },
  {
    "city": "Gizo",
    "country": "Solomon Islands",
    "IATA": "GZO",
    "icao_code": "AGGN"
  },
  {
    "city": "Gjoa Haven",
    "country": "Canada",
    "IATA": "YHK",
    "icao_code": "CYHK"
  },
  {
    "city": "Gjogur",
    "country": "Iceland",
    "IATA": "GJR",
    "icao_code": "BIGJ"
  },
  {
    "city": "Gladstone",
    "country": "Australia",
    "IATA": "GLT",
    "icao_code": "YGLA"
  },
  {
    "city": "Gladwin",
    "country": "United States",
    "IATA": "GDW",
    "icao_code": "KGDW"
  },
  {
    "city": "Glasgow",
    "country": "United States",
    "IATA": "GLW",
    "icao_code": "KGLW"
  },
  {
    "city": "Glasgow",
    "country": "United States",
    "IATA": "GGW",
    "icao_code": "KGGW"
  },
  {
    "city": "Glasgow",
    "country": "United Kingdom",
    "IATA": "GLA",
    "icao_code": "EGPF"
  },
  {
    "city": "Glen Innes",
    "country": "Australia",
    "IATA": "GLI",
    "icao_code": "YGLI"
  },
  {
    "city": "Glendive",
    "country": "United States",
    "IATA": "GDV",
    "icao_code": "KGDV"
  },
  {
    "city": "Glentanner",
    "country": "New Zealand",
    "IATA": "GTN",
    "icao_code": "NZMC"
  },
  {
    "city": "Goa",
    "country": "India",
    "IATA": "GOI",
    "icao_code": "VAGO"
  },
  {
    "city": "Goba",
    "country": "Ethiopia",
    "IATA": "GOB",
    "icao_code": "HAGB"
  },
  {
    "city": "Gobernador Gregores",
    "country": "Argentina",
    "IATA": "GGS",
    "icao_code": "SAWR"
  },
  {
    "city": "Gode",
    "country": "Ethiopia",
    "IATA": "GDE",
    "icao_code": "HAGO"
  },
  {
    "city": "Gods Lake Narrows",
    "country": "Canada",
    "IATA": "YGO",
    "icao_code": "CYGO"
  },
  {
    "city": "Gods River",
    "country": "Canada",
    "IATA": "ZGI",
    "icao_code": "CZGI"
  },
  {
    "city": "Godthaab",
    "country": "Greenland",
    "IATA": "GOH",
    "icao_code": "BGGH"
  },
  {
    "city": "Goiania",
    "country": "Brazil",
    "IATA": "GYN",
    "icao_code": "SBGO"
  },
  {
    "city": "Goldsboro",
    "country": "United States",
    "IATA": "GSB"
  },
  {
    "city": "Golfito",
    "country": "Costa Rica",
    "IATA": "GLF",
    "icao_code": "MRGF"
  },
  {
    "city": "Golmud",
    "country": "China",
    "IATA": "GOQ",
    "icao_code": "ZLGM"
  },
  {
    "city": "Golog",
    "country": "China",
    "IATA": "GMQ",
    "icao_code": "ZLGL"
  },
  {
    "city": "Golouchestershire",
    "country": "United Kingdom",
    "IATA": "GLO",
    "icao_code": "EGBJ"
  },
  {
    "city": "Golovin",
    "country": "United States",
    "IATA": "GLV",
    "icao_code": "PAGL"
  },
  {
    "city": "Goma",
    "country": "Congo (Kinshasa)",
    "IATA": "GOM",
    "icao_code": "FZNA"
  },
  {
    "city": "Gombe",
    "country": "Nigeria",
    "IATA": "GMO",
    "icao_code": "DNGO"
  },
  {
    "city": "Gomel",
    "country": "Belarus",
    "IATA": "GME",
    "icao_code": "UMGG"
  },
  {
    "city": "Gondar",
    "country": "Ethiopia",
    "IATA": "GDQ",
    "icao_code": "HAGN"
  },
  {
    "city": "Goodland",
    "country": "United States",
    "IATA": "GLD",
    "icao_code": "KGLD"
  },
  {
    "city": "Goodwood",
    "country": "United Kingdom",
    "IATA": "QUG",
    "icao_code": "EGHR"
  },
  {
    "city": "Goodyear",
    "country": "United States",
    "IATA": "GYR",
    "icao_code": "KGYR"
  },
  {
    "city": "Goondiwindi",
    "country": "Australia",
    "IATA": "GOO",
    "icao_code": "YGDI"
  },
  {
    "city": "Goose Bay",
    "country": "Canada",
    "IATA": "YYR",
    "icao_code": "CYYR"
  },
  {
    "city": "Gorakhpur",
    "country": "India",
    "IATA": "GOP",
    "icao_code": "VEGK"
  },
  {
    "city": "Gore",
    "country": "Ethiopia",
    "IATA": "GOR",
    "icao_code": "HAGR"
  },
  {
    "city": "Gore Bay",
    "country": "Canada",
    "IATA": "YZE",
    "icao_code": "CYZE"
  },
  {
    "city": "Gorgan",
    "country": "Iran",
    "IATA": "GBT",
    "icao_code": "OING"
  },
  {
    "city": "Gorna Orechovica",
    "country": "Bulgaria",
    "IATA": "GOZ",
    "icao_code": "LBGO"
  },
  {
    "city": "Gorno-Altaysk",
    "country": "Russia",
    "IATA": "RGK",
    "icao_code": "UNBG"
  },
  {
    "city": "Goroka",
    "country": "Papua New Guinea",
    "IATA": "GKA",
    "icao_code": "AYGA"
  },
  {
    "city": "Gorontalo",
    "country": "Indonesia",
    "IATA": "GTO",
    "icao_code": "WAMG"
  },
  {
    "city": "Gothenborg",
    "country": "Sweden",
    "IATA": "GSE",
    "icao_code": "ESGP"
  },
  {
    "city": "Gothenborg",
    "country": "Sweden",
    "IATA": "GOT",
    "icao_code": "ESGG"
  },
  {
    "city": "Goulburn",
    "country": "Australia",
    "IATA": "GUL",
    "icao_code": "YGLB"
  },
  {
    "city": "Gove",
    "country": "Australia",
    "IATA": "GOV",
    "icao_code": "YPGV"
  },
  {
    "city": "Governador Valadares",
    "country": "Brazil",
    "IATA": "GVR",
    "icao_code": "SNGV"
  },
  {
    "city": "Governor's Harbor",
    "country": "Bahamas",
    "IATA": "GHB",
    "icao_code": "MYEM"
  },
  {
    "city": "Goya",
    "country": "Argentina",
    "IATA": "OYA",
    "icao_code": "SATG"
  },
  {
    "city": "Gozo",
    "country": "Malta",
    "IATA": "GZM"
  },
  {
    "city": "Graciosa Island",
    "country": "Portugal",
    "IATA": "GRW",
    "icao_code": "LPGR"
  },
  {
    "city": "Grafton",
    "country": "Australia",
    "IATA": "GFN",
    "icao_code": "YGFN"
  },
  {
    "city": "Gran Canaria",
    "country": "Spain",
    "IATA": "LPA",
    "icao_code": "GCLP"
  },
  {
    "city": "Granada",
    "country": "Spain",
    "IATA": "GRX",
    "icao_code": "LEGR"
  },
  {
    "city": "Grand Bourg",
    "country": "Guadeloupe",
    "IATA": "GBJ"
  },
  {
    "city": "Grand Canyon",
    "country": "United States",
    "IATA": "GCN",
    "icao_code": "KGCN"
  },
  {
    "city": "Grand Forks",
    "country": "Canada",
    "IATA": "ZGF",
    "icao_code": "CZGF"
  },
  {
    "city": "Grand Forks",
    "country": "United States",
    "IATA": "GFK",
    "icao_code": "KGFK"
  },
  {
    "city": "Grand Island",
    "country": "United States",
    "IATA": "GRI",
    "icao_code": "KGRI"
  },
  {
    "city": "Grand Junction",
    "country": "United States",
    "IATA": "GJT",
    "icao_code": "KGJT"
  },
  {
    "city": "Grand Marais",
    "country": "United States",
    "IATA": "GRM",
    "icao_code": "KCKC"
  },
  {
    "city": "Grand Rapids",
    "country": "United States",
    "IATA": "GRR",
    "icao_code": "KGRR"
  },
  {
    "city": "Grand Rapids MN",
    "country": "United States",
    "IATA": "GPZ",
    "icao_code": "KGPZ"
  },
  {
    "city": "Grand-Santi",
    "country": "French Guiana",
    "IATA": "GSI"
  },
  {
    "city": "Grande Anse",
    "country": "Guadeloupe",
    "IATA": "DSD"
  },
  {
    "city": "Grande Prairie",
    "country": "Canada",
    "IATA": "YQU",
    "icao_code": "CYQU"
  },
  {
    "city": "Grant County Airport",
    "country": "United States",
    "IATA": "MWH",
    "icao_code": "KMWH"
  },
  {
    "city": "Grants",
    "country": "United States",
    "IATA": "GNT",
    "icao_code": "KGNT"
  },
  {
    "city": "Granville",
    "country": "France",
    "IATA": "GFR"
  },
  {
    "city": "Graz",
    "country": "Austria",
    "IATA": "GRZ",
    "icao_code": "LOWG"
  },
  {
    "city": "Great Bend",
    "country": "United States",
    "IATA": "GBD",
    "icao_code": "KGBD"
  },
  {
    "city": "Great Exuma",
    "country": "Bahamas",
    "IATA": "GGT",
    "icao_code": "MYEF"
  },
  {
    "city": "Great Falls",
    "country": "United States",
    "IATA": "GTF",
    "icao_code": "KGTF"
  },
  {
    "city": "Great Keppel Island",
    "country": "Australia",
    "IATA": "GKL"
  },
  {
    "city": "Greeley",
    "country": "United States",
    "IATA": "GXY",
    "icao_code": "KGXY"
  },
  {
    "city": "Green Bay",
    "country": "United States",
    "IATA": "GRB",
    "icao_code": "KGRB"
  },
  {
    "city": "Green Island",
    "country": "Taiwan",
    "IATA": "GNI",
    "icao_code": "RCGI"
  },
  {
    "city": "Greensboro",
    "country": "United States",
    "IATA": "GSO",
    "icao_code": "KGSO"
  },
  {
    "city": "Greenvile",
    "country": "United States",
    "IATA": "GVT",
    "icao_code": "KGVT"
  },
  {
    "city": "Greenville",
    "country": "United States",
    "IATA": "GMU",
    "icao_code": "KGMU"
  },
  {
    "city": "Greenville",
    "country": "United States",
    "IATA": "GDC",
    "icao_code": "KGYH"
  },
  {
    "city": "Greenville",
    "country": "Liberia",
    "IATA": "CPA",
    "icao_code": "GLCP"
  },
  {
    "city": "Greenville",
    "country": "United States",
    "IATA": "PGV",
    "icao_code": "KPGV"
  },
  {
    "city": "Greenville",
    "country": "United States",
    "IATA": "GLH",
    "icao_code": "KGLH"
  },
  {
    "city": "Greenville",
    "country": "United States",
    "IATA": "GSP",
    "icao_code": "KGSP"
  },
  {
    "city": "Greenwood",
    "country": "United States",
    "IATA": "GWO",
    "icao_code": "KGWO"
  },
  {
    "city": "Greenwood",
    "country": "Canada",
    "IATA": "YZX",
    "icao_code": "CYZX"
  },
  {
    "city": "Grenchen",
    "country": "Switzerland",
    "IATA": "ZHI"
  },
  {
    "city": "Grenoble",
    "country": "France",
    "IATA": "GNB",
    "icao_code": "LFLS"
  },
  {
    "city": "Griffith",
    "country": "Australia",
    "IATA": "GFF",
    "icao_code": "YGTH"
  },
  {
    "city": "Grise Fiord",
    "country": "Canada",
    "IATA": "YGZ",
    "icao_code": "CYGZ"
  },
  {
    "city": "Groningen",
    "country": "Netherlands",
    "IATA": "GRQ",
    "icao_code": "EHGG"
  },
  {
    "city": "Groote Eylandt",
    "country": "Australia",
    "IATA": "GTE",
    "icao_code": "YGTE"
  },
  {
    "city": "Grootfontein",
    "country": "Namibia",
    "IATA": "GFY",
    "icao_code": "FYGF"
  },
  {
    "city": "Grosseto",
    "country": "Italy",
    "IATA": "GRS",
    "icao_code": "LIRS"
  },
  {
    "city": "Groton CT",
    "country": "United States",
    "IATA": "GON",
    "icao_code": "KGON"
  },
  {
    "city": "Grottaglie",
    "country": "Italy",
    "IATA": "TAR",
    "icao_code": "LIBG"
  },
  {
    "city": "Grundarfjordur",
    "country": "Iceland",
    "IATA": "GUU",
    "icao_code": "BIGF"
  },
  {
    "city": "Grímsey",
    "country": "Iceland",
    "IATA": "GRY",
    "icao_code": "BIGR"
  },
  {
    "city": "Guadalajara",
    "country": "Mexico",
    "IATA": "GDL",
    "icao_code": "MMGL"
  },
  {
    "city": "Guajara-mirim",
    "country": "Brazil",
    "IATA": "GJM",
    "icao_code": "SBGM"
  },
  {
    "city": "Gualeguaychu",
    "country": "Argentina",
    "IATA": "GHU",
    "icao_code": "SAAG"
  },
  {
    "city": "Guanaja",
    "country": "Honduras",
    "IATA": "GJA",
    "icao_code": "MHNJ"
  },
  {
    "city": "Guanambi",
    "country": "Brazil",
    "IATA": "GNM",
    "icao_code": "SNGI"
  },
  {
    "city": "Guanare",
    "country": "Venezuela",
    "IATA": "GUQ",
    "icao_code": "SVGU"
  },
  {
    "city": "Guanghua",
    "country": "China",
    "IATA": "LHK",
    "icao_code": "ZHGH"
  },
  {
    "city": "Guangyuan",
    "country": "China",
    "IATA": "GYS",
    "icao_code": "ZUGU"
  },
  {
    "city": "Guangzhou",
    "country": "China",
    "IATA": "CAN",
    "icao_code": "ZGGG"
  },
  {
    "city": "Guantanamo",
    "country": "Cuba",
    "IATA": "GAO",
    "icao_code": "MUGT"
  },
  {
    "city": "Guantanamo",
    "country": "Cuba",
    "IATA": "NBW",
    "icao_code": "MUGM"
  },
  {
    "city": "Guapi",
    "country": "Colombia",
    "IATA": "GPI",
    "icao_code": "SKGP"
  },
  {
    "city": "Guapiles",
    "country": "Costa Rica",
    "IATA": "GPL",
    "icao_code": "MRGP"
  },
  {
    "city": "Guarapari",
    "country": "Brazil",
    "IATA": "GUZ",
    "icao_code": "SNGA"
  },
  {
    "city": "Guarapuava",
    "country": "Brazil",
    "IATA": "GPB",
    "icao_code": "SSUW"
  },
  {
    "city": "Guaratingueta",
    "country": "Brazil",
    "IATA": "GUJ",
    "icao_code": "SBGW"
  },
  {
    "city": "Guasdualito",
    "country": "Venezuela",
    "IATA": "GDO",
    "icao_code": "SVGD"
  },
  {
    "city": "Guatemala City",
    "country": "Guatemala",
    "IATA": "GUA",
    "icao_code": "MGGT"
  },
  {
    "city": "Guayana",
    "country": "Venezuela",
    "IATA": "PZO",
    "icao_code": "SVPR"
  },
  {
    "city": "Guayaquil",
    "country": "Ecuador",
    "IATA": "GYE",
    "icao_code": "SEGU"
  },
  {
    "city": "Guayaramerín",
    "country": "Bolivia",
    "IATA": "GYA",
    "icao_code": "SLGY"
  },
  {
    "city": "Guaymas",
    "country": "Mexico",
    "IATA": "GYM",
    "icao_code": "MMGM"
  },
  {
    "city": "Guemar",
    "country": "Algeria",
    "IATA": "ELU",
    "icao_code": "DAUO"
  },
  {
    "city": "Guernsey",
    "country": "Guernsey",
    "IATA": "GCI",
    "icao_code": "EGJB"
  },
  {
    "city": "Guerrero Negro",
    "country": "Mexico",
    "IATA": "GUB",
    "icao_code": "MMGR"
  },
  {
    "city": "Guetersloh",
    "country": "Germany",
    "IATA": "GUT",
    "icao_code": "ETUO"
  },
  {
    "city": "Guilin",
    "country": "China",
    "IATA": "KWL",
    "icao_code": "ZGKL"
  },
  {
    "city": "Guiria",
    "country": "Venezuela",
    "IATA": "GUI",
    "icao_code": "SVGI"
  },
  {
    "city": "Guiyang",
    "country": "China",
    "IATA": "KWE",
    "icao_code": "ZUGY"
  },
  {
    "city": "Gulf Shores",
    "country": "United States",
    "IATA": "GUF",
    "icao_code": "KJKA"
  },
  {
    "city": "Gulfport",
    "country": "United States",
    "IATA": "GPT",
    "icao_code": "KGPT"
  },
  {
    "city": "Gulkana",
    "country": "United States",
    "IATA": "GKN",
    "icao_code": "PAGK"
  },
  {
    "city": "Gulu",
    "country": "Uganda",
    "IATA": "ULU",
    "icao_code": "HUGU"
  },
  {
    "city": "Guna",
    "country": "India",
    "IATA": "GUX",
    "icao_code": "VAGN"
  },
  {
    "city": "Gunnison",
    "country": "United States",
    "IATA": "GUC",
    "icao_code": "KGUC"
  },
  {
    "city": "Gunung Sitoli",
    "country": "Indonesia",
    "IATA": "GNS",
    "icao_code": "WIMB"
  },
  {
    "city": "Guriat",
    "country": "Saudi Arabia",
    "IATA": "URY",
    "icao_code": "OEGT"
  },
  {
    "city": "Gurney",
    "country": "Papua New Guinea",
    "IATA": "GUR",
    "icao_code": "AYGN"
  },
  {
    "city": "Gurupi",
    "country": "Brazil",
    "IATA": "GRP",
    "icao_code": "SWGI"
  },
  {
    "city": "Gustavia",
    "country": "France",
    "IATA": "SBH",
    "icao_code": "TFFJ"
  },
  {
    "city": "Gustavus",
    "country": "United States",
    "IATA": "GST",
    "icao_code": "PAGS"
  },
  {
    "city": "Guwahati",
    "country": "India",
    "IATA": "GAU",
    "icao_code": "VEGT"
  },
  {
    "city": "Guymon",
    "country": "United States",
    "IATA": "GUY",
    "icao_code": "KGUY"
  },
  {
    "city": "Guyuan",
    "country": "China",
    "IATA": "GYU"
  },
  {
    "city": "Gwadar",
    "country": "Pakistan",
    "IATA": "GWD",
    "icao_code": "OPGD"
  },
  {
    "city": "Gwalior",
    "country": "India",
    "IATA": "GWL",
    "icao_code": "VIGR"
  },
  {
    "city": "Gwert",
    "country": "Zimbabwe",
    "IATA": "GWE",
    "icao_code": "FVTL"
  },
  {
    "city": "Gwinn",
    "country": "United States",
    "IATA": "MQT",
    "icao_code": "KSAW"
  },
  {
    "city": "Gyumri",
    "country": "Armenia",
    "IATA": "LWN",
    "icao_code": "UDSG"
  },
  {
    "city": "Haa Dhaalu Atoll",
    "country": "Maldives",
    "IATA": "HAQ",
    "icao_code": "VRMH"
  },
  {
    "city": "Hachijojima",
    "country": "Japan",
    "IATA": "HAC",
    "icao_code": "RJTH"
  },
  {
    "city": "Hachinoe",
    "country": "Japan",
    "IATA": "HHE"
  },
  {
    "city": "Hafr Al-batin",
    "country": "Saudi Arabia",
    "IATA": "AQI",
    "icao_code": "OEPA"
  },
  {
    "city": "Hagerstown",
    "country": "United States",
    "IATA": "HGR",
    "icao_code": "KHGR"
  },
  {
    "city": "Hagfors",
    "country": "Sweden",
    "IATA": "HFS",
    "icao_code": "ESOH"
  },
  {
    "city": "Hahn",
    "country": "Germany",
    "IATA": "HHN",
    "icao_code": "EDFH"
  },
  {
    "city": "Haifa",
    "country": "Israel",
    "IATA": "HFA",
    "icao_code": "LLHA"
  },
  {
    "city": "Haikou",
    "country": "China",
    "IATA": "HAK",
    "icao_code": "ZJHK"
  },
  {
    "city": "Hail",
    "country": "Saudi Arabia",
    "IATA": "HAS",
    "icao_code": "OEHL"
  },
  {
    "city": "Hailar",
    "country": "China",
    "IATA": "HLD",
    "icao_code": "ZBLA"
  },
  {
    "city": "Sun Valley, Idaho",
    "country": "United States",
    "IATA": "SUN",
    "icao_code": "KSUN"
  },
  {
    "city": "Haines",
    "country": "United States",
    "IATA": "HNS",
    "icao_code": "KHNS"
  },
  {
    "city": "Haines Junction",
    "country": "Canada",
    "IATA": "YHT",
    "icao_code": "CYHT"
  },
  {
    "city": "Haiphong",
    "country": "Vietnam",
    "IATA": "HPH",
    "icao_code": "VVCI"
  },
  {
    "city": "Haixi",
    "country": "China",
    "IATA": "HXD",
    "icao_code": "ZLDL"
  },
  {
    "city": "Hakkari",
    "country": "Turkey",
    "IATA": "YKO",
    "icao_code": "LTCW"
  },
  {
    "city": "Hakodate",
    "country": "Japan",
    "IATA": "HKD",
    "icao_code": "RJCH"
  },
  {
    "city": "Half Moon Bay",
    "country": "United States",
    "IATA": "HAF",
    "icao_code": "KHAF"
  },
  {
    "city": "Halifax",
    "country": "Canada",
    "IATA": "YHZ",
    "icao_code": "CYHZ"
  },
  {
    "city": "Hall Beach",
    "country": "Canada",
    "IATA": "YUX",
    "icao_code": "CYUX"
  },
  {
    "city": "Halli",
    "country": "Finland",
    "IATA": "KEV",
    "icao_code": "EFHA"
  },
  {
    "city": "Halls Creek",
    "country": "Australia",
    "IATA": "HCQ",
    "icao_code": "YHLC"
  },
  {
    "city": "Halmstad",
    "country": "Sweden",
    "IATA": "HAD",
    "icao_code": "ESMT"
  },
  {
    "city": "Hamadan",
    "country": "Iran",
    "IATA": "HDM",
    "icao_code": "OIHS"
  },
  {
    "city": "Hamar",
    "country": "Norway",
    "IATA": "HMR",
    "icao_code": "ENHA"
  },
  {
    "city": "Hamburg",
    "country": "Germany",
    "IATA": "XFW",
    "icao_code": "EDHI"
  },
  {
    "city": "Hamburg",
    "country": "Germany",
    "IATA": "HAM",
    "icao_code": "EDDH"
  },
  {
    "city": "Hamhung",
    "country": "North Korea",
    "IATA": "DSO"
  },
  {
    "city": "Hami",
    "country": "China",
    "IATA": "HMI",
    "icao_code": "ZWHM"
  },
  {
    "city": "Hamilton",
    "country": "United States",
    "IATA": "HAO"
  },
  {
    "city": "Hamilton",
    "country": "Australia",
    "IATA": "HLT",
    "icao_code": "YHML"
  },
  {
    "city": "Hamilton",
    "country": "New Zealand",
    "IATA": "HLZ",
    "icao_code": "NZHN"
  },
  {
    "city": "Hamilton",
    "country": "Canada",
    "IATA": "YHM",
    "icao_code": "CYHM"
  },
  {
    "city": "Hamilton Island",
    "country": "Australia",
    "IATA": "HTI",
    "icao_code": "YBHM"
  },
  {
    "city": "Hammerfest",
    "country": "Norway",
    "IATA": "HFT",
    "icao_code": "ENHF"
  },
  {
    "city": "Hampton",
    "country": "United States",
    "IATA": "LFI"
  },
  {
    "city": "Hana",
    "country": "United States",
    "IATA": "HNM",
    "icao_code": "PHHN"
  },
  {
    "city": "Hanamaki",
    "country": "Japan",
    "IATA": "HNA",
    "icao_code": "RJSI"
  },
  {
    "city": "Hanau",
    "country": "Germany",
    "IATA": "ZNF"
  },
  {
    "city": "Hancock",
    "country": "United States",
    "IATA": "CMX",
    "icao_code": "KCMX"
  },
  {
    "city": "Handan",
    "country": "China",
    "IATA": "HDG",
    "icao_code": "ZBHD"
  },
  {
    "city": "Hangzhou",
    "country": "China",
    "IATA": "HGH",
    "icao_code": "ZSHC"
  },
  {
    "city": "Hannover",
    "country": "Germany",
    "IATA": "HAJ",
    "icao_code": "EDDV"
  },
  {
    "city": "Hanoi",
    "country": "Vietnam",
    "IATA": "HAN",
    "icao_code": "VVNB"
  },
  {
    "city": "Hanzhong",
    "country": "China",
    "IATA": "HZG",
    "icao_code": "ZLHZ"
  },
  {
    "city": "Hao Island",
    "country": "French Polynesia",
    "IATA": "HOI",
    "icao_code": "NTTO"
  },
  {
    "city": "Harare",
    "country": "Zimbabwe",
    "IATA": "HRE",
    "icao_code": "FVHA"
  },
  {
    "city": "Harbin",
    "country": "China",
    "IATA": "HRB",
    "icao_code": "ZYHB"
  },
  {
    "city": "Hargeisa",
    "country": "Somalia",
    "IATA": "HGA",
    "icao_code": "HCMH"
  },
  {
    "city": "Harlingen",
    "country": "United States",
    "IATA": "HRL",
    "icao_code": "KHRL"
  },
  {
    "city": "Harrisburg",
    "country": "United States",
    "IATA": "HAR",
    "icao_code": "KCXY"
  },
  {
    "city": "Harrisburg",
    "country": "United States",
    "IATA": "MDT",
    "icao_code": "KMDT"
  },
  {
    "city": "Harrismith",
    "country": "South Africa",
    "IATA": "HRS"
  },
  {
    "city": "Harrison",
    "country": "United States",
    "IATA": "HRO",
    "icao_code": "KHRO"
  },
  {
    "city": "Hartford",
    "country": "United States",
    "IATA": "HFD",
    "icao_code": "KHFD"
  },
  {
    "city": "Hartsville",
    "country": "United States",
    "IATA": "HVS",
    "icao_code": "KHVS"
  },
  {
    "city": "Hassi Messaoud",
    "country": "Algeria",
    "IATA": "HME",
    "icao_code": "DAUH"
  },
  {
    "city": "Hasvik",
    "country": "Norway",
    "IATA": "HAA",
    "icao_code": "ENHK"
  },
  {
    "city": "Hat Yai",
    "country": "Thailand",
    "IATA": "HDY",
    "icao_code": "VTSS"
  },
  {
    "city": "Hatay",
    "country": "Turkey",
    "IATA": "HTY",
    "icao_code": "LTDA"
  },
  {
    "city": "Hattiesburg",
    "country": "United States",
    "IATA": "HBG",
    "icao_code": "KHBG"
  },
  {
    "city": "Hattiesburg/Laurel",
    "country": "United States",
    "IATA": "PIB",
    "icao_code": "KPIB"
  },
  {
    "city": "Haugesund",
    "country": "Norway",
    "IATA": "HAU",
    "icao_code": "ENHD"
  },
  {
    "city": "Havana",
    "country": "Cuba",
    "IATA": "HAV",
    "icao_code": "MUHA"
  },
  {
    "city": "Haverfordwest",
    "country": "United Kingdom",
    "IATA": "HAW",
    "icao_code": "EGFE"
  },
  {
    "city": "Havre",
    "country": "United States",
    "IATA": "HVR",
    "icao_code": "KHVR"
  },
  {
    "city": "Havre-Saint-Pierre",
    "country": "Canada",
    "IATA": "YGV",
    "icao_code": "CYGV"
  },
  {
    "city": "Hawarden",
    "country": "United Kingdom",
    "IATA": "CEG",
    "icao_code": "EGNR"
  },
  {
    "city": "Hawthorne",
    "country": "United States",
    "IATA": "HHR"
  },
  {
    "city": "Hay River",
    "country": "Canada",
    "IATA": "YHY",
    "icao_code": "CYHY"
  },
  {
    "city": "Hayden",
    "country": "United States",
    "IATA": "HDN",
    "icao_code": "KHDN"
  },
  {
    "city": "Hays",
    "country": "United States",
    "IATA": "HYS",
    "icao_code": "KHYS"
  },
  {
    "city": "Hayward",
    "country": "United States",
    "IATA": "HYR"
  },
  {
    "city": "Hayward",
    "country": "United States",
    "IATA": "HWD",
    "icao_code": "KHWD"
  },
  {
    "city": "Hazleton",
    "country": "United States",
    "IATA": "HZL",
    "icao_code": "KHZL"
  },
  {
    "city": "Hearst",
    "country": "Canada",
    "IATA": "YHF",
    "icao_code": "CYHF"
  },
  {
    "city": "Hechi",
    "country": "China",
    "IATA": "HCJ",
    "icao_code": "ZGHC"
  },
  {
    "city": "Hefei",
    "country": "China",
    "IATA": "HFE",
    "icao_code": "ZSOF"
  },
  {
    "city": "Heho",
    "country": "Burma",
    "IATA": "HEH",
    "icao_code": "VYHH"
  },
  {
    "city": "Heihe",
    "country": "China",
    "IATA": "HEK",
    "icao_code": "ZYHE"
  },
  {
    "city": "Helena",
    "country": "United States",
    "IATA": "HLN",
    "icao_code": "KHLN"
  },
  {
    "city": "Helgoland",
    "country": "Germany",
    "IATA": "HGL",
    "icao_code": "EDXH"
  },
  {
    "city": "Helsinki",
    "country": "Finland",
    "IATA": "HEL",
    "icao_code": "EFHK"
  },
  {
    "city": "Helsinki",
    "country": "Finland",
    "IATA": "HEM",
    "icao_code": "EFHF"
  },
  {
    "city": "Hemavan",
    "country": "Sweden",
    "IATA": "HMV",
    "icao_code": "ESUT"
  },
  {
    "city": "Henderson",
    "country": "United States",
    "IATA": "HSH",
    "icao_code": "KHND"
  },
  {
    "city": "Hengchun",
    "country": "Taiwan",
    "IATA": "HCN",
    "icao_code": "RCKW"
  },
  {
    "city": "Hengyang",
    "country": "China",
    "IATA": "HNY",
    "icao_code": "ZGHY"
  },
  {
    "city": "Heraklion",
    "country": "Greece",
    "IATA": "HER",
    "icao_code": "LGIR"
  },
  {
    "city": "Herat",
    "country": "Afghanistan",
    "IATA": "HEA",
    "icao_code": "OAHR"
  },
  {
    "city": "Heringsdorf",
    "country": "Germany",
    "IATA": "HDF",
    "icao_code": "EDAH"
  },
  {
    "city": "Hermannsburg",
    "country": "Australia",
    "IATA": "HMG",
    "icao_code": "YHMB"
  },
  {
    "city": "Hermosillo",
    "country": "Mexico",
    "IATA": "HMO",
    "icao_code": "MMHO"
  },
  {
    "city": "Hervey Bay",
    "country": "Australia",
    "IATA": "HVB",
    "icao_code": "YHBA"
  },
  {
    "city": "Hewandorra",
    "country": "Saint Lucia",
    "IATA": "UVF",
    "icao_code": "TLPL"
  },
  {
    "city": "Hibbing",
    "country": "United States",
    "IATA": "HIB",
    "icao_code": "KHIB"
  },
  {
    "city": "Hickory",
    "country": "United States",
    "IATA": "HKY",
    "icao_code": "KHKY"
  },
  {
    "city": "Hierro",
    "country": "Spain",
    "IATA": "VDE",
    "icao_code": "GCHI"
  },
  {
    "city": "High Level",
    "country": "Canada",
    "IATA": "YOJ",
    "icao_code": "CYOJ"
  },
  {
    "city": "Higuerote",
    "country": "Venezuela",
    "IATA": "HGE"
  },
  {
    "city": "Hikueru",
    "country": "French Polynesia",
    "IATA": "HHZ",
    "icao_code": "NTGH"
  },
  {
    "city": "Hillsboro",
    "country": "United States",
    "IATA": "HIO",
    "icao_code": "KHIO"
  },
  {
    "city": "Hilo",
    "country": "United States",
    "IATA": "ITO",
    "icao_code": "PHTO"
  },
  {
    "city": "Hilton Head Island",
    "country": "United States",
    "IATA": "HHH",
    "icao_code": "KHXD"
  },
  {
    "city": "Hinton",
    "country": "Canada",
    "IATA": "YJP"
  },
  {
    "city": "Hiroshima",
    "country": "Japan",
    "IATA": "HIW",
    "icao_code": "RJBH"
  },
  {
    "city": "Hiroshima",
    "country": "Japan",
    "IATA": "HIJ",
    "icao_code": "RJOA"
  },
  {
    "city": "Hissar",
    "country": "India",
    "IATA": "HSS",
    "icao_code": "VIHR"
  },
  {
    "city": "Hiva-oa",
    "country": "French Polynesia",
    "IATA": "AUQ",
    "icao_code": "NTMN"
  },
  {
    "city": "Ho Chi Minh City",
    "country": "Vietnam",
    "IATA": "SGN",
    "icao_code": "VVTS"
  },
  {
    "city": "Hobart",
    "country": "United States",
    "IATA": "HBR",
    "icao_code": "KHBR"
  },
  {
    "city": "Hobart",
    "country": "Australia",
    "IATA": "HBA",
    "icao_code": "YMHB"
  },
  {
    "city": "Hobbs",
    "country": "United States",
    "IATA": "HOB",
    "icao_code": "KHOB"
  },
  {
    "city": "Hodeidah",
    "country": "Yemen",
    "IATA": "HOD",
    "icao_code": "OYHD"
  },
  {
    "city": "Hoedspruit",
    "country": "South Africa",
    "IATA": "HDS",
    "icao_code": "FAHS"
  },
  {
    "city": "Hof",
    "country": "Germany",
    "IATA": "HOQ",
    "icao_code": "EDQM"
  },
  {
    "city": "Hofn",
    "country": "Iceland",
    "IATA": "HFN",
    "icao_code": "BIHN"
  },
  {
    "city": "Hohenems",
    "country": "Austria",
    "IATA": "HOH",
    "icao_code": "LOIH"
  },
  {
    "city": "Hohhot",
    "country": "China",
    "IATA": "HET",
    "icao_code": "ZBHH"
  },
  {
    "city": "Hokitika",
    "country": "New Zealand",
    "IATA": "HKK",
    "icao_code": "NZHK"
  },
  {
    "city": "Hola",
    "country": "Kenya",
    "IATA": "HOA",
    "icao_code": "HKHO"
  },
  {
    "city": "Holdredge",
    "country": "United States",
    "IATA": "HDE",
    "icao_code": "KHDE"
  },
  {
    "city": "Holguin",
    "country": "Cuba",
    "IATA": "HOG",
    "icao_code": "MUHG"
  },
  {
    "city": "Holingol",
    "country": "China",
    "IATA": "HUO",
    "icao_code": "ZBHZ"
  },
  {
    "city": "Hollister",
    "country": "United States",
    "IATA": "HLI",
    "icao_code": "KCVH"
  },
  {
    "city": "Hollywood",
    "country": "United States",
    "IATA": "HWO",
    "icao_code": "KHWO"
  },
  {
    "city": "Holman Island",
    "country": "Canada",
    "IATA": "YHI",
    "icao_code": "CYHI"
  },
  {
    "city": "Holy Cross",
    "country": "United States",
    "IATA": "HCR",
    "icao_code": "PAHC"
  },
  {
    "city": "Homer",
    "country": "United States",
    "IATA": "HOM",
    "icao_code": "PAHO"
  },
  {
    "city": "Homestead",
    "country": "United States",
    "IATA": "HST"
  },
  {
    "city": "Hommalin",
    "country": "Burma",
    "IATA": "HOX"
  },
  {
    "city": "Hon",
    "country": "Libya",
    "IATA": "HUQ",
    "icao_code": "HLON"
  },
  {
    "city": "Hong Kong",
    "country": "Hong Kong",
    "IATA": "HHP"
  },
  {
    "city": "Hong Kong",
    "country": "Hong Kong",
    "IATA": "HKG",
    "icao_code": "VHHH"
  },
  {
    "city": "Honiara",
    "country": "Solomon Islands",
    "IATA": "HIR",
    "icao_code": "AGGH"
  },
  {
    "city": "Honington",
    "country": "United Kingdom",
    "IATA": "BEQ"
  },
  {
    "city": "Honningsvag",
    "country": "Norway",
    "IATA": "HVG",
    "icao_code": "ENHV"
  },
  {
    "city": "Honolulu",
    "country": "United States",
    "IATA": "HNL",
    "icao_code": "PHNL"
  },
  {
    "city": "Honuu",
    "country": "Russia",
    "IATA": "MQJ"
  },
  {
    "city": "Hooker Creek",
    "country": "Australia",
    "IATA": "HOK",
    "icao_code": "YHOO"
  },
  {
    "city": "Hoonah",
    "country": "United States",
    "IATA": "HNH",
    "icao_code": "PAOH"
  },
  {
    "city": "Hooper Bay",
    "country": "United States",
    "IATA": "HPB",
    "icao_code": "PAHP"
  },
  {
    "city": "Hope",
    "country": "Canada",
    "IATA": "YHE"
  },
  {
    "city": "Hopedale",
    "country": "Canada",
    "IATA": "YHO",
    "icao_code": "CYHO"
  },
  {
    "city": "Hopkinsville",
    "country": "United States",
    "IATA": "HOP",
    "icao_code": "KHOP"
  },
  {
    "city": "Hoquiam",
    "country": "United States",
    "IATA": "HQM"
  },
  {
    "city": "Horn Island",
    "country": "Australia",
    "IATA": "HID",
    "icao_code": "YHID"
  },
  {
    "city": "Hornepayne",
    "country": "Canada",
    "IATA": "YHN",
    "icao_code": "CYHN"
  },
  {
    "city": "Horsham",
    "country": "Australia",
    "IATA": "HSM",
    "icao_code": "YHSM"
  },
  {
    "city": "Horta",
    "country": "Portugal",
    "IATA": "HOR",
    "icao_code": "LPHR"
  },
  {
    "city": "Hoskins",
    "country": "Papua New Guinea",
    "IATA": "HKN",
    "icao_code": "AYHK"
  },
  {
    "city": "Hot Springs",
    "country": "United States",
    "IATA": "HOT",
    "icao_code": "KHOT"
  },
  {
    "city": "Hotan",
    "country": "China",
    "IATA": "HTN",
    "icao_code": "ZWTN"
  },
  {
    "city": "Houghton Lake",
    "country": "United States",
    "IATA": "HTL",
    "icao_code": "KHTL"
  },
  {
    "city": "Houlton",
    "country": "United States",
    "IATA": "HUL",
    "icao_code": "KHUL"
  },
  {
    "city": "Houston",
    "country": "United States",
    "IATA": "AAP",
    "icao_code": "WALS"
  },
  {
    "city": "Houston",
    "country": "United States",
    "IATA": "IWS",
    "icao_code": "KIWS"
  },
  {
    "city": "Houston",
    "country": "United States",
    "IATA": "DWH",
    "icao_code": "KDWH"
  },
  {
    "city": "Houston",
    "country": "United States",
    "IATA": "EFD",
    "icao_code": "KEFD"
  },
  {
    "city": "Houston",
    "country": "United States",
    "IATA": "HOU",
    "icao_code": "KHOU"
  },
  {
    "city": "Houston",
    "country": "United States",
    "IATA": "IAH",
    "icao_code": "KIAH"
  },
  {
    "city": "Howard",
    "country": "Panama",
    "IATA": "BLB"
  },
  {
    "city": "Hpa-an",
    "country": "Burma",
    "IATA": "PAA"
  },
  {
    "city": "Hrodna",
    "country": "Belarus",
    "IATA": "GNA",
    "icao_code": "UMMG"
  },
  {
    "city": "Hsinchu",
    "country": "Taiwan",
    "IATA": "HSZ"
  },
  {
    "city": "Huahine Island",
    "country": "French Polynesia",
    "IATA": "HUH",
    "icao_code": "NTTH"
  },
  {
    "city": "Huai An",
    "country": "China",
    "IATA": "HIA",
    "icao_code": "ZSSH"
  },
  {
    "city": "Hualien",
    "country": "Taiwan",
    "IATA": "HUN",
    "icao_code": "RCYU"
  },
  {
    "city": "Huambo",
    "country": "Angola",
    "IATA": "NOV",
    "icao_code": "FNHU"
  },
  {
    "city": "Huangshan",
    "country": "China",
    "IATA": "TXN",
    "icao_code": "ZSTX"
  },
  {
    "city": "Huangyan",
    "country": "China",
    "IATA": "HYN",
    "icao_code": "ZSLQ"
  },
  {
    "city": "Huatulco",
    "country": "Mexico",
    "IATA": "HUX",
    "icao_code": "MMBT"
  },
  {
    "city": "Huay Xai",
    "country": "Laos",
    "IATA": "HOE"
  },
  {
    "city": "Hubli",
    "country": "India",
    "IATA": "HBX",
    "icao_code": "VAHB"
  },
  {
    "city": "Hudiksvall",
    "country": "Sweden",
    "IATA": "HUV"
  },
  {
    "city": "Hudson Bay",
    "country": "Canada",
    "IATA": "YHB",
    "icao_code": "CYHB"
  },
  {
    "city": "Hue",
    "country": "Vietnam",
    "IATA": "HUI",
    "icao_code": "VVPB"
  },
  {
    "city": "Huesca",
    "country": "Spain",
    "IATA": "HSK",
    "icao_code": "LEHC"
  },
  {
    "city": "Hughenden",
    "country": "Australia",
    "IATA": "HGD",
    "icao_code": "YHUG"
  },
  {
    "city": "Hughes",
    "country": "United States",
    "IATA": "HUS",
    "icao_code": "PAHU"
  },
  {
    "city": "Huizhou",
    "country": "China",
    "IATA": "HUZ"
  },
  {
    "city": "Hultsfred",
    "country": "Sweden",
    "IATA": "HLF",
    "icao_code": "ESSF"
  },
  {
    "city": "Humaita",
    "country": "Brazil",
    "IATA": "HUW",
    "icao_code": "SWHT"
  },
  {
    "city": "Humberside",
    "country": "United Kingdom",
    "IATA": "HUY",
    "icao_code": "EGNJ"
  },
  {
    "city": "Humera",
    "country": "Ethiopia",
    "IATA": "HUE",
    "icao_code": "HAHU"
  },
  {
    "city": "Hunter Aaf",
    "country": "United States",
    "IATA": "SVN",
    "icao_code": "KSVN"
  },
  {
    "city": "Huntington",
    "country": "United States",
    "IATA": "HTS",
    "icao_code": "KHTS"
  },
  {
    "city": "Huntsville",
    "country": "United States",
    "IATA": "HTV"
  },
  {
    "city": "Huntsville",
    "country": "United States",
    "IATA": "HSV",
    "icao_code": "KHSV"
  },
  {
    "city": "Hurghada",
    "country": "Egypt",
    "IATA": "HRG",
    "icao_code": "HEGN"
  },
  {
    "city": "Huron",
    "country": "United States",
    "IATA": "HON",
    "icao_code": "KHON"
  },
  {
    "city": "Husavik",
    "country": "Iceland",
    "IATA": "HZK",
    "icao_code": "BIHU"
  },
  {
    "city": "Huslia",
    "country": "United States",
    "IATA": "HSL",
    "icao_code": "PAHS"
  },
  {
    "city": "Hutchinson",
    "country": "United States",
    "IATA": "HUT",
    "icao_code": "KHUT"
  },
  {
    "city": "Huánuco",
    "country": "Peru",
    "IATA": "HUU",
    "icao_code": "SPNC"
  },
  {
    "city": "Hwange",
    "country": "Zimbabwe",
    "IATA": "WKI",
    "icao_code": "FVWT"
  },
  {
    "city": "Hwange National Park",
    "country": "Zimbabwe",
    "IATA": "HWN",
    "icao_code": "FVWN"
  },
  {
    "city": "Hydaburg",
    "country": "United States",
    "IATA": "HYG",
    "icao_code": "PAHY"
  },
  {
    "city": "Hyderabad",
    "country": "India",
    "IATA": "HYD",
    "icao_code": "VOHS"
  },
  {
    "city": "Hyderabad",
    "country": "Pakistan",
    "IATA": "HDD",
    "icao_code": "OPKD"
  },
  {
    "city": "Hyderabad",
    "country": "India",
    "IATA": "BPM",
    "icao_code": "VOHY"
  },
  {
    "city": "Hyeres",
    "country": "France",
    "IATA": "TLN",
    "icao_code": "LFTH"
  },
  {
    "city": "Hyvinkaa",
    "country": "Finland",
    "IATA": "HYV",
    "icao_code": "EFHV"
  },
  {
    "city": "Iasi",
    "country": "Romania",
    "IATA": "IAS",
    "icao_code": "LRIA"
  },
  {
    "city": "Ibadan",
    "country": "Nigeria",
    "IATA": "IBA",
    "icao_code": "DNIB"
  },
  {
    "city": "Ibague",
    "country": "Colombia",
    "IATA": "IBE",
    "icao_code": "SKIB"
  },
  {
    "city": "Ibaraki",
    "country": "Japan",
    "IATA": "IBR",
    "icao_code": "RJAH"
  },
  {
    "city": "Iberia",
    "country": "Peru",
    "IATA": "IBP",
    "icao_code": "SPBR"
  },
  {
    "city": "Ibiza",
    "country": "Spain",
    "IATA": "IBZ",
    "icao_code": "LEIB"
  },
  {
    "city": "Idaho Falls",
    "country": "United States",
    "IATA": "IDA",
    "icao_code": "KIDA"
  },
  {
    "city": "Ie",
    "country": "Japan",
    "IATA": "IEJ",
    "icao_code": "RORE"
  },
  {
    "city": "Ifuru",
    "country": "Maldives",
    "IATA": "IFU",
    "icao_code": "VREI"
  },
  {
    "city": "Igarka",
    "country": "Russia",
    "IATA": "IAA",
    "icao_code": "UOII"
  },
  {
    "city": "Igdir",
    "country": "Turkey",
    "IATA": "IGD"
  },
  {
    "city": "Igiugig",
    "country": "United States",
    "IATA": "IGG",
    "icao_code": "PAIG"
  },
  {
    "city": "Igloolik",
    "country": "Canada",
    "IATA": "YGT",
    "icao_code": "CYGT"
  },
  {
    "city": "Ignace",
    "country": "Canada",
    "IATA": "ZUC",
    "icao_code": "CZUC"
  },
  {
    "city": "Iguatu",
    "country": "Brazil",
    "IATA": "QIG"
  },
  {
    "city": "Iguazu Falls",
    "country": "Argentina",
    "IATA": "IGR",
    "icao_code": "SARI"
  },
  {
    "city": "Ikamiut",
    "country": "Greenland",
    "IATA": "QJI"
  },
  {
    "city": "Ikaria",
    "country": "Greece",
    "IATA": "JIK",
    "icao_code": "LGIK"
  },
  {
    "city": "Ikela",
    "country": "Congo (Kinshasa)",
    "IATA": "IKL",
    "icao_code": "FZGV"
  },
  {
    "city": "Ikerasaarsuk",
    "country": "Greenland",
    "IATA": "QRY"
  },
  {
    "city": "Iki",
    "country": "Japan",
    "IATA": "IKI",
    "icao_code": "RJDB"
  },
  {
    "city": "Ilam",
    "country": "Iran",
    "IATA": "IIL",
    "icao_code": "OICI"
  },
  {
    "city": "Ilebo",
    "country": "Congo (Kinshasa)",
    "IATA": "PFR",
    "icao_code": "FZVS"
  },
  {
    "city": "Iles De La Madeleine",
    "country": "Canada",
    "IATA": "YGR",
    "icao_code": "CYGR"
  },
  {
    "city": "Ilford",
    "country": "Canada",
    "IATA": "ILF",
    "icao_code": "CZBD"
  },
  {
    "city": "Ilheus",
    "country": "Brazil",
    "IATA": "IOS",
    "icao_code": "SBIL"
  },
  {
    "city": "Iliamna",
    "country": "United States",
    "IATA": "ILI",
    "icao_code": "PAIL"
  },
  {
    "city": "Ilimanaq",
    "country": "Greenland",
    "IATA": "XIQ"
  },
  {
    "city": "Illaga",
    "country": "Indonesia",
    "IATA": "ILA",
    "icao_code": "WABL"
  },
  {
    "city": "Illizi",
    "country": "Algeria",
    "IATA": "VVZ",
    "icao_code": "DAAP"
  },
  {
    "city": "Ilo",
    "country": "Peru",
    "IATA": "ILQ"
  },
  {
    "city": "Iloilo",
    "country": "Philippines",
    "IATA": "ILO",
    "icao_code": "RPVI"
  },
  {
    "city": "Ilorin",
    "country": "Nigeria",
    "IATA": "ILR",
    "icao_code": "DNIL"
  },
  {
    "city": "Ilulissat",
    "country": "Greenland",
    "IATA": "JAV",
    "icao_code": "BGJN"
  },
  {
    "city": "Imbaimadai",
    "country": "Guyana",
    "IATA": "IMB",
    "icao_code": "SYIB"
  },
  {
    "city": "Immokalee",
    "country": "United States",
    "IATA": "IMM",
    "icao_code": "KIMM"
  },
  {
    "city": "Imo",
    "country": "Nigeria",
    "IATA": "QOW",
    "icao_code": "DNIM"
  },
  {
    "city": "Imperatriz",
    "country": "Brazil",
    "IATA": "IMP",
    "icao_code": "SBIZ"
  },
  {
    "city": "Imperial",
    "country": "United States",
    "IATA": "IPL",
    "icao_code": "KIPL"
  },
  {
    "city": "Impfondo",
    "country": "Congo (Brazzaville)",
    "IATA": "ION"
  },
  {
    "city": "Imphal",
    "country": "India",
    "IATA": "IMF",
    "icao_code": "VEIM"
  },
  {
    "city": "In Salah",
    "country": "Algeria",
    "IATA": "INZ",
    "icao_code": "DAUI"
  },
  {
    "city": "Independence",
    "country": "United States",
    "IATA": "IDP",
    "icao_code": "KIDP"
  },
  {
    "city": "Indian Mountains",
    "country": "United States",
    "IATA": "UTO",
    "icao_code": "PAIM"
  },
  {
    "city": "Indian Springs",
    "country": "United States",
    "IATA": "INS"
  },
  {
    "city": "Indianapolis",
    "country": "United States",
    "IATA": "IND",
    "icao_code": "KIND"
  },
  {
    "city": "Indore",
    "country": "India",
    "IATA": "IDR",
    "icao_code": "VAID"
  },
  {
    "city": "Indreabhan",
    "country": "Ireland",
    "IATA": "NNR",
    "icao_code": "EICA"
  },
  {
    "city": "Ingeniero Jacobacci",
    "country": "Argentina",
    "IATA": "IGB",
    "icao_code": "SAVJ"
  },
  {
    "city": "Ingolstadt",
    "country": "Germany",
    "IATA": "IGS",
    "icao_code": "INGS"
  },
  {
    "city": "Inhaca",
    "country": "Mozambique",
    "IATA": "IHC",
    "icao_code": "FQIA"
  },
  {
    "city": "Inhambane",
    "country": "Mozambique",
    "IATA": "INH",
    "icao_code": "FQIN"
  },
  {
    "city": "Inis Mor",
    "country": "Ireland",
    "IATA": "IOR",
    "icao_code": "EIIM"
  },
  {
    "city": "Inisheer",
    "country": "Ireland",
    "IATA": "INQ",
    "icao_code": "EIIR"
  },
  {
    "city": "Inishmaan",
    "country": "Ireland",
    "IATA": "IIA"
  },
  {
    "city": "Innisfail",
    "country": "Australia",
    "IATA": "IFL",
    "icao_code": "YIFL"
  },
  {
    "city": "Innsbruck",
    "country": "Austria",
    "IATA": "INN",
    "icao_code": "LOWI"
  },
  {
    "city": "Inongo",
    "country": "Congo (Kinshasa)",
    "IATA": "INO",
    "icao_code": "FZBA"
  },
  {
    "city": "Inta",
    "country": "Russia",
    "IATA": "INA",
    "icao_code": "UUYI"
  },
  {
    "city": "Interlaken",
    "country": "Switzerland",
    "IATA": "ZIN"
  },
  {
    "city": "International Falls",
    "country": "United States",
    "IATA": "INL",
    "icao_code": "KINL"
  },
  {
    "city": "Inukjuak",
    "country": "Canada",
    "IATA": "YPH",
    "icao_code": "CYPH"
  },
  {
    "city": "Inuvik",
    "country": "Canada",
    "IATA": "YEV",
    "icao_code": "CYEV"
  },
  {
    "city": "Invercargill",
    "country": "New Zealand",
    "IATA": "IVC",
    "icao_code": "NZNV"
  },
  {
    "city": "Inverell",
    "country": "Australia",
    "IATA": "IVR",
    "icao_code": "YIVL"
  },
  {
    "city": "Inverness",
    "country": "United Kingdom",
    "IATA": "INV",
    "icao_code": "EGPE"
  },
  {
    "city": "Inyokern",
    "country": "United States",
    "IATA": "IYK",
    "icao_code": "KIYK"
  },
  {
    "city": "Ioannina",
    "country": "Greece",
    "IATA": "IOA",
    "icao_code": "LGIO"
  },
  {
    "city": "Iowa City",
    "country": "United States",
    "IATA": "IOW",
    "icao_code": "KIOW"
  },
  {
    "city": "Ipatinga",
    "country": "Brazil",
    "IATA": "IPN",
    "icao_code": "SBIP"
  },
  {
    "city": "Ipiales",
    "country": "Colombia",
    "IATA": "IPI",
    "icao_code": "SKIP"
  },
  {
    "city": "Ipoh",
    "country": "Malaysia",
    "IATA": "IPH",
    "icao_code": "WMKI"
  },
  {
    "city": "Ipota",
    "country": "Vanuatu",
    "IATA": "IPA",
    "icao_code": "NVVI"
  },
  {
    "city": "Iqaluit",
    "country": "Canada",
    "IATA": "YFB",
    "icao_code": "CYFB"
  },
  {
    "city": "Iquique",
    "country": "Chile",
    "IATA": "IQQ",
    "icao_code": "SCDA"
  },
  {
    "city": "Iquitos",
    "country": "Peru",
    "IATA": "IQT",
    "icao_code": "SPQT"
  },
  {
    "city": "Iraan",
    "country": "United States",
    "IATA": "IRB"
  },
  {
    "city": "Iran Shahr",
    "country": "Iran",
    "IATA": "IHR",
    "icao_code": "OIZI"
  },
  {
    "city": "Iringa",
    "country": "Tanzania",
    "IATA": "IRI",
    "icao_code": "HTIR"
  },
  {
    "city": "Irkutsk",
    "country": "Russia",
    "IATA": "IKT",
    "icao_code": "UIII"
  },
  {
    "city": "Iron Mountain",
    "country": "United States",
    "IATA": "IMT",
    "icao_code": "KIMT"
  },
  {
    "city": "Isabela",
    "country": "Ecuador",
    "IATA": "IBB"
  },
  {
    "city": "Isafjordur",
    "country": "Iceland",
    "IATA": "IFJ",
    "icao_code": "BIIS"
  },
  {
    "city": "Ishigaki",
    "country": "Japan",
    "IATA": "ISG",
    "icao_code": "ROIG"
  },
  {
    "city": "Ishurdi",
    "country": "Bangladesh",
    "IATA": "IRD",
    "icao_code": "VGIS"
  },
  {
    "city": "Isiro",
    "country": "Congo (Kinshasa)",
    "IATA": "IRP",
    "icao_code": "FZJH"
  },
  {
    "city": "Isla De Coche",
    "country": "Venezuela",
    "IATA": "ICC"
  },
  {
    "city": "Isla Mujeres",
    "country": "Mexico",
    "IATA": "ISJ",
    "icao_code": "MMIM"
  },
  {
    "city": "Isla Rey Jorge",
    "country": "Antarctica",
    "IATA": "TNM",
    "icao_code": "SCRM"
  },
  {
    "city": "Islamabad",
    "country": "Pakistan",
    "IATA": "ISB",
    "icao_code": "OPRN"
  },
  {
    "city": "Island Lake",
    "country": "Canada",
    "IATA": "YIV"
  },
  {
    "city": "Islay",
    "country": "United Kingdom",
    "IATA": "ILY",
    "icao_code": "EGPI"
  },
  {
    "city": "Isle Of Man",
    "country": "Isle of Man",
    "IATA": "IOM",
    "icao_code": "EGNS"
  },
  {
    "city": "Isparta",
    "country": "Turkey",
    "IATA": "ISE",
    "icao_code": "LTFC"
  },
  {
    "city": "Istanbul",
    "country": "Turkey",
    "IATA": "IST",
    "icao_code": "LTBA"
  },
  {
    "city": "Istanbul",
    "country": "Turkey",
    "IATA": "SAW",
    "icao_code": "LTFJ"
  },
  {
    "city": "Istanbul",
    "country": "Turkey",
    "IATA": "ISL"
  },
  {
    "city": "Itaituba",
    "country": "Brazil",
    "IATA": "ITB",
    "icao_code": "SBIH"
  },
  {
    "city": "Itaituba",
    "country": "Brazil",
    "IATA": "ITA",
    "icao_code": "SBIC"
  },
  {
    "city": "Itaperuna",
    "country": "Brazil",
    "IATA": "ITP"
  },
  {
    "city": "Ithaca",
    "country": "United States",
    "IATA": "ITH",
    "icao_code": "KITH"
  },
  {
    "city": "Ittoqqortoormiit",
    "country": "Greenland",
    "IATA": "OBY",
    "icao_code": "BGSC"
  },
  {
    "city": "Itumbiara",
    "country": "Brazil",
    "IATA": "ITR",
    "icao_code": "SBIT"
  },
  {
    "city": "Ivalo",
    "country": "Finland",
    "IATA": "IVL",
    "icao_code": "EFIV"
  },
  {
    "city": "Ivano-Frankivsk",
    "country": "Ukraine",
    "IATA": "IFO",
    "icao_code": "UKLI"
  },
  {
    "city": "Ivanovo",
    "country": "Russia",
    "IATA": "IWA",
    "icao_code": "UUBI"
  },
  {
    "city": "Ivujivik",
    "country": "Canada",
    "IATA": "YIK",
    "icao_code": "CYIK"
  },
  {
    "city": "Iwakuni",
    "country": "Japan",
    "IATA": "IWK"
  },
  {
    "city": "Iwami",
    "country": "Japan",
    "IATA": "IWJ",
    "icao_code": "RJOW"
  },
  {
    "city": "Iwojima",
    "country": "Japan",
    "IATA": "IWO",
    "icao_code": "RJAW"
  },
  {
    "city": "Izhevsk",
    "country": "Russia",
    "IATA": "IJK",
    "icao_code": "USII"
  },
  {
    "city": "Izmir",
    "country": "Turkey",
    "IATA": "IGL",
    "icao_code": "LTBL"
  },
  {
    "city": "Izmir",
    "country": "Turkey",
    "IATA": "ADB",
    "icao_code": "LTBJ"
  },
  {
    "city": "Iztepec",
    "country": "Mexico",
    "IATA": "IZT"
  },
  {
    "city": "Izumo",
    "country": "Japan",
    "IATA": "IZO",
    "icao_code": "RJOC"
  },
  {
    "city": "Jabalpur",
    "country": "India",
    "IATA": "JLR",
    "icao_code": "VAJB"
  },
  {
    "city": "Jabiru",
    "country": "Australia",
    "IATA": "JAB",
    "icao_code": "YJAB"
  },
  {
    "city": "Jacare-acanga",
    "country": "Brazil",
    "IATA": "JCR",
    "icao_code": "SBEK"
  },
  {
    "city": "Jacksn Hole",
    "country": "United States",
    "IATA": "JAC",
    "icao_code": "KJAC"
  },
  {
    "city": "Jackson",
    "country": "United States",
    "IATA": "JXN",
    "icao_code": "KJXN"
  },
  {
    "city": "Jackson",
    "country": "United States",
    "IATA": "MKL",
    "icao_code": "KMKL"
  },
  {
    "city": "Jackson",
    "country": "United States",
    "IATA": "JAN",
    "icao_code": "KJAN"
  },
  {
    "city": "Jacksonville",
    "country": "United States",
    "IATA": "CRG",
    "icao_code": "KCRG"
  },
  {
    "city": "Jacksonville",
    "country": "United States",
    "IATA": "VQQ"
  },
  {
    "city": "Jacksonville",
    "country": "United States",
    "IATA": "JAX",
    "icao_code": "KJAX"
  },
  {
    "city": "Jacksonville",
    "country": "United States",
    "IATA": "NIP",
    "icao_code": "KNIP"
  },
  {
    "city": "Jacksonville",
    "country": "United States",
    "IATA": "LRF",
    "icao_code": "KLRF"
  },
  {
    "city": "Jacksonville NC",
    "country": "United States",
    "IATA": "OAJ",
    "icao_code": "KOAJ"
  },
  {
    "city": "Jacmel",
    "country": "Haiti",
    "IATA": "JAK"
  },
  {
    "city": "Jacobsbad",
    "country": "Pakistan",
    "IATA": "JAG",
    "icao_code": "OPJA"
  },
  {
    "city": "Jaffna",
    "country": "Sri Lanka",
    "IATA": "JAF",
    "icao_code": "VCCJ"
  },
  {
    "city": "Jaguaruna",
    "country": "Brazil",
    "IATA": "JJG",
    "icao_code": "SBJA"
  },
  {
    "city": "Jahrom",
    "country": "Iran",
    "IATA": "JAR"
  },
  {
    "city": "Jaipur",
    "country": "India",
    "IATA": "JAI",
    "icao_code": "VIJP"
  },
  {
    "city": "Jaisalmer",
    "country": "India",
    "IATA": "JSA",
    "icao_code": "VIJR"
  },
  {
    "city": "Jakar",
    "country": "Bhutan",
    "IATA": "BUT",
    "icao_code": "VQBT"
  },
  {
    "city": "Jakarta",
    "country": "Indonesia",
    "IATA": "HLP",
    "icao_code": "WIIH"
  },
  {
    "city": "Jakarta",
    "country": "Indonesia",
    "IATA": "PCB",
    "icao_code": "WIHP"
  },
  {
    "city": "Jakarta",
    "country": "Indonesia",
    "IATA": "CGK",
    "icao_code": "WIII"
  },
  {
    "city": "Jalalabad",
    "country": "Afghanistan",
    "IATA": "JAA",
    "icao_code": "OAJL"
  },
  {
    "city": "Jalapa",
    "country": "Mexico",
    "IATA": "JAL",
    "icao_code": "MMJA"
  },
  {
    "city": "Jambi",
    "country": "Indonesia",
    "IATA": "DJB",
    "icao_code": "WIPA"
  },
  {
    "city": "Jamestown",
    "country": "United States",
    "IATA": "JHW",
    "icao_code": "KJHW"
  },
  {
    "city": "Jamestown",
    "country": "United States",
    "IATA": "JMS",
    "icao_code": "KJMS"
  },
  {
    "city": "Jammu",
    "country": "India",
    "IATA": "IXJ",
    "icao_code": "VIJU"
  },
  {
    "city": "Jamnagar",
    "country": "India",
    "IATA": "JGA",
    "icao_code": "VAJM"
  },
  {
    "city": "Jamshedpur",
    "country": "India",
    "IATA": "IXW",
    "icao_code": "VEJS"
  },
  {
    "city": "Janakpur",
    "country": "Nepal",
    "IATA": "JKR",
    "icao_code": "VNJP"
  },
  {
    "city": "Janesville",
    "country": "United States",
    "IATA": "JVL",
    "icao_code": "KJVL"
  },
  {
    "city": "Jaqué",
    "country": "Panama",
    "IATA": "JQE",
    "icao_code": "MPJE"
  },
  {
    "city": "Jask",
    "country": "Iran",
    "IATA": "JSK"
  },
  {
    "city": "Jasper",
    "country": "United States",
    "IATA": "APT"
  },
  {
    "city": "Jasper",
    "country": "United States",
    "IATA": "JAS"
  },
  {
    "city": "Jauja",
    "country": "Peru",
    "IATA": "JAU",
    "icao_code": "SPJJ"
  },
  {
    "city": "Jayapura",
    "country": "Indonesia",
    "IATA": "DJJ",
    "icao_code": "WAJJ"
  },
  {
    "city": "Jaén",
    "country": "Peru",
    "IATA": "JAE"
  },
  {
    "city": "Jeddah",
    "country": "Saudi Arabia",
    "IATA": "JED",
    "icao_code": "OEJN"
  },
  {
    "city": "Jefferson City",
    "country": "United States",
    "IATA": "JEF",
    "icao_code": "KJEF"
  },
  {
    "city": "Jenpeg",
    "country": "Canada",
    "IATA": "ZJG",
    "icao_code": "CZJG"
  },
  {
    "city": "Jeremie",
    "country": "Haiti",
    "IATA": "JEE",
    "icao_code": "MTJE"
  },
  {
    "city": "Jerez",
    "country": "Spain",
    "IATA": "XRY",
    "icao_code": "LEJR"
  },
  {
    "city": "Jersey",
    "country": "Jersey",
    "IATA": "JER",
    "icao_code": "EGJJ"
  },
  {
    "city": "Jessore",
    "country": "Bangladesh",
    "IATA": "JSR",
    "icao_code": "VGJR"
  },
  {
    "city": "Jeypore",
    "country": "India",
    "IATA": "PYB",
    "icao_code": "VEJP"
  },
  {
    "city": "Jhunju",
    "country": "South Korea",
    "IATA": "CHN",
    "icao_code": "RKJU"
  },
  {
    "city": "Ji-Paraná",
    "country": "Brazil",
    "IATA": "JPR",
    "icao_code": "SWJI"
  },
  {
    "city": "Jiagedaqi District",
    "country": "China",
    "IATA": "JGD"
  },
  {
    "city": "Jiamusi",
    "country": "China",
    "IATA": "JMU",
    "icao_code": "ZYJM"
  },
  {
    "city": "Jian",
    "country": "China",
    "IATA": "JGS",
    "icao_code": "ZSJA"
  },
  {
    "city": "Jiansanjiang",
    "country": "China",
    "IATA": "JSJ",
    "icao_code": "ZYJS"
  },
  {
    "city": "Jiayuguan",
    "country": "China",
    "IATA": "JGN",
    "icao_code": "ZLJQ"
  },
  {
    "city": "Jijel",
    "country": "Algeria",
    "IATA": "GJL",
    "icao_code": "DAAV"
  },
  {
    "city": "Jijiga",
    "country": "Ethiopia",
    "IATA": "JIJ",
    "icao_code": "HAJJ"
  },
  {
    "city": "Jimma",
    "country": "Ethiopia",
    "IATA": "JIM",
    "icao_code": "HAJM"
  },
  {
    "city": "Jinan",
    "country": "China",
    "IATA": "TNA",
    "icao_code": "ZSJN"
  },
  {
    "city": "Jinchuan",
    "country": "China",
    "IATA": "JIC"
  },
  {
    "city": "Jingdezhen",
    "country": "China",
    "IATA": "JDZ",
    "icao_code": "ZSJD"
  },
  {
    "city": "Jinghonggasa",
    "country": "China",
    "IATA": "JHG"
  },
  {
    "city": "Jining",
    "country": "China",
    "IATA": "JNG"
  },
  {
    "city": "Jinzhou",
    "country": "China",
    "IATA": "JNZ",
    "icao_code": "ZYJZ"
  },
  {
    "city": "Jiroft",
    "country": "Iran",
    "IATA": "JYR",
    "icao_code": "OIKJ"
  },
  {
    "city": "Jiujiang",
    "country": "China",
    "IATA": "JIU",
    "icao_code": "ZSJJ"
  },
  {
    "city": "Jiuzhaigou",
    "country": "China",
    "IATA": "JZH",
    "icao_code": "ZUJZ"
  },
  {
    "city": "Jiwani",
    "country": "Pakistan",
    "IATA": "JIW",
    "icao_code": "OPJI"
  },
  {
    "city": "Jixi",
    "country": "China",
    "IATA": "JXA",
    "icao_code": "ZYJX"
  },
  {
    "city": "Joacaba",
    "country": "Brazil",
    "IATA": "JCB",
    "icao_code": "SSJA"
  },
  {
    "city": "Joao Pessoa",
    "country": "Brazil",
    "IATA": "JPA",
    "icao_code": "SBJP"
  },
  {
    "city": "Jodhpur",
    "country": "India",
    "IATA": "JDH",
    "icao_code": "VIJO"
  },
  {
    "city": "Joenkoeping",
    "country": "Sweden",
    "IATA": "JKG",
    "icao_code": "ESGJ"
  },
  {
    "city": "Joensuu",
    "country": "Finland",
    "IATA": "JOE",
    "icao_code": "EFJO"
  },
  {
    "city": "Johannesburg",
    "country": "South Africa",
    "IATA": "QRA",
    "icao_code": "FAGM"
  },
  {
    "city": "Johannesburg",
    "country": "South Africa",
    "IATA": "HLA",
    "icao_code": "FALA"
  },
  {
    "city": "Johannesburg",
    "country": "South Africa",
    "IATA": "JNB",
    "icao_code": "FAJS"
  },
  {
    "city": "Johannesburg",
    "country": "South Africa",
    "IATA": "GCJ",
    "icao_code": "FAGC"
  },
  {
    "city": "Johnston Island",
    "country": "Johnston Atoll",
    "IATA": "JON"
  },
  {
    "city": "Johnstown",
    "country": "United States",
    "IATA": "JST",
    "icao_code": "KJST"
  },
  {
    "city": "Johor Bahru",
    "country": "Malaysia",
    "IATA": "JHB",
    "icao_code": "WMKJ"
  },
  {
    "city": "Joinville",
    "country": "Brazil",
    "IATA": "JOI",
    "icao_code": "SBJV"
  },
  {
    "city": "Joliet",
    "country": "United States",
    "IATA": "JOT",
    "icao_code": "KJOT"
  },
  {
    "city": "Jolo",
    "country": "Philippines",
    "IATA": "JOL"
  },
  {
    "city": "Jomsom",
    "country": "Nepal",
    "IATA": "JMO",
    "icao_code": "VNJS"
  },
  {
    "city": "Jonesboro",
    "country": "United States",
    "IATA": "JBR",
    "icao_code": "KJBR"
  },
  {
    "city": "Joplin",
    "country": "United States",
    "IATA": "JLN",
    "icao_code": "KJLN"
  },
  {
    "city": "Jorhat",
    "country": "India",
    "IATA": "JRH",
    "icao_code": "VEJT"
  },
  {
    "city": "Jos",
    "country": "Nigeria",
    "IATA": "JOS",
    "icao_code": "DNJO"
  },
  {
    "city": "Jose de San Martin",
    "country": "Argentina",
    "IATA": "JSM",
    "icao_code": "SAWS"
  },
  {
    "city": "Juanjui",
    "country": "Peru",
    "IATA": "JJI",
    "icao_code": "SPJI"
  },
  {
    "city": "Juara",
    "country": "Brazil",
    "IATA": "JUA",
    "icao_code": "SIZX"
  },
  {
    "city": "Juazeiro Do Norte",
    "country": "Brazil",
    "IATA": "JDO",
    "icao_code": "SNQY"
  },
  {
    "city": "Juba",
    "country": "South Sudan",
    "IATA": "JUB",
    "icao_code": "HSSJ"
  },
  {
    "city": "Jubail",
    "country": "Saudi Arabia",
    "IATA": "QJB"
  },
  {
    "city": "Juina",
    "country": "Brazil",
    "IATA": "JIA",
    "icao_code": "SWJN"
  },
  {
    "city": "Juist",
    "country": "Germany",
    "IATA": "JUI",
    "icao_code": "EDWJ"
  },
  {
    "city": "Juiz De Fora",
    "country": "Brazil",
    "IATA": "JDF",
    "icao_code": "SBJF"
  },
  {
    "city": "Juiz de Fora",
    "country": "Brazil",
    "IATA": "IZA",
    "icao_code": "SDZY"
  },
  {
    "city": "Jujuy",
    "country": "Argentina",
    "IATA": "JUJ",
    "icao_code": "SASJ"
  },
  {
    "city": "Julia Creek",
    "country": "Australia",
    "IATA": "JCK",
    "icao_code": "YJLC"
  },
  {
    "city": "Juliaca",
    "country": "Peru",
    "IATA": "JUL",
    "icao_code": "SPJL"
  },
  {
    "city": "Jumla",
    "country": "Nepal",
    "IATA": "JUM",
    "icao_code": "VNJL"
  },
  {
    "city": "Junction",
    "country": "United States",
    "IATA": "JCT"
  },
  {
    "city": "Juneau",
    "country": "United States",
    "IATA": "JNU",
    "icao_code": "PAJN"
  },
  {
    "city": "Junin",
    "country": "Argentina",
    "IATA": "JNI",
    "icao_code": "SAAJ"
  },
  {
    "city": "Juruena",
    "country": "Brazil",
    "IATA": "JRN",
    "icao_code": "SWJU"
  },
  {
    "city": "Jwaneng",
    "country": "Botswana",
    "IATA": "JWA",
    "icao_code": "FBJW"
  },
  {
    "city": "Jyvaskyla",
    "country": "Finland",
    "IATA": "JYV",
    "icao_code": "EFJY"
  },
  {
    "city": "Kaadedhdhoo",
    "country": "Maldives",
    "IATA": "KDM",
    "icao_code": "VRMT"
  },
  {
    "city": "Kabinda",
    "country": "Congo (Kinshasa)",
    "IATA": "KBN",
    "icao_code": "FZWT"
  },
  {
    "city": "Kabri Dehar",
    "country": "Ethiopia",
    "IATA": "ABK",
    "icao_code": "HADK"
  },
  {
    "city": "Kabul",
    "country": "Afghanistan",
    "IATA": "OAI",
    "icao_code": "OAIX"
  },
  {
    "city": "Kabul",
    "country": "Afghanistan",
    "IATA": "KBL",
    "icao_code": "OAKB"
  },
  {
    "city": "Kadanwari",
    "country": "Pakistan",
    "IATA": "KCF",
    "icao_code": "OPKW"
  },
  {
    "city": "Kadena",
    "country": "Japan",
    "IATA": "DNA",
    "icao_code": "RODN"
  },
  {
    "city": "Kadugli",
    "country": "Sudan",
    "IATA": "KDX"
  },
  {
    "city": "Kaduna",
    "country": "Nigeria",
    "IATA": "KAD",
    "icao_code": "DNKA"
  },
  {
    "city": "Kaedi",
    "country": "Mauritania",
    "IATA": "KED",
    "icao_code": "GQNK"
  },
  {
    "city": "Kagau Island",
    "country": "Solomon Islands",
    "IATA": "KGE",
    "icao_code": "AGKG"
  },
  {
    "city": "Kagi",
    "country": "Papua New Guinea",
    "IATA": "KGW"
  },
  {
    "city": "Kagoshima",
    "country": "Japan",
    "IATA": "KOJ",
    "icao_code": "RJFK"
  },
  {
    "city": "Kahramanmaras",
    "country": "Turkey",
    "IATA": "KCM",
    "icao_code": "LTCN"
  },
  {
    "city": "Kahului",
    "country": "United States",
    "IATA": "OGG",
    "icao_code": "PHOG"
  },
  {
    "city": "Kaieteur",
    "country": "Guyana",
    "IATA": "KAI",
    "icao_code": "PKSA"
  },
  {
    "city": "Kaikoura",
    "country": "New Zealand",
    "IATA": "KBZ",
    "icao_code": "NZKI"
  },
  {
    "city": "Kailashahar",
    "country": "India",
    "IATA": "IXH",
    "icao_code": "VEKR"
  },
  {
    "city": "Kaili",
    "country": "China",
    "IATA": "KJH",
    "icao_code": "ZUKJ"
  },
  {
    "city": "Kaimana",
    "country": "Indonesia",
    "IATA": "KNG",
    "icao_code": "WASK"
  },
  {
    "city": "Kaiser Lake Ozark",
    "country": "United States",
    "IATA": "AIZ",
    "icao_code": "KAIZ"
  },
  {
    "city": "Kaitaia",
    "country": "New Zealand",
    "IATA": "KAT",
    "icao_code": "NZKT"
  },
  {
    "city": "Kajaani",
    "country": "Finland",
    "IATA": "KAJ",
    "icao_code": "EFKI"
  },
  {
    "city": "Kakamega",
    "country": "Kenya",
    "IATA": "GGM",
    "icao_code": "HKKG"
  },
  {
    "city": "Kalaleh",
    "country": "Iran",
    "IATA": "KLM"
  },
  {
    "city": "Kalamata",
    "country": "Greece",
    "IATA": "KLX",
    "icao_code": "LGKL"
  },
  {
    "city": "Kalamazoo",
    "country": "United States",
    "IATA": "AZO",
    "icao_code": "KAZO"
  },
  {
    "city": "Kalbarri",
    "country": "Australia",
    "IATA": "KAX",
    "icao_code": "YKBR"
  },
  {
    "city": "Kalemie",
    "country": "Congo (Kinshasa)",
    "IATA": "FMI",
    "icao_code": "FZRF"
  },
  {
    "city": "Kalemyo",
    "country": "Myanmar",
    "IATA": "KMV",
    "icao_code": "VYKL"
  },
  {
    "city": "Kalgoorlie",
    "country": "Australia",
    "IATA": "KGI",
    "icao_code": "YPKG"
  },
  {
    "city": "Kalibo",
    "country": "Philippines",
    "IATA": "KLO",
    "icao_code": "RPVK"
  },
  {
    "city": "Kaliningrad",
    "country": "Russia",
    "IATA": "KGD",
    "icao_code": "UMKK"
  },
  {
    "city": "Kalispell",
    "country": "United States",
    "IATA": "FCA",
    "icao_code": "KGPI"
  },
  {
    "city": "Kalkgurung",
    "country": "Australia",
    "IATA": "KFG",
    "icao_code": "YKKG"
  },
  {
    "city": "Kalkmar",
    "country": "Sweden",
    "IATA": "KLR",
    "icao_code": "ESMQ"
  },
  {
    "city": "Kalskag",
    "country": "United States",
    "IATA": "KLG",
    "icao_code": "PALG"
  },
  {
    "city": "Kaltag",
    "country": "United States",
    "IATA": "KAL",
    "icao_code": "PAKV"
  },
  {
    "city": "Kaluga",
    "country": "Russia",
    "IATA": "KLF",
    "icao_code": "UUBC"
  },
  {
    "city": "Kalymnos",
    "country": "Greece",
    "IATA": "JKL",
    "icao_code": "LGKY"
  },
  {
    "city": "Kamarang",
    "country": "Guyana",
    "IATA": "KAR",
    "icao_code": "SYKM"
  },
  {
    "city": "Kamembe",
    "country": "Rwanda",
    "IATA": "KME",
    "icao_code": "HRZA"
  },
  {
    "city": "Kamina Base",
    "country": "Congo (Kinshasa)",
    "IATA": "KMN",
    "icao_code": "FZSA"
  },
  {
    "city": "Kamishly",
    "country": "Syria",
    "IATA": "KAC",
    "icao_code": "OSKL"
  },
  {
    "city": "Kamloops",
    "country": "Canada",
    "IATA": "AMN",
    "icao_code": "KAMN"
  },
  {
    "city": "Kamloops",
    "country": "Canada",
    "IATA": "YKA",
    "icao_code": "CYKA"
  },
  {
    "city": "Kamra",
    "country": "Pakistan",
    "IATA": "ATG"
  },
  {
    "city": "Kamuela",
    "country": "United States",
    "IATA": "MUE",
    "icao_code": "PHMU"
  },
  {
    "city": "Kananga",
    "country": "Congo (Kinshasa)",
    "IATA": "KGA",
    "icao_code": "FZUA"
  },
  {
    "city": "Kanazawa",
    "country": "Japan",
    "IATA": "KMQ",
    "icao_code": "RJNK"
  },
  {
    "city": "Kandahar",
    "country": "Afghanistan",
    "IATA": "KDH",
    "icao_code": "OAKN"
  },
  {
    "city": "Kandi",
    "country": "Benin",
    "IATA": "KDC",
    "icao_code": "DBBK"
  },
  {
    "city": "Kandla",
    "country": "India",
    "IATA": "IXY",
    "icao_code": "VAKE"
  },
  {
    "city": "Kaneohe Bay",
    "country": "United States",
    "IATA": "NGF",
    "icao_code": "PHNG"
  },
  {
    "city": "Kangaatsiaq",
    "country": "Greenland",
    "IATA": "QPW"
  },
  {
    "city": "Kangan",
    "country": "Iran",
    "IATA": "KNR"
  },
  {
    "city": "Kangding",
    "country": "China",
    "IATA": "KGT",
    "icao_code": "ZUKD"
  },
  {
    "city": "Kangiqsualujjuaq",
    "country": "Canada",
    "IATA": "XGR",
    "icao_code": "CYLU"
  },
  {
    "city": "Kangiqsujuaq",
    "country": "Canada",
    "IATA": "YWB",
    "icao_code": "CYKG"
  },
  {
    "city": "Kangirsuk",
    "country": "Canada",
    "IATA": "YKG",
    "icao_code": "CYAS"
  },
  {
    "city": "Kangnung",
    "country": "South Korea",
    "IATA": "KAG",
    "icao_code": "RKNN"
  },
  {
    "city": "Kangra",
    "country": "India",
    "IATA": "DHM",
    "icao_code": "VIGG"
  },
  {
    "city": "Kankakee",
    "country": "United States",
    "IATA": "IKK",
    "icao_code": "KIKK"
  },
  {
    "city": "Kannur",
    "country": "India",
    "IATA": "CNN",
    "icao_code": "VOKN"
  },
  {
    "city": "Kano",
    "country": "Nigeria",
    "IATA": "KAN",
    "icao_code": "DNKN"
  },
  {
    "city": "Kanpur",
    "country": "India",
    "IATA": "KNU",
    "icao_code": "VIKA"
  },
  {
    "city": "Kansas City",
    "country": "United States",
    "IATA": "MKC",
    "icao_code": "KMKC"
  },
  {
    "city": "Kansas City",
    "country": "United States",
    "IATA": "MCI",
    "icao_code": "KMCI"
  },
  {
    "city": "Kao",
    "country": "Indonesia",
    "IATA": "KAZ",
    "icao_code": "WAMK"
  },
  {
    "city": "Kaohsiung",
    "country": "Taiwan",
    "IATA": "KHH",
    "icao_code": "RCKH"
  },
  {
    "city": "Kaolack",
    "country": "Senegal",
    "IATA": "KLC",
    "icao_code": "GOOK"
  },
  {
    "city": "Kapanda",
    "country": "Angola",
    "IATA": "KNP",
    "icao_code": "FNCP"
  },
  {
    "city": "Kapolei",
    "country": "United States",
    "IATA": "JRF"
  },
  {
    "city": "Kapuskasing",
    "country": "Canada",
    "IATA": "YYU",
    "icao_code": "CYYU"
  },
  {
    "city": "Karachi",
    "country": "Pakistan",
    "IATA": "KHI",
    "icao_code": "OPKC"
  },
  {
    "city": "Karaganda",
    "country": "Kazakhstan",
    "IATA": "KGF",
    "icao_code": "UAKK"
  },
  {
    "city": "Karaj",
    "country": "Iran",
    "IATA": "PYK"
  },
  {
    "city": "Karamay",
    "country": "China",
    "IATA": "KRY",
    "icao_code": "ZWKM"
  },
  {
    "city": "Kardla",
    "country": "Estonia",
    "IATA": "KDL",
    "icao_code": "EEKA"
  },
  {
    "city": "Kariba",
    "country": "Zimbabwe",
    "IATA": "KAB",
    "icao_code": "FVKB"
  },
  {
    "city": "Karimunjawa",
    "country": "Indonesia",
    "IATA": "KWB",
    "icao_code": "WICM"
  },
  {
    "city": "Karlovy Vary",
    "country": "Czech Republic",
    "IATA": "KLV",
    "icao_code": "LKKV"
  },
  {
    "city": "Karlskoga",
    "country": "Sweden",
    "IATA": "KSK"
  },
  {
    "city": "Karlsruhe/Baden-Baden",
    "country": "Germany",
    "IATA": "FKB",
    "icao_code": "EDSB"
  },
  {
    "city": "Karlstad",
    "country": "Sweden",
    "IATA": "KSD",
    "icao_code": "ESOK"
  },
  {
    "city": "Karluk",
    "country": "United States",
    "IATA": "KYK",
    "icao_code": "PAKY"
  },
  {
    "city": "Karonga",
    "country": "Malawi",
    "IATA": "KGJ",
    "icao_code": "FWKA"
  },
  {
    "city": "Karpathos",
    "country": "Greece",
    "IATA": "AOK",
    "icao_code": "LGKP"
  },
  {
    "city": "Karratha",
    "country": "Australia",
    "IATA": "KTA",
    "icao_code": "YPKA"
  },
  {
    "city": "Kars",
    "country": "Turkey",
    "IATA": "KSY",
    "icao_code": "LTCF"
  },
  {
    "city": "Karumba",
    "country": "Australia",
    "IATA": "KRB",
    "icao_code": "YKMB"
  },
  {
    "city": "Karup",
    "country": "Denmark",
    "IATA": "KRP",
    "icao_code": "EKKA"
  },
  {
    "city": "Kasaba Bay",
    "country": "Zambia",
    "IATA": "ZKB",
    "icao_code": "FLKY"
  },
  {
    "city": "Kasabonika",
    "country": "Canada",
    "IATA": "XKS",
    "icao_code": "CYAQ"
  },
  {
    "city": "Kasama",
    "country": "Zambia",
    "IATA": "KAA",
    "icao_code": "FLKS"
  },
  {
    "city": "Kasane",
    "country": "Botswana",
    "IATA": "BBK",
    "icao_code": "FBKE"
  },
  {
    "city": "Kasese",
    "country": "Uganda",
    "IATA": "KSE",
    "icao_code": "HUKS"
  },
  {
    "city": "Kashan",
    "country": "Iran",
    "IATA": "KKS"
  },
  {
    "city": "Kashechewan",
    "country": "Canada",
    "IATA": "ZKE",
    "icao_code": "CZKE"
  },
  {
    "city": "Kashi",
    "country": "China",
    "IATA": "KHG",
    "icao_code": "ZWSH"
  },
  {
    "city": "Kasigluk",
    "country": "United States",
    "IATA": "KUK",
    "icao_code": "PFKA"
  },
  {
    "city": "Kasompe",
    "country": "Zambia",
    "IATA": "CGJ"
  },
  {
    "city": "Kasos",
    "country": "Greece",
    "IATA": "KSJ",
    "icao_code": "LGKS"
  },
  {
    "city": "Kassala",
    "country": "Sudan",
    "IATA": "KSL",
    "icao_code": "HSKA"
  },
  {
    "city": "Kassel",
    "country": "Germany",
    "IATA": "KSF",
    "icao_code": "EDVK"
  },
  {
    "city": "Kastamonu",
    "country": "Turkey",
    "IATA": "KFS",
    "icao_code": "LTAL"
  },
  {
    "city": "Kastelorizo",
    "country": "Greece",
    "IATA": "KZS",
    "icao_code": "LGKJ"
  },
  {
    "city": "Kastoria",
    "country": "Greece",
    "IATA": "KSO",
    "icao_code": "LGKA"
  },
  {
    "city": "Kasungu",
    "country": "Malawi",
    "IATA": "KBQ",
    "icao_code": "FWKG"
  },
  {
    "city": "Katherine",
    "country": "Australia",
    "IATA": "KTR",
    "icao_code": "YPTN"
  },
  {
    "city": "Kathmandu",
    "country": "Nepal",
    "IATA": "KTM",
    "icao_code": "VNKT"
  },
  {
    "city": "Katowice",
    "country": "Poland",
    "IATA": "KTW",
    "icao_code": "EPKT"
  },
  {
    "city": "Kauehi",
    "country": "French Polynesia",
    "IATA": "KHZ",
    "icao_code": "NTKA"
  },
  {
    "city": "Kauhajoki",
    "country": "Finland",
    "IATA": "KHJ",
    "icao_code": "EFKJ"
  },
  {
    "city": "Kauhava",
    "country": "Finland",
    "IATA": "KAU",
    "icao_code": "EFKA"
  },
  {
    "city": "Kaukura Atoll",
    "country": "French Polynesia",
    "IATA": "KKR",
    "icao_code": "NTGK"
  },
  {
    "city": "Kaunas",
    "country": "Lithuania",
    "IATA": "KUN",
    "icao_code": "EYKA"
  },
  {
    "city": "Kautokeino",
    "country": "Norway",
    "IATA": "QKX"
  },
  {
    "city": "Kavala",
    "country": "Greece",
    "IATA": "KVA",
    "icao_code": "LGKV"
  },
  {
    "city": "Kavalerovo",
    "country": "Russia",
    "IATA": "KVR"
  },
  {
    "city": "Kavieng",
    "country": "Papua New Guinea",
    "IATA": "KVG",
    "icao_code": "AYKV"
  },
  {
    "city": "Kawama",
    "country": "Cuba",
    "IATA": "VRO"
  },
  {
    "city": "Kawthoung",
    "country": "Burma",
    "IATA": "KAW",
    "icao_code": "VYKT"
  },
  {
    "city": "Kayes",
    "country": "Mali",
    "IATA": "KYS",
    "icao_code": "GAKY"
  },
  {
    "city": "Kayseri",
    "country": "Turkey",
    "IATA": "ASR",
    "icao_code": "LTAU"
  },
  {
    "city": "Kazan",
    "country": "Russia",
    "IATA": "KZN",
    "icao_code": "UWKD"
  },
  {
    "city": "Kedougou",
    "country": "Senegal",
    "IATA": "KGG",
    "icao_code": "GOTK"
  },
  {
    "city": "Keene",
    "country": "United States",
    "IATA": "EEN",
    "icao_code": "KEEN"
  },
  {
    "city": "Keetmanshoop",
    "country": "Namibia",
    "IATA": "KMP",
    "icao_code": "FYKT"
  },
  {
    "city": "Keewaywin",
    "country": "Canada",
    "IATA": "KEW",
    "icao_code": "CPV8"
  },
  {
    "city": "Keffallinia",
    "country": "Greece",
    "IATA": "EFL",
    "icao_code": "LGKF"
  },
  {
    "city": "Keflavik",
    "country": "Iceland",
    "IATA": "KEF",
    "icao_code": "BIKF"
  },
  {
    "city": "Kegaska",
    "country": "Canada",
    "IATA": "ZKG",
    "icao_code": "CTK6"
  },
  {
    "city": "Kelowna",
    "country": "Canada",
    "IATA": "YLW",
    "icao_code": "CYLW"
  },
  {
    "city": "Kelsey",
    "country": "Canada",
    "IATA": "KES",
    "icao_code": "CZEE"
  },
  {
    "city": "Kelso",
    "country": "United States",
    "IATA": "KLS",
    "icao_code": "KKLS"
  },
  {
    "city": "Kemi",
    "country": "Finland",
    "IATA": "KEM",
    "icao_code": "EFKE"
  },
  {
    "city": "Kemorovo",
    "country": "Russia",
    "IATA": "KEJ",
    "icao_code": "UNEE"
  },
  {
    "city": "Kempten",
    "country": "Germany",
    "IATA": "EMP"
  },
  {
    "city": "Kenai",
    "country": "United States",
    "IATA": "ENA",
    "icao_code": "PAEN"
  },
  {
    "city": "South Florida, Kendall-tamiami",
    "country": "United States",
    "IATA": "TMB",
    "icao_code": "KTMB"
  },
  {
    "city": "Kendari",
    "country": "Indonesia",
    "IATA": "KDI",
    "icao_code": "WAAU"
  },
  {
    "city": "Kenema",
    "country": "Sierra Leone",
    "IATA": "KEN",
    "icao_code": "GFKE"
  },
  {
    "city": "Kengtung",
    "country": "Burma",
    "IATA": "KET",
    "icao_code": "VYKG"
  },
  {
    "city": "Kenora",
    "country": "Canada",
    "IATA": "YQK",
    "icao_code": "CYQK"
  },
  {
    "city": "Kenosha",
    "country": "United States",
    "IATA": "ENW",
    "icao_code": "KENW"
  },
  {
    "city": "Kentira",
    "country": "Morocco",
    "IATA": "NNA",
    "icao_code": "GMMY"
  },
  {
    "city": "Keokuk",
    "country": "United States",
    "IATA": "EOK",
    "icao_code": "KEOK"
  },
  {
    "city": "Kerama",
    "country": "Japan",
    "IATA": "KJP",
    "icao_code": "ROKR"
  },
  {
    "city": "Kerch",
    "country": "Ukraine",
    "IATA": "KHC",
    "icao_code": "UKFK"
  },
  {
    "city": "Kerema",
    "country": "Papua New Guinea",
    "IATA": "KMA",
    "icao_code": "AYKM"
  },
  {
    "city": "Kericho",
    "country": "Kenya",
    "IATA": "KEY",
    "icao_code": "HKKR"
  },
  {
    "city": "Kerikeri",
    "country": "New Zealand",
    "IATA": "KKE",
    "icao_code": "NZKK"
  },
  {
    "city": "Kerinci Regency",
    "country": "Indonesia",
    "IATA": "KRC",
    "icao_code": "WIPH"
  },
  {
    "city": "Kerkyra/corfu",
    "country": "Greece",
    "IATA": "CFU",
    "icao_code": "LGKR"
  },
  {
    "city": "Kerman",
    "country": "Iran",
    "IATA": "KER",
    "icao_code": "OIKK"
  },
  {
    "city": "Kerrville",
    "country": "United States",
    "IATA": "ERV"
  },
  {
    "city": "Kerry",
    "country": "Ireland",
    "IATA": "KIR",
    "icao_code": "EIKY"
  },
  {
    "city": "Kerteh",
    "country": "Malaysia",
    "IATA": "KTE",
    "icao_code": "WMKE"
  },
  {
    "city": "Keshod",
    "country": "India",
    "IATA": "IXK",
    "icao_code": "VAKS"
  },
  {
    "city": "Ketapang",
    "country": "Indonesia",
    "IATA": "KTG",
    "icao_code": "WIOK"
  },
  {
    "city": "Ketchikan",
    "country": "United States",
    "IATA": "KTN",
    "icao_code": "PAKT"
  },
  {
    "city": "Key Lake",
    "country": "Canada",
    "IATA": "YKJ"
  },
  {
    "city": "Key West",
    "country": "United States",
    "IATA": "EYW",
    "icao_code": "KEYW"
  },
  {
    "city": "Key West",
    "country": "United States",
    "IATA": "NQX"
  },
  {
    "city": "Khabarovsk",
    "country": "Russia",
    "IATA": "KHV",
    "icao_code": "UHHH"
  },
  {
    "city": "Khajuraho",
    "country": "India",
    "IATA": "HJR",
    "icao_code": "VAKJ"
  },
  {
    "city": "Khalije Fars",
    "country": "Iran",
    "IATA": "PGU",
    "icao_code": "OIBP"
  },
  {
    "city": "Khamti",
    "country": "Burma",
    "IATA": "KHM",
    "icao_code": "VYKI"
  },
  {
    "city": "Khanabad",
    "country": "Uzbekistan",
    "IATA": "KSQ",
    "icao_code": "UTSL"
  },
  {
    "city": "Khandyga",
    "country": "Russia",
    "IATA": "KDY"
  },
  {
    "city": "Khanty-Mansiysk",
    "country": "Russia",
    "IATA": "HMA",
    "icao_code": "USHH"
  },
  {
    "city": "Khark Island",
    "country": "Iran",
    "IATA": "KHK"
  },
  {
    "city": "Kharkov",
    "country": "Ukraine",
    "IATA": "HRK",
    "icao_code": "UKHH"
  },
  {
    "city": "Khartoum",
    "country": "Sudan",
    "IATA": "KRT",
    "icao_code": "HSSS"
  },
  {
    "city": "Khasab",
    "country": "Oman",
    "IATA": "KHS",
    "icao_code": "OOKB"
  },
  {
    "city": "Khatanga",
    "country": "Russia",
    "IATA": "HTG",
    "icao_code": "UOHH"
  },
  {
    "city": "Kherson",
    "country": "Ukraine",
    "IATA": "KHE",
    "icao_code": "UKOH"
  },
  {
    "city": "Khmeinitskiy",
    "country": "Ukraine",
    "IATA": "HMJ",
    "icao_code": "UKLH"
  },
  {
    "city": "Khon Kaen",
    "country": "Thailand",
    "IATA": "KKC",
    "icao_code": "VTUK"
  },
  {
    "city": "Khorram Abad",
    "country": "Iran",
    "IATA": "KHD",
    "icao_code": "OICK"
  },
  {
    "city": "Khost",
    "country": "Afghanistan",
    "IATA": "KHT"
  },
  {
    "city": "Khovd",
    "country": "Mongolia",
    "IATA": "HVD",
    "icao_code": "ZMKD"
  },
  {
    "city": "Khoy",
    "country": "Iran",
    "IATA": "KHY",
    "icao_code": "OITK"
  },
  {
    "city": "Khudzhand",
    "country": "Tajikistan",
    "IATA": "LBD",
    "icao_code": "UTDL"
  },
  {
    "city": "Khuzdar",
    "country": "Pakistan",
    "IATA": "KDD",
    "icao_code": "OPKH"
  },
  {
    "city": "Khwai River",
    "country": "Botswana",
    "IATA": "KHW",
    "icao_code": "FBKR"
  },
  {
    "city": "Kiana",
    "country": "United States",
    "IATA": "IAN",
    "icao_code": "PAIK"
  },
  {
    "city": "Kiel",
    "country": "Germany",
    "IATA": "KEL",
    "icao_code": "EDHK"
  },
  {
    "city": "Kieta",
    "country": "Papua New Guinea",
    "IATA": "KZF"
  },
  {
    "city": "Kiev",
    "country": "Ukraine",
    "IATA": "GML",
    "icao_code": "UKKM"
  },
  {
    "city": "Kiev",
    "country": "Ukraine",
    "IATA": "IEV",
    "icao_code": "UKKK"
  },
  {
    "city": "Kiev",
    "country": "Ukraine",
    "IATA": "KBP",
    "icao_code": "UKBB"
  },
  {
    "city": "Kiffa",
    "country": "Mauritania",
    "IATA": "KFA",
    "icao_code": "GQNF"
  },
  {
    "city": "Kigali",
    "country": "Rwanda",
    "IATA": "KGL",
    "icao_code": "HRYR"
  },
  {
    "city": "Kigoma",
    "country": "Tanzania",
    "IATA": "TKQ",
    "icao_code": "HTKA"
  },
  {
    "city": "Kikai",
    "country": "Japan",
    "IATA": "KKX",
    "icao_code": "RJKI"
  },
  {
    "city": "Kikori",
    "country": "Papua New Guinea",
    "IATA": "KRI",
    "icao_code": "AYKK"
  },
  {
    "city": "Kikwit",
    "country": "Congo (Kinshasa)",
    "IATA": "KKW",
    "icao_code": "FZCA"
  },
  {
    "city": "Kilaguni",
    "country": "Kenya",
    "IATA": "ILU",
    "icao_code": "HKKL"
  },
  {
    "city": "Kili Island",
    "country": "Marshall Islands",
    "IATA": "KIO"
  },
  {
    "city": "Kilimanjaro",
    "country": "Tanzania",
    "IATA": "JRO",
    "icao_code": "HTKJ"
  },
  {
    "city": "Kill Devil Hills",
    "country": "United States",
    "IATA": "FFA",
    "icao_code": "KFFA"
  },
  {
    "city": "Killeen",
    "country": "United States",
    "IATA": "GRK",
    "icao_code": "KGRK"
  },
  {
    "city": "Kilwa Masoko",
    "country": "Tanzania",
    "IATA": "KIY",
    "icao_code": "HTKI"
  },
  {
    "city": "Kimberley",
    "country": "South Africa",
    "IATA": "KIM",
    "icao_code": "FAKM"
  },
  {
    "city": "Kimmirut",
    "country": "Canada",
    "IATA": "YLC",
    "icao_code": "CYLC"
  },
  {
    "city": "Kindersley",
    "country": "Canada",
    "IATA": "YKY",
    "icao_code": "CYKY"
  },
  {
    "city": "Kindu",
    "country": "Congo (Kinshasa)",
    "IATA": "KND",
    "icao_code": "FZOA"
  },
  {
    "city": "King Cove",
    "country": "United States",
    "IATA": "KVC",
    "icao_code": "PAVC"
  },
  {
    "city": "King Island",
    "country": "Australia",
    "IATA": "KNS",
    "icao_code": "YKII"
  },
  {
    "city": "King Khalid Mil.city",
    "country": "Saudi Arabia",
    "IATA": "KMC",
    "icao_code": "OEKK"
  },
  {
    "city": "King Salmon",
    "country": "United States",
    "IATA": "AKN",
    "icao_code": "PAKN"
  },
  {
    "city": "Kingfisher Lake",
    "country": "Canada",
    "IATA": "KIF",
    "icao_code": "CNM5"
  },
  {
    "city": "Kingscote",
    "country": "Australia",
    "IATA": "KGC",
    "icao_code": "YKSC"
  },
  {
    "city": "Kingston",
    "country": "Jamaica",
    "IATA": "KTP"
  },
  {
    "city": "Kingston",
    "country": "Jamaica",
    "IATA": "KIN",
    "icao_code": "MKJP"
  },
  {
    "city": "Kingston",
    "country": "Canada",
    "IATA": "YGK",
    "icao_code": "CYGK"
  },
  {
    "city": "Kingstown",
    "country": "Saint Vincent and the Grenadines",
    "IATA": "SVD",
    "icao_code": "TVSV"
  },
  {
    "city": "Kingsville",
    "country": "United States",
    "IATA": "NQI",
    "icao_code": "KNQI"
  },
  {
    "city": "Kinloss",
    "country": "United Kingdom",
    "IATA": "FSS",
    "icao_code": "EGQK"
  },
  {
    "city": "Kinmen",
    "country": "Taiwan",
    "IATA": "KNH",
    "icao_code": "RCBS"
  },
  {
    "city": "Kinshasa",
    "country": "Congo (Kinshasa)",
    "IATA": "NLO",
    "icao_code": "FZAB"
  },
  {
    "city": "Kinshasa",
    "country": "Congo (Kinshasa)",
    "IATA": "FIH",
    "icao_code": "FZAA"
  },
  {
    "city": "Kinston",
    "country": "United States",
    "IATA": "ISO"
  },
  {
    "city": "Kipnuk",
    "country": "United States",
    "IATA": "KPN",
    "icao_code": "PAKI"
  },
  {
    "city": "Kirakira",
    "country": "Solomon Islands",
    "IATA": "IRA",
    "icao_code": "AGGK"
  },
  {
    "city": "Kirensk",
    "country": "Russia",
    "IATA": "KCK"
  },
  {
    "city": "Kiri",
    "country": "Congo (Kinshasa)",
    "IATA": "KRZ",
    "icao_code": "FZBT"
  },
  {
    "city": "Kiritimati",
    "country": "Kiribati",
    "IATA": "CXI",
    "icao_code": "PLCH"
  },
  {
    "city": "Kirkenes",
    "country": "Norway",
    "IATA": "KKN",
    "icao_code": "ENKR"
  },
  {
    "city": "Kirkland Lake",
    "country": "Canada",
    "IATA": "YKX",
    "icao_code": "CYKX"
  },
  {
    "city": "Kirksville",
    "country": "United States",
    "IATA": "IRK",
    "icao_code": "KIRK"
  },
  {
    "city": "Kirkuk",
    "country": "Iraq",
    "IATA": "KIK",
    "icao_code": "ORKK"
  },
  {
    "city": "Kirkwall",
    "country": "United Kingdom",
    "IATA": "KOI",
    "icao_code": "EGPA"
  },
  {
    "city": "Kirov",
    "country": "Russia",
    "IATA": "KVX",
    "icao_code": "USKK"
  },
  {
    "city": "Kirovograd",
    "country": "Ukraine",
    "IATA": "KGO",
    "icao_code": "UKKG"
  },
  {
    "city": "Kiruna",
    "country": "Sweden",
    "IATA": "KRN",
    "icao_code": "ESNQ"
  },
  {
    "city": "Kisangani",
    "country": "Congo (Kinshasa)",
    "IATA": "FKI",
    "icao_code": "FZIC"
  },
  {
    "city": "Kish Island",
    "country": "Iran",
    "IATA": "KIH",
    "icao_code": "OIBK"
  },
  {
    "city": "Kismayu",
    "country": "Somalia",
    "IATA": "KMU",
    "icao_code": "HCMK"
  },
  {
    "city": "Kissidougou",
    "country": "Guinea",
    "IATA": "KSI",
    "icao_code": "GUKU"
  },
  {
    "city": "Kissimmee",
    "country": "United States",
    "IATA": "ISM",
    "icao_code": "KISM"
  },
  {
    "city": "Kisumu",
    "country": "Kenya",
    "IATA": "KIS",
    "icao_code": "HKKI"
  },
  {
    "city": "Kitadaito",
    "country": "Japan",
    "IATA": "KTD",
    "icao_code": "RORK"
  },
  {
    "city": "Kitakyushu",
    "country": "Japan",
    "IATA": "KKJ",
    "icao_code": "RJFR"
  },
  {
    "city": "Kitale",
    "country": "Kenya",
    "IATA": "KTL",
    "icao_code": "HKKT"
  },
  {
    "city": "Kitee",
    "country": "Finland",
    "IATA": "KTQ",
    "icao_code": "EFIT"
  },
  {
    "city": "Kithira",
    "country": "Greece",
    "IATA": "KIT",
    "icao_code": "LGKC"
  },
  {
    "city": "Kitsissuarsuit",
    "country": "Greenland",
    "IATA": "QJE"
  },
  {
    "city": "Kittila",
    "country": "Finland",
    "IATA": "KTT",
    "icao_code": "EFKT"
  },
  {
    "city": "Kitzingen",
    "country": "Germany",
    "IATA": "KZG",
    "icao_code": "ETIN"
  },
  {
    "city": "Kiunga",
    "country": "Papua New Guinea",
    "IATA": "UNG",
    "icao_code": "AYKI"
  },
  {
    "city": "Kivalina",
    "country": "United States",
    "IATA": "KVL",
    "icao_code": "PAVL"
  },
  {
    "city": "Klagenfurt",
    "country": "Austria",
    "IATA": "KLU",
    "icao_code": "LOWK"
  },
  {
    "city": "Klaipeda",
    "country": "Lithuania",
    "IATA": "KLJ"
  },
  {
    "city": "Klamath Falls",
    "country": "United States",
    "IATA": "LMT",
    "icao_code": "KLMT"
  },
  {
    "city": "Klawock",
    "country": "United States",
    "IATA": "KLW",
    "icao_code": "PAKW"
  },
  {
    "city": "Kleiat",
    "country": "Lebanon",
    "IATA": "KYE",
    "icao_code": "OLKA"
  },
  {
    "city": "Kleinsee",
    "country": "South Africa",
    "IATA": "KLZ"
  },
  {
    "city": "Klerksdorp",
    "country": "South Africa",
    "IATA": "KXE",
    "icao_code": "FAKD"
  },
  {
    "city": "Knobnoster",
    "country": "United States",
    "IATA": "SZL"
  },
  {
    "city": "Knoxville",
    "country": "United States",
    "IATA": "TYS",
    "icao_code": "KTYS"
  },
  {
    "city": "Ko Samui",
    "country": "Thailand",
    "IATA": "USM",
    "icao_code": "VTSM"
  },
  {
    "city": "Kobe",
    "country": "Japan",
    "IATA": "UKB",
    "icao_code": "RJBE"
  },
  {
    "city": "Kobuk",
    "country": "United States",
    "IATA": "OBU",
    "icao_code": "PAOB"
  },
  {
    "city": "Kochi",
    "country": "India",
    "IATA": "COK",
    "icao_code": "VOCI"
  },
  {
    "city": "Kochi",
    "country": "Japan",
    "IATA": "KCZ",
    "icao_code": "RJOK"
  },
  {
    "city": "Kodiak",
    "country": "United States",
    "IATA": "ADQ",
    "icao_code": "PADQ"
  },
  {
    "city": "Koethen",
    "country": "Germany",
    "IATA": "KOQ"
  },
  {
    "city": "Kogalym",
    "country": "Russia",
    "IATA": "KGP",
    "icao_code": "USRK"
  },
  {
    "city": "Koggala",
    "country": "Sri Lanka",
    "IATA": "KCT",
    "icao_code": "VCCK"
  },
  {
    "city": "Kokomo",
    "country": "United States",
    "IATA": "OKK",
    "icao_code": "KOKK"
  },
  {
    "city": "Kokonau",
    "country": "Indonesia",
    "IATA": "KOX",
    "icao_code": "WABN"
  },
  {
    "city": "Kokshetau",
    "country": "Kazakhstan",
    "IATA": "KOV",
    "icao_code": "UACK"
  },
  {
    "city": "Kolda",
    "country": "Senegal",
    "IATA": "KDA",
    "icao_code": "GOGK"
  },
  {
    "city": "Kolhapur",
    "country": "India",
    "IATA": "KLH",
    "icao_code": "VAKP"
  },
  {
    "city": "Koliganek",
    "country": "United States",
    "IATA": "KGK",
    "icao_code": "PAJZ"
  },
  {
    "city": "Kolkata",
    "country": "India",
    "IATA": "CCU",
    "icao_code": "VECC"
  },
  {
    "city": "Kolwezi",
    "country": "Congo (Kinshasa)",
    "IATA": "KWZ",
    "icao_code": "FZQM"
  },
  {
    "city": "Kompong Chnang",
    "country": "Cambodia",
    "IATA": "KZC",
    "icao_code": "VDKH"
  },
  {
    "city": "Komsomolsk-on-Amur",
    "country": "Russia",
    "IATA": "KXK",
    "icao_code": "UHKK"
  },
  {
    "city": "Kona",
    "country": "United States",
    "IATA": "KOA",
    "icao_code": "PHKO"
  },
  {
    "city": "Kone",
    "country": "New Caledonia",
    "IATA": "KNQ",
    "icao_code": "NWWD"
  },
  {
    "city": "Kongiganak",
    "country": "United States",
    "IATA": "KKH",
    "icao_code": "PADY"
  },
  {
    "city": "Kongolo",
    "country": "Congo (Kinshasa)",
    "IATA": "KOO",
    "icao_code": "FZRQ"
  },
  {
    "city": "Konya",
    "country": "Turkey",
    "IATA": "KYA",
    "icao_code": "LTAN"
  },
  {
    "city": "Kooddoo",
    "country": "Maldives",
    "IATA": "GKK",
    "icao_code": "VRMO"
  },
  {
    "city": "Korhogo",
    "country": "Cote d'Ivoire",
    "IATA": "HGO",
    "icao_code": "DIKO"
  },
  {
    "city": "Korla",
    "country": "China",
    "IATA": "KRL",
    "icao_code": "ZWKL"
  },
  {
    "city": "Koro Island",
    "country": "Fiji",
    "IATA": "KXF",
    "icao_code": "NFNO"
  },
  {
    "city": "Kortrijk-vevelgem",
    "country": "Belgium",
    "IATA": "KJK",
    "icao_code": "EBKT"
  },
  {
    "city": "Kos",
    "country": "Greece",
    "IATA": "KGS",
    "icao_code": "LGKO"
  },
  {
    "city": "Kosice",
    "country": "Slovakia",
    "IATA": "KSC",
    "icao_code": "LZKZ"
  },
  {
    "city": "Kosrae",
    "country": "Micronesia",
    "IATA": "KSA",
    "icao_code": "PTSA"
  },
  {
    "city": "Kostanay",
    "country": "Kazakhstan",
    "IATA": "KSN",
    "icao_code": "UAUU"
  },
  {
    "city": "Kostroma",
    "country": "Russia",
    "IATA": "KMW",
    "icao_code": "UUBD"
  },
  {
    "city": "Kota",
    "country": "India",
    "IATA": "KTU",
    "icao_code": "VIKO"
  },
  {
    "city": "Kota Bahru",
    "country": "Malaysia",
    "IATA": "KBR",
    "icao_code": "WMKC"
  },
  {
    "city": "Kota Kinabalu",
    "country": "Malaysia",
    "IATA": "BKI",
    "icao_code": "WBKK"
  },
  {
    "city": "Kotakoli",
    "country": "Congo (Kinshasa)",
    "IATA": "KLI",
    "icao_code": "FZFP"
  },
  {
    "city": "Kotlas",
    "country": "Russia",
    "IATA": "KSZ",
    "icao_code": "ULKK"
  },
  {
    "city": "Kotlik",
    "country": "United States",
    "IATA": "KOT",
    "icao_code": "PFKO"
  },
  {
    "city": "Kotzebue",
    "country": "United States",
    "IATA": "OTZ",
    "icao_code": "PAOT"
  },
  {
    "city": "Koulamoutou",
    "country": "Gabon",
    "IATA": "KOU",
    "icao_code": "FOGK"
  },
  {
    "city": "Koumac",
    "country": "New Caledonia",
    "IATA": "KOC",
    "icao_code": "NWWK"
  },
  {
    "city": "Kowanyama",
    "country": "Australia",
    "IATA": "KWM",
    "icao_code": "YKOW"
  },
  {
    "city": "Koyuk",
    "country": "United States",
    "IATA": "KKA",
    "icao_code": "PAKK"
  },
  {
    "city": "Koyukuk",
    "country": "United States",
    "IATA": "KYU",
    "icao_code": "PFKU"
  },
  {
    "city": "Kozani",
    "country": "Greece",
    "IATA": "KZI",
    "icao_code": "LGKZ"
  },
  {
    "city": "Krabi",
    "country": "Thailand",
    "IATA": "KBV",
    "icao_code": "VTSG"
  },
  {
    "city": "Krakow",
    "country": "Poland",
    "IATA": "KRK",
    "icao_code": "EPKK"
  },
  {
    "city": "Kralendijk",
    "country": "Netherlands Antilles",
    "IATA": "BON",
    "icao_code": "TNCB"
  },
  {
    "city": "Kramatorsk",
    "country": "Ukraine",
    "IATA": "KRQ",
    "icao_code": "UKCK"
  },
  {
    "city": "Kramfors",
    "country": "Sweden",
    "IATA": "KRF",
    "icao_code": "ESNK"
  },
  {
    "city": "Krasnodar",
    "country": "Russia",
    "IATA": "KRR",
    "icao_code": "URKK"
  },
  {
    "city": "Krasnovodsk",
    "country": "Turkmenistan",
    "IATA": "KRW",
    "icao_code": "UTAK"
  },
  {
    "city": "Krasnoyarsk",
    "country": "Russia",
    "IATA": "KJA",
    "icao_code": "UNKL"
  },
  {
    "city": "Kratie",
    "country": "Cambodia",
    "IATA": "KTI",
    "icao_code": "VDKT"
  },
  {
    "city": "Kristiansand",
    "country": "Norway",
    "IATA": "KRS",
    "icao_code": "ENCN"
  },
  {
    "city": "Kristianstad",
    "country": "Sweden",
    "IATA": "KID",
    "icao_code": "ESMK"
  },
  {
    "city": "Kristiansund",
    "country": "Norway",
    "IATA": "KSU",
    "icao_code": "ENKB"
  },
  {
    "city": "Krivoy Rog",
    "country": "Ukraine",
    "IATA": "KWG",
    "icao_code": "UKDR"
  },
  {
    "city": "Kruunupyy",
    "country": "Finland",
    "IATA": "KOK",
    "icao_code": "EFKK"
  },
  {
    "city": "Kuala Lumpur",
    "country": "Malaysia",
    "IATA": "SZB",
    "icao_code": "WMSA"
  },
  {
    "city": "Kuala Lumpur",
    "country": "Malaysia",
    "IATA": "KUL",
    "icao_code": "WMKK"
  },
  {
    "city": "Kuala Terengganu",
    "country": "Malaysia",
    "IATA": "TGG",
    "icao_code": "WMKN"
  },
  {
    "city": "Kuantan",
    "country": "Malaysia",
    "IATA": "KUA",
    "icao_code": "WMKD"
  },
  {
    "city": "Kubin",
    "country": "Australia",
    "IATA": "KUG",
    "icao_code": "YKUB"
  },
  {
    "city": "Kuching",
    "country": "Malaysia",
    "IATA": "KCH",
    "icao_code": "WBGG"
  },
  {
    "city": "Kudat",
    "country": "Malaysia",
    "IATA": "KUD",
    "icao_code": "WBKT"
  },
  {
    "city": "Kufra",
    "country": "Libya",
    "IATA": "AKF",
    "icao_code": "HLKF"
  },
  {
    "city": "Kuito",
    "country": "Angola",
    "IATA": "SVP",
    "icao_code": "FNKU"
  },
  {
    "city": "Kulu",
    "country": "India",
    "IATA": "KUU",
    "icao_code": "VIBR"
  },
  {
    "city": "Kulyab",
    "country": "Tajikistan",
    "IATA": "TJU",
    "icao_code": "UTDK"
  },
  {
    "city": "Kumamoto",
    "country": "Japan",
    "IATA": "KMJ",
    "icao_code": "RJFT"
  },
  {
    "city": "Kumasi",
    "country": "Ghana",
    "IATA": "KMS",
    "icao_code": "DGSI"
  },
  {
    "city": "Kumejima",
    "country": "Japan",
    "IATA": "UEO",
    "icao_code": "ROKJ"
  },
  {
    "city": "Kundiawa",
    "country": "Papua New Guinea",
    "IATA": "CMU",
    "icao_code": "AYCH"
  },
  {
    "city": "Kunduz",
    "country": "Afghanistan",
    "IATA": "UND",
    "icao_code": "OAUZ"
  },
  {
    "city": "Kunming",
    "country": "China",
    "IATA": "KMG",
    "icao_code": "ZPPP"
  },
  {
    "city": "Kunovice",
    "country": "Czech Republic",
    "IATA": "UHE",
    "icao_code": "LKKU"
  },
  {
    "city": "Kunsan",
    "country": "South Korea",
    "IATA": "KUV",
    "icao_code": "RKJK"
  },
  {
    "city": "Kununurra",
    "country": "Australia",
    "IATA": "KNX",
    "icao_code": "YPKU"
  },
  {
    "city": "Kuopio",
    "country": "Finland",
    "IATA": "KUO",
    "icao_code": "EFKU"
  },
  {
    "city": "Kupang",
    "country": "Indonesia",
    "IATA": "KOE",
    "icao_code": "WRKK"
  },
  {
    "city": "Kuparuk",
    "country": "United States",
    "IATA": "UUK"
  },
  {
    "city": "Kuqa",
    "country": "China",
    "IATA": "KCA",
    "icao_code": "ZWKC"
  },
  {
    "city": "Kuressaare",
    "country": "Estonia",
    "IATA": "URE",
    "icao_code": "EEKE"
  },
  {
    "city": "Kurgan",
    "country": "Russia",
    "IATA": "KRO",
    "icao_code": "USUU"
  },
  {
    "city": "Kurgan Tyube",
    "country": "Tajikistan",
    "IATA": "KQT",
    "icao_code": "UTDT"
  },
  {
    "city": "Kuria",
    "country": "Kiribati",
    "IATA": "KUC"
  },
  {
    "city": "Kursk",
    "country": "Russia",
    "IATA": "URS",
    "icao_code": "UUOK"
  },
  {
    "city": "Kuruman",
    "country": "South Africa",
    "IATA": "KMH",
    "icao_code": "FAKU"
  },
  {
    "city": "Kushiro",
    "country": "Japan",
    "IATA": "KUH",
    "icao_code": "RJCK"
  },
  {
    "city": "Kutahya",
    "country": "Turkey",
    "IATA": "KZR"
  },
  {
    "city": "Kutaisi",
    "country": "Georgia",
    "IATA": "KUT",
    "icao_code": "UGKO"
  },
  {
    "city": "Kuujjuarapik",
    "country": "Canada",
    "IATA": "YGW",
    "icao_code": "CYGW"
  },
  {
    "city": "Kuusamo",
    "country": "Finland",
    "IATA": "KAO",
    "icao_code": "EFKS"
  },
  {
    "city": "Kuwait",
    "country": "Kuwait",
    "IATA": "KWI",
    "icao_code": "OKBK"
  },
  {
    "city": "Kwajalein",
    "country": "Marshall Islands",
    "IATA": "KWA",
    "icao_code": "PKWA"
  },
  {
    "city": "Kwangju",
    "country": "South Korea",
    "IATA": "KWJ",
    "icao_code": "RKJJ"
  },
  {
    "city": "Kwethluk",
    "country": "United States",
    "IATA": "KWT",
    "icao_code": "PFKW"
  },
  {
    "city": "Kwigillingok",
    "country": "United States",
    "IATA": "KWK",
    "icao_code": "PAGG"
  },
  {
    "city": "Kyaukpyu",
    "country": "Burma",
    "IATA": "KYP",
    "icao_code": "VYKP"
  },
  {
    "city": "Kyzyl",
    "country": "Russia",
    "IATA": "KYZ",
    "icao_code": "UNKY"
  },
  {
    "city": "Kzyl-Orda",
    "country": "Kazakhstan",
    "IATA": "KZO",
    "icao_code": "UAOO"
  },
  {
    "city": "LINFEN",
    "country": "China",
    "IATA": "LFQ",
    "icao_code": "ZBLF"
  },
  {
    "city": "La Baule",
    "country": "France",
    "IATA": "LBY",
    "icao_code": "LFRE"
  },
  {
    "city": "La Ceiba",
    "country": "Honduras",
    "IATA": "LCE",
    "icao_code": "MHLC"
  },
  {
    "city": "La Coloma",
    "country": "Cuba",
    "IATA": "LCL",
    "icao_code": "MULM"
  },
  {
    "city": "La Coruna",
    "country": "Spain",
    "IATA": "LCG",
    "icao_code": "LECO"
  },
  {
    "city": "La Crosse",
    "country": "United States",
    "IATA": "LSE",
    "icao_code": "KLSE"
  },
  {
    "city": "La Dorada",
    "country": "Colombia",
    "IATA": "PAL"
  },
  {
    "city": "La Fortuna/San Carlos",
    "country": "Costa Rica",
    "IATA": "FON",
    "icao_code": "MRAN"
  },
  {
    "city": "La Fria",
    "country": "Venezuela",
    "IATA": "LFR",
    "icao_code": "SVLF"
  },
  {
    "city": "La Gomera",
    "country": "Spain",
    "IATA": "GMZ",
    "icao_code": "GCGM"
  },
  {
    "city": "La Grande",
    "country": "United States",
    "IATA": "LGD",
    "icao_code": "KLGD"
  },
  {
    "city": "La Grande Riviere",
    "country": "Canada",
    "IATA": "YGL",
    "icao_code": "CYGL"
  },
  {
    "city": "La Grande-4",
    "country": "Canada",
    "IATA": "YAH"
  },
  {
    "city": "La Isabela",
    "country": "Dominican Republic",
    "IATA": "JBQ",
    "icao_code": "MDJB"
  },
  {
    "city": "La Mina",
    "country": "Colombia",
    "IATA": "MCJ",
    "icao_code": "SKLM"
  },
  {
    "city": "La Môle",
    "country": "France",
    "IATA": "LTT",
    "icao_code": "LFTZ"
  },
  {
    "city": "La Palma",
    "country": "Panama",
    "IATA": "PLP",
    "icao_code": "MPLP"
  },
  {
    "city": "La Paz",
    "country": "Bolivia",
    "IATA": "LPB",
    "icao_code": "SLLP"
  },
  {
    "city": "La Paz",
    "country": "Mexico",
    "IATA": "LAP",
    "icao_code": "MMLP"
  },
  {
    "city": "La Pedrera",
    "country": "Colombia",
    "IATA": "LPD",
    "icao_code": "SKLP"
  },
  {
    "city": "La Plata",
    "country": "Argentina",
    "IATA": "LPG",
    "icao_code": "SADL"
  },
  {
    "city": "La Rioja",
    "country": "Argentina",
    "IATA": "IRJ",
    "icao_code": "SANL"
  },
  {
    "city": "La Roche-sur-yon",
    "country": "France",
    "IATA": "EDM",
    "icao_code": "LFRI"
  },
  {
    "city": "La Rochelle",
    "country": "France",
    "IATA": "LRH",
    "icao_code": "LFBH"
  },
  {
    "city": "La Romaine",
    "country": "Canada",
    "IATA": "ZGS"
  },
  {
    "city": "La Romana",
    "country": "Dominican Republic",
    "IATA": "LRM",
    "icao_code": "MDLR"
  },
  {
    "city": "La Ronge",
    "country": "Canada",
    "IATA": "YVC",
    "icao_code": "CYVC"
  },
  {
    "city": "La Serena",
    "country": "Chile",
    "IATA": "LSC",
    "icao_code": "SCSE"
  },
  {
    "city": "La Tabatière",
    "country": "Canada",
    "IATA": "ZLT",
    "icao_code": "CTU5"
  },
  {
    "city": "La Toma (Catamayo)",
    "country": "Ecuador",
    "IATA": "LOH",
    "icao_code": "SELO"
  },
  {
    "city": "La Verne",
    "country": "United States",
    "IATA": "POC"
  },
  {
    "city": "LaGrange",
    "country": "United States",
    "IATA": "LGC",
    "icao_code": "KLGC"
  },
  {
    "city": "Laage",
    "country": "Germany",
    "IATA": "RLG",
    "icao_code": "ETNL"
  },
  {
    "city": "Laamu Atoll",
    "country": "Maldives",
    "IATA": "KDO",
    "icao_code": "VRMT"
  },
  {
    "city": "Labe",
    "country": "Guinea",
    "IATA": "LEK",
    "icao_code": "GULB"
  },
  {
    "city": "Labrea",
    "country": "Brazil",
    "IATA": "LBR",
    "icao_code": "SWLB"
  },
  {
    "city": "Labuan",
    "country": "Malaysia",
    "IATA": "LBU",
    "icao_code": "WBKL"
  },
  {
    "city": "Labuhan Bajo",
    "country": "Indonesia",
    "IATA": "LBJ",
    "icao_code": "WRKO"
  },
  {
    "city": "Lac Brochet",
    "country": "Canada",
    "IATA": "XLB"
  },
  {
    "city": "Ladysmith",
    "country": "South Africa",
    "IATA": "LAY",
    "icao_code": "FALY"
  },
  {
    "city": "Laeso",
    "country": "Denmark",
    "IATA": "BYR"
  },
  {
    "city": "Lafayette",
    "country": "United States",
    "IATA": "LAF"
  },
  {
    "city": "Lafayette",
    "country": "United States",
    "IATA": "LFT",
    "icao_code": "KLFT"
  },
  {
    "city": "Laghouat",
    "country": "Algeria",
    "IATA": "LOO",
    "icao_code": "DAUL"
  },
  {
    "city": "Lagos",
    "country": "Nigeria",
    "IATA": "LOS",
    "icao_code": "DNMM"
  },
  {
    "city": "Lahad Datu",
    "country": "Malaysia",
    "IATA": "LDU",
    "icao_code": "WBKD"
  },
  {
    "city": "Lahania-kapalua",
    "country": "United States",
    "IATA": "JHM",
    "icao_code": "PHJH"
  },
  {
    "city": "Lahore",
    "country": "Pakistan",
    "IATA": "LHE",
    "icao_code": "OPLA"
  },
  {
    "city": "Lahr",
    "country": "Germany",
    "IATA": "LHA",
    "icao_code": "EDTL"
  },
  {
    "city": "Lajes",
    "country": "Brazil",
    "IATA": "LAJ",
    "icao_code": "SBLJ"
  },
  {
    "city": "Lajes (terceira Island)",
    "country": "Portugal",
    "IATA": "TER",
    "icao_code": "LPLA"
  },
  {
    "city": "Lake Charles",
    "country": "United States",
    "IATA": "LCH",
    "icao_code": "KLCH"
  },
  {
    "city": "Lake City",
    "country": "United States",
    "IATA": "LCQ",
    "icao_code": "KLCQ"
  },
  {
    "city": "Lake Evella",
    "country": "Australia",
    "IATA": "LEL",
    "icao_code": "YLEV"
  },
  {
    "city": "Lake Havasu City",
    "country": "United States",
    "IATA": "HII",
    "icao_code": "KHII"
  },
  {
    "city": "Lake Macquarie",
    "country": "Australia",
    "IATA": "BEO",
    "icao_code": "YPEC"
  },
  {
    "city": "Lake Manyara",
    "country": "Tanzania",
    "IATA": "LKY",
    "icao_code": "HTLM"
  },
  {
    "city": "Lake Minchumina",
    "country": "United States",
    "IATA": "LMA"
  },
  {
    "city": "Lake Placid",
    "country": "United States",
    "IATA": "LKP",
    "icao_code": "KLKP"
  },
  {
    "city": "Lakeba Island",
    "country": "Fiji",
    "IATA": "LKB",
    "icao_code": "NFNK"
  },
  {
    "city": "Lakehurst",
    "country": "United States",
    "IATA": "NEL"
  },
  {
    "city": "Lakeland",
    "country": "United States",
    "IATA": "LAL",
    "icao_code": "KLAL"
  },
  {
    "city": "Lakenheath",
    "country": "United Kingdom",
    "IATA": "LKZ"
  },
  {
    "city": "Lakeview",
    "country": "United States",
    "IATA": "LKV",
    "icao_code": "KLKV"
  },
  {
    "city": "Lakselv",
    "country": "Norway",
    "IATA": "LKL",
    "icao_code": "ENNA"
  },
  {
    "city": "Lalibella",
    "country": "Ethiopia",
    "IATA": "LLI",
    "icao_code": "HALL"
  },
  {
    "city": "Lamap",
    "country": "Vanuatu",
    "IATA": "LPM",
    "icao_code": "NVSL"
  },
  {
    "city": "Lamar",
    "country": "United States",
    "IATA": "LAA",
    "icao_code": "KLAA"
  },
  {
    "city": "Lambarene",
    "country": "Gabon",
    "IATA": "LBQ",
    "icao_code": "FOGR"
  },
  {
    "city": "Lambasa",
    "country": "Fiji",
    "IATA": "LBS",
    "icao_code": "NFNL"
  },
  {
    "city": "Lamen Bay",
    "country": "Vanuatu",
    "IATA": "LNB",
    "icao_code": "NVSM"
  },
  {
    "city": "Lamerd",
    "country": "Iran",
    "IATA": "LFM",
    "icao_code": "OISR"
  },
  {
    "city": "Lamezia",
    "country": "Italy",
    "IATA": "SUF",
    "icao_code": "LICA"
  },
  {
    "city": "Lamidanda",
    "country": "Nepal",
    "IATA": "LDN",
    "icao_code": "VNLD"
  },
  {
    "city": "Lampang",
    "country": "Thailand",
    "IATA": "LPT",
    "icao_code": "VTCL"
  },
  {
    "city": "Lampedusa",
    "country": "Italy",
    "IATA": "LMP",
    "icao_code": "LICD"
  },
  {
    "city": "Lamu",
    "country": "Kenya",
    "IATA": "LAU",
    "icao_code": "HKLU"
  },
  {
    "city": "Lanai",
    "country": "United States",
    "IATA": "LNY",
    "icao_code": "PHNY"
  },
  {
    "city": "Lancang Lahu",
    "country": "China",
    "IATA": "JMJ",
    "icao_code": "ZPJM"
  },
  {
    "city": "Lancaster",
    "country": "United States",
    "IATA": "WJF"
  },
  {
    "city": "Lancaster",
    "country": "United States",
    "IATA": "LNS",
    "icao_code": "KLNS"
  },
  {
    "city": "Land's End",
    "country": "United Kingdom",
    "IATA": "LEQ",
    "icao_code": "EGHC"
  },
  {
    "city": "Landivisiau",
    "country": "France",
    "IATA": "LDV"
  },
  {
    "city": "Langebaanweg",
    "country": "South Africa",
    "IATA": "SDB",
    "icao_code": "FASD"
  },
  {
    "city": "Langeoog",
    "country": "Germany",
    "IATA": "LGO"
  },
  {
    "city": "Langgur-Kei Islands",
    "country": "Indonesia",
    "IATA": "LUV",
    "icao_code": "WAPL"
  },
  {
    "city": "Langkawi",
    "country": "Malaysia",
    "IATA": "LGK",
    "icao_code": "WMKL"
  },
  {
    "city": "Langley Township",
    "country": "Canada",
    "IATA": "YLY"
  },
  {
    "city": "Lankaran",
    "country": "Azerbaijan",
    "IATA": "LLK",
    "icao_code": "UBBL"
  },
  {
    "city": "Lannion",
    "country": "France",
    "IATA": "LAI",
    "icao_code": "LFRO"
  },
  {
    "city": "Lansdowne House",
    "country": "Canada",
    "IATA": "YLH",
    "icao_code": "CYLH"
  },
  {
    "city": "Lansing",
    "country": "United States",
    "IATA": "LAN",
    "icao_code": "KLAN"
  },
  {
    "city": "Lanyu",
    "country": "Taiwan",
    "IATA": "KYD",
    "icao_code": "RCLY"
  },
  {
    "city": "Lanzhou",
    "country": "China",
    "IATA": "LHW",
    "icao_code": "ZLLL"
  },
  {
    "city": "Laoag",
    "country": "Philippines",
    "IATA": "LAO",
    "icao_code": "RPLI"
  },
  {
    "city": "Lappeenranta",
    "country": "Finland",
    "IATA": "LPP",
    "icao_code": "EFLP"
  },
  {
    "city": "Lar",
    "country": "Iran",
    "IATA": "LRR",
    "icao_code": "OISL"
  },
  {
    "city": "Laramie",
    "country": "United States",
    "IATA": "LAR",
    "icao_code": "KLAR"
  },
  {
    "city": "Laredo",
    "country": "United States",
    "IATA": "LRD",
    "icao_code": "KLRD"
  },
  {
    "city": "Larissa",
    "country": "Greece",
    "IATA": "LRA",
    "icao_code": "LGLR"
  },
  {
    "city": "Larnaca",
    "country": "Cyprus",
    "IATA": "LCA",
    "icao_code": "LCLK"
  },
  {
    "city": "Larsen Bay",
    "country": "United States",
    "IATA": "KLN",
    "icao_code": "PALB"
  },
  {
    "city": "Las Cruces",
    "country": "United States",
    "IATA": "LRU",
    "icao_code": "KLRU"
  },
  {
    "city": "Las Heras",
    "country": "Argentina",
    "IATA": "LHS",
    "icao_code": "SAVH"
  },
  {
    "city": "Las Tunas",
    "country": "Cuba",
    "IATA": "VTU",
    "icao_code": "MUVT"
  },
  {
    "city": "Las Vegas",
    "country": "United States",
    "IATA": "VGT",
    "icao_code": "KVGT"
  },
  {
    "city": "Las Vegas",
    "country": "United States",
    "IATA": "LVS",
    "icao_code": "KLVS"
  },
  {
    "city": "Las Vegas",
    "country": "United States",
    "IATA": "LAS",
    "icao_code": "KLAS"
  },
  {
    "city": "Las Vegas",
    "country": "United States",
    "IATA": "LSV",
    "icao_code": "KLSV"
  },
  {
    "city": "Lasham",
    "country": "United Kingdom",
    "IATA": "QLA",
    "icao_code": "EGHL"
  },
  {
    "city": "Lashio",
    "country": "Burma",
    "IATA": "LSH",
    "icao_code": "VYLS"
  },
  {
    "city": "Lashkar Gah",
    "country": "Afghanistan",
    "IATA": "BST",
    "icao_code": "OABT"
  },
  {
    "city": "Latacunga",
    "country": "Ecuador",
    "IATA": "LTX"
  },
  {
    "city": "Latakia",
    "country": "Syria",
    "IATA": "LTK",
    "icao_code": "OSLK"
  },
  {
    "city": "Latina",
    "country": "Italy",
    "IATA": "QLT"
  },
  {
    "city": "Latrobe",
    "country": "United States",
    "IATA": "LBE",
    "icao_code": "KLBE"
  },
  {
    "city": "Laucala",
    "country": "Fiji",
    "IATA": "LUC",
    "icao_code": "NFNH"
  },
  {
    "city": "Launceston",
    "country": "Australia",
    "IATA": "LST",
    "icao_code": "YMLT"
  },
  {
    "city": "Lausanne",
    "country": "Switzerland",
    "IATA": "QLS",
    "icao_code": "LSGL"
  },
  {
    "city": "Laval",
    "country": "France",
    "IATA": "LVA",
    "icao_code": "LFOV"
  },
  {
    "city": "Lavan Island",
    "country": "Iran",
    "IATA": "LVP"
  },
  {
    "city": "Laverton",
    "country": "Australia",
    "IATA": "LVO",
    "icao_code": "YLTN"
  },
  {
    "city": "Lawas",
    "country": "Malaysia",
    "IATA": "LWY",
    "icao_code": "WBGW"
  },
  {
    "city": "Lawrence",
    "country": "United States",
    "IATA": "LWC",
    "icao_code": "KLWC"
  },
  {
    "city": "Lawrence",
    "country": "United States",
    "IATA": "LWM",
    "icao_code": "KLWM"
  },
  {
    "city": "Lawrenceville",
    "country": "United States",
    "IATA": "LZU",
    "icao_code": "KLZU"
  },
  {
    "city": "Lawton",
    "country": "United States",
    "IATA": "LAW",
    "icao_code": "KLAW"
  },
  {
    "city": "Lazard Cardenas",
    "country": "Mexico",
    "IATA": "LZC",
    "icao_code": "MMLC"
  },
  {
    "city": "Le Castellet",
    "country": "France",
    "IATA": "CTT",
    "icao_code": "LFMQ"
  },
  {
    "city": "Le Havre",
    "country": "France",
    "IATA": "LEH",
    "icao_code": "LFOH"
  },
  {
    "city": "Le Mans",
    "country": "France",
    "IATA": "LME",
    "icao_code": "LFRM"
  },
  {
    "city": "Le Puy",
    "country": "France",
    "IATA": "LPY",
    "icao_code": "LFHP"
  },
  {
    "city": "Le Tourquet",
    "country": "France",
    "IATA": "LTQ",
    "icao_code": "LFAT"
  },
  {
    "city": "Leaf Rapids",
    "country": "Canada",
    "IATA": "YLR",
    "icao_code": "CYLR"
  },
  {
    "city": "Learmonth",
    "country": "Australia",
    "IATA": "LEA",
    "icao_code": "YPLM"
  },
  {
    "city": "Lebanon",
    "country": "United States",
    "IATA": "LEB",
    "icao_code": "KLEB"
  },
  {
    "city": "Lecce",
    "country": "Italy",
    "IATA": "LCC",
    "icao_code": "LIBN"
  },
  {
    "city": "Leeds",
    "country": "United Kingdom",
    "IATA": "LBA",
    "icao_code": "EGNM"
  },
  {
    "city": "Leesburg",
    "country": "United States",
    "IATA": "LEE",
    "icao_code": "KLEE"
  },
  {
    "city": "Leeuwarden",
    "country": "Netherlands",
    "IATA": "LWR",
    "icao_code": "EHLW"
  },
  {
    "city": "Legazpi",
    "country": "Philippines",
    "IATA": "LGP",
    "icao_code": "RPLP"
  },
  {
    "city": "Leh",
    "country": "India",
    "IATA": "IXL",
    "icao_code": "VILH"
  },
  {
    "city": "Leigh Creek",
    "country": "Australia",
    "IATA": "LGH",
    "icao_code": "YLEC"
  },
  {
    "city": "Leinster",
    "country": "Australia",
    "IATA": "LER",
    "icao_code": "YLST"
  },
  {
    "city": "Leipzig",
    "country": "Germany",
    "IATA": "LEJ",
    "icao_code": "EDDP"
  },
  {
    "city": "Leknes",
    "country": "Norway",
    "IATA": "LKN",
    "icao_code": "ENLK"
  },
  {
    "city": "Lelystad",
    "country": "Netherlands",
    "IATA": "LEY"
  },
  {
    "city": "Lemoore",
    "country": "United States",
    "IATA": "NLC",
    "icao_code": "KNLC"
  },
  {
    "city": "Lensk",
    "country": "Russia",
    "IATA": "ULK",
    "icao_code": "UERL"
  },
  {
    "city": "Lençóis",
    "country": "Brazil",
    "IATA": "LEC",
    "icao_code": "SBLE"
  },
  {
    "city": "Leon",
    "country": "Spain",
    "IATA": "LEN",
    "icao_code": "LELN"
  },
  {
    "city": "Leonora",
    "country": "Australia",
    "IATA": "LNO",
    "icao_code": "YLEO"
  },
  {
    "city": "Leros",
    "country": "Greece",
    "IATA": "LRS",
    "icao_code": "LGLE"
  },
  {
    "city": "Lerwick",
    "country": "United Kingdom",
    "IATA": "LWK",
    "icao_code": "EGET"
  },
  {
    "city": "Les Saintes",
    "country": "Guadeloupe",
    "IATA": "LSS"
  },
  {
    "city": "Lethbridge",
    "country": "Canada",
    "IATA": "YQL",
    "icao_code": "CYQL"
  },
  {
    "city": "Lethem",
    "country": "Guyana",
    "IATA": "LTM",
    "icao_code": "SYLT"
  },
  {
    "city": "Leticia",
    "country": "Colombia",
    "IATA": "LET",
    "icao_code": "SKLT"
  },
  {
    "city": "Leuchars",
    "country": "United Kingdom",
    "IATA": "ADX",
    "icao_code": "EGQL"
  },
  {
    "city": "Levuka",
    "country": "Fiji",
    "IATA": "LEV",
    "icao_code": "NFNB"
  },
  {
    "city": "Lewisburg",
    "country": "United States",
    "IATA": "LWB",
    "icao_code": "KLWB"
  },
  {
    "city": "Lewiston",
    "country": "United States",
    "IATA": "LEW",
    "icao_code": "KLEW"
  },
  {
    "city": "Lewiston",
    "country": "United States",
    "IATA": "LWS",
    "icao_code": "KLWS"
  },
  {
    "city": "Lewistown",
    "country": "United States",
    "IATA": "LWT",
    "icao_code": "KLWT"
  },
  {
    "city": "Lexington",
    "country": "United States",
    "IATA": "LXN",
    "icao_code": "KLXN"
  },
  {
    "city": "Lexington KY",
    "country": "United States",
    "IATA": "LEX",
    "icao_code": "KLEX"
  },
  {
    "city": "Lhasa",
    "country": "China",
    "IATA": "LXA",
    "icao_code": "ZULS"
  },
  {
    "city": "Lhok Seumawe-Sumatra Island",
    "country": "Indonesia",
    "IATA": "LSW",
    "icao_code": "WITM"
  },
  {
    "city": "Lhok Sukon",
    "country": "Indonesia",
    "IATA": "LSX",
    "icao_code": "WITL"
  },
  {
    "city": "Lianyungang",
    "country": "China",
    "IATA": "LYG",
    "icao_code": "ZSLG"
  },
  {
    "city": "Liberal",
    "country": "United States",
    "IATA": "LBL",
    "icao_code": "KLBL"
  },
  {
    "city": "Liberia",
    "country": "Costa Rica",
    "IATA": "LIR",
    "icao_code": "MRLB"
  },
  {
    "city": "Libo",
    "country": "China",
    "IATA": "LLB",
    "icao_code": "ZULB"
  },
  {
    "city": "Libreville",
    "country": "Gabon",
    "IATA": "LBV",
    "icao_code": "FOOL"
  },
  {
    "city": "Lichinga",
    "country": "Mozambique",
    "IATA": "VXC",
    "icao_code": "FQLC"
  },
  {
    "city": "Lidkoping",
    "country": "Sweden",
    "IATA": "LDK"
  },
  {
    "city": "Liege",
    "country": "Belgium",
    "IATA": "LGG",
    "icao_code": "EBLG"
  },
  {
    "city": "Liepaja",
    "country": "Latvia",
    "IATA": "LPX",
    "icao_code": "EVLA"
  },
  {
    "city": "Lifou",
    "country": "New Caledonia",
    "IATA": "LIF",
    "icao_code": "NWWL"
  },
  {
    "city": "Lifuka",
    "country": "Tonga",
    "IATA": "HPA",
    "icao_code": "NFTL"
  },
  {
    "city": "Lightning Ridge",
    "country": "Australia",
    "IATA": "LHG",
    "icao_code": "YLRD"
  },
  {
    "city": "Lihue",
    "country": "United States",
    "IATA": "LIH",
    "icao_code": "PHLI"
  },
  {
    "city": "Lijiang",
    "country": "China",
    "IATA": "LJG",
    "icao_code": "ZPLJ"
  },
  {
    "city": "Likoma Island",
    "country": "Malawi",
    "IATA": "LIX",
    "icao_code": "FWLK"
  },
  {
    "city": "Lilabari",
    "country": "India",
    "IATA": "IXI",
    "icao_code": "VELR"
  },
  {
    "city": "Lille",
    "country": "France",
    "IATA": "LIL",
    "icao_code": "LFQQ"
  },
  {
    "city": "Lilongwe",
    "country": "Malawi",
    "IATA": "LLW",
    "icao_code": "FWLI"
  },
  {
    "city": "Lima",
    "country": "United States",
    "IATA": "AOH",
    "icao_code": "KAOH"
  },
  {
    "city": "Lima",
    "country": "Peru",
    "IATA": "LIM",
    "icao_code": "SPIM"
  },
  {
    "city": "Limbang",
    "country": "Malaysia",
    "IATA": "LMN",
    "icao_code": "WBGJ"
  },
  {
    "city": "Lime Acres",
    "country": "South Africa",
    "IATA": "LMR",
    "icao_code": "FALC"
  },
  {
    "city": "Limnos",
    "country": "Greece",
    "IATA": "LXS",
    "icao_code": "LGLM"
  },
  {
    "city": "Limoges",
    "country": "France",
    "IATA": "LIG",
    "icao_code": "LFBL"
  },
  {
    "city": "Limon",
    "country": "Costa Rica",
    "IATA": "LIO",
    "icao_code": "MRLM"
  },
  {
    "city": "Lincang",
    "country": "China",
    "IATA": "LNJ",
    "icao_code": "ZPLC"
  },
  {
    "city": "Lincoln",
    "country": "United States",
    "IATA": "LNK",
    "icao_code": "KLNK"
  },
  {
    "city": "Lindau",
    "country": "Germany",
    "IATA": "LND",
    "icao_code": "KLND"
  },
  {
    "city": "Linden",
    "country": "United States",
    "IATA": "LDJ",
    "icao_code": "KLDJ"
  },
  {
    "city": "Lindi",
    "country": "Tanzania",
    "IATA": "LDI"
  },
  {
    "city": "Linkoeping",
    "country": "Sweden",
    "IATA": "LPI",
    "icao_code": "ESSL"
  },
  {
    "city": "Lins",
    "country": "Brazil",
    "IATA": "LIP",
    "icao_code": "SBLN"
  },
  {
    "city": "Linton-on-ouse",
    "country": "United Kingdom",
    "IATA": "HRT",
    "icao_code": "KHRT"
  },
  {
    "city": "Linyi",
    "country": "China",
    "IATA": "LYI",
    "icao_code": "ZSLY"
  },
  {
    "city": "Linz",
    "country": "Austria",
    "IATA": "LNZ",
    "icao_code": "LOWL"
  },
  {
    "city": "Lipetsk",
    "country": "Russia",
    "IATA": "LPK",
    "icao_code": "UUOL"
  },
  {
    "city": "Liping",
    "country": "China",
    "IATA": "HZH",
    "icao_code": "ZUNP"
  },
  {
    "city": "Lisala",
    "country": "Congo (Kinshasa)",
    "IATA": "LIQ",
    "icao_code": "FZGA"
  },
  {
    "city": "Lisbon",
    "country": "Portugal",
    "IATA": "LIS",
    "icao_code": "LPPT"
  },
  {
    "city": "Lismore",
    "country": "Australia",
    "IATA": "LSY",
    "icao_code": "YLIS"
  },
  {
    "city": "Little Cayman",
    "country": "Cayman Islands",
    "IATA": "LYB",
    "icao_code": "MWCL"
  },
  {
    "city": "Little Grand Rapids",
    "country": "Canada",
    "IATA": "ZGR",
    "icao_code": "CZGR"
  },
  {
    "city": "Little Rock",
    "country": "United States",
    "IATA": "LIT",
    "icao_code": "KLIT"
  },
  {
    "city": "Liupanshui",
    "country": "China",
    "IATA": "LPF",
    "icao_code": "ZUPS"
  },
  {
    "city": "Liuzhou",
    "country": "China",
    "IATA": "LZH",
    "icao_code": "ZGZH"
  },
  {
    "city": "Livermore",
    "country": "United States",
    "IATA": "LVK",
    "icao_code": "KLVK"
  },
  {
    "city": "Liverpool",
    "country": "United Kingdom",
    "IATA": "LPL",
    "icao_code": "EGGP"
  },
  {
    "city": "Livingston-Montana",
    "country": "United States",
    "IATA": "LVM",
    "icao_code": "KLVM"
  },
  {
    "city": "Livingstone",
    "country": "Zambia",
    "IATA": "LVI",
    "icao_code": "FLLI"
  },
  {
    "city": "Lizard Island",
    "country": "Australia",
    "IATA": "LZR",
    "icao_code": "YLZI"
  },
  {
    "city": "Ljubljana",
    "country": "Slovenia",
    "IATA": "LJU",
    "icao_code": "LJLJ"
  },
  {
    "city": "Lleida",
    "country": "Spain",
    "IATA": "ILD",
    "icao_code": "LEDA"
  },
  {
    "city": "Lloydminster",
    "country": "Canada",
    "IATA": "YLL",
    "icao_code": "CYLL"
  },
  {
    "city": "Locarno",
    "country": "Switzerland",
    "IATA": "ZJI"
  },
  {
    "city": "Lock Haven",
    "country": "United States",
    "IATA": "LHV",
    "icao_code": "KLHV"
  },
  {
    "city": "Lockhart River",
    "country": "Australia",
    "IATA": "IRG",
    "icao_code": "YLHR"
  },
  {
    "city": "Lockport",
    "country": "United States",
    "IATA": "LOT",
    "icao_code": "KLOT"
  },
  {
    "city": "Lodja",
    "country": "Congo (Kinshasa)",
    "IATA": "LJA",
    "icao_code": "FZVA"
  },
  {
    "city": "Lodwar",
    "country": "Kenya",
    "IATA": "LOK",
    "icao_code": "HKLO"
  },
  {
    "city": "Lodz",
    "country": "Poland",
    "IATA": "LCJ",
    "icao_code": "EPLL"
  },
  {
    "city": "Loei",
    "country": "Thailand",
    "IATA": "LOE",
    "icao_code": "VTUL"
  },
  {
    "city": "Logan",
    "country": "United States",
    "IATA": "LGU",
    "icao_code": "KLGU"
  },
  {
    "city": "Logroño-Agoncillo",
    "country": "Spain",
    "IATA": "RJL",
    "icao_code": "LELO"
  },
  {
    "city": "Loh/Linua",
    "country": "Vanuatu",
    "IATA": "TOH",
    "icao_code": "NVSD"
  },
  {
    "city": "Loikaw",
    "country": "Burma",
    "IATA": "LIW",
    "icao_code": "VYLK"
  },
  {
    "city": "Lokichoggio",
    "country": "Kenya",
    "IATA": "LKG",
    "icao_code": "HKLK"
  },
  {
    "city": "Lome",
    "country": "Togo",
    "IATA": "LFW",
    "icao_code": "DXXX"
  },
  {
    "city": "Lompoc",
    "country": "United States",
    "IATA": "LPC",
    "icao_code": "KLPC"
  },
  {
    "city": "Lompoc",
    "country": "United States",
    "IATA": "VBG"
  },
  {
    "city": "London",
    "country": "United States",
    "IATA": "LOZ",
    "icao_code": "KLOZ"
  },
  {
    "city": "London",
    "country": "United Kingdom",
    "IATA": "STN",
    "icao_code": "EGSS"
  },
  {
    "city": "London",
    "country": "United Kingdom",
    "IATA": "LHR",
    "icao_code": "EGLL"
  },
  {
    "city": "London",
    "country": "United Kingdom",
    "IATA": "LCY",
    "icao_code": "EGLC"
  },
  {
    "city": "London",
    "country": "United Kingdom",
    "IATA": "LGW",
    "icao_code": "EGKK"
  },
  {
    "city": "London",
    "country": "United Kingdom",
    "IATA": "LTN",
    "icao_code": "EGGW"
  },
  {
    "city": "London",
    "country": "Canada",
    "IATA": "YXU",
    "icao_code": "CYXU"
  },
  {
    "city": "Londonderry",
    "country": "United Kingdom",
    "IATA": "LDY",
    "icao_code": "EGAE"
  },
  {
    "city": "Londrina",
    "country": "Brazil",
    "IATA": "LDB",
    "icao_code": "SBLO"
  },
  {
    "city": "Lone Rock",
    "country": "United States",
    "IATA": "LNR",
    "icao_code": "KLNR"
  },
  {
    "city": "Long Akah",
    "country": "Malaysia",
    "IATA": "LKH",
    "icao_code": "WBGL"
  },
  {
    "city": "Long Apung-Borneo Island",
    "country": "Indonesia",
    "IATA": "LPU",
    "icao_code": "WRLP"
  },
  {
    "city": "Long Bawan-Borneo Island",
    "country": "Indonesia",
    "IATA": "LBW",
    "icao_code": "WRLB"
  },
  {
    "city": "Los Angeles, Long Beach",
    "country": "United States",
    "IATA": "LGB",
    "icao_code": "KLGB"
  },
  {
    "city": "Long Datih",
    "country": "Malaysia",
    "IATA": "LGL",
    "icao_code": "WBGF"
  },
  {
    "city": "Long Seridan",
    "country": "Malaysia",
    "IATA": "ODN",
    "icao_code": "WBGI"
  },
  {
    "city": "Longana",
    "country": "Vanuatu",
    "IATA": "LOD",
    "icao_code": "NVSG"
  },
  {
    "city": "Longnan",
    "country": "China",
    "IATA": "LNL",
    "icao_code": "ZLLN"
  },
  {
    "city": "Longreach",
    "country": "Australia",
    "IATA": "LRE",
    "icao_code": "YBLR"
  },
  {
    "city": "Longview",
    "country": "United States",
    "IATA": "GGG",
    "icao_code": "KGGG"
  },
  {
    "city": "Longwood",
    "country": "United Kingdom",
    "IATA": "HLE",
    "icao_code": "FHSH"
  },
  {
    "city": "Longyan",
    "country": "China",
    "IATA": "LCX"
  },
  {
    "city": "Lonorore",
    "country": "Vanuatu",
    "IATA": "LNE",
    "icao_code": "NVSO"
  },
  {
    "city": "Lopez",
    "country": "United States",
    "IATA": "LPS"
  },
  {
    "city": "Lord Howe Island",
    "country": "Australia",
    "IATA": "LDH",
    "icao_code": "YLHI"
  },
  {
    "city": "Loreto",
    "country": "Mexico",
    "IATA": "LTO",
    "icao_code": "MMLT"
  },
  {
    "city": "Lorient",
    "country": "France",
    "IATA": "LRT",
    "icao_code": "LFRH"
  },
  {
    "city": "Los Alamos",
    "country": "United States",
    "IATA": "LAM",
    "icao_code": "KLAM"
  },
  {
    "city": "Los Angeles",
    "country": "United States",
    "IATA": "WHP",
    "icao_code": "KWHP"
  },
  {
    "city": "Los Angeles",
    "country": "United States",
    "IATA": "LAX",
    "icao_code": "KLAX"
  },
  {
    "city": "Los Angeles",
    "country": "Chile",
    "IATA": "LSQ",
    "icao_code": "SCGE"
  },
  {
    "city": "Los Chiles",
    "country": "Costa Rica",
    "IATA": "LSL",
    "icao_code": "MRLC"
  },
  {
    "city": "Los Mochis",
    "country": "Mexico",
    "IATA": "LMM",
    "icao_code": "MMLM"
  },
  {
    "city": "Los Roques",
    "country": "Venezuela",
    "IATA": "LRV",
    "icao_code": "SVRS"
  },
  {
    "city": "Lossiemouth",
    "country": "United Kingdom",
    "IATA": "LMO",
    "icao_code": "EGQS"
  },
  {
    "city": "Loubomo",
    "country": "Congo (Brazzaville)",
    "IATA": "DIS",
    "icao_code": "FCPL"
  },
  {
    "city": "Louisiana",
    "country": "United States",
    "IATA": "ARA",
    "icao_code": "KARA"
  },
  {
    "city": "Louisville",
    "country": "United States",
    "IATA": "SDF",
    "icao_code": "KSDF"
  },
  {
    "city": "Louisville",
    "country": "United States",
    "IATA": "LOU",
    "icao_code": "KLOU"
  },
  {
    "city": "Lourdes-De-Blanc-Sablon",
    "country": "Canada",
    "IATA": "YBX",
    "icao_code": "CYBX"
  },
  {
    "city": "Lovelock",
    "country": "United States",
    "IATA": "LOL",
    "icao_code": "KLOL"
  },
  {
    "city": "Luanda",
    "country": "Angola",
    "IATA": "LAD",
    "icao_code": "FNLU"
  },
  {
    "city": "Luang Namtha",
    "country": "Laos",
    "IATA": "LXG",
    "icao_code": "VLLN"
  },
  {
    "city": "Luang Prabang",
    "country": "Laos",
    "IATA": "LPQ",
    "icao_code": "VLLB"
  },
  {
    "city": "Lubang",
    "country": "Philippines",
    "IATA": "LBX"
  },
  {
    "city": "Lubango",
    "country": "Angola",
    "IATA": "SDD",
    "icao_code": "FNBU"
  },
  {
    "city": "Lubbock",
    "country": "United States",
    "IATA": "LBB",
    "icao_code": "KLBB"
  },
  {
    "city": "Lublin",
    "country": "Poland",
    "IATA": "LUZ"
  },
  {
    "city": "Lubumashi",
    "country": "Congo (Kinshasa)",
    "IATA": "FBM",
    "icao_code": "FZQA"
  },
  {
    "city": "Lucapa",
    "country": "Angola",
    "IATA": "LBZ",
    "icao_code": "FNLK"
  },
  {
    "city": "Lucknow",
    "country": "India",
    "IATA": "LKO",
    "icao_code": "VILK"
  },
  {
    "city": "Luderitz",
    "country": "Namibia",
    "IATA": "LUD",
    "icao_code": "FYLZ"
  },
  {
    "city": "Ludhiaha",
    "country": "India",
    "IATA": "LUH",
    "icao_code": "VILD"
  },
  {
    "city": "Ludington",
    "country": "United States",
    "IATA": "LDM",
    "icao_code": "KLDM"
  },
  {
    "city": "Luebeck",
    "country": "Germany",
    "IATA": "LBC",
    "icao_code": "EDHL"
  },
  {
    "city": "Luena",
    "country": "Angola",
    "IATA": "LUO",
    "icao_code": "FNUE"
  },
  {
    "city": "Lufkin",
    "country": "United States",
    "IATA": "LFK",
    "icao_code": "KLFK"
  },
  {
    "city": "Lugano",
    "country": "Switzerland",
    "IATA": "LUG",
    "icao_code": "LSZA"
  },
  {
    "city": "Lugansk",
    "country": "Ukraine",
    "IATA": "VSG",
    "icao_code": "UKCW"
  },
  {
    "city": "Lukla",
    "country": "Nepal",
    "IATA": "LUA",
    "icao_code": "VNLK"
  },
  {
    "city": "Lulea",
    "country": "Sweden",
    "IATA": "LLA",
    "icao_code": "ESPA"
  },
  {
    "city": "Lumberton",
    "country": "United States",
    "IATA": "LBT",
    "icao_code": "KLBT"
  },
  {
    "city": "Lumbo",
    "country": "Mozambique",
    "IATA": "LFB"
  },
  {
    "city": "Luoyang",
    "country": "China",
    "IATA": "LYA",
    "icao_code": "ZHLY"
  },
  {
    "city": "Lusaka",
    "country": "Zambia",
    "IATA": "LUN",
    "icao_code": "FLLS"
  },
  {
    "city": "Lutselk'e",
    "country": "Canada",
    "IATA": "YSG"
  },
  {
    "city": "Lutsk",
    "country": "Ukraine",
    "IATA": "UCK",
    "icao_code": "UKLC"
  },
  {
    "city": "Luwuk",
    "country": "Indonesia",
    "IATA": "LUW",
    "icao_code": "WAMW"
  },
  {
    "city": "Luxemburg",
    "country": "Luxembourg",
    "IATA": "LUX",
    "icao_code": "ELLX"
  },
  {
    "city": "Luxi",
    "country": "China",
    "IATA": "LUM",
    "icao_code": "ZPLX"
  },
  {
    "city": "Luxor",
    "country": "Egypt",
    "IATA": "LXR",
    "icao_code": "HELX"
  },
  {
    "city": "Luzhou",
    "country": "China",
    "IATA": "LZO",
    "icao_code": "ZULZ"
  },
  {
    "city": "Lvliang",
    "country": "China",
    "IATA": "LLV",
    "icao_code": "ZBLL"
  },
  {
    "city": "Lvov",
    "country": "Ukraine",
    "IATA": "LWO",
    "icao_code": "UKLL"
  },
  {
    "city": "Lycksele",
    "country": "Sweden",
    "IATA": "LYC",
    "icao_code": "ESNL"
  },
  {
    "city": "Lydd",
    "country": "United Kingdom",
    "IATA": "LYX",
    "icao_code": "EGMD"
  },
  {
    "city": "Lympne",
    "country": "United Kingdom",
    "IATA": "LYM"
  },
  {
    "city": "Lynchburg",
    "country": "United States",
    "IATA": "LYH",
    "icao_code": "KLYH"
  },
  {
    "city": "Lyneham",
    "country": "United Kingdom",
    "IATA": "LYE",
    "icao_code": "EGDL"
  },
  {
    "city": "Lynn Lake",
    "country": "Canada",
    "IATA": "YYL",
    "icao_code": "CYYL"
  },
  {
    "city": "Lyon",
    "country": "France",
    "IATA": "LYN",
    "icao_code": "LFLY"
  },
  {
    "city": "Lyon",
    "country": "France",
    "IATA": "LYS",
    "icao_code": "LFLL"
  },
  {
    "city": "M'banza-congo",
    "country": "Angola",
    "IATA": "SSY",
    "icao_code": "FNBC"
  },
  {
    "city": "MONTGOMERY",
    "country": "United States",
    "IATA": "MGM",
    "icao_code": "KMGM"
  },
  {
    "city": "Maamigili",
    "country": "Maldives",
    "IATA": "VAM",
    "icao_code": "VRMV"
  },
  {
    "city": "Maastricht",
    "country": "Netherlands",
    "IATA": "MST",
    "icao_code": "EHBK"
  },
  {
    "city": "Mabaruma",
    "country": "Guyana",
    "IATA": "USI",
    "icao_code": "SYMB"
  },
  {
    "city": "Mabuiag Island",
    "country": "Australia",
    "IATA": "UBB",
    "icao_code": "YMAA"
  },
  {
    "city": "Macapa",
    "country": "Brazil",
    "IATA": "MCP",
    "icao_code": "SBMQ"
  },
  {
    "city": "Macara",
    "country": "Ecuador",
    "IATA": "MRR",
    "icao_code": "SEMA"
  },
  {
    "city": "Macas",
    "country": "Ecuador",
    "IATA": "XMS",
    "icao_code": "SEMC"
  },
  {
    "city": "Macau",
    "country": "Macau",
    "IATA": "MFM",
    "icao_code": "VMMC"
  },
  {
    "city": "Macaé",
    "country": "Brazil",
    "IATA": "MEA",
    "icao_code": "SBME"
  },
  {
    "city": "Maceio",
    "country": "Brazil",
    "IATA": "MCZ",
    "icao_code": "SBMO"
  },
  {
    "city": "Machala",
    "country": "Ecuador",
    "IATA": "MCH",
    "icao_code": "SEMH"
  },
  {
    "city": "Mackay",
    "country": "Australia",
    "IATA": "MKY",
    "icao_code": "YBMK"
  },
  {
    "city": "Macon",
    "country": "United States",
    "IATA": "WRB"
  },
  {
    "city": "Macon",
    "country": "United States",
    "IATA": "MCN",
    "icao_code": "KMCN"
  },
  {
    "city": "Macon",
    "country": "United States",
    "IATA": "MNM",
    "icao_code": "KMNM"
  },
  {
    "city": "Madang",
    "country": "Papua New Guinea",
    "IATA": "MAG",
    "icao_code": "AYMD"
  },
  {
    "city": "Madera",
    "country": "United States",
    "IATA": "MAE",
    "icao_code": "KMAE"
  },
  {
    "city": "Madinah",
    "country": "Saudi Arabia",
    "IATA": "MED",
    "icao_code": "OEMA"
  },
  {
    "city": "Madison",
    "country": "United States",
    "IATA": "MSN",
    "icao_code": "KMSN"
  },
  {
    "city": "Madras",
    "country": "India",
    "IATA": "MAA",
    "icao_code": "VOMM"
  },
  {
    "city": "Madrid",
    "country": "Spain",
    "IATA": "ECV"
  },
  {
    "city": "Madrid",
    "country": "Spain",
    "IATA": "TOJ",
    "icao_code": "LETO"
  },
  {
    "city": "Madrid",
    "country": "Spain",
    "IATA": "MAD",
    "icao_code": "LEMD"
  },
  {
    "city": "Madurai",
    "country": "India",
    "IATA": "IXM",
    "icao_code": "VOMD"
  },
  {
    "city": "Mae Hong Son",
    "country": "Thailand",
    "IATA": "HGN",
    "icao_code": "VTCH"
  },
  {
    "city": "Maewo Island",
    "country": "Vanuatu",
    "IATA": "MWF",
    "icao_code": "NVSN"
  },
  {
    "city": "Mafeking",
    "country": "South Africa",
    "IATA": "MBD",
    "icao_code": "FAMM"
  },
  {
    "city": "Mafia Island",
    "country": "Tanzania",
    "IATA": "MFA",
    "icao_code": "HTMA"
  },
  {
    "city": "Mafraq",
    "country": "Jordan",
    "IATA": "OMF",
    "icao_code": "OJMF"
  },
  {
    "city": "Magadan",
    "country": "Russia",
    "IATA": "GDX",
    "icao_code": "UHMM"
  },
  {
    "city": "Magangue",
    "country": "Colombia",
    "IATA": "MGN"
  },
  {
    "city": "Magas",
    "country": "Russia",
    "IATA": "IGT",
    "icao_code": "URMS"
  },
  {
    "city": "Magdeburg",
    "country": "Germany",
    "IATA": "ZMG"
  },
  {
    "city": "Magnetiogorsk",
    "country": "Russia",
    "IATA": "MQF",
    "icao_code": "USCM"
  },
  {
    "city": "Magwe",
    "country": "Burma",
    "IATA": "MWQ",
    "icao_code": "VYMW"
  },
  {
    "city": "Mahajanga",
    "country": "Madagascar",
    "IATA": "MJN",
    "icao_code": "FMNM"
  },
  {
    "city": "Mahdia",
    "country": "Guyana",
    "IATA": "MHA",
    "icao_code": "SYMD"
  },
  {
    "city": "Mahe",
    "country": "Seychelles",
    "IATA": "SEZ",
    "icao_code": "FSIA"
  },
  {
    "city": "Maiana",
    "country": "Kiribati",
    "IATA": "MNK"
  },
  {
    "city": "Maiduguri",
    "country": "Nigeria",
    "IATA": "MIU",
    "icao_code": "DNMA"
  },
  {
    "city": "Maimama",
    "country": "Afghanistan",
    "IATA": "MMZ",
    "icao_code": "OAMN"
  },
  {
    "city": "Maintirano",
    "country": "Madagascar",
    "IATA": "MXT",
    "icao_code": "FMMO"
  },
  {
    "city": "Maio",
    "country": "Cape Verde",
    "IATA": "MMO",
    "icao_code": "GVMA"
  },
  {
    "city": "Maitland",
    "country": "Australia",
    "IATA": "MTL",
    "icao_code": "YMND"
  },
  {
    "city": "Majuro",
    "country": "Marshall Islands",
    "IATA": "MAJ",
    "icao_code": "PKMJ"
  },
  {
    "city": "Makale",
    "country": "Indonesia",
    "IATA": "TTR"
  },
  {
    "city": "Makale",
    "country": "Ethiopia",
    "IATA": "MQX",
    "icao_code": "HAMK"
  },
  {
    "city": "Makemo",
    "country": "French Polynesia",
    "IATA": "MKP",
    "icao_code": "NTGM"
  },
  {
    "city": "Makhachkala",
    "country": "Russia",
    "IATA": "MCX",
    "icao_code": "URML"
  },
  {
    "city": "Makin",
    "country": "Kiribati",
    "IATA": "MTK"
  },
  {
    "city": "Makkovik",
    "country": "Canada",
    "IATA": "YMN",
    "icao_code": "CYFT"
  },
  {
    "city": "Makokou",
    "country": "Gabon",
    "IATA": "MKU",
    "icao_code": "FOOK"
  },
  {
    "city": "Makung",
    "country": "Taiwan",
    "IATA": "MZG",
    "icao_code": "RCQC"
  },
  {
    "city": "Makurdi",
    "country": "Nigeria",
    "IATA": "MDI",
    "icao_code": "DNMK"
  },
  {
    "city": "Malabo",
    "country": "Equatorial Guinea",
    "IATA": "SSG",
    "icao_code": "FGSL"
  },
  {
    "city": "Malacca",
    "country": "Malaysia",
    "IATA": "MKZ",
    "icao_code": "WMKM"
  },
  {
    "city": "Malaga",
    "country": "Spain",
    "IATA": "AGP",
    "icao_code": "LEMG"
  },
  {
    "city": "Malakal",
    "country": "Sudan",
    "IATA": "MAK",
    "icao_code": "HSSM"
  },
  {
    "city": "Malalane",
    "country": "South Africa",
    "IATA": "LLE"
  },
  {
    "city": "Malamala",
    "country": "South Africa",
    "IATA": "AAM",
    "icao_code": "FAMD"
  },
  {
    "city": "Malang",
    "country": "Indonesia",
    "IATA": "MLG",
    "icao_code": "WIAS"
  },
  {
    "city": "Malanje",
    "country": "Angola",
    "IATA": "MEG",
    "icao_code": "FNMA"
  },
  {
    "city": "Malargue",
    "country": "Argentina",
    "IATA": "LGS",
    "icao_code": "SAMM"
  },
  {
    "city": "Malatya",
    "country": "Turkey",
    "IATA": "MLX",
    "icao_code": "LTAT"
  },
  {
    "city": "Male",
    "country": "Maldives",
    "IATA": "MLE",
    "icao_code": "VRMM"
  },
  {
    "city": "Malekula Island",
    "country": "Vanuatu",
    "IATA": "SWJ",
    "icao_code": "NVSX"
  },
  {
    "city": "Mali Losinj",
    "country": "Croatia",
    "IATA": "LSZ",
    "icao_code": "LDLO"
  },
  {
    "city": "Malindi",
    "country": "Kenya",
    "IATA": "MYD",
    "icao_code": "HKML"
  },
  {
    "city": "Mallacoota",
    "country": "Australia",
    "IATA": "XMC",
    "icao_code": "YMCO"
  },
  {
    "city": "Malmoe",
    "country": "Sweden",
    "IATA": "MMX",
    "icao_code": "ESMS"
  },
  {
    "city": "Malolo Lailai Island",
    "country": "Fiji",
    "IATA": "PTF",
    "icao_code": "NFFO"
  },
  {
    "city": "Malta",
    "country": "Malta",
    "IATA": "MLA",
    "icao_code": "LMML"
  },
  {
    "city": "Mamburao",
    "country": "Philippines",
    "IATA": "MBO"
  },
  {
    "city": "Mammoth Lakes",
    "country": "United States",
    "IATA": "MMH",
    "icao_code": "KMMH"
  },
  {
    "city": "Mampikony",
    "country": "Madagascar",
    "IATA": "WMP"
  },
  {
    "city": "Man",
    "country": "Cote d'Ivoire",
    "IATA": "MJC",
    "icao_code": "DIMN"
  },
  {
    "city": "Mana Island",
    "country": "Fiji",
    "IATA": "MNF",
    "icao_code": "NFMA"
  },
  {
    "city": "Manado",
    "country": "Indonesia",
    "IATA": "MDC",
    "icao_code": "WAMM"
  },
  {
    "city": "Managua",
    "country": "Nicaragua",
    "IATA": "MGA",
    "icao_code": "MNMG"
  },
  {
    "city": "Manakara",
    "country": "Madagascar",
    "IATA": "WVK"
  },
  {
    "city": "Mananara",
    "country": "Madagascar",
    "IATA": "WMR",
    "icao_code": "FMNC"
  },
  {
    "city": "Manang",
    "country": "Nepal",
    "IATA": "NGX",
    "icao_code": "VNMA"
  },
  {
    "city": "Mananjary",
    "country": "Madagascar",
    "IATA": "MNJ",
    "icao_code": "FMSM"
  },
  {
    "city": "Manapouri",
    "country": "New Zealand",
    "IATA": "TEU"
  },
  {
    "city": "Manassas",
    "country": "United States",
    "IATA": "MNZ"
  },
  {
    "city": "Manaus",
    "country": "Brazil",
    "IATA": "PLL"
  },
  {
    "city": "Manaus",
    "country": "Brazil",
    "IATA": "MAO",
    "icao_code": "SBEG"
  },
  {
    "city": "Manchester",
    "country": "United Kingdom",
    "IATA": "MAN",
    "icao_code": "EGCC"
  },
  {
    "city": "Manchester NH",
    "country": "United States",
    "IATA": "MHT",
    "icao_code": "KMHT"
  },
  {
    "city": "Mandalay",
    "country": "Burma",
    "IATA": "MDL",
    "icao_code": "VYCZ"
  },
  {
    "city": "Mandritsara",
    "country": "Madagascar",
    "IATA": "WMA"
  },
  {
    "city": "Mangaia Island",
    "country": "Cook Islands",
    "IATA": "MGS",
    "icao_code": "NCGO"
  },
  {
    "city": "Mangalore",
    "country": "India",
    "IATA": "IXE",
    "icao_code": "VOML"
  },
  {
    "city": "Mangla",
    "country": "Pakistan",
    "IATA": "XJM"
  },
  {
    "city": "Mangochi",
    "country": "Malawi",
    "IATA": "MAI"
  },
  {
    "city": "Manhattan",
    "country": "United States",
    "IATA": "MHK",
    "icao_code": "KMHK"
  },
  {
    "city": "Manicore",
    "country": "Brazil",
    "IATA": "MNX",
    "icao_code": "SBMY"
  },
  {
    "city": "Manihi",
    "country": "French Polynesia",
    "IATA": "XMH",
    "icao_code": "NTGI"
  },
  {
    "city": "Manihiki Island",
    "country": "Cook Islands",
    "IATA": "MHX",
    "icao_code": "NCMH"
  },
  {
    "city": "Maniitsoq",
    "country": "Greenland",
    "IATA": "JSU",
    "icao_code": "BGMQ"
  },
  {
    "city": "Manila",
    "country": "Philippines",
    "IATA": "MNL",
    "icao_code": "RPLL"
  },
  {
    "city": "Maningrida",
    "country": "Australia",
    "IATA": "MNG",
    "icao_code": "YMGD"
  },
  {
    "city": "Manistee",
    "country": "United States",
    "IATA": "MBL",
    "icao_code": "KMBL"
  },
  {
    "city": "Manitouwadge",
    "country": "Canada",
    "IATA": "YMG",
    "icao_code": "CYMG"
  },
  {
    "city": "Manitowaning",
    "country": "Canada",
    "IATA": "YEM",
    "icao_code": "CYEM"
  },
  {
    "city": "Maniwaki",
    "country": "Canada",
    "IATA": "YMW",
    "icao_code": "CYMW"
  },
  {
    "city": "Manizales",
    "country": "Colombia",
    "IATA": "MZL",
    "icao_code": "SKMZ"
  },
  {
    "city": "Manja",
    "country": "Madagascar",
    "IATA": "MJA",
    "icao_code": "FMSJ"
  },
  {
    "city": "Manley Hot Springs",
    "country": "United States",
    "IATA": "MLY",
    "icao_code": "PAML"
  },
  {
    "city": "Mannheim",
    "country": "Germany",
    "IATA": "MHG",
    "icao_code": "EDFM"
  },
  {
    "city": "Manokotak",
    "country": "United States",
    "IATA": "KMO",
    "icao_code": "PAMB"
  },
  {
    "city": "Manokwari",
    "country": "Indonesia",
    "IATA": "MKW",
    "icao_code": "WASR"
  },
  {
    "city": "Mansa",
    "country": "Zambia",
    "IATA": "MNS",
    "icao_code": "FLMA"
  },
  {
    "city": "Mansfield",
    "country": "United States",
    "IATA": "MFD",
    "icao_code": "KMFD"
  },
  {
    "city": "Manston",
    "country": "United Kingdom",
    "IATA": "MSE"
  },
  {
    "city": "Manta",
    "country": "Ecuador",
    "IATA": "MEC",
    "icao_code": "SEMT"
  },
  {
    "city": "Manteo",
    "country": "United States",
    "IATA": "MEO"
  },
  {
    "city": "Manzanillo",
    "country": "Cuba",
    "IATA": "MZO",
    "icao_code": "MUMZ"
  },
  {
    "city": "Manzanillo",
    "country": "Mexico",
    "IATA": "ZLO",
    "icao_code": "MMZO"
  },
  {
    "city": "Manzhouli",
    "country": "China",
    "IATA": "NZH"
  },
  {
    "city": "Manzini",
    "country": "Swaziland",
    "IATA": "SHO"
  },
  {
    "city": "Manzini",
    "country": "Swaziland",
    "IATA": "MTS",
    "icao_code": "FDMS"
  },
  {
    "city": "Maputo",
    "country": "Mozambique",
    "IATA": "MPM",
    "icao_code": "FQMA"
  },
  {
    "city": "Mar Del Plata",
    "country": "Argentina",
    "IATA": "MDQ",
    "icao_code": "SAZM"
  },
  {
    "city": "Maraba",
    "country": "Brazil",
    "IATA": "MAB",
    "icao_code": "SBMA"
  },
  {
    "city": "Maracaibo",
    "country": "Venezuela",
    "IATA": "MAR",
    "icao_code": "SVMC"
  },
  {
    "city": "Maracay",
    "country": "Venezuela",
    "IATA": "MYC",
    "icao_code": "SVBS"
  },
  {
    "city": "Maradi",
    "country": "Niger",
    "IATA": "MFQ",
    "icao_code": "DRRM"
  },
  {
    "city": "Maragheh",
    "country": "Iran",
    "IATA": "ACP",
    "icao_code": "OITM"
  },
  {
    "city": "Marakei",
    "country": "Kiribati",
    "IATA": "MZK"
  },
  {
    "city": "Marana",
    "country": "United States",
    "IATA": "MZJ",
    "icao_code": "KMZJ"
  },
  {
    "city": "Marathon",
    "country": "Canada",
    "IATA": "YSP",
    "icao_code": "CYSP"
  },
  {
    "city": "Marathon",
    "country": "United States",
    "IATA": "MTH",
    "icao_code": "KMTH"
  },
  {
    "city": "Marau",
    "country": "Solomon Islands",
    "IATA": "RUS",
    "icao_code": "AGGU"
  },
  {
    "city": "Marco Island Airport",
    "country": "United States",
    "IATA": "MRK",
    "icao_code": "KMKY"
  },
  {
    "city": "Mardin",
    "country": "Turkey",
    "IATA": "MQM",
    "icao_code": "LTCR"
  },
  {
    "city": "Mare",
    "country": "New Caledonia",
    "IATA": "MEE",
    "icao_code": "NWWR"
  },
  {
    "city": "Marfa",
    "country": "United States",
    "IATA": "MRF",
    "icao_code": "KMRF"
  },
  {
    "city": "Margate",
    "country": "South Africa",
    "IATA": "MGH",
    "icao_code": "FAMG"
  },
  {
    "city": "Marham",
    "country": "United Kingdom",
    "IATA": "KNF",
    "icao_code": "EGYM"
  },
  {
    "city": "Maribo",
    "country": "Denmark",
    "IATA": "MRW"
  },
  {
    "city": "Maribor",
    "country": "Slovenia",
    "IATA": "MBX",
    "icao_code": "LJMB"
  },
  {
    "city": "Mariehamn",
    "country": "Finland",
    "IATA": "MHQ",
    "icao_code": "EFMA"
  },
  {
    "city": "Marietta",
    "country": "United States",
    "IATA": "MGE"
  },
  {
    "city": "Marina Di Campo",
    "country": "Italy",
    "IATA": "EBA",
    "icao_code": "LIRJ"
  },
  {
    "city": "Maringa",
    "country": "Brazil",
    "IATA": "MGF",
    "icao_code": "SBMG"
  },
  {
    "city": "Marion",
    "country": "United States",
    "IATA": "MWA",
    "icao_code": "KMWA"
  },
  {
    "city": "Maripasoula",
    "country": "French Guiana",
    "IATA": "MPY"
  },
  {
    "city": "Mariposa",
    "country": "United States",
    "IATA": "RMY",
    "icao_code": "KMPI"
  },
  {
    "city": "Mariquita",
    "country": "Colombia",
    "IATA": "MQU",
    "icao_code": "SKQU"
  },
  {
    "city": "Mariscal Estigarribia",
    "country": "Paraguay",
    "IATA": "ESG",
    "icao_code": "SGME"
  },
  {
    "city": "Mariupol International",
    "country": "Ukraine",
    "IATA": "MPW",
    "icao_code": "UKCM"
  },
  {
    "city": "Markovo",
    "country": "Russia",
    "IATA": "KVM"
  },
  {
    "city": "Maroantsetra",
    "country": "Madagascar",
    "IATA": "WMN",
    "icao_code": "FMNR"
  },
  {
    "city": "Maroochydore",
    "country": "Australia",
    "IATA": "MCY",
    "icao_code": "YBMC"
  },
  {
    "city": "Maroua",
    "country": "Cameroon",
    "IATA": "MVR",
    "icao_code": "FKKL"
  },
  {
    "city": "Marrakech",
    "country": "Morocco",
    "IATA": "RAK",
    "icao_code": "GMMX"
  },
  {
    "city": "Marsa Alam",
    "country": "Egypt",
    "IATA": "RMF",
    "icao_code": "HEMA"
  },
  {
    "city": "Marsa Brega",
    "country": "Libya",
    "IATA": "LMQ",
    "icao_code": "HLMB"
  },
  {
    "city": "Marseille",
    "country": "France",
    "IATA": "MRS",
    "icao_code": "LFML"
  },
  {
    "city": "Marsh Harbor",
    "country": "Bahamas",
    "IATA": "MHH",
    "icao_code": "MYAM"
  },
  {
    "city": "Marshall",
    "country": "United States",
    "IATA": "MLL",
    "icao_code": "PADM"
  },
  {
    "city": "Marshfield",
    "country": "United States",
    "IATA": "MFI"
  },
  {
    "city": "Martinsburg",
    "country": "United States",
    "IATA": "MRB",
    "icao_code": "KMRB"
  },
  {
    "city": "Marudi",
    "country": "Malaysia",
    "IATA": "MUR",
    "icao_code": "WBGM"
  },
  {
    "city": "Mary",
    "country": "Turkmenistan",
    "IATA": "MYP",
    "icao_code": "UTAM"
  },
  {
    "city": "Mary's Harbour",
    "country": "Canada",
    "IATA": "YMH",
    "icao_code": "CYMH"
  },
  {
    "city": "Maryborough",
    "country": "Australia",
    "IATA": "MBH",
    "icao_code": "YMYB"
  },
  {
    "city": "Marysville",
    "country": "United States",
    "IATA": "BAB",
    "icao_code": "KBAB"
  },
  {
    "city": "Marília",
    "country": "Brazil",
    "IATA": "MII",
    "icao_code": "SBML"
  },
  {
    "city": "Masai Mara",
    "country": "Kenya",
    "IATA": "MRE"
  },
  {
    "city": "Masamba",
    "country": "Indonesia",
    "IATA": "MXB",
    "icao_code": "WAWM"
  },
  {
    "city": "Masbate",
    "country": "Philippines",
    "IATA": "MBT",
    "icao_code": "RPVJ"
  },
  {
    "city": "Maseru",
    "country": "Lesotho",
    "IATA": "MSU",
    "icao_code": "FXMM"
  },
  {
    "city": "Mashhad",
    "country": "Iran",
    "IATA": "MHD",
    "icao_code": "OIMM"
  },
  {
    "city": "Masirah",
    "country": "Oman",
    "IATA": "MSH",
    "icao_code": "OOMA"
  },
  {
    "city": "Mason City",
    "country": "United States",
    "IATA": "MCW",
    "icao_code": "KMCW"
  },
  {
    "city": "Massawa",
    "country": "Eritrea",
    "IATA": "MSW",
    "icao_code": "HHMS"
  },
  {
    "city": "Massena",
    "country": "United States",
    "IATA": "MSS",
    "icao_code": "KMSS"
  },
  {
    "city": "Masset",
    "country": "Canada",
    "IATA": "ZMT",
    "icao_code": "CZMT"
  },
  {
    "city": "Masterton",
    "country": "New Zealand",
    "IATA": "MRO",
    "icao_code": "NZMS"
  },
  {
    "city": "Masvingo",
    "country": "Zimbabwe",
    "IATA": "MVZ",
    "icao_code": "FVMV"
  },
  {
    "city": "Matadi",
    "country": "Congo (Kinshasa)",
    "IATA": "MAT",
    "icao_code": "FZAM"
  },
  {
    "city": "Matagami",
    "country": "Canada",
    "IATA": "YNM",
    "icao_code": "CYNM"
  },
  {
    "city": "Mataiva",
    "country": "French Polynesia",
    "IATA": "MVT",
    "icao_code": "NTGV"
  },
  {
    "city": "Matam",
    "country": "Senegal",
    "IATA": "MAX",
    "icao_code": "GOSM"
  },
  {
    "city": "Matamoros",
    "country": "Mexico",
    "IATA": "MAM",
    "icao_code": "MMMA"
  },
  {
    "city": "Matane",
    "country": "Canada",
    "IATA": "YME",
    "icao_code": "CYME"
  },
  {
    "city": "Mataram",
    "country": "Indonesia",
    "IATA": "AMI"
  },
  {
    "city": "Matei",
    "country": "Fiji",
    "IATA": "TVU",
    "icao_code": "NFNM"
  },
  {
    "city": "Matsu Islands",
    "country": "Taiwan",
    "IATA": "MFK",
    "icao_code": "RCMT"
  },
  {
    "city": "Matsu Islands",
    "country": "Taiwan",
    "IATA": "LZN",
    "icao_code": "RCFG"
  },
  {
    "city": "Matsumoto",
    "country": "Japan",
    "IATA": "MMJ",
    "icao_code": "RJAF"
  },
  {
    "city": "Matsuyama",
    "country": "Japan",
    "IATA": "MYJ",
    "icao_code": "RJOM"
  },
  {
    "city": "Mattala",
    "country": "Sri Lanka",
    "IATA": "HRI"
  },
  {
    "city": "Matthew Town",
    "country": "Bahamas",
    "IATA": "IGA",
    "icao_code": "MYIG"
  },
  {
    "city": "Maturin",
    "country": "Venezuela",
    "IATA": "MUN",
    "icao_code": "SVMT"
  },
  {
    "city": "Maubeuge",
    "country": "France",
    "IATA": "XME"
  },
  {
    "city": "Maues",
    "country": "Brazil",
    "IATA": "MBZ",
    "icao_code": "SWMW"
  },
  {
    "city": "Mauke Island",
    "country": "Cook Islands",
    "IATA": "MUK",
    "icao_code": "NCMK"
  },
  {
    "city": "Maumere",
    "country": "Indonesia",
    "IATA": "MOF",
    "icao_code": "WRKC"
  },
  {
    "city": "Maun",
    "country": "Botswana",
    "IATA": "MUB",
    "icao_code": "FBMN"
  },
  {
    "city": "Maupiti",
    "country": "French Polynesia",
    "IATA": "MAU",
    "icao_code": "NTTP"
  },
  {
    "city": "Mawlamyine",
    "country": "Burma",
    "IATA": "MNU",
    "icao_code": "VYMM"
  },
  {
    "city": "Mayaguana",
    "country": "Bahamas",
    "IATA": "MYG",
    "icao_code": "MYMM"
  },
  {
    "city": "Mayaguez",
    "country": "Puerto Rico",
    "IATA": "MAZ",
    "icao_code": "TJMZ"
  },
  {
    "city": "Mayo",
    "country": "Canada",
    "IATA": "YMA",
    "icao_code": "CYMA"
  },
  {
    "city": "Mayport",
    "country": "United States",
    "IATA": "NRB"
  },
  {
    "city": "Mayumba",
    "country": "Gabon",
    "IATA": "MYB",
    "icao_code": "FOOY"
  },
  {
    "city": "Mazar-i-sharif",
    "country": "Afghanistan",
    "IATA": "MZR",
    "icao_code": "OAMS"
  },
  {
    "city": "Mazatlan",
    "country": "Mexico",
    "IATA": "MZT",
    "icao_code": "MMMZ"
  },
  {
    "city": "Mazuffarpur",
    "country": "India",
    "IATA": "MZU",
    "icao_code": "VEMZ"
  },
  {
    "city": "Mbambanakira",
    "country": "Solomon Islands",
    "IATA": "MBU"
  },
  {
    "city": "Mbandaka",
    "country": "Congo (Kinshasa)",
    "IATA": "MDK",
    "icao_code": "FZEA"
  },
  {
    "city": "Mbeya",
    "country": "Tanzania",
    "IATA": "MBI",
    "icao_code": "HTMB"
  },
  {
    "city": "Mbuji-mayi",
    "country": "Congo (Kinshasa)",
    "IATA": "MJM",
    "icao_code": "FZWA"
  },
  {
    "city": "Mc Comb",
    "country": "United States",
    "IATA": "MCB"
  },
  {
    "city": "McArthur River Mine",
    "country": "Australia",
    "IATA": "MCV",
    "icao_code": "YMHU"
  },
  {
    "city": "McCall",
    "country": "United States",
    "IATA": "MYL",
    "icao_code": "KMYL"
  },
  {
    "city": "McCook",
    "country": "United States",
    "IATA": "MCK",
    "icao_code": "KMCK"
  },
  {
    "city": "McKinley Park",
    "country": "United States",
    "IATA": "MCL"
  },
  {
    "city": "Mcalester",
    "country": "United States",
    "IATA": "MLC",
    "icao_code": "KMLC"
  },
  {
    "city": "Mcallen",
    "country": "United States",
    "IATA": "MFE",
    "icao_code": "KMFE"
  },
  {
    "city": "Mcgrath",
    "country": "United States",
    "IATA": "MCG",
    "icao_code": "PAMC"
  },
  {
    "city": "Meadow Lake",
    "country": "Canada",
    "IATA": "YLJ",
    "icao_code": "CYLJ"
  },
  {
    "city": "Mecheria",
    "country": "Algeria",
    "IATA": "MZW",
    "icao_code": "DAAY"
  },
  {
    "city": "Medan",
    "country": "Indonesia",
    "IATA": "KNO"
  },
  {
    "city": "Medan",
    "country": "Indonesia",
    "IATA": "MES",
    "icao_code": "WIMM"
  },
  {
    "city": "Medellin",
    "country": "Colombia",
    "IATA": "EOH",
    "icao_code": "SKMD"
  },
  {
    "city": "Medford",
    "country": "United States",
    "IATA": "MFR",
    "icao_code": "KMFR"
  },
  {
    "city": "Medicine Hat",
    "country": "Canada",
    "IATA": "YXH",
    "icao_code": "CYXH"
  },
  {
    "city": "Meekatharra",
    "country": "Australia",
    "IATA": "MKR",
    "icao_code": "YMEK"
  },
  {
    "city": "Meghauli",
    "country": "Nepal",
    "IATA": "MEY",
    "icao_code": "VNMG"
  },
  {
    "city": "Mehamn",
    "country": "Norway",
    "IATA": "MEH",
    "icao_code": "ENMR"
  },
  {
    "city": "Meixian",
    "country": "China",
    "IATA": "MXZ",
    "icao_code": "ZGMX"
  },
  {
    "city": "Mekane Selam",
    "country": "Ethiopia",
    "IATA": "MKS",
    "icao_code": "HAMA"
  },
  {
    "city": "Meknes",
    "country": "Morocco",
    "IATA": "MEK",
    "icao_code": "GMFM"
  },
  {
    "city": "Mekoryuk",
    "country": "United States",
    "IATA": "MYU",
    "icao_code": "PAMY"
  },
  {
    "city": "Melbourne",
    "country": "United States",
    "IATA": "MLB",
    "icao_code": "KMLB"
  },
  {
    "city": "Melbourne",
    "country": "Australia",
    "IATA": "MEL",
    "icao_code": "YMML"
  },
  {
    "city": "Melbourne",
    "country": "Australia",
    "IATA": "MBW",
    "icao_code": "YMMB"
  },
  {
    "city": "Melbourne",
    "country": "Australia",
    "IATA": "MEB",
    "icao_code": "YMEN"
  },
  {
    "city": "Melilla",
    "country": "Spain",
    "IATA": "MLN",
    "icao_code": "GEML"
  },
  {
    "city": "Melo",
    "country": "Uruguay",
    "IATA": "MLZ",
    "icao_code": "SUMO"
  },
  {
    "city": "Melonguane",
    "country": "Indonesia",
    "IATA": "MNA",
    "icao_code": "WAMN"
  },
  {
    "city": "Memanbetsu",
    "country": "Japan",
    "IATA": "MMB",
    "icao_code": "RJCM"
  },
  {
    "city": "Memmingen",
    "country": "Germany",
    "IATA": "FMM",
    "icao_code": "EDJA"
  },
  {
    "city": "Memphis",
    "country": "United States",
    "IATA": "MEM",
    "icao_code": "KMEM"
  },
  {
    "city": "Mende",
    "country": "France",
    "IATA": "MEN",
    "icao_code": "LFNB"
  },
  {
    "city": "Mendi",
    "country": "Papua New Guinea",
    "IATA": "MDU",
    "icao_code": "AYMN"
  },
  {
    "city": "Mendoza",
    "country": "Argentina",
    "IATA": "MDZ",
    "icao_code": "SAME"
  },
  {
    "city": "Mengnai",
    "country": "China",
    "IATA": "HTT",
    "icao_code": "ZLHX"
  },
  {
    "city": "Menongue",
    "country": "Angola",
    "IATA": "SPP",
    "icao_code": "FNME"
  },
  {
    "city": "Menorca",
    "country": "Spain",
    "IATA": "MAH",
    "icao_code": "LEMH"
  },
  {
    "city": "Merauke",
    "country": "Indonesia",
    "IATA": "MKQ",
    "icao_code": "WAKK"
  },
  {
    "city": "Merced",
    "country": "United States",
    "IATA": "MCE",
    "icao_code": "KMCE"
  },
  {
    "city": "Merced",
    "country": "United States",
    "IATA": "MER",
    "icao_code": "KMER"
  },
  {
    "city": "Mercury",
    "country": "United States",
    "IATA": "DRA",
    "icao_code": "KDRA"
  },
  {
    "city": "Merida",
    "country": "Venezuela",
    "IATA": "MRD",
    "icao_code": "SVMD"
  },
  {
    "city": "Merida",
    "country": "Mexico",
    "IATA": "MID",
    "icao_code": "MMMD"
  },
  {
    "city": "Meridian",
    "country": "United States",
    "IATA": "MEI",
    "icao_code": "KMEI"
  },
  {
    "city": "Merimbula",
    "country": "Australia",
    "IATA": "MIM",
    "icao_code": "YMER"
  },
  {
    "city": "Merowe",
    "country": "Sudan",
    "IATA": "MWE",
    "icao_code": "HSMR"
  },
  {
    "city": "Mersa-matruh",
    "country": "Egypt",
    "IATA": "MUH",
    "icao_code": "HEMM"
  },
  {
    "city": "Meru National Park",
    "country": "Kenya",
    "IATA": "JJM",
    "icao_code": "HKMK"
  },
  {
    "city": "Merville",
    "country": "France",
    "IATA": "HZB",
    "icao_code": "LFQT"
  },
  {
    "city": "Merzifon",
    "country": "Turkey",
    "IATA": "MZH",
    "icao_code": "LTAP"
  },
  {
    "city": "Mesa",
    "country": "United States",
    "IATA": "MSC"
  },
  {
    "city": "Mesa",
    "country": "United States",
    "IATA": "AZA",
    "icao_code": "KIWA"
  },
  {
    "city": "Metakatla",
    "country": "United States",
    "IATA": "MTM",
    "icao_code": "PAMM"
  },
  {
    "city": "Metz",
    "country": "France",
    "IATA": "MZM"
  },
  {
    "city": "Metz",
    "country": "France",
    "IATA": "ETZ",
    "icao_code": "LFJL"
  },
  {
    "city": "Metzada",
    "country": "Israel",
    "IATA": "MTZ",
    "icao_code": "LLMZ"
  },
  {
    "city": "Mexicali",
    "country": "Mexico",
    "IATA": "MXL",
    "icao_code": "MMML"
  },
  {
    "city": "Mexico City",
    "country": "Mexico",
    "IATA": "MEX",
    "icao_code": "MMMX"
  },
  {
    "city": "Mfuwe",
    "country": "Zambia",
    "IATA": "MFU",
    "icao_code": "FLMF"
  },
  {
    "city": "South Florida, Miami",
    "country": "United States",
    "IATA": "OPF",
    "icao_code": "KOPF"
  },
  {
    "city": "South Florida, Opa-Locka",
    "country": "United States",
    "IATA": "OPF",
    "icao_code": "KOPF"
  },
  {
    "city": "South Florida, Miami",
    "country": "United States",
    "IATA": "TNT",
    "icao_code": "KTNT"
  },
  {
    "city": "South Florida, Miami",
    "country": "United States",
    "IATA": "MIA",
    "icao_code": "KMIA"
  },
  {
    "city": "Miandrivazo",
    "country": "Madagascar",
    "IATA": "ZVA"
  },
  {
    "city": "Mianwali",
    "country": "Pakistan",
    "IATA": "MWD",
    "icao_code": "OPMI"
  },
  {
    "city": "Mianyang",
    "country": "China",
    "IATA": "MIG",
    "icao_code": "EDPN"
  },
  {
    "city": "Michigan City",
    "country": "United States",
    "IATA": "MGC",
    "icao_code": "KMGC"
  },
  {
    "city": "Middle Caicos",
    "country": "Turks and Caicos Islands",
    "IATA": "MDS",
    "icao_code": "MBMC"
  },
  {
    "city": "Midland",
    "country": "United States",
    "IATA": "MAF",
    "icao_code": "KMAF"
  },
  {
    "city": "Midway",
    "country": "Midway Islands",
    "IATA": "MDY",
    "icao_code": "PMDY"
  },
  {
    "city": "Miho",
    "country": "Japan",
    "IATA": "YGJ",
    "icao_code": "RJOH"
  },
  {
    "city": "Mikkeli",
    "country": "Finland",
    "IATA": "MIK",
    "icao_code": "EFMI"
  },
  {
    "city": "Milan",
    "country": "Italy",
    "IATA": "LIN",
    "icao_code": "LIML"
  },
  {
    "city": "Milano",
    "country": "Italy",
    "IATA": "MXP",
    "icao_code": "LIMC"
  },
  {
    "city": "Mildenhall",
    "country": "United Kingdom",
    "IATA": "MHZ"
  },
  {
    "city": "Mildura",
    "country": "Australia",
    "IATA": "MQL",
    "icao_code": "YMIA"
  },
  {
    "city": "Miles City",
    "country": "United States",
    "IATA": "MLS",
    "icao_code": "KMLS"
  },
  {
    "city": "Milford Sound",
    "country": "New Zealand",
    "IATA": "MFN",
    "icao_code": "NZMF"
  },
  {
    "city": "Mili Island",
    "country": "Marshall Islands",
    "IATA": "MIJ"
  },
  {
    "city": "Milingimbi",
    "country": "Australia",
    "IATA": "MGT",
    "icao_code": "YMGB"
  },
  {
    "city": "Millington",
    "country": "United States",
    "IATA": "NQA",
    "icao_code": "KNQA"
  },
  {
    "city": "Millville",
    "country": "United States",
    "IATA": "MIV",
    "icao_code": "KMIV"
  },
  {
    "city": "Milos",
    "country": "Greece",
    "IATA": "MLO",
    "icao_code": "LGML"
  },
  {
    "city": "Milton",
    "country": "United States",
    "IATA": "NSE"
  },
  {
    "city": "Milwaukee",
    "country": "United States",
    "IATA": "MWC",
    "icao_code": "KMWC"
  },
  {
    "city": "Milwaukee",
    "country": "United States",
    "IATA": "MKE",
    "icao_code": "KMKE"
  },
  {
    "city": "Minacu",
    "country": "Brazil",
    "IATA": "MQH",
    "icao_code": "SBMC"
  },
  {
    "city": "Minami Daito",
    "country": "Japan",
    "IATA": "MMD",
    "icao_code": "ROMD"
  },
  {
    "city": "Minami Tori Shima",
    "country": "Japan",
    "IATA": "MUS"
  },
  {
    "city": "Minatitlan",
    "country": "Mexico",
    "IATA": "MTT",
    "icao_code": "MMMT"
  },
  {
    "city": "Mineral Wells",
    "country": "United States",
    "IATA": "MWL",
    "icao_code": "KMWL"
  },
  {
    "city": "Mineralnye Vody",
    "country": "Russia",
    "IATA": "MRV",
    "icao_code": "URMM"
  },
  {
    "city": "Minhad AB",
    "country": "United Arab Emirates",
    "IATA": "NHD"
  },
  {
    "city": "Minna",
    "country": "Nigeria",
    "IATA": "MXJ",
    "icao_code": "DNMN"
  },
  {
    "city": "Minneapolis",
    "country": "United States",
    "IATA": "MSP",
    "icao_code": "KMSP"
  },
  {
    "city": "Minocqua - Woodruff",
    "country": "United States",
    "IATA": "ARV",
    "icao_code": "KARV"
  },
  {
    "city": "Minot",
    "country": "United States",
    "IATA": "MIB"
  },
  {
    "city": "Minot",
    "country": "United States",
    "IATA": "MOT",
    "icao_code": "KMOT"
  },
  {
    "city": "Minsk",
    "country": "Belarus",
    "IATA": "MHP",
    "icao_code": "UMMM"
  },
  {
    "city": "Minsk 2",
    "country": "Belarus",
    "IATA": "MSQ",
    "icao_code": "UMMS"
  },
  {
    "city": "Miquelon",
    "country": "Saint Pierre and Miquelon",
    "IATA": "MQC",
    "icao_code": "LFVM"
  },
  {
    "city": "Miramar",
    "country": "United States",
    "IATA": "NKX"
  },
  {
    "city": "Miri",
    "country": "Malaysia",
    "IATA": "MYY",
    "icao_code": "WBGR"
  },
  {
    "city": "Mirnyj",
    "country": "Russia",
    "IATA": "MJZ",
    "icao_code": "UERR"
  },
  {
    "city": "Misawa",
    "country": "Japan",
    "IATA": "MSJ",
    "icao_code": "RJSM"
  },
  {
    "city": "Misima Island",
    "country": "Papua New Guinea",
    "IATA": "MIS",
    "icao_code": "AYMS"
  },
  {
    "city": "Missoula",
    "country": "United States",
    "IATA": "MSO",
    "icao_code": "KMSO"
  },
  {
    "city": "Mitchell",
    "country": "United States",
    "IATA": "MHE",
    "icao_code": "KMHE"
  },
  {
    "city": "Mitiaro Island",
    "country": "Cook Islands",
    "IATA": "MOI",
    "icao_code": "NCMR"
  },
  {
    "city": "Mitu",
    "country": "Colombia",
    "IATA": "MVP",
    "icao_code": "SKMU"
  },
  {
    "city": "Miyakejima",
    "country": "Japan",
    "IATA": "MYE",
    "icao_code": "RJTQ"
  },
  {
    "city": "Miyako",
    "country": "Japan",
    "IATA": "MMY",
    "icao_code": "ROMY"
  },
  {
    "city": "Miyazaki",
    "country": "Japan",
    "IATA": "KMI",
    "icao_code": "RJFM"
  },
  {
    "city": "Mizan Teferi",
    "country": "Ethiopia",
    "IATA": "MTF",
    "icao_code": "HAMT"
  },
  {
    "city": "Mkuze",
    "country": "South Africa",
    "IATA": "MZQ"
  },
  {
    "city": "Moa",
    "country": "Cuba",
    "IATA": "MOA",
    "icao_code": "MUMO"
  },
  {
    "city": "Moab",
    "country": "United States",
    "IATA": "CNY",
    "icao_code": "KCNY"
  },
  {
    "city": "Moala",
    "country": "Fiji",
    "IATA": "MFJ",
    "icao_code": "NFMO"
  },
  {
    "city": "Mobile",
    "country": "United States",
    "IATA": "MOB",
    "icao_code": "KMOB"
  },
  {
    "city": "Mobile",
    "country": "United States",
    "IATA": "BFM",
    "icao_code": "KBFM"
  },
  {
    "city": "Mobridge",
    "country": "United States",
    "IATA": "MBG",
    "icao_code": "KMBG"
  },
  {
    "city": "Mocamedes",
    "country": "Angola",
    "IATA": "MSZ",
    "icao_code": "FNMO"
  },
  {
    "city": "Mocimboa Da Praia",
    "country": "Mozambique",
    "IATA": "MZB",
    "icao_code": "FQMP"
  },
  {
    "city": "Mocord",
    "country": "Brazil",
    "IATA": "MVF",
    "icao_code": "SBMW"
  },
  {
    "city": "Modesto",
    "country": "United States",
    "IATA": "MOD",
    "icao_code": "KMOD"
  },
  {
    "city": "Moenchengladbach",
    "country": "Germany",
    "IATA": "MGL",
    "icao_code": "EDLN"
  },
  {
    "city": "Moengo",
    "country": "Suriname",
    "IATA": "MOJ"
  },
  {
    "city": "Moenjodaro",
    "country": "Pakistan",
    "IATA": "MJD",
    "icao_code": "OPMJ"
  },
  {
    "city": "Mogadishu",
    "country": "Somalia",
    "IATA": "MGQ",
    "icao_code": "HCMM"
  },
  {
    "city": "Mogilev",
    "country": "Belarus",
    "IATA": "MVQ",
    "icao_code": "UMOO"
  },
  {
    "city": "Mohanbari",
    "country": "India",
    "IATA": "DIB",
    "icao_code": "VEMN"
  },
  {
    "city": "Mohe County",
    "country": "China",
    "IATA": "OHE",
    "icao_code": "ZYMH"
  },
  {
    "city": "Mohed",
    "country": "Sweden",
    "IATA": "SQO",
    "icao_code": "ESUD"
  },
  {
    "city": "Moheli",
    "country": "Comoros",
    "IATA": "NWA",
    "icao_code": "FMCI"
  },
  {
    "city": "Mojave",
    "country": "United States",
    "IATA": "MHV",
    "icao_code": "KMHV"
  },
  {
    "city": "Mokpo",
    "country": "South Korea",
    "IATA": "MPK"
  },
  {
    "city": "Mokuti Lodge",
    "country": "Namibia",
    "IATA": "OKU",
    "icao_code": "FYMO"
  },
  {
    "city": "Molde",
    "country": "Norway",
    "IATA": "MOL",
    "icao_code": "ENML"
  },
  {
    "city": "Moline",
    "country": "United States",
    "IATA": "MLI",
    "icao_code": "KMLI"
  },
  {
    "city": "Molokai",
    "country": "United States",
    "IATA": "LUP",
    "icao_code": "PHLU"
  },
  {
    "city": "Molokai",
    "country": "United States",
    "IATA": "MKK",
    "icao_code": "PHMK"
  },
  {
    "city": "Mombasa",
    "country": "Kenya",
    "IATA": "MBA",
    "icao_code": "HKMO"
  },
  {
    "city": "Momeik",
    "country": "Burma",
    "IATA": "MOE",
    "icao_code": "VYMO"
  },
  {
    "city": "Momote",
    "country": "Papua New Guinea",
    "IATA": "MAS",
    "icao_code": "AYMO"
  },
  {
    "city": "Monastir",
    "country": "Tunisia",
    "IATA": "MIR",
    "icao_code": "DTMB"
  },
  {
    "city": "Monbetsu",
    "country": "Japan",
    "IATA": "MBE",
    "icao_code": "RJEB"
  },
  {
    "city": "Monclova",
    "country": "Mexico",
    "IATA": "LOV",
    "icao_code": "MMMV"
  },
  {
    "city": "Moncton",
    "country": "Canada",
    "IATA": "YQM",
    "icao_code": "CYQM"
  },
  {
    "city": "Mong Hsat",
    "country": "Burma",
    "IATA": "MOG",
    "icao_code": "VYMS"
  },
  {
    "city": "Mongu",
    "country": "Zambia",
    "IATA": "MNR",
    "icao_code": "FLMG"
  },
  {
    "city": "Monkey Bay",
    "country": "Malawi",
    "IATA": "MYZ",
    "icao_code": "FWMY"
  },
  {
    "city": "Monroe",
    "country": "United States",
    "IATA": "MLU",
    "icao_code": "KMLU"
  },
  {
    "city": "Monrovia",
    "country": "Liberia",
    "IATA": "ROB",
    "icao_code": "GLRB"
  },
  {
    "city": "Monrovia",
    "country": "Liberia",
    "IATA": "MLW",
    "icao_code": "GLMR"
  },
  {
    "city": "Mont Joli",
    "country": "Canada",
    "IATA": "YYY",
    "icao_code": "CYYY"
  },
  {
    "city": "Mont-Tremblant",
    "country": "Canada",
    "IATA": "YTM",
    "icao_code": "CYFJ"
  },
  {
    "city": "Montauban",
    "country": "France",
    "IATA": "XMW"
  },
  {
    "city": "Montauk",
    "country": "United States",
    "IATA": "MTP",
    "icao_code": "KMTP"
  },
  {
    "city": "Monte Caseros",
    "country": "Argentina",
    "IATA": "MCS",
    "icao_code": "SARM"
  },
  {
    "city": "Monte Real",
    "country": "Portugal",
    "IATA": "QLR"
  },
  {
    "city": "Montego Bay",
    "country": "Jamaica",
    "IATA": "MBJ",
    "icao_code": "MKJS"
  },
  {
    "city": "Monterey",
    "country": "United States",
    "IATA": "MRY",
    "icao_code": "KMRY"
  },
  {
    "city": "Monteria",
    "country": "Colombia",
    "IATA": "MTR",
    "icao_code": "SKMR"
  },
  {
    "city": "Monterrey",
    "country": "Mexico",
    "IATA": "MTY",
    "icao_code": "MMMY"
  },
  {
    "city": "Monterrey",
    "country": "Mexico",
    "IATA": "NTR",
    "icao_code": "MMAN"
  },
  {
    "city": "Montes Claros",
    "country": "Brazil",
    "IATA": "MOC",
    "icao_code": "SBMK"
  },
  {
    "city": "Montevideo",
    "country": "Uruguay",
    "IATA": "MVD",
    "icao_code": "SUMU"
  },
  {
    "city": "Montgomery",
    "country": "United States",
    "IATA": "MGJ",
    "icao_code": "KMGJ"
  },
  {
    "city": "Montgomery",
    "country": "United States",
    "IATA": "MXF",
    "icao_code": "KMXF"
  },
  {
    "city": "Montlucon-gueret",
    "country": "France",
    "IATA": "MCU",
    "icao_code": "LFLT"
  },
  {
    "city": "Montpelier",
    "country": "United States",
    "IATA": "MPV",
    "icao_code": "KMPV"
  },
  {
    "city": "Montpellier",
    "country": "France",
    "IATA": "MPL",
    "icao_code": "LFMT"
  },
  {
    "city": "Montreal",
    "country": "Canada",
    "IATA": "YUL",
    "icao_code": "CYUL"
  },
  {
    "city": "Montreal",
    "country": "Canada",
    "IATA": "YMX",
    "icao_code": "CYMX"
  },
  {
    "city": "Montreal",
    "country": "Canada",
    "IATA": "YHU",
    "icao_code": "CYHU"
  },
  {
    "city": "Montrose CO",
    "country": "United States",
    "IATA": "MTJ",
    "icao_code": "KMTJ"
  },
  {
    "city": "Monument Valley",
    "country": "United States",
    "IATA": "GMV"
  },
  {
    "city": "Monywa",
    "country": "Burma",
    "IATA": "NYW",
    "icao_code": "VYMY"
  },
  {
    "city": "Moomba",
    "country": "Australia",
    "IATA": "MOO",
    "icao_code": "YOOM"
  },
  {
    "city": "Moorea",
    "country": "French Polynesia",
    "IATA": "MOZ",
    "icao_code": "NTTM"
  },
  {
    "city": "Moose Jaw",
    "country": "Canada",
    "IATA": "YMJ",
    "icao_code": "CYMJ"
  },
  {
    "city": "Moosonee",
    "country": "Canada",
    "IATA": "YMO",
    "icao_code": "CYMO"
  },
  {
    "city": "Mopti",
    "country": "Mali",
    "IATA": "MZI",
    "icao_code": "GAMB"
  },
  {
    "city": "Mora",
    "country": "Sweden",
    "IATA": "MXX",
    "icao_code": "ESKM"
  },
  {
    "city": "Morafenobe",
    "country": "Madagascar",
    "IATA": "TVA"
  },
  {
    "city": "Moranbah",
    "country": "Australia",
    "IATA": "MOV",
    "icao_code": "YMRB"
  },
  {
    "city": "Moree",
    "country": "Australia",
    "IATA": "MRZ",
    "icao_code": "YMOR"
  },
  {
    "city": "Morelia",
    "country": "Mexico",
    "IATA": "MLM",
    "icao_code": "MMMM"
  },
  {
    "city": "Morganton",
    "country": "United States",
    "IATA": "MRN",
    "icao_code": "KMRN"
  },
  {
    "city": "Morgantown",
    "country": "United States",
    "IATA": "MGW",
    "icao_code": "KMGW"
  },
  {
    "city": "Morlaix",
    "country": "France",
    "IATA": "MXN",
    "icao_code": "LFRU"
  },
  {
    "city": "Mornington Island",
    "country": "Australia",
    "IATA": "ONG",
    "icao_code": "YMTI"
  },
  {
    "city": "Moro",
    "country": "Papua New Guinea",
    "IATA": "MXH",
    "icao_code": "AYMR"
  },
  {
    "city": "Morombe",
    "country": "Madagascar",
    "IATA": "MXM",
    "icao_code": "FMSR"
  },
  {
    "city": "Morondava",
    "country": "Madagascar",
    "IATA": "MOQ",
    "icao_code": "FMMV"
  },
  {
    "city": "Moroni",
    "country": "Comoros",
    "IATA": "YVA",
    "icao_code": "FMCN"
  },
  {
    "city": "Moroni",
    "country": "Comoros",
    "IATA": "HAH",
    "icao_code": "FMCH"
  },
  {
    "city": "Morotai Island",
    "country": "Indonesia",
    "IATA": "OTI",
    "icao_code": "WAMR"
  },
  {
    "city": "Morrisville",
    "country": "United States",
    "IATA": "MVL",
    "icao_code": "KMVL"
  },
  {
    "city": "Moruya",
    "country": "Australia",
    "IATA": "MYA",
    "icao_code": "YMRY"
  },
  {
    "city": "Morwell",
    "country": "Australia",
    "IATA": "TGN"
  },
  {
    "city": "Moscow",
    "country": "Russia",
    "IATA": "OSF",
    "icao_code": "UUMO"
  },
  {
    "city": "Moscow",
    "country": "Russia",
    "IATA": "BKA"
  },
  {
    "city": "Moscow",
    "country": "Russia",
    "IATA": "DME",
    "icao_code": "UUDD"
  },
  {
    "city": "Moscow",
    "country": "Russia",
    "IATA": "VKO",
    "icao_code": "UUWW"
  },
  {
    "city": "Moscow",
    "country": "Russia",
    "IATA": "SVO",
    "icao_code": "UUEE"
  },
  {
    "city": "Moshi",
    "country": "Tanzania",
    "IATA": "QSI"
  },
  {
    "city": "Mosjoen",
    "country": "Norway",
    "IATA": "MJF",
    "icao_code": "ENMS"
  },
  {
    "city": "Mostar",
    "country": "Bosnia and Herzegovina",
    "IATA": "OMO",
    "icao_code": "LQMO"
  },
  {
    "city": "Mosul",
    "country": "Iraq",
    "IATA": "OSM",
    "icao_code": "ORBM"
  },
  {
    "city": "Motueka",
    "country": "New Zealand",
    "IATA": "MZP"
  },
  {
    "city": "Mouila",
    "country": "Gabon",
    "IATA": "MJL",
    "icao_code": "FOGM"
  },
  {
    "city": "Moundou",
    "country": "Chad",
    "IATA": "MQQ",
    "icao_code": "FTTD"
  },
  {
    "city": "Mount Clemens",
    "country": "United States",
    "IATA": "MTC",
    "icao_code": "KMTC"
  },
  {
    "city": "Mount Cook",
    "country": "New Zealand",
    "IATA": "MON",
    "icao_code": "NZGT"
  },
  {
    "city": "Mount Gambier",
    "country": "Australia",
    "IATA": "MGB",
    "icao_code": "YMTG"
  },
  {
    "city": "Mount Hagen",
    "country": "Papua New Guinea",
    "IATA": "HGU",
    "icao_code": "AYMH"
  },
  {
    "city": "Mount Holly",
    "country": "United States",
    "IATA": "LLY"
  },
  {
    "city": "Mount Hotham",
    "country": "Australia",
    "IATA": "MHU",
    "icao_code": "YHOT"
  },
  {
    "city": "Mount Isa",
    "country": "Australia",
    "IATA": "ISA",
    "icao_code": "YBMA"
  },
  {
    "city": "Mount Keith",
    "country": "Australia",
    "IATA": "WME",
    "icao_code": "YMNE"
  },
  {
    "city": "Mount Magnet",
    "country": "Australia",
    "IATA": "MMG",
    "icao_code": "YMOG"
  },
  {
    "city": "Mount Pleasant",
    "country": "Falkland Islands",
    "IATA": "MPN",
    "icao_code": "EGYP"
  },
  {
    "city": "Mount Pocono",
    "country": "United States",
    "IATA": "MPO",
    "icao_code": "KMPO"
  },
  {
    "city": "Mountain Home",
    "country": "United States",
    "IATA": "WMH",
    "icao_code": "KBPK"
  },
  {
    "city": "Mountain Home",
    "country": "United States",
    "IATA": "MUO",
    "icao_code": "KMUO"
  },
  {
    "city": "Mountain View",
    "country": "United States",
    "IATA": "NUQ",
    "icao_code": "KNUQ"
  },
  {
    "city": "Mountain Village",
    "country": "United States",
    "IATA": "MOU",
    "icao_code": "PAMO"
  },
  {
    "city": "Moyale",
    "country": "Kenya",
    "IATA": "OYL",
    "icao_code": "HKMY"
  },
  {
    "city": "Mpacha",
    "country": "Namibia",
    "IATA": "MPA",
    "icao_code": "FAMP"
  },
  {
    "city": "Mpumalanga",
    "country": "South Africa",
    "IATA": "MQP",
    "icao_code": "FAKN"
  },
  {
    "city": "Mtwara",
    "country": "Tanzania",
    "IATA": "MYW",
    "icao_code": "HTMT"
  },
  {
    "city": "Muan",
    "country": "South Korea",
    "IATA": "MWX",
    "icao_code": "RKJB"
  },
  {
    "city": "Muanda",
    "country": "Congo (Kinshasa)",
    "IATA": "MNB",
    "icao_code": "FZAG"
  },
  {
    "city": "Muang Xay",
    "country": "Laos",
    "IATA": "ODY",
    "icao_code": "VLOS"
  },
  {
    "city": "Muara Bungo",
    "country": "Indonesia",
    "IATA": "BUU",
    "icao_code": "WIPI"
  },
  {
    "city": "Mucuri",
    "country": "Brazil",
    "IATA": "MVS",
    "icao_code": "SNMU"
  },
  {
    "city": "Mudanjiang",
    "country": "China",
    "IATA": "MDG",
    "icao_code": "ZYMD"
  },
  {
    "city": "Mudgee",
    "country": "Australia",
    "IATA": "DGE",
    "icao_code": "YMDG"
  },
  {
    "city": "Mueda",
    "country": "Mozambique",
    "IATA": "MUD"
  },
  {
    "city": "Muir",
    "country": "United States",
    "IATA": "MUI",
    "icao_code": "KMUI"
  },
  {
    "city": "Mukah",
    "country": "Malaysia",
    "IATA": "MKM",
    "icao_code": "WBGK"
  },
  {
    "city": "Mukalla",
    "country": "Yemen",
    "IATA": "RIY",
    "icao_code": "OYRN"
  },
  {
    "city": "Mulhouse",
    "country": "France",
    "IATA": "BSL",
    "icao_code": "_BSL"
  },
  {
    "city": "Mulia",
    "country": "Indonesia",
    "IATA": "LII",
    "icao_code": "WAJM"
  },
  {
    "city": "Multan",
    "country": "Pakistan",
    "IATA": "MUX",
    "icao_code": "OPMT"
  },
  {
    "city": "Mulu",
    "country": "Malaysia",
    "IATA": "MZV",
    "icao_code": "WBFC"
  },
  {
    "city": "Mumbai",
    "country": "India",
    "IATA": "BOM",
    "icao_code": "VABB"
  },
  {
    "city": "Muncie",
    "country": "United States",
    "IATA": "MIE"
  },
  {
    "city": "Munda",
    "country": "Solomon Islands",
    "IATA": "MUA",
    "icao_code": "AGGM"
  },
  {
    "city": "Munich",
    "country": "Germany",
    "IATA": "MUC",
    "icao_code": "EDDM"
  },
  {
    "city": "Munster",
    "country": "Germany",
    "IATA": "FMO",
    "icao_code": "EDDG"
  },
  {
    "city": "Murcia",
    "country": "Spain",
    "IATA": "RMU",
    "icao_code": "LEMI"
  },
  {
    "city": "Murcia",
    "country": "Spain",
    "IATA": "MJV",
    "icao_code": "LELC"
  },
  {
    "city": "Muren",
    "country": "Mongolia",
    "IATA": "MXV",
    "icao_code": "ZMMN"
  },
  {
    "city": "Murmansk",
    "country": "Russia",
    "IATA": "MMK",
    "icao_code": "ULMM"
  },
  {
    "city": "Murray Island",
    "country": "Australia",
    "IATA": "MYI",
    "icao_code": "YMUI"
  },
  {
    "city": "Murrieta-Temecula",
    "country": "United States",
    "IATA": "RBK"
  },
  {
    "city": "Mus",
    "country": "Turkey",
    "IATA": "MSR",
    "icao_code": "LTCK"
  },
  {
    "city": "Muscat",
    "country": "Oman",
    "IATA": "MCT",
    "icao_code": "OOMS"
  },
  {
    "city": "Muscle Shoals",
    "country": "United States",
    "IATA": "MSL",
    "icao_code": "KMSL"
  },
  {
    "city": "Muskegon",
    "country": "United States",
    "IATA": "MKG",
    "icao_code": "KMKG"
  },
  {
    "city": "Muskoka",
    "country": "Canada",
    "IATA": "YQA",
    "icao_code": "CYQA"
  },
  {
    "city": "Muskrat Dam",
    "country": "Canada",
    "IATA": "MSA",
    "icao_code": "CZMD"
  },
  {
    "city": "Musoma",
    "country": "Tanzania",
    "IATA": "MUZ",
    "icao_code": "HTMU"
  },
  {
    "city": "Musselwhite Mine",
    "country": "Canada",
    "IATA": "YBS"
  },
  {
    "city": "Mustique",
    "country": "Saint Vincent and the Grenadines",
    "IATA": "MQS",
    "icao_code": "TVSM"
  },
  {
    "city": "Mutare",
    "country": "Zimbabwe",
    "IATA": "UTA",
    "icao_code": "FVMU"
  },
  {
    "city": "Muzaffarabad",
    "country": "Pakistan",
    "IATA": "MFG",
    "icao_code": "OPMF"
  },
  {
    "city": "Mwanza",
    "country": "Tanzania",
    "IATA": "MWZ",
    "icao_code": "HTMW"
  },
  {
    "city": "Myeik",
    "country": "Burma",
    "IATA": "MGZ",
    "icao_code": "VYME"
  },
  {
    "city": "Myitkyina",
    "country": "Burma",
    "IATA": "MYT",
    "icao_code": "VYMK"
  },
  {
    "city": "Mykonos",
    "country": "Greece",
    "IATA": "JMK",
    "icao_code": "LGMK"
  },
  {
    "city": "Myrtle Beach",
    "country": "United States",
    "IATA": "MYR",
    "icao_code": "KMYR"
  },
  {
    "city": "Mysore",
    "country": "India",
    "IATA": "MYQ",
    "icao_code": "VOMY"
  },
  {
    "city": "Mytilini",
    "country": "Greece",
    "IATA": "MJT",
    "icao_code": "LGMT"
  },
  {
    "city": "Myvatn",
    "country": "Iceland",
    "IATA": "MVA",
    "icao_code": "BIRL"
  },
  {
    "city": "Mzuzu",
    "country": "Malawi",
    "IATA": "ZZU",
    "icao_code": "FWUU"
  },
  {
    "city": "N'djamena",
    "country": "Chad",
    "IATA": "NDJ",
    "icao_code": "FTTJ"
  },
  {
    "city": "N'gaoundere",
    "country": "Cameroon",
    "IATA": "NGE",
    "icao_code": "FKKN"
  },
  {
    "city": "NAPIER",
    "country": "New Zealand",
    "IATA": "NPE",
    "icao_code": "NZNR"
  },
  {
    "city": "NYERI",
    "country": "Kenya",
    "IATA": "NYE",
    "icao_code": "HKNI"
  },
  {
    "city": "Nabire",
    "country": "Indonesia",
    "IATA": "NBX",
    "icao_code": "WABI"
  },
  {
    "city": "Nacala",
    "country": "Mozambique",
    "IATA": "MNC"
  },
  {
    "city": "Nadunumu",
    "country": "Papua New Guinea",
    "IATA": "NDN"
  },
  {
    "city": "Nadym",
    "country": "Russia",
    "IATA": "NYM",
    "icao_code": "USMM"
  },
  {
    "city": "Nadzab",
    "country": "Papua New Guinea",
    "IATA": "LAE",
    "icao_code": "AYNZ"
  },
  {
    "city": "Naga",
    "country": "Philippines",
    "IATA": "WNP",
    "icao_code": "RPUN"
  },
  {
    "city": "Nagan Raya",
    "country": "Indonesia",
    "IATA": "MEQ",
    "icao_code": "WITC"
  },
  {
    "city": "Nagasaki",
    "country": "Japan",
    "IATA": "NGS",
    "icao_code": "RJFU"
  },
  {
    "city": "Nagoya",
    "country": "Japan",
    "IATA": "NKM",
    "icao_code": "RJNA"
  },
  {
    "city": "Nagoya",
    "country": "Japan",
    "IATA": "NGO",
    "icao_code": "RJGG"
  },
  {
    "city": "Nagpur",
    "country": "India",
    "IATA": "NAG",
    "icao_code": "VANP"
  },
  {
    "city": "Naha",
    "country": "Indonesia",
    "IATA": "NAH",
    "icao_code": "WAMH"
  },
  {
    "city": "Nain",
    "country": "Canada",
    "IATA": "YDP",
    "icao_code": "CYDP"
  },
  {
    "city": "Nainital",
    "country": "India",
    "IATA": "PGH",
    "icao_code": "VIPT"
  },
  {
    "city": "Nairobi",
    "country": "Kenya",
    "IATA": "NBO",
    "icao_code": "HKJK"
  },
  {
    "city": "Nairobi",
    "country": "Kenya",
    "IATA": "WIL",
    "icao_code": "HKNW"
  },
  {
    "city": "Najaf",
    "country": "Iraq",
    "IATA": "NJF",
    "icao_code": "ORNI"
  },
  {
    "city": "Nakashibetsu",
    "country": "Japan",
    "IATA": "SHB",
    "icao_code": "RJCN"
  },
  {
    "city": "Nakhchivan",
    "country": "Azerbaijan",
    "IATA": "NAJ",
    "icao_code": "UBBN"
  },
  {
    "city": "Nakhon Pathom",
    "country": "Thailand",
    "IATA": "KDT"
  },
  {
    "city": "Nakhon Phanom",
    "country": "Thailand",
    "IATA": "KOP",
    "icao_code": "VTUW"
  },
  {
    "city": "Nakhon Ratchasima",
    "country": "Thailand",
    "IATA": "NAK",
    "icao_code": "VTUQ"
  },
  {
    "city": "Nakhon Sawan",
    "country": "Thailand",
    "IATA": "TKH"
  },
  {
    "city": "Nakhon Si Thammarat",
    "country": "Thailand",
    "IATA": "NST",
    "icao_code": "VTSN"
  },
  {
    "city": "Nakina",
    "country": "Canada",
    "IATA": "YQN",
    "icao_code": "CYQN"
  },
  {
    "city": "Nalchik",
    "country": "Russia",
    "IATA": "NAL",
    "icao_code": "URMN"
  },
  {
    "city": "Namangan",
    "country": "Uzbekistan",
    "IATA": "NMA",
    "icao_code": "UTKN"
  },
  {
    "city": "Namlea",
    "country": "Indonesia",
    "IATA": "NAM",
    "icao_code": "WAPR"
  },
  {
    "city": "Nampula",
    "country": "Mozambique",
    "IATA": "APL",
    "icao_code": "FQNP"
  },
  {
    "city": "Namsang",
    "country": "Burma",
    "IATA": "NMS",
    "icao_code": "VYNS"
  },
  {
    "city": "Namsos",
    "country": "Norway",
    "IATA": "OSY",
    "icao_code": "ENNM"
  },
  {
    "city": "Nan",
    "country": "Thailand",
    "IATA": "NNT",
    "icao_code": "VTCN"
  },
  {
    "city": "Nanaimo",
    "country": "Canada",
    "IATA": "ZNA",
    "icao_code": "CAC8"
  },
  {
    "city": "Nanaimo",
    "country": "Canada",
    "IATA": "YCD",
    "icao_code": "CYCD"
  },
  {
    "city": "Nanchang",
    "country": "China",
    "IATA": "KHN",
    "icao_code": "ZSCN"
  },
  {
    "city": "Nanchong",
    "country": "China",
    "IATA": "NAO",
    "icao_code": "ZUNC"
  },
  {
    "city": "Nancy",
    "country": "France",
    "IATA": "ENC",
    "icao_code": "LFSN"
  },
  {
    "city": "Nandayure",
    "country": "Costa Rica",
    "IATA": "PBP",
    "icao_code": "MRIA"
  },
  {
    "city": "Nanded",
    "country": "India",
    "IATA": "NDC",
    "icao_code": "VAND"
  },
  {
    "city": "Nandi",
    "country": "Fiji",
    "IATA": "NAN",
    "icao_code": "NFFN"
  },
  {
    "city": "Nangapinoh",
    "country": "Indonesia",
    "IATA": "NPO",
    "icao_code": "WIOG"
  },
  {
    "city": "Nanisivik",
    "country": "Canada",
    "IATA": "YSR"
  },
  {
    "city": "Nanjing",
    "country": "China",
    "IATA": "NKG",
    "icao_code": "ZSNJ"
  },
  {
    "city": "Nanki-shirahama",
    "country": "Japan",
    "IATA": "SHM",
    "icao_code": "RJBD"
  },
  {
    "city": "Nanning",
    "country": "China",
    "IATA": "NNG",
    "icao_code": "ZGNN"
  },
  {
    "city": "Nanortalik",
    "country": "Greenland",
    "IATA": "JNN",
    "icao_code": "BGNN"
  },
  {
    "city": "Nantes",
    "country": "France",
    "IATA": "NTE",
    "icao_code": "LFRS"
  },
  {
    "city": "Nantucket",
    "country": "United States",
    "IATA": "ACK",
    "icao_code": "KACK"
  },
  {
    "city": "Nanyang",
    "country": "China",
    "IATA": "NNY",
    "icao_code": "ZHNY"
  },
  {
    "city": "Nanyuki",
    "country": "Kenya",
    "IATA": "NYK",
    "icao_code": "HKNY"
  },
  {
    "city": "Napa",
    "country": "United States",
    "IATA": "APC",
    "icao_code": "KAPC"
  },
  {
    "city": "Napakiak",
    "country": "United States",
    "IATA": "WNA",
    "icao_code": "PANA"
  },
  {
    "city": "Napaskiak",
    "country": "United States",
    "IATA": "PKA",
    "icao_code": "PAPK"
  },
  {
    "city": "Naples",
    "country": "United States",
    "IATA": "APF",
    "icao_code": "KAPF"
  },
  {
    "city": "Naples",
    "country": "Italy",
    "IATA": "NAP",
    "icao_code": "LIRN"
  },
  {
    "city": "Napuka",
    "country": "French Polynesia",
    "IATA": "NAU",
    "icao_code": "NTGN"
  },
  {
    "city": "Naracoorte",
    "country": "Australia",
    "IATA": "NAC",
    "icao_code": "YNRC"
  },
  {
    "city": "Narathiwat",
    "country": "Thailand",
    "IATA": "NAW",
    "icao_code": "VTSC"
  },
  {
    "city": "Narrabri",
    "country": "Australia",
    "IATA": "NAA",
    "icao_code": "YNBR"
  },
  {
    "city": "Narrandera",
    "country": "Australia",
    "IATA": "NRA",
    "icao_code": "YNAR"
  },
  {
    "city": "Narsaq",
    "country": "Greenland",
    "IATA": "JNS",
    "icao_code": "BGNS"
  },
  {
    "city": "Narssarssuaq",
    "country": "Greenland",
    "IATA": "UAK",
    "icao_code": "BGBW"
  },
  {
    "city": "Narvik",
    "country": "Norway",
    "IATA": "NVK",
    "icao_code": "ENNK"
  },
  {
    "city": "Naryan-Mar",
    "country": "Russia",
    "IATA": "NNM",
    "icao_code": "ULAM"
  },
  {
    "city": "Nashua",
    "country": "United States",
    "IATA": "ASH",
    "icao_code": "KASH"
  },
  {
    "city": "Nashville",
    "country": "United States",
    "IATA": "BNA",
    "icao_code": "KBNA"
  },
  {
    "city": "Nasik Road",
    "country": "India",
    "IATA": "ISK",
    "icao_code": "VANR"
  },
  {
    "city": "Nassau",
    "country": "Bahamas",
    "IATA": "PID"
  },
  {
    "city": "Nassau",
    "country": "Bahamas",
    "IATA": "NAS",
    "icao_code": "MYNN"
  },
  {
    "city": "Natal",
    "country": "Brazil",
    "IATA": "NAT",
    "icao_code": "SBNT"
  },
  {
    "city": "Natashquan",
    "country": "Canada",
    "IATA": "YNA",
    "icao_code": "CYNA"
  },
  {
    "city": "Natuashish",
    "country": "Canada",
    "IATA": "YNP"
  },
  {
    "city": "Nauru",
    "country": "Nauru",
    "IATA": "INU",
    "icao_code": "ANAU"
  },
  {
    "city": "Nausori",
    "country": "Fiji",
    "IATA": "SUV",
    "icao_code": "NFNA"
  },
  {
    "city": "Navegantes",
    "country": "Brazil",
    "IATA": "NVT",
    "icao_code": "SBNF"
  },
  {
    "city": "Navoi",
    "country": "Uzbekistan",
    "IATA": "NVI",
    "icao_code": "UTSA"
  },
  {
    "city": "Nawabshah",
    "country": "Pakistan",
    "IATA": "WNS",
    "icao_code": "OPNH"
  },
  {
    "city": "Naypyidaw",
    "country": "Burma",
    "IATA": "NMT",
    "icao_code": "VNMT"
  },
  {
    "city": "Naypyidaw",
    "country": "Burma",
    "IATA": "NYT",
    "icao_code": "VYNT"
  },
  {
    "city": "Nazca",
    "country": "Peru",
    "IATA": "NZC"
  },
  {
    "city": "Ndola",
    "country": "Zambia",
    "IATA": "NLA",
    "icao_code": "FLND"
  },
  {
    "city": "Nea Anghialos",
    "country": "Greece",
    "IATA": "VOL",
    "icao_code": "LGBL"
  },
  {
    "city": "Necochea",
    "country": "Argentina",
    "IATA": "NEC"
  },
  {
    "city": "Needles",
    "country": "United States",
    "IATA": "EED",
    "icao_code": "KEED"
  },
  {
    "city": "Neerlerit Inaat",
    "country": "Greenland",
    "IATA": "CNP",
    "icao_code": "BGCO"
  },
  {
    "city": "Nefteyugansk",
    "country": "Russia",
    "IATA": "NFG",
    "icao_code": "USRN"
  },
  {
    "city": "Negage",
    "country": "Angola",
    "IATA": "GXG",
    "icao_code": "FNNG"
  },
  {
    "city": "Negril",
    "country": "Jamaica",
    "IATA": "NEG"
  },
  {
    "city": "Neiva",
    "country": "Colombia",
    "IATA": "NVA",
    "icao_code": "SKNV"
  },
  {
    "city": "Nejran",
    "country": "Saudi Arabia",
    "IATA": "EAM",
    "icao_code": "OENG"
  },
  {
    "city": "Nelson",
    "country": "New Zealand",
    "IATA": "NSN",
    "icao_code": "NZNS"
  },
  {
    "city": "Nelson Lagoon",
    "country": "United States",
    "IATA": "NLG",
    "icao_code": "PAOU"
  },
  {
    "city": "Nelspruit",
    "country": "South Africa",
    "IATA": "NLP",
    "icao_code": "FANS"
  },
  {
    "city": "Nema",
    "country": "Mauritania",
    "IATA": "EMN",
    "icao_code": "GQNI"
  },
  {
    "city": "Nemiscau",
    "country": "Canada",
    "IATA": "YNS",
    "icao_code": "CYHH"
  },
  {
    "city": "Nenana",
    "country": "United States",
    "IATA": "ENN",
    "icao_code": "PANN"
  },
  {
    "city": "Nepalgunj",
    "country": "Nepal",
    "IATA": "KEP",
    "icao_code": "VNNG"
  },
  {
    "city": "Neryungri",
    "country": "Russia",
    "IATA": "NER",
    "icao_code": "UELL"
  },
  {
    "city": "Neubrandenburg",
    "country": "Germany",
    "IATA": "FNB",
    "icao_code": "ETNU"
  },
  {
    "city": "Neuchatel",
    "country": "Switzerland",
    "IATA": "QNC"
  },
  {
    "city": "Neumuenster",
    "country": "Germany",
    "IATA": "EUM"
  },
  {
    "city": "Neuquen",
    "country": "Argentina",
    "IATA": "NQN",
    "icao_code": "SAZN"
  },
  {
    "city": "Nevatim",
    "country": "Israel",
    "IATA": "VTM",
    "icao_code": "LLNV"
  },
  {
    "city": "Nevers",
    "country": "France",
    "IATA": "NVS",
    "icao_code": "LFQG"
  },
  {
    "city": "Nevsehir",
    "country": "Turkey",
    "IATA": "NAV",
    "icao_code": "LTAZ"
  },
  {
    "city": "New Bedford",
    "country": "United States",
    "IATA": "EWB",
    "icao_code": "KEWB"
  },
  {
    "city": "New Bern",
    "country": "United States",
    "IATA": "EWN",
    "icao_code": "KEWN"
  },
  {
    "city": "New Haven",
    "country": "United States",
    "IATA": "HVN",
    "icao_code": "KHVN"
  },
  {
    "city": "New Orleans",
    "country": "United States",
    "IATA": "NEW",
    "icao_code": "KNEW"
  },
  {
    "city": "New Orleans",
    "country": "United States",
    "IATA": "MSY",
    "icao_code": "KMSY"
  },
  {
    "city": "New Orleans",
    "country": "United States",
    "IATA": "NBG"
  },
  {
    "city": "New Philadelpha",
    "country": "United States",
    "IATA": "PHD"
  },
  {
    "city": "New Plymouth",
    "country": "New Zealand",
    "IATA": "NPL",
    "icao_code": "NZNP"
  },
  {
    "city": "New Stuyahok",
    "country": "United States",
    "IATA": "KNW",
    "icao_code": "PANW"
  },
  {
    "city": "New York, All Airports",
    "country": "United States",
    "IATA": "NYALL"
  },
  {
    "city": "New York, NYC",
    "country": "United States",
    "IATA": "JRA"
  },
  {
    "city": "New York, NYC",
    "country": "United States",
    "IATA": "JRB"
  },
  {
    "city": "New York, NYC",
    "country": "United States",
    "IATA": "JFK",
    "icao_code": "KJFK"
  },
  {
    "city": "New York, NYC",
    "country": "United States",
    "IATA": "LGA",
    "icao_code": "KLGA"
  },
  {
    "city": "New York, Newark",
    "country": "United States",
    "IATA": "EWR",
    "icao_code": "KEWR"
  },
  {
    "city": "New York, Monmouth",
    "country": "United States",
    "IATA": "BLM",
    "icao_code": "KBLM"
  },
  {
    "city": "Newburgh",
    "country": "United States",
    "IATA": "SWF",
    "icao_code": "KSWF"
  },
  {
    "city": "Newcastle",
    "country": "Australia",
    "IATA": "NTL",
    "icao_code": "YWLM"
  },
  {
    "city": "Newcastle",
    "country": "South Africa",
    "IATA": "NCS",
    "icao_code": "FANC"
  },
  {
    "city": "Newcastle",
    "country": "United Kingdom",
    "IATA": "NCL",
    "icao_code": "EGNT"
  },
  {
    "city": "Newman",
    "country": "Australia",
    "IATA": "ZNE",
    "icao_code": "YNWM"
  },
  {
    "city": "Newport",
    "country": "United States",
    "IATA": "ONP",
    "icao_code": "KONP"
  },
  {
    "city": "Newport News",
    "country": "United States",
    "IATA": "PHF",
    "icao_code": "KPHF"
  },
  {
    "city": "Newquai",
    "country": "United Kingdom",
    "IATA": "NQY",
    "icao_code": "EGDG"
  },
  {
    "city": "Newton",
    "country": "United States",
    "IATA": "EWK",
    "icao_code": "KEWK"
  },
  {
    "city": "Ngau",
    "country": "Fiji",
    "IATA": "NGI",
    "icao_code": "NFNG"
  },
  {
    "city": "Ngawa",
    "country": "China",
    "IATA": "AHJ",
    "icao_code": "ZUHY"
  },
  {
    "city": "Nha Trang",
    "country": "Vietnam",
    "IATA": "CXR",
    "icao_code": "VVCR"
  },
  {
    "city": "Nhatrang",
    "country": "Vietnam",
    "IATA": "NHA",
    "icao_code": "VVNT"
  },
  {
    "city": "Niagara Falls",
    "country": "United States",
    "IATA": "IAG",
    "icao_code": "KIAG"
  },
  {
    "city": "Niamey",
    "country": "Niger",
    "IATA": "NIM",
    "icao_code": "DRRN"
  },
  {
    "city": "Niatougou",
    "country": "Togo",
    "IATA": "LRL",
    "icao_code": "DXNG"
  },
  {
    "city": "Niau",
    "country": "French Polynesia",
    "IATA": "NIU",
    "icao_code": "NTKN"
  },
  {
    "city": "Nice",
    "country": "France",
    "IATA": "NCE",
    "icao_code": "LFMN"
  },
  {
    "city": "Nicosia",
    "country": "Cyprus",
    "IATA": "ECN",
    "icao_code": "LCEN"
  },
  {
    "city": "Nicoya",
    "country": "Costa Rica",
    "IATA": "TMU",
    "icao_code": "MRTR"
  },
  {
    "city": "Nieuw Nickerie",
    "country": "Suriname",
    "IATA": "ICK"
  },
  {
    "city": "Nightmute",
    "country": "United States",
    "IATA": "NME",
    "icao_code": "PAGT"
  },
  {
    "city": "Niigata",
    "country": "Japan",
    "IATA": "KIJ",
    "icao_code": "RJSN"
  },
  {
    "city": "Nikolai",
    "country": "United States",
    "IATA": "NIB",
    "icao_code": "PAFS"
  },
  {
    "city": "Nikolayev",
    "country": "Ukraine",
    "IATA": "NLV",
    "icao_code": "UKON"
  },
  {
    "city": "Nikolayevsk-na-Amure Airport",
    "country": "Russia",
    "IATA": "NLI",
    "icao_code": "UHNN"
  },
  {
    "city": "Nikolski",
    "country": "United States",
    "IATA": "IKO"
  },
  {
    "city": "Nikunau",
    "country": "Kiribati",
    "IATA": "NIG"
  },
  {
    "city": "Nimes",
    "country": "France",
    "IATA": "FNI",
    "icao_code": "LFTW"
  },
  {
    "city": "Ninbo",
    "country": "China",
    "IATA": "NGB",
    "icao_code": "ZSNB"
  },
  {
    "city": "Ninglang",
    "country": "China",
    "IATA": "NLH",
    "icao_code": "ZPNL"
  },
  {
    "city": "Nioki",
    "country": "Congo (Kinshasa)",
    "IATA": "NIO",
    "icao_code": "FZBI"
  },
  {
    "city": "Niort",
    "country": "France",
    "IATA": "NIT",
    "icao_code": "LFBN"
  },
  {
    "city": "Nis",
    "country": "Serbia",
    "IATA": "INI",
    "icao_code": "LYNI"
  },
  {
    "city": "Niuatoputapu",
    "country": "Tonga",
    "IATA": "NTT",
    "icao_code": "NFTP"
  },
  {
    "city": "Nizhnekamsk",
    "country": "Russia",
    "IATA": "NBC",
    "icao_code": "KNBC"
  },
  {
    "city": "Nizhnevartovsk",
    "country": "Russia",
    "IATA": "NJC",
    "icao_code": "USNN"
  },
  {
    "city": "Nizhniy Novgorod",
    "country": "Russia",
    "IATA": "GOJ",
    "icao_code": "UWGG"
  },
  {
    "city": "Noatak",
    "country": "United States",
    "IATA": "WTK",
    "icao_code": "PAWN"
  },
  {
    "city": "Nogales",
    "country": "United States",
    "IATA": "OLS",
    "icao_code": "KOLS"
  },
  {
    "city": "Nogales",
    "country": "Mexico",
    "IATA": "NOG"
  },
  {
    "city": "Nogliki",
    "country": "Russia",
    "IATA": "NGK"
  },
  {
    "city": "Nome",
    "country": "United States",
    "IATA": "OME",
    "icao_code": "PAOM"
  },
  {
    "city": "Nondalton",
    "country": "United States",
    "IATA": "NNL",
    "icao_code": "PANO"
  },
  {
    "city": "Nonouti",
    "country": "Kiribati",
    "IATA": "NON"
  },
  {
    "city": "Noonkanbah",
    "country": "Australia",
    "IATA": "NKB"
  },
  {
    "city": "Noorvik",
    "country": "United States",
    "IATA": "ORV",
    "icao_code": "PFNO"
  },
  {
    "city": "Norden",
    "country": "Germany",
    "IATA": "NOD"
  },
  {
    "city": "Norderney",
    "country": "Germany",
    "IATA": "NRD"
  },
  {
    "city": "Nordfjordur",
    "country": "Iceland",
    "IATA": "NOR",
    "icao_code": "BINF"
  },
  {
    "city": "Nordholz",
    "country": "Germany",
    "IATA": "FCN"
  },
  {
    "city": "Norfolk",
    "country": "United States",
    "IATA": "NGU",
    "icao_code": "KNGU"
  },
  {
    "city": "Norfolk",
    "country": "United States",
    "IATA": "ORF",
    "icao_code": "KORF"
  },
  {
    "city": "Norfolk Island",
    "country": "Norfolk Island",
    "IATA": "NLK",
    "icao_code": "YSNF"
  },
  {
    "city": "Norfolk Nebraska",
    "country": "United States",
    "IATA": "OFK",
    "icao_code": "KOFK"
  },
  {
    "city": "Norilsk",
    "country": "Russia",
    "IATA": "NSK",
    "icao_code": "UOOO"
  },
  {
    "city": "Norman Wells",
    "country": "Canada",
    "IATA": "YVQ",
    "icao_code": "CYVQ"
  },
  {
    "city": "Norman's Cay",
    "country": "Bahamas",
    "IATA": "NMC",
    "icao_code": "MYEN"
  },
  {
    "city": "Normanton",
    "country": "Australia",
    "IATA": "NTN",
    "icao_code": "YNTN"
  },
  {
    "city": "Norrkoeping",
    "country": "Sweden",
    "IATA": "NRK",
    "icao_code": "ESSP"
  },
  {
    "city": "Norsup",
    "country": "Vanuatu",
    "IATA": "NUS",
    "icao_code": "NVSP"
  },
  {
    "city": "North Battleford",
    "country": "Canada",
    "IATA": "YQW",
    "icao_code": "CYQW"
  },
  {
    "city": "North Bay",
    "country": "Canada",
    "IATA": "YYB",
    "icao_code": "CYYB"
  },
  {
    "city": "North Bend",
    "country": "United States",
    "IATA": "OTH",
    "icao_code": "KOTH"
  },
  {
    "city": "North Caicos",
    "country": "Turks and Caicos Islands",
    "IATA": "NCA",
    "icao_code": "MBNC"
  },
  {
    "city": "North Connel",
    "country": "United Kingdom",
    "IATA": "OBN",
    "icao_code": "EGEO"
  },
  {
    "city": "North Eleuthera",
    "country": "Bahamas",
    "IATA": "ELH",
    "icao_code": "MYEH"
  },
  {
    "city": "North Kingstown",
    "country": "United States",
    "IATA": "NCO",
    "icao_code": "KOQU"
  },
  {
    "city": "North Myrtle Beach",
    "country": "United States",
    "IATA": "CRE",
    "icao_code": "KCRE"
  },
  {
    "city": "North Platte",
    "country": "United States",
    "IATA": "LBF",
    "icao_code": "KLBF"
  },
  {
    "city": "North Ronaldsay",
    "country": "United Kingdom",
    "IATA": "NRL",
    "icao_code": "EGEN"
  },
  {
    "city": "North Spirit Lake",
    "country": "Canada",
    "IATA": "YNO",
    "icao_code": "CKQ3"
  },
  {
    "city": "North Wilkesboro",
    "country": "United States",
    "IATA": "IKB"
  },
  {
    "city": "Northolt",
    "country": "United Kingdom",
    "IATA": "NHT",
    "icao_code": "EGWU"
  },
  {
    "city": "Northway",
    "country": "United States",
    "IATA": "ORT",
    "icao_code": "PAOR"
  },
  {
    "city": "Norway House",
    "country": "Canada",
    "IATA": "YNE"
  },
  {
    "city": "Norwich",
    "country": "United Kingdom",
    "IATA": "NWI",
    "icao_code": "EGSH"
  },
  {
    "city": "Norwood",
    "country": "United States",
    "IATA": "OWD",
    "icao_code": "KOWD"
  },
  {
    "city": "Nosara Beach",
    "country": "Costa Rica",
    "IATA": "NOB",
    "icao_code": "MRNS"
  },
  {
    "city": "Noshahr",
    "country": "Iran",
    "IATA": "NSH",
    "icao_code": "OINN"
  },
  {
    "city": "Nosy-be",
    "country": "Madagascar",
    "IATA": "NOS",
    "icao_code": "FMNN"
  },
  {
    "city": "Notodden",
    "country": "Norway",
    "IATA": "NTB",
    "icao_code": "ENNO"
  },
  {
    "city": "Nottingham",
    "country": "United Kingdom",
    "IATA": "NQT",
    "icao_code": "EGBN"
  },
  {
    "city": "Nouadhibou",
    "country": "Mauritania",
    "IATA": "NDB",
    "icao_code": "GQPP"
  },
  {
    "city": "Nouakschott",
    "country": "Mauritania",
    "IATA": "NKC",
    "icao_code": "GQNN"
  },
  {
    "city": "Noumea",
    "country": "New Caledonia",
    "IATA": "NOU",
    "icao_code": "NWWW"
  },
  {
    "city": "Noumea",
    "country": "New Caledonia",
    "IATA": "GEA",
    "icao_code": "NWWM"
  },
  {
    "city": "Nova Iguacu",
    "country": "Brazil",
    "IATA": "QNV"
  },
  {
    "city": "Novato",
    "country": "United States",
    "IATA": "NOT"
  },
  {
    "city": "Novi Sad",
    "country": "Serbia",
    "IATA": "QND"
  },
  {
    "city": "Novo Aripuana",
    "country": "Brazil",
    "IATA": "NVP",
    "icao_code": "SWNA"
  },
  {
    "city": "Novo Progresso",
    "country": "Brazil",
    "IATA": "NPR",
    "icao_code": "SJNP"
  },
  {
    "city": "Novokuznetsk",
    "country": "Russia",
    "IATA": "NOZ",
    "icao_code": "UNWW"
  },
  {
    "city": "Novosibirsk",
    "country": "Russia",
    "IATA": "OVB",
    "icao_code": "UNNT"
  },
  {
    "city": "Novy Urengoy",
    "country": "Russia",
    "IATA": "NUX",
    "icao_code": "USMU"
  },
  {
    "city": "Nowra",
    "country": "Australia",
    "IATA": "NOA",
    "icao_code": "YSNW"
  },
  {
    "city": "Noyabrsk",
    "country": "Russia",
    "IATA": "NOJ",
    "icao_code": "USRO"
  },
  {
    "city": "Nuernberg",
    "country": "Germany",
    "IATA": "NUE",
    "icao_code": "EDDN"
  },
  {
    "city": "Nueva Gerona",
    "country": "Cuba",
    "IATA": "GER",
    "icao_code": "MUNG"
  },
  {
    "city": "Nuevo Casas Grandes",
    "country": "Mexico",
    "IATA": "NCG"
  },
  {
    "city": "Nuevo Laredo",
    "country": "Mexico",
    "IATA": "NLD",
    "icao_code": "MMNL"
  },
  {
    "city": "Nuiqsut",
    "country": "United States",
    "IATA": "NUI",
    "icao_code": "PAQT"
  },
  {
    "city": "Nuku Hiva",
    "country": "French Polynesia",
    "IATA": "NHV",
    "icao_code": "NTMD"
  },
  {
    "city": "Nukus",
    "country": "Uzbekistan",
    "IATA": "NCU",
    "icao_code": "UTNN"
  },
  {
    "city": "Nukutavake",
    "country": "French Polynesia",
    "IATA": "NUK",
    "icao_code": "NTGW"
  },
  {
    "city": "Nulato",
    "country": "United States",
    "IATA": "NUL",
    "icao_code": "PANU"
  },
  {
    "city": "Null",
    "country": "United States",
    "IATA": "SUS",
    "icao_code": "KSUS"
  },
  {
    "city": "Null",
    "country": "Bosnia and Herzegovina",
    "IATA": "TZL",
    "icao_code": "LQTZ"
  },
  {
    "city": "Nunukan-Nunukan Island",
    "country": "Indonesia",
    "IATA": "NNX",
    "icao_code": "WRLF"
  },
  {
    "city": "Nuquí",
    "country": "Colombia",
    "IATA": "NQU",
    "icao_code": "SKNQ"
  },
  {
    "city": "Nusawiru",
    "country": "Indonesia",
    "IATA": "CJN"
  },
  {
    "city": "Nyagan",
    "country": "Russia",
    "IATA": "NYA",
    "icao_code": "USHN"
  },
  {
    "city": "Nyala",
    "country": "Sudan",
    "IATA": "UYL",
    "icao_code": "HSNL"
  },
  {
    "city": "Nyingchi",
    "country": "China",
    "IATA": "LZY",
    "icao_code": "ZUNZ"
  },
  {
    "city": "Nyurba",
    "country": "Russia",
    "IATA": "NYR"
  },
  {
    "city": "Nzagi",
    "country": "Angola",
    "IATA": "NZA",
    "icao_code": "FNZG"
  },
  {
    "city": "Nzerekore",
    "country": "Guinea",
    "IATA": "NZE",
    "icao_code": "GUNZ"
  },
  {
    "city": "Oakey",
    "country": "Australia",
    "IATA": "OKY",
    "icao_code": "YBOK"
  },
  {
    "city": "Oakland",
    "country": "United States",
    "IATA": "OAK",
    "icao_code": "KOAK"
  },
  {
    "city": "Oakley",
    "country": "United States",
    "IATA": "OEL",
    "icao_code": "UUOR"
  },
  {
    "city": "Oamaru",
    "country": "New Zealand",
    "IATA": "OAM",
    "icao_code": "NZOU"
  },
  {
    "city": "Oaxaca",
    "country": "Mexico",
    "IATA": "OAX",
    "icao_code": "MMOX"
  },
  {
    "city": "Oberpfaffenhofen",
    "country": "Germany",
    "IATA": "OBF",
    "icao_code": "EDMO"
  },
  {
    "city": "Obihiro",
    "country": "Japan",
    "IATA": "OBO",
    "icao_code": "RJCB"
  },
  {
    "city": "Obock",
    "country": "Djibouti",
    "IATA": "OBC",
    "icao_code": "HDOB"
  },
  {
    "city": "Ocala",
    "country": "United States",
    "IATA": "OCF"
  },
  {
    "city": "Ocana",
    "country": "Colombia",
    "IATA": "OCV",
    "icao_code": "SKOC"
  },
  {
    "city": "Ocean Reef Club Airport",
    "country": "United States",
    "IATA": "OCA"
  },
  {
    "city": "Oceana",
    "country": "United States",
    "IATA": "NTU",
    "icao_code": "KNTU"
  },
  {
    "city": "Ocho Rios",
    "country": "Jamaica",
    "IATA": "OCJ"
  },
  {
    "city": "Odate Noshiro",
    "country": "Japan",
    "IATA": "ONJ",
    "icao_code": "RJSR"
  },
  {
    "city": "Odense",
    "country": "Denmark",
    "IATA": "ODE",
    "icao_code": "EKOD"
  },
  {
    "city": "Odessa",
    "country": "Ukraine",
    "IATA": "ODS",
    "icao_code": "UKOO"
  },
  {
    "city": "Odienne",
    "country": "Cote d'Ivoire",
    "IATA": "KEO",
    "icao_code": "DIOD"
  },
  {
    "city": "Odiham",
    "country": "United Kingdom",
    "IATA": "ODH",
    "icao_code": "EGVO"
  },
  {
    "city": "Ogden",
    "country": "United States",
    "IATA": "OGD",
    "icao_code": "KOGD"
  },
  {
    "city": "Ogden",
    "country": "United States",
    "IATA": "HIF"
  },
  {
    "city": "Ogdensburg",
    "country": "United States",
    "IATA": "OGS",
    "icao_code": "KOGS"
  },
  {
    "city": "Ogoki Post",
    "country": "Canada",
    "IATA": "YOG",
    "icao_code": "CNT3"
  },
  {
    "city": "Ohakea",
    "country": "New Zealand",
    "IATA": "OHA",
    "icao_code": "NZOH"
  },
  {
    "city": "Ohrid",
    "country": "Macedonia",
    "IATA": "OHD",
    "icao_code": "LWOH"
  },
  {
    "city": "Oioiapoque",
    "country": "Brazil",
    "IATA": "OYK",
    "icao_code": "SBOI"
  },
  {
    "city": "Oita",
    "country": "Japan",
    "IATA": "OIT",
    "icao_code": "RJFO"
  },
  {
    "city": "Okaukuejo",
    "country": "Namibia",
    "IATA": "OKF"
  },
  {
    "city": "Okayama",
    "country": "Japan",
    "IATA": "OKJ",
    "icao_code": "RJOB"
  },
  {
    "city": "Okeechobee",
    "country": "United States",
    "IATA": "OBE",
    "icao_code": "KOBE"
  },
  {
    "city": "Okhotsk",
    "country": "Russia",
    "IATA": "OHO",
    "icao_code": "UHOO"
  },
  {
    "city": "Oki Island",
    "country": "Japan",
    "IATA": "OKI",
    "icao_code": "RJNO"
  },
  {
    "city": "Okierabu",
    "country": "Japan",
    "IATA": "OKE",
    "icao_code": "RJKB"
  },
  {
    "city": "Okinawa",
    "country": "Japan",
    "IATA": "OKA",
    "icao_code": "ROAH"
  },
  {
    "city": "Oklahoma City",
    "country": "United States",
    "IATA": "PWA",
    "icao_code": "KPWA"
  },
  {
    "city": "Oklahoma City",
    "country": "United States",
    "IATA": "OKC",
    "icao_code": "KOKC"
  },
  {
    "city": "Oklahoma City",
    "country": "United States",
    "IATA": "TIK",
    "icao_code": "KTIK"
  },
  {
    "city": "Okmulgee",
    "country": "United States",
    "IATA": "OKM",
    "icao_code": "KOKM"
  },
  {
    "city": "Okondja",
    "country": "Gabon",
    "IATA": "OKN",
    "icao_code": "FOGQ"
  },
  {
    "city": "Oksibil",
    "country": "Indonesia",
    "IATA": "OKL",
    "icao_code": "WAJO"
  },
  {
    "city": "Okushiri",
    "country": "Japan",
    "IATA": "OIR",
    "icao_code": "RJEO"
  },
  {
    "city": "Olathe",
    "country": "United States",
    "IATA": "OJC",
    "icao_code": "KOJC"
  },
  {
    "city": "Olathe",
    "country": "United States",
    "IATA": "JCI",
    "icao_code": "KIXD"
  },
  {
    "city": "Olavarria",
    "country": "Argentina",
    "IATA": "OVR",
    "icao_code": "SAZF"
  },
  {
    "city": "Olbia",
    "country": "Italy",
    "IATA": "OLB",
    "icao_code": "LIEO"
  },
  {
    "city": "Old Crow",
    "country": "Canada",
    "IATA": "YOC",
    "icao_code": "CYOC"
  },
  {
    "city": "Olekminsk",
    "country": "Russia",
    "IATA": "OLZ"
  },
  {
    "city": "Olenyok",
    "country": "Russia",
    "IATA": "ONK"
  },
  {
    "city": "Olgii",
    "country": "Mongolia",
    "IATA": "ULG",
    "icao_code": "ZMUL"
  },
  {
    "city": "Olive Branch",
    "country": "United States",
    "IATA": "OLV",
    "icao_code": "KOLV"
  },
  {
    "city": "Olongapo City",
    "country": "Philippines",
    "IATA": "SFS",
    "icao_code": "RPLB"
  },
  {
    "city": "Olpoi",
    "country": "Vanuatu",
    "IATA": "OLJ",
    "icao_code": "NVSZ"
  },
  {
    "city": "Olympia",
    "country": "United States",
    "IATA": "OLM",
    "icao_code": "KOLM"
  },
  {
    "city": "Olympic Dam",
    "country": "Australia",
    "IATA": "OLP",
    "icao_code": "YOLD"
  },
  {
    "city": "Omaha",
    "country": "United States",
    "IATA": "OFF",
    "icao_code": "KOFF"
  },
  {
    "city": "Omaha",
    "country": "United States",
    "IATA": "OMA",
    "icao_code": "KOMA"
  },
  {
    "city": "Omboue Hospial",
    "country": "Gabon",
    "IATA": "OMB",
    "icao_code": "FOOH"
  },
  {
    "city": "Omidyeh",
    "country": "Iran",
    "IATA": "OMI"
  },
  {
    "city": "Omsk",
    "country": "Russia",
    "IATA": "OMS",
    "icao_code": "UNOO"
  },
  {
    "city": "Ondangwa",
    "country": "Namibia",
    "IATA": "OND",
    "icao_code": "FAOA"
  },
  {
    "city": "Ondjiva",
    "country": "Angola",
    "IATA": "VPE",
    "icao_code": "FNGI"
  },
  {
    "city": "Onslow",
    "country": "Australia",
    "IATA": "ONS",
    "icao_code": "YOLW"
  },
  {
    "city": "Ontario",
    "country": "United States",
    "IATA": "ONO",
    "icao_code": "KONO"
  },
  {
    "city": "Los Angeles, Ontario",
    "country": "United States",
    "IATA": "ONT",
    "icao_code": "KONT"
  },
  {
    "city": "Opolu",
    "country": "United States",
    "IATA": "UPP",
    "icao_code": "PHUP"
  },
  {
    "city": "Oradea",
    "country": "Romania",
    "IATA": "OMR",
    "icao_code": "LROD"
  },
  {
    "city": "Oran",
    "country": "Argentina",
    "IATA": "ORA",
    "icao_code": "SASO"
  },
  {
    "city": "Oran",
    "country": "Algeria",
    "IATA": "ORN",
    "icao_code": "DAOO"
  },
  {
    "city": "Oran",
    "country": "Algeria",
    "IATA": "TAF",
    "icao_code": "DAOL"
  },
  {
    "city": "Orange",
    "country": "Australia",
    "IATA": "OAG",
    "icao_code": "YORG"
  },
  {
    "city": "Orange",
    "country": "France",
    "IATA": "XOG"
  },
  {
    "city": "Orangeburg",
    "country": "United States",
    "IATA": "OGB",
    "icao_code": "KOGB"
  },
  {
    "city": "Oranjemund",
    "country": "Namibia",
    "IATA": "OMD",
    "icao_code": "FYOG"
  },
  {
    "city": "Oranjestad",
    "country": "Netherlands Antilles",
    "IATA": "EUX",
    "icao_code": "TNCE"
  },
  {
    "city": "Oranjestad",
    "country": "Aruba",
    "IATA": "AUA",
    "icao_code": "TNCA"
  },
  {
    "city": "Orapa",
    "country": "Botswana",
    "IATA": "ORP",
    "icao_code": "FBOR"
  },
  {
    "city": "Ordu-Giresun",
    "country": "Turkey",
    "IATA": "OGU",
    "icao_code": "LTCB"
  },
  {
    "city": "Orebro",
    "country": "Sweden",
    "IATA": "ORB",
    "icao_code": "ESOE"
  },
  {
    "city": "Orenburg",
    "country": "Russia",
    "IATA": "REN",
    "icao_code": "UWOO"
  },
  {
    "city": "Orinduik",
    "country": "Guyana",
    "IATA": "ORJ",
    "icao_code": "SYOR"
  },
  {
    "city": "Oristano",
    "country": "Italy",
    "IATA": "FNU"
  },
  {
    "city": "Oriximina",
    "country": "Brazil",
    "IATA": "ORX",
    "icao_code": "SNOX"
  },
  {
    "city": "Oriximina",
    "country": "Brazil",
    "IATA": "TMT",
    "icao_code": "SBTB"
  },
  {
    "city": "Orland",
    "country": "Norway",
    "IATA": "OLA",
    "icao_code": "ENOL"
  },
  {
    "city": "Orlando",
    "country": "United States",
    "IATA": "MCO",
    "icao_code": "KMCO"
  },
  {
    "city": "Orlando",
    "country": "United States",
    "IATA": "ORL",
    "icao_code": "KORL"
  },
  {
    "city": "Orleans",
    "country": "France",
    "IATA": "ORE"
  },
  {
    "city": "Ormara Raik",
    "country": "Pakistan",
    "IATA": "ORW",
    "icao_code": "OPOR"
  },
  {
    "city": "Ormoc City",
    "country": "Philippines",
    "IATA": "OMC",
    "icao_code": "RPVO"
  },
  {
    "city": "Ornskoldsvik",
    "country": "Sweden",
    "IATA": "OER",
    "icao_code": "ESNO"
  },
  {
    "city": "Orsk",
    "country": "Russia",
    "IATA": "OSW",
    "icao_code": "UWOR"
  },
  {
    "city": "Oruro",
    "country": "Bolivia",
    "IATA": "ORU",
    "icao_code": "SLOR"
  },
  {
    "city": "Osaka",
    "country": "Japan",
    "IATA": "KIX",
    "icao_code": "RJBB"
  },
  {
    "city": "Osaka",
    "country": "Japan",
    "IATA": "ITM",
    "icao_code": "RJOO"
  },
  {
    "city": "Osan",
    "country": "South Korea",
    "IATA": "OSN",
    "icao_code": "RKSO"
  },
  {
    "city": "Oscoda",
    "country": "United States",
    "IATA": "OSC",
    "icao_code": "KOSC"
  },
  {
    "city": "Osh",
    "country": "Kyrgyzstan",
    "IATA": "OSS",
    "icao_code": "UAFO"
  },
  {
    "city": "Oshawa",
    "country": "Canada",
    "IATA": "YOO",
    "icao_code": "CYOO"
  },
  {
    "city": "Oshima",
    "country": "Japan",
    "IATA": "OIM",
    "icao_code": "RJTO"
  },
  {
    "city": "Oshkosh",
    "country": "United States",
    "IATA": "OSH",
    "icao_code": "KOSH"
  },
  {
    "city": "Osijek",
    "country": "Croatia",
    "IATA": "OSI",
    "icao_code": "LDOS"
  },
  {
    "city": "Oskarshamn",
    "country": "Sweden",
    "IATA": "OSK",
    "icao_code": "ESMO"
  },
  {
    "city": "Oslo",
    "country": "Norway",
    "IATA": "OSL",
    "icao_code": "ENGM"
  },
  {
    "city": "Osorno",
    "country": "Chile",
    "IATA": "ZOS",
    "icao_code": "SCJO"
  },
  {
    "city": "Ostend",
    "country": "Belgium",
    "IATA": "OST",
    "icao_code": "EBOS"
  },
  {
    "city": "Ostrava",
    "country": "Czech Republic",
    "IATA": "OSR",
    "icao_code": "LKMT"
  },
  {
    "city": "Osubi",
    "country": "Nigeria",
    "IATA": "QRW",
    "icao_code": "DNSU"
  },
  {
    "city": "Otjiwarongo",
    "country": "Namibia",
    "IATA": "OTJ"
  },
  {
    "city": "Ottawa",
    "country": "Canada",
    "IATA": "YRO",
    "icao_code": "CYRO"
  },
  {
    "city": "Ottawa",
    "country": "Canada",
    "IATA": "YOW",
    "icao_code": "CYOW"
  },
  {
    "city": "Ottumwa",
    "country": "United States",
    "IATA": "OTM",
    "icao_code": "KOTM"
  },
  {
    "city": "Otu",
    "country": "Colombia",
    "IATA": "OTU"
  },
  {
    "city": "Ouagadougou",
    "country": "Burkina Faso",
    "IATA": "OUA",
    "icao_code": "DFFD"
  },
  {
    "city": "Ouargla",
    "country": "Algeria",
    "IATA": "OGX",
    "icao_code": "DAUU"
  },
  {
    "city": "Ouarzazate",
    "country": "Morocco",
    "IATA": "OZZ",
    "icao_code": "GMMZ"
  },
  {
    "city": "Oudtshoorn",
    "country": "South Africa",
    "IATA": "OUH",
    "icao_code": "FAOH"
  },
  {
    "city": "Ouesso",
    "country": "Congo (Kinshasa)",
    "IATA": "OUE",
    "icao_code": "FCOU"
  },
  {
    "city": "Oujda",
    "country": "Morocco",
    "IATA": "OUD",
    "icao_code": "GMFO"
  },
  {
    "city": "Oulu",
    "country": "Finland",
    "IATA": "OUL",
    "icao_code": "EFOU"
  },
  {
    "city": "Ourilandia do Norte",
    "country": "Brazil",
    "IATA": "OIA",
    "icao_code": "SDOW"
  },
  {
    "city": "Outer Skerries",
    "country": "United Kingdom",
    "IATA": "OUK"
  },
  {
    "city": "Ouvea",
    "country": "New Caledonia",
    "IATA": "UVE",
    "icao_code": "NWWV"
  },
  {
    "city": "Ovda",
    "country": "Israel",
    "IATA": "VDA",
    "icao_code": "LLOV"
  },
  {
    "city": "Overberg",
    "country": "South Africa",
    "IATA": "OVG",
    "icao_code": "FAOB"
  },
  {
    "city": "Owando",
    "country": "Congo (Kinshasa)",
    "IATA": "FTX"
  },
  {
    "city": "Owen Sound",
    "country": "Canada",
    "IATA": "YOS",
    "icao_code": "CYOS"
  },
  {
    "city": "Owensboro",
    "country": "United States",
    "IATA": "OWB",
    "icao_code": "KOWB"
  },
  {
    "city": "Oxford",
    "country": "United States",
    "IATA": "UOX",
    "icao_code": "KUOX"
  },
  {
    "city": "Oxford",
    "country": "United States",
    "IATA": "OXC",
    "icao_code": "KOXC"
  },
  {
    "city": "Oxford",
    "country": "United Kingdom",
    "IATA": "OXF",
    "icao_code": "EGTK"
  },
  {
    "city": "Oxford House",
    "country": "Canada",
    "IATA": "YOH",
    "icao_code": "CYOH"
  },
  {
    "city": "Oxnard",
    "country": "United States",
    "IATA": "OXR",
    "icao_code": "KOXR"
  },
  {
    "city": "Oyem",
    "country": "Gabon",
    "IATA": "OYE",
    "icao_code": "FOGO"
  },
  {
    "city": "Oyo",
    "country": "Congo (Brazzaville)",
    "IATA": "OLL"
  },
  {
    "city": "Ozamis",
    "country": "Philippines",
    "IATA": "OZC",
    "icao_code": "RPMO"
  },
  {
    "city": "Ozona",
    "country": "United States",
    "IATA": "OZA"
  },
  {
    "city": "PADUCAH",
    "country": "United States",
    "IATA": "PAH",
    "icao_code": "KPAH"
  },
  {
    "city": "PARKERSBURG",
    "country": "United States",
    "IATA": "PKB",
    "icao_code": "KPKB"
  },
  {
    "city": "Paama Island",
    "country": "Vanuatu",
    "IATA": "PBJ",
    "icao_code": "NVSI"
  },
  {
    "city": "Paamiut",
    "country": "Greenland",
    "IATA": "JFR",
    "icao_code": "BGFH"
  },
  {
    "city": "Padang",
    "country": "Indonesia",
    "IATA": "PDG",
    "icao_code": "WIMG"
  },
  {
    "city": "Padang Sidempuan",
    "country": "Indonesia",
    "IATA": "AEG",
    "icao_code": "WIME"
  },
  {
    "city": "Paderborn",
    "country": "Germany",
    "IATA": "PAD",
    "icao_code": "EDLP"
  },
  {
    "city": "Padova",
    "country": "Italy",
    "IATA": "QPA"
  },
  {
    "city": "Pagadian",
    "country": "Philippines",
    "IATA": "PAG",
    "icao_code": "RPMP"
  },
  {
    "city": "Page",
    "country": "United States",
    "IATA": "PGA",
    "icao_code": "KPGA"
  },
  {
    "city": "Pago Pago",
    "country": "American Samoa",
    "IATA": "PPG",
    "icao_code": "NSTU"
  },
  {
    "city": "Pahokee",
    "country": "United States",
    "IATA": "PHK"
  },
  {
    "city": "Pai",
    "country": "Thailand",
    "IATA": "PYY",
    "icao_code": "VTCI"
  },
  {
    "city": "Pailton",
    "country": "United Kingdom",
    "IATA": "GBA"
  },
  {
    "city": "Pajala",
    "country": "Sweden",
    "IATA": "PJA",
    "icao_code": "ESUP"
  },
  {
    "city": "Pakhokku",
    "country": "Burma",
    "IATA": "PKK",
    "icao_code": "VYPU"
  },
  {
    "city": "Pakse",
    "country": "Laos",
    "IATA": "PKZ",
    "icao_code": "VLPS"
  },
  {
    "city": "Palacios",
    "country": "United States",
    "IATA": "PSX",
    "icao_code": "KPSX"
  },
  {
    "city": "Palanga",
    "country": "Lithuania",
    "IATA": "PLQ",
    "icao_code": "EYPA"
  },
  {
    "city": "Palangkaraya",
    "country": "Indonesia",
    "IATA": "PKY",
    "icao_code": "WRBP"
  },
  {
    "city": "Palembang",
    "country": "Indonesia",
    "IATA": "PLM",
    "icao_code": "WIPP"
  },
  {
    "city": "Palenque",
    "country": "Mexico",
    "IATA": "PQM",
    "icao_code": "MMPQ"
  },
  {
    "city": "Palermo",
    "country": "Italy",
    "IATA": "PMO",
    "icao_code": "LICJ"
  },
  {
    "city": "Palm Island",
    "country": "Australia",
    "IATA": "PMK",
    "icao_code": "YPAM"
  },
  {
    "city": "Palm Springs",
    "country": "United States",
    "IATA": "UDD",
    "icao_code": "KUDD"
  },
  {
    "city": "Palm Springs",
    "country": "United States",
    "IATA": "TRM",
    "icao_code": "KTRM"
  },
  {
    "city": "Palm Springs",
    "country": "United States",
    "IATA": "PSP",
    "icao_code": "KPSP"
  },
  {
    "city": "Palma de Mallorca",
    "country": "Spain",
    "IATA": "PMI",
    "icao_code": "LEPA"
  },
  {
    "city": "Palmar Sur",
    "country": "Costa Rica",
    "IATA": "PMZ",
    "icao_code": "MRPM"
  },
  {
    "city": "Palmarito",
    "country": "Venezuela",
    "IATA": "PTM"
  },
  {
    "city": "Palmas",
    "country": "Brazil",
    "IATA": "PMW",
    "icao_code": "SWPJ"
  },
  {
    "city": "Palmdale",
    "country": "United States",
    "IATA": "PMD"
  },
  {
    "city": "Palmer",
    "country": "United States",
    "IATA": "PAQ",
    "icao_code": "PAAQ"
  },
  {
    "city": "Palmerston North",
    "country": "New Zealand",
    "IATA": "PMR",
    "icao_code": "NZPM"
  },
  {
    "city": "Palmyra",
    "country": "Syria",
    "IATA": "PMS",
    "icao_code": "OSPR"
  },
  {
    "city": "Palo Alto",
    "country": "United States",
    "IATA": "PAO",
    "icao_code": "KPAO"
  },
  {
    "city": "Paloemeu",
    "country": "Suriname",
    "IATA": "OEM"
  },
  {
    "city": "Palu",
    "country": "Indonesia",
    "IATA": "PLW",
    "icao_code": "WAML"
  },
  {
    "city": "Pampa",
    "country": "United States",
    "IATA": "PPA"
  },
  {
    "city": "Pamplona",
    "country": "Spain",
    "IATA": "PNA",
    "icao_code": "LEPP"
  },
  {
    "city": "Panama",
    "country": "Panama",
    "IATA": "PAC",
    "icao_code": "MPMG"
  },
  {
    "city": "Panama City",
    "country": "United States",
    "IATA": "ECP",
    "icao_code": "KECP"
  },
  {
    "city": "Panama City",
    "country": "United States",
    "IATA": "PFN"
  },
  {
    "city": "Panama City",
    "country": "United States",
    "IATA": "PAM"
  },
  {
    "city": "Panama City",
    "country": "Panama",
    "IATA": "PTY",
    "icao_code": "MPTO"
  },
  {
    "city": "Panevezys",
    "country": "Lithuania",
    "IATA": "PNV",
    "icao_code": "EYPP"
  },
  {
    "city": "Pangkal Pinang",
    "country": "Indonesia",
    "IATA": "PGK",
    "icao_code": "WIKK"
  },
  {
    "city": "Pangkalan Bun",
    "country": "Indonesia",
    "IATA": "PKN",
    "icao_code": "WRBI"
  },
  {
    "city": "Pangkor Island",
    "country": "Malaysia",
    "IATA": "PKG",
    "icao_code": "WMPA"
  },
  {
    "city": "Pangnirtung",
    "country": "Canada",
    "IATA": "YXP",
    "icao_code": "CYXP"
  },
  {
    "city": "Panjgur",
    "country": "Pakistan",
    "IATA": "PJG",
    "icao_code": "OPPG"
  },
  {
    "city": "Pantelleria",
    "country": "Italy",
    "IATA": "PNL",
    "icao_code": "LICG"
  },
  {
    "city": "Panzhihua",
    "country": "China",
    "IATA": "PZI",
    "icao_code": "ZUZH"
  },
  {
    "city": "Papa Westray",
    "country": "United Kingdom",
    "IATA": "PPW",
    "icao_code": "EGEP"
  },
  {
    "city": "Papeete",
    "country": "French Polynesia",
    "IATA": "PPT",
    "icao_code": "NTAA"
  },
  {
    "city": "Paphos",
    "country": "Cyprus",
    "IATA": "PFO",
    "icao_code": "LCPH"
  },
  {
    "city": "Paraburdoo",
    "country": "Australia",
    "IATA": "PBO",
    "icao_code": "APPB"
  },
  {
    "city": "Parachinar",
    "country": "Pakistan",
    "IATA": "PAJ"
  },
  {
    "city": "Paraguana",
    "country": "Venezuela",
    "IATA": "LSP",
    "icao_code": "SVJC"
  },
  {
    "city": "Parakou",
    "country": "Benin",
    "IATA": "PKO",
    "icao_code": "DBBP"
  },
  {
    "city": "Paramaribo",
    "country": "Suriname",
    "IATA": "ORG"
  },
  {
    "city": "Parana",
    "country": "Argentina",
    "IATA": "PRA",
    "icao_code": "SAAP"
  },
  {
    "city": "Paraparaumu",
    "country": "New Zealand",
    "IATA": "PPQ",
    "icao_code": "NZPP"
  },
  {
    "city": "Parauapebas",
    "country": "Brazil",
    "IATA": "CKS",
    "icao_code": "SBCJ"
  },
  {
    "city": "Parchim",
    "country": "Germany",
    "IATA": "SZW"
  },
  {
    "city": "Pardubice",
    "country": "Czech Republic",
    "IATA": "PED",
    "icao_code": "LKPD"
  },
  {
    "city": "Parintins",
    "country": "Brazil",
    "IATA": "PIN",
    "icao_code": "SWPI"
  },
  {
    "city": "Paris",
    "country": "United States",
    "IATA": "PRX",
    "icao_code": "KPRX"
  },
  {
    "city": "Paris",
    "country": "France",
    "IATA": "ORY",
    "icao_code": "LFPO"
  },
  {
    "city": "Paris",
    "country": "France",
    "IATA": "CDG",
    "icao_code": "LFPG"
  },
  {
    "city": "Paris",
    "country": "France",
    "IATA": "LBG",
    "icao_code": "LFPB"
  },
  {
    "city": "Parkes",
    "country": "Australia",
    "IATA": "PKE",
    "icao_code": "YPKS"
  },
  {
    "city": "Parma",
    "country": "Italy",
    "IATA": "PMF",
    "icao_code": "LIMP"
  },
  {
    "city": "Parnaiba",
    "country": "Brazil",
    "IATA": "PHB",
    "icao_code": "SBPB"
  },
  {
    "city": "Parnu",
    "country": "Estonia",
    "IATA": "EPU",
    "icao_code": "EEPU"
  },
  {
    "city": "Paros",
    "country": "Greece",
    "IATA": "PAS",
    "icao_code": "LGPA"
  },
  {
    "city": "Parry Sound",
    "country": "Canada",
    "IATA": "YPD"
  },
  {
    "city": "Parsabad",
    "country": "Iran",
    "IATA": "PFQ",
    "icao_code": "OITP"
  },
  {
    "city": "Parsons",
    "country": "United States",
    "IATA": "PPF",
    "icao_code": "KPPF"
  },
  {
    "city": "Pasco",
    "country": "United States",
    "IATA": "PSC",
    "icao_code": "KPSC"
  },
  {
    "city": "Pasighat",
    "country": "India",
    "IATA": "IXT",
    "icao_code": "VEPG"
  },
  {
    "city": "Pasir Pangaraian",
    "country": "Indonesia",
    "IATA": "PPR",
    "icao_code": "WIDE"
  },
  {
    "city": "Pasni",
    "country": "Pakistan",
    "IATA": "PSI"
  },
  {
    "city": "Paso De Los Libres",
    "country": "Argentina",
    "IATA": "AOL",
    "icao_code": "SARL"
  },
  {
    "city": "Paso Robles",
    "country": "United States",
    "IATA": "PRB",
    "icao_code": "KPRB"
  },
  {
    "city": "Passo Fundo",
    "country": "Brazil",
    "IATA": "PFB",
    "icao_code": "SBPF"
  },
  {
    "city": "Pastaza",
    "country": "Ecuador",
    "IATA": "PTZ",
    "icao_code": "SEPA"
  },
  {
    "city": "Pasto",
    "country": "Colombia",
    "IATA": "PSO",
    "icao_code": "SKPS"
  },
  {
    "city": "Pathankot",
    "country": "India",
    "IATA": "IXP",
    "icao_code": "VIPK"
  },
  {
    "city": "Pathein",
    "country": "Burma",
    "IATA": "BSX",
    "icao_code": "VYPN"
  },
  {
    "city": "Patina",
    "country": "India",
    "IATA": "PAT",
    "icao_code": "VEPT"
  },
  {
    "city": "Patos de Minas",
    "country": "Brazil",
    "IATA": "POJ",
    "icao_code": "SNPD"
  },
  {
    "city": "Patras",
    "country": "Greece",
    "IATA": "GPA",
    "icao_code": "LGRX"
  },
  {
    "city": "Patreksfjordur",
    "country": "Iceland",
    "IATA": "PFJ",
    "icao_code": "BIPA"
  },
  {
    "city": "Pattani",
    "country": "Thailand",
    "IATA": "PAN",
    "icao_code": "VTSK"
  },
  {
    "city": "Pattaya",
    "country": "Thailand",
    "IATA": "UTP",
    "icao_code": "VTBU"
  },
  {
    "city": "Patuxent River",
    "country": "United States",
    "IATA": "NHK"
  },
  {
    "city": "Pau",
    "country": "France",
    "IATA": "PUF",
    "icao_code": "LFBP"
  },
  {
    "city": "Paulatuk",
    "country": "Canada",
    "IATA": "YPC",
    "icao_code": "CYPC"
  },
  {
    "city": "Paulo Alfonso",
    "country": "Brazil",
    "IATA": "PAV",
    "icao_code": "SBUF"
  },
  {
    "city": "Pavlodar",
    "country": "Kazakhstan",
    "IATA": "PWQ",
    "icao_code": "UASP"
  },
  {
    "city": "Paya Lebar",
    "country": "Singapore",
    "IATA": "QPG",
    "icao_code": "WSAP"
  },
  {
    "city": "Paysandu",
    "country": "Uruguay",
    "IATA": "PDU",
    "icao_code": "SUPU"
  },
  {
    "city": "Paz De Ariporo",
    "country": "Colombia",
    "IATA": "PZA"
  },
  {
    "city": "Peace River",
    "country": "Canada",
    "IATA": "YPE",
    "icao_code": "CYPE"
  },
  {
    "city": "Peawanuck",
    "country": "Canada",
    "IATA": "YPO",
    "icao_code": "CYPO"
  },
  {
    "city": "Pechora",
    "country": "Russia",
    "IATA": "PEX"
  },
  {
    "city": "Pecos",
    "country": "United States",
    "IATA": "PEQ",
    "icao_code": "KPEQ"
  },
  {
    "city": "Pedro Juan Caballero",
    "country": "Paraguay",
    "IATA": "PJC",
    "icao_code": "SGPJ"
  },
  {
    "city": "Peenemunde",
    "country": "Germany",
    "IATA": "PEF"
  },
  {
    "city": "Pehuajo",
    "country": "Argentina",
    "IATA": "PEH",
    "icao_code": "SAZP"
  },
  {
    "city": "Pekanbaru",
    "country": "Indonesia",
    "IATA": "PKU",
    "icao_code": "WIBB"
  },
  {
    "city": "Pellston",
    "country": "United States",
    "IATA": "PLN",
    "icao_code": "KPLN"
  },
  {
    "city": "Pelly Bay",
    "country": "Canada",
    "IATA": "YBB",
    "icao_code": "CYBB"
  },
  {
    "city": "Pelotas",
    "country": "Brazil",
    "IATA": "PET",
    "icao_code": "SBPK"
  },
  {
    "city": "Pemba",
    "country": "Tanzania",
    "IATA": "PMA",
    "icao_code": "HTPE"
  },
  {
    "city": "Pemba",
    "country": "Mozambique",
    "IATA": "POL",
    "icao_code": "FQPB"
  },
  {
    "city": "Pembina",
    "country": "United States",
    "IATA": "PMB",
    "icao_code": "KPMB"
  },
  {
    "city": "Pembroke",
    "country": "Canada",
    "IATA": "YTA",
    "icao_code": "CYTA"
  },
  {
    "city": "Penang",
    "country": "Malaysia",
    "IATA": "PEN",
    "icao_code": "WMKP"
  },
  {
    "city": "Pendicherry",
    "country": "India",
    "IATA": "PNY",
    "icao_code": "VOPC"
  },
  {
    "city": "Pendleton",
    "country": "United States",
    "IATA": "PDT",
    "icao_code": "KPDT"
  },
  {
    "city": "Penneshaw",
    "country": "Australia",
    "IATA": "PEA",
    "icao_code": "YPSH"
  },
  {
    "city": "Penrhyn Island",
    "country": "Cook Islands",
    "IATA": "PYE"
  },
  {
    "city": "Pensacola",
    "country": "United States",
    "IATA": "PNS",
    "icao_code": "KPNS"
  },
  {
    "city": "Pensacola",
    "country": "United States",
    "IATA": "NPA",
    "icao_code": "KNPA"
  },
  {
    "city": "Pentecost Island",
    "country": "Vanuatu",
    "IATA": "SSR",
    "icao_code": "NVSH"
  },
  {
    "city": "Penticton",
    "country": "Canada",
    "IATA": "YYF",
    "icao_code": "CYYF"
  },
  {
    "city": "Penza",
    "country": "Russia",
    "IATA": "PEZ",
    "icao_code": "UWPP"
  },
  {
    "city": "Penzance",
    "country": "United Kingdom",
    "IATA": "PZE"
  },
  {
    "city": "Peoria",
    "country": "United States",
    "IATA": "PIA",
    "icao_code": "KPIA"
  },
  {
    "city": "Pereira",
    "country": "Colombia",
    "IATA": "PEI",
    "icao_code": "SKPE"
  },
  {
    "city": "Perigueux",
    "country": "France",
    "IATA": "PGX",
    "icao_code": "LFBX"
  },
  {
    "city": "Perito Moreno",
    "country": "Argentina",
    "IATA": "PMQ",
    "icao_code": "SAWP"
  },
  {
    "city": "Perm",
    "country": "Russia",
    "IATA": "PEE",
    "icao_code": "USPP"
  },
  {
    "city": "Perpignan",
    "country": "France",
    "IATA": "PGF",
    "icao_code": "LFMP"
  },
  {
    "city": "Perryville",
    "country": "United States",
    "IATA": "KPV",
    "icao_code": "PAPE"
  },
  {
    "city": "Perth",
    "country": "United Kingdom",
    "IATA": "PSL",
    "icao_code": "EGPT"
  },
  {
    "city": "Perth",
    "country": "Australia",
    "IATA": "PER",
    "icao_code": "YPPH"
  },
  {
    "city": "Perth",
    "country": "Australia",
    "IATA": "JAD",
    "icao_code": "YPJT"
  },
  {
    "city": "Peru",
    "country": "United States",
    "IATA": "VYS"
  },
  {
    "city": "Peru",
    "country": "United States",
    "IATA": "GUS",
    "icao_code": "KGUS"
  },
  {
    "city": "Perugia",
    "country": "Italy",
    "IATA": "PEG",
    "icao_code": "LIRZ"
  },
  {
    "city": "Pescara",
    "country": "Italy",
    "IATA": "PSR",
    "icao_code": "LIBP"
  },
  {
    "city": "Peshawar",
    "country": "Pakistan",
    "IATA": "PEW",
    "icao_code": "OPPS"
  },
  {
    "city": "Petawawa",
    "country": "Canada",
    "IATA": "YWA",
    "icao_code": "CYWA"
  },
  {
    "city": "Peterborough",
    "country": "Canada",
    "IATA": "YPQ",
    "icao_code": "CYPQ"
  },
  {
    "city": "Petermann",
    "country": "Australia",
    "IATA": "KCS",
    "icao_code": "YKCS"
  },
  {
    "city": "Petersburg",
    "country": "United States",
    "IATA": "PTB",
    "icao_code": "KPTB"
  },
  {
    "city": "Petersburg",
    "country": "United States",
    "IATA": "PSG",
    "icao_code": "PAPG"
  },
  {
    "city": "Petrolina",
    "country": "Brazil",
    "IATA": "PNZ",
    "icao_code": "SBPL"
  },
  {
    "city": "Petropavlosk",
    "country": "Kazakhstan",
    "IATA": "PPK",
    "icao_code": "UACP"
  },
  {
    "city": "Petropavlovsk",
    "country": "Russia",
    "IATA": "PKC",
    "icao_code": "UHPP"
  },
  {
    "city": "Petrozavodsk",
    "country": "Russia",
    "IATA": "PES",
    "icao_code": "ULPB"
  },
  {
    "city": "Pevek",
    "country": "Russia",
    "IATA": "PWE",
    "icao_code": "UHMP"
  },
  {
    "city": "Phalaborwa",
    "country": "South Africa",
    "IATA": "PHW",
    "icao_code": "FAPH"
  },
  {
    "city": "Phan Rang",
    "country": "Vietnam",
    "IATA": "PHA"
  },
  {
    "city": "Phaplu",
    "country": "Nepal",
    "IATA": "PPL",
    "icao_code": "VNPL"
  },
  {
    "city": "Phetchabun",
    "country": "Thailand",
    "IATA": "PHY",
    "icao_code": "VTPB"
  },
  {
    "city": "Philadelphia",
    "country": "United States",
    "IATA": "BBX",
    "icao_code": "KLOM"
  },
  {
    "city": "Philadelphia",
    "country": "United States",
    "IATA": "PHL",
    "icao_code": "KPHL"
  },
  {
    "city": "Philadelphia",
    "country": "United States",
    "IATA": "PNE",
    "icao_code": "KPNE"
  },
  {
    "city": "St Maarten",
    "country": "Netherlands Antilles",
    "IATA": "SXM",
    "icao_code": "TNCM"
  },
  {
    "city": "Phinda",
    "country": "South Africa",
    "IATA": "PZL",
    "icao_code": "FADQ"
  },
  {
    "city": "Phitsanulok",
    "country": "Thailand",
    "IATA": "PHS",
    "icao_code": "VTPP"
  },
  {
    "city": "Phnom-penh",
    "country": "Cambodia",
    "IATA": "PNH",
    "icao_code": "VDPP"
  },
  {
    "city": "Phoenix",
    "country": "United States",
    "IATA": "DVT",
    "icao_code": "KDVT"
  },
  {
    "city": "Phoenix",
    "country": "United States",
    "IATA": "LUF"
  },
  {
    "city": "Phoenix",
    "country": "United States",
    "IATA": "PHX",
    "icao_code": "KPHX"
  },
  {
    "city": "Phon Savan",
    "country": "Laos",
    "IATA": "XKH",
    "icao_code": "VLXK"
  },
  {
    "city": "Phongsaly",
    "country": "Laos",
    "IATA": "PCQ"
  },
  {
    "city": "Phrae",
    "country": "Thailand",
    "IATA": "PRH",
    "icao_code": "VTCP"
  },
  {
    "city": "Phucat",
    "country": "Vietnam",
    "IATA": "UIH",
    "icao_code": "VVQN"
  },
  {
    "city": "Phuket",
    "country": "Thailand",
    "IATA": "HKT",
    "icao_code": "VTSP"
  },
  {
    "city": "Phuquoc",
    "country": "Vietnam",
    "IATA": "PQC",
    "icao_code": "VVPQ"
  },
  {
    "city": "Pickle Lake",
    "country": "Canada",
    "IATA": "YPL",
    "icao_code": "CYPL"
  },
  {
    "city": "Pico",
    "country": "Portugal",
    "IATA": "PIX",
    "icao_code": "LPPI"
  },
  {
    "city": "Picos",
    "country": "Brazil",
    "IATA": "PCS",
    "icao_code": "SNPC"
  },
  {
    "city": "Picton",
    "country": "New Zealand",
    "IATA": "PCN",
    "icao_code": "NZPN"
  },
  {
    "city": "Piedras Negras",
    "country": "Mexico",
    "IATA": "PDS"
  },
  {
    "city": "Pierre",
    "country": "United States",
    "IATA": "PIR",
    "icao_code": "KPIR"
  },
  {
    "city": "Piestany",
    "country": "Slovakia",
    "IATA": "PZY"
  },
  {
    "city": "Pietermaritzburg",
    "country": "South Africa",
    "IATA": "PZB",
    "icao_code": "FAPM"
  },
  {
    "city": "Pikangikum",
    "country": "Canada",
    "IATA": "YPM",
    "icao_code": "CYPM"
  },
  {
    "city": "Pikeville",
    "country": "United States",
    "IATA": "PVL",
    "icao_code": "KPBX"
  },
  {
    "city": "Pikwitonei",
    "country": "Canada",
    "IATA": "PIW",
    "icao_code": "CZMN"
  },
  {
    "city": "Pilanesberg",
    "country": "South Africa",
    "IATA": "NTY",
    "icao_code": "FAPN"
  },
  {
    "city": "Pilar",
    "country": "Paraguay",
    "IATA": "PIL",
    "icao_code": "SGPI"
  },
  {
    "city": "Pilot Point",
    "country": "United States",
    "IATA": "PIP",
    "icao_code": "PAPN"
  },
  {
    "city": "Pimenta Bueno",
    "country": "Brazil",
    "IATA": "PBQ",
    "icao_code": "SWPM"
  },
  {
    "city": "Pinar Del Rio",
    "country": "Cuba",
    "IATA": "SNJ"
  },
  {
    "city": "Pinar Del Rio Norte",
    "country": "Cuba",
    "IATA": "QPD"
  },
  {
    "city": "Pincher Creek",
    "country": "Canada",
    "IATA": "WPC"
  },
  {
    "city": "Pine Mountain",
    "country": "United States",
    "IATA": "PIM",
    "icao_code": "KPIM"
  },
  {
    "city": "Pinedale",
    "country": "United States",
    "IATA": "PWY",
    "icao_code": "KPNA"
  },
  {
    "city": "Pinehurst-Southern Pines",
    "country": "United States",
    "IATA": "SOP",
    "icao_code": "KSOP"
  },
  {
    "city": "Pingtung",
    "country": "Taiwan",
    "IATA": "PIF",
    "icao_code": "RCDC"
  },
  {
    "city": "Piracununga",
    "country": "Brazil",
    "IATA": "QPS"
  },
  {
    "city": "Pirlangimpi",
    "country": "Australia",
    "IATA": "GPN",
    "icao_code": "YGPT"
  },
  {
    "city": "Pisa",
    "country": "Italy",
    "IATA": "PSA",
    "icao_code": "LIRP"
  },
  {
    "city": "Pisco",
    "country": "Peru",
    "IATA": "PIO",
    "icao_code": "SPSO"
  },
  {
    "city": "Pitalito",
    "country": "Colombia",
    "IATA": "PTX",
    "icao_code": "SKPI"
  },
  {
    "city": "Pittsburgh",
    "country": "United States",
    "IATA": "AGC",
    "icao_code": "KAGC"
  },
  {
    "city": "Pittsburgh",
    "country": "United States",
    "IATA": "PIT",
    "icao_code": "KPIT"
  },
  {
    "city": "Piura",
    "country": "Peru",
    "IATA": "PIU",
    "icao_code": "SPUR"
  },
  {
    "city": "Plaisance",
    "country": "Mauritius",
    "IATA": "MRU",
    "icao_code": "FIMP"
  },
  {
    "city": "Plattsburgh",
    "country": "United States",
    "IATA": "PBG",
    "icao_code": "KPBG"
  },
  {
    "city": "Pleiku",
    "country": "Vietnam",
    "IATA": "PXU",
    "icao_code": "VVPK"
  },
  {
    "city": "Plettenberg Bay",
    "country": "South Africa",
    "IATA": "PBZ",
    "icao_code": "FAPG"
  },
  {
    "city": "Plovdiv",
    "country": "Bulgaria",
    "IATA": "PDV",
    "icao_code": "LBPD"
  },
  {
    "city": "Plymouth",
    "country": "United States",
    "IATA": "PYM",
    "icao_code": "KPYM"
  },
  {
    "city": "Plymouth",
    "country": "United Kingdom",
    "IATA": "PLH"
  },
  {
    "city": "Pocatello",
    "country": "United States",
    "IATA": "PIH",
    "icao_code": "KPIH"
  },
  {
    "city": "Pococi",
    "country": "Costa Rica",
    "IATA": "BCL",
    "icao_code": "MRBC"
  },
  {
    "city": "Pocos De Caldas",
    "country": "Brazil",
    "IATA": "POO",
    "icao_code": "SBPC"
  },
  {
    "city": "Podgorica",
    "country": "Montenegro",
    "IATA": "TGD",
    "icao_code": "LYPG"
  },
  {
    "city": "Pohang",
    "country": "South Korea",
    "IATA": "KPO",
    "icao_code": "RKTH"
  },
  {
    "city": "Pohnpei",
    "country": "Micronesia",
    "IATA": "PNI",
    "icao_code": "PTPN"
  },
  {
    "city": "Point Barrow",
    "country": "United States",
    "IATA": "STG",
    "icao_code": "PAPB"
  },
  {
    "city": "Point Lay",
    "country": "United States",
    "IATA": "PIZ",
    "icao_code": "PPIZ"
  },
  {
    "city": "Point Mugu",
    "country": "United States",
    "IATA": "NTD",
    "icao_code": "KNTD"
  },
  {
    "city": "Point Salines",
    "country": "Grenada",
    "IATA": "GND",
    "icao_code": "TGPY"
  },
  {
    "city": "Pointe-a-pitre",
    "country": "Guadeloupe",
    "IATA": "PTP",
    "icao_code": "TFFR"
  },
  {
    "city": "Pointe-noire",
    "country": "Congo (Brazzaville)",
    "IATA": "PNR",
    "icao_code": "FCPP"
  },
  {
    "city": "Points North Landing",
    "country": "Canada",
    "IATA": "YNL",
    "icao_code": "CYNL"
  },
  {
    "city": "Poitiers",
    "country": "France",
    "IATA": "PIS",
    "icao_code": "LFBI"
  },
  {
    "city": "Pokhara",
    "country": "Nepal",
    "IATA": "PKR",
    "icao_code": "VNPK"
  },
  {
    "city": "Polonnaruwa Town",
    "country": "Sri Lanka",
    "IATA": "HIM",
    "icao_code": "VCCH"
  },
  {
    "city": "Poltava",
    "country": "Ukraine",
    "IATA": "PLV",
    "icao_code": "UKHP"
  },
  {
    "city": "Pompano Beach",
    "country": "United States",
    "IATA": "PPM",
    "icao_code": "KPMP"
  },
  {
    "city": "Ponca City",
    "country": "United States",
    "IATA": "PNC",
    "icao_code": "KPNC"
  },
  {
    "city": "Ponce",
    "country": "Puerto Rico",
    "IATA": "PSE",
    "icao_code": "TJPS"
  },
  {
    "city": "Pond Inlet",
    "country": "Canada",
    "IATA": "YIO",
    "icao_code": "CYIO"
  },
  {
    "city": "Ponta Delgada",
    "country": "Portugal",
    "IATA": "PDL",
    "icao_code": "LPPD"
  },
  {
    "city": "Ponta Grossa",
    "country": "Brazil",
    "IATA": "PGZ",
    "icao_code": "SSZW"
  },
  {
    "city": "Ponta Pora",
    "country": "Brazil",
    "IATA": "PMG",
    "icao_code": "SBPP"
  },
  {
    "city": "Pontiac",
    "country": "United States",
    "IATA": "PTK",
    "icao_code": "KPTK"
  },
  {
    "city": "Pontianak",
    "country": "Indonesia",
    "IATA": "PNK",
    "icao_code": "WIOO"
  },
  {
    "city": "Pontoise",
    "country": "France",
    "IATA": "POX",
    "icao_code": "LFPT"
  },
  {
    "city": "Popayan",
    "country": "Colombia",
    "IATA": "PPN",
    "icao_code": "SKPP"
  },
  {
    "city": "Poplar Bluff",
    "country": "United States",
    "IATA": "POF"
  },
  {
    "city": "Poplar Hill",
    "country": "Canada",
    "IATA": "YHP",
    "icao_code": "CPV7"
  },
  {
    "city": "Poplar River",
    "country": "Canada",
    "IATA": "XPP"
  },
  {
    "city": "Poprad",
    "country": "Slovakia",
    "IATA": "TAT",
    "icao_code": "LZTT"
  },
  {
    "city": "Porbandar",
    "country": "India",
    "IATA": "PBD",
    "icao_code": "VAPR"
  },
  {
    "city": "Pori",
    "country": "Finland",
    "IATA": "POR",
    "icao_code": "EFPO"
  },
  {
    "city": "Porlamar",
    "country": "Venezuela",
    "IATA": "PMV",
    "icao_code": "SVMG"
  },
  {
    "city": "Pormpuraaw",
    "country": "Australia",
    "IATA": "EDR",
    "icao_code": "YPMP"
  },
  {
    "city": "Port Angeles",
    "country": "United States",
    "IATA": "CLM",
    "icao_code": "KCLM"
  },
  {
    "city": "Port Antonio",
    "country": "Jamaica",
    "IATA": "POT"
  },
  {
    "city": "Port Bergé",
    "country": "Madagascar",
    "IATA": "WPB"
  },
  {
    "city": "Port Blair",
    "country": "India",
    "IATA": "IXZ",
    "icao_code": "VOPB"
  },
  {
    "city": "Port Clarence",
    "country": "United States",
    "IATA": "KPC",
    "icao_code": "PAPC"
  },
  {
    "city": "Port Elizabeth",
    "country": "South Africa",
    "IATA": "PLZ",
    "icao_code": "FAPE"
  },
  {
    "city": "Port Gentil",
    "country": "Gabon",
    "IATA": "POG",
    "icao_code": "FOOG"
  },
  {
    "city": "Port Hardy",
    "country": "Canada",
    "IATA": "YZT",
    "icao_code": "CYZT"
  },
  {
    "city": "Port Hartcourt",
    "country": "Nigeria",
    "IATA": "PHC",
    "icao_code": "DNPO"
  },
  {
    "city": "Port Hawkesbury",
    "country": "Canada",
    "IATA": "YPS",
    "icao_code": "CYPD"
  },
  {
    "city": "Port Hedland",
    "country": "Australia",
    "IATA": "PHE",
    "icao_code": "YPPD"
  },
  {
    "city": "Port Heiden",
    "country": "United States",
    "IATA": "PTH",
    "icao_code": "PAPH"
  },
  {
    "city": "Port Hope Simpson",
    "country": "Canada",
    "IATA": "YHA",
    "icao_code": "CYHA"
  },
  {
    "city": "Port Huron",
    "country": "United States",
    "IATA": "PHN",
    "icao_code": "KPHN"
  },
  {
    "city": "Port Lincoln",
    "country": "Australia",
    "IATA": "PLO",
    "icao_code": "YPLC"
  },
  {
    "city": "Port Macquarie",
    "country": "Australia",
    "IATA": "PQQ",
    "icao_code": "YPMQ"
  },
  {
    "city": "Port Menier",
    "country": "Canada",
    "IATA": "YPN",
    "icao_code": "CYPN"
  },
  {
    "city": "Port Moller",
    "country": "United States",
    "IATA": "PTU",
    "icao_code": "PAPM"
  },
  {
    "city": "Port Moresby",
    "country": "Papua New Guinea",
    "IATA": "POM",
    "icao_code": "AYPY"
  },
  {
    "city": "Port Nelson",
    "country": "Bahamas",
    "IATA": "RCY",
    "icao_code": "MYRP"
  },
  {
    "city": "Port Said",
    "country": "Egypt",
    "IATA": "PSD",
    "icao_code": "HEPS"
  },
  {
    "city": "Port Saint Johns",
    "country": "South Africa",
    "IATA": "JOH",
    "icao_code": "FAPJ"
  },
  {
    "city": "Port Sudan",
    "country": "Sudan",
    "IATA": "PZU",
    "icao_code": "HSSP"
  },
  {
    "city": "Port alsworth",
    "country": "United States",
    "IATA": "PTA",
    "icao_code": "PALJ"
  },
  {
    "city": "Port-au-prince",
    "country": "Haiti",
    "IATA": "PAP",
    "icao_code": "MTPP"
  },
  {
    "city": "Port-de-Paix",
    "country": "Haiti",
    "IATA": "PAX"
  },
  {
    "city": "Port-of-spain",
    "country": "Trinidad and Tobago",
    "IATA": "POS",
    "icao_code": "TTPP"
  },
  {
    "city": "Port-vila",
    "country": "Vanuatu",
    "IATA": "VLI",
    "icao_code": "NVVV"
  },
  {
    "city": "Portage-la-prairie",
    "country": "Canada",
    "IATA": "YPG",
    "icao_code": "CYPG"
  },
  {
    "city": "Portimao",
    "country": "Portugal",
    "IATA": "PRM",
    "icao_code": "LPPM"
  },
  {
    "city": "Portland",
    "country": "Australia",
    "IATA": "PTJ",
    "icao_code": "YPOR"
  },
  {
    "city": "Portland",
    "country": "United States",
    "IATA": "PWM",
    "icao_code": "KPWM"
  },
  {
    "city": "Portland",
    "country": "United States",
    "IATA": "PDX",
    "icao_code": "KPDX"
  },
  {
    "city": "Porto",
    "country": "Portugal",
    "IATA": "OPO",
    "icao_code": "LPPR"
  },
  {
    "city": "Porto Alegre",
    "country": "Brazil",
    "IATA": "POA",
    "icao_code": "SBPA"
  },
  {
    "city": "Porto Amboim",
    "country": "Angola",
    "IATA": "PBN",
    "icao_code": "FNPA"
  },
  {
    "city": "Porto Heli",
    "country": "Greece",
    "IATA": "PKH",
    "icao_code": "LGHL"
  },
  {
    "city": "Porto Nacional",
    "country": "Brazil",
    "IATA": "PNB",
    "icao_code": "SBPN"
  },
  {
    "city": "Porto Santo",
    "country": "Portugal",
    "IATA": "PXO",
    "icao_code": "LPPS"
  },
  {
    "city": "Porto Seguro",
    "country": "Brazil",
    "IATA": "BPS",
    "icao_code": "SBPS"
  },
  {
    "city": "Porto Velho",
    "country": "Brazil",
    "IATA": "PVH",
    "icao_code": "SBPV"
  },
  {
    "city": "Portoroz",
    "country": "Slovenia",
    "IATA": "POW",
    "icao_code": "LJPZ"
  },
  {
    "city": "Portoviejo",
    "country": "Ecuador",
    "IATA": "PVO",
    "icao_code": "SEPV"
  },
  {
    "city": "Portsmouth",
    "country": "United States",
    "IATA": "PMH",
    "icao_code": "KPMH"
  },
  {
    "city": "Portsmouth",
    "country": "United States",
    "IATA": "PSM",
    "icao_code": "KPSM"
  },
  {
    "city": "Porvenir",
    "country": "Chile",
    "IATA": "WPR"
  },
  {
    "city": "Posadas",
    "country": "Argentina",
    "IATA": "PSS",
    "icao_code": "SARP"
  },
  {
    "city": "Poso",
    "country": "Indonesia",
    "IATA": "PSJ",
    "icao_code": "WAMP"
  },
  {
    "city": "Postville",
    "country": "Canada",
    "IATA": "YSO",
    "icao_code": "CCD4"
  },
  {
    "city": "Potchefstroom",
    "country": "South Africa",
    "IATA": "PCF"
  },
  {
    "city": "Potgietersrus",
    "country": "South Africa",
    "IATA": "PTG",
    "icao_code": "FAPI"
  },
  {
    "city": "Potosi",
    "country": "Bolivia",
    "IATA": "POI",
    "icao_code": "SLPO"
  },
  {
    "city": "Poughkeepsie",
    "country": "United States",
    "IATA": "POU",
    "icao_code": "KPOU"
  },
  {
    "city": "Pouso Alegre",
    "country": "Brazil",
    "IATA": "PPY",
    "icao_code": "SNZA"
  },
  {
    "city": "Powell River",
    "country": "Canada",
    "IATA": "YPW",
    "icao_code": "CYPW"
  },
  {
    "city": "Poza Rico",
    "country": "Mexico",
    "IATA": "PAZ",
    "icao_code": "MMPA"
  },
  {
    "city": "Poznan",
    "country": "Poland",
    "IATA": "POZ",
    "icao_code": "EPPO"
  },
  {
    "city": "Prachuap Khiri Khan",
    "country": "Thailand",
    "IATA": "HHQ",
    "icao_code": "VTPH"
  },
  {
    "city": "Prague",
    "country": "Czech Republic",
    "IATA": "PRG",
    "icao_code": "LKPR"
  },
  {
    "city": "Prairie du Chien",
    "country": "United States",
    "IATA": "PCD",
    "icao_code": "KPDC"
  },
  {
    "city": "Praslin",
    "country": "Seychelles",
    "IATA": "PRI",
    "icao_code": "FSPP"
  },
  {
    "city": "Pratt",
    "country": "United States",
    "IATA": "PTT",
    "icao_code": "KPTT"
  },
  {
    "city": "Praya",
    "country": "Indonesia",
    "IATA": "LOP",
    "icao_code": "WADL"
  },
  {
    "city": "Prerov",
    "country": "Czech Republic",
    "IATA": "PRV",
    "icao_code": "LKPO"
  },
  {
    "city": "Prescott",
    "country": "United States",
    "IATA": "PRC",
    "icao_code": "KPRC"
  },
  {
    "city": "Presidencia R.s.pena",
    "country": "Argentina",
    "IATA": "PRQ"
  },
  {
    "city": "President Prudente",
    "country": "Brazil",
    "IATA": "PPB",
    "icao_code": "SBDN"
  },
  {
    "city": "Presque Isle",
    "country": "United States",
    "IATA": "PQI",
    "icao_code": "KPQI"
  },
  {
    "city": "Prestwick",
    "country": "United Kingdom",
    "IATA": "PIK",
    "icao_code": "EGPK"
  },
  {
    "city": "Pretoria",
    "country": "South Africa",
    "IATA": "PRY",
    "icao_code": "FAWB"
  },
  {
    "city": "Preveza",
    "country": "Greece",
    "IATA": "PVK",
    "icao_code": "LGPZ"
  },
  {
    "city": "Price",
    "country": "United States",
    "IATA": "PUC",
    "icao_code": "KPUC"
  },
  {
    "city": "Prince Albert",
    "country": "Canada",
    "IATA": "YPA",
    "icao_code": "CYPA"
  },
  {
    "city": "Prince George",
    "country": "Canada",
    "IATA": "YXS",
    "icao_code": "CYXS"
  },
  {
    "city": "Prince Pupert",
    "country": "Canada",
    "IATA": "YPR",
    "icao_code": "CYPR"
  },
  {
    "city": "Prince Rupert",
    "country": "Canada",
    "IATA": "ZSW",
    "icao_code": "CZSW"
  },
  {
    "city": "Principe",
    "country": "Sao Tome and Principe",
    "IATA": "PCP",
    "icao_code": "FPPR"
  },
  {
    "city": "Prineville",
    "country": "United States",
    "IATA": "PRZ"
  },
  {
    "city": "Pristina",
    "country": "Serbia",
    "IATA": "PRN",
    "icao_code": "LYPR"
  },
  {
    "city": "Prominent Hill",
    "country": "Australia",
    "IATA": "PXH",
    "icao_code": "YPMH"
  },
  {
    "city": "Propriano",
    "country": "France",
    "IATA": "PRP"
  },
  {
    "city": "Prospect Creek",
    "country": "United States",
    "IATA": "PPC",
    "icao_code": "PAPR"
  },
  {
    "city": "Prosserpine",
    "country": "Australia",
    "IATA": "PPP",
    "icao_code": "YBPN"
  },
  {
    "city": "Providence",
    "country": "United States",
    "IATA": "PVD",
    "icao_code": "KPVD"
  },
  {
    "city": "Providencia",
    "country": "Colombia",
    "IATA": "PVA",
    "icao_code": "SKPV"
  },
  {
    "city": "Providenciales",
    "country": "Turks and Caicos Islands",
    "IATA": "PLS",
    "icao_code": "MBPV"
  },
  {
    "city": "Provideniya Bay",
    "country": "Russia",
    "IATA": "PVS",
    "icao_code": "UHMD"
  },
  {
    "city": "Provincetown",
    "country": "United States",
    "IATA": "PVC",
    "icao_code": "KPVC"
  },
  {
    "city": "Provo",
    "country": "United States",
    "IATA": "PVU",
    "icao_code": "KPVU"
  },
  {
    "city": "Pskov",
    "country": "Russia",
    "IATA": "PKV",
    "icao_code": "ULOO"
  },
  {
    "city": "Pucallpa",
    "country": "Peru",
    "IATA": "PCL",
    "icao_code": "SPCL"
  },
  {
    "city": "Pucon",
    "country": "Chile",
    "IATA": "ZPC",
    "icao_code": "SCPC"
  },
  {
    "city": "Puebla",
    "country": "Mexico",
    "IATA": "PBC",
    "icao_code": "MMPB"
  },
  {
    "city": "Pueblo",
    "country": "United States",
    "IATA": "PUB",
    "icao_code": "KPUB"
  },
  {
    "city": "Puerto Asis",
    "country": "Colombia",
    "IATA": "PUU",
    "icao_code": "SKAS"
  },
  {
    "city": "Puerto Ayacucho",
    "country": "Venezuela",
    "IATA": "PYH",
    "icao_code": "SVPA"
  },
  {
    "city": "Puerto Barrios",
    "country": "Guatemala",
    "IATA": "PBR",
    "icao_code": "MGPB"
  },
  {
    "city": "Puerto Cabello",
    "country": "Venezuela",
    "IATA": "PBL",
    "icao_code": "SVPC"
  },
  {
    "city": "Puerto Cabezas",
    "country": "Nicaragua",
    "IATA": "PUZ",
    "icao_code": "MNPC"
  },
  {
    "city": "Puerto Carreno",
    "country": "Colombia",
    "IATA": "PCR",
    "icao_code": "SKPC"
  },
  {
    "city": "Puerto Deseado",
    "country": "Argentina",
    "IATA": "PUD",
    "icao_code": "SAWD"
  },
  {
    "city": "Puerto Escondido",
    "country": "Mexico",
    "IATA": "PXM",
    "icao_code": "MMPS"
  },
  {
    "city": "Puerto Inírida",
    "country": "Colombia",
    "IATA": "PDA",
    "icao_code": "SKPD"
  },
  {
    "city": "Puerto Jimenez",
    "country": "Costa Rica",
    "IATA": "PJM",
    "icao_code": "MRPJ"
  },
  {
    "city": "Puerto Leguízamo",
    "country": "Colombia",
    "IATA": "LQM",
    "icao_code": "SKLG"
  },
  {
    "city": "Puerto Lempira",
    "country": "Honduras",
    "IATA": "PEU",
    "icao_code": "MHPL"
  },
  {
    "city": "Puerto Madryn",
    "country": "Argentina",
    "IATA": "PMY",
    "icao_code": "SAVY"
  },
  {
    "city": "Puerto Maldonado",
    "country": "Peru",
    "IATA": "PEM",
    "icao_code": "SPTU"
  },
  {
    "city": "Puerto Montt",
    "country": "Chile",
    "IATA": "PMC",
    "icao_code": "SCTE"
  },
  {
    "city": "Puerto Natales",
    "country": "Chile",
    "IATA": "PNT",
    "icao_code": "SCNT"
  },
  {
    "city": "Puerto Obaldia",
    "country": "Panama",
    "IATA": "PUE",
    "icao_code": "MPOA"
  },
  {
    "city": "Puerto Plata",
    "country": "Dominican Republic",
    "IATA": "POP",
    "icao_code": "MDPP"
  },
  {
    "city": "Puerto Princesa",
    "country": "Philippines",
    "IATA": "PPS",
    "icao_code": "RPVP"
  },
  {
    "city": "Puerto Rico/Manuripi",
    "country": "Bolivia",
    "IATA": "PUR",
    "icao_code": "SLPR"
  },
  {
    "city": "Puerto Suarez",
    "country": "Bolivia",
    "IATA": "PSZ",
    "icao_code": "SLPS"
  },
  {
    "city": "Puerto Vallarta",
    "country": "Mexico",
    "IATA": "PVR",
    "icao_code": "MMPR"
  },
  {
    "city": "Puerto Williams",
    "country": "Chile",
    "IATA": "WPU"
  },
  {
    "city": "Puka Puka",
    "country": "French Polynesia",
    "IATA": "PKP",
    "icao_code": "NTGP"
  },
  {
    "city": "Pukaki",
    "country": "New Zealand",
    "IATA": "TWZ"
  },
  {
    "city": "Pukarua",
    "country": "French Polynesia",
    "IATA": "PUK",
    "icao_code": "NTGQ"
  },
  {
    "city": "Pukatawagan",
    "country": "Canada",
    "IATA": "XPK",
    "icao_code": "CZFG"
  },
  {
    "city": "Pula",
    "country": "Croatia",
    "IATA": "PUY",
    "icao_code": "LDPL"
  },
  {
    "city": "Pullman",
    "country": "United States",
    "IATA": "PUW",
    "icao_code": "KPUW"
  },
  {
    "city": "Pune",
    "country": "India",
    "IATA": "PNQ",
    "icao_code": "VAPO"
  },
  {
    "city": "Punta Arenas",
    "country": "Chile",
    "IATA": "PUQ",
    "icao_code": "SCCI"
  },
  {
    "city": "Punta Cana",
    "country": "Dominican Republic",
    "IATA": "PUJ",
    "icao_code": "MDPC"
  },
  {
    "city": "Punta Gorda",
    "country": "United States",
    "IATA": "PGD",
    "icao_code": "KPGD"
  },
  {
    "city": "Punta Penasco",
    "country": "Mexico",
    "IATA": "PPE"
  },
  {
    "city": "Punta del Este",
    "country": "Uruguay",
    "IATA": "PDP",
    "icao_code": "SUPE"
  },
  {
    "city": "Puntarenas",
    "country": "Costa Rica",
    "IATA": "DRK",
    "icao_code": "MRDK"
  },
  {
    "city": "Putao",
    "country": "Burma",
    "IATA": "PBU",
    "icao_code": "VYPT"
  },
  {
    "city": "Puttaparthi",
    "country": "India",
    "IATA": "PUT",
    "icao_code": "VOPN"
  },
  {
    "city": "Putussibau-Borneo Island",
    "country": "Indonesia",
    "IATA": "PSU",
    "icao_code": "WIOP"
  },
  {
    "city": "Puvirnituq",
    "country": "Canada",
    "IATA": "YPX",
    "icao_code": "CYPX"
  },
  {
    "city": "Pyay",
    "country": "Burma",
    "IATA": "PRU",
    "icao_code": "VYPY"
  },
  {
    "city": "Pyongyang",
    "country": "North Korea",
    "IATA": "FNJ",
    "icao_code": "ZKPY"
  },
  {
    "city": "Pécs-Pogány",
    "country": "Hungary",
    "IATA": "PEV",
    "icao_code": "LHPP"
  },
  {
    "city": "Qaanaaq",
    "country": "Greenland",
    "IATA": "NAQ",
    "icao_code": "BGQQ"
  },
  {
    "city": "Qabala",
    "country": "Azerbaijan",
    "IATA": "GBB",
    "icao_code": "UBBQ"
  },
  {
    "city": "Qaqortoq",
    "country": "Greenland",
    "IATA": "JJU",
    "icao_code": "BGJH"
  },
  {
    "city": "Qasigiannguit",
    "country": "Greenland",
    "IATA": "JCH",
    "icao_code": "BGCH"
  },
  {
    "city": "Qayyarah",
    "country": "Iraq",
    "IATA": "RQW"
  },
  {
    "city": "Qeqertaq",
    "country": "Greenland",
    "IATA": "QQT",
    "icao_code": "BGQE"
  },
  {
    "city": "Qeqertarsuaq Airport",
    "country": "Greenland",
    "IATA": "JGO",
    "icao_code": "BGGN"
  },
  {
    "city": "Qianjiang",
    "country": "China",
    "IATA": "JIQ"
  },
  {
    "city": "Qiemo",
    "country": "China",
    "IATA": "IQM",
    "icao_code": "ZWCM"
  },
  {
    "city": "Qingdao",
    "country": "China",
    "IATA": "TAO",
    "icao_code": "ZSQD"
  },
  {
    "city": "Qingyang",
    "country": "China",
    "IATA": "IQN",
    "icao_code": "ZLQY"
  },
  {
    "city": "Qinhuangdao",
    "country": "China",
    "IATA": "SHP",
    "icao_code": "ZBSH"
  },
  {
    "city": "Qionghai",
    "country": "China",
    "IATA": "BAR"
  },
  {
    "city": "Qiqihar",
    "country": "China",
    "IATA": "NDG",
    "icao_code": "ZYQQ"
  },
  {
    "city": "Quakertown",
    "country": "United States",
    "IATA": "UKT",
    "icao_code": "KUKT"
  },
  {
    "city": "Quanzhou",
    "country": "China",
    "IATA": "JJN",
    "icao_code": "ZSQZ"
  },
  {
    "city": "Quaqtaq",
    "country": "Canada",
    "IATA": "YQC",
    "icao_code": "CUHA"
  },
  {
    "city": "Quebec",
    "country": "Canada",
    "IATA": "YQB",
    "icao_code": "CYQB"
  },
  {
    "city": "Queensbury",
    "country": "United States",
    "IATA": "GFL",
    "icao_code": "KGFL"
  },
  {
    "city": "Queenstown",
    "country": "South Africa",
    "IATA": "UTW",
    "icao_code": "FAQT"
  },
  {
    "city": "Queenstown International",
    "country": "New Zealand",
    "IATA": "ZQN",
    "icao_code": "NZQN"
  },
  {
    "city": "Quelimane",
    "country": "Mozambique",
    "IATA": "UEL",
    "icao_code": "FQQL"
  },
  {
    "city": "Quepos",
    "country": "Costa Rica",
    "IATA": "XQP",
    "icao_code": "MRQP"
  },
  {
    "city": "Queretaro",
    "country": "Mexico",
    "IATA": "QRO",
    "icao_code": "MMQT"
  },
  {
    "city": "Quesnel",
    "country": "Canada",
    "IATA": "YQZ",
    "icao_code": "CYQZ"
  },
  {
    "city": "Quetta",
    "country": "Pakistan",
    "IATA": "UET",
    "icao_code": "OPQT"
  },
  {
    "city": "Quezaltenango",
    "country": "Guatemala",
    "IATA": "AAZ",
    "icao_code": "MGQZ"
  },
  {
    "city": "Quibdo",
    "country": "Colombia",
    "IATA": "UIB",
    "icao_code": "SKUI"
  },
  {
    "city": "Quilpie",
    "country": "Australia",
    "IATA": "ULP",
    "icao_code": "YQLP"
  },
  {
    "city": "Quimper",
    "country": "France",
    "IATA": "UIP",
    "icao_code": "LFRQ"
  },
  {
    "city": "Quincy",
    "country": "United States",
    "IATA": "UIN",
    "icao_code": "KUIN"
  },
  {
    "city": "Quinhagak",
    "country": "United States",
    "IATA": "KWN",
    "icao_code": "PAQH"
  },
  {
    "city": "Quito",
    "country": "Ecuador",
    "IATA": "UIO",
    "icao_code": "SEQU"
  },
  {
    "city": "Quujjuaq",
    "country": "Canada",
    "IATA": "YVP",
    "icao_code": "CYVP"
  },
  {
    "city": "Quzhou",
    "country": "China",
    "IATA": "JUZ",
    "icao_code": "ZSJU"
  },
  {
    "city": "RADOM",
    "country": "Poland",
    "IATA": "RDO",
    "icao_code": "EPRA"
  },
  {
    "city": "Rabat",
    "country": "Morocco",
    "IATA": "RBA",
    "icao_code": "GMME"
  },
  {
    "city": "Rach Gia",
    "country": "Vietnam",
    "IATA": "VKG",
    "icao_code": "VVRG"
  },
  {
    "city": "Racine",
    "country": "United States",
    "IATA": "RAC"
  },
  {
    "city": "Raduzhnyi",
    "country": "Russia",
    "IATA": "RAT"
  },
  {
    "city": "Rafha",
    "country": "Saudi Arabia",
    "IATA": "RAH",
    "icao_code": "OERF"
  },
  {
    "city": "Rafsanjan",
    "country": "Iran",
    "IATA": "RJN",
    "icao_code": "OIKR"
  },
  {
    "city": "Raha",
    "country": "Indonesia",
    "IATA": "RAQ",
    "icao_code": "WAWR"
  },
  {
    "city": "Rahim Yar Khan",
    "country": "Pakistan",
    "IATA": "RYK",
    "icao_code": "OPRK"
  },
  {
    "city": "Raiatea Island",
    "country": "French Polynesia",
    "IATA": "RFP",
    "icao_code": "NTTR"
  },
  {
    "city": "Rainbow Lake",
    "country": "Canada",
    "IATA": "YOP",
    "icao_code": "CYOP"
  },
  {
    "city": "Raipur",
    "country": "India",
    "IATA": "RPR",
    "icao_code": "VARP"
  },
  {
    "city": "Raivavae",
    "country": "French Polynesia",
    "IATA": "RVV",
    "icao_code": "NTAV"
  },
  {
    "city": "Rajahmundry",
    "country": "India",
    "IATA": "RJA",
    "icao_code": "VORY"
  },
  {
    "city": "Rajbiraj",
    "country": "Nepal",
    "IATA": "RJB",
    "icao_code": "VNRB"
  },
  {
    "city": "Rajkot",
    "country": "India",
    "IATA": "RAJ",
    "icao_code": "VARK"
  },
  {
    "city": "Rajshahi",
    "country": "Bangladesh",
    "IATA": "RJH",
    "icao_code": "VGRJ"
  },
  {
    "city": "Raleigh-durham",
    "country": "United States",
    "IATA": "RDU",
    "icao_code": "KRDU"
  },
  {
    "city": "Ramata",
    "country": "Solomon Islands",
    "IATA": "RBV",
    "icao_code": "AGRM"
  },
  {
    "city": "Ramechhap",
    "country": "Nepal",
    "IATA": "RHP",
    "icao_code": "VNRC"
  },
  {
    "city": "Ramenskoe",
    "country": "Russia",
    "IATA": "ZIA",
    "icao_code": "UUBW"
  },
  {
    "city": "Ramingining",
    "country": "Australia",
    "IATA": "RAM",
    "icao_code": "YRNG"
  },
  {
    "city": "Ramon",
    "country": "Israel",
    "IATA": "MIP",
    "icao_code": "LLMR"
  },
  {
    "city": "Ramsar",
    "country": "Iran",
    "IATA": "RZR",
    "icao_code": "OINR"
  },
  {
    "city": "Ramstein",
    "country": "Germany",
    "IATA": "RMS",
    "icao_code": "ETAR"
  },
  {
    "city": "Ranai-Natuna Besar Island",
    "country": "Indonesia",
    "IATA": "NTX",
    "icao_code": "WION"
  },
  {
    "city": "Rancagua",
    "country": "Chile",
    "IATA": "QRC"
  },
  {
    "city": "Ranchi",
    "country": "India",
    "IATA": "IXR",
    "icao_code": "VERC"
  },
  {
    "city": "Rangiroa",
    "country": "French Polynesia",
    "IATA": "RGI",
    "icao_code": "NTTG"
  },
  {
    "city": "Rankin Inlet",
    "country": "Canada",
    "IATA": "YRT",
    "icao_code": "CYRT"
  },
  {
    "city": "Ranong",
    "country": "Thailand",
    "IATA": "UNN",
    "icao_code": "VTSR"
  },
  {
    "city": "Rapid City",
    "country": "United States",
    "IATA": "RAP",
    "icao_code": "KRAP"
  },
  {
    "city": "Rapid City",
    "country": "United States",
    "IATA": "RCA"
  },
  {
    "city": "Raroia",
    "country": "French Polynesia",
    "IATA": "RRR",
    "icao_code": "NTKO"
  },
  {
    "city": "Ras Al Khaimah",
    "country": "United Arab Emirates",
    "IATA": "RKT",
    "icao_code": "OMRK"
  },
  {
    "city": "Rasht",
    "country": "Iran",
    "IATA": "RAS",
    "icao_code": "OIGG"
  },
  {
    "city": "Ratanakiri",
    "country": "Cambodia",
    "IATA": "RBE",
    "icao_code": "VDRK"
  },
  {
    "city": "Raton",
    "country": "United States",
    "IATA": "RTN"
  },
  {
    "city": "Ravensthorpe",
    "country": "Australia",
    "IATA": "RVT",
    "icao_code": "YNRV"
  },
  {
    "city": "Rawala Kot",
    "country": "Pakistan",
    "IATA": "RAZ",
    "icao_code": "OPRT"
  },
  {
    "city": "Rawlins",
    "country": "United States",
    "IATA": "RWL",
    "icao_code": "KFWL"
  },
  {
    "city": "Reading",
    "country": "United States",
    "IATA": "RDG",
    "icao_code": "KRDG"
  },
  {
    "city": "Reao",
    "country": "French Polynesia",
    "IATA": "REA",
    "icao_code": "NTGE"
  },
  {
    "city": "Rechlin-laerz",
    "country": "Germany",
    "IATA": "REB",
    "icao_code": "EDAX"
  },
  {
    "city": "Recife",
    "country": "Brazil",
    "IATA": "REC",
    "icao_code": "SBRF"
  },
  {
    "city": "Reconquista",
    "country": "Argentina",
    "IATA": "RCQ",
    "icao_code": "SATR"
  },
  {
    "city": "Red Bluff",
    "country": "United States",
    "IATA": "RBL",
    "icao_code": "KRBL"
  },
  {
    "city": "Red Deer Industrial",
    "country": "Canada",
    "IATA": "YQF",
    "icao_code": "CYQF"
  },
  {
    "city": "Red Dog",
    "country": "United States",
    "IATA": "RDB",
    "icao_code": "PADG"
  },
  {
    "city": "Red Lake",
    "country": "Canada",
    "IATA": "YRL",
    "icao_code": "CYRL"
  },
  {
    "city": "Red River",
    "country": "United States",
    "IATA": "RDR"
  },
  {
    "city": "Red Sucker Lake",
    "country": "Canada",
    "IATA": "YRS",
    "icao_code": "CYRS"
  },
  {
    "city": "Redang",
    "country": "Malaysia",
    "IATA": "RDN",
    "icao_code": "WMPR"
  },
  {
    "city": "Redcliffe",
    "country": "Vanuatu",
    "IATA": "RCL",
    "icao_code": "NVSR"
  },
  {
    "city": "Redding",
    "country": "United States",
    "IATA": "RDD",
    "icao_code": "KRDD"
  },
  {
    "city": "Redencao",
    "country": "Brazil",
    "IATA": "RDC",
    "icao_code": "SNDC"
  },
  {
    "city": "Redhill",
    "country": "United Kingdom",
    "IATA": "KRH",
    "icao_code": "EGKR"
  },
  {
    "city": "Redmond-Bend",
    "country": "United States",
    "IATA": "RDM",
    "icao_code": "KRDM"
  },
  {
    "city": "Redstone",
    "country": "United States",
    "IATA": "HUA"
  },
  {
    "city": "Redwood Falls",
    "country": "United States",
    "IATA": "RWF"
  },
  {
    "city": "Reggio Calabria",
    "country": "Italy",
    "IATA": "REG",
    "icao_code": "LICR"
  },
  {
    "city": "Regina",
    "country": "Canada",
    "IATA": "YQR",
    "icao_code": "CYQR"
  },
  {
    "city": "Reims",
    "country": "France",
    "IATA": "RHE",
    "icao_code": "LFSR"
  },
  {
    "city": "Rengat",
    "country": "Indonesia",
    "IATA": "RGT",
    "icao_code": "WIPR"
  },
  {
    "city": "Renmark",
    "country": "Australia",
    "IATA": "RMK",
    "icao_code": "YREN"
  },
  {
    "city": "Rennell Island",
    "country": "Solomon Islands",
    "IATA": "RNL",
    "icao_code": "AGGR"
  },
  {
    "city": "Rennes",
    "country": "France",
    "IATA": "RNS",
    "icao_code": "LFRN"
  },
  {
    "city": "Reno",
    "country": "United States",
    "IATA": "RNO",
    "icao_code": "KRNO"
  },
  {
    "city": "Rensselaer",
    "country": "United States",
    "IATA": "RNZ",
    "icao_code": "KRZL"
  },
  {
    "city": "Renton",
    "country": "United States",
    "IATA": "RNT",
    "icao_code": "KRNT"
  },
  {
    "city": "Repulse Bay",
    "country": "Canada",
    "IATA": "YUT",
    "icao_code": "CYUT"
  },
  {
    "city": "Rerrenabaque",
    "country": "Bolivia",
    "IATA": "RBQ",
    "icao_code": "SLRQ"
  },
  {
    "city": "Resende",
    "country": "Brazil",
    "IATA": "REZ"
  },
  {
    "city": "Resistencia",
    "country": "Argentina",
    "IATA": "RES",
    "icao_code": "SARE"
  },
  {
    "city": "Resolute",
    "country": "Canada",
    "IATA": "YRB",
    "icao_code": "CYRB"
  },
  {
    "city": "Retalhuleu",
    "country": "Guatemala",
    "IATA": "RER",
    "icao_code": "MGRT"
  },
  {
    "city": "Reus",
    "country": "Spain",
    "IATA": "REU",
    "icao_code": "LERS"
  },
  {
    "city": "Revelstoke",
    "country": "Canada",
    "IATA": "YRV"
  },
  {
    "city": "Reyes",
    "country": "Bolivia",
    "IATA": "REY",
    "icao_code": "SLRY"
  },
  {
    "city": "Reykjavik",
    "country": "Iceland",
    "IATA": "RKV",
    "icao_code": "BIRK"
  },
  {
    "city": "Reynosa",
    "country": "Mexico",
    "IATA": "REX",
    "icao_code": "MMRX"
  },
  {
    "city": "Rhinelander",
    "country": "United States",
    "IATA": "RHI",
    "icao_code": "KRHI"
  },
  {
    "city": "Rhodos",
    "country": "Greece",
    "IATA": "RHO",
    "icao_code": "LGRP"
  },
  {
    "city": "Ribeirao Preto",
    "country": "Brazil",
    "IATA": "RAO",
    "icao_code": "SBRP"
  },
  {
    "city": "Riberalta",
    "country": "Bolivia",
    "IATA": "RIB",
    "icao_code": "SLRI"
  },
  {
    "city": "Richard's Bay",
    "country": "South Africa",
    "IATA": "RCB",
    "icao_code": "FARB"
  },
  {
    "city": "Richmond",
    "country": "Australia",
    "IATA": "RCM",
    "icao_code": "YSRI"
  },
  {
    "city": "Richmond",
    "country": "United States",
    "IATA": "RIC",
    "icao_code": "KRIC"
  },
  {
    "city": "Richmond",
    "country": "Australia",
    "IATA": "XRH"
  },
  {
    "city": "Richmond Heights",
    "country": "United States",
    "IATA": "CGF"
  },
  {
    "city": "Riesa",
    "country": "Germany",
    "IATA": "IES"
  },
  {
    "city": "Rifle",
    "country": "United States",
    "IATA": "RIL",
    "icao_code": "KRIL"
  },
  {
    "city": "Riga",
    "country": "Latvia",
    "IATA": "RIX",
    "icao_code": "EVRA"
  },
  {
    "city": "Rigolet",
    "country": "Canada",
    "IATA": "YRG",
    "icao_code": "CCZ2"
  },
  {
    "city": "Rijeka",
    "country": "Croatia",
    "IATA": "RJK",
    "icao_code": "LDRI"
  },
  {
    "city": "Rimatara",
    "country": "French Polynesia",
    "IATA": "RMT",
    "icao_code": "NTAM"
  },
  {
    "city": "Rimini",
    "country": "Italy",
    "IATA": "RMI",
    "icao_code": "LIPR"
  },
  {
    "city": "Rimouski",
    "country": "Canada",
    "IATA": "YXK",
    "icao_code": "CYXK"
  },
  {
    "city": "Rincon de los Sauces",
    "country": "Argentina",
    "IATA": "RDS",
    "icao_code": "SAHS"
  },
  {
    "city": "Ringi Cove",
    "country": "Solomon Islands",
    "IATA": "RIN",
    "icao_code": "AGRC"
  },
  {
    "city": "Rio Branco",
    "country": "Brazil",
    "IATA": "RBR",
    "icao_code": "SBRB"
  },
  {
    "city": "Rio Cuarto",
    "country": "Argentina",
    "IATA": "RCU",
    "icao_code": "SAOC"
  },
  {
    "city": "Rio De Janeiro",
    "country": "Brazil",
    "IATA": "SNZ"
  },
  {
    "city": "Rio De Janeiro",
    "country": "Brazil",
    "IATA": "SDU",
    "icao_code": "SBRJ"
  },
  {
    "city": "Rio De Janeiro",
    "country": "Brazil",
    "IATA": "GIG",
    "icao_code": "SBGL"
  },
  {
    "city": "Rio Gallegos",
    "country": "Argentina",
    "IATA": "RGL",
    "icao_code": "SAWG"
  },
  {
    "city": "Rio Grande",
    "country": "Argentina",
    "IATA": "RGA",
    "icao_code": "SAWE"
  },
  {
    "city": "Rio Hacha",
    "country": "Colombia",
    "IATA": "RCH",
    "icao_code": "SKRH"
  },
  {
    "city": "Rio Hato",
    "country": "Panama",
    "IATA": "RIH",
    "icao_code": "MPRH"
  },
  {
    "city": "Rio Hondo",
    "country": "Argentina",
    "IATA": "RHD",
    "icao_code": "SANH"
  },
  {
    "city": "Rio Negro",
    "country": "Colombia",
    "IATA": "MDE",
    "icao_code": "SKRG"
  },
  {
    "city": "Rio Verde",
    "country": "Brazil",
    "IATA": "RVD",
    "icao_code": "SWLC"
  },
  {
    "city": "Rioja",
    "country": "Peru",
    "IATA": "RIJ",
    "icao_code": "SPJA"
  },
  {
    "city": "Rishiri Island",
    "country": "Japan",
    "IATA": "RIS",
    "icao_code": "RJER"
  },
  {
    "city": "Rivera",
    "country": "Uruguay",
    "IATA": "RVY",
    "icao_code": "SURV"
  },
  {
    "city": "Riverside",
    "country": "United States",
    "IATA": "RIV"
  },
  {
    "city": "Riverside",
    "country": "United States",
    "IATA": "RAL",
    "icao_code": "KRAL"
  },
  {
    "city": "Riverton WY",
    "country": "United States",
    "IATA": "RIW",
    "icao_code": "KRIW"
  },
  {
    "city": "Riviere Du Loup",
    "country": "Canada",
    "IATA": "YRI",
    "icao_code": "CYRI"
  },
  {
    "city": "Rivne",
    "country": "Ukraine",
    "IATA": "RWN",
    "icao_code": "UKLR"
  },
  {
    "city": "Riyadh",
    "country": "Saudi Arabia",
    "IATA": "XXN"
  },
  {
    "city": "Riyadh",
    "country": "Saudi Arabia",
    "IATA": "RUH",
    "icao_code": "OERK"
  },
  {
    "city": "Rizhao",
    "country": "China",
    "IATA": "RIZ"
  },
  {
    "city": "Roanne",
    "country": "France",
    "IATA": "RNE",
    "icao_code": "LFLO"
  },
  {
    "city": "Roanoke VA",
    "country": "United States",
    "IATA": "ROA",
    "icao_code": "KROA"
  },
  {
    "city": "Roatan",
    "country": "Honduras",
    "IATA": "RTB",
    "icao_code": "MHRO"
  },
  {
    "city": "Robertson",
    "country": "South Africa",
    "IATA": "ROD"
  },
  {
    "city": "Roberval",
    "country": "Canada",
    "IATA": "YRJ",
    "icao_code": "CYRJ"
  },
  {
    "city": "Rochefort",
    "country": "France",
    "IATA": "RCO",
    "icao_code": "LFDN"
  },
  {
    "city": "Rochester",
    "country": "United Kingdom",
    "IATA": "RCS",
    "icao_code": "EGTO"
  },
  {
    "city": "Rochester",
    "country": "United States",
    "IATA": "RST",
    "icao_code": "KRST"
  },
  {
    "city": "Rochester",
    "country": "United States",
    "IATA": "ROC",
    "icao_code": "KROC"
  },
  {
    "city": "Rock Hill",
    "country": "United States",
    "IATA": "RKH",
    "icao_code": "KUZA"
  },
  {
    "city": "Rock Sound",
    "country": "Bahamas",
    "IATA": "RSD",
    "icao_code": "MYER"
  },
  {
    "city": "Rock Springs",
    "country": "United States",
    "IATA": "RKS",
    "icao_code": "KRKS"
  },
  {
    "city": "Rockford",
    "country": "United States",
    "IATA": "RFD",
    "icao_code": "KRFD"
  },
  {
    "city": "Rockhampton",
    "country": "Australia",
    "IATA": "ROK",
    "icao_code": "YBRK"
  },
  {
    "city": "Rockland",
    "country": "United States",
    "IATA": "RKD",
    "icao_code": "KRKD"
  },
  {
    "city": "Rockport",
    "country": "United States",
    "IATA": "RKP",
    "icao_code": "KRKP"
  },
  {
    "city": "Rocky Mount",
    "country": "United States",
    "IATA": "RWI",
    "icao_code": "KRWI"
  },
  {
    "city": "Rocky Mountain House",
    "country": "Canada",
    "IATA": "YRM",
    "icao_code": "CYRM"
  },
  {
    "city": "Rodez",
    "country": "France",
    "IATA": "RDZ",
    "icao_code": "LFCR"
  },
  {
    "city": "Rodriguez Island",
    "country": "Mauritius",
    "IATA": "RRG",
    "icao_code": "FIMR"
  },
  {
    "city": "Roi Et",
    "country": "Thailand",
    "IATA": "ROI",
    "icao_code": "VTUV"
  },
  {
    "city": "Roma",
    "country": "Australia",
    "IATA": "RMA",
    "icao_code": "YROM"
  },
  {
    "city": "Romblon",
    "country": "Philippines",
    "IATA": "TBH",
    "icao_code": "RPVU"
  },
  {
    "city": "Romblon",
    "country": "Philippines",
    "IATA": "GES",
    "icao_code": "RPWB"
  },
  {
    "city": "Rome",
    "country": "United States",
    "IATA": "RMG",
    "icao_code": "KRMG"
  },
  {
    "city": "Rome",
    "country": "United States",
    "IATA": "RME",
    "icao_code": "KRME"
  },
  {
    "city": "Rome",
    "country": "Italy",
    "IATA": "FCO",
    "icao_code": "LIRF"
  },
  {
    "city": "Rome",
    "country": "Italy",
    "IATA": "CIA",
    "icao_code": "LIRA"
  },
  {
    "city": "Ronchi De Legionari",
    "country": "Italy",
    "IATA": "TRS",
    "icao_code": "LIPQ"
  },
  {
    "city": "Rondonopolis",
    "country": "Brazil",
    "IATA": "ROO",
    "icao_code": "SWRD"
  },
  {
    "city": "Ronne",
    "country": "Denmark",
    "IATA": "RNN",
    "icao_code": "EKRN"
  },
  {
    "city": "Ronneby",
    "country": "Sweden",
    "IATA": "RNB",
    "icao_code": "ESDF"
  },
  {
    "city": "Roper Bar",
    "country": "Australia",
    "IATA": "RPB"
  },
  {
    "city": "Roros",
    "country": "Norway",
    "IATA": "RRS",
    "icao_code": "ENRO"
  },
  {
    "city": "Rosario",
    "country": "Argentina",
    "IATA": "ROS",
    "icao_code": "SAAR"
  },
  {
    "city": "Rosecrans",
    "country": "United States",
    "IATA": "STJ",
    "icao_code": "KSTJ"
  },
  {
    "city": "Rosh Pina",
    "country": "Israel",
    "IATA": "RPN",
    "icao_code": "LLIB"
  },
  {
    "city": "Rosita",
    "country": "Nicaragua",
    "IATA": "RFS",
    "icao_code": "MNRT"
  },
  {
    "city": "Ross River",
    "country": "Canada",
    "IATA": "XRR"
  },
  {
    "city": "Rostov",
    "country": "Russia",
    "IATA": "ROV",
    "icao_code": "URRR"
  },
  {
    "city": "Roswell",
    "country": "United States",
    "IATA": "ROW",
    "icao_code": "KROW"
  },
  {
    "city": "Rota",
    "country": "Northern Mariana Islands",
    "IATA": "ROP",
    "icao_code": "PGRO"
  },
  {
    "city": "Rota",
    "country": "Spain",
    "IATA": "ROZ"
  },
  {
    "city": "Rotorua",
    "country": "New Zealand",
    "IATA": "ROT",
    "icao_code": "NZRO"
  },
  {
    "city": "Rotterdam",
    "country": "Netherlands",
    "IATA": "RTM",
    "icao_code": "EHRD"
  },
  {
    "city": "Rottnest Island",
    "country": "Australia",
    "IATA": "RTS",
    "icao_code": "YRTI"
  },
  {
    "city": "Rotuma",
    "country": "Fiji",
    "IATA": "RTA",
    "icao_code": "NFNR"
  },
  {
    "city": "Rouen",
    "country": "France",
    "IATA": "URO",
    "icao_code": "LFOP"
  },
  {
    "city": "Round Lake",
    "country": "Canada",
    "IATA": "ZRJ",
    "icao_code": "CZRJ"
  },
  {
    "city": "Rourkela",
    "country": "India",
    "IATA": "RRK",
    "icao_code": "VERK"
  },
  {
    "city": "Rouyn",
    "country": "Canada",
    "IATA": "YUY",
    "icao_code": "CYUY"
  },
  {
    "city": "Rovaniemi",
    "country": "Finland",
    "IATA": "RVN",
    "icao_code": "EFRO"
  },
  {
    "city": "Roxana",
    "country": "Costa Rica",
    "IATA": "TTQ",
    "icao_code": "MRAO"
  },
  {
    "city": "Roxas City",
    "country": "Philippines",
    "IATA": "RXS",
    "icao_code": "RPVR"
  },
  {
    "city": "Royan",
    "country": "France",
    "IATA": "RYN",
    "icao_code": "LFCY"
  },
  {
    "city": "Rubelsanto",
    "country": "Guatemala",
    "IATA": "RUV",
    "icao_code": "MGRB"
  },
  {
    "city": "Ruby",
    "country": "United States",
    "IATA": "RBY",
    "icao_code": "PARY"
  },
  {
    "city": "Ruegen",
    "country": "Germany",
    "IATA": "GTI"
  },
  {
    "city": "Rugao",
    "country": "China",
    "IATA": "RUG",
    "icao_code": "ZSRG"
  },
  {
    "city": "Ruidoso",
    "country": "United States",
    "IATA": "RUI"
  },
  {
    "city": "Rukumkot",
    "country": "Nepal",
    "IATA": "RUK",
    "icao_code": "VNRK"
  },
  {
    "city": "Rumbek",
    "country": "Sudan",
    "IATA": "RBX",
    "icao_code": "HSMK"
  },
  {
    "city": "Rumjatar",
    "country": "Nepal",
    "IATA": "RUM",
    "icao_code": "VNRT"
  },
  {
    "city": "Rundu",
    "country": "Namibia",
    "IATA": "NDU",
    "icao_code": "FYRU"
  },
  {
    "city": "Rurutu",
    "country": "French Polynesia",
    "IATA": "RUR",
    "icao_code": "NTAR"
  },
  {
    "city": "Russell",
    "country": "United States",
    "IATA": "RSL",
    "icao_code": "KRSL"
  },
  {
    "city": "Russian Mission",
    "country": "United States",
    "IATA": "RSH",
    "icao_code": "PARS"
  },
  {
    "city": "Ruteng",
    "country": "Indonesia",
    "IATA": "RTG"
  },
  {
    "city": "Rutland",
    "country": "United States",
    "IATA": "RUT",
    "icao_code": "KRUT"
  },
  {
    "city": "Rybinsk",
    "country": "Russia",
    "IATA": "RYB",
    "icao_code": "UUBK"
  },
  {
    "city": "Rzeszow",
    "country": "Poland",
    "IATA": "RZE",
    "icao_code": "EPRZ"
  },
  {
    "city": "Røst",
    "country": "Norway",
    "IATA": "RET",
    "icao_code": "ENRS"
  },
  {
    "city": "SARATOGA",
    "country": "United States",
    "IATA": "SAA"
  },
  {
    "city": "ST MARY'S",
    "country": "United Kingdom",
    "IATA": "ISC",
    "icao_code": "EGHE"
  },
  {
    "city": "Saarbruecken",
    "country": "Germany",
    "IATA": "SCN",
    "icao_code": "EDDR"
  },
  {
    "city": "Saba",
    "country": "Netherlands Antilles",
    "IATA": "SAB",
    "icao_code": "TNCS"
  },
  {
    "city": "Sabadell",
    "country": "Spain",
    "IATA": "QSA"
  },
  {
    "city": "Sabang",
    "country": "Indonesia",
    "IATA": "SBG",
    "icao_code": "WITB"
  },
  {
    "city": "Sabetta",
    "country": "Russia",
    "IATA": "SBT",
    "icao_code": "USDA"
  },
  {
    "city": "Sabzevar",
    "country": "Iran",
    "IATA": "AFZ",
    "icao_code": "OIMS"
  },
  {
    "city": "Sacheon",
    "country": "South Korea",
    "IATA": "HIN",
    "icao_code": "RKPS"
  },
  {
    "city": "Sachigo Lake",
    "country": "Canada",
    "IATA": "ZPB",
    "icao_code": "CZPB"
  },
  {
    "city": "Sachs Harbour",
    "country": "Canada",
    "IATA": "YSY",
    "icao_code": "CYSY"
  },
  {
    "city": "Sacramento",
    "country": "United States",
    "IATA": "SMF",
    "icao_code": "KSMF"
  },
  {
    "city": "Sacramento",
    "country": "United States",
    "IATA": "MCC"
  },
  {
    "city": "Sacramento",
    "country": "United States",
    "IATA": "SAC",
    "icao_code": "KSAC"
  },
  {
    "city": "Sacramento",
    "country": "United States",
    "IATA": "MHR",
    "icao_code": "KMHR"
  },
  {
    "city": "Sado",
    "country": "Japan",
    "IATA": "SDS",
    "icao_code": "RJSD"
  },
  {
    "city": "Safford",
    "country": "United States",
    "IATA": "SAD",
    "icao_code": "KSAD"
  },
  {
    "city": "Saga",
    "country": "Japan",
    "IATA": "HSG",
    "icao_code": "RJFS"
  },
  {
    "city": "Saginaw",
    "country": "United States",
    "IATA": "MBS",
    "icao_code": "KMBS"
  },
  {
    "city": "Saibai Island",
    "country": "Australia",
    "IATA": "SBR"
  },
  {
    "city": "Saidpur",
    "country": "Bangladesh",
    "IATA": "SPD",
    "icao_code": "VGSD"
  },
  {
    "city": "Saidu Sharif",
    "country": "Pakistan",
    "IATA": "SDT",
    "icao_code": "OPSS"
  },
  {
    "city": "Saint Catherines",
    "country": "Canada",
    "IATA": "YCM",
    "icao_code": "CYSN"
  },
  {
    "city": "Saint Cloud",
    "country": "United States",
    "IATA": "STC",
    "icao_code": "KSTC"
  },
  {
    "city": "Saint George",
    "country": "United States",
    "IATA": "SGU",
    "icao_code": "KSGU"
  },
  {
    "city": "Saint-Laurent-du-Maroni",
    "country": "French Guiana",
    "IATA": "LDX"
  },
  {
    "city": "Sainte Marie",
    "country": "Madagascar",
    "IATA": "SMS",
    "icao_code": "FMMS"
  },
  {
    "city": "Sainyabuli",
    "country": "Laos",
    "IATA": "ZBY",
    "icao_code": "VLSB"
  },
  {
    "city": "Saipan",
    "country": "Northern Mariana Islands",
    "IATA": "SPN",
    "icao_code": "PGSN"
  },
  {
    "city": "Sakon Nakhon",
    "country": "Thailand",
    "IATA": "SNO",
    "icao_code": "VTUI"
  },
  {
    "city": "Salalah",
    "country": "Oman",
    "IATA": "SLL",
    "icao_code": "OOSA"
  },
  {
    "city": "Salamanca",
    "country": "Spain",
    "IATA": "SLM",
    "icao_code": "LESA"
  },
  {
    "city": "Sale",
    "country": "Australia",
    "IATA": "SXE",
    "icao_code": "YMES"
  },
  {
    "city": "Salekhard",
    "country": "Russia",
    "IATA": "SLY",
    "icao_code": "USDD"
  },
  {
    "city": "Salem",
    "country": "United States",
    "IATA": "SLE",
    "icao_code": "KSLE"
  },
  {
    "city": "Salem",
    "country": "India",
    "IATA": "SXV",
    "icao_code": "VOSM"
  },
  {
    "city": "Salerno",
    "country": "Italy",
    "IATA": "QSR",
    "icao_code": "LIRI"
  },
  {
    "city": "Salina",
    "country": "United States",
    "IATA": "SLN",
    "icao_code": "KSLN"
  },
  {
    "city": "Salinas",
    "country": "United States",
    "IATA": "SNS",
    "icao_code": "KSNS"
  },
  {
    "city": "Salinas",
    "country": "Ecuador",
    "IATA": "SNC",
    "icao_code": "SESA"
  },
  {
    "city": "Salisbury",
    "country": "United States",
    "IATA": "SBY",
    "icao_code": "KSBY"
  },
  {
    "city": "Salluit",
    "country": "Canada",
    "IATA": "YZG",
    "icao_code": "CYZG"
  },
  {
    "city": "Salmon",
    "country": "United States",
    "IATA": "SMN",
    "icao_code": "KSMN"
  },
  {
    "city": "Salmon Arm",
    "country": "Canada",
    "IATA": "YSN",
    "icao_code": "CZAM"
  },
  {
    "city": "Salt Cay",
    "country": "Turks and Caicos Islands",
    "IATA": "SLX",
    "icao_code": "MBSY"
  },
  {
    "city": "Salt Lake City",
    "country": "United States",
    "IATA": "SLC",
    "icao_code": "KSLC"
  },
  {
    "city": "Salta",
    "country": "Argentina",
    "IATA": "SLA",
    "icao_code": "SASA"
  },
  {
    "city": "Saltillo",
    "country": "Mexico",
    "IATA": "SLW",
    "icao_code": "MMIO"
  },
  {
    "city": "Salto",
    "country": "Uruguay",
    "IATA": "STY",
    "icao_code": "SUSO"
  },
  {
    "city": "Saltsy",
    "country": "Moldova",
    "IATA": "BZY"
  },
  {
    "city": "Salvador",
    "country": "Brazil",
    "IATA": "SSA",
    "icao_code": "SBSV"
  },
  {
    "city": "Salzburg",
    "country": "Austria",
    "IATA": "SZG",
    "icao_code": "LOWS"
  },
  {
    "city": "Sam Neua",
    "country": "Laos",
    "IATA": "NEU",
    "icao_code": "VLSN"
  },
  {
    "city": "Samana",
    "country": "Dominican Republic",
    "IATA": "AZS",
    "icao_code": "MDZY"
  },
  {
    "city": "Samara",
    "country": "Russia",
    "IATA": "KUF",
    "icao_code": "UWWW"
  },
  {
    "city": "Samarinda",
    "country": "Indonesia",
    "IATA": "SRI",
    "icao_code": "WALS"
  },
  {
    "city": "Samarkand",
    "country": "Uzbekistan",
    "IATA": "SKD",
    "icao_code": "UTSS"
  },
  {
    "city": "Sambava",
    "country": "Madagascar",
    "IATA": "SVB",
    "icao_code": "FMNS"
  },
  {
    "city": "Samburu South",
    "country": "Kenya",
    "IATA": "UAS",
    "icao_code": "HKSB"
  },
  {
    "city": "Samedan",
    "country": "Switzerland",
    "IATA": "SMV",
    "icao_code": "LSZS"
  },
  {
    "city": "Samos",
    "country": "Greece",
    "IATA": "SMI",
    "icao_code": "LGSM"
  },
  {
    "city": "Sampit-Borneo Island",
    "country": "Indonesia",
    "IATA": "SMQ",
    "icao_code": "WAOS"
  },
  {
    "city": "Samsun",
    "country": "Turkey",
    "IATA": "SZF",
    "icao_code": "LTFH"
  },
  {
    "city": "San Andres Island",
    "country": "Colombia",
    "IATA": "ADZ",
    "icao_code": "SKSP"
  },
  {
    "city": "San Andros",
    "country": "Bahamas",
    "IATA": "SAQ"
  },
  {
    "city": "San Angelo",
    "country": "United States",
    "IATA": "SJT",
    "icao_code": "KSJT"
  },
  {
    "city": "San Antonio",
    "country": "United States",
    "IATA": "RND"
  },
  {
    "city": "San Antonio",
    "country": "United States",
    "IATA": "SKF"
  },
  {
    "city": "San Antonio",
    "country": "United States",
    "IATA": "SAT",
    "icao_code": "KSAT"
  },
  {
    "city": "San Antonio",
    "country": "Venezuela",
    "IATA": "SVZ",
    "icao_code": "SVSA"
  },
  {
    "city": "San Antonio Oeste",
    "country": "Argentina",
    "IATA": "OES",
    "icao_code": "SAVN"
  },
  {
    "city": "San Antonio de Pale",
    "country": "Equatorial Guinea",
    "IATA": "NBN"
  },
  {
    "city": "San Bernardino",
    "country": "United States",
    "IATA": "SBD",
    "icao_code": "KSBD"
  },
  {
    "city": "San Borja",
    "country": "Bolivia",
    "IATA": "SRJ",
    "icao_code": "SLSB"
  },
  {
    "city": "San Carlos",
    "country": "United States",
    "IATA": "SQL",
    "icao_code": "KSQL"
  },
  {
    "city": "San Carlos",
    "country": "Nicaragua",
    "IATA": "NCR",
    "icao_code": "MNSC"
  },
  {
    "city": "San Carlos De Bariloche",
    "country": "Argentina",
    "IATA": "BRC",
    "icao_code": "SAZS"
  },
  {
    "city": "San Cristobal",
    "country": "Venezuela",
    "IATA": "SCI",
    "icao_code": "SVPM"
  },
  {
    "city": "San Cristobal de las Casas",
    "country": "Mexico",
    "IATA": "SZT"
  },
  {
    "city": "San Cristóbal",
    "country": "Ecuador",
    "IATA": "SCY",
    "icao_code": "SPSF"
  },
  {
    "city": "San Diego",
    "country": "United States",
    "IATA": "SDM",
    "icao_code": "KSDM"
  },
  {
    "city": "San Diego",
    "country": "United States",
    "IATA": "SAN",
    "icao_code": "KSAN"
  },
  {
    "city": "San Diego",
    "country": "United States",
    "IATA": "NZY",
    "icao_code": "KNZY"
  },
  {
    "city": "San Felipe",
    "country": "Venezuela",
    "IATA": "SNF",
    "icao_code": "SVSP"
  },
  {
    "city": "San Fernando",
    "country": "Philippines",
    "IATA": "SFE"
  },
  {
    "city": "San Fernando",
    "country": "Argentina",
    "IATA": "FDO"
  },
  {
    "city": "San Fernando De Apure",
    "country": "Venezuela",
    "IATA": "SFD",
    "icao_code": "SVSR"
  },
  {
    "city": "San Filipe",
    "country": "Mexico",
    "IATA": "SFH",
    "icao_code": "SVSP"
  },
  {
    "city": "San Francisco",
    "country": "United States",
    "IATA": "SFO",
    "icao_code": "KSFO"
  },
  {
    "city": "San Jose",
    "country": "United States",
    "IATA": "RHV",
    "icao_code": "KRHV"
  },
  {
    "city": "San Jose",
    "country": "Costa Rica",
    "IATA": "SYQ",
    "icao_code": "MRPV"
  },
  {
    "city": "San Jose",
    "country": "United States",
    "IATA": "SJC",
    "icao_code": "KSJC"
  },
  {
    "city": "San Jose",
    "country": "Philippines",
    "IATA": "EUQ"
  },
  {
    "city": "San Jose",
    "country": "Philippines",
    "IATA": "SJI",
    "icao_code": "RPVS"
  },
  {
    "city": "San Jose",
    "country": "Costa Rica",
    "IATA": "SJO",
    "icao_code": "MROC"
  },
  {
    "city": "San Jose",
    "country": "Guatemala",
    "IATA": "GSJ",
    "icao_code": "MGSJ"
  },
  {
    "city": "Cabo San Lucas, All Airports",
    "country": "Mexico",
    "IATA": "CSLALL"
  },
  {
    "city": "Cabo San Lucas, Mexico",
    "country": "Mexico",
    "IATA": "SJD",
    "icao_code": "MMSD"
  },
  {
    "city": "Cabo San Lucas, Mexico",
    "country": "Mexico",
    "IATA": "CSL"
  },
  {
    "city": "San Jose Del Guaviare",
    "country": "Colombia",
    "IATA": "SJE",
    "icao_code": "SKSJ"
  },
  {
    "city": "San Juan",
    "country": "Puerto Rico",
    "IATA": "SJU",
    "icao_code": "TJSJ"
  },
  {
    "city": "San Juan",
    "country": "Puerto Rico",
    "IATA": "SIG",
    "icao_code": "TJIG"
  },
  {
    "city": "San Juan",
    "country": "Argentina",
    "IATA": "UAQ",
    "icao_code": "SANU"
  },
  {
    "city": "San Julian",
    "country": "Argentina",
    "IATA": "ULA",
    "icao_code": "SAWJ"
  },
  {
    "city": "San Luis",
    "country": "Argentina",
    "IATA": "LUQ",
    "icao_code": "SAOU"
  },
  {
    "city": "San Luis Obispo",
    "country": "United States",
    "IATA": "SBP",
    "icao_code": "KSBP"
  },
  {
    "city": "San Luis Potosi",
    "country": "Mexico",
    "IATA": "SLP",
    "icao_code": "MMSP"
  },
  {
    "city": "San Martin Des Andes",
    "country": "Argentina",
    "IATA": "CPC",
    "icao_code": "SAZY"
  },
  {
    "city": "San Nicolás",
    "country": "Cuba",
    "IATA": "QSN"
  },
  {
    "city": "San Pedro",
    "country": "Belize",
    "IATA": "SPR"
  },
  {
    "city": "San Pedro",
    "country": "Cote d'Ivoire",
    "IATA": "SPY",
    "icao_code": "DISP"
  },
  {
    "city": "San Pedro Sula",
    "country": "Honduras",
    "IATA": "SAP",
    "icao_code": "MHLM"
  },
  {
    "city": "San Rafael",
    "country": "Argentina",
    "IATA": "AFA",
    "icao_code": "SAMR"
  },
  {
    "city": "San Salvador",
    "country": "El Salvador",
    "IATA": "SAL",
    "icao_code": "MSLP"
  },
  {
    "city": "San Sebastian",
    "country": "Spain",
    "IATA": "EAS",
    "icao_code": "LESO"
  },
  {
    "city": "San Tome",
    "country": "Venezuela",
    "IATA": "SOM",
    "icao_code": "SVST"
  },
  {
    "city": "San Vincente De Caguan",
    "country": "Colombia",
    "IATA": "SVI",
    "icao_code": "SKSV"
  },
  {
    "city": "San Vito De Jaba",
    "country": "Costa Rica",
    "IATA": "TOO",
    "icao_code": "MRSV"
  },
  {
    "city": "Sanaa",
    "country": "Yemen",
    "IATA": "SAH",
    "icao_code": "OYSN"
  },
  {
    "city": "Sanana",
    "country": "Indonesia",
    "IATA": "SQN",
    "icao_code": "WAPN"
  },
  {
    "city": "Sanandaj",
    "country": "Iran",
    "IATA": "SDG",
    "icao_code": "OICS"
  },
  {
    "city": "Sancti Spiritus",
    "country": "Cuba",
    "IATA": "USS"
  },
  {
    "city": "Sand Point",
    "country": "United States",
    "IATA": "SDP",
    "icao_code": "PASD"
  },
  {
    "city": "Sandakan",
    "country": "Malaysia",
    "IATA": "SDK",
    "icao_code": "WBKS"
  },
  {
    "city": "Sandane",
    "country": "Norway",
    "IATA": "SDN",
    "icao_code": "ENSD"
  },
  {
    "city": "Sanday",
    "country": "United Kingdom",
    "IATA": "NDY",
    "icao_code": "EGES"
  },
  {
    "city": "Sandnessjoen",
    "country": "Norway",
    "IATA": "SSJ",
    "icao_code": "ENST"
  },
  {
    "city": "Sandspit",
    "country": "Canada",
    "IATA": "YZP",
    "icao_code": "CYZP"
  },
  {
    "city": "Sandusky",
    "country": "United States",
    "IATA": "SKY"
  },
  {
    "city": "Sandy Lake",
    "country": "Canada",
    "IATA": "ZSJ",
    "icao_code": "CZSJ"
  },
  {
    "city": "Sanford",
    "country": "United States",
    "IATA": "SFB",
    "icao_code": "KSFB"
  },
  {
    "city": "Sanga Sanga",
    "country": "Philippines",
    "IATA": "TWT"
  },
  {
    "city": "Sangafa",
    "country": "Vanuatu",
    "IATA": "EAE",
    "icao_code": "NVSE"
  },
  {
    "city": "Sanggata",
    "country": "Indonesia",
    "IATA": "SGQ",
    "icao_code": "WRLA"
  },
  {
    "city": "Sangley Point",
    "country": "Philippines",
    "IATA": "SUG",
    "icao_code": "RPWS"
  },
  {
    "city": "Sanikiluaq",
    "country": "Canada",
    "IATA": "YSK",
    "icao_code": "CYSK"
  },
  {
    "city": "Sankt Peter-Ording",
    "country": "Germany",
    "IATA": "PSH"
  },
  {
    "city": "Sankt-Augustin",
    "country": "Germany",
    "IATA": "BNJ"
  },
  {
    "city": "Sanliurfa",
    "country": "Turkey",
    "IATA": "GNY",
    "icao_code": "LTCH"
  },
  {
    "city": "Sanliurfa",
    "country": "Turkey",
    "IATA": "SFQ",
    "icao_code": "LTCH"
  },
  {
    "city": "Sanming",
    "country": "China",
    "IATA": "SQJ",
    "icao_code": "ZSSM"
  },
  {
    "city": "Santa Ana",
    "country": "Solomon Islands",
    "IATA": "NNB",
    "icao_code": "AGGT"
  },
  {
    "city": "Santa Ana",
    "country": "United States",
    "IATA": "NZJ"
  },
  {
    "city": "Los Angeles, Santa Ana",
    "country": "United States",
    "IATA": "SNA",
    "icao_code": "KSNA"
  },
  {
    "city": "Santa Ana",
    "country": "Bolivia",
    "IATA": "SBL",
    "icao_code": "SLSA"
  },
  {
    "city": "Santa Ana De Uairen",
    "country": "Venezuela",
    "IATA": "SNV"
  },
  {
    "city": "Santa Barbara",
    "country": "United States",
    "IATA": "SBA",
    "icao_code": "KSBA"
  },
  {
    "city": "Santa Barbara",
    "country": "Venezuela",
    "IATA": "STB",
    "icao_code": "SVSZ"
  },
  {
    "city": "Santa Barbara",
    "country": "Venezuela",
    "IATA": "SBB",
    "icao_code": "SVSB"
  },
  {
    "city": "Santa Clara",
    "country": "Cuba",
    "IATA": "SNU",
    "icao_code": "MUSC"
  },
  {
    "city": "Santa Cruz",
    "country": "Bolivia",
    "IATA": "SRZ",
    "icao_code": "SLET"
  },
  {
    "city": "Santa Cruz",
    "country": "Bolivia",
    "IATA": "VVI",
    "icao_code": "SLVR"
  },
  {
    "city": "Santa Cruz",
    "country": "Argentina",
    "IATA": "RZA",
    "icao_code": "SAWU"
  },
  {
    "city": "Santa Cruz De La Palma",
    "country": "Spain",
    "IATA": "SPC",
    "icao_code": "GCLA"
  },
  {
    "city": "Santa Cruz des Quiche",
    "country": "Guatemala",
    "IATA": "AQB",
    "icao_code": "MGQC"
  },
  {
    "city": "Santa Cruz/Graciosa Bay/Luova",
    "country": "Solomon Islands",
    "IATA": "SCZ",
    "icao_code": "AGGL"
  },
  {
    "city": "Santa Fe",
    "country": "United States",
    "IATA": "SAF",
    "icao_code": "KSAF"
  },
  {
    "city": "Santa Fe",
    "country": "Argentina",
    "IATA": "SFN",
    "icao_code": "SAAV"
  },
  {
    "city": "Santa Isabel do Rio Negro",
    "country": "Brazil",
    "IATA": "IRZ",
    "icao_code": "SWTP"
  },
  {
    "city": "Santa Maria",
    "country": "Brazil",
    "IATA": "RIA",
    "icao_code": "SBSM"
  },
  {
    "city": "Santa Maria",
    "country": "United States",
    "IATA": "SMX",
    "icao_code": "KSMX"
  },
  {
    "city": "Santa Maria (island)",
    "country": "Portugal",
    "IATA": "SMA",
    "icao_code": "LPAZ"
  },
  {
    "city": "Santa Marta",
    "country": "Colombia",
    "IATA": "SMR",
    "icao_code": "SKSM"
  },
  {
    "city": "Santa Monica",
    "country": "United States",
    "IATA": "SMO",
    "icao_code": "KSMO"
  },
  {
    "city": "Santa Rosa",
    "country": "United States",
    "IATA": "STS",
    "icao_code": "KSTS"
  },
  {
    "city": "Santa Rosa",
    "country": "Brazil",
    "IATA": "SRA",
    "icao_code": "SSZR"
  },
  {
    "city": "Santa Rosa",
    "country": "Ecuador",
    "IATA": "ETR",
    "icao_code": "LFFY"
  },
  {
    "city": "Santa Rosa",
    "country": "Argentina",
    "IATA": "RSA",
    "icao_code": "SAZR"
  },
  {
    "city": "Santa Teresita",
    "country": "Argentina",
    "IATA": "SST"
  },
  {
    "city": "Santa Terezinha",
    "country": "Brazil",
    "IATA": "STZ",
    "icao_code": "SWST"
  },
  {
    "city": "Santa Ynez",
    "country": "United States",
    "IATA": "SQA",
    "icao_code": "KIZA"
  },
  {
    "city": "Santana do Araguaia",
    "country": "Brazil",
    "IATA": "CMP",
    "icao_code": "SNKE"
  },
  {
    "city": "Santander",
    "country": "Spain",
    "IATA": "SDR",
    "icao_code": "LEXJ"
  },
  {
    "city": "Santarem",
    "country": "Brazil",
    "IATA": "STM",
    "icao_code": "SBSN"
  },
  {
    "city": "Santiago",
    "country": "Chile",
    "IATA": "SCL",
    "icao_code": "SCEL"
  },
  {
    "city": "Santiago",
    "country": "Panama",
    "IATA": "SYP",
    "icao_code": "MPSA"
  },
  {
    "city": "Santiago",
    "country": "Dominican Republic",
    "IATA": "STI",
    "icao_code": "MDST"
  },
  {
    "city": "Santiago",
    "country": "Spain",
    "IATA": "SCQ",
    "icao_code": "LEST"
  },
  {
    "city": "Santiago De Cuba",
    "country": "Cuba",
    "IATA": "SCU",
    "icao_code": "MUCU"
  },
  {
    "city": "Santiago Del Estero",
    "country": "Argentina",
    "IATA": "SDE",
    "icao_code": "SANE"
  },
  {
    "city": "Santo",
    "country": "Vanuatu",
    "IATA": "SON",
    "icao_code": "NVSS"
  },
  {
    "city": "Santo Angelo",
    "country": "Brazil",
    "IATA": "GEL",
    "icao_code": "SBNM"
  },
  {
    "city": "Santo Domingo",
    "country": "Dominican Republic",
    "IATA": "HEX"
  },
  {
    "city": "Santo Domingo",
    "country": "Venezuela",
    "IATA": "STD",
    "icao_code": "SVSO"
  },
  {
    "city": "Santo Domingo",
    "country": "Dominican Republic",
    "IATA": "SDQ",
    "icao_code": "MDSD"
  },
  {
    "city": "Santos",
    "country": "Brazil",
    "IATA": "SSZ",
    "icao_code": "SBST"
  },
  {
    "city": "Sanya",
    "country": "China",
    "IATA": "SYX",
    "icao_code": "ZJSY"
  },
  {
    "city": "Sao Carlos",
    "country": "Brazil",
    "IATA": "QSC"
  },
  {
    "city": "Sao Felix do Araguaia",
    "country": "Brazil",
    "IATA": "SXO",
    "icao_code": "SWFX"
  },
  {
    "city": "Sao Felix do Xingu",
    "country": "Brazil",
    "IATA": "SXX",
    "icao_code": "SNFX"
  },
  {
    "city": "Sao Gabriel",
    "country": "Brazil",
    "IATA": "SJL",
    "icao_code": "SBUA"
  },
  {
    "city": "Sao Jorge Island",
    "country": "Portugal",
    "IATA": "SJZ",
    "icao_code": "LPSJ"
  },
  {
    "city": "Sao Jose Do Rio Preto",
    "country": "Brazil",
    "IATA": "SJP",
    "icao_code": "SBSR"
  },
  {
    "city": "Sao Jose Dos Campos",
    "country": "Brazil",
    "IATA": "SJK",
    "icao_code": "SBSJ"
  },
  {
    "city": "Sao Luis",
    "country": "Brazil",
    "IATA": "SLZ",
    "icao_code": "SBSL"
  },
  {
    "city": "Sao Miguel do Oeste",
    "country": "Brazil",
    "IATA": "SQX",
    "icao_code": "SSOE"
  },
  {
    "city": "Sao Nocolau Island",
    "country": "Cape Verde",
    "IATA": "SNE",
    "icao_code": "GVSN"
  },
  {
    "city": "Sao Paulo",
    "country": "Brazil",
    "IATA": "CGH",
    "icao_code": "SBSP"
  },
  {
    "city": "Sao Paulo",
    "country": "Brazil",
    "IATA": "GRU",
    "icao_code": "SBGR"
  },
  {
    "city": "Sao Paulo de Olivenca",
    "country": "Brazil",
    "IATA": "OLC"
  },
  {
    "city": "Sao Tome",
    "country": "Sao Tome and Principe",
    "IATA": "TMS",
    "icao_code": "FPST"
  },
  {
    "city": "Sao Vicente Island",
    "country": "Cape Verde",
    "IATA": "VXE",
    "icao_code": "GVSV"
  },
  {
    "city": "Sapporo",
    "country": "Japan",
    "IATA": "OKD",
    "icao_code": "RJCO"
  },
  {
    "city": "Sapporo",
    "country": "Japan",
    "IATA": "CTS",
    "icao_code": "RJCC"
  },
  {
    "city": "Sarajevo",
    "country": "Bosnia and Herzegovina",
    "IATA": "SJJ",
    "icao_code": "LQSA"
  },
  {
    "city": "Sarakhs",
    "country": "Iran",
    "IATA": "CKT",
    "icao_code": "OIMC"
  },
  {
    "city": "Saranac Lake",
    "country": "United States",
    "IATA": "SLK",
    "icao_code": "KSLK"
  },
  {
    "city": "Saransk",
    "country": "Russia",
    "IATA": "SKX",
    "icao_code": "UWPS"
  },
  {
    "city": "Sarasota",
    "country": "United States",
    "IATA": "SRQ",
    "icao_code": "KSRQ"
  },
  {
    "city": "Saratov",
    "country": "Russia",
    "IATA": "RTW",
    "icao_code": "UWSS"
  },
  {
    "city": "Saravane",
    "country": "Laos",
    "IATA": "VNA",
    "icao_code": "VLSV"
  },
  {
    "city": "Saravena",
    "country": "Colombia",
    "IATA": "RVE",
    "icao_code": "SKSA"
  },
  {
    "city": "Sargodha",
    "country": "Pakistan",
    "IATA": "SGI",
    "icao_code": "OPSR"
  },
  {
    "city": "Sarh",
    "country": "Chad",
    "IATA": "SRH",
    "icao_code": "FTTA"
  },
  {
    "city": "Sarnia",
    "country": "Canada",
    "IATA": "YZR",
    "icao_code": "CYZR"
  },
  {
    "city": "Sarzana (SP)",
    "country": "Italy",
    "IATA": "QLP"
  },
  {
    "city": "Sasereme",
    "country": "Papua New Guinea",
    "IATA": "TDS"
  },
  {
    "city": "Saskatoon",
    "country": "Canada",
    "IATA": "YXE",
    "icao_code": "CYXE"
  },
  {
    "city": "Saskylakh",
    "country": "Russia",
    "IATA": "SYS"
  },
  {
    "city": "Satna",
    "country": "India",
    "IATA": "TNI",
    "icao_code": "VIST"
  },
  {
    "city": "Satu Mare",
    "country": "Romania",
    "IATA": "SUJ",
    "icao_code": "LRSM"
  },
  {
    "city": "Saudarkrokur",
    "country": "Iceland",
    "IATA": "SAK",
    "icao_code": "BIKR"
  },
  {
    "city": "Saul",
    "country": "French Guiana",
    "IATA": "XAU"
  },
  {
    "city": "Sault Sainte Marie",
    "country": "Canada",
    "IATA": "YAM",
    "icao_code": "CYAM"
  },
  {
    "city": "Sault Ste Marie",
    "country": "United States",
    "IATA": "CIU",
    "icao_code": "KCIU"
  },
  {
    "city": "Saumlaki",
    "country": "Indonesia",
    "IATA": "SXK",
    "icao_code": "WAPI"
  },
  {
    "city": "Saurimo",
    "country": "Angola",
    "IATA": "VHC",
    "icao_code": "FNSA"
  },
  {
    "city": "Savaii Island",
    "country": "Samoa",
    "IATA": "MXS",
    "icao_code": "NSMA"
  },
  {
    "city": "Savannah",
    "country": "United States",
    "IATA": "SAV",
    "icao_code": "KSAV"
  },
  {
    "city": "Savannakhet",
    "country": "Laos",
    "IATA": "ZVK",
    "icao_code": "VLSK"
  },
  {
    "city": "Savonlinna",
    "country": "Finland",
    "IATA": "SVL",
    "icao_code": "EFSA"
  },
  {
    "city": "Savoonga",
    "country": "United States",
    "IATA": "SVA",
    "icao_code": "PASA"
  },
  {
    "city": "Savusavu",
    "country": "Fiji",
    "IATA": "SVU",
    "icao_code": "NFNS"
  },
  {
    "city": "Sayun Intl",
    "country": "Yemen",
    "IATA": "GXF",
    "icao_code": "OYSY"
  },
  {
    "city": "Scammon Bay",
    "country": "United States",
    "IATA": "SCM",
    "icao_code": "PACM"
  },
  {
    "city": "Scampton",
    "country": "United Kingdom",
    "IATA": "SQZ",
    "icao_code": "EGXP"
  },
  {
    "city": "Scarborough",
    "country": "Trinidad and Tobago",
    "IATA": "TAB",
    "icao_code": "TTCP"
  },
  {
    "city": "Scatsta",
    "country": "United Kingdom",
    "IATA": "SCS",
    "icao_code": "EGPM"
  },
  {
    "city": "Schefferville",
    "country": "Canada",
    "IATA": "YKL",
    "icao_code": "CYKL"
  },
  {
    "city": "Schleswig",
    "country": "Germany",
    "IATA": "WBG",
    "icao_code": "ETNS"
  },
  {
    "city": "Schoenhagen",
    "country": "Germany",
    "IATA": "QXH"
  },
  {
    "city": "Scone",
    "country": "Australia",
    "IATA": "NSO",
    "icao_code": "YSCO"
  },
  {
    "city": "Scotia NY",
    "country": "United States",
    "IATA": "SCH",
    "icao_code": "KSCH"
  },
  {
    "city": "Scottsbluff",
    "country": "United States",
    "IATA": "BFF",
    "icao_code": "KBFF"
  },
  {
    "city": "Scottsdale",
    "country": "United States",
    "IATA": "SCF",
    "icao_code": "KSDL"
  },
  {
    "city": "Scranton",
    "country": "United States",
    "IATA": "AVP",
    "icao_code": "KAVP"
  },
  {
    "city": "Seattle",
    "country": "United States",
    "IATA": "BFI",
    "icao_code": "KBFI"
  },
  {
    "city": "Seattle",
    "country": "United States",
    "IATA": "SEA",
    "icao_code": "KSEA"
  },
  {
    "city": "Sebha",
    "country": "Libya",
    "IATA": "SEB",
    "icao_code": "HLLS"
  },
  {
    "city": "Sebring",
    "country": "United States",
    "IATA": "SEF",
    "icao_code": "KSEF"
  },
  {
    "city": "Secunda",
    "country": "South Africa",
    "IATA": "ZEC",
    "icao_code": "FASC"
  },
  {
    "city": "Sedona",
    "country": "United States",
    "IATA": "SDX",
    "icao_code": "KSEZ"
  },
  {
    "city": "Sege",
    "country": "Solomon Islands",
    "IATA": "EGM",
    "icao_code": "AGGS"
  },
  {
    "city": "Sehwan Sharif",
    "country": "Pakistan",
    "IATA": "SYW",
    "icao_code": "OPSN"
  },
  {
    "city": "Seinäjoki / Ilmajoki",
    "country": "Finland",
    "IATA": "SJY",
    "icao_code": "EFIL"
  },
  {
    "city": "Selawik",
    "country": "United States",
    "IATA": "WLK",
    "icao_code": "PASK"
  },
  {
    "city": "Seldovia",
    "country": "United States",
    "IATA": "SOV",
    "icao_code": "PASO"
  },
  {
    "city": "Selebi-phikwe",
    "country": "Botswana",
    "IATA": "PKW",
    "icao_code": "FBSP"
  },
  {
    "city": "Selibabi",
    "country": "Mauritania",
    "IATA": "SEY",
    "icao_code": "GQNS"
  },
  {
    "city": "Selma",
    "country": "United States",
    "IATA": "SEM",
    "icao_code": "KSEM"
  },
  {
    "city": "Semarang",
    "country": "Indonesia",
    "IATA": "SRG",
    "icao_code": "WIIS"
  },
  {
    "city": "Semiplatinsk",
    "country": "Kazakhstan",
    "IATA": "PLX",
    "icao_code": "UASS"
  },
  {
    "city": "Sendai",
    "country": "Japan",
    "IATA": "SDJ",
    "icao_code": "RJSS"
  },
  {
    "city": "Senggeh-Papua Island",
    "country": "Indonesia",
    "IATA": "SEH",
    "icao_code": "WAJS"
  },
  {
    "city": "Seo De Urgel",
    "country": "Spain",
    "IATA": "LEU",
    "icao_code": "LESU"
  },
  {
    "city": "Seogwipo",
    "country": "South Korea",
    "IATA": "JDG",
    "icao_code": "RKPD"
  },
  {
    "city": "Seosan",
    "country": "South Korea",
    "IATA": "HMY"
  },
  {
    "city": "Seoul",
    "country": "South Korea",
    "IATA": "ICN",
    "icao_code": "RKSI"
  },
  {
    "city": "Seoul",
    "country": "South Korea",
    "IATA": "GMP",
    "icao_code": "RKSS"
  },
  {
    "city": "Seoul East",
    "country": "South Korea",
    "IATA": "SSN",
    "icao_code": "RKSM"
  },
  {
    "city": "Sept-iles",
    "country": "Canada",
    "IATA": "YZV",
    "icao_code": "CYZV"
  },
  {
    "city": "Seronera",
    "country": "Tanzania",
    "IATA": "SEU",
    "icao_code": "HTSN"
  },
  {
    "city": "Setif",
    "country": "Algeria",
    "IATA": "QSF",
    "icao_code": "DAAS"
  },
  {
    "city": "Sevastopol",
    "country": "Ukraine",
    "IATA": "UKS"
  },
  {
    "city": "Sevilla",
    "country": "Spain",
    "IATA": "SVQ",
    "icao_code": "LEZL"
  },
  {
    "city": "Sevilla",
    "country": "Spain",
    "IATA": "OZP",
    "icao_code": "LEMO"
  },
  {
    "city": "Sewanee",
    "country": "United States",
    "IATA": "UOS",
    "icao_code": "KUOS"
  },
  {
    "city": "Seward",
    "country": "United States",
    "IATA": "SWD"
  },
  {
    "city": "Sfax",
    "country": "Tunisia",
    "IATA": "SFA",
    "icao_code": "DTTX"
  },
  {
    "city": "Shageluk",
    "country": "United States",
    "IATA": "SHX",
    "icao_code": "PAHX"
  },
  {
    "city": "Shahre Kord",
    "country": "Iran",
    "IATA": "CQD",
    "icao_code": "OIFS"
  },
  {
    "city": "Shakawe",
    "country": "Botswana",
    "IATA": "SWX",
    "icao_code": "FBSW"
  },
  {
    "city": "Shakhtyorsk",
    "country": "Russia",
    "IATA": "EKS",
    "icao_code": "KEKS"
  },
  {
    "city": "Shaktoolik",
    "country": "United States",
    "IATA": "SKK",
    "icao_code": "PFSH"
  },
  {
    "city": "Shamattawa",
    "country": "Canada",
    "IATA": "ZTM",
    "icao_code": "CZTM"
  },
  {
    "city": "Shanghai",
    "country": "China",
    "IATA": "PVG",
    "icao_code": "ZSPD"
  },
  {
    "city": "Shanghai",
    "country": "China",
    "IATA": "SHA",
    "icao_code": "ZSSS"
  },
  {
    "city": "Shangrao",
    "country": "China",
    "IATA": "SQD",
    "icao_code": "ZSSR"
  },
  {
    "city": "Shangri-La",
    "country": "China",
    "IATA": "DIG",
    "icao_code": "ZPDQ"
  },
  {
    "city": "Shank",
    "country": "Afghanistan",
    "IATA": "OAA"
  },
  {
    "city": "Shannon",
    "country": "Ireland",
    "IATA": "SNN",
    "icao_code": "EINN"
  },
  {
    "city": "Shanshan",
    "country": "China",
    "IATA": "SXJ",
    "icao_code": "ZWSS"
  },
  {
    "city": "Shantou",
    "country": "China",
    "IATA": "SWA",
    "icao_code": "ZGOW"
  },
  {
    "city": "Shaoyang",
    "country": "China",
    "IATA": "WGN"
  },
  {
    "city": "Sharjah",
    "country": "United Arab Emirates",
    "IATA": "SHJ",
    "icao_code": "OMSJ"
  },
  {
    "city": "Shark Bay",
    "country": "Australia",
    "IATA": "MJK",
    "icao_code": "YSHK"
  },
  {
    "city": "Sharm El Sheikh",
    "country": "Egypt",
    "IATA": "SSH",
    "icao_code": "HESH"
  },
  {
    "city": "Sharona",
    "country": "Afghanistan",
    "IATA": "OAS"
  },
  {
    "city": "Sharq Al-Owainat",
    "country": "Egypt",
    "IATA": "GSQ",
    "icao_code": "HEOW"
  },
  {
    "city": "Sharurah",
    "country": "Saudi Arabia",
    "IATA": "SHW",
    "icao_code": "OESH"
  },
  {
    "city": "Shchyolkovo",
    "country": "Russia",
    "IATA": "CKL",
    "icao_code": "UUMU"
  },
  {
    "city": "Sheboygan",
    "country": "United States",
    "IATA": "SBM",
    "icao_code": "KSBM"
  },
  {
    "city": "Shelton",
    "country": "United States",
    "IATA": "SHN",
    "icao_code": "KSHN"
  },
  {
    "city": "Shemya",
    "country": "United States",
    "IATA": "SYA"
  },
  {
    "city": "Shennongjia",
    "country": "China",
    "IATA": "HPG",
    "icao_code": "ZHSN"
  },
  {
    "city": "Shenyang",
    "country": "China",
    "IATA": "SHE",
    "icao_code": "ZYTX"
  },
  {
    "city": "Shenzhen",
    "country": "China",
    "IATA": "SZX",
    "icao_code": "ZGSZ"
  },
  {
    "city": "Shepparton",
    "country": "Australia",
    "IATA": "SHT",
    "icao_code": "YSHT"
  },
  {
    "city": "Sherbrooke",
    "country": "Canada",
    "IATA": "YSC",
    "icao_code": "CYSC"
  },
  {
    "city": "Sheridan",
    "country": "United States",
    "IATA": "SHR",
    "icao_code": "KSHR"
  },
  {
    "city": "Shigatse",
    "country": "China",
    "IATA": "RKZ",
    "icao_code": "ZURK"
  },
  {
    "city": "Shijiazhuang",
    "country": "China",
    "IATA": "SJW",
    "icao_code": "ZBSJ"
  },
  {
    "city": "Shillong",
    "country": "India",
    "IATA": "SHL",
    "icao_code": "VEBI"
  },
  {
    "city": "Shimla",
    "country": "India",
    "IATA": "SLV",
    "icao_code": "VISM"
  },
  {
    "city": "Shimojishima",
    "country": "Japan",
    "IATA": "SHI",
    "icao_code": "RORS"
  },
  {
    "city": "Shindand",
    "country": "Afghanistan",
    "IATA": "OAH"
  },
  {
    "city": "Shinyanga",
    "country": "Tanzania",
    "IATA": "SHY",
    "icao_code": "HTSY"
  },
  {
    "city": "Shiquanhe",
    "country": "China",
    "IATA": "NGQ",
    "icao_code": "ZUAL"
  },
  {
    "city": "Shiraz",
    "country": "Iran",
    "IATA": "SYZ",
    "icao_code": "OISS"
  },
  {
    "city": "Shirdi",
    "country": "India",
    "IATA": "SAG",
    "icao_code": "VASD"
  },
  {
    "city": "Shishmaref",
    "country": "United States",
    "IATA": "SHH",
    "icao_code": "PASH"
  },
  {
    "city": "Shiyan",
    "country": "China",
    "IATA": "WDS",
    "icao_code": "ZHSY"
  },
  {
    "city": "Sholapur",
    "country": "India",
    "IATA": "SSE",
    "icao_code": "VASL"
  },
  {
    "city": "Shonai",
    "country": "Japan",
    "IATA": "SYO",
    "icao_code": "RJSY"
  },
  {
    "city": "Shoreham By Sea",
    "country": "United Kingdom",
    "IATA": "ESH",
    "icao_code": "EGKA"
  },
  {
    "city": "Show Low",
    "country": "United States",
    "IATA": "SOW",
    "icao_code": "KSOW"
  },
  {
    "city": "Shreveport",
    "country": "United States",
    "IATA": "DTN",
    "icao_code": "KDTN"
  },
  {
    "city": "Shreveport",
    "country": "United States",
    "IATA": "BAD"
  },
  {
    "city": "Shreveport",
    "country": "United States",
    "IATA": "SHV",
    "icao_code": "KSHV"
  },
  {
    "city": "Shungnak",
    "country": "United States",
    "IATA": "SHG",
    "icao_code": "PAGH"
  },
  {
    "city": "Shute Harbour",
    "country": "Australia",
    "IATA": "JHQ"
  },
  {
    "city": "Sialkot",
    "country": "Pakistan",
    "IATA": "SKT",
    "icao_code": "OPST"
  },
  {
    "city": "Siargao",
    "country": "Philippines",
    "IATA": "IAO",
    "icao_code": "RPNS"
  },
  {
    "city": "Siauliai",
    "country": "Lithuania",
    "IATA": "SQQ",
    "icao_code": "EYSA"
  },
  {
    "city": "Sibiu",
    "country": "Romania",
    "IATA": "SBZ",
    "icao_code": "LRSB"
  },
  {
    "city": "Sibolga",
    "country": "Indonesia",
    "IATA": "FLZ",
    "icao_code": "WIMS\r\n"
  },
  {
    "city": "Siborong-Borong",
    "country": "Indonesia",
    "IATA": "DTB",
    "icao_code": "WIMN"
  },
  {
    "city": "Sibu",
    "country": "Malaysia",
    "IATA": "SBW",
    "icao_code": "WBGS"
  },
  {
    "city": "Sidi Bel Abbes",
    "country": "Algeria",
    "IATA": "BFW",
    "icao_code": "DAOS"
  },
  {
    "city": "Sidney",
    "country": "United States",
    "IATA": "SNY",
    "icao_code": "KSNY"
  },
  {
    "city": "Sidney",
    "country": "United States",
    "IATA": "SDY",
    "icao_code": "KSDY"
  },
  {
    "city": "Siegerland",
    "country": "Germany",
    "IATA": "SGE"
  },
  {
    "city": "Siem-reap",
    "country": "Cambodia",
    "IATA": "REP",
    "icao_code": "VDSR"
  },
  {
    "city": "Siena",
    "country": "Italy",
    "IATA": "SAY",
    "icao_code": "LIQS"
  },
  {
    "city": "Sierra Grande",
    "country": "Argentina",
    "IATA": "SGV",
    "icao_code": "SAVS"
  },
  {
    "city": "Sigiriya",
    "country": "Sri Lanka",
    "IATA": "GIU",
    "icao_code": "VCCS"
  },
  {
    "city": "Siglufjordur",
    "country": "Iceland",
    "IATA": "SIJ",
    "icao_code": "BISI"
  },
  {
    "city": "Sigonella",
    "country": "Italy",
    "IATA": "NSY",
    "icao_code": "LICZ"
  },
  {
    "city": "Siguanea",
    "country": "Cuba",
    "IATA": "SZJ",
    "icao_code": "MUSN"
  },
  {
    "city": "Siguiri",
    "country": "Guinea",
    "IATA": "GII",
    "icao_code": "GUSI"
  },
  {
    "city": "Sihanoukville",
    "country": "Cambodia",
    "IATA": "KOS",
    "icao_code": "VDSV"
  },
  {
    "city": "Siirt",
    "country": "Turkey",
    "IATA": "SXZ",
    "icao_code": "LTCL"
  },
  {
    "city": "Sikasso",
    "country": "Mali",
    "IATA": "KSS",
    "icao_code": "GASK"
  },
  {
    "city": "Sikeston",
    "country": "United States",
    "IATA": "SIK",
    "icao_code": "KSIK"
  },
  {
    "city": "Silchar",
    "country": "India",
    "IATA": "IXS",
    "icao_code": "VEKU"
  },
  {
    "city": "Simao",
    "country": "China",
    "IATA": "SYM",
    "icao_code": "ZPSM"
  },
  {
    "city": "Simara",
    "country": "Nepal",
    "IATA": "SIF",
    "icao_code": "VNSI"
  },
  {
    "city": "Simberi Island",
    "country": "Papua New Guinea",
    "IATA": "NIS"
  },
  {
    "city": "Simferopol",
    "country": "Ukraine",
    "IATA": "SIP",
    "icao_code": "UKFF"
  },
  {
    "city": "Simikot",
    "country": "Nepal",
    "IATA": "IMK",
    "icao_code": "VNST"
  },
  {
    "city": "Sindal",
    "country": "Denmark",
    "IATA": "CNL",
    "icao_code": "EKSN"
  },
  {
    "city": "Sindh",
    "country": "Pakistan",
    "IATA": "RZS"
  },
  {
    "city": "Singapore",
    "country": "Singapore",
    "IATA": "SIN",
    "icao_code": "WSSS"
  },
  {
    "city": "Singapore",
    "country": "Singapore",
    "IATA": "XSP",
    "icao_code": "WSSL"
  },
  {
    "city": "Singkep",
    "country": "Indonesia",
    "IATA": "SIQ",
    "icao_code": "WIKS"
  },
  {
    "city": "Sinop",
    "country": "Brazil",
    "IATA": "OPS",
    "icao_code": "SWSI"
  },
  {
    "city": "Sinop",
    "country": "Turkey",
    "IATA": "NOP"
  },
  {
    "city": "Sintang-Borneo Island",
    "country": "Indonesia",
    "IATA": "SQG",
    "icao_code": "WIOS"
  },
  {
    "city": "Sion",
    "country": "Switzerland",
    "IATA": "SIR",
    "icao_code": "LSGS"
  },
  {
    "city": "Sioux City",
    "country": "United States",
    "IATA": "SUX",
    "icao_code": "KSUX"
  },
  {
    "city": "Sioux Falls",
    "country": "United States",
    "IATA": "FSD",
    "icao_code": "KFSD"
  },
  {
    "city": "Sioux Lookout",
    "country": "Canada",
    "IATA": "YXL",
    "icao_code": "CYXL"
  },
  {
    "city": "Sipora",
    "country": "Indonesia",
    "IATA": "RKO"
  },
  {
    "city": "Sir Bani Yas Island",
    "country": "United Arab Emirates",
    "IATA": "XSB"
  },
  {
    "city": "Siri Island",
    "country": "Iran",
    "IATA": "SXI",
    "icao_code": "OIBS"
  },
  {
    "city": "Sirjan",
    "country": "Iran",
    "IATA": "SYJ",
    "icao_code": "OIKY"
  },
  {
    "city": "Sirt",
    "country": "Libya",
    "IATA": "SRX",
    "icao_code": "HLGD"
  },
  {
    "city": "Sishen",
    "country": "South Africa",
    "IATA": "SIS",
    "icao_code": "FASS"
  },
  {
    "city": "Sisimiut",
    "country": "Greenland",
    "IATA": "JHS",
    "icao_code": "BGHB"
  },
  {
    "city": "Sitia",
    "country": "Greece",
    "IATA": "JSH",
    "icao_code": "LGST"
  },
  {
    "city": "Sitka",
    "country": "United States",
    "IATA": "SIT",
    "icao_code": "PASI"
  },
  {
    "city": "Sittwe",
    "country": "Burma",
    "IATA": "AKY",
    "icao_code": "VYSW"
  },
  {
    "city": "Siuna",
    "country": "Nicaragua",
    "IATA": "SIU",
    "icao_code": "MNSI"
  },
  {
    "city": "Sivas",
    "country": "Turkey",
    "IATA": "VAS",
    "icao_code": "LTAR"
  },
  {
    "city": "Skagit",
    "country": "United States",
    "IATA": "MVW",
    "icao_code": "KBVS"
  },
  {
    "city": "Skagway",
    "country": "United States",
    "IATA": "SGY",
    "icao_code": "PAGY"
  },
  {
    "city": "Skardu",
    "country": "Pakistan",
    "IATA": "KDU",
    "icao_code": "OPSD"
  },
  {
    "city": "Skelleftea",
    "country": "Sweden",
    "IATA": "SFT",
    "icao_code": "ESNS"
  },
  {
    "city": "Skiathos",
    "country": "Greece",
    "IATA": "JSI",
    "icao_code": "LGSK"
  },
  {
    "city": "Skien",
    "country": "Norway",
    "IATA": "SKE",
    "icao_code": "ENSN"
  },
  {
    "city": "Skiros",
    "country": "Greece",
    "IATA": "SKU",
    "icao_code": "LGSY"
  },
  {
    "city": "Skive",
    "country": "Denmark",
    "IATA": "SQW",
    "icao_code": "EKSV"
  },
  {
    "city": "Skopje",
    "country": "Macedonia",
    "IATA": "SKP",
    "icao_code": "LWSK"
  },
  {
    "city": "Skovde",
    "country": "Sweden",
    "IATA": "KVB",
    "icao_code": "ESGR"
  },
  {
    "city": "Skrydstrup",
    "country": "Denmark",
    "IATA": "SKS",
    "icao_code": "EKSP"
  },
  {
    "city": "Skukuza",
    "country": "South Africa",
    "IATA": "SZK"
  },
  {
    "city": "Slave Lake",
    "country": "Canada",
    "IATA": "YZH",
    "icao_code": "CYZH"
  },
  {
    "city": "Sleetmute",
    "country": "United States",
    "IATA": "SLQ",
    "icao_code": "PASL"
  },
  {
    "city": "Sliac",
    "country": "Slovakia",
    "IATA": "SLD",
    "icao_code": "LZSL"
  },
  {
    "city": "Sligo",
    "country": "Ireland",
    "IATA": "SXL",
    "icao_code": "EISG"
  },
  {
    "city": "Slupsk",
    "country": "Poland",
    "IATA": "OSP"
  },
  {
    "city": "Smara",
    "country": "Western Sahara",
    "IATA": "SMW",
    "icao_code": "GMMA"
  },
  {
    "city": "Smithers",
    "country": "Canada",
    "IATA": "YYD",
    "icao_code": "CYYD"
  },
  {
    "city": "Smithfield",
    "country": "United States",
    "IATA": "SFZ",
    "icao_code": "KSFZ"
  },
  {
    "city": "Smiths Falls",
    "country": "Canada",
    "IATA": "YSH",
    "icao_code": "CYSH"
  },
  {
    "city": "Smolensk",
    "country": "Russia",
    "IATA": "LNX",
    "icao_code": "UUBS"
  },
  {
    "city": "Smyrna",
    "country": "United States",
    "IATA": "MQY",
    "icao_code": "KMQY"
  },
  {
    "city": "Soalala",
    "country": "Madagascar",
    "IATA": "DWB"
  },
  {
    "city": "Sochi",
    "country": "Russia",
    "IATA": "AER",
    "icao_code": "URSS"
  },
  {
    "city": "Socotra",
    "country": "Yemen",
    "IATA": "SCT",
    "icao_code": "ODSQ"
  },
  {
    "city": "Sodankyla",
    "country": "Finland",
    "IATA": "SOT",
    "icao_code": "EFSO"
  },
  {
    "city": "Soderhamn",
    "country": "Sweden",
    "IATA": "SOO",
    "icao_code": "ESCL"
  },
  {
    "city": "Soenderborg",
    "country": "Denmark",
    "IATA": "SGD",
    "icao_code": "EKSB"
  },
  {
    "city": "Soesterberg",
    "country": "Netherlands",
    "IATA": "UTC",
    "icao_code": "EHSB"
  },
  {
    "city": "Sofia",
    "country": "Bulgaria",
    "IATA": "SOF",
    "icao_code": "LBSF"
  },
  {
    "city": "Sogndal",
    "country": "Norway",
    "IATA": "SOG",
    "icao_code": "ENSG"
  },
  {
    "city": "Sohag",
    "country": "Egypt",
    "IATA": "HMB",
    "icao_code": "HEMK"
  },
  {
    "city": "Sohar",
    "country": "Oman",
    "IATA": "OHS",
    "icao_code": "OOSH"
  },
  {
    "city": "Sokcho / Gangneung",
    "country": "South Korea",
    "IATA": "YNY",
    "icao_code": "RKNY"
  },
  {
    "city": "Sokoto",
    "country": "Nigeria",
    "IATA": "SKO",
    "icao_code": "DNSO"
  },
  {
    "city": "Sola",
    "country": "Vanuatu",
    "IATA": "SLH",
    "icao_code": "NVSC"
  },
  {
    "city": "Soldotna",
    "country": "United States",
    "IATA": "SXQ"
  },
  {
    "city": "Solenzara",
    "country": "France",
    "IATA": "SOZ"
  },
  {
    "city": "Solo City",
    "country": "Indonesia",
    "IATA": "SOC",
    "icao_code": "WRSQ"
  },
  {
    "city": "Solomon",
    "country": "Australia",
    "IATA": "SLJ"
  },
  {
    "city": "Solovetsky Islands",
    "country": "Russia",
    "IATA": "CSH",
    "icao_code": "ULAS"
  },
  {
    "city": "Somerset",
    "country": "United States",
    "IATA": "SME",
    "icao_code": "KSME"
  },
  {
    "city": "Son-La",
    "country": "Vietnam",
    "IATA": "SQH",
    "icao_code": "VVNS"
  },
  {
    "city": "Sondrestrom",
    "country": "Greenland",
    "IATA": "SFJ",
    "icao_code": "BGSF"
  },
  {
    "city": "Songea",
    "country": "Tanzania",
    "IATA": "SGX",
    "icao_code": "HTSO"
  },
  {
    "city": "Songkhla",
    "country": "Thailand",
    "IATA": "SGZ",
    "icao_code": "VTSH"
  },
  {
    "city": "Songyuan",
    "country": "China",
    "IATA": "YSQ"
  },
  {
    "city": "Sorkjosen",
    "country": "Norway",
    "IATA": "SOJ",
    "icao_code": "ENSR"
  },
  {
    "city": "Soroako",
    "country": "Indonesia",
    "IATA": "SQR",
    "icao_code": "WAWS"
  },
  {
    "city": "Sorocaba",
    "country": "Brazil",
    "IATA": "SOD",
    "icao_code": "SDCO"
  },
  {
    "city": "Sorong",
    "country": "Indonesia",
    "IATA": "SOQ",
    "icao_code": "WASS"
  },
  {
    "city": "Soroti",
    "country": "Uganda",
    "IATA": "SRT",
    "icao_code": "HUSO"
  },
  {
    "city": "Sorriso",
    "country": "Brazil",
    "IATA": "SMT"
  },
  {
    "city": "Soure",
    "country": "Brazil",
    "IATA": "SFK"
  },
  {
    "city": "South Bend",
    "country": "United States",
    "IATA": "SBN",
    "icao_code": "KSBN"
  },
  {
    "city": "South Caicos",
    "country": "Turks and Caicos Islands",
    "IATA": "XSC",
    "icao_code": "MBSC"
  },
  {
    "city": "South Indian Lake",
    "country": "Canada",
    "IATA": "XSI",
    "icao_code": "CZSN"
  },
  {
    "city": "South Lake Tahoe",
    "country": "United States",
    "IATA": "TVL",
    "icao_code": "KTVL"
  },
  {
    "city": "South Naknek",
    "country": "United States",
    "IATA": "WSN",
    "icao_code": "PFWS"
  },
  {
    "city": "Southampton",
    "country": "United Kingdom",
    "IATA": "SOU",
    "icao_code": "EGHI"
  },
  {
    "city": "Southdowns",
    "country": "Zambia",
    "IATA": "KIW",
    "icao_code": "FLSO"
  },
  {
    "city": "Southend",
    "country": "United Kingdom",
    "IATA": "SEN",
    "icao_code": "EGMC"
  },
  {
    "city": "Sovetskaya Gavan",
    "country": "Russia",
    "IATA": "GVN"
  },
  {
    "city": "Sovetskiy",
    "country": "Russia",
    "IATA": "OVS",
    "icao_code": "USHS"
  },
  {
    "city": "Sowa",
    "country": "Botswana",
    "IATA": "SXN",
    "icao_code": "FBSN"
  },
  {
    "city": "Soyo",
    "country": "Angola",
    "IATA": "SZA",
    "icao_code": "FNSO"
  },
  {
    "city": "Spangdahlem",
    "country": "Germany",
    "IATA": "SPM",
    "icao_code": "ETAD"
  },
  {
    "city": "Spanish Town",
    "country": "British Virgin Islands",
    "IATA": "VIJ",
    "icao_code": "TUPW"
  },
  {
    "city": "Sparrevohn",
    "country": "United States",
    "IATA": "SVW",
    "icao_code": "PASV"
  },
  {
    "city": "Spartangurg",
    "country": "United States",
    "IATA": "SPA",
    "icao_code": "KSPA"
  },
  {
    "city": "Sparti",
    "country": "Greece",
    "IATA": "SPJ",
    "icao_code": "LGSP"
  },
  {
    "city": "Spearfish-South Dakota",
    "country": "United States",
    "IATA": "SPF",
    "icao_code": "KSPF"
  },
  {
    "city": "Spence Bay",
    "country": "Canada",
    "IATA": "YYH",
    "icao_code": "CYYH"
  },
  {
    "city": "Spencer",
    "country": "United States",
    "IATA": "SPW",
    "icao_code": "KSPW"
  },
  {
    "city": "Split",
    "country": "Croatia",
    "IATA": "SPU",
    "icao_code": "LDSP"
  },
  {
    "city": "Spokane",
    "country": "United States",
    "IATA": "SFF",
    "icao_code": "KSFF"
  },
  {
    "city": "Spokane",
    "country": "United States",
    "IATA": "SKA"
  },
  {
    "city": "Spokane",
    "country": "United States",
    "IATA": "GEG",
    "icao_code": "KGEG"
  },
  {
    "city": "Spring Point",
    "country": "Bahamas",
    "IATA": "AXP",
    "icao_code": "MYAP"
  },
  {
    "city": "Springbok",
    "country": "South Africa",
    "IATA": "SBU",
    "icao_code": "FASB"
  },
  {
    "city": "Springfield",
    "country": "United States",
    "IATA": "SGH"
  },
  {
    "city": "Springfield",
    "country": "United States",
    "IATA": "SGF",
    "icao_code": "KSGF"
  },
  {
    "city": "Springfield",
    "country": "United States",
    "IATA": "SPI",
    "icao_code": "KSPI"
  },
  {
    "city": "Squamish",
    "country": "Canada",
    "IATA": "YSE",
    "icao_code": "CWSK"
  },
  {
    "city": "Srednekolymsk",
    "country": "Russia",
    "IATA": "SEK"
  },
  {
    "city": "Srinagar",
    "country": "India",
    "IATA": "SXR",
    "icao_code": "VISR"
  },
  {
    "city": "St George",
    "country": "Australia",
    "IATA": "SGO",
    "icao_code": "YSGE"
  },
  {
    "city": "St Leonard",
    "country": "Canada",
    "IATA": "YSL",
    "icao_code": "CYSL"
  },
  {
    "city": "St Mary's",
    "country": "United States",
    "IATA": "KSM",
    "icao_code": "PASM"
  },
  {
    "city": "St Thomas",
    "country": "Canada",
    "IATA": "YQS",
    "icao_code": "CYQS"
  },
  {
    "city": "St-Augustin",
    "country": "Canada",
    "IATA": "YIF",
    "icao_code": "CYIF"
  },
  {
    "city": "St-Etienne",
    "country": "France",
    "IATA": "EBU",
    "icao_code": "LFMH"
  },
  {
    "city": "St-François",
    "country": "Guadeloupe",
    "IATA": "SFC"
  },
  {
    "city": "St. Anthony",
    "country": "Canada",
    "IATA": "YAY",
    "icao_code": "CYAY"
  },
  {
    "city": "St. Augustine Airport",
    "country": "United States",
    "IATA": "UST",
    "icao_code": "KSGJ"
  },
  {
    "city": "St. Catherine",
    "country": "Egypt",
    "IATA": "SKV"
  },
  {
    "city": "St. Croix Island",
    "country": "Virgin Islands",
    "IATA": "STX",
    "icao_code": "TISX"
  },
  {
    "city": "St. Jean",
    "country": "Canada",
    "IATA": "YJN",
    "icao_code": "CYJN"
  },
  {
    "city": "St. John",
    "country": "Canada",
    "IATA": "YSJ",
    "icao_code": "CYSJ"
  },
  {
    "city": "St. John's",
    "country": "Canada",
    "IATA": "YYT",
    "icao_code": "CYYT"
  },
  {
    "city": "St. Lewis",
    "country": "Canada",
    "IATA": "YFX",
    "icao_code": "CCK4"
  },
  {
    "city": "St. Louis",
    "country": "United States",
    "IATA": "STL",
    "icao_code": "KSTL"
  },
  {
    "city": "St. Louis",
    "country": "Senegal",
    "IATA": "XLS",
    "icao_code": "GOSS"
  },
  {
    "city": "St. Martin",
    "country": "Guadeloupe",
    "IATA": "SFG",
    "icao_code": "TFFG"
  },
  {
    "city": "St. Michael",
    "country": "United States",
    "IATA": "SMK",
    "icao_code": "PAMK"
  },
  {
    "city": "St. Paul",
    "country": "United States",
    "IATA": "STP",
    "icao_code": "KSTP"
  },
  {
    "city": "St. Paul Island",
    "country": "United States",
    "IATA": "SNP",
    "icao_code": "PASN"
  },
  {
    "city": "St. Petersburg",
    "country": "United States",
    "IATA": "PIE",
    "icao_code": "KPIE"
  },
  {
    "city": "St. Petersburg",
    "country": "United States",
    "IATA": "SPG",
    "icao_code": "KSPG"
  },
  {
    "city": "St. Petersburg",
    "country": "Russia",
    "IATA": "LED",
    "icao_code": "ULLI"
  },
  {
    "city": "St. Theresa Point",
    "country": "Canada",
    "IATA": "YST",
    "icao_code": "CYST"
  },
  {
    "city": "St. Thomas",
    "country": "Virgin Islands",
    "IATA": "STT",
    "icao_code": "TIST"
  },
  {
    "city": "St.-brieuc Armor",
    "country": "France",
    "IATA": "SBK",
    "icao_code": "LFRT"
  },
  {
    "city": "St.-denis",
    "country": "Reunion",
    "IATA": "RUN",
    "icao_code": "FMEE"
  },
  {
    "city": "St.-georges Oyapock",
    "country": "French Guiana",
    "IATA": "OYP"
  },
  {
    "city": "St.-nazaire",
    "country": "France",
    "IATA": "SNR",
    "icao_code": "LFRZ"
  },
  {
    "city": "St.-pierre",
    "country": "Saint Pierre and Miquelon",
    "IATA": "FSP",
    "icao_code": "LFVP"
  },
  {
    "city": "St.-pierre",
    "country": "Reunion",
    "IATA": "ZSE",
    "icao_code": "KZSE"
  },
  {
    "city": "St.-yan",
    "country": "France",
    "IATA": "SYT",
    "icao_code": "LFLN"
  },
  {
    "city": "Staniel Cay",
    "country": "Bahamas",
    "IATA": "TYM"
  },
  {
    "city": "Stanley",
    "country": "Falkland Islands",
    "IATA": "PSY",
    "icao_code": "SFAL"
  },
  {
    "city": "Stara Zagora",
    "country": "Bulgaria",
    "IATA": "SZR"
  },
  {
    "city": "State College Pennsylvania",
    "country": "United States",
    "IATA": "SCE",
    "icao_code": "KUNV"
  },
  {
    "city": "Statesboro",
    "country": "United States",
    "IATA": "TBR"
  },
  {
    "city": "Statesville",
    "country": "United States",
    "IATA": "SVH",
    "icao_code": "KSVH"
  },
  {
    "city": "Stauning",
    "country": "Denmark",
    "IATA": "STA",
    "icao_code": "EKVJ"
  },
  {
    "city": "Stavanger",
    "country": "Norway",
    "IATA": "SVG",
    "icao_code": "ENZV"
  },
  {
    "city": "Stavropol",
    "country": "Russia",
    "IATA": "STW",
    "icao_code": "URMT"
  },
  {
    "city": "Steamboat Springs",
    "country": "United States",
    "IATA": "SBS",
    "icao_code": "KSBS"
  },
  {
    "city": "Stella Maris",
    "country": "Bahamas",
    "IATA": "SML",
    "icao_code": "MYLS"
  },
  {
    "city": "Stephenville",
    "country": "United States",
    "IATA": "SEP"
  },
  {
    "city": "Stephenville",
    "country": "Canada",
    "IATA": "YJT",
    "icao_code": "CYJT"
  },
  {
    "city": "Sterling",
    "country": "United States",
    "IATA": "STK"
  },
  {
    "city": "Stevens Point",
    "country": "United States",
    "IATA": "STE",
    "icao_code": "KSTE"
  },
  {
    "city": "Stewart",
    "country": "Canada",
    "IATA": "ZST",
    "icao_code": "CZST"
  },
  {
    "city": "Stewart Island",
    "country": "New Zealand",
    "IATA": "SZS",
    "icao_code": "NZRC"
  },
  {
    "city": "Stillwater",
    "country": "United States",
    "IATA": "SWO",
    "icao_code": "KSWO"
  },
  {
    "city": "Stinson",
    "country": "United States",
    "IATA": "SSF",
    "icao_code": "KSSF"
  },
  {
    "city": "Stirling Island",
    "country": "Solomon Islands",
    "IATA": "MNY",
    "icao_code": "AGGO"
  },
  {
    "city": "Stockholm",
    "country": "Sweden",
    "IATA": "BMA",
    "icao_code": "ESSB"
  },
  {
    "city": "Stockholm",
    "country": "Sweden",
    "IATA": "ARN",
    "icao_code": "ESSA"
  },
  {
    "city": "Stockholm",
    "country": "Sweden",
    "IATA": "NYO",
    "icao_code": "ESKN"
  },
  {
    "city": "Stockton",
    "country": "United States",
    "IATA": "SCK",
    "icao_code": "KSCK"
  },
  {
    "city": "Stoelmans Eiland",
    "country": "Suriname",
    "IATA": "SMZ"
  },
  {
    "city": "Stokmarknes",
    "country": "Norway",
    "IATA": "SKN",
    "icao_code": "ENSK"
  },
  {
    "city": "Stony Rapids",
    "country": "Canada",
    "IATA": "YSF",
    "icao_code": "CYSF"
  },
  {
    "city": "Stord",
    "country": "Norway",
    "IATA": "SRP",
    "icao_code": "ENSO"
  },
  {
    "city": "Stornoway",
    "country": "United Kingdom",
    "IATA": "SYY",
    "icao_code": "EGPO"
  },
  {
    "city": "Strahan",
    "country": "Australia",
    "IATA": "SRN",
    "icao_code": "YSRN"
  },
  {
    "city": "Strasbourg",
    "country": "France",
    "IATA": "SXB",
    "icao_code": "LFST"
  },
  {
    "city": "Stratford",
    "country": "United States",
    "IATA": "BDR",
    "icao_code": "KBDR"
  },
  {
    "city": "Straubing",
    "country": "Germany",
    "IATA": "RBM",
    "icao_code": "KRBM"
  },
  {
    "city": "Strezhevoy",
    "country": "Russia",
    "IATA": "SWT",
    "icao_code": "UNSS"
  },
  {
    "city": "Stronsay",
    "country": "United Kingdom",
    "IATA": "SOY",
    "icao_code": "EGER"
  },
  {
    "city": "Stuart",
    "country": "United States",
    "IATA": "SUA",
    "icao_code": "KSUA"
  },
  {
    "city": "Stung Treng",
    "country": "Cambodia",
    "IATA": "TNX",
    "icao_code": "VDST"
  },
  {
    "city": "Stuttgart",
    "country": "Germany",
    "IATA": "STR",
    "icao_code": "EDDS"
  },
  {
    "city": "Suai",
    "country": "East Timor",
    "IATA": "UAI"
  },
  {
    "city": "Suavanao",
    "country": "Solomon Islands",
    "IATA": "VAO",
    "icao_code": "AGGV"
  },
  {
    "city": "Suceava",
    "country": "Romania",
    "IATA": "SCV",
    "icao_code": "LRSV"
  },
  {
    "city": "Sucre",
    "country": "Bolivia",
    "IATA": "SRE",
    "icao_code": "SLSU"
  },
  {
    "city": "Sudbury",
    "country": "Canada",
    "IATA": "YSB",
    "icao_code": "CYSB"
  },
  {
    "city": "Sue Islet",
    "country": "Australia",
    "IATA": "SYU",
    "icao_code": "YWBS"
  },
  {
    "city": "Suffield",
    "country": "Canada",
    "IATA": "YSD"
  },
  {
    "city": "Sugapa-Papua Island",
    "country": "Indonesia",
    "IATA": "UGU"
  },
  {
    "city": "Sugar Land",
    "country": "United States",
    "IATA": "SGR",
    "icao_code": "KSGR"
  },
  {
    "city": "Sui",
    "country": "Pakistan",
    "IATA": "SUL",
    "icao_code": "OPSU"
  },
  {
    "city": "Sukhothai",
    "country": "Thailand",
    "IATA": "THS",
    "icao_code": "VTPO"
  },
  {
    "city": "Sukhumi",
    "country": "Georgia",
    "IATA": "SUI",
    "icao_code": "UGSS"
  },
  {
    "city": "Sukkur",
    "country": "Pakistan",
    "IATA": "SKZ",
    "icao_code": "OPSK"
  },
  {
    "city": "Sulawesi Tenggara",
    "country": "Indonesia",
    "IATA": "TQQ"
  },
  {
    "city": "Sulayel",
    "country": "Saudi Arabia",
    "IATA": "SLF",
    "icao_code": "OESL"
  },
  {
    "city": "Sulaymaniyah",
    "country": "Iraq",
    "IATA": "ISU",
    "icao_code": "ORSU"
  },
  {
    "city": "Sumbawa Island",
    "country": "Indonesia",
    "IATA": "SWQ",
    "icao_code": "WADS"
  },
  {
    "city": "Sumbe",
    "country": "Angola",
    "IATA": "NDD",
    "icao_code": "FNSU"
  },
  {
    "city": "Sumburgh",
    "country": "United Kingdom",
    "IATA": "LSI",
    "icao_code": "EGPB"
  },
  {
    "city": "Sumenep",
    "country": "Indonesia",
    "IATA": "SUP",
    "icao_code": "WART"
  },
  {
    "city": "Summer Beaver",
    "country": "Canada",
    "IATA": "SUR",
    "icao_code": "CJV7"
  },
  {
    "city": "Summerside",
    "country": "Canada",
    "IATA": "YSU",
    "icao_code": "CYSU"
  },
  {
    "city": "Sumter",
    "country": "United States",
    "IATA": "SSC"
  },
  {
    "city": "Sunchales",
    "country": "Argentina",
    "IATA": "NCJ"
  },
  {
    "city": "Sundsvall",
    "country": "Sweden",
    "IATA": "SDL",
    "icao_code": "ESNN"
  },
  {
    "city": "Suntar",
    "country": "Russia",
    "IATA": "SUY"
  },
  {
    "city": "Sunyani",
    "country": "Ghana",
    "IATA": "NYI",
    "icao_code": "DGSN"
  },
  {
    "city": "Surabaya",
    "country": "Indonesia",
    "IATA": "SUB",
    "icao_code": "WRSJ"
  },
  {
    "city": "Surallah",
    "country": "Philippines",
    "IATA": "AAV"
  },
  {
    "city": "Surat",
    "country": "India",
    "IATA": "STV",
    "icao_code": "VASU"
  },
  {
    "city": "Surat Thani",
    "country": "Thailand",
    "IATA": "URT",
    "icao_code": "VTSB"
  },
  {
    "city": "Surgut",
    "country": "Russia",
    "IATA": "SGC",
    "icao_code": "USRR"
  },
  {
    "city": "Surin",
    "country": "Thailand",
    "IATA": "PXR"
  },
  {
    "city": "Surkhet",
    "country": "Nepal",
    "IATA": "SKH",
    "icao_code": "VNSK"
  },
  {
    "city": "Suwon",
    "country": "South Korea",
    "IATA": "SWU",
    "icao_code": "RKSW"
  },
  {
    "city": "Suzhou",
    "country": "China",
    "IATA": "SZV",
    "icao_code": "ZSSZ"
  },
  {
    "city": "Sveg",
    "country": "Sweden",
    "IATA": "EVG",
    "icao_code": "ESND"
  },
  {
    "city": "Svolvær",
    "country": "Norway",
    "IATA": "SVJ",
    "icao_code": "ENSH"
  },
  {
    "city": "Swakopmund",
    "country": "Namibia",
    "IATA": "SWP"
  },
  {
    "city": "Swan Hill",
    "country": "Australia",
    "IATA": "SWH",
    "icao_code": "YSWH"
  },
  {
    "city": "Swan River",
    "country": "Canada",
    "IATA": "ZJN",
    "icao_code": "CZJN"
  },
  {
    "city": "Swansea",
    "country": "United Kingdom",
    "IATA": "SWS",
    "icao_code": "EGFH"
  },
  {
    "city": "Swift Current",
    "country": "Canada",
    "IATA": "YYN",
    "icao_code": "CYYN"
  },
  {
    "city": "Syangboche",
    "country": "Nepal",
    "IATA": "SYH"
  },
  {
    "city": "Sydney",
    "country": "Australia",
    "IATA": "SYD",
    "icao_code": "YSSY"
  },
  {
    "city": "Sydney",
    "country": "Australia",
    "IATA": "BWU",
    "icao_code": "YSBK"
  },
  {
    "city": "Sydney",
    "country": "Canada",
    "IATA": "YQY",
    "icao_code": "CYQY"
  },
  {
    "city": "Syktyvkar",
    "country": "Russia",
    "IATA": "SCW",
    "icao_code": "UUYY"
  },
  {
    "city": "Sylhet Osmani",
    "country": "Bangladesh",
    "IATA": "ZYL",
    "icao_code": "VGSY"
  },
  {
    "city": "Syracuse",
    "country": "United States",
    "IATA": "SYR",
    "icao_code": "KSYR"
  },
  {
    "city": "Syros Island",
    "country": "Greece",
    "IATA": "JSY",
    "icao_code": "LGSO"
  },
  {
    "city": "Szczecin",
    "country": "Poland",
    "IATA": "SZZ",
    "icao_code": "EPSC"
  },
  {
    "city": "Szczytno-Szymany",
    "country": "Poland",
    "IATA": "SZY",
    "icao_code": "EPSY"
  },
  {
    "city": "Sármellék",
    "country": "Hungary",
    "IATA": "SOB",
    "icao_code": "LHSM"
  },
  {
    "city": "Taba",
    "country": "Egypt",
    "IATA": "TCP",
    "icao_code": "HETB"
  },
  {
    "city": "Tabarka",
    "country": "Tunisia",
    "IATA": "TBJ",
    "icao_code": "DTKA"
  },
  {
    "city": "Tabas",
    "country": "Iran",
    "IATA": "TCX",
    "icao_code": "OIMT"
  },
  {
    "city": "Tabatinga",
    "country": "Brazil",
    "IATA": "TBT",
    "icao_code": "SBTT"
  },
  {
    "city": "Tabiteuea",
    "country": "Kiribati",
    "IATA": "TSU"
  },
  {
    "city": "Tabiteuea North",
    "country": "Kiribati",
    "IATA": "TBF"
  },
  {
    "city": "Tabora",
    "country": "Tanzania",
    "IATA": "TBO",
    "icao_code": "HTTB"
  },
  {
    "city": "Tabriz",
    "country": "Iran",
    "IATA": "TBZ",
    "icao_code": "OITT"
  },
  {
    "city": "Tabubil",
    "country": "Papua New Guinea",
    "IATA": "TBG",
    "icao_code": "AYTB"
  },
  {
    "city": "Tabuk",
    "country": "Saudi Arabia",
    "IATA": "TUU",
    "icao_code": "OETB"
  },
  {
    "city": "Tacheng",
    "country": "China",
    "IATA": "TCG",
    "icao_code": "SPCH"
  },
  {
    "city": "Tachilek",
    "country": "Burma",
    "IATA": "THL",
    "icao_code": "VYTL"
  },
  {
    "city": "Tacloban",
    "country": "Philippines",
    "IATA": "TAC",
    "icao_code": "RPVA"
  },
  {
    "city": "Tacna",
    "country": "Peru",
    "IATA": "TCQ",
    "icao_code": "SPTN"
  },
  {
    "city": "Tacoma",
    "country": "United States",
    "IATA": "TIW",
    "icao_code": "KTIW"
  },
  {
    "city": "Tacoma",
    "country": "United States",
    "IATA": "TCM",
    "icao_code": "KTCM"
  },
  {
    "city": "Tadjoura",
    "country": "Djibouti",
    "IATA": "TDJ",
    "icao_code": "HDTJ"
  },
  {
    "city": "Tadoule Lake",
    "country": "Canada",
    "IATA": "XTL",
    "icao_code": "CYBQ"
  },
  {
    "city": "Taegu",
    "country": "South Korea",
    "IATA": "TAE",
    "icao_code": "RKTN"
  },
  {
    "city": "Taganrog",
    "country": "Russia",
    "IATA": "TGK",
    "icao_code": "URRT"
  },
  {
    "city": "Tagbilaran",
    "country": "Philippines",
    "IATA": "TAG",
    "icao_code": "RPVT"
  },
  {
    "city": "Tahoua",
    "country": "Niger",
    "IATA": "THZ",
    "icao_code": "DRRT"
  },
  {
    "city": "Taichung",
    "country": "Taiwan",
    "IATA": "TXG"
  },
  {
    "city": "Taichung",
    "country": "Taiwan",
    "IATA": "RMQ",
    "icao_code": "RCMQ"
  },
  {
    "city": "Taif",
    "country": "Saudi Arabia",
    "IATA": "TIF",
    "icao_code": "OETF"
  },
  {
    "city": "Tainan",
    "country": "Taiwan",
    "IATA": "TNN",
    "icao_code": "RCNN"
  },
  {
    "city": "Taipei",
    "country": "Taiwan",
    "IATA": "TPE",
    "icao_code": "RCTP"
  },
  {
    "city": "Taipei",
    "country": "Taiwan",
    "IATA": "TSA",
    "icao_code": "RCSS"
  },
  {
    "city": "Taiyuan",
    "country": "China",
    "IATA": "TYN",
    "icao_code": "ZBYN"
  },
  {
    "city": "Taiz",
    "country": "Yemen",
    "IATA": "TAI",
    "icao_code": "OYTZ"
  },
  {
    "city": "Tak",
    "country": "Thailand",
    "IATA": "TKT",
    "icao_code": "VTPT"
  },
  {
    "city": "Tak",
    "country": "Thailand",
    "IATA": "MAQ",
    "icao_code": "VTPM"
  },
  {
    "city": "Takaka",
    "country": "New Zealand",
    "IATA": "KTF",
    "icao_code": "NZTK"
  },
  {
    "city": "Takamatsu",
    "country": "Japan",
    "IATA": "TAK",
    "icao_code": "RJOT"
  },
  {
    "city": "Takapoto",
    "country": "French Polynesia",
    "IATA": "TKP",
    "icao_code": "NTGT"
  },
  {
    "city": "Takaroa",
    "country": "French Polynesia",
    "IATA": "TKX",
    "icao_code": "NTKR"
  },
  {
    "city": "Taketomi",
    "country": "Japan",
    "IATA": "HTR",
    "icao_code": "RORH"
  },
  {
    "city": "Takoradi",
    "country": "Ghana",
    "IATA": "TKD",
    "icao_code": "DGTK"
  },
  {
    "city": "Talakan",
    "country": "Russia",
    "IATA": "TLK",
    "icao_code": "UECT"
  },
  {
    "city": "Talang Gudang-Sumatra Island",
    "country": "Indonesia",
    "IATA": "PDO",
    "icao_code": "WIPQ"
  },
  {
    "city": "Talara",
    "country": "Peru",
    "IATA": "TYL",
    "icao_code": "SPYL"
  },
  {
    "city": "Talca",
    "country": "Chile",
    "IATA": "TLX",
    "icao_code": "SCTL"
  },
  {
    "city": "Talhar",
    "country": "Pakistan",
    "IATA": "BDN",
    "icao_code": "OPTH"
  },
  {
    "city": "Talkeetna",
    "country": "United States",
    "IATA": "TKA",
    "icao_code": "PATK"
  },
  {
    "city": "Talladega",
    "country": "United States",
    "IATA": "ASN",
    "icao_code": "KASN"
  },
  {
    "city": "Tallahassee",
    "country": "United States",
    "IATA": "TLH",
    "icao_code": "KTLH"
  },
  {
    "city": "Tallinn-ulemiste International",
    "country": "Estonia",
    "IATA": "TLL",
    "icao_code": "EETN"
  },
  {
    "city": "Taltal",
    "country": "Chile",
    "IATA": "TTC"
  },
  {
    "city": "Tamale",
    "country": "Ghana",
    "IATA": "TML",
    "icao_code": "DGLE"
  },
  {
    "city": "Tamana",
    "country": "Kiribati",
    "IATA": "TMN"
  },
  {
    "city": "Tamanrasset",
    "country": "Algeria",
    "IATA": "TMR",
    "icao_code": "DAAT"
  },
  {
    "city": "Tambacounda",
    "country": "Senegal",
    "IATA": "TUD",
    "icao_code": "GOTT"
  },
  {
    "city": "Tambohorano",
    "country": "Madagascar",
    "IATA": "WTA"
  },
  {
    "city": "Tambow",
    "country": "Russia",
    "IATA": "TBW",
    "icao_code": "UUOT"
  },
  {
    "city": "Tamchy",
    "country": "Kyrgyzstan",
    "IATA": "IKU",
    "icao_code": "UAFL"
  },
  {
    "city": "Tame",
    "country": "Colombia",
    "IATA": "TME",
    "icao_code": "SKTM"
  },
  {
    "city": "Tampa",
    "country": "United States",
    "IATA": "TPF",
    "icao_code": "KTPF"
  },
  {
    "city": "Tampa",
    "country": "United States",
    "IATA": "MCF",
    "icao_code": "KMCF"
  },
  {
    "city": "Tampa",
    "country": "United States",
    "IATA": "TPA",
    "icao_code": "KTPA"
  },
  {
    "city": "Tampere",
    "country": "Finland",
    "IATA": "TMP",
    "icao_code": "EFTP"
  },
  {
    "city": "Tampico",
    "country": "Mexico",
    "IATA": "TAM",
    "icao_code": "MMTM"
  },
  {
    "city": "Tamuin",
    "country": "Mexico",
    "IATA": "TSL",
    "icao_code": "MMTN"
  },
  {
    "city": "Tamworth",
    "country": "Australia",
    "IATA": "TMW",
    "icao_code": "YSTW"
  },
  {
    "city": "Tan Tan",
    "country": "Morocco",
    "IATA": "TTA",
    "icao_code": "GMAT"
  },
  {
    "city": "Tandag",
    "country": "Philippines",
    "IATA": "TDG"
  },
  {
    "city": "Tandil",
    "country": "Argentina",
    "IATA": "TDL",
    "icao_code": "SAZT"
  },
  {
    "city": "Tanegashima",
    "country": "Japan",
    "IATA": "TNE",
    "icao_code": "RJFG"
  },
  {
    "city": "Tanga",
    "country": "Tanzania",
    "IATA": "TGT",
    "icao_code": "HTTG"
  },
  {
    "city": "Tangara da Serra",
    "country": "Brazil",
    "IATA": "TGQ"
  },
  {
    "city": "Tanger",
    "country": "Morocco",
    "IATA": "TNG",
    "icao_code": "GMTT"
  },
  {
    "city": "Tanjore",
    "country": "India",
    "IATA": "TJV",
    "icao_code": "VOTJ"
  },
  {
    "city": "Tanjung Balai Karimun",
    "country": "Indonesia",
    "IATA": "TJB",
    "icao_code": "WIBT"
  },
  {
    "city": "Tanjung Pandan",
    "country": "Indonesia",
    "IATA": "TJQ",
    "icao_code": "WIKD"
  },
  {
    "city": "Tanjung Pinang",
    "country": "Indonesia",
    "IATA": "TNJ",
    "icao_code": "WIKN"
  },
  {
    "city": "Tanjung Redep-Borneo Island",
    "country": "Indonesia",
    "IATA": "BEJ",
    "icao_code": "WALK"
  },
  {
    "city": "Tanjung Santan",
    "country": "Indonesia",
    "IATA": "TSX",
    "icao_code": "WALT"
  },
  {
    "city": "Tanjung Selor-Borneo Island",
    "country": "Indonesia",
    "IATA": "TJS",
    "icao_code": "WALG"
  },
  {
    "city": "Tanjung-Borneo Island",
    "country": "Indonesia",
    "IATA": "TJG",
    "icao_code": "WAON"
  },
  {
    "city": "Tanna",
    "country": "Vanuatu",
    "IATA": "TAH",
    "icao_code": "NVVW"
  },
  {
    "city": "Taos",
    "country": "United States",
    "IATA": "TSM",
    "icao_code": "KSKX"
  },
  {
    "city": "Tapachula",
    "country": "Mexico",
    "IATA": "TAP",
    "icao_code": "MMTP"
  },
  {
    "city": "Taplejung",
    "country": "Nepal",
    "IATA": "TPJ",
    "icao_code": "VNTJ"
  },
  {
    "city": "Taraken",
    "country": "Indonesia",
    "IATA": "TRK",
    "icao_code": "WALR"
  },
  {
    "city": "Tarama",
    "country": "Japan",
    "IATA": "TRA",
    "icao_code": "RORT"
  },
  {
    "city": "Tarapoa",
    "country": "Ecuador",
    "IATA": "TPC",
    "icao_code": "SETR"
  },
  {
    "city": "Tarapoto",
    "country": "Peru",
    "IATA": "TPP",
    "icao_code": "SPST"
  },
  {
    "city": "Tarauaca",
    "country": "Brazil",
    "IATA": "TRQ",
    "icao_code": "SBTK"
  },
  {
    "city": "Tarawa",
    "country": "Kiribati",
    "IATA": "TRW",
    "icao_code": "NGTA"
  },
  {
    "city": "Tarbes",
    "country": "France",
    "IATA": "LDE",
    "icao_code": "LFBT"
  },
  {
    "city": "Taree",
    "country": "Australia",
    "IATA": "TRO",
    "icao_code": "YTRE"
  },
  {
    "city": "Tari",
    "country": "Papua New Guinea",
    "IATA": "TIZ",
    "icao_code": "AYTA"
  },
  {
    "city": "Tarija",
    "country": "Bolivia",
    "IATA": "TJA",
    "icao_code": "SLTJ"
  },
  {
    "city": "Tarin Kowt",
    "country": "Afghanistan",
    "IATA": "TII",
    "icao_code": "OATN"
  },
  {
    "city": "Tarko-Sale",
    "country": "Russia",
    "IATA": "TQL",
    "icao_code": "USDS"
  },
  {
    "city": "Taroom",
    "country": "Australia",
    "IATA": "XTO",
    "icao_code": "YTAM"
  },
  {
    "city": "Tartagal",
    "country": "Argentina",
    "IATA": "TTG",
    "icao_code": "SAST"
  },
  {
    "city": "Tartu",
    "country": "Estonia",
    "IATA": "TAY",
    "icao_code": "EETU"
  },
  {
    "city": "Tashkent",
    "country": "Uzbekistan",
    "IATA": "TAS",
    "icao_code": "UTTT"
  },
  {
    "city": "Tasikmalaya",
    "country": "Indonesia",
    "IATA": "TSY",
    "icao_code": "WIAM"
  },
  {
    "city": "Tasiujaq",
    "country": "Canada",
    "IATA": "YTQ",
    "icao_code": "CYTQ"
  },
  {
    "city": "Tatakoto",
    "country": "French Polynesia",
    "IATA": "TKV",
    "icao_code": "NTGO"
  },
  {
    "city": "Tatalina",
    "country": "United States",
    "IATA": "TLJ",
    "icao_code": "PATL"
  },
  {
    "city": "Taubaté",
    "country": "Brazil",
    "IATA": "QHP"
  },
  {
    "city": "Taupo",
    "country": "New Zealand",
    "IATA": "TUO",
    "icao_code": "NZAP"
  },
  {
    "city": "Tauranga",
    "country": "New Zealand",
    "IATA": "TRG",
    "icao_code": "NZTG"
  },
  {
    "city": "Tawau",
    "country": "Malaysia",
    "IATA": "TWU",
    "icao_code": "WBKW"
  },
  {
    "city": "Taytay",
    "country": "Philippines",
    "IATA": "RZP"
  },
  {
    "city": "Tbilisi",
    "country": "Georgia",
    "IATA": "TBS",
    "icao_code": "UGGG"
  },
  {
    "city": "Tchibanga",
    "country": "Gabon",
    "IATA": "TCH",
    "icao_code": "FOOT"
  },
  {
    "city": "Tebessa",
    "country": "Algeria",
    "IATA": "TEE",
    "icao_code": "DABS"
  },
  {
    "city": "Teesside",
    "country": "United Kingdom",
    "IATA": "MME",
    "icao_code": "EGNV"
  },
  {
    "city": "Tefe",
    "country": "Brazil",
    "IATA": "TFF",
    "icao_code": "SBTF"
  },
  {
    "city": "Tegucigalpa",
    "country": "Honduras",
    "IATA": "TGU",
    "icao_code": "MHTG"
  },
  {
    "city": "Teheran",
    "country": "Iran",
    "IATA": "THR",
    "icao_code": "OIII"
  },
  {
    "city": "Tehran",
    "country": "Iran",
    "IATA": "IKA"
  },
  {
    "city": "Tehuacan",
    "country": "Mexico",
    "IATA": "TCN",
    "icao_code": "MMHC"
  },
  {
    "city": "Teixeira de Freitas",
    "country": "Brazil",
    "IATA": "TXF"
  },
  {
    "city": "Tel-aviv",
    "country": "Israel",
    "IATA": "SDV",
    "icao_code": "LLSD"
  },
  {
    "city": "Tel-aviv",
    "country": "Israel",
    "IATA": "TLV",
    "icao_code": "LLBG"
  },
  {
    "city": "Tela",
    "country": "Honduras",
    "IATA": "TEA",
    "icao_code": "MHTE"
  },
  {
    "city": "Telemaco Borba",
    "country": "Brazil",
    "IATA": "TEC",
    "icao_code": "SBTL"
  },
  {
    "city": "Telfer",
    "country": "Australia",
    "IATA": "TEF",
    "icao_code": "YTEF"
  },
  {
    "city": "Teller",
    "country": "United States",
    "IATA": "TLA",
    "icao_code": "PATE"
  },
  {
    "city": "Telluride",
    "country": "United States",
    "IATA": "TEX",
    "icao_code": "KTEX"
  },
  {
    "city": "Temora",
    "country": "Australia",
    "IATA": "TEM",
    "icao_code": "YTEM"
  },
  {
    "city": "Temple",
    "country": "United States",
    "IATA": "TPL",
    "icao_code": "KTPL"
  },
  {
    "city": "Temuco",
    "country": "Chile",
    "IATA": "ZCO",
    "icao_code": "SCQP"
  },
  {
    "city": "Temuco",
    "country": "Chile",
    "IATA": "PZS"
  },
  {
    "city": "Tena",
    "country": "Ecuador",
    "IATA": "TNW"
  },
  {
    "city": "Tenerife",
    "country": "Spain",
    "IATA": "TFN",
    "icao_code": "GCXO"
  },
  {
    "city": "Tenerife",
    "country": "Spain",
    "IATA": "TFS",
    "icao_code": "GCTS"
  },
  {
    "city": "Tengah",
    "country": "Singapore",
    "IATA": "TGA"
  },
  {
    "city": "Tengchong",
    "country": "China",
    "IATA": "TCZ",
    "icao_code": "ZUTC"
  },
  {
    "city": "Tennant Creek",
    "country": "Australia",
    "IATA": "TCA",
    "icao_code": "YTNK"
  },
  {
    "city": "Teofilo Otoni",
    "country": "Brazil",
    "IATA": "TFL",
    "icao_code": "SNTO"
  },
  {
    "city": "Tepic",
    "country": "Mexico",
    "IATA": "TPQ",
    "icao_code": "MMEP"
  },
  {
    "city": "Teresina",
    "country": "Brazil",
    "IATA": "THE",
    "icao_code": "SBTE"
  },
  {
    "city": "Termez",
    "country": "Uzbekistan",
    "IATA": "TMJ",
    "icao_code": "UTST"
  },
  {
    "city": "Ternate",
    "country": "Indonesia",
    "IATA": "TTE",
    "icao_code": "WAMT"
  },
  {
    "city": "Terrace",
    "country": "Canada",
    "IATA": "YXT",
    "icao_code": "CYXT"
  },
  {
    "city": "Teruel",
    "country": "Spain",
    "IATA": "TEV",
    "icao_code": "LETL"
  },
  {
    "city": "Teslin",
    "country": "Canada",
    "IATA": "YZW",
    "icao_code": "CYZW"
  },
  {
    "city": "Tete",
    "country": "Mozambique",
    "IATA": "TET",
    "icao_code": "FQTT"
  },
  {
    "city": "New York, Teterboro NYC",
    "country": "United States",
    "IATA": "TEB",
    "icao_code": "KTEB"
  },
  {
    "city": "Tetiaroa",
    "country": "French Polynesia",
    "IATA": "TTI",
    "icao_code": "NTTE"
  },
  {
    "city": "Tetouan",
    "country": "Morocco",
    "IATA": "TTU",
    "icao_code": "GMTN"
  },
  {
    "city": "Texada",
    "country": "Canada",
    "IATA": "YGB",
    "icao_code": "CYGB"
  },
  {
    "city": "Texarkana",
    "country": "United States",
    "IATA": "TXK",
    "icao_code": "KTXK"
  },
  {
    "city": "Tezpur",
    "country": "India",
    "IATA": "TEZ",
    "icao_code": "VETZ"
  },
  {
    "city": "Tezu",
    "country": "India",
    "IATA": "TEI",
    "icao_code": "VETJ"
  },
  {
    "city": "Thandwe",
    "country": "Burma",
    "IATA": "SNW",
    "icao_code": "VYTD"
  },
  {
    "city": "Thargomindah",
    "country": "Australia",
    "IATA": "XTG",
    "icao_code": "YTGM"
  },
  {
    "city": "The Dalles",
    "country": "United States",
    "IATA": "DLS",
    "icao_code": "KDLS"
  },
  {
    "city": "The Pas",
    "country": "Canada",
    "IATA": "YQD",
    "icao_code": "CYQD"
  },
  {
    "city": "The Valley",
    "country": "Anguilla",
    "IATA": "AXA",
    "icao_code": "TQPF"
  },
  {
    "city": "Theodore",
    "country": "Australia",
    "IATA": "TDR",
    "icao_code": "YTDR"
  },
  {
    "city": "Thessaloniki",
    "country": "Greece",
    "IATA": "SKG",
    "icao_code": "LGTS"
  },
  {
    "city": "Thicket Portage",
    "country": "Canada",
    "IATA": "YTD",
    "icao_code": "CZLQ"
  },
  {
    "city": "Thief River Falls",
    "country": "United States",
    "IATA": "TVF",
    "icao_code": "KTVF"
  },
  {
    "city": "Thimarafushi",
    "country": "Maldives",
    "IATA": "TMF",
    "icao_code": "VRNT"
  },
  {
    "city": "Thimphu",
    "country": "Bhutan",
    "IATA": "PBH",
    "icao_code": "VQPR"
  },
  {
    "city": "Thira",
    "country": "Greece",
    "IATA": "JTR",
    "icao_code": "LGSR"
  },
  {
    "city": "Thisted",
    "country": "Denmark",
    "IATA": "TED",
    "icao_code": "EKTS"
  },
  {
    "city": "Thomasville",
    "country": "United States",
    "IATA": "TVI",
    "icao_code": "KTVI"
  },
  {
    "city": "Thompson",
    "country": "Canada",
    "IATA": "YTH",
    "icao_code": "CYTH"
  },
  {
    "city": "Thorshofn",
    "country": "Iceland",
    "IATA": "THO",
    "icao_code": "BITN"
  },
  {
    "city": "Thule",
    "country": "Greenland",
    "IATA": "THU",
    "icao_code": "BGTL"
  },
  {
    "city": "Thumrait",
    "country": "Oman",
    "IATA": "TTH",
    "icao_code": "OOTH"
  },
  {
    "city": "Thunder Bay",
    "country": "Canada",
    "IATA": "YQT",
    "icao_code": "CYQT"
  },
  {
    "city": "Tianjin",
    "country": "China",
    "IATA": "TSN",
    "icao_code": "ZBTJ"
  },
  {
    "city": "Tianshui",
    "country": "China",
    "IATA": "THQ",
    "icao_code": "ZLTS"
  },
  {
    "city": "Tiaret",
    "country": "Algeria",
    "IATA": "TID",
    "icao_code": "DAOB"
  },
  {
    "city": "Tidjikja",
    "country": "Mauritania",
    "IATA": "TIY",
    "icao_code": "GQND"
  },
  {
    "city": "Tifton",
    "country": "United States",
    "IATA": "TMA",
    "icao_code": "KTMA"
  },
  {
    "city": "Tiga",
    "country": "New Caledonia",
    "IATA": "TGJ",
    "icao_code": "NWWA"
  },
  {
    "city": "Tijuana",
    "country": "Mexico",
    "IATA": "TIJ",
    "icao_code": "MMTJ"
  },
  {
    "city": "Tikehau",
    "country": "French Polynesia",
    "IATA": "TIH",
    "icao_code": "NTGC"
  },
  {
    "city": "Tiko",
    "country": "Cameroon",
    "IATA": "TKC",
    "icao_code": "FKKC"
  },
  {
    "city": "Tiksi",
    "country": "Russia",
    "IATA": "IKS",
    "icao_code": "UEST"
  },
  {
    "city": "Tillamook",
    "country": "United States",
    "IATA": "OTK"
  },
  {
    "city": "Tilrempt",
    "country": "Algeria",
    "IATA": "HRM",
    "icao_code": "DAFH"
  },
  {
    "city": "Timaru",
    "country": "New Zealand",
    "IATA": "TIU",
    "icao_code": "NZTU"
  },
  {
    "city": "Timika",
    "country": "Indonesia",
    "IATA": "TIM",
    "icao_code": "WABP"
  },
  {
    "city": "Timimoun",
    "country": "Algeria",
    "IATA": "TMX",
    "icao_code": "DAUT"
  },
  {
    "city": "Timisoara",
    "country": "Romania",
    "IATA": "TSR",
    "icao_code": "LRTR"
  },
  {
    "city": "Timmins",
    "country": "Canada",
    "IATA": "YTS",
    "icao_code": "CYTS"
  },
  {
    "city": "Tin City",
    "country": "United States",
    "IATA": "TNC",
    "icao_code": "PATC"
  },
  {
    "city": "Tindouf",
    "country": "Algeria",
    "IATA": "TIN",
    "icao_code": "DAOF"
  },
  {
    "city": "Tingo Maria",
    "country": "Peru",
    "IATA": "TGI",
    "icao_code": "SPGM"
  },
  {
    "city": "Tioman",
    "country": "Malaysia",
    "IATA": "TOD",
    "icao_code": "WMBT"
  },
  {
    "city": "Tippi",
    "country": "Ethiopia",
    "IATA": "TIE",
    "icao_code": "HATP"
  },
  {
    "city": "Tiputini",
    "country": "Ecuador",
    "IATA": "TPN",
    "icao_code": "SETI"
  },
  {
    "city": "Tirana",
    "country": "Albania",
    "IATA": "TIA",
    "icao_code": "LATI"
  },
  {
    "city": "Tiree",
    "country": "United Kingdom",
    "IATA": "TRE",
    "icao_code": "EGPU"
  },
  {
    "city": "Tirgu Mures",
    "country": "Romania",
    "IATA": "TGM",
    "icao_code": "LRTM"
  },
  {
    "city": "Tiruchirappalli",
    "country": "India",
    "IATA": "TRZ",
    "icao_code": "VOTR"
  },
  {
    "city": "Tirupeti",
    "country": "India",
    "IATA": "TIR",
    "icao_code": "VOTP"
  },
  {
    "city": "Titusville",
    "country": "United States",
    "IATA": "TIX",
    "icao_code": "KTIX"
  },
  {
    "city": "Tivat",
    "country": "Montenegro",
    "IATA": "TIV",
    "icao_code": "LYTV"
  },
  {
    "city": "Tlemcen",
    "country": "Algeria",
    "IATA": "TLM",
    "icao_code": "DAON"
  },
  {
    "city": "Toamasina",
    "country": "Madagascar",
    "IATA": "TMM",
    "icao_code": "FMMT"
  },
  {
    "city": "Tobruk",
    "country": "Libya",
    "IATA": "TOB",
    "icao_code": "HLGN"
  },
  {
    "city": "Toccoa",
    "country": "United States",
    "IATA": "TOC",
    "icao_code": "KTOC"
  },
  {
    "city": "Tocopilla",
    "country": "Chile",
    "IATA": "TOQ"
  },
  {
    "city": "Tofino",
    "country": "Canada",
    "IATA": "YAZ",
    "icao_code": "CYAZ"
  },
  {
    "city": "Togiak Village",
    "country": "United States",
    "IATA": "TOG",
    "icao_code": "PATG"
  },
  {
    "city": "Tok",
    "country": "United States",
    "IATA": "TKJ",
    "icao_code": "PATJ"
  },
  {
    "city": "Tokat",
    "country": "Turkey",
    "IATA": "TJK",
    "icao_code": "LTAW"
  },
  {
    "city": "Toksook Bay",
    "country": "United States",
    "IATA": "OOK",
    "icao_code": "PAOO"
  },
  {
    "city": "Tokua",
    "country": "Papua New Guinea",
    "IATA": "RAB",
    "icao_code": "AYTK"
  },
  {
    "city": "Tokunoshima",
    "country": "Japan",
    "IATA": "TKN",
    "icao_code": "RJKN"
  },
  {
    "city": "Tokushima",
    "country": "Japan",
    "IATA": "TKS",
    "icao_code": "RJOS"
  },
  {
    "city": "Tokyo",
    "country": "Japan",
    "IATA": "HND",
    "icao_code": "RJTT"
  },
  {
    "city": "Tokyo",
    "country": "Japan",
    "IATA": "NRT",
    "icao_code": "RJAA"
  },
  {
    "city": "Tola",
    "country": "Nicaragua",
    "IATA": "ECI"
  },
  {
    "city": "Tolagnaro",
    "country": "Madagascar",
    "IATA": "FTU",
    "icao_code": "FMSD"
  },
  {
    "city": "Toledo",
    "country": "Brazil",
    "IATA": "TOW",
    "icao_code": "SBTD"
  },
  {
    "city": "Toledo",
    "country": "United States",
    "IATA": "TOL",
    "icao_code": "KTOL"
  },
  {
    "city": "Toli-Toli",
    "country": "Indonesia",
    "IATA": "TLI"
  },
  {
    "city": "Toliara",
    "country": "Madagascar",
    "IATA": "TLE",
    "icao_code": "FMST"
  },
  {
    "city": "Tolu",
    "country": "Colombia",
    "IATA": "TLU",
    "icao_code": "SKTL"
  },
  {
    "city": "Toluca",
    "country": "Mexico",
    "IATA": "TLC",
    "icao_code": "MMTO"
  },
  {
    "city": "Tomanggong",
    "country": "Malaysia",
    "IATA": "TMG",
    "icao_code": "WBKM"
  },
  {
    "city": "Tombouctou",
    "country": "Mali",
    "IATA": "TOM",
    "icao_code": "GATB"
  },
  {
    "city": "Tomsk",
    "country": "Russia",
    "IATA": "TOF",
    "icao_code": "UNTT"
  },
  {
    "city": "Tongatapu",
    "country": "Tonga",
    "IATA": "TBU",
    "icao_code": "NFTF"
  },
  {
    "city": "Tonghua",
    "country": "China",
    "IATA": "TNH",
    "icao_code": "ZYTN"
  },
  {
    "city": "Tongliao",
    "country": "China",
    "IATA": "TGO",
    "icao_code": "ZBTL"
  },
  {
    "city": "Tongoa Island",
    "country": "Vanuatu",
    "IATA": "TGH",
    "icao_code": "NVST"
  },
  {
    "city": "Tongren",
    "country": "China",
    "IATA": "TEN",
    "icao_code": "ZUTR"
  },
  {
    "city": "Tonopah",
    "country": "United States",
    "IATA": "TPH",
    "icao_code": "KTPH"
  },
  {
    "city": "Tonopah",
    "country": "United States",
    "IATA": "XSD",
    "icao_code": "KTNX"
  },
  {
    "city": "Toowoomba",
    "country": "Australia",
    "IATA": "WTB",
    "icao_code": "YBWW"
  },
  {
    "city": "Toowoomba",
    "country": "Australia",
    "IATA": "TWB",
    "icao_code": "YTWB"
  },
  {
    "city": "Topeka",
    "country": "United States",
    "IATA": "TOP",
    "icao_code": "KTOP"
  },
  {
    "city": "Topeka",
    "country": "United States",
    "IATA": "FOE",
    "icao_code": "KFOE"
  },
  {
    "city": "Topel",
    "country": "Turkey",
    "IATA": "KCO",
    "icao_code": "LTBQ"
  },
  {
    "city": "Toranagallu",
    "country": "India",
    "IATA": "VDY",
    "icao_code": "VOJV"
  },
  {
    "city": "Torino",
    "country": "Italy",
    "IATA": "TRN",
    "icao_code": "LIMF"
  },
  {
    "city": "Toronto",
    "country": "Canada",
    "IATA": "YZD"
  },
  {
    "city": "Toronto",
    "country": "Canada",
    "IATA": "YYZ",
    "icao_code": "CYYZ"
  },
  {
    "city": "Toronto",
    "country": "Canada",
    "IATA": "YTZ",
    "icao_code": "CYTZ"
  },
  {
    "city": "Toronto",
    "country": "Canada",
    "IATA": "YKZ",
    "icao_code": "CYKZ"
  },
  {
    "city": "Torrance",
    "country": "United States",
    "IATA": "TOA",
    "icao_code": "KTOA"
  },
  {
    "city": "Torreon",
    "country": "Mexico",
    "IATA": "TRC",
    "icao_code": "MMTC"
  },
  {
    "city": "Torsby",
    "country": "Sweden",
    "IATA": "TYF",
    "icao_code": "ESST"
  },
  {
    "city": "Tortola",
    "country": "British Virgin Islands",
    "IATA": "EIS",
    "icao_code": "TUPJ"
  },
  {
    "city": "Tortoli",
    "country": "Italy",
    "IATA": "TTB",
    "icao_code": "LIET"
  },
  {
    "city": "Totegegie",
    "country": "French Polynesia",
    "IATA": "GMR",
    "icao_code": "NTGJ"
  },
  {
    "city": "Totness",
    "country": "Suriname",
    "IATA": "TOT"
  },
  {
    "city": "Tottori",
    "country": "Japan",
    "IATA": "TTJ",
    "icao_code": "RJOR"
  },
  {
    "city": "Touggourt",
    "country": "Algeria",
    "IATA": "TGR",
    "icao_code": "DAUK"
  },
  {
    "city": "Touho",
    "country": "New Caledonia",
    "IATA": "TOU",
    "icao_code": "NWWU"
  },
  {
    "city": "Toulouse",
    "country": "France",
    "IATA": "TLS",
    "icao_code": "LFBO"
  },
  {
    "city": "Tours",
    "country": "France",
    "IATA": "TUF",
    "icao_code": "LFOT"
  },
  {
    "city": "Toussous-le-noble",
    "country": "France",
    "IATA": "TNF",
    "icao_code": "LFPN"
  },
  {
    "city": "Townsville",
    "country": "Australia",
    "IATA": "TSV",
    "icao_code": "YBTL"
  },
  {
    "city": "Toyama",
    "country": "Japan",
    "IATA": "TOY",
    "icao_code": "RJNT"
  },
  {
    "city": "Toyooka",
    "country": "Japan",
    "IATA": "TJH",
    "icao_code": "RJBT"
  },
  {
    "city": "Tozeur",
    "country": "Tunisia",
    "IATA": "TOE",
    "icao_code": "DTTZ"
  },
  {
    "city": "Trabzon",
    "country": "Turkey",
    "IATA": "TZX",
    "icao_code": "LTCG"
  },
  {
    "city": "Trail",
    "country": "Canada",
    "IATA": "YZZ",
    "icao_code": "CAD4"
  },
  {
    "city": "Trang",
    "country": "Thailand",
    "IATA": "TST",
    "icao_code": "VTST"
  },
  {
    "city": "Trapani",
    "country": "Italy",
    "IATA": "TPS",
    "icao_code": "LICT"
  },
  {
    "city": "Trat",
    "country": "Thailand",
    "IATA": "TDX",
    "icao_code": "VTBO"
  },
  {
    "city": "Traverse City",
    "country": "United States",
    "IATA": "TVC",
    "icao_code": "KTVC"
  },
  {
    "city": "Treasure Cay",
    "country": "Bahamas",
    "IATA": "TCB",
    "icao_code": "MYAT"
  },
  {
    "city": "Trelew",
    "country": "Argentina",
    "IATA": "REL",
    "icao_code": "SAVT"
  },
  {
    "city": "Trenton",
    "country": "United States",
    "IATA": "TTN",
    "icao_code": "KTTN"
  },
  {
    "city": "Trenton",
    "country": "Canada",
    "IATA": "YTR",
    "icao_code": "CYTR"
  },
  {
    "city": "Tres Arroyos",
    "country": "Argentina",
    "IATA": "OYO",
    "icao_code": "SAZH"
  },
  {
    "city": "Tres Esquinas",
    "country": "Colombia",
    "IATA": "TQS",
    "icao_code": "SKTQ"
  },
  {
    "city": "Tres Lagoas",
    "country": "Brazil",
    "IATA": "TJL",
    "icao_code": "SSTL"
  },
  {
    "city": "Treviso",
    "country": "Italy",
    "IATA": "TSF",
    "icao_code": "LIPH"
  },
  {
    "city": "Trinciomalee",
    "country": "Sri Lanka",
    "IATA": "TRR",
    "icao_code": "VCCT"
  },
  {
    "city": "Trinidad",
    "country": "Cuba",
    "IATA": "TND",
    "icao_code": "MUTD"
  },
  {
    "city": "Trinidad",
    "country": "Bolivia",
    "IATA": "TDD",
    "icao_code": "SLTR"
  },
  {
    "city": "Tripoli",
    "country": "Libya",
    "IATA": "MJI",
    "icao_code": "HLLM"
  },
  {
    "city": "Tripoli",
    "country": "Libya",
    "IATA": "TIP",
    "icao_code": "HLLT"
  },
  {
    "city": "Trivandrum",
    "country": "India",
    "IATA": "TRV",
    "icao_code": "VOTV"
  },
  {
    "city": "Trois Rivieres",
    "country": "Canada",
    "IATA": "YRQ",
    "icao_code": "CYRQ"
  },
  {
    "city": "Trollhattan",
    "country": "Sweden",
    "IATA": "THN",
    "icao_code": "ESGT"
  },
  {
    "city": "Trondheim",
    "country": "Norway",
    "IATA": "TRD",
    "icao_code": "ENVA"
  },
  {
    "city": "Troutdale",
    "country": "United States",
    "IATA": "TTD",
    "icao_code": "KTTD"
  },
  {
    "city": "Troy",
    "country": "United States",
    "IATA": "TOI",
    "icao_code": "KTOI"
  },
  {
    "city": "Truckee",
    "country": "United States",
    "IATA": "TKF",
    "icao_code": "KTRK"
  },
  {
    "city": "Trujillo",
    "country": "Peru",
    "IATA": "TRU",
    "icao_code": "SPRU"
  },
  {
    "city": "Trujillo",
    "country": "Honduras",
    "IATA": "TJI"
  },
  {
    "city": "Truth Or Consequences",
    "country": "United States",
    "IATA": "TCS",
    "icao_code": "KTCS"
  },
  {
    "city": "Tselinograd",
    "country": "Kazakhstan",
    "IATA": "TSE",
    "icao_code": "UACC"
  },
  {
    "city": "Tshikapa",
    "country": "Congo (Kinshasa)",
    "IATA": "TSH",
    "icao_code": "FZUK"
  },
  {
    "city": "Tsiroanomandidy",
    "country": "Madagascar",
    "IATA": "WTS"
  },
  {
    "city": "Tsumeb",
    "country": "Namibia",
    "IATA": "TSB",
    "icao_code": "FYTM"
  },
  {
    "city": "Tsushima",
    "country": "Japan",
    "IATA": "TSJ",
    "icao_code": "RJDT"
  },
  {
    "city": "Tubuai",
    "country": "French Polynesia",
    "IATA": "TUB",
    "icao_code": "NTAT"
  },
  {
    "city": "Tucson",
    "country": "United States",
    "IATA": "AVW",
    "icao_code": "KAVQ"
  },
  {
    "city": "Tucson",
    "country": "United States",
    "IATA": "TUS",
    "icao_code": "KTUS"
  },
  {
    "city": "Tucson",
    "country": "United States",
    "IATA": "DMA",
    "icao_code": "KDMA"
  },
  {
    "city": "Tucuman",
    "country": "Argentina",
    "IATA": "TUC",
    "icao_code": "SANT"
  },
  {
    "city": "Tucumcari",
    "country": "United States",
    "IATA": "TCC",
    "icao_code": "KTCC"
  },
  {
    "city": "Tucupita",
    "country": "Venezuela",
    "IATA": "TUV",
    "icao_code": "SVTC"
  },
  {
    "city": "Tucurui",
    "country": "Brazil",
    "IATA": "TUR",
    "icao_code": "SBTU"
  },
  {
    "city": "Tuguegarao",
    "country": "Philippines",
    "IATA": "TUG",
    "icao_code": "RPUT"
  },
  {
    "city": "Tuktoyaktuk",
    "country": "Canada",
    "IATA": "YUB",
    "icao_code": "CYUB"
  },
  {
    "city": "Tulcan",
    "country": "Ecuador",
    "IATA": "TUA",
    "icao_code": "SETU"
  },
  {
    "city": "Tulcea",
    "country": "Romania",
    "IATA": "TCE",
    "icao_code": "LRTC"
  },
  {
    "city": "Tuli Lodge",
    "country": "Botswana",
    "IATA": "TLD",
    "icao_code": "FBTL"
  },
  {
    "city": "Tulita",
    "country": "Canada",
    "IATA": "ZFN",
    "icao_code": "CZFN"
  },
  {
    "city": "Tulsa",
    "country": "United States",
    "IATA": "RVS",
    "icao_code": "KRVS"
  },
  {
    "city": "Tulsa",
    "country": "United States",
    "IATA": "TUL",
    "icao_code": "KTUL"
  },
  {
    "city": "Tulua",
    "country": "Colombia",
    "IATA": "ULQ",
    "icao_code": "SKUL"
  },
  {
    "city": "Tumaco",
    "country": "Colombia",
    "IATA": "TCO",
    "icao_code": "SKCO"
  },
  {
    "city": "Tumbes",
    "country": "Peru",
    "IATA": "TBP",
    "icao_code": "SPME"
  },
  {
    "city": "Tumeremo",
    "country": "Venezuela",
    "IATA": "TMO",
    "icao_code": "SVTM"
  },
  {
    "city": "Tumling Tar",
    "country": "Nepal",
    "IATA": "TMI",
    "icao_code": "VNTR"
  },
  {
    "city": "Tunica",
    "country": "United States",
    "IATA": "UTM",
    "icao_code": "KUTA"
  },
  {
    "city": "Tunis",
    "country": "Tunisia",
    "IATA": "TUN",
    "icao_code": "DTTA"
  },
  {
    "city": "Tupelo",
    "country": "United States",
    "IATA": "TUP",
    "icao_code": "KTUP"
  },
  {
    "city": "Turaif",
    "country": "Saudi Arabia",
    "IATA": "TUI",
    "icao_code": "OETR"
  },
  {
    "city": "Turbat",
    "country": "Pakistan",
    "IATA": "TUK",
    "icao_code": "OPTU"
  },
  {
    "city": "Tureia",
    "country": "French Polynesia",
    "IATA": "ZTA",
    "icao_code": "NTGY"
  },
  {
    "city": "Turku",
    "country": "Finland",
    "IATA": "TKU",
    "icao_code": "EFTU"
  },
  {
    "city": "Turpan",
    "country": "China",
    "IATA": "TLQ"
  },
  {
    "city": "Turtle Island",
    "country": "Fiji",
    "IATA": "TTL"
  },
  {
    "city": "Turukhansk",
    "country": "Russia",
    "IATA": "THX",
    "icao_code": "UOTT"
  },
  {
    "city": "Tuscaloosa AL",
    "country": "United States",
    "IATA": "TCL",
    "icao_code": "KTCL"
  },
  {
    "city": "Tuxtla Gutierrez",
    "country": "Mexico",
    "IATA": "TGZ",
    "icao_code": "MMTG"
  },
  {
    "city": "Tuy Hoa",
    "country": "Vietnam",
    "IATA": "TBB",
    "icao_code": "VVTH"
  },
  {
    "city": "Tver",
    "country": "Russia",
    "IATA": "KLD",
    "icao_code": "UUEM"
  },
  {
    "city": "Twin Falls",
    "country": "United States",
    "IATA": "TWF",
    "icao_code": "KTWF"
  },
  {
    "city": "Tyler",
    "country": "United States",
    "IATA": "TYR",
    "icao_code": "KTYR"
  },
  {
    "city": "Tyumen",
    "country": "Russia",
    "IATA": "TJM",
    "icao_code": "USTR"
  },
  {
    "city": "Tzaneen",
    "country": "South Africa",
    "IATA": "LTA",
    "icao_code": "FATZ"
  },
  {
    "city": "Tête-à-la-Baleine",
    "country": "Canada",
    "IATA": "ZTB",
    "icao_code": "CTB6"
  },
  {
    "city": "Ua Huka",
    "country": "French Polynesia",
    "IATA": "UAH",
    "icao_code": "NTMU"
  },
  {
    "city": "Ua Pou",
    "country": "French Polynesia",
    "IATA": "UAP",
    "icao_code": "NTMP"
  },
  {
    "city": "Ubatuba",
    "country": "Brazil",
    "IATA": "UBT",
    "icao_code": "SDUB"
  },
  {
    "city": "Uberaba",
    "country": "Brazil",
    "IATA": "UBA",
    "icao_code": "SBUR"
  },
  {
    "city": "Uberlandia",
    "country": "Brazil",
    "IATA": "UDI",
    "icao_code": "SBUL"
  },
  {
    "city": "Ubon Ratchathani",
    "country": "Thailand",
    "IATA": "UBP",
    "icao_code": "VTUU"
  },
  {
    "city": "Udaipur",
    "country": "India",
    "IATA": "UDR",
    "icao_code": "VAUD"
  },
  {
    "city": "Udon Thani",
    "country": "Thailand",
    "IATA": "UTH",
    "icao_code": "VTUD"
  },
  {
    "city": "Ufa",
    "country": "Russia",
    "IATA": "UFA",
    "icao_code": "UWUU"
  },
  {
    "city": "Uige",
    "country": "Angola",
    "IATA": "UGO",
    "icao_code": "FNUG"
  },
  {
    "city": "Ujae Atoll",
    "country": "Marshall Islands",
    "IATA": "UJE"
  },
  {
    "city": "Ujung Pandang",
    "country": "Indonesia",
    "IATA": "UPG",
    "icao_code": "WAAA"
  },
  {
    "city": "Ukhta",
    "country": "Russia",
    "IATA": "UCT",
    "icao_code": "UUYH"
  },
  {
    "city": "Ukiah",
    "country": "United States",
    "IATA": "UKI",
    "icao_code": "KUKI"
  },
  {
    "city": "Ukunda",
    "country": "Kenya",
    "IATA": "UKA",
    "icao_code": "HKUK"
  },
  {
    "city": "Ulaangom",
    "country": "Mongolia",
    "IATA": "ULO",
    "icao_code": "ZMUG"
  },
  {
    "city": "Ulan Bator",
    "country": "Mongolia",
    "IATA": "ULN",
    "icao_code": "ZMUB"
  },
  {
    "city": "Ulan-ude",
    "country": "Russia",
    "IATA": "UUD",
    "icao_code": "UIUU"
  },
  {
    "city": "Ulanhot",
    "country": "China",
    "IATA": "HLH",
    "icao_code": "ZBUL"
  },
  {
    "city": "Ulawa",
    "country": "Solomon Islands",
    "IATA": "RNA",
    "icao_code": "AGAR"
  },
  {
    "city": "Uliastai",
    "country": "Mongolia",
    "IATA": "ULZ"
  },
  {
    "city": "Ulsan",
    "country": "South Korea",
    "IATA": "USN",
    "icao_code": "RKPU"
  },
  {
    "city": "Ulundi",
    "country": "South Africa",
    "IATA": "ULD",
    "icao_code": "FAUL"
  },
  {
    "city": "Uluru",
    "country": "Australia",
    "IATA": "AYQ",
    "icao_code": "YAYE"
  },
  {
    "city": "Ulyanovsk",
    "country": "Russia",
    "IATA": "ULV",
    "icao_code": "UWLL"
  },
  {
    "city": "Ulyanovsk",
    "country": "Russia",
    "IATA": "ULY",
    "icao_code": "UWLW"
  },
  {
    "city": "Umea",
    "country": "Sweden",
    "IATA": "UME",
    "icao_code": "ESNU"
  },
  {
    "city": "Umiujaq",
    "country": "Canada",
    "IATA": "YUD",
    "icao_code": "CYMU"
  },
  {
    "city": "Umtata",
    "country": "South Africa",
    "IATA": "UTT",
    "icao_code": "FAUT"
  },
  {
    "city": "Umuarama",
    "country": "Brazil",
    "IATA": "UMU",
    "icao_code": "SSUM"
  },
  {
    "city": "Una",
    "country": "Brazil",
    "IATA": "UNA",
    "icao_code": "SBTC"
  },
  {
    "city": "Unalakleet",
    "country": "United States",
    "IATA": "UNK",
    "icao_code": "PAUN"
  },
  {
    "city": "Unalaska",
    "country": "United States",
    "IATA": "DUT",
    "icao_code": "PADU"
  },
  {
    "city": "Union Island",
    "country": "Saint Vincent and the Grenadines",
    "IATA": "UNI"
  },
  {
    "city": "Unst",
    "country": "United Kingdom",
    "IATA": "UNT"
  },
  {
    "city": "Upala",
    "country": "Costa Rica",
    "IATA": "UPL",
    "icao_code": "MRUP"
  },
  {
    "city": "Upernavik",
    "country": "Greenland",
    "IATA": "JUV",
    "icao_code": "BGUK"
  },
  {
    "city": "Upington",
    "country": "South Africa",
    "IATA": "UTN",
    "icao_code": "FAUP"
  },
  {
    "city": "Upland",
    "country": "United States",
    "IATA": "CCB",
    "icao_code": "KCCB"
  },
  {
    "city": "Uraj",
    "country": "Russia",
    "IATA": "URJ",
    "icao_code": "USHU"
  },
  {
    "city": "Uralsk",
    "country": "Kazakhstan",
    "IATA": "URA",
    "icao_code": "UARR"
  },
  {
    "city": "Uranium City",
    "country": "Canada",
    "IATA": "YBE",
    "icao_code": "CYBE"
  },
  {
    "city": "Urgench",
    "country": "Uzbekistan",
    "IATA": "UGC",
    "icao_code": "UTNU"
  },
  {
    "city": "Uromiyeh",
    "country": "Iran",
    "IATA": "OMH",
    "icao_code": "OITR"
  },
  {
    "city": "Uruapan",
    "country": "Mexico",
    "IATA": "UPN",
    "icao_code": "MMPN"
  },
  {
    "city": "Uruguaiana",
    "country": "Brazil",
    "IATA": "URG",
    "icao_code": "SBUG"
  },
  {
    "city": "Urumqi",
    "country": "China",
    "IATA": "URC",
    "icao_code": "ZWWW"
  },
  {
    "city": "Urzhar",
    "country": "Kazakhstan",
    "IATA": "UZR"
  },
  {
    "city": "Usak",
    "country": "Turkey",
    "IATA": "USQ",
    "icao_code": "LTBO"
  },
  {
    "city": "Ushuaia",
    "country": "Argentina",
    "IATA": "USH",
    "icao_code": "SAWH"
  },
  {
    "city": "Usinsk",
    "country": "Russia",
    "IATA": "USK",
    "icao_code": "UUYS"
  },
  {
    "city": "Ust Ilimsk",
    "country": "Russia",
    "IATA": "UIK",
    "icao_code": "UIBS"
  },
  {
    "city": "Ust Kamenogorsk",
    "country": "Kazakhstan",
    "IATA": "UKK",
    "icao_code": "UASK"
  },
  {
    "city": "Ust-Kut",
    "country": "Russia",
    "IATA": "UKX",
    "icao_code": "UITT"
  },
  {
    "city": "Ust-Kuyga",
    "country": "Russia",
    "IATA": "UKG"
  },
  {
    "city": "Ust-Maya",
    "country": "Russia",
    "IATA": "UMS"
  },
  {
    "city": "Ust-Nera",
    "country": "Russia",
    "IATA": "USR"
  },
  {
    "city": "Ust-Tsylma",
    "country": "Russia",
    "IATA": "UTS",
    "icao_code": "UUYX"
  },
  {
    "city": "Utila",
    "country": "Honduras",
    "IATA": "UII",
    "icao_code": "MHUT"
  },
  {
    "city": "Utirik Island",
    "country": "Marshall Islands",
    "IATA": "UTK"
  },
  {
    "city": "Utti",
    "country": "Finland",
    "IATA": "UTI",
    "icao_code": "EFUT"
  },
  {
    "city": "Uummannaq",
    "country": "Greenland",
    "IATA": "UMD",
    "icao_code": "BGUM"
  },
  {
    "city": "Uummannaq",
    "country": "Greenland",
    "IATA": "JQA",
    "icao_code": "BGUQ"
  },
  {
    "city": "Uvalde",
    "country": "United States",
    "IATA": "UVA"
  },
  {
    "city": "Uyo",
    "country": "Nigeria",
    "IATA": "QUO",
    "icao_code": "DNAI"
  },
  {
    "city": "Uyuni",
    "country": "Bolivia",
    "IATA": "UYU"
  },
  {
    "city": "Uzhgorod",
    "country": "Ukraine",
    "IATA": "UDJ",
    "icao_code": "UKLU"
  },
  {
    "city": "Vaasa",
    "country": "Finland",
    "IATA": "VAA",
    "icao_code": "EFVA"
  },
  {
    "city": "Vadsø",
    "country": "Norway",
    "IATA": "VDS",
    "icao_code": "ENVD"
  },
  {
    "city": "Vagar",
    "country": "Faroe Islands",
    "IATA": "FAE",
    "icao_code": "EKVG"
  },
  {
    "city": "Vahitahi",
    "country": "French Polynesia",
    "IATA": "VHZ",
    "icao_code": "NTUV"
  },
  {
    "city": "Vail",
    "country": "United States",
    "IATA": "EGE",
    "icao_code": "KEGE"
  },
  {
    "city": "Val D'or",
    "country": "Canada",
    "IATA": "YVO",
    "icao_code": "CYVO"
  },
  {
    "city": "Valdez",
    "country": "United States",
    "IATA": "VDZ",
    "icao_code": "PAVD"
  },
  {
    "city": "Valdivia",
    "country": "Chile",
    "IATA": "ZAL",
    "icao_code": "SCVD"
  },
  {
    "city": "Valdosta",
    "country": "United States",
    "IATA": "VLD",
    "icao_code": "KVLD"
  },
  {
    "city": "Valdosta",
    "country": "United States",
    "IATA": "VAD"
  },
  {
    "city": "Valenca",
    "country": "Brazil",
    "IATA": "VAL",
    "icao_code": "SNVB"
  },
  {
    "city": "Valence",
    "country": "France",
    "IATA": "VAF",
    "icao_code": "LFLU"
  },
  {
    "city": "Valencia",
    "country": "Venezuela",
    "IATA": "VLN",
    "icao_code": "SVVA"
  },
  {
    "city": "Valencia",
    "country": "Spain",
    "IATA": "VLC",
    "icao_code": "LEVC"
  },
  {
    "city": "Valenciennes",
    "country": "France",
    "IATA": "XVS"
  },
  {
    "city": "Valentine",
    "country": "United States",
    "IATA": "VTN",
    "icao_code": "KVTN"
  },
  {
    "city": "Valera",
    "country": "Venezuela",
    "IATA": "VLV",
    "icao_code": "SVVL"
  },
  {
    "city": "Valesdir",
    "country": "Vanuatu",
    "IATA": "VLS",
    "icao_code": "NVSV"
  },
  {
    "city": "Valkenburg",
    "country": "Netherlands",
    "IATA": "LID"
  },
  {
    "city": "Valladolid",
    "country": "Spain",
    "IATA": "VLL",
    "icao_code": "LEVD"
  },
  {
    "city": "Valle De La Pascua",
    "country": "Venezuela",
    "IATA": "VDP",
    "icao_code": "SVVP"
  },
  {
    "city": "Valledupar",
    "country": "Colombia",
    "IATA": "VUP",
    "icao_code": "SKVP"
  },
  {
    "city": "Vallenar",
    "country": "Chile",
    "IATA": "VLR",
    "icao_code": "SCLL"
  },
  {
    "city": "Valparaiso",
    "country": "United States",
    "IATA": "VPS",
    "icao_code": "KVPS"
  },
  {
    "city": "Valparaiso IN",
    "country": "United States",
    "IATA": "VPZ",
    "icao_code": "KVPZ"
  },
  {
    "city": "Van",
    "country": "Turkey",
    "IATA": "VAN",
    "icao_code": "LTCI"
  },
  {
    "city": "Los Angeles, Van Nuys",
    "country": "United States",
    "IATA": "VNY",
    "icao_code": "KVNY"
  },
  {
    "city": "Vancouver",
    "country": "Canada",
    "IATA": "CXH",
    "icao_code": "CYHC"
  },
  {
    "city": "Vancouver",
    "country": "Canada",
    "IATA": "YVR",
    "icao_code": "CYVR"
  },
  {
    "city": "Vanimo",
    "country": "Papua New Guinea",
    "IATA": "VAI",
    "icao_code": "AYVN"
  },
  {
    "city": "Vannes",
    "country": "France",
    "IATA": "VNE",
    "icao_code": "LFRV"
  },
  {
    "city": "Vanua Balavu",
    "country": "Fiji",
    "IATA": "VBV",
    "icao_code": "NFVB"
  },
  {
    "city": "Varadero",
    "country": "Cuba",
    "IATA": "VRA",
    "icao_code": "MUVR"
  },
  {
    "city": "Varanasi",
    "country": "India",
    "IATA": "VNS",
    "icao_code": "VIBN"
  },
  {
    "city": "Varginha",
    "country": "Brazil",
    "IATA": "VAG",
    "icao_code": "SBVG"
  },
  {
    "city": "Varkaus",
    "country": "Finland",
    "IATA": "VRK",
    "icao_code": "EFVR"
  },
  {
    "city": "Varna",
    "country": "Bulgaria",
    "IATA": "VAR",
    "icao_code": "LBWN"
  },
  {
    "city": "Vasteras",
    "country": "Sweden",
    "IATA": "VST",
    "icao_code": "ESOW"
  },
  {
    "city": "Vava'u",
    "country": "Tonga",
    "IATA": "VAV",
    "icao_code": "NFTV"
  },
  {
    "city": "Vaxjo",
    "country": "Sweden",
    "IATA": "VXO",
    "icao_code": "ESMX"
  },
  {
    "city": "Velikiye Luki",
    "country": "Russia",
    "IATA": "VLU",
    "icao_code": "ULOL"
  },
  {
    "city": "Veliky Ustyug",
    "country": "Russia",
    "IATA": "VUS",
    "icao_code": "ULWU"
  },
  {
    "city": "Venetie",
    "country": "United States",
    "IATA": "VEE",
    "icao_code": "PAVE"
  },
  {
    "city": "Venice",
    "country": "United States",
    "IATA": "VNC",
    "icao_code": "KVNC"
  },
  {
    "city": "Venice",
    "country": "Italy",
    "IATA": "VCE",
    "icao_code": "LIPZ"
  },
  {
    "city": "Ventspils",
    "country": "Latvia",
    "IATA": "VNT",
    "icao_code": "EVVA"
  },
  {
    "city": "Vera Cruz",
    "country": "Mexico",
    "IATA": "VER",
    "icao_code": "MMVR"
  },
  {
    "city": "Verdun",
    "country": "France",
    "IATA": "MVV"
  },
  {
    "city": "Verkhnevilyuysk",
    "country": "Russia",
    "IATA": "VHV"
  },
  {
    "city": "Vermillion",
    "country": "Canada",
    "IATA": "YVG",
    "icao_code": "CYVG"
  },
  {
    "city": "Vernal",
    "country": "United States",
    "IATA": "VEL",
    "icao_code": "KVEL"
  },
  {
    "city": "Vernon",
    "country": "Canada",
    "IATA": "YVE",
    "icao_code": "CYVK"
  },
  {
    "city": "Vero Beach",
    "country": "United States",
    "IATA": "VRB",
    "icao_code": "KVRB"
  },
  {
    "city": "Vesivehmaa",
    "country": "Finland",
    "IATA": "QLF"
  },
  {
    "city": "Vestmannaeyjar",
    "country": "Iceland",
    "IATA": "VEY",
    "icao_code": "BIVM"
  },
  {
    "city": "Vicenza",
    "country": "Italy",
    "IATA": "VIC",
    "icao_code": "LIPT"
  },
  {
    "city": "Vichy",
    "country": "France",
    "IATA": "VHY",
    "icao_code": "LFLV"
  },
  {
    "city": "Victoria",
    "country": "Chile",
    "IATA": "ZIC",
    "icao_code": "SCTO"
  },
  {
    "city": "Victoria",
    "country": "United States",
    "IATA": "VCT",
    "icao_code": "KVCT"
  },
  {
    "city": "Victoria",
    "country": "Canada",
    "IATA": "YWH",
    "icao_code": "CYWH"
  },
  {
    "city": "Victoria",
    "country": "Canada",
    "IATA": "YYJ",
    "icao_code": "CYYJ"
  },
  {
    "city": "Victoria Falls",
    "country": "Zimbabwe",
    "IATA": "VFA",
    "icao_code": "FVFA"
  },
  {
    "city": "Victoria River Downs",
    "country": "Australia",
    "IATA": "VCD",
    "icao_code": "YVRD"
  },
  {
    "city": "Victorville",
    "country": "United States",
    "IATA": "VCV",
    "icao_code": "KVCV"
  },
  {
    "city": "Vidalia",
    "country": "United States",
    "IATA": "VDI"
  },
  {
    "city": "Viedma",
    "country": "Argentina",
    "IATA": "VDM",
    "icao_code": "SAVV"
  },
  {
    "city": "Vienna",
    "country": "Austria",
    "IATA": "VIE",
    "icao_code": "LOWW"
  },
  {
    "city": "Vientiane",
    "country": "Laos",
    "IATA": "VTE",
    "icao_code": "VLVT"
  },
  {
    "city": "Vieques Island",
    "country": "Puerto Rico",
    "IATA": "VQS"
  },
  {
    "city": "Vigo",
    "country": "Spain",
    "IATA": "VGO",
    "icao_code": "LEVX"
  },
  {
    "city": "Vijayawada",
    "country": "India",
    "IATA": "VGA",
    "icao_code": "VOBZ"
  },
  {
    "city": "Vila Bela da Santissima Trindade",
    "country": "Brazil",
    "IATA": "MTG"
  },
  {
    "city": "Vila Real",
    "country": "Portugal",
    "IATA": "VRL",
    "icao_code": "LPVR"
  },
  {
    "city": "Vila Rica",
    "country": "Brazil",
    "IATA": "VLP",
    "icao_code": "SWVC"
  },
  {
    "city": "Vilankulu",
    "country": "Mozambique",
    "IATA": "VNX",
    "icao_code": "FQVL"
  },
  {
    "city": "Vilhelmina",
    "country": "Sweden",
    "IATA": "VHM",
    "icao_code": "ESNV"
  },
  {
    "city": "Vilhena",
    "country": "Brazil",
    "IATA": "BVH",
    "icao_code": "SBVH"
  },
  {
    "city": "Villa Dolores",
    "country": "Argentina",
    "IATA": "VDR",
    "icao_code": "SAOD"
  },
  {
    "city": "Villa Garzon",
    "country": "Colombia",
    "IATA": "VGZ",
    "icao_code": "SKVG"
  },
  {
    "city": "Villa Gesell",
    "country": "Argentina",
    "IATA": "VLG",
    "icao_code": "SAZV"
  },
  {
    "city": "Villa Montes",
    "country": "Bolivia",
    "IATA": "VLM",
    "icao_code": "SLVM"
  },
  {
    "city": "Villa Reynolds",
    "country": "Argentina",
    "IATA": "VME",
    "icao_code": "SAOR"
  },
  {
    "city": "Villacoublay",
    "country": "France",
    "IATA": "VIY"
  },
  {
    "city": "Villafranca",
    "country": "Italy",
    "IATA": "VRN",
    "icao_code": "LIPX"
  },
  {
    "city": "Villahermosa",
    "country": "Mexico",
    "IATA": "VSA",
    "icao_code": "MMVA"
  },
  {
    "city": "Villavicencio",
    "country": "Colombia",
    "IATA": "VVC",
    "icao_code": "SKVV"
  },
  {
    "city": "Vilnius",
    "country": "Lithuania",
    "IATA": "VNO",
    "icao_code": "EYVI"
  },
  {
    "city": "Vilyuisk",
    "country": "Russia",
    "IATA": "VYI"
  },
  {
    "city": "Vina del Mar",
    "country": "Chile",
    "IATA": "KNA",
    "icao_code": "SCVM"
  },
  {
    "city": "Vineyard Haven MA",
    "country": "United States",
    "IATA": "MVY",
    "icao_code": "KMVY"
  },
  {
    "city": "Vinh",
    "country": "Vietnam",
    "IATA": "VII",
    "icao_code": "VVVH"
  },
  {
    "city": "Vinnitsa",
    "country": "Ukraine",
    "IATA": "VIN",
    "icao_code": "UKWW"
  },
  {
    "city": "Virac",
    "country": "Philippines",
    "IATA": "VRC",
    "icao_code": "RPUV"
  },
  {
    "city": "Visalia",
    "country": "United States",
    "IATA": "VIS",
    "icao_code": "KVIS"
  },
  {
    "city": "Visby",
    "country": "Sweden",
    "IATA": "VBY",
    "icao_code": "ESSV"
  },
  {
    "city": "Viseu",
    "country": "Portugal",
    "IATA": "VSE",
    "icao_code": "LPVZ"
  },
  {
    "city": "Vishakhapatnam",
    "country": "India",
    "IATA": "VTZ",
    "icao_code": "VEVZ"
  },
  {
    "city": "Vitebsk",
    "country": "Belarus",
    "IATA": "VTB",
    "icao_code": "UMII"
  },
  {
    "city": "Vitoria",
    "country": "Brazil",
    "IATA": "VIX",
    "icao_code": "SBVT"
  },
  {
    "city": "Vitoria",
    "country": "Spain",
    "IATA": "VIT",
    "icao_code": "LEVT"
  },
  {
    "city": "Vitória Da Conquista",
    "country": "Brazil",
    "IATA": "VDC",
    "icao_code": "SBQV"
  },
  {
    "city": "Vladivostok",
    "country": "Russia",
    "IATA": "VVO",
    "icao_code": "UHWW"
  },
  {
    "city": "Vodochody",
    "country": "Czech Republic",
    "IATA": "VOD"
  },
  {
    "city": "Vohemar",
    "country": "Madagascar",
    "IATA": "VOH",
    "icao_code": "FMNV"
  },
  {
    "city": "Volgograd",
    "country": "Russia",
    "IATA": "VOG",
    "icao_code": "URWW"
  },
  {
    "city": "Vologda",
    "country": "Russia",
    "IATA": "VGD",
    "icao_code": "ULWW"
  },
  {
    "city": "Vopnafjörður",
    "country": "Iceland",
    "IATA": "VPN",
    "icao_code": "BIVO"
  },
  {
    "city": "Vorkuta",
    "country": "Russia",
    "IATA": "VKT",
    "icao_code": "UUYW"
  },
  {
    "city": "Voronezh",
    "country": "Russia",
    "IATA": "VOZ",
    "icao_code": "UUOO"
  },
  {
    "city": "Vredendal",
    "country": "South Africa",
    "IATA": "VRE",
    "icao_code": "FAVR"
  },
  {
    "city": "Vryburg",
    "country": "South Africa",
    "IATA": "VRU",
    "icao_code": "FAVB"
  },
  {
    "city": "Vunisea",
    "country": "Fiji",
    "IATA": "KDV",
    "icao_code": "NFKD"
  },
  {
    "city": "Værøy",
    "country": "Norway",
    "IATA": "VRY"
  },
  {
    "city": "WEST BEND",
    "country": "United States",
    "IATA": "ETB",
    "icao_code": "KETB"
  },
  {
    "city": "Waala",
    "country": "New Caledonia",
    "IATA": "BMY",
    "icao_code": "NWWC"
  },
  {
    "city": "Wabush",
    "country": "Canada",
    "IATA": "YWK",
    "icao_code": "CYWK"
  },
  {
    "city": "Waco",
    "country": "United States",
    "IATA": "ACT",
    "icao_code": "KACT"
  },
  {
    "city": "Waco",
    "country": "United States",
    "IATA": "CNW",
    "icao_code": "KCNW"
  },
  {
    "city": "Waddington",
    "country": "United Kingdom",
    "IATA": "WTN",
    "icao_code": "EGXW"
  },
  {
    "city": "Wadeye",
    "country": "Australia",
    "IATA": "PKT",
    "icao_code": "YPKT"
  },
  {
    "city": "Wadi Halfa",
    "country": "Sudan",
    "IATA": "WHF",
    "icao_code": "HSSW"
  },
  {
    "city": "Wadi-al-dawasir",
    "country": "Saudi Arabia",
    "IATA": "WAE",
    "icao_code": "OEWD"
  },
  {
    "city": "Wageningen",
    "country": "Suriname",
    "IATA": "AGI",
    "icao_code": "SMWA"
  },
  {
    "city": "Wagga Wagga",
    "country": "Australia",
    "IATA": "WGA",
    "icao_code": "YSWG"
  },
  {
    "city": "Wahiawa",
    "country": "United States",
    "IATA": "HHI"
  },
  {
    "city": "Waiheke Island",
    "country": "New Zealand",
    "IATA": "WIK",
    "icao_code": "NZKE"
  },
  {
    "city": "Waikabubak-Sumba Island",
    "country": "Indonesia",
    "IATA": "TMC"
  },
  {
    "city": "Waikoloa Village",
    "country": "United States",
    "IATA": "WKL"
  },
  {
    "city": "Waingapu",
    "country": "Indonesia",
    "IATA": "WGP",
    "icao_code": "WRRW"
  },
  {
    "city": "Wainwright",
    "country": "United States",
    "IATA": "AIN",
    "icao_code": "PAWT"
  },
  {
    "city": "Wairoa",
    "country": "New Zealand",
    "IATA": "WIR"
  },
  {
    "city": "Wajima",
    "country": "Japan",
    "IATA": "NTQ",
    "icao_code": "RJNW"
  },
  {
    "city": "Wajir",
    "country": "Kenya",
    "IATA": "WJR",
    "icao_code": "HKWJ"
  },
  {
    "city": "Wake island",
    "country": "Wake Island",
    "IATA": "AWK",
    "icao_code": "PWAK"
  },
  {
    "city": "Wakkanai",
    "country": "Japan",
    "IATA": "WKJ",
    "icao_code": "RJCW"
  },
  {
    "city": "Walaha",
    "country": "Vanuatu",
    "IATA": "WLH",
    "icao_code": "NVSW"
  },
  {
    "city": "Wales",
    "country": "United States",
    "IATA": "WAA",
    "icao_code": "PAIW"
  },
  {
    "city": "Walgett",
    "country": "Australia",
    "IATA": "WGE",
    "icao_code": "YWLG"
  },
  {
    "city": "Walker's Cay",
    "country": "Bahamas",
    "IATA": "WKR"
  },
  {
    "city": "Walla Walla",
    "country": "United States",
    "IATA": "ALW",
    "icao_code": "KALW"
  },
  {
    "city": "Wallis",
    "country": "Wallis and Futuna",
    "IATA": "WLS",
    "icao_code": "NLWW"
  },
  {
    "city": "Wallops Island",
    "country": "United States",
    "IATA": "WAL"
  },
  {
    "city": "Walvis Bay",
    "country": "Namibia",
    "IATA": "WVB",
    "icao_code": "FYWB"
  },
  {
    "city": "Wamena",
    "country": "Indonesia",
    "IATA": "WMX",
    "icao_code": "WAJW"
  },
  {
    "city": "Wana",
    "country": "Pakistan",
    "IATA": "WAF"
  },
  {
    "city": "Wanaka",
    "country": "New Zealand",
    "IATA": "WKA",
    "icao_code": "NZWF"
  },
  {
    "city": "Wang An",
    "country": "Taiwan",
    "IATA": "WOT",
    "icao_code": "RCWA"
  },
  {
    "city": "Wanganui",
    "country": "New Zealand",
    "IATA": "WAG",
    "icao_code": "NZWU"
  },
  {
    "city": "Wangerooge",
    "country": "Germany",
    "IATA": "AGE"
  },
  {
    "city": "Wanxian",
    "country": "China",
    "IATA": "WXN",
    "icao_code": "ZUWX"
  },
  {
    "city": "Wapenamanda",
    "country": "Papua New Guinea",
    "IATA": "WBM",
    "icao_code": "AYWD"
  },
  {
    "city": "Waris-Papua Island",
    "country": "Indonesia",
    "IATA": "WAR",
    "icao_code": "WAJR"
  },
  {
    "city": "Warrnambool",
    "country": "Australia",
    "IATA": "WMB",
    "icao_code": "YWBL"
  },
  {
    "city": "Warsaw",
    "country": "Poland",
    "IATA": "WMI",
    "icao_code": "EPMO"
  },
  {
    "city": "Warsaw",
    "country": "Poland",
    "IATA": "WAW",
    "icao_code": "EPWA"
  },
  {
    "city": "Warton",
    "country": "United Kingdom",
    "IATA": "WRT",
    "icao_code": "EGNO"
  },
  {
    "city": "Washabo",
    "country": "Suriname",
    "IATA": "WSO"
  },
  {
    "city": "Washington",
    "country": "United States",
    "IATA": "OCW"
  },
  {
    "city": "Washington",
    "country": "United States",
    "IATA": "IAD",
    "icao_code": "KIAD"
  },
  {
    "city": "Washington",
    "country": "United States",
    "IATA": "DCA",
    "icao_code": "KDCA"
  },
  {
    "city": "Washington County",
    "country": "United States",
    "IATA": "WST",
    "icao_code": "KWST"
  },
  {
    "city": "Wasilla",
    "country": "United States",
    "IATA": "WWA"
  },
  {
    "city": "Wasior",
    "country": "Indonesia",
    "IATA": "WSR",
    "icao_code": "WASW"
  },
  {
    "city": "Waskaganish",
    "country": "Canada",
    "IATA": "YKQ",
    "icao_code": "CYKQ"
  },
  {
    "city": "Waspam",
    "country": "Nicaragua",
    "IATA": "WSP",
    "icao_code": "MNWP"
  },
  {
    "city": "Wassau",
    "country": "United States",
    "IATA": "CWA",
    "icao_code": "KCWA"
  },
  {
    "city": "Waterford",
    "country": "Ireland",
    "IATA": "WAT",
    "icao_code": "EIWF"
  },
  {
    "city": "Waterkloof",
    "country": "South Africa",
    "IATA": "WKF"
  },
  {
    "city": "Waterloo",
    "country": "United States",
    "IATA": "ALO",
    "icao_code": "KALO"
  },
  {
    "city": "Waterloo",
    "country": "Canada",
    "IATA": "YKF",
    "icao_code": "CYKF"
  },
  {
    "city": "Watertown",
    "country": "United States",
    "IATA": "ATY",
    "icao_code": "KATY"
  },
  {
    "city": "Watertown",
    "country": "United States",
    "IATA": "ART",
    "icao_code": "KART"
  },
  {
    "city": "Watson Lake",
    "country": "Canada",
    "IATA": "YQH",
    "icao_code": "CYQH"
  },
  {
    "city": "Watsonville",
    "country": "United States",
    "IATA": "WVI",
    "icao_code": "KWVI"
  },
  {
    "city": "Wau",
    "country": "Sudan",
    "IATA": "WUU",
    "icao_code": "HSWW"
  },
  {
    "city": "Wausau",
    "country": "United States",
    "IATA": "AUW"
  },
  {
    "city": "Wawa",
    "country": "Canada",
    "IATA": "YXZ",
    "icao_code": "CYXZ"
  },
  {
    "city": "Waycross",
    "country": "United States",
    "IATA": "AYS",
    "icao_code": "KAYS"
  },
  {
    "city": "Webequie",
    "country": "Canada",
    "IATA": "YWP",
    "icao_code": "CYWP"
  },
  {
    "city": "Weeze",
    "country": "Germany",
    "IATA": "NRN",
    "icao_code": "EDLV"
  },
  {
    "city": "Weifang",
    "country": "China",
    "IATA": "WEF",
    "icao_code": "ZSWF"
  },
  {
    "city": "Weihai",
    "country": "China",
    "IATA": "WEH",
    "icao_code": "ZSWH"
  },
  {
    "city": "Weipa",
    "country": "Australia",
    "IATA": "WEI",
    "icao_code": "YBWP"
  },
  {
    "city": "Wejh",
    "country": "Saudi Arabia",
    "IATA": "EJH",
    "icao_code": "OEWJ"
  },
  {
    "city": "Wekweeti",
    "country": "Canada",
    "IATA": "YFJ",
    "icao_code": "CFJ2"
  },
  {
    "city": "Wellington",
    "country": "New Zealand",
    "IATA": "WLG",
    "icao_code": "NZWN"
  },
  {
    "city": "Wemindji",
    "country": "Canada",
    "IATA": "YNC",
    "icao_code": "CYNC"
  },
  {
    "city": "Wenatchee",
    "country": "United States",
    "IATA": "EAT",
    "icao_code": "KEAT"
  },
  {
    "city": "Wendover",
    "country": "United States",
    "IATA": "ENV",
    "icao_code": "KENV"
  },
  {
    "city": "Wenzhou",
    "country": "China",
    "IATA": "WNZ",
    "icao_code": "ZSWZ"
  },
  {
    "city": "West Chicago",
    "country": "United States",
    "IATA": "DPA",
    "icao_code": "KDPA"
  },
  {
    "city": "South Florida, West Palm Beach",
    "country": "United States",
    "IATA": "PBI",
    "icao_code": "KPBI"
  },
  {
    "city": "West Palm Beach",
    "country": "United States",
    "IATA": "LNA",
    "icao_code": "KLNA"
  },
  {
    "city": "West Tinian",
    "country": "Northern Mariana Islands",
    "IATA": "TIQ",
    "icao_code": "PGWT"
  },
  {
    "city": "West Wyalong",
    "country": "Australia",
    "IATA": "WWY",
    "icao_code": "YWWL"
  },
  {
    "city": "West Yellowstone",
    "country": "United States",
    "IATA": "WYS",
    "icao_code": "KWYS"
  },
  {
    "city": "Westerland",
    "country": "Germany",
    "IATA": "GWT",
    "icao_code": "EDXW"
  },
  {
    "city": "Westfield",
    "country": "United States",
    "IATA": "BAF",
    "icao_code": "KBAF"
  },
  {
    "city": "Westport",
    "country": "New Zealand",
    "IATA": "WSZ",
    "icao_code": "NZWS"
  },
  {
    "city": "Westray",
    "country": "United Kingdom",
    "IATA": "WRY",
    "icao_code": "EGEW"
  },
  {
    "city": "Wewak",
    "country": "Papua New Guinea",
    "IATA": "WWK",
    "icao_code": "AYWK"
  },
  {
    "city": "Weyers Cave",
    "country": "United States",
    "IATA": "SHD",
    "icao_code": "KSHD"
  },
  {
    "city": "Whakatane",
    "country": "New Zealand",
    "IATA": "WHK",
    "icao_code": "NZWK"
  },
  {
    "city": "Whale Cove",
    "country": "Canada",
    "IATA": "YXN",
    "icao_code": "CYXN"
  },
  {
    "city": "Whangarei",
    "country": "New Zealand",
    "IATA": "WRE",
    "icao_code": "NZWR"
  },
  {
    "city": "Whatì",
    "country": "Canada",
    "IATA": "YLE",
    "icao_code": "CEM3"
  },
  {
    "city": "Wheeling",
    "country": "United States",
    "IATA": "HLG"
  },
  {
    "city": "Whidbey Island",
    "country": "United States",
    "IATA": "NUW"
  },
  {
    "city": "Whistler",
    "country": "Canada",
    "IATA": "YWS"
  },
  {
    "city": "White Mountain",
    "country": "United States",
    "IATA": "WMO",
    "icao_code": "PAWM"
  },
  {
    "city": "White Sands",
    "country": "United States",
    "IATA": "WSD"
  },
  {
    "city": "Whitecourt",
    "country": "Canada",
    "IATA": "YZU",
    "icao_code": "CYZU"
  },
  {
    "city": "Whitehorse",
    "country": "Canada",
    "IATA": "YXY",
    "icao_code": "CYXY"
  },
  {
    "city": "Whitianga",
    "country": "New Zealand",
    "IATA": "WTZ"
  },
  {
    "city": "Whyalla",
    "country": "Australia",
    "IATA": "WYA",
    "icao_code": "YWHA"
  },
  {
    "city": "Wiarton",
    "country": "Canada",
    "IATA": "YVV",
    "icao_code": "CYVV"
  },
  {
    "city": "Wichita",
    "country": "United States",
    "IATA": "BEC",
    "icao_code": "KBEC"
  },
  {
    "city": "Wichita",
    "country": "United States",
    "IATA": "IAB"
  },
  {
    "city": "Wichita",
    "country": "United States",
    "IATA": "ICT",
    "icao_code": "KICT"
  },
  {
    "city": "Wichita Falls",
    "country": "United States",
    "IATA": "SPS",
    "icao_code": "KSPS"
  },
  {
    "city": "Wick",
    "country": "United Kingdom",
    "IATA": "WIC",
    "icao_code": "EGPC"
  },
  {
    "city": "Wide Awake",
    "country": "Saint Helena",
    "IATA": "ASI"
  },
  {
    "city": "Wiesbaden",
    "country": "Germany",
    "IATA": "WIE",
    "icao_code": "ETOU"
  },
  {
    "city": "Wilcannia",
    "country": "Australia",
    "IATA": "WIO",
    "icao_code": "YWCA"
  },
  {
    "city": "Wildwood",
    "country": "United States",
    "IATA": "WWD",
    "icao_code": "KWWD"
  },
  {
    "city": "Wilhelmshaven",
    "country": "Germany",
    "IATA": "WVN"
  },
  {
    "city": "Wilkes-Barre",
    "country": "United States",
    "IATA": "WBW",
    "icao_code": "KWBW"
  },
  {
    "city": "Willemstad",
    "country": "Netherlands Antilles",
    "IATA": "CUR",
    "icao_code": "TNCC"
  },
  {
    "city": "Williams Harbour",
    "country": "Canada",
    "IATA": "YWM",
    "icao_code": "CCA6"
  },
  {
    "city": "Williams Lake",
    "country": "Canada",
    "IATA": "YWL",
    "icao_code": "CYWL"
  },
  {
    "city": "Williamsport",
    "country": "United States",
    "IATA": "IPT",
    "icao_code": "KIPT"
  },
  {
    "city": "Williston",
    "country": "United States",
    "IATA": "ISN",
    "icao_code": "KISN"
  },
  {
    "city": "Willoughby",
    "country": "United States",
    "IATA": "LNN"
  },
  {
    "city": "Willow Grove",
    "country": "United States",
    "IATA": "NXX",
    "icao_code": "KNXX"
  },
  {
    "city": "Wilmington",
    "country": "United States",
    "IATA": "ILN",
    "icao_code": "KILN"
  },
  {
    "city": "Wilmington",
    "country": "United States",
    "IATA": "ILG",
    "icao_code": "KILG"
  },
  {
    "city": "Wilmington",
    "country": "United States",
    "IATA": "ILM",
    "icao_code": "KILM"
  },
  {
    "city": "Wiluna",
    "country": "Australia",
    "IATA": "WUN",
    "icao_code": "YWLU"
  },
  {
    "city": "Winder",
    "country": "United States",
    "IATA": "WDR",
    "icao_code": "KWDR"
  },
  {
    "city": "Windhoek",
    "country": "Namibia",
    "IATA": "ERS",
    "icao_code": "FYWE"
  },
  {
    "city": "Windhoek",
    "country": "Namibia",
    "IATA": "WDH",
    "icao_code": "FYWH"
  },
  {
    "city": "Windorah",
    "country": "Australia",
    "IATA": "WNR",
    "icao_code": "YWDH"
  },
  {
    "city": "Windsor",
    "country": "Canada",
    "IATA": "YQG",
    "icao_code": "CYQG"
  },
  {
    "city": "Windsor Locks",
    "country": "United States",
    "IATA": "BDL",
    "icao_code": "KBDL"
  },
  {
    "city": "Winfield",
    "country": "United States",
    "IATA": "WLD",
    "icao_code": "KWLD"
  },
  {
    "city": "Wink",
    "country": "United States",
    "IATA": "INK",
    "icao_code": "KINK"
  },
  {
    "city": "Winnemucca",
    "country": "United States",
    "IATA": "WMC",
    "icao_code": "KWMC"
  },
  {
    "city": "Winnipeg",
    "country": "Canada",
    "IATA": "YWG",
    "icao_code": "CYWG"
  },
  {
    "city": "Winslow",
    "country": "United States",
    "IATA": "INW",
    "icao_code": "KINW"
  },
  {
    "city": "Winston-salem",
    "country": "United States",
    "IATA": "INT",
    "icao_code": "KINT"
  },
  {
    "city": "Winter Haven",
    "country": "United States",
    "IATA": "GIF",
    "icao_code": "KGIF"
  },
  {
    "city": "Winton",
    "country": "Australia",
    "IATA": "WIN",
    "icao_code": "YWTN"
  },
  {
    "city": "Wirawila",
    "country": "Sri Lanka",
    "IATA": "WRZ",
    "icao_code": "VCCW"
  },
  {
    "city": "Wisconsin Rapids",
    "country": "United States",
    "IATA": "ISW"
  },
  {
    "city": "Woensdrecht",
    "country": "Netherlands",
    "IATA": "WOE",
    "icao_code": "EHWO"
  },
  {
    "city": "Wolf Point",
    "country": "United States",
    "IATA": "OLF",
    "icao_code": "KOLF"
  },
  {
    "city": "Wollaston Lake",
    "country": "Canada",
    "IATA": "ZWL",
    "icao_code": "CZWL"
  },
  {
    "city": "Wollongong",
    "country": "Australia",
    "IATA": "WOL",
    "icao_code": "YWOL"
  },
  {
    "city": "Wonju",
    "country": "South Korea",
    "IATA": "WJU",
    "icao_code": "RKNW"
  },
  {
    "city": "Wonsan",
    "country": "North Korea",
    "IATA": "WOS"
  },
  {
    "city": "Wood Buffalo",
    "country": "Canada",
    "IATA": "HZP",
    "icao_code": "CYNR"
  },
  {
    "city": "Woodbourne",
    "country": "New Zealand",
    "IATA": "BHE",
    "icao_code": "NZWB"
  },
  {
    "city": "Woodward",
    "country": "United States",
    "IATA": "WWR",
    "icao_code": "KWWR"
  },
  {
    "city": "Woomera",
    "country": "Australia",
    "IATA": "UMR",
    "icao_code": "YPWR"
  },
  {
    "city": "Worcester",
    "country": "United States",
    "IATA": "ORH",
    "icao_code": "KORH"
  },
  {
    "city": "Worland",
    "country": "United States",
    "IATA": "WRL",
    "icao_code": "KWRL"
  },
  {
    "city": "Wrangell",
    "country": "United States",
    "IATA": "WRG",
    "icao_code": "PAWG"
  },
  {
    "city": "Wright",
    "country": "United States",
    "IATA": "LIY"
  },
  {
    "city": "Wrightstown",
    "country": "United States",
    "IATA": "WRI"
  },
  {
    "city": "Wrigley",
    "country": "Canada",
    "IATA": "YWY",
    "icao_code": "CYWY"
  },
  {
    "city": "Wroclaw",
    "country": "Poland",
    "IATA": "WRO",
    "icao_code": "EPWR"
  },
  {
    "city": "Wudalianchi",
    "country": "China",
    "IATA": "DTU",
    "icao_code": "ZYDU"
  },
  {
    "city": "Wuhai",
    "country": "China",
    "IATA": "WUA",
    "icao_code": "ZBUH"
  },
  {
    "city": "Wuhan",
    "country": "China",
    "IATA": "WUH",
    "icao_code": "ZHHH"
  },
  {
    "city": "Wuhu",
    "country": "China",
    "IATA": "WHU",
    "icao_code": "ZSWU"
  },
  {
    "city": "Wulanchabu",
    "country": "China",
    "IATA": "UCB",
    "icao_code": "ZBUC"
  },
  {
    "city": "Wunnumin Lake",
    "country": "Canada",
    "IATA": "WNN",
    "icao_code": "CKL3"
  },
  {
    "city": "Wuxi",
    "country": "China",
    "IATA": "WUX",
    "icao_code": "ZSWX"
  },
  {
    "city": "Wuyishan",
    "country": "China",
    "IATA": "WUS",
    "icao_code": "ZSWY"
  },
  {
    "city": "Wuzhou",
    "country": "China",
    "IATA": "WUZ",
    "icao_code": "ZGWZ"
  },
  {
    "city": "Wycombe",
    "country": "United Kingdom",
    "IATA": "HYC"
  },
  {
    "city": "Wyndham",
    "country": "Australia",
    "IATA": "WYN",
    "icao_code": "YWYM"
  },
  {
    "city": "Wyton",
    "country": "United Kingdom",
    "IATA": "QUY"
  },
  {
    "city": "Xangongo",
    "country": "Angola",
    "IATA": "XGN",
    "icao_code": "FNXA"
  },
  {
    "city": "Xi'AN",
    "country": "China",
    "IATA": "SIA"
  },
  {
    "city": "Xi'an",
    "country": "China",
    "IATA": "XIY",
    "icao_code": "ZLXY"
  },
  {
    "city": "Xiahe city",
    "country": "China",
    "IATA": "GXH",
    "icao_code": "ZLXH"
  },
  {
    "city": "Xiamen",
    "country": "China",
    "IATA": "XMN",
    "icao_code": "ZSAM"
  },
  {
    "city": "Xiangfan",
    "country": "China",
    "IATA": "XFN",
    "icao_code": "ZHXF"
  },
  {
    "city": "Xichang",
    "country": "China",
    "IATA": "XIC",
    "icao_code": "ZUXC"
  },
  {
    "city": "Xilinhot",
    "country": "China",
    "IATA": "XIL",
    "icao_code": "ZBXH"
  },
  {
    "city": "Xingyi",
    "country": "China",
    "IATA": "ACX"
  },
  {
    "city": "Xining",
    "country": "China",
    "IATA": "XNN",
    "icao_code": "ZLXN"
  },
  {
    "city": "Xinyuan",
    "country": "China",
    "IATA": "NLT",
    "icao_code": "ZWNL"
  },
  {
    "city": "Xinzhou",
    "country": "China",
    "IATA": "WUT",
    "icao_code": "ZBXZ"
  },
  {
    "city": "Xuzhou",
    "country": "China",
    "IATA": "XUZ",
    "icao_code": "ZSXZ"
  },
  {
    "city": "Yacuiba",
    "country": "Bolivia",
    "IATA": "BYC",
    "icao_code": "SLYA"
  },
  {
    "city": "Yakataga",
    "country": "United States",
    "IATA": "CYT",
    "icao_code": "PACY"
  },
  {
    "city": "Yakima",
    "country": "United States",
    "IATA": "YKM",
    "icao_code": "KYKM"
  },
  {
    "city": "Yakushima",
    "country": "Japan",
    "IATA": "KUM",
    "icao_code": "RJFC"
  },
  {
    "city": "Yakutat",
    "country": "United States",
    "IATA": "YAK",
    "icao_code": "PAYA"
  },
  {
    "city": "Yakutia",
    "country": "Russia",
    "IATA": "PYJ",
    "icao_code": "UERP"
  },
  {
    "city": "Yakutsk",
    "country": "Russia",
    "IATA": "GYG"
  },
  {
    "city": "Yakutsk",
    "country": "Russia",
    "IATA": "YKS",
    "icao_code": "UEEE"
  },
  {
    "city": "Yalata",
    "country": "Australia",
    "IATA": "KYI",
    "icao_code": "YYTA"
  },
  {
    "city": "Yam Island",
    "country": "Australia",
    "IATA": "XMY",
    "icao_code": "YYMI"
  },
  {
    "city": "Yamagata",
    "country": "Japan",
    "IATA": "GAJ",
    "icao_code": "RJSC"
  },
  {
    "city": "Yamaguchi",
    "country": "Japan",
    "IATA": "UBJ",
    "icao_code": "RJDC"
  },
  {
    "city": "Yambol",
    "country": "Bulgaria",
    "IATA": "JAM",
    "icao_code": "LBIA"
  },
  {
    "city": "Yamoussoukro",
    "country": "Cote d'Ivoire",
    "IATA": "ASK",
    "icao_code": "DIYO"
  },
  {
    "city": "Yan'an",
    "country": "China",
    "IATA": "ENY",
    "icao_code": "ZLYA"
  },
  {
    "city": "Yancheng",
    "country": "China",
    "IATA": "YNZ",
    "icao_code": "ZSYN"
  },
  {
    "city": "Yandina",
    "country": "Solomon Islands",
    "IATA": "XYA",
    "icao_code": "AGGY"
  },
  {
    "city": "Yangon",
    "country": "Burma",
    "IATA": "RGN",
    "icao_code": "VYYY"
  },
  {
    "city": "Yangzhou",
    "country": "China",
    "IATA": "YTY",
    "icao_code": "ZSYA"
  },
  {
    "city": "Yanji",
    "country": "China",
    "IATA": "YNJ",
    "icao_code": "ZYYJ"
  },
  {
    "city": "Yankton",
    "country": "United States",
    "IATA": "YKN",
    "icao_code": "KYKN"
  },
  {
    "city": "Yantai",
    "country": "China",
    "IATA": "YNT",
    "icao_code": "ZSYT"
  },
  {
    "city": "Yaounde",
    "country": "Cameroon",
    "IATA": "NSI",
    "icao_code": "FKYS"
  },
  {
    "city": "Yaounde",
    "country": "Cameroon",
    "IATA": "YAO",
    "icao_code": "FKKY"
  },
  {
    "city": "Yap",
    "country": "Micronesia",
    "IATA": "YAP",
    "icao_code": "PTYA"
  },
  {
    "city": "Yarkant",
    "country": "China",
    "IATA": "QSZ",
    "icao_code": "ZWSC"
  },
  {
    "city": "Yarmouth",
    "country": "Canada",
    "IATA": "YQI",
    "icao_code": "CYQI"
  },
  {
    "city": "Yaroslavl",
    "country": "Russia",
    "IATA": "IAR",
    "icao_code": "UUDL"
  },
  {
    "city": "Yasawa Island",
    "country": "Fiji",
    "IATA": "YAS"
  },
  {
    "city": "Yasuj",
    "country": "Iran",
    "IATA": "YES",
    "icao_code": "OISY"
  },
  {
    "city": "Yazd",
    "country": "Iran",
    "IATA": "AZD",
    "icao_code": "OIYY"
  },
  {
    "city": "Ye",
    "country": "Burma",
    "IATA": "XYE",
    "icao_code": "VYYE"
  },
  {
    "city": "Yechon",
    "country": "South Korea",
    "IATA": "YEC",
    "icao_code": "RKTY"
  },
  {
    "city": "Yekaterinburg",
    "country": "Russia",
    "IATA": "SVX",
    "icao_code": "USSS"
  },
  {
    "city": "Yellowknife",
    "country": "Canada",
    "IATA": "YZF",
    "icao_code": "CYZF"
  },
  {
    "city": "Yenbo",
    "country": "Saudi Arabia",
    "IATA": "YNB",
    "icao_code": "OEYN"
  },
  {
    "city": "Yengema",
    "country": "Sierra Leone",
    "IATA": "WYE",
    "icao_code": "GFYE"
  },
  {
    "city": "Yenisehir",
    "country": "Turkey",
    "IATA": "YEI",
    "icao_code": "LTBR"
  },
  {
    "city": "Yeniseysk",
    "country": "Russia",
    "IATA": "EIE",
    "icao_code": "UNII"
  },
  {
    "city": "Yeosu",
    "country": "South Korea",
    "IATA": "RSU",
    "icao_code": "RKJY"
  },
  {
    "city": "Yeovilton",
    "country": "United Kingdom",
    "IATA": "YEO",
    "icao_code": "EGDY"
  },
  {
    "city": "Yerbogachen",
    "country": "Russia",
    "IATA": "ERG",
    "icao_code": "UIKE"
  },
  {
    "city": "Yerevan",
    "country": "Armenia",
    "IATA": "EVN",
    "icao_code": "UGEE"
  },
  {
    "city": "Yibin",
    "country": "China",
    "IATA": "YBP",
    "icao_code": "ZUYB"
  },
  {
    "city": "Yichang",
    "country": "China",
    "IATA": "YIH",
    "icao_code": "ZHYC"
  },
  {
    "city": "Yichun",
    "country": "China",
    "IATA": "YIC",
    "icao_code": "ZSYC"
  },
  {
    "city": "Yinchun",
    "country": "China",
    "IATA": "LDS",
    "icao_code": "ZYLD"
  },
  {
    "city": "Yingkou",
    "country": "China",
    "IATA": "YKH",
    "icao_code": "ZYYK"
  },
  {
    "city": "Yining",
    "country": "China",
    "IATA": "YIN",
    "icao_code": "ZWYN"
  },
  {
    "city": "Yiwu",
    "country": "China",
    "IATA": "YIW",
    "icao_code": "ZSYW"
  },
  {
    "city": "Ylivieska-raudaskyla",
    "country": "Finland",
    "IATA": "YLI",
    "icao_code": "EFYL"
  },
  {
    "city": "Yogyakarta",
    "country": "Indonesia",
    "IATA": "JOG",
    "icao_code": "WIIJ"
  },
  {
    "city": "Yokota",
    "country": "Japan",
    "IATA": "OKO",
    "icao_code": "RJTY"
  },
  {
    "city": "Yola",
    "country": "Nigeria",
    "IATA": "YOL",
    "icao_code": "DNYO"
  },
  {
    "city": "Yonaguni Jima",
    "country": "Japan",
    "IATA": "OGN",
    "icao_code": "ROYN"
  },
  {
    "city": "Yongzhou",
    "country": "China",
    "IATA": "LLF",
    "icao_code": "ZGLG"
  },
  {
    "city": "Yopal",
    "country": "Colombia",
    "IATA": "EYP",
    "icao_code": "SKYP"
  },
  {
    "city": "York Landing",
    "country": "Canada",
    "IATA": "ZAC",
    "icao_code": "CZAC"
  },
  {
    "city": "Yorke Island",
    "country": "Australia",
    "IATA": "OKR",
    "icao_code": "YYKI"
  },
  {
    "city": "Yorkton",
    "country": "Canada",
    "IATA": "YQV",
    "icao_code": "CYQV"
  },
  {
    "city": "Yoron",
    "country": "Japan",
    "IATA": "RNJ",
    "icao_code": "RORY"
  },
  {
    "city": "Yoshkar-Ola",
    "country": "Russia",
    "IATA": "JOK",
    "icao_code": "UWKJ"
  },
  {
    "city": "Youngstown",
    "country": "United States",
    "IATA": "YNG",
    "icao_code": "KYNG"
  },
  {
    "city": "Yuanmou",
    "country": "China",
    "IATA": "YUA"
  },
  {
    "city": "Yuba City",
    "country": "United States",
    "IATA": "MYV",
    "icao_code": "KMYV"
  },
  {
    "city": "Yuendumu",
    "country": "Australia",
    "IATA": "YUE",
    "icao_code": "YYND"
  },
  {
    "city": "Yulin",
    "country": "China",
    "IATA": "UYN",
    "icao_code": "ZLYL"
  },
  {
    "city": "Yuma",
    "country": "United States",
    "IATA": "YUM",
    "icao_code": "KNYL"
  },
  {
    "city": "Yuncheng",
    "country": "China",
    "IATA": "YCU",
    "icao_code": "ZBYC"
  },
  {
    "city": "Yurimaguas",
    "country": "Peru",
    "IATA": "YMS",
    "icao_code": "SPMS"
  },
  {
    "city": "Yushu",
    "country": "China",
    "IATA": "YUS",
    "icao_code": "ZLYS"
  },
  {
    "city": "Yuzhno-Kurilsk",
    "country": "Russia",
    "IATA": "DEE",
    "icao_code": "UHSM"
  },
  {
    "city": "Yuzhno-sakhalinsk",
    "country": "Russia",
    "IATA": "UUS",
    "icao_code": "UHSS"
  },
  {
    "city": "ZAPALA",
    "country": "Argentina",
    "IATA": "APZ",
    "icao_code": "SAHZ"
  },
  {
    "city": "Zabol",
    "country": "Iran",
    "IATA": "ACZ",
    "icao_code": "OIZB"
  },
  {
    "city": "Zacatecas",
    "country": "Mexico",
    "IATA": "ZCL",
    "icao_code": "MMZC"
  },
  {
    "city": "Zadar",
    "country": "Croatia",
    "IATA": "ZAD",
    "icao_code": "LDZD"
  },
  {
    "city": "Zagora",
    "country": "Morocco",
    "IATA": "OZG"
  },
  {
    "city": "Zagreb",
    "country": "Croatia",
    "IATA": "ZAG",
    "icao_code": "LDZA"
  },
  {
    "city": "Zahedan",
    "country": "Iran",
    "IATA": "ZAH",
    "icao_code": "OIZH"
  },
  {
    "city": "Zakynthos",
    "country": "Greece",
    "IATA": "ZTH",
    "icao_code": "LGZA"
  },
  {
    "city": "Zamboanga",
    "country": "Philippines",
    "IATA": "ZAM",
    "icao_code": "RPMZ"
  },
  {
    "city": "Zamora",
    "country": "Mexico",
    "IATA": "ZMM"
  },
  {
    "city": "Zandery",
    "country": "Suriname",
    "IATA": "PBM",
    "icao_code": "SMJP"
  },
  {
    "city": "Zanesville",
    "country": "United States",
    "IATA": "ZZV",
    "icao_code": "KZZV"
  },
  {
    "city": "Zanjan",
    "country": "Iran",
    "IATA": "JWN",
    "icao_code": "OITZ"
  },
  {
    "city": "Zanzibar",
    "country": "Tanzania",
    "IATA": "ZNZ",
    "icao_code": "HTZA"
  },
  {
    "city": "Zaporozhye",
    "country": "Ukraine",
    "IATA": "OZH",
    "icao_code": "UKDE"
  },
  {
    "city": "Zaqatala",
    "country": "Azerbaijan",
    "IATA": "ZTU",
    "icao_code": "UBBY"
  },
  {
    "city": "Zarafshan",
    "country": "Uzbekistan",
    "IATA": "AFS",
    "icao_code": "UTSN"
  },
  {
    "city": "Zaragoza",
    "country": "Spain",
    "IATA": "ZAZ",
    "icao_code": "LEZG"
  },
  {
    "city": "Zaranj",
    "country": "Afghanistan",
    "IATA": "ZAJ",
    "icao_code": "OAZJ"
  },
  {
    "city": "Zaria",
    "country": "Nigeria",
    "IATA": "ZAR",
    "icao_code": "DNZA"
  },
  {
    "city": "Zarzaitine",
    "country": "Algeria",
    "IATA": "IAM",
    "icao_code": "DAUZ"
  },
  {
    "city": "Zephyrhills",
    "country": "United States",
    "IATA": "ZPH",
    "icao_code": "KZPH"
  },
  {
    "city": "Zero",
    "country": "India",
    "IATA": "ZER",
    "icao_code": "VEZO"
  },
  {
    "city": "Zhalantun",
    "country": "China",
    "IATA": "NZL",
    "icao_code": "ZBZL"
  },
  {
    "city": "Zhangjiakou",
    "country": "China",
    "IATA": "ZQZ"
  },
  {
    "city": "Zhangye",
    "country": "China",
    "IATA": "YZY"
  },
  {
    "city": "Zhanjiang",
    "country": "China",
    "IATA": "ZHA",
    "icao_code": "ZGZJ"
  },
  {
    "city": "Zhaotong",
    "country": "China",
    "IATA": "ZAT",
    "icao_code": "ZPZT"
  },
  {
    "city": "Zhengzhou",
    "country": "China",
    "IATA": "CGO",
    "icao_code": "ZHCC"
  },
  {
    "city": "Zhezkazgan",
    "country": "Kazakhstan",
    "IATA": "DZN",
    "icao_code": "UAKD"
  },
  {
    "city": "Zhigansk",
    "country": "Russia",
    "IATA": "ZIX"
  },
  {
    "city": "Zhijiang",
    "country": "China",
    "IATA": "HJJ",
    "icao_code": "ZGCJ"
  },
  {
    "city": "Zhob",
    "country": "Pakistan",
    "IATA": "PZH",
    "icao_code": "OPZB"
  },
  {
    "city": "Zhongwei",
    "country": "China",
    "IATA": "ZHY",
    "icao_code": "ZLZW"
  },
  {
    "city": "Zhoushan",
    "country": "China",
    "IATA": "HSN",
    "icao_code": "ZSZS"
  },
  {
    "city": "Zhuhai",
    "country": "China",
    "IATA": "ZUH",
    "icao_code": "ZGUH"
  },
  {
    "city": "Zhytomyr",
    "country": "Ukraine",
    "IATA": "ZTR",
    "icao_code": "UKKV"
  },
  {
    "city": "Zielona Gora",
    "country": "Poland",
    "IATA": "IEG",
    "icao_code": "EPZG"
  },
  {
    "city": "Ziguinchor",
    "country": "Senegal",
    "IATA": "ZIG",
    "icao_code": "GOGG"
  },
  {
    "city": "Zihuatanejo",
    "country": "Mexico",
    "IATA": "ZIH",
    "icao_code": "MMZH"
  },
  {
    "city": "Zinder",
    "country": "Niger",
    "IATA": "ZND",
    "icao_code": "DRZR"
  },
  {
    "city": "Zintan",
    "country": "Libya",
    "IATA": "ZIS",
    "icao_code": "HLZN"
  },
  {
    "city": "Zoersel",
    "country": "Belgium",
    "IATA": "OBL",
    "icao_code": "EBZR"
  },
  {
    "city": "Zonguldak",
    "country": "Turkey",
    "IATA": "ONQ",
    "icao_code": "LTAS"
  },
  {
    "city": "Zouerat",
    "country": "Mauritania",
    "IATA": "OUZ",
    "icao_code": "GQPZ"
  },
  {
    "city": "Zunyi",
    "country": "China",
    "IATA": "WMT",
    "icao_code": "ZUMT"
  },
  {
    "city": "Zunyi",
    "country": "China",
    "IATA": "ZYI",
    "icao_code": "ZUZY"
  },
  {
    "city": "Zurich",
    "country": "Switzerland",
    "IATA": "ZRH",
    "icao_code": "LSZH"
  },
  {
    "city": "Zweibruecken",
    "country": "Germany",
    "IATA": "ZQW",
    "icao_code": "EDRZ"
  },
  {
    "city": "Zyryanka",
    "country": "Russia",
    "IATA": "ZKP"
  },
  {
    "city": "Ängelholm",
    "country": "Sweden",
    "IATA": "AGH",
    "icao_code": "ESDB"
  },
  {
    "city": "Çorlu",
    "country": "Turkey",
    "IATA": "TEQ",
    "icao_code": "LTBU"
  },
  {
    "city": "Île d'Yeu",
    "country": "France",
    "IATA": "IDY",
    "icao_code": "LFEY"
  },
  {
    "city": "Île des Pins",
    "country": "New Caledonia",
    "IATA": "ILP",
    "icao_code": "NWEE"
  },
  {
    "city": "Östersund",
    "country": "Sweden",
    "IATA": "OSD",
    "icao_code": "ESPC"
  },
  {
    "city": "Žilina",
    "country": "Slovakia",
    "IATA": "ILZ",
    "icao_code": "LZZI"
  },
  {
    "city": "New York, East Hampton NYC",
    "country": "United States",
    "IATA": "HTO",
    "icao_code": "KHTO"
  },
  {
    "city": "New York, Westchester County NYC",
    "country": "United States",
    "IATA": "HPN",
    "icao_code": "KHPN"
  },
  {
    "city": "New York, White Plains NYC",
    "country": "United States",
    "IATA": "HPN",
    "icao_code": "KHPN"
  },
  {
    "city": "New York, Farmingdale NYC",
    "country": "United States",
    "IATA": "FRG",
    "icao_code": "KFRG"
  },
  {
    "city": "New York, Morristown NYC",
    "country": "United States",
    "IATA": "MMU",
    "icao_code": "KMMU"
  },
  {
    "city": "New York, Islip NYC",
    "country": "United States",
    "IATA": "ISP",
    "icao_code": "KISP"
  },
  {
    "city": "New York, Westhampton NYC",
    "country": "United States",
    "IATA": "FOK",
    "icao_code": "KFOK"
  }
];
