import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SendContactEmailApi } from "../utils/SendContactEmail";
import {
  Grid,
  Card,
  Input,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Snackbar
} from "@material-ui/core";
import { Alert } from 'reactstrap';

import { Row, Col, Button } from 'reactstrap'
import "./common.css";

function ContactUs() {
  const [isAllowSubmit, SetAllowSubmit] = useState(false);
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [address, SetAddress] = useState("");
  const [subject, SetSubject] = useState("");
  const [message, SetMessage] = useState("");
  const [isFormSubmitted, SetFormSubmitted] = useState(false);

  useEffect(() => {
    if (isFormSubmitted) {
      SetName("");
      SetEmail("")
      SetPhone("")
      SetAddress("")
      SetSubject("")
      SetMessage("")
    }
  }, [isFormSubmitted]);


  async function SendContactEmail(e) {
    const contactData = {
      name: name,
      email: email,
      phone: phone,
      address: address,
      subject: subject,
      message: message,
    };
    if (name != "" && email !== "" && subject !== "" && message !== "") {
      const response = await SendContactEmailApi(contactData);
      if (response.status) {
        SetFormSubmitted(true);
      }
    }
  }

  function onCaptchaChange(value) {
    if (
      value &&
      name != "" &&
      email !== "" &&
      subject !== "" &&
      message !== ""
    ) {
      SetAllowSubmit(true);
    } else {
      SetAllowSubmit(false);
    }
  }
  return (
    <>
      {isFormSubmitted && (
        <Snackbar
          open={isFormSubmitted}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetFormSubmitted(!isFormSubmitted)}
        >
          <Alert className="m-2" color="success">
            Your contact details submitted successfully.
          </Alert>
        </Snackbar>
      )}
      <Grid>
        <div className="fullWidth">
          <Card className="p-3 m-4">
            <Row>
              <Col lg="5" md="5" sm="12">
                <div className="p-6">
                  <div className="my-6">
                    <h2>Contact Us</h2>
                    <h3>info@FlyKatana.com</h3>
                    <h3>(929) 318-9292</h3>
                  </div>
                </div>
              </Col>
              <Col lg="7" md="7" sm="12" className="imgBackgroundFlight">
                <div className="py-5">
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <FormControl style={{ background: "#ffffff" }} fullWidth className="mb-2">
                        <InputLabel htmlFor="name">Name</InputLabel>
                        <Input
                          id="name"
                          value={name}
                          onChange={(e) => SetName(e.target.value)}
                        />
                      </FormControl>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <FormControl style={{ background: "#ffffff" }} fullWidth className="mb-2">
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                          id="email"
                          value={email}
                          onChange={(e) => SetEmail(e.target.value)}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <FormControl style={{ background: "#ffffff" }} fullWidth className="mb-2">
                        <InputLabel htmlFor="phone">Phone</InputLabel>
                        <Input
                          id="phone"
                          value={phone}
                          onChange={(e) => SetPhone(e.target.value)}
                        />
                      </FormControl>
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <FormControl style={{ background: "#ffffff" }} fullWidth className="mb-2">
                        <InputLabel htmlFor="address">Address</InputLabel>
                        <Input
                          id="address"
                          value={address}
                          onChange={(e) => SetAddress(e.target.value)}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <FormControl style={{ background: "#ffffff" }} fullWidth className="mb-2">
                        <InputLabel htmlFor="subject">Subject</InputLabel>
                        <Input
                          id="subject"
                          value={subject}
                          onChange={(e) => SetSubject(e.target.value)}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <FormControl fullWidth className="mb-2">
                        <TextareaAutosize
                          minRows={3}
                          placeholder="Type your message here..."
                          value={message}
                          onChange={(e) => SetMessage(e.target.value)}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" md="12" sm="12">
                      <ReCAPTCHA
                        sitekey="6LcFXVIcAAAAADWuwjSBs4vMiFMLtqHaL9y1cwmf"
                        onChange={onCaptchaChange}
                      />
                    </Col>
                  </Row>
                  {isAllowSubmit && (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <FormControl fullWidth className="mb-2">
                          <Button
                            name="submit"
                            className="btn btn-block btnYellow"
                            onClick={SendContactEmail}
                          >
                            SUBMIT
                          </Button>
                        </FormControl>
                      </Col>
                    </Row>
                  )}
                  {!isAllowSubmit && (
                    <Row>
                      <Col lg="12" md="12" sm="12">
                        <FormControl fullWidth className="mb-2">
                          <Button
                            disabled
                            name="submit"
                            className="btn btn-block btnYellow"
                            onClick={SendContactEmail}
                          >
                            SUBMIT
                          </Button>
                        </FormControl>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </Grid>
    </>
  );
}

export default ContactUs;
