import "../pages/common.css";
import { useState, useEffect } from "react";
import * as React from "react";
import { authAndUserData } from "../data/AuthAndUserData";
import { Card, CardBody, Row, Col } from "reactstrap";

function Press() {
  const { GetPress } = authAndUserData();
  const [press, SetPress] = useState([]);

  const cardsData = [
    {
      headline: "BROWSE",
      cover_image: require("../assets/images/Browse.jpg").default,
      description:
        "Explore Katana's extensive database of private plane seats, empty leg charters, and Member flight preferences to discover the ideal travel solution for your needs. If none of the options meet your requirements, you have the option to list your flight preferences or request pricing from one of our Partners. Additionally, if you have extra seats on your charter, you can list them here for other Members.",
    },
    {
      headline: "BOOK",
      cover_image: require("../assets/images/Book.jpg").default,
      description:
        "Katana will link you with the carrier, Member, or Operator, enabling you to commence your flight. When you list your Flight Interest or Seats for sale, other Members will receive notifications, and they will contact you directly to arrange flight sharing.",
    },
    {
      headline: "RELAX",
      cover_image: require("../assets/images/Relax.jpg").default,
      description:
        "Reach your destination more quickly, conveniently, and affordably by sharing with fellow Katana Members.",
    },
  ];

  async function GetPressList() {
    const response = await GetPress();
    SetPress(response);
  }

  useEffect(() => {
    // GetPressList();
  }, []);

  const NewsCard = ({ title, image, link, description }) => (
    <div className="news-card" style={{padding: '0px', margin: '15px' , borderRadius: '4px'}}>
      <a href={link} target="_blank">
        <img
          src={image}
          alt={title}
          style={{
            width: "100%",
            height: "200px",
            "object-fit": "cover",
            "border-radius": "4px 4px 0px 0px",
          }}
        />
        <h2>{title}</h2>
        <div style={{padding: '10px'}}>
          <p className="description">{description}</p>
        </div>
      </a>
    </div>
  );

  const NewsList = ({ news }) => (
    <div className="news-list">
      {news.map((item, index) => (
        <NewsCard
          key={index}
          title={item.headline}
          image={item.cover_image}
          description={item.description}
          date={item.date}
        />
      ))}
    </div>
  );

  return (
    <>
      <div class="newsdiv" style={{margin: '0px'}}>
        <NewsList news={cardsData} />
      </div>
    </>
  );
  }
export default Press;
