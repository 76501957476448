import React, { useState } from "react";
import { FaEnvelope, FaMinusCircle, FaPhone, FaSms, FaTrash } from "react-icons/fa";
import { IoMdChatbubbles } from "react-icons/io";
import { Link } from "react-router-dom";
import { Table, Button, Alert } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";

function FriendList({ friendData, RemoveFriend, SetRemoveFriendData }) {
  const [confirmDelete, SetConfirmDelete] = useState(false);

  function ConfirmRemoveFriend(e, frienddata) {
    SetRemoveFriendData(frienddata);
    SetConfirmDelete(true);
  }
  function YesConfirm(isconfirm) {
    if (isconfirm) {
      RemoveFriend();
    }
    SetConfirmDelete(false);
  }
  const HandelMyfriendChat = (FriendData) => {
    if (FriendData.is_group) {
      console.log(FriendData, "group");
      localStorage.setItem("ModalData", JSON.stringify(FriendData))
      const url = `/chat`;
      window.open(url, "_self", "noopener,noreferrer");
    } else {
      console.log(FriendData, "friendData");
      const url = `/chat/${FriendData.userid}`;
      window.open(url, "_self", "noopener,noreferrer");
    }
  }
  return (
    <div>
      {confirmDelete && (
        <SweetAlert
          custom
          showCancel
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="btnYellow"
          cancelBtnBsStyle="btnYellow"
          onConfirm={(e) => YesConfirm(true)}
          onCancel={(e) => YesConfirm(false)}
          focusCancelBtn
        >
          Are you sure, You want to remove member?
        </SweetAlert>
      )}
      {!friendData ||
        (friendData.length < 1 && (
          <>
            <Alert className="alertBlue text-white mx-auto" style={{ width: "97%" }}>
              You don't have any friends yet. please add friend.
            </Alert>
          </>
        ))}
      {friendData && friendData.length > 0 && (
        <Table className="align-items-center table-flush" responsive>
          <tbody>
            {friendData.map((friend, i) => {
              return (
                <tr key={friend.memberid + i}>
                  <th
                    scope="row"
                    className="pr-0 pl-1"
                    style={{ width: "20%" }}
                  >
                    {friend.profile_pic_path &&
                      friend.profile_pic_path !== "" ? (
                      <img
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        src={friend.profile_pic_path}
                      />
                    ) : (
                      <div
                        className="bg-white pr-0 text-center"
                        style={{
                          height: "45px",
                          width: "45px",
                          border: "3px solid black",
                          borderRadius: "50%",
                        }}
                      >
                        <h2 className="textInitial">
                          {friend.name.split(" ").length > 1
                            ? friend.name.split(" ")[0].substring(0, 1) +
                            friend.name.split(" ")[1].substring(0, 1)
                            : friend.name.substring(0, 2)}
                        </h2>
                      </div>
                    )}
                  </th>
                  <th
                    scope="row"
                    style={{ textTransform: "uppercase", width: "30%" }}
                    className=""
                  >
                    {friend.name}
                  </th>
                  <td scope="row" style={{ textTransform: "uppercase", width: "30%" }}>
                    {friend.mobile}
                  </td>
                  <td className="text-right">
                    {friend.mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={() => HandelMyfriendChat(friend)}
                      >
                        <IoMdChatbubbles fontSize="large" />
                      </Button>
                      // <Button
                      //   className="btn btn-sm"
                      //   color="white"
                      //   onClick={(e) => {
                      //     window.location = "sms:" + friend.mobile;
                      //     e.preventDefault();
                      //   }}
                      // >
                      //   <FaSms fontSize="large" />
                      // </Button>
                    )}
                    {friend.email && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "mailto:" + friend.email;
                          e.preventDefault();
                        }}
                      >
                        <FaEnvelope fontSize="large" />
                      </Button>
                    )}
                    {friend.mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "tel:" + friend.mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaPhone fontSize="large" />
                      </Button>
                    )}
                    <Button
                      className="btn btn-sm"
                      color="white"
                      onClick={(e) => {
                        ConfirmRemoveFriend(e, friend);
                      }}
                    >
                      <FaTrash fontSize="large" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default FriendList;
