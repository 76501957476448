import React from 'react'
import Aboutimage from '../assets/images/AboutPage.png'
import { Box, Grid } from '@mui/material'
function About() {
    return (
        <div style={{ paddingLeft: '6%', paddingRight: '6%' }} className='py-3'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4} className="text-center">
                    <Box
                        component="img"
                        src={Aboutimage}
                        alt="About"
                        sx={{
                            width: '100%',
                            objectFit: 'contain',
                            paddingTop: '15px',
                            height: { xs: '500px', md: '450px' }, // 500px on small screens, auto on medium and larger screens
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <div style={{ paddingTop: '10px' }}>
                        <h2 style={{ fontWeight: '900' }}>Letter From the Founder:</h2>
                        <p>As the proud founder of Katana, I am thrilled to welcome you to our innovative travel community. Born out of the challenges created by the pandemic, Katana provides a highly sophisticated search engine platform for private aviation, allowing customers to find seats, explore empty-leg charters, and access options from esteemed available private jet providers. Furthermore, Katana's technology allows for customers to create customized groups with friends, colleagues, and like-minded travelers in an effort to coordinate their travel plans.</p>
                        <p>Our overall mission is to make private flying accessible and affordable for all. I invite your feedback and inquiries to enhance your experience with Katana. Please feel free to contact me directly at <a href="mailto:bv@flykatana.com">BV@FlyKatana.com.</a></p>
                        <p>Together, let us redefine the future of travel.</p>
                        <p>Fly high and see you soon,</p>
                        <p>Bryan Verona</p>
                        <p>Founder, Katana</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default About