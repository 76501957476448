import React, { useState, useEffect } from "react";
import {
  FaCopy,
  FaEdit,
  FaEye,
  FaShare,
  FaTrash,
  FaUserPlus,
} from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Table, Button, Card, CardBody } from "reactstrap";
import { Snackbar } from "@material-ui/core";
import { Alert } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useGroupsData } from "../../data/GroupsData";
import { IoMdChatbubbles } from "react-icons/io";

function MyGroupDetails({
  groupData,
  DeleteGroup,
  EditGroup,
  isallowEditAndDelete,
  SetDeleteGroupData,
}) {
  const [confirmDelete, SetConfirmDelete] = useState(false);
  const { GroupDetailsApi } = useGroupsData();
  const [isCopyLink, setCopyLink] = useState(false);
  const [copyText, setCopyText] = useState("");

  useEffect(() => {
    if (groupData) {
      CopyLink(groupData[0]);
    }
  }, [groupData]);

  function DeleteGroupDetails(e, groupdata) {
    SetDeleteGroupData(groupdata);
    SetConfirmDelete(true);
  }
  function YesConfirm(isconfirm) {
    if (isconfirm) {
      DeleteGroup();
    }
    SetConfirmDelete(false);
  }

  async function CopyLink(groupdata) {
    const response = await GroupDetailsApi(groupdata.group_id);
    if (response) {
      setCopyText(response[0].short_link);
    }
  }
  const handelGroupChat = (data) => {
    console.log({ is_group: true, ...data }, "groupData");
    // localStorage.setItem("groupData", JSON.stringify({ is_group: true, ...data }));
    localStorage.setItem("ModalData", JSON.stringify({ is_group: true, ...data }))
    const url = `/chat`;
    window.open(url, "_self", "noopener,noreferrer");

  }

  return (
    <div>
      {isCopyLink && (
        <Snackbar
          open={isCopyLink}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => setCopyLink(!isCopyLink)}
        >
          <Alert color="success">Link copied successfully.</Alert>
        </Snackbar>
      )}
      {confirmDelete && (
        <SweetAlert
          custom
          showCancel
          title=""
          confirmBtnText="Yes"
          confirmBtnBsStyle="btnYellow"
          cancelBtnBsStyle="btnYellow"
          onConfirm={(e) => YesConfirm(true)}
          onCancel={(e) => YesConfirm(false)}
          focusCancelBtn
        >
          Are you sure, You want to delete group?
        </SweetAlert>
      )}
      {groupData && groupData.length > 0 && (
        <Card>
          <CardBody className="text-center p-2">
            <span style={{ fontSize: "larger", fontWeight: "bolder" }}>
              {groupData[0].group_name}
            </span>
            &nbsp; &nbsp;
            <FaEdit
              style={{ fontSize: "larger", cursor: "pointer" }}
              onClick={(e) => EditGroup(e, groupData[0])}
            />
            &nbsp; &nbsp;
            <CopyToClipboard text={copyText} onCopy={(e) => setCopyLink(true)}>
              <FaShare
                style={{ fontSize: "larger", cursor: "pointer" }} />
            </CopyToClipboard>
            &nbsp; &nbsp;
            < IoMdChatbubbles
              style={{ fontSize: "larger", cursor: "pointer" }}
              onClick={() => handelGroupChat(groupData[0])}
            />
            {/* <FaShare
              style={{ fontSize: "larger", cursor: "pointer" }}
              onClick={(e) => CopyLink(e, groupData[0])}
            /> */}
            &nbsp; &nbsp;
            <FaTrash
              style={{ fontSize: "larger", cursor: "pointer" }}
              onClick={(e) => DeleteGroupDetails(e, groupData[0])}
            />
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default MyGroupDetails;
