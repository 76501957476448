import './common.css'
import { useState, useEffect } from "react";
import * as React from 'react';
import {authAndUserData} from '../data/AuthAndUserData';
import { Card, CardBody, Row, Col } from "reactstrap";
import Noimage from '../assets/press/Noimage.jpg'
function Press() {
  const { GetPress } = authAndUserData();
  const [press, SetPress] = useState([]);

  async function GetPressList() {
    const response = await GetPress();
    SetPress(response);
  }

  useEffect(() => {
    GetPressList();
  }, []);
  const handelErrorImage = (event) => {
    console.log(event,"event");
    event.target.src = Noimage;
  };
  const NewsCard = ({ title, image, link, date }) => (
    <div className="news-card">
      {console.log(image,"press")}
      <a href={link} target="_blank">
      <img src={image} alt={title} onError={handelErrorImage}/>
      
      <div><p className="date">{date}</p></div>
      
      <h2>{title}</h2>
      </a>
    </div>
  );

  
const NewsList = ({ news }) => (
  <div className="news-list">
    {news.map((item, index) => (
      <NewsCard
        key={index}
        title={item.headline}
        image={item.cover_image}
        link ={item.link}
        date = {item.date}
      />
    ))}
  </div>
);

return(
  <>
  <Card style={{ margin: 0, padding: 0, overflowY: "scroll" }}>
        <CardBody>
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-2">
              <h1>KATANA in The News</h1>
            </Col>
          </Row>
        </CardBody>
  </Card>
  <div class="newsdiv">
    <NewsList
      news={press} />
  </div></>
);
//   return (
//     <div class="row">
//     {
//       press.length > 0 && press.map((press) => (
//         <div class="col-sm-6">
//         <Card sx={{ maxWidth: 250 }}>
//           <CardMedia
//             sx={{ height: 140 }}
//             image={press.cover_image}
//             title={press.headline}
//           />
//           <CardContent>
//             <Typography gutterBottom variant="h5" component="div">
//               <a href={press.link} target="_blank">{press.headline}</a>
//             </Typography>
//           </CardContent>
//           {/* <CardActions>
//             <Button size="small">Share</Button>
//             <Button size="small">Learn More</Button>
//           </CardActions> */}
//         </Card>
//         </div>
//       ))
//     }
//     </div>
//   );
 }
export default Press;
