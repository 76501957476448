import React, { useState } from "react";
import {
  FaEnvelope,
  FaPhone,
  FaSms,
} from "react-icons/fa";
import { Table, Button, Alert } from "reactstrap";
import { useFriendsData } from "../../data/FrindsData";
import { Snackbar } from "@material-ui/core";

function InviteeList({ friendData, user }) {
  const { AddFriend, InviteFriend } = useFriendsData();
  const [IsInvited, SetInvited] = useState(false);

  async function InviteAgain(data) {
    if (data.is_friend_user) {
      const friendData = {
        mobile: user.mobile,
        invmobile: data.mobile,
        inviteename: user.name,
      }
      const response = await AddFriend(friendData, user);
      if (response.status) {
        SetInvited(true);
      }
    } else {
      const friendData = {
        mobile: user.mobile,
        invmobile: data.mobile,
        inviteename: user.name,
        friend_name: data.name,
      };
      const response = await InviteFriend(friendData, user);
      if (response.status) {
        SetInvited(true);
      }
    }
  }
  return (
    <div>
      {!friendData ||
        (friendData.length < 1 && (
          <>
            <Alert className="alertBlue text-white mx-auto" style={{ width: "97%" }}>No invites sent.</Alert>
          </>
        ))}
      {IsInvited && (
        <Snackbar
          open={IsInvited}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          onClose={(e) => SetInvited(!IsInvited)}
        >
          <Alert className="m-2 alertYellowInverse">
            User invited successfully.
          </Alert>
        </Snackbar>
      )}
      {friendData && friendData.length > 0 && (
        <Table className="align-items-center table-flush" responsive>
          {/* <thead className="bg-dark text-yellow">
            <tr>
              <th
                scope="col"
                colSpan="4"
                style={{ width: "100%" }}
                className="p-1"
              ></th>
            </tr>
          </thead> */}
          <tbody>
            {friendData.map((friend, i) => {
              return (
                <tr key={friend.memberid + i}>
                  <th
                    scope="row"
                    className="pr-0 pl-1"
                    style={{ width: "20%" }}
                  >
                    {friend.profile_pic_path &&
                      friend.profile_pic_path !== "" ? (
                      <img
                        style={{
                          height: "45px",
                          width: "45px",
                          borderRadius: "50%",
                        }}
                        src={friend.profile_pic_path}
                      />
                    ) : (
                      <div
                        className="bg-white pr-0 text-center"
                        style={{
                          height: "45px",
                          width: "45px",
                          border: "3px solid black",
                          borderRadius: "50%",
                        }}
                      >
                        <h2 className="textInitial">
                          {friend.name.split(" ").length > 1
                            ? friend.name.split(" ")[0].substring(0, 1) +
                            friend.name.split(" ")[1].substring(0, 1)
                            : friend.name.substring(0, 2)}
                        </h2>
                      </div>
                    )}
                  </th>
                  <th
                    scope="row"
                    style={{ textTransform: "uppercase", width: "30%" }}
                    className=""
                  >
                    {friend.name}
                  </th>
                  <td
                    scope="row"
                    style={{ textTransform: "uppercase", width: "30%" }}
                  >
                    {friend.mobile}
                  </td>
                  <td className="text-right">
                    {friend.mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "sms:" + friend.mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaSms fontSize="large" />
                      </Button>
                    )}
                    {friend.email && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "mailto:" + friend.email;
                          e.preventDefault();
                        }}
                      >
                        <FaEnvelope fontSize="large" />
                      </Button>
                    )}
                    {friend.mobile && (
                      <Button
                        className="btn btn-sm"
                        color="white"
                        onClick={(e) => {
                          window.location = "tel:" + friend.mobile;
                          e.preventDefault();
                        }}
                      >
                        <FaPhone fontSize="large" />
                      </Button>
                    )}
                    <Button
                      className="btn btn-sm"
                      color="white"
                      onClick={(e) => {
                        InviteAgain(friend);
                      }}
                    >
                      Invite Again
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        // <Table className="align-items-center table-flush" responsive>
        //   <thead className="bg-dark text-yellow">
        //     <tr>
        //       <th scope="col" style={{ width: "20%" }} className="p-1">
        //         {/* Mobile */}
        //       </th>
        //       <th scope="col" style={{ width: "40%" }} className="p-1" />
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {friendData.map((friend, i) => {
        //       return (
        //         <tr key={i}>
        //           <td scope="row" style={{ textTransform: "uppercase" }}>
        //             {friend.friend_mobile}
        //           </td>
        //           <td className="text-right">
        //             {friend.friend_mobile && (
        //               <Button
        //                 className="btn btn-sm"
        //                 color="white"
        //                 onClick={(e) => {
        //                   window.location = "sms:" + friend.friend_mobile;
        //                   e.preventDefault();
        //                 }}
        //               >
        //                 <FaSms fontSize="large" />
        //               </Button>
        //             )}
        //             {friend.friend_mobile && (
        //               <Button
        //                 className="btn btn-sm"
        //                 color="white"
        //                 onClick={(e) => {
        //                   window.location = "tel:" + friend.friend_mobile;
        //                   e.preventDefault();
        //                 }}
        //               >
        //                 <FaPhone fontSize="large" />
        //               </Button>
        //             )}
        //           </td>
        //         </tr>
        //       );
        //     })}
        //   </tbody>
        // </Table>
      )}
    </div>
  );
}

export default InviteeList;
