import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import "./common.css";

function FAQ() {
  return (
    <>
      <Card style={{ margin: 0, padding: 0, overflowY: "scroll" }}>
        <CardBody>
          <div className="container">
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12">
                <h1>FAQS</h1>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-2">
                <h2 style={{ fontWeight: "400" }}>
                  HOW MUCH DOES MEMBERSHIP COST?
                </h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12">
                <p className=" badge bg-yellow text-dark">
                  **BETA PERIOD IN EFFECT, FIRST-YEAR MEMBERSHIP FOR FREE!!!**
                </p>
                <p class="text-dark text-justify">
                  AFTER BETA PERIOD ENDS, If you are new to Katana you will get
                  a Test Flight for 30 days. After the Test Flight, your card
                  will be charged $150 for the Initial Annual Membership.
                  However, you can lower that amount to $100 by adding at least
                  5 Members and 2 indications of flight interest during the test
                  flight period. You may cancel at any time during the Test
                  Flight, prior to your card being charged
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-2">
                <h2 style={{ fontWeight: "400" }}>WHAT ARE GROUPS?</h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12">
                <p class="text-dark text-justify">
                  Members may create their very own GROUP. A Group allows
                  Members to connect with each other and see their flights
                  without the necessity to ADD and ACCEPT each Member
                  individually as a friend.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-2">
                <h2 style={{ fontWeight: "400" }}>
                  HOW DOES JOINING A POSTED FLIGHT WORK?
                </h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12">
                <p class="text-dark text-justify">
                  Members who have booked and paid for a flight may be
                  interested in sharing their excess seats with a Katana Member
                  by POSTing the flight information. If a Member wants to join a
                  POSTed flight the Originator will be notified. If still
                  interested the Joiner will be contacted by a representative
                  and they will be charged and added to the flight manifest.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-2">
                <h2 style={{ fontWeight: "400" }}>
                  ANY ADDITIONAL COSTS TO BOOK A FLIGHT?
                </h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12">
                <p class="text-dark text-justify">
                  When joining a POSTed flight the originator will list the
                  price per seat. IN addition, there will be a Federal Excise
                  Tax (FET) of 7.5% for Domestic Flights and Segment Fees of
                  $4.30 per person per leg of the flight.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-2">
                <h2 style={{ fontWeight: "400" }}>
                  DOES KATANA BOOK, ARRANGE, COORDINATE FLIGHTS?
                </h2>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg="12" md="12" sm="12">
                <p class="text-dark text-justify">
                  No. Katana is simply an engine that allows users to find
                  others who may be traveling to the same destinations around
                  the same time. It allows for dialogue between parties to
                  coordinate, fly together and save costs. All bookings,
                  payments, and plane inquiries are furbished by 3rd party
                  licensed brokers.
                </p>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default FAQ;
