import axios from "../libs/axios";

const CLIENT_ID =
  "656341340721-lv2n6vfugr15naahi3fsd09bpuas9u87.apps.googleusercontent.com";
// const API_KEY = "AIzaSyAeFXgwApT9_opep_lQtBT3_LVIri_3DG0";
const REDIRECT_URL = process.env.REACT_APP_DOMAIN + "/AuthorizeGoogle";
const AuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
const SCOPES = "https://www.googleapis.com/auth/contacts.readonly";
const PeopleUrl =
  "https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses&access_token=";

const Authorize = () => {
  // const response = gapi.load("client:auth2", initClient);
  var AUTHURL =
    AuthUrl +
    "?client_id=" +
    CLIENT_ID +
    "&redirect_uri=" +
    REDIRECT_URL +
    "&response_type=code&scope=" +
    SCOPES +
    "&access_type=offline&include_granted_scopes=true&state=" + window.location.pathname;
  window.open(AUTHURL, "_self");
  return false;
};

const GetAccessTokenByAuthCode = async (userid, code, user) => {
  const { data } = await axios.request({
    url: "/GetAccessTokenByAuthCode",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { auth_code: code, userid: userid },
  });
  return data;
};

const GetContactListByUserId = async (userid, user) => {
  const { data } = await axios.request({
    url: "/GetGoogleContactsByUserId",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid },
  });
  return data;
};

const SearchGoogleContactsByUserId = async (userid, searchdata, user) => {
  const { data } = await axios.request({
    url: "/SearchGoogleContactsByUserId",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid, search_query: searchdata },
  });
  return data;
};

const SignOutGoogle = async (userid, user) => {
  const { data } = await axios.request({
    url: "/SignOutGoogle",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid },
  });
  return data;
};

export const useGoogleData = () => ({
  Authorize,
  GetAccessTokenByAuthCode,
  GetContactListByUserId,
  SearchGoogleContactsByUserId,
  SignOutGoogle,
});
