import React, { useState } from 'react'
import { CardHeader, Row, Col, Input, Button } from "reactstrap";
import { useGroupsData } from '../../data/GroupsData'

function AddGroupData({
  user,
  SetGroupAdded,
}) {
  const { CreateGroup } = useGroupsData();
  const [groupName, setGroupName] = useState("");

  async function AddGroup() {
    const GroupData = {
      mobile: parseInt(user.mobile, 10),
      userid: parseInt(user.userid, 10),
      groupname: groupName,
      groupName
    };
    const response = await CreateGroup(GroupData, user);
    SetGroupAdded(true);
    setGroupName("");
  }

  return (
    <>
      <CardHeader className="border-0">
        <Row>
          <Col lg="6" md="6" sm="12">
            <Input
              type="text"
              placeholder="Group Name"
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
            ></Input>
          </Col>
          <Col lg="2" md="4" sm="12">
            <Button color="dark" onClick={AddGroup}>
              Add Group
            </Button>
          </Col>
        </Row>
      </CardHeader>
    </>
  );
}

export default AddGroupData;
