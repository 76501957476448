import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
const utc = require("dayjs/plugin/utc");
const customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

// abcd

export function dateDisplay(givenDate, includeTodayPrefix) {
  const currentDate = dayjs();
  const dayStart = currentDate.startOf('day');
  const parsedDate = dayjs(givenDate);

  if (parsedDate.isAfter(dayStart) && includeTodayPrefix) {
    return "today " + parsedDate.format("hh:mm A");
  } else if (parsedDate.isAfter(dayStart)) {
    return parsedDate.format("hh:mm A");
  } else if (parsedDate.isAfter(dayStart.subtract(1, 'day'))) {
    return "yesterday " + parsedDate.format("h:mm A");
  } else {
    return parsedDate.format(`MM/DD/YY  h:mm A`);
  }
}

export const formatDateToLocale = (dateString) => {
  const date = dayjs(dateString).toDate();

  const localeString = date.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const [datePart, timePart] = localeString.split(', ');

  const [month, day, year] = datePart.split('/');
  const formattedDate = `${day}-${month}-${year}`;

  return `${formattedDate}, ${timePart}`;
};

export const formatSimpleDate = (dateString) => {
  return dayjs(dateString).format('DD-MM-YYYY, h:mm A');
};


export const formatTimeToTimeZone = (time, timeZone) => {
  return dayjs.utc(time).tz(timeZone).format('DD-MM-YYYY | hh:mm A');
};






