import axios from "../libs/axios";

const CheckEmailRegistered = async (email) => {
  const { data } = await axios.request({
    url: "/CheckUserByEmail",
    method: "post",
    data: { email: email },
  });
  if (data.length < 1){
      return false;
    }else {
        return true;
    }
};

const CheckUserByMobile = async (mobile) => {
  const { data } = await axios.request({
    url: "/CheckUserByMobile",
    method: "post",
    data: { mobile: mobile },
  });
  if (data.length < 1) {
    return false;
  } else {
    return true;
  }
};

export const useValidateData = () => ({
  CheckEmailRegistered,
  CheckUserByMobile,
});