import axios from "../libs/axios";

const GetAllFriend = async (mobile, user) => {
  const { data } = await axios.request({
    url: "/Friends",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { mobile: mobile },
  });
  return data;
};

const RemoveFriendApi = async (friendsData, user) => {
  const { data } = await axios.request({
    url: "/RemoveFriend",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: friendsData,
  });
  return data;
};

const InviteFriend = async (friendsData, user) => {
  const { data } = await axios.request({
    url: "/CreateConnection",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: friendsData,
  });
  return data;
};

const AddFriend = async (friendsData, user) => {
  const { data } = await axios.request({
    url: "/CreateMemberConnection",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: friendsData,
  });
  return data;
};

const LeaveGroup = async (groupData, user) => {
  const { data } = await axios.request({
    url: "/LeaveGroup",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: groupData,
  });
  return data;
};

const InvitesSent = async (userid, user) => {
  const { data } = await axios.request({
    url: "/InvitesSent",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: { userid: userid },
  });
  return data;
};

const AddAllKatanaMembers = async (membersdata, user) => {
  const { data } = await axios.request({
    url: "/AddAllKatanaMembers",
    method: "post",
    headers: {
      Authorization: `Bearer ${user.jwt}`
    },
    data: membersdata,
  });
  return data;
}

export const useFriendsData = () => ({
  GetAllFriend,
  RemoveFriendApi,
  InviteFriend,
  AddFriend,
  LeaveGroup,
  InvitesSent,
  AddAllKatanaMembers,
});