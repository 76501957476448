import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { FaTimes, FaPlane } from "react-icons/fa";
import airportData from "../data/airport";
import { FormGroup } from "reactstrap";
import "./City.css";

function HomeAway({ type, code, setCode, Input , InputLabel, page }){
    const [cityCountry, setCityCountry] = useState("");
    const [filterdata, setFilterData] = useState([]);

    const airportSuggestionRef = useRef(
      airportData.map((item) => ({
        label: `${item.city}: ${item.IATA}`,
        value: item.IATA,
      }))
    );
    const options = airportSuggestionRef.current;

    useEffect(() => {
      setFilterData(options);
      if(code !== null || code !== "" || code !== undefined){
        setCityCountry(code);
      }
    }, []);

    const onClickInput = () => {
      setCode("");
      setCityCountry("");
    };

    const onTextChange = (event) => {
      setCityCountry(event.target.value);
      const filterdata = options.filter(
        (opt) =>
          opt.label.toLowerCase().includes(event.target.value.toLowerCase()) ||
          opt.value.toLowerCase().includes(event.target.value.toLowerCase())
      );
      setFilterData(filterdata);
    };

    const SelectOption = (option) => {
      setCode(option);
      setCityCountry(option);
    };

    const ClearInput = (e) => {
      setCode(null);
      setCityCountry("");
    };

    return (
      <>
        {type === "Home" && (
          <>
            {InputLabel && <InputLabel>Home (Airport)</InputLabel>}
            <Input
              id="home"
              value={cityCountry} 
              onChange={onTextChange}
              onClick={onClickInput}
              autoComplete="off"
              className={page !== "Profile" ? "inputSignUp" : ""}
            />
            {code !== null && (
              <FaTimes
                className={page === "Profile" ? "timesPosition" : "timesSignUp"}
                onClick={(e) => {
                  ClearInput(e);
                }}
              />
            )}
          </>
        )}
        {type === "Away" && (
          <>
            {InputLabel && <InputLabel>Away (Frequent Destination)</InputLabel>}
            <Input
              id="away"
              value={cityCountry}
              onChange={onTextChange}
              onClick={onClickInput}
              autoComplete="off"
              className={page !== "Profile" ? "inputSignUp" : "inputSignUp"}
            />
            {code !== null && (
              <FaTimes
                className={page === "Profile" ? "timesPosition" : "timesSignUp"}
                onClick={(e) => {
                  ClearInput(e);
                }}
              />
            )}
          </>
        )}
        {code === "" && (
          <div className="Dropdown">
            <ul>
              {filterdata &&
                filterdata.map((item) => {
                  return (
                    <li
                      key={item.label}
                      data-value={item.value}
                      onClick={(e) => SelectOption(item.label)}
                    >
                      {item.label}
                      {/* <div>
                        <div>
                          <span className="h3" data-value={item.value}>
                            {item.label}
                          </span>
                          <span
                            className="h4"
                            style={{ float: "right" }}
                            data-value={item.value}
                          >
                            {item.value}
                          </span>
                        </div>
                        <hr className="m-1" />
                      </div> */}
                    </li>
                  );
                })}
              {filterdata.length < 1 && <li>No Data Found</li>}
            </ul>
          </div>
        )}
      </>
    );
}

export default HomeAway;