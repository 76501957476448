import React, { useState, useEffect } from "react";
import { useParams, useHistory } from 'react-router-dom'
import { useFlightData } from "../data/FlightData";
import { useLocalStorage } from "../utils/useLocalStorage";
import EditSearch from "../components/EditFlight/EditSearch";
import EditBook from "../components/EditFlight/EditBook";

function EditFlightDetails() {
  const { id } = useParams();
  const { getFlightDetail } = useFlightData();
  const [flightDetails, SetFlightDetails] = useState(null);
  const [user] = useLocalStorage("auth", {});
  const history = useHistory();

  useEffect(() => {
    GetFlightDetails(id);
  }, [])

  async function GetFlightDetails(id) {
    const data = await getFlightDetail(id, user);
    SetFlightDetails(data);
    // if (data && data.userid === user.userid){
    //     SetFlightDetails(data);
    // }else{
    //     history.push("/myFlights");
    // }
  }
  return (
    <>
      {flightDetails &&
        flightDetails.length > 0 &&
        flightDetails[0].is_posted && <EditBook flightdata={flightDetails} />}
      {flightDetails &&
        flightDetails.length > 0 &&
        !flightDetails[0].is_posted && <EditSearch flightdata={flightDetails} />}
    </>
  );
}

export default EditFlightDetails
