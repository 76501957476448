import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const DuffelPortal = ({ children, wrapperId = 'duffel-payment-wrapper' }) => {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', wrapperId);

    useEffect(() => {
        // Append the wrapper to the body
        document.body.appendChild(wrapperElement);

        return () => {
            // Clean up when component is unmounted
            document.body.removeChild(wrapperElement);
        };
    }, [wrapperElement]);

    return createPortal(children, wrapperElement);
};

export default DuffelPortal;
