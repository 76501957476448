import React, { useState } from 'react';
import { Modal, Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PopupBg from '../assets/images/UpgradeScreen.png';
//import './PlansPopup.css';

const PlansPopup = ({ show, handleClose, closeOnClick = false }) => {
  const history = useHistory();
  const navigatePlans = () => {
    if (closeOnClick) handleClose();
    history.push('/plans');
  }

  return (
    <Modal
      centered
      size="md"
      style={{ borderRadius: '3px' }}
      show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      {/* <Modal.Body style={{ backgroundImage: `url(${PopupBg})`, backgroundSize: 'cover' }}> */}
      <Modal.Body style={{ background: '#ffe600', borderRadius: '7px' }}>
        {/* <button className="close" onClick={() => handleClose()}
          style={{
            position: 'absolute',
            top: '9px',
            right: '25px',
            fontSize: '30px',
            fontWeight: 'bolder',
            color: 'black',color:'black',
            backgroundColor: 'transparent',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}>X</button> */}
        <Card text="white" style={{ marginTop: '30px' }}>
          <Card.Body style={{ padding: '0' }}>
            <img src={PopupBg} style={{ width: '100%' }} alt="Popup img" />
          </Card.Body>
          <Card.Body
            style={{ textAlign: 'center' }}>
            <Card.Text
              style={{ fontSize: '25px', fontWeight: '900', margin: '0', color: 'black' }}>UPGRADE NOW</Card.Text>
            <Card.Text
              style={{ fontSize: '18px', fontWeight: '900', margin: '0', color: 'black' }}>That feature requires a (Seat) Upgrade...</Card.Text>
            <Card.Text
              style={{ fontSize: '18px', color: 'black' }}>(Don't worry, first three months are free!)</Card.Text>
          </Card.Body>
          <div className='text-center mb-2'>
            <Button
              style={{ background: '#ffe600', borderRadius: '30px', fontStyle: 'italic', color: 'black', border: '#ffe600' }}
              onClick={navigatePlans}>Upgrade now</Button>
            <Button
              style={{ background: '#ffe600', borderRadius: '30px', fontStyle: 'italic', color: 'black', border: '#ffe600' }}
              onClick={() => handleClose()}>No Thanks</Button>
          </div>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

// const PopupContainer = () => {
//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button variant="primary" onClick={handleShow}>
//         Launch demo modal
//       </Button>

//       <Popup
//         show={show}
//         handleClose={handleClose}
//         title="Popup title"
//         text="Popup text"
//         buttonText="Popup button text"
//         imageSrc="https://via.placeholder.com/800x600.png?text=Popup+background+image"
//       />
//     </>
//   );
// };

export default PlansPopup;
